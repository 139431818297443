import { useState } from "react";
import BaseModal from "./BaseModal";
import Typography from "../Typography";
import Styled from "./Styled";
import GeneralButton from "../Button";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";
import Space from "../Space";
import IconClose from "../../../assets/ic-close.svg";
import styled from "styled-components";
import { breakpoint } from "utils/responsive";

interface ContentContainerProps {
  needResponsive?: boolean;
}

const ContentContainer = styled.div<ContentContainerProps>`
  max-height: 80vh;
  overflow: auto;
  max-width: 90vw;

  ${props => props?.needResponsive && `
    @media ${breakpoint.mobile} {
      max-width: 80vw;
    }

    @media ${breakpoint.desktop} {
      max-width: 55vw;
    }
  `}
`

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  needResponsive?: boolean;
}

const CloseIconModal = ({ children, open, onClose, needResponsive }: ModalProps) => {
  const { t } = useTranslation("common");
  return (
    <BaseModal open={open} onClose={onClose}>
      <Styled.SimpleModalCotnainer>
        <div className="flex justify-end">
          <img src={IconClose} className="cursor-pointer" onClick={onClose} />
        </div>
        <ContentContainer needResponsive={needResponsive}>
          {children}
        </ContentContainer>
      </Styled.SimpleModalCotnainer>
    </BaseModal>
  );
};

export default CloseIconModal;
