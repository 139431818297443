import PageContainer from "components/common/PageContainer";
import { useTranslation } from "react-i18next";
import BgEnquiry from "assets/bg-enquiry-banner.png";
import ColorBannerCard from "pageComponents/facility/ColorBannerCard";
import Typography from "components/common/Typography";
import theme from "utils/theme";
import Space from "components/common/Space";
import Form from "components/common/Form";
import { useContext, useMemo } from "react";
import GeneralButton from "components/common/Button";
import { object, string } from "yup";
import { useEnquiryViewModel } from "common/viewModels/EnquiryViewModel";
import { IEnquiryFormViewModel } from "common/models/Enquiry";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { appearGlobalError } from "common/context/requests/globalRequest";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { IGlobalProviderContext } from "../../@types/common";

interface IEnquiryForm {
  org_name: string;
  contact_name: string;
  email: string;
  phone: string;
  enquiry_title: string;
  enquiry_content: string;
}

const EnquiryFormYupSchema = object({
  org_name: string().required(),
  contact_name: string().required(),
  email: string().required(),
  phone: string().required(),
  enquiry_title: string().required(),
  enquiry_content: string().required(),
});

const EnquiryPage = () => {
  const { t } = useTranslation("enquiry");
  const navigate = useNavigate();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { createEnquiryForm } = useEnquiryViewModel() as IEnquiryFormViewModel;

  const initialFormState = useMemo<IEnquiryForm>(
    () => ({
      org_name: "",
      contact_name: "",
      email: "",
      phone: "",
      enquiry_title: "",
      enquiry_content: "",
    }),
    []
  );

  const formChecker = (value: IEnquiryForm) => {
    let error = "";
    const phoneChecker = /^[0-9]*$/;
    const emailChecker = /\S+@\S+\.\S+/;

    if (!emailChecker.test(value?.email)) {
      error = t("error.invalidField", { field: t("email") });
    } else if (!phoneChecker.test(value?.phone)) {
      error = t("error.invalidField", { field: t("phone") });
    }

    return error;
  };

  const onFormSubmit = async (value: IEnquiryForm) => {
    const checker = formChecker(value);
    if (!_.isEmpty(checker)) {
      appearGlobalError(globalDispatch, checker);
    } else {
      const res = await createEnquiryForm(value);
      if (!_.isEmpty(res) && res?.success) {
        navigate("success", { state: { enableAccess: true } });
      }
    }
  };

  return (
    <PageContainer banner={BgEnquiry} title={t("enquiry")}>
      <Typography fontSize="30px" fontWeight="500" color={theme.black}>
        {t("title")}
      </Typography>
      <Space size="40px" />
      <Typography>{t("desc")}</Typography>
      <Space size="40px" />
      <ColorBannerCard title={t("enqiuryForm")}>
        <Form
          initialValues={initialFormState}
          validationSchema={EnquiryFormYupSchema}
          onSubmit={onFormSubmit}
        >
          {(formik) => (
            <Form.Element id="enquiry_form">
              <Form.Field.TextField
                id="org_name"
                name="org_name"
                title={t("organization")}
                placeholder={t("organization")}
              />
              <Space size="20px" />
              <Form.Field.TextField
                id="contact_name"
                name="contact_name"
                title={t("contactName")}
                placeholder={t("contactName")}
              />
              <Space size="20px" />
              <Form.Field.TextField
                id="email"
                name="email"
                title={t("email")}
                placeholder={t("email")}
              />
              <Space size="20px" />
              <Form.Field.TextField
                id="phone"
                name="phone"
                title={t("phone")}
                placeholder={t("phone")}
              />
              <Space size="20px" />
              <Form.Field.TextField
                id="enquiry_title"
                name="enquiry_title"
                title={t("enqiuryTitle")}
                placeholder={t("enqiuryTitle")}
              />
              <Space size="20px" />
              <Form.Field.TextField
                id="enquiry_content"
                name="enquiry_content"
                title={t("content")}
                placeholder={t("content")}
                multiline
                rows={6}
              />

              <div className="mt-[40px] flex justify-end">
                <GeneralButton
                  type="submit"
                  disabled={
                    !formik.dirty || !formik.isValid || formik.isSubmitting
                  }
                >
                  {t("common:button.submit")}
                </GeneralButton>
              </div>
            </Form.Element>
          )}
        </Form>
      </ColorBannerCard>
    </PageContainer>
  );
};
export default EnquiryPage;
