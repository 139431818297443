import { Button } from "@mui/material";
import MuiButtonGroup from "@mui/material/ButtonGroup";
import theme from "utils/theme";
import GeneralButton from "./Button";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

interface ButtonGroupProps {
  themeColor?: string;
  options: { label: string; value: string }[];
  selected: string;
  setSelected: (value: string) => void;
}

const ButtonGroup = ({
  themeColor = theme.orange,
  options,
  selected,
  setSelected,
}: ButtonGroupProps) => {
  const { t } = useTranslation();

  return (
    <MuiButtonGroup
      variant="outlined"
      style={{ width: "100%" }}
      sx={{
        ".MuiButtonGroup-grouped": {
          borderColor: themeColor,
        },
        ".MuiButtonGroup-grouped:hover": {
          borderColor: themeColor,
        },
      }}
    >
      {options?.map((option) => {
        const isSelected = selected === option?.value;
        return (
          <GeneralButton
            bgColor={isSelected ? theme.orange : "transparent"}
            textColor={isSelected ? theme.white : theme.orange}
            fullWidth
            key={option?.value}
            onClick={() => setSelected(option?.value)}
          >
            {t(option?.label)}
          </GeneralButton>
        );
      })}
    </MuiButtonGroup>
  );
};

export default ButtonGroup;
