import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { ROUTE_BASE } from "../../Route";
import MyBookingScreen from "./MyBookingScreen";
import { MyBookingListPage } from "./MyBookingListPage";
import { MyBookingDetailPage } from "./MyBookingDetailPage";

// full path for component route matching only, not for dom router
export const myBookingRoutes = [
  { path: "/my-bookings" },
  { path: `/my-bookings/:bookingId` },
];

const MyBookingRouter: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <MyBookingScreen />,
      children: [
        {
          path: "/",
          element: <MyBookingListPage />,
        },
        {
          path: "/:bookingId",
          element: <MyBookingDetailPage />,
        },
        {
          path: "*",
          element: <Navigate to={ROUTE_BASE.MY_BOOKINGS} />,
        },
      ],
    },
  ]);
};

export default MyBookingRouter;
