import { useEffect, useState } from "react";
import { useCommonFetcher } from "./CommonViewModel";
import { getCountryCodeList as getCountryCodeListAPI } from "../../network/common";
import { ICountryCode } from "../../models/Common/FieldConfig";

export const useFieldConfigViewModel = () => {
  const { fetcher } = useCommonFetcher();
  const [countryCodeList, setCountryCodeList] = useState<ICountryCode[]>([]);

  useEffect(() => {
    getCountryCodeList();
  }, []);

  const getCountryCodeList = async () => {
    const res = await fetcher({
      api: getCountryCodeListAPI,
    });

    console.log("===res", res);
    if (res?.success) setCountryCodeList(res.result);
    return res;
  };

  return {
    countryCodeList,
    getCountryCodeList,
  };
};
