import styled from "styled-components";
import theme from "utils/theme";

const PageContainer = styled.div`
  /** Ref: https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container */
  min-width: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: ${theme.lightCyan};
  padding: 22px 26px 24px;
`;

export default { PageContainer };
