export enum UserTitle {
  Mr = "MR",
  Ms = "MS",
  Mrs = "MRS",
  Miss = "MISS",
}

export const userTitleList: UserTitle[] = [
  UserTitle.Mr,
  UserTitle.Ms,
  UserTitle.Mrs,
  UserTitle.Miss,
];
