import i18n from "i18n";

export class GolfMap {
  constructor({
    _id,
    id,
    golfmap_image_ref,
    golfmap_desc_en,
    golfmap_desc_tc,
  }) {
    this._id = _id;
    this.id = id;
    this.golfmap_image_ref = golfmap_image_ref;
    this.golfmap_desc_en = golfmap_desc_en;
    this.golfmap_desc_tc = golfmap_desc_tc;
  }

  getDescription() {
    return i18n.language === "en" ? this.golfmap_desc_en : this.golfmap_desc_tc;
  }
}
