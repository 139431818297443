import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import MonthlyProgram from "./MonthlyProgram";
import MonthlyProgramDetail from "./MonthlyProgramDetail";
import MonthlyProgramByCategory from "./MonthlyProgramByCategory";
import MonthlyProgramBooking from "./MonthlyProgramBooking";
import MonthlyProgramScreen from "./MonthlyProgramScreen";
import MonthlyProgramBookingScreen from "./MonthlyProgramBookingScreen";
import { ROUTE_BASE } from "Route";

const RegistrationRouter: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <MonthlyProgramScreen />,
      children: [
        {
          path: "/",
          element: <Navigate to={`../category`} />,
        },
        {
          path: "/category",
          element: <MonthlyProgram />,
        },
        {
          path: "/category/:id",
          element: <MonthlyProgramByCategory />,
        },
        {
          path: "/program/:programId",
          element: <MonthlyProgramDetail />,
        },
        {
          path: "/program/:programId/booking/:classId",
          element: <MonthlyProgramBookingScreen />,
        },
        {
          path: "*",
          element: <Navigate to={`../`} />,
        },
      ],
    },
  ]);
};

export default RegistrationRouter;
