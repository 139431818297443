import config from "../../config";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { useContext } from "react";
import {
  UserViewModelContext,
  UserViewModelProvider,
} from "../viewModels/UserViewModel";
import {
  getDefaults,
  saveDefaults,
  userDefaultKeys,
} from "../../utils/UserDefaultHelper";
import { refreshToken, refreshTokenUnderMultiAccount } from "./auth";
import { isEmptyValues } from "utils/common";
import axiosRetry from "axios-retry";
// const axios = require("axios");

export const instance = axios.create({
  baseURL: config.API_ROOT,
  timeout: config.API_TIMEOUT,
  headers: {
    "application-version": config.APPLICATION_VERSION,
    "application-platform": config.APPLICATION_PLATFORM,
  },
});

export const authedInstance = axios.create({
  baseURL: config.API_ROOT,
  timeout: config.API_TIMEOUT,
  headers: {
    "application-version": config.APPLICATION_VERSION,
    "application-platform": config.APPLICATION_PLATFORM,
  },
});

export const generalInstance = axios.create({
  timeout: config.API_TIMEOUT,
});

export const generalInstanceWithBaseUrl = axios.create({
  baseURL: config.API_ROOT,
  timeout: config.API_TIMEOUT,
  headers: {
    "application-version": config.APPLICATION_VERSION,
    "application-platform": config.APPLICATION_PLATFORM,
  },
});

export const kioskPaymentInstance = axios.create({
  timeout: config.KIOSK_PAYMENT_API_TIMEOUT,
});

const genericErrorHandler = (err) => {
  console.log("response interceptor genericErrorHandler", err);

  return Promise.reject(err);
};

// NOTE: resolve will retry the request again. If force logout, reject to avoid retrying pending requests
const refreshAuthLogic = async (
  failedRequest,
  platform,
  onFailed,
  onRefreshed = null
) => {
  console.log("response interceptor refreshAuthLogic");

  let currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
  console.log("[Racing test] - refreshAuthLogic. currentUser", currentUser);
  console.log(
    "[Racing test] - refreshAuthLogic. origin req session",
    failedRequest.config.sessionId
  );
  console.log(
    "[Racing test] - refreshAuthLogic. currentUser session",
    currentUser?.sessionId
  );

  if (currentUser && currentUser.sessionId !== failedRequest.config.sessionId) {
    console.log(
      "[Racing test] - refreshAuthLogic. currentUser session not match with origin req session. Reject let it die"
    );
    return Promise.reject(
      new Error(
        "currentUser session not match with origin req session. Reject let it die"
      )
    );
  }

  // console.log('refreshAuthLogic 1, log current user', currentUser, currentUser?.refresh_token);
  // NOTE: there is a racing condition here, the currentUser might be null after refresh fail but in parallel api response 401 delayed after refresh fail.
  if (!currentUser?.refresh_token) {
    console.log("refreshAuthLogic ", "no refresh token found");
    // ASSUME - if no currentUser, the 1st interceptor will handle it. can remove below line after test
    // KEEP IN VIEW - switchUser triggered multiple times will cause cross writing userDefaultKeys.CURRENT_USER.
    // if(onFailed) onFailed(false)
    return Promise.reject(new Error("Local store Refresh token not found"));
  }
  try {
    // refreshTokenUnderMultiAccount also work with only one current user
    // console.log('refreshAuthLogic 2, log current user', currentUser, currentUser?.refresh_token);
    const res = await refreshTokenUnderMultiAccount(
      { refresh_token: currentUser?.refresh_token, platform },
      currentUser?.sessionId
    );
    authedInstance.defaults.headers.Authorization =
      "Bearer " + res.access_token;
    failedRequest.response.config.headers[
      "Authorization"
    ] = `Bearer ${res.access_token}`;

    // Refresh API success may delay, currentUser can be other user, can be null
    currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
    console.log(
      "[Racing test] - On refresh response. origin req",
      failedRequest.config.sessionId
    );
    console.log(
      "[Racing test] - On refresh response. currentUser session",
      currentUser.sessionId
    );
    if (
      !currentUser ||
      currentUser.sessionId !== failedRequest.config.sessionId
    ) {
      console.log(
        "[Racing test] - On refresh response. currentUser session not match with origin req session. save to cache user list"
      );
      // Save the latest token pair to local store. otherwise next time user won't be able to refresh token
      const cachedUsers =
        (await getDefaults(userDefaultKeys.CACHED_USERS)) ?? [];
      // FIXME - above line of code still may have sync issue that the CACHED_USERS get updated in other function right after we get here
      // FIXME - use local db to avoid full list get and full list save. So that each item can be mutated(upsert/remove) seperately
      const newUserList = cachedUsers.map((savedUser) =>
        savedUser.sessionId &&
        savedUser.sessionId === failedRequest.config.sessionId
          ? {
              ...savedUser,
              refresh_token: res.refresh_token,
              access_token: res.access_token,
            }
          : savedUser
      );
      await saveDefaults(userDefaultKeys.CACHED_USERS, newUserList);
      console.log("refreshAuthLogic 3, new cachedUsers should be", newUserList);
      // if there is current user/guest, it may has other pending requests, let it retry

      return Promise.resolve();
    } else {
      // NORMAL CASE
      console.log("refreshAuthLogic 3, new current user should be", {
        ...currentUser,
        refresh_token: res.refresh_token,
        access_token: res.access_token,
      });

      await saveDefaults(userDefaultKeys.CURRENT_USER, {
        ...currentUser,
        refresh_token: res.refresh_token,
        access_token: res.access_token,
      });
      if (onRefreshed) onRefreshed(res.access_token, res.refresh_token);

      return Promise.resolve();
    }
  } catch (err) {
    console.log("refreshAuthLogic err", err);
    if (err.response) {
      // Other Response error from remote
      const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
      console.log(
        "[Racing test] - On refresh failed. origin req",
        failedRequest.config.sessionId
      );
      console.log(
        "[Racing test] - On refresh failed. currentUser session",
        currentUser?.sessionId
      );

      // if currentUser is not null but session id not match(switched), resolve then let OnRetry check session to fire the current user pending request
      if (
        currentUser &&
        currentUser.sessionId !== failedRequest.config.sessionId
      )
        return Promise.resolve();
      // if currentUser is logged/guest user but session id not null. It should retry guest's requests, let onRetry handle the racing condition
      if (!currentUser && failedRequest.config.sessionId)
        return Promise.resolve();

      if (err.response.status === 400 || err.response.status === 500) {
        // console.log('refreshAuthLogic 4, log current user', currentUser, currentUser?.refresh_token);

        // force logout
        if (onFailed) onFailed();
        // reject will not retry the request again
        return Promise.reject();
      }

      // not API managed error(might be db connection problem), just retry
      return Promise.resolve();
    } else {
      // network error, just retry
      return Promise.resolve();
    }
  }
};

/**
 * NOTE: When a function is defined, it captures its surrounding scope at the time of creation.
 * If the value of the variable outside the function changes,
 * the captured value inside the function remains the same unless the function is redefined.
 * Please aware the change happen outside will different to function inside when the callback get called.
 * @param {*} onFailed Callback when fail to refresh token.
 * @param {(access_token, refresh_token) => {}} onRefreshed Callback when token refrehsed.
 */
export const initRefreshInterceptor = (
  onFailed,
  onRefreshed = null,
  platform = "web"
) => {
  let interceptorId = createAuthRefreshInterceptor(
    authedInstance,
    (failedRequest) =>
      refreshAuthLogic(failedRequest, platform, onFailed, onRefreshed),
    {
      skipWhileRefreshing: false,
      statusCodes: [401],
      //After refreshAuthLogic resolve, if there are any request fire after refresh occured, this will be called
      onRetry: async (request) => {
        console.log("[Racing test] - On retry. req session", request);

        // the refresh instance pending all requests during refreshing, include the requests before or after switch user

        // axios request interceptor execute in reverse order, so the new request session show not set
        if (!request.sessionId) return request;

        const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
        // for those request.sessionId not same current, reject let it die and retry all the others(guest/other new user)
        if (currentUser && currentUser.sessionId !== request.sessionId) {
          console.log(
            "[Racing test] - On refresh failed then retry pending request. currentUser session not match with origin req session. Reject let it die"
          );
          return Promise.reject(
            new Error(
              "currentUser session not match with origin req session. Reject let it die"
            )
          );
        }
        return request;
      },
    }
  );
  // const responseInterceptors = authedInstance.interceptors.response.handlers
  // console.log("responseInterceptors 2", responseInterceptors)
  // const requestInterceptors = authedInstance.interceptors.request.handlers
  // console.log("requestInterceptors 2", requestInterceptors)
  return interceptorId;
};

export const ejectInterceptor = (interceptorId) => {
  authedInstance.interceptors.response.eject(interceptorId);
};

export const getAccessToken = async () => {
  const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
  return currentUser?.access_token;
};

authedInstance.interceptors.request.use(
  async (res) => {
    console.log("request interceptor 0");
    try {
      const token = await getAccessToken();
      res.headers["Authorization"] = !isEmptyValues(token)
        ? `Bearer ${await getAccessToken()}`
        : undefined;
      const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
      res.sessionId = currentUser?.sessionId || undefined;
      console.log(
        "[Racing test] - request interceptor. session",
        res.sessionId
      );
    } catch (err) {
      console.log("request interceptor 1", err);
    }
    return res;
  },
  (err) => {
    console.log("request interceptor error", err);

    return Promise.reject(err);
  }
);

export const setRequestInterceptors = (onRequest = (config) => {}) => {
  instance.interceptors.request.use((config) => {
    onRequest(config);
    return config;
  });

  authedInstance.interceptors.request.use((config) => {
    onRequest(config);
    return config;
  });
};

export const setResponseInterceptors = (onResponse = (config) => {}) => {
  authedInstance.interceptors.response.use((res) => {
    onResponse(res);
    return res;
  }, genericErrorHandler);

  instance.interceptors.response.use((res) => {
    onResponse(res);
    return res;
  }, genericErrorHandler);
};

authedInstance.interceptors.response.use(
  async (res) => {
    // console.log("response interceptor. success", res);
    console.log("Response: ", res);
    const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
    if (!currentUser) {
      // no current user, guest or logged out
      if (res.config.sessionId) {
        // request has sessionId and not refresh api. Reject let it die
        console.log(
          "[Racing test] - no current user but request has sessionId also not refresh. Reject let it die"
        );
        return Promise.reject(
          new Error(
            "no current user but request has sessionId also not refresh. Reject let it die"
          )
        );
      }
    } else {
      if (currentUser.sessionId) {
        // current user has session id
        console.log(
          "[Racing test] - response interceptor. req session",
          res.config?.sessionId,
          " currentUser.sessionId",
          currentUser.sessionId,
          res.config?.url
        );
        if (currentUser.sessionId !== res.config.sessionId) {
          // session id not match with request session id and not refresh api. Reject let it die
          console.log(
            "[Racing test] - current user session id not match with request session id. Reject let it die"
          );
          return Promise.reject(
            new Error(
              "current user session id not match with request session id. Reject let it die"
            )
          );
        }
      }
    }
    return res;
  },
  async (err) => {
    if (err && err.config) {
      // the err can be undefined if reject from refreshAuthLogic
      const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
      // console.log("response interceptor. fail", err, "current user - ", currentUser);
      // console.log("err", err.response , err.response?.data, currentUser);
      if (!currentUser) {
        // no current user, guest or logged out
        if (err.config.sessionId) {
          // request has sessionId and not refresh api. Reject let it die
          console.log(
            "[Racing test] - no current user but request has sessionId also not refresh. Reject let it die"
          );
          return Promise.reject(
            new Error(
              "no current user but request has sessionId also not refresh. Reject let it die"
            )
          );
        }
      } else {
        if (currentUser.sessionId) {
          // current user has session id
          console.log(
            "[Racing test] - response interceptor. req session",
            err.config.sessionId,
            " currentUser.sessionId",
            currentUser.sessionId,
            err.config.url
          );
          if (currentUser.sessionId !== err.config.sessionId) {
            // session id not match with request session id and not refresh api. Reject let it die
            console.log(
              "[Racing test] - current user session id not match with request session id. Reject let it die"
            );
            return Promise.reject(
              new Error(
                "current user session id not match with request session id. Reject let it die"
              )
            );
          }
        }
      }
    }

    await genericErrorHandler(err);
  }
);

instance.interceptors.response.use(async (res) => {
  console.log("Response: ", res);
  authedInstance.defaults.headers.Authorization = `Bearer ${await getAccessToken()}`;
  return res;
}, genericErrorHandler);

export const setKioskPaymentInterceptor = (onResponse = (config) => {}) => {
  kioskPaymentInstance.interceptors.response.use((res) => {
    onResponse(res);
    return res;
  }, genericErrorHandler);
};

kioskPaymentInstance.interceptors.response.use((res) => {
  return res;
}, genericErrorHandler);

axiosRetry(kioskPaymentInstance, {
  retries: 3,
  retryDelay: axiosRetry.linearDelay(),
  retryCondition: (err) => {
    return true;
  },
});
