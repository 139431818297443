import { Table, TableCell, TableRow } from "@mui/material";
import {
  EEnrolmentRole,
  IEnrollmentDate,
  MonthlyProgramClass,
  ProgramClassStatus,
} from "common/models/MonthlyProgram";
import GeneralButton from "components/common/Button";
import SecondaryButton from "components/common/SecondaryButton";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getLocalizedString } from "utils/locale";
import { ResponsiveUI, WindowSize, breakpoint } from "utils/responsive";
import { DataCell, DateCell, HeaderCell } from "./Styled";
import cn from "classnames";
import { ProgramClassEnrollButton } from "./ProgramClassEnrollButton";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { IUserViewModelContext } from "../../../../@types/common";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

type AlternatingCellsCountBySectionResult = {
  classYearMonth: number;
  lessons: number;
  coaches: number;
  quota: number;
  pricing: number;
  totalPrice: number;
  venues: number;
  enrolmentDates: number;
  enrolmentRole: number;
};

export const ProgramClassTable = (props: {
  programClass: MonthlyProgramClass;
  onClickEnroll: (classObject: MonthlyProgramClass) => void;
}) => {
  const { currentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { programClass, onClickEnroll } = props;
  const {
    lessons,
    coaches,
    classYearMonth,
    quota,
    pricing,
    totalPrice,
    venues,
    formattedEnrollmentDates,
    enrolmentRole,
  } = programClass;

  const { t } = useTranslation("monthlyProgram");
  const isMobileScreen = WindowSize() === "mobile";

  // seemed the color of alternating cells can only be determine by counting

  // memo counts
  const alternatingCellsCountBySection = useMemo(() => {
    const bySection = _.reduce(
      {
        lessons,
        coaches,
        quota,
        pricing,
        totalPrice,
        venues,
        formattedEnrollmentDates,
        enrolmentRole,
      },
      function (result, value, key) {
        //console.log(value, key);
        let numCells;
        if (_.isObject(value)) {
          numCells = _.size(value) || 1;
        } else {
          numCells = 1;
        }
        return {
          ...result,
          classYearMonth: 1,
          [key]: numCells,
        };
      },
      {}
    ) as AlternatingCellsCountBySectionResult;

    // TODO: refactor to one reduce
    const accumulated: AlternatingCellsCountBySectionResult = {
      classYearMonth: 0,
      lessons: bySection.classYearMonth,
      coaches: bySection.classYearMonth + bySection.lessons,
      quota: bySection.classYearMonth + bySection.lessons + bySection.coaches,
      pricing:
        bySection.classYearMonth +
        bySection.lessons +
        bySection.coaches +
        bySection.quota,
      totalPrice:
        bySection.classYearMonth +
        bySection.lessons +
        bySection.coaches +
        bySection.quota +
        bySection.pricing,
      venues:
        bySection.classYearMonth +
        bySection.lessons +
        bySection.coaches +
        bySection.quota +
        bySection.pricing +
        bySection.totalPrice,
      enrolmentDates:
        bySection.classYearMonth +
        bySection.lessons +
        bySection.coaches +
        bySection.quota +
        bySection.pricing +
        bySection.totalPrice +
        bySection.venues,
      enrolmentRole:
        bySection.classYearMonth +
        bySection.lessons +
        bySection.coaches +
        bySection.quota +
        bySection.pricing +
        bySection.totalPrice +
        bySection.venues +
        bySection.enrolmentDates,
    };
    return accumulated;
  }, [programClass]);

  return (
    <div>
      <Table sx={{ borderBottom: "1px #d8d8d8 solid" }}>
        <TableRow>
          <DataCell colSpan={isMobileScreen ? 1 : 2} count={0}>
            {dayjs(classYearMonth).tz().format("MMM")}
          </DataCell>
        </TableRow>
        {/**date and time */}
        <TableRow>
          <HeaderCell rowSpan={isMobileScreen ? 1 : lessons.length + 1}>
            {t("monthlyProgramDetail.textDateTime")}
          </HeaderCell>
        </TableRow>
        {lessons.map((lesson, idx) => {
          const dayOfWeek = dayjs(lesson.date, "DD/MM/YYYY")
            .tz("Asia/Hong_Kong", true).format("ddd")
            .toLowerCase();
          return (
            <TableRow key={idx}>
              <DateCell count={alternatingCellsCountBySection.lessons + idx}>
                {t(`common:dateFormat.isoWeekday.${dayOfWeek}`)} {lesson.date}{" "}
                {lesson.startTime}-{lesson.endTime}
              </DateCell>
            </TableRow>
          );
        })}
        {/**coach */}
        <TableRow>
          <HeaderCell rowSpan={isMobileScreen ? 1 : coaches.length + 1}>
            {t("monthlyProgramDetail.textCoach")}
          </HeaderCell>
        </TableRow>
        {coaches.map((coach, idx) => {
          return (
            <TableRow key={idx}>
              <DataCell count={alternatingCellsCountBySection.coaches + idx}>
                {getLocalizedString(coach.coach_name_en, coach.coach_name_zh)}
              </DataCell>
            </TableRow>
          );
        })}
        {/**Student */}
        <ResponsiveUI
          desktop={
            <TableRow>
              <HeaderCell>{t("monthlyProgramDetail.textStudent")}</HeaderCell>
              <DataCell count={alternatingCellsCountBySection.quota}>
                {t("booking:venue.capacity_people", { capacity: quota })}
              </DataCell>
            </TableRow>
          }
          mobile={
            <>
              <TableRow>
                <HeaderCell>{t("monthlyProgramDetail.textStudent")}</HeaderCell>
              </TableRow>
              <TableRow>
                <DataCell count={alternatingCellsCountBySection.quota}>
                  {t("booking:venue.capacity_people", { capacity: quota })}
                </DataCell>
              </TableRow>
            </>
          }
        />
        {/**Fee */}
        <ResponsiveUI
          desktop={
            <>
              <TableRow>
                <HeaderCell>{t("monthlyProgramDetail.textFee")}</HeaderCell>
                <DataCell count={alternatingCellsCountBySection.pricing}>
                  {t("booking:bookingDetail.pricePerLesson", {
                    price: pricing,
                  })}
                </DataCell>
              </TableRow>
              <TableRow>
                <HeaderCell></HeaderCell>
                <DataCell count={alternatingCellsCountBySection.totalPrice}>
                  {t("monthlyProgramDetail.totalPrice", {
                    price: totalPrice,
                  })}
                </DataCell>
              </TableRow>
            </>
          }
          mobile={
            <>
              <TableRow>
                <HeaderCell>{t("monthlyProgramDetail.textFee")}</HeaderCell>
              </TableRow>
              <TableRow>
                <DataCell count={alternatingCellsCountBySection.pricing}>
                  {t("booking:bookingDetail.pricePerLesson", {
                    price: pricing,
                  })}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell count={alternatingCellsCountBySection.totalPrice}>
                  {t("booking:bookingDetail.totalPrice", {
                    price: totalPrice,
                  })}
                </DataCell>
              </TableRow>
            </>
          }
        />
        {/**Venue */}
        <TableRow>
          <HeaderCell rowSpan={isMobileScreen ? 1 : venues.length + 1}>
            {t("monthlyProgramDetail.textVenue")}
          </HeaderCell>
        </TableRow>
        {programClass.venues.map((venue, idx) => {
          return (
            <TableRow key={idx}>
              <DataCell count={alternatingCellsCountBySection.venues + idx}>
                {getLocalizedString(venue.name_en, venue.name_tc)}
              </DataCell>
            </TableRow>
          );
        })}
        {/**enrollment start */}
        <TableRow>
          <HeaderCell
            rowSpan={isMobileScreen ? 1 : _.size(formattedEnrollmentDates) + 1}
          >
            {t("monthlyProgramDetail.enrollmentDate")}
          </HeaderCell>
        </TableRow>
        {Object.keys(formattedEnrollmentDates).map((key, idx) => {
          return (
            <TableRow key={idx}>
              <DateCell
                count={alternatingCellsCountBySection.enrolmentDates + idx}
              >
                {`${t(
                  `monthlyProgramDetail.enrollRole.${EEnrolmentRole[key as keyof IEnrollmentDate]
                  }`
                )}: ${formattedEnrollmentDates[key as keyof IEnrollmentDate]}`}
              </DateCell>
            </TableRow>
          );
        })}

        {/**enrolment role */}
        {enrolmentRole && (
          <ResponsiveUI
            desktop={
              <TableRow>
                <HeaderCell>
                  {t("monthlyProgramDetail.enrollmentRole")}
                </HeaderCell>
                <DataCell count={alternatingCellsCountBySection.enrolmentRole}>
                  {t(`monthlyProgramDetail.enrollRole.${enrolmentRole}`)}
                </DataCell>
              </TableRow>
            }
            mobile={
              <>
                <TableRow>
                  <HeaderCell>
                    {t("monthlyProgramDetail.enrollmentRole")}
                  </HeaderCell>
                </TableRow>
                <TableRow>
                  <DataCell
                    count={alternatingCellsCountBySection.enrolmentRole}
                  >
                    {t(`monthlyProgramDetail.enrollRole.${enrolmentRole}`)}
                  </DataCell>
                </TableRow>
              </>
            }
          />
        )}
      </Table>

      <div
        className={cn(
          isMobileScreen
            ? "pt-4 pb-10 text-center"
            : "pt-4 pb-10  mr-16 text-end"
        )}
      >
        <ProgramClassEnrollButton
          programClass={programClass}
          onClickEnroll={(e) => onClickEnroll(programClass)}
          isLogin={!_.isEmpty(currentUser)}
        />
      </div>
    </div>
  );
};

export default ProgramClassTable;
