import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import BaseModal from "components/common/Modal/BaseModal";
import GeneralButton from "components/common/Button";
import ModalCard from "components/common/Modal/ModalCard";
import { ReactComponent as CrossSvg } from "../../../assets/white-cross.svg";
import Styled from "./Styled";
import { HeadlineNews } from "models";
import Carousel from "components/common/Carousel";
import HTMLTypography from "components/common/HTMLTypography";
import HeadlineCarousel from "components/common/carousel/HeadlineCarousel";
import Checkbox from "components/common/Checkbox";
import theme from "utils/theme";

type Props = {
  //confirmCallback: (() => void | Promise<void>) | null;
  //closeModal: () => void;
  headlineNews: HeadlineNews[];
};

const HeadlineNewsModal = ({ headlineNews }: Props) => {
  const { t } = useTranslation("home");
  const [open, setOpen] = useState(false);
  const [notShowChecked, setNotShowChecked] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("defaultShowHeadlineNews")) {
      localStorage.setItem("defaultShowHeadlineNews", "true");
    }

    const shouldOpen =
      localStorage.getItem("defaultShowHeadlineNews") === "true" &&
      headlineNews.length > 0;
    if (shouldOpen) {
      setOpen(true);
    }
  }, [headlineNews]);

  const closeModal = () => {
    setOpen(false);
    if (notShowChecked) {
      return localStorage.setItem("defaultShowHeadlineNews", "false");
    }
  };

  return (
    <BaseModal open={open} onClose={closeModal}>
      <Styled.CustomModalCard>
        <div className="h-[55.8px]" />

        <div className="m-auto pl-4 md:w-10/12">
          <HeadlineCarousel items={headlineNews} />
        </div>

        <div className="h-[55.8px]" />

        <div className=" bg-[#F5F5F5] rounded-b-[29px] ">
          <div className="flex mx-auto py-8 justify-center items-center md:justify-start md:w-10/12">
            <Checkbox
              id="no-headline-checkbox"
              onChange={() => setNotShowChecked((prev) => !prev)}
            />
            <label htmlFor="no-headline-checkbox" className="text-app-orange">
              {t("headline.dontShow")}
            </label>
          </div>
        </div>
        <button
          type="button"
          onClick={closeModal}
          className={cn(
            "absolute",
            "top-[-36.04px]",
            "right-0",
            "lg:top-0",
            "lg:right-[-42px]"
          )}
        >
          <CrossSvg />
        </button>
      </Styled.CustomModalCard>
    </BaseModal>
  );
};

export default HeadlineNewsModal;
