import { CountryCode } from "libphonenumber-js";
import { SaiShaAPIFailureResponse, SaiShaAPIResponse } from "../../Common";
import { CountryOption } from "../../CountryOption";

export enum AuthTypes {
  EMAIL = "email",
  MOBILE = "mobile",
}

export enum LoginPlatforms {
  APP = "app",
  WEB = "web",
}
export interface IAuthViewmodel {
  loginWithEmail: (
    payload: IEmailLoginSubmit
  ) => Promise<{ success: boolean } | SaiShaAPIFailureResponse<object>>;
  loginWithMobile: (
    payload: IMobileLoginSubmit
  ) => Promise<{ success: boolean } | SaiShaAPIFailureResponse<object>>;
  forgetPasswordWithEmail: (
    payload: IEmailForgetPasswordFormSubmit
  ) => Promise<{ success: boolean } | SaiShaAPIFailureResponse<object>>;
  forgetPasswordWithMobile: (
    payload: IMobileForgetPasswordFormSubmit
  ) => Promise<{ success: boolean } | SaiShaAPIFailureResponse<object>>;
  changePassword: (payload: {
    old_password: string;
    new_password: string;
  }) => Promise<{ success: boolean } | SaiShaAPIFailureResponse<object>>;
}

interface IAppLoginSubmit {
  authType?: AuthTypes;
  password: string;
  platform?: LoginPlatforms;
}

export interface IEmailLoginSubmit extends IAppLoginSubmit {
  email: string;
}

export interface IMobileLoginSubmit extends IAppLoginSubmit {
  country: string;
  phoneNumber: string;
}

export interface IEmailForgetPasswordFormSubmit {
  email: string;
}

export interface IMobileForgetPasswordFormSubmit {
  country: string;
  phoneNumber: string;
}

export interface IChangePasswordFormSubmit {
  old_password: string;
  new_password: string;
}
