import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import cn from "classnames";
import saishaLogoPng from "assets/ic-shishaLogo.png";
import Form from "../../components/common/Form/Form";
import Button from "../../components/common/Button";
import Styled from "./Styled";
import { FormikHelpers } from "formik";
import { UserViewModelContext } from "../../common/viewModels/UserViewModel";
import {
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../@types/common";
import { useLoginLoading } from "./LoginScreen";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { InferType, object, string, ref } from "yup";
import Typography from "components/common/Typography";
import theme from "utils/theme";
import { appearGlobalError } from "common/context/requests/globalRequest";
import { ROUTE_BASE } from "Route";
import { validatePassword } from "common/utils/stringHelper";
import { FormHelperText } from "@mui/material";
import LogoCard from "components/common/cards/LogoCard";
import Space from "components/common/Space";

const PasswordFormYupSchema = object({
  password: string()
    .required()
    .test("valid-password", "not valid password", (value) => {
      return !!validatePassword(value);
    }),
  confirmPassword: string()
    .required()
    .oneOf([ref("password")], "Passwords must match"),
});

type ResetPassword = InferType<typeof PasswordFormYupSchema>;

export const ResetPassword = () => {
  // lang
  const { t, i18n } = useTranslation("common");

  // router
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // states
  const { setIsLoading } = useLoginLoading();
  const { resetPassword } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const [error, setError] = useState<boolean>(false);

  const initialFormState = useMemo<ResetPassword>(
    () => ({ password: "", confirmPassword: "" }),
    []
  );

  // callbacks
  const onResetPwFormSubmit = async (
    value: ResetPassword,
    helpers: FormikHelpers<ResetPassword>
  ) => {
    setIsLoading(true);
    const { password, confirmPassword } = value;
    const token = searchParams.get("token");
    if (password === confirmPassword && token) {
      setError(false);
      const result = await resetPassword({
        password,
        token,
        dispatch: globalDispatch,
      });
      if (result?.success) {
        appearGlobalError(globalDispatch, t("login:reset_password_success"));
        navigate(`/${i18n.language}/${ROUTE_BASE.AUTH}/sign-in`);
      }
    } else {
      setError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const token = searchParams.get("token");
    if (token == null) {
      navigate(`/${i18n.language}/${ROUTE_BASE.AUTH}/sign-in`);
    }
  }, [searchParams, navigate]);

  return (
    <LogoCard title={t("login:title.reset_pw")}>
      <Form
        initialValues={initialFormState}
        validationSchema={PasswordFormYupSchema}
        onSubmit={onResetPwFormSubmit}
      >
        {(formik) => (
          <Styled.FormContainer>
            <Form.Element
              id="reset_password_form"
              className={cn(
                "w-full",
                "flex",
                "flex-col",
                "items-start",
                "gap-[20px]",
                "lg:max-xl:gap-[15px]"
              )}
            >
              <Form.Field.TextField
                id="password"
                name="password"
                type="password"
                placeholder={t("login:field.password")}
              />
              {formik.errors.password && formik.touched.password && (
                <FormHelperText error>
                  {t("register:err.invalid_password")}
                </FormHelperText>
              )}
              <Form.Field.TextField
                id="confirm_password"
                name="confirmPassword"
                type="password"
                placeholder={t("login:field.confirm_pw")}
              />

              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <FormHelperText error>
                    {t("register:err.invalid_confirm_password")}
                  </FormHelperText>
                )}

              <div className="w-full mt-[30px] lg:mt-[24px] xl:mt-[53.8px]">
                <Button
                  fullWidth
                  type="submit"
                  disabled={
                    !formik.dirty || !formik.isValid || formik.isSubmitting
                  }
                >
                  {t("button.save")}
                </Button>
              </div>
            </Form.Element>
          </Styled.FormContainer>
        )}
      </Form>
    </LogoCard>
  );
};
