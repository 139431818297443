import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import cn from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import ProfileImage from "components/common/images/ProfileImage";
import Image from "components/common/Image";
import Typography from "components/common/Typography";
import { getLocalizedString } from "utils/locale";
import theme from "utils/theme";
import { ROUTE_BASE } from "Route";
import { IUserViewModelContext } from "../../@types/common";
import IconNotification from "../../assets/ic-navBar-notification.svg";
import IconNotificationRed from "../../assets/ic-navBar-notification-red.svg";
import IconGuest from "../../assets/bg-avatar-placeholder.png";
import IconNews from "../../assets/ic-navBar-news.svg";
import { ReactComponent as NavToggleSvg } from "../../assets/nav-mobile-toggle.svg";
import NavPageOverlay from "./NavPageOverlay";
import LoginIconButton from "./LoginIconButton";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { IGlobalProviderContext } from "common/models/Common";
import { SportBistroUrl } from "./DesktopList";
import IconNavbarArrow from "assets/navbar-arrow-white.svg";

type Props = {
  hvNewMessage?: boolean;
  className?: React.HTMLProps<HTMLElement>["className"];
};

enum Page {
  LIST = 0,
  BOOKING = 1,
}

type MobileViewPage<P = object> = React.FC<
  P & {
    id?: string;
    page: Page;
    setPage: (page: Page) => void;
    closeNavBar: () => void;
  }
>;

const PageListingContent: MobileViewPage<{
  hvNewMessage: boolean;
  id?: string;
  currentUser: any;
  logout: () => void;
}> = (props) => {
  const { t, i18n } = useTranslation("navBar");
  const { page, hvNewMessage, setPage, currentUser, closeNavBar, logout } =
    props;
  const navigate = useNavigate();
  const pathname = location.pathname;

  const _bindCloseNavbar = useCallback(
    (fn: () => void) => {
      return () => {
        fn();
        closeNavBar();
      };
    },
    [closeNavBar]
  );

  const ITEM_LIST = useMemo(() => {
    return currentUser
      ? [
          { key: "mypass", url: ROUTE_BASE.MY_PASS },
          { key: "myActivity", url: ROUTE_BASE.MY_BOOKINGS },
          // { key: "sportPass", url: ROUTE_BASE.SPORT_PASS },
          { key: "news", url: ROUTE_BASE.NEWS },
          // { key: "packageOffer", url: ROUTE_BASE.PACKAGE_OFFER },
        ]
      : [{ key: "news", url: ROUTE_BASE.NEWS }];
  }, [currentUser]);

  const handleChangeLanguage = (lang: string) => {
    localStorage.setItem("language", lang);
    navigate(`/${lang}/${pathname.split("/").slice(2).join("/")}`);
  };

  return (
    <React.Fragment>
      <div className="w-full h-full flex flex-col justify-between">
        <div className="w-full p-[30px_31px_83px_35px] flex-flex-col items-center divide-y divide-solid divide-white ">
          <div className="mb-[27px]">
            {currentUser == null ? (
              <Link to="/profile">
                <button className="w-full h-[51.38px]">
                  <ProfileImage
                    width="64px"
                    height="64px"
                    style={{ cursor: "pointer" }}
                    onClick={closeNavBar}
                    src={IconGuest}
                  />
                </button>
              </Link>
            ) : (
              <div className="flex gap-[14px]">
                <Link to={"profile"}>
                  <ProfileImage
                    width="64px"
                    height="64px"
                    style={{ cursor: "pointer" }}
                    onClick={closeNavBar}
                    src={currentUser?.signed_profile_pic_ref ?? IconGuest}
                  />
                </Link>
                <div className="flex-col">
                  <div className="w-full text-xl font-medium leading-[18px] tracking-[0px] text-left">
                    {getLocalizedString(
                      currentUser.name_en,
                      currentUser.name_zh
                    )}
                  </div>
                  <div className="h-[12px]" />
                  {currentUser && (
                    <Link to={ROUTE_BASE.NOTIFICATION} onClick={closeNavBar}>
                      <Image
                        src={
                          hvNewMessage ? IconNotificationRed : IconNotification
                        }
                      />
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="h-[26px]" />
            <Link to={ROUTE_BASE.HEADLINE_NEWS} onClick={closeNavBar}>
              <Image src={IconNews} height="35px" width="35px" />
            </Link>
            <div className="h-[26px]" />
            <div className="flex flex-col items-start gap-[26px]">
              {ITEM_LIST.map((item) => {
                return (
                  <Link key={item.url} to={item.url} onClick={closeNavBar}>
                    <p className="text-lg font-poppins font-medium leading-6 tracking-[0px] text-center">
                      {item.key === "mypass"
                        ? t("common:tab.my_pass")
                        : t(`topBar.${item.key}`)}
                    </p>
                  </Link>
                );
              })}

              <div
                style={{ marginRight: 20 }}
                onClick={() => setPage(Page.BOOKING)}
              >
                <div className="flex items-center cursor-pointer">
                  <p className="text-lg font-poppins font-medium leading-6 tracking-[0px] text-center mr-3">
                    {t(`topBar.booking`)}
                  </p>
                  <img src={IconNavbarArrow} className="h-[12px] w-[7px]" />
                </div>
              </div>
            </div>
            <div className="h-[26px]" />
            <Link to={ROUTE_BASE.ENQUIRY} onClick={closeNavBar}>
              <Typography
                fontSize="18px"
                fontWeight="500"
                className="cursor-pointer"
                color={theme.white}
              >
                {t(`topBar.enquiry`)}
              </Typography>
            </Link>
            <div className="h-[26px]" />
            <div className="flex">
              <Typography
                fontSize="18px"
                className="cursor-pointer"
                fontWeight="500"
                color={i18n.language === "en" ? theme.black : theme.white}
                onClick={() => handleChangeLanguage("en")}
              >
                English
              </Typography>
              <Typography color={theme.white} style={{ margin: "0 5px" }}>
                |
              </Typography>
              <Typography
                fontSize="18px"
                className="cursor-pointer"
                fontWeight="500"
                color={i18n.language === "zh" ? theme.black : theme.white}
                onClick={() => handleChangeLanguage("zh")}
              >
                中文
              </Typography>
            </div>
          </div>
        </div>
        {currentUser != null && (
          <button
            type="button"
            className={cn(
              "w-full",
              "h-[83px]",
              "self-end",
              "bg-[#DC6840]",
              "flex-shrink-0",
              "grid",
              "place-items-center"
            )}
            onClick={_bindCloseNavbar(logout)}
          >
            <p className="text-[22px] font-medium leading-[18px] tracking-[0px] text-left">
              {t("profile:index.logout")}
            </p>
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

const PageBookingContent: MobileViewPage = (props) => {
  const { page, setPage, closeNavBar } = props;
  const { t, i18n } = useTranslation("navBar");
  const locale = i18n.language as keyof typeof SportBistroUrl;

  const ITEM_LIST = useMemo(
    () => [
      { key: "facilityBooking", url: ROUTE_BASE.FACILITY_BOOKING },
      { key: "privateCoaching", url: ROUTE_BASE.PRIVATE_COACH },
      { key: "monthlyProgram", url: ROUTE_BASE.MONTHLY_PROGRAMME },
      { key: "events", url: ROUTE_BASE.EVENT },
      {
        key: "sportsBistro",
        url: SportBistroUrl?.[locale] || SportBistroUrl.en,
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div
        className="w-full h-full flex-flex-col 
        items-center divide-y divide-solid divide-white"
      >
        <div className="flex flex-col">
          <div className="w-full flex items-center gap-[15px] p-[40px]">
            <button type="button" onClick={() => setPage(Page.LIST)}>
              <svg
                width="15"
                height="24"
                viewBox="0 0 15 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.0449 2.04688L2.99902 12.0928L13.0449 22.1387"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <p className="text-[22px] font-medium leading-6 tracking-[0px] text-left">
              {t(`topBar.booking`)}
            </p>
          </div>
          {ITEM_LIST.map((option) => {
            const target = option.key === "sportsBistro" ? "_blank" : "_self";
            return (
              <Link
                to={option.url}
                target={target}
                key={option.key}
                onClick={closeNavBar}
                className="group p-[18px_70px] cursor-pointer hover:bg-[#F4672B]"
              >
                <div className="flex items-center min-w-[300px]">
                  <p className="text-[18px] font-medium leading-6 tracking-[0px] mr-3">
                    {t(`topBar.${option.key}`)}
                  </p>
                  <img src={IconNavbarArrow} className="h-[12px] w-[7px]" />
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

const MobileNavList = (props: Props) => {
  const { className, hvNewMessage } = props;
  const { globalDispatch } = useContext(
    GlobalContextStore as any
  ) as IGlobalProviderContext;
  const { currentUser, logout } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState<Page>(0);

  const closeNavBar = useCallback(() => {
    setOpen(false);
    setPage(Page.LIST);
  }, [setOpen, setPage]);

  // silly approach to disable body scroll when menu is open
  useEffect(() => {
    if (open === true) {
      document
        .querySelectorAll("body")
        .forEach((body) => (body.style.overflow = "hidden"));
    } else {
      closeNavBar();
      document
        .querySelectorAll("body")
        .forEach((body) => (body.style.overflow = "auto"));
    }

    return () => {
      document
        .querySelectorAll("body")
        .forEach((body) => (body.style.overflow = "auto"));
    };
  }, [open, closeNavBar]);

  return (
    <div className={className}>
      <button type="button" onClick={() => setOpen((prev) => !prev)}>
        <NavToggleSvg />
      </button>
      {open && (
        <React.Fragment>
          <NavPageOverlay onClick={closeNavBar} />
          <div
            className={cn(
              "block",
              "bg-app-orange",
              "fixed",
              "w-[300px]",
              "h-screen",
              "z-[1000]",
              "overflow-y-auto",
              "rounded-[20px_0_0px_20px]",
              "right-0",
              "top-0",
              "text-white",
              "animate-show-navbar-horizontal" // animation from tailwind config
            )}
          >
            <div className="w-full h-full relative overflow-x-hidden">
              <div
                className={cn(
                  "w-[300px] h-full absolute top-0 left-0",
                  page === Page.LIST ? "" : `-translate-x-[300px]`,
                  "transition duration-150"
                )}
              >
                <PageListingContent
                  page={page}
                  setPage={setPage}
                  currentUser={currentUser}
                  hvNewMessage={hvNewMessage ?? false}
                  logout={() => logout(globalDispatch)}
                  closeNavBar={closeNavBar}
                />
              </div>
              <div
                className={cn(
                  "w-[300px] h-content absolute top-0 left-0",
                  page === Page.BOOKING ? "" : "translate-x-[300px]",
                  "transition duration-150"
                )}
              >
                <PageBookingContent
                  page={page}
                  setPage={setPage}
                  closeNavBar={closeNavBar}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default MobileNavList;
