import cn from "classnames";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import dayjs from "common/utils/dayjs";
import GeneralButton from "components/common/Button";
import BaseModal, { BaseModalProps } from "components/common/Modal/BaseModal";
import ModalCard from "components/common/Modal/ModalCard";
import SecondaryButton from "components/common/SecondaryButton";
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  IGlobalProviderContext,
  SaiShaAPIResponse,
} from "../../../@types/common";
import { ReactComponent as CrossSvg } from "../../../assets/white-cross.svg";
import Styled from "./Styled";
import { ProgramBookingLesson } from "models/Programme";
import { ProgrammeLessonAttendanceStatus } from "common/models/MonthlyProgram";
import { useUploadFile } from "hooks/useUploadFile";
import PhotoUploadCard from "components/common/PhotoUploadCard/PhotoUploadCard";
import { getLocalizedString } from "utils/locale";

export enum SickLeaveModalOpenState {
  REQUESTING_SICK_LEAVE = "REQUESTING_SICK_LEAVE",
  REQUESTING_DOCTOR_PROOF = "REQUESTING_DOCTOR_PROOF",
  UPLOAD_DOCTOR_PROOF = "UPLOAD_DOCTOR_PROOF",
  SUCCESS_UPLOAD_PROOF = "SUCCESS_UPLOAD_PROOF",
}

type Props = {
  onRequestSickLeave: (
    lessonId: string,
    url?: string
  ) => Promise<SaiShaAPIResponse<object>>;
  onClose: () => void;
  lesson: ProgramBookingLesson;
};

const ProgramSickLeaveModal = (props: Props & BaseModalProps) => {
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { requestPresignedURLByFile } = useUploadFile();
  const { onRequestSickLeave, onClose, lesson, ...modalProps } = props;
  const { t } = useTranslation("myBooking");
  const [documentUrl, setDocumentUrl] = useState<string | null>(null);
  const [documentFile, setDocumentFile] = useState<File | null>(null);

  const handleUploadToS3 = (url: string, file: File) => {
    setDocumentUrl(url);
    setDocumentFile(file);
  };

  const handleUploadDoctorProof = async () => {
    console.log(
      "🚀 ~ handleUploadDoctorProof ~ handleUploadDoctorProof:",
      documentUrl,
      openState
    );
    if (
      documentUrl &&
      openState === SickLeaveModalOpenState.UPLOAD_DOCTOR_PROOF
    ) {
      const res = await requestPresignedURLByFile(
        documentFile as File,
        documentUrl as string
      );

      if (res?.success) {
        onRequestSickLeave(lesson._id, res?.ref_url).then((result) => {
          console.log("🚀 ~ onRequestSickLeave ~ result:", result);
          if (result?.success === false) {
            // api returned error
            setLocalModalOpenState(null);
          } else {
            setLocalModalOpenState(
              SickLeaveModalOpenState.SUCCESS_UPLOAD_PROOF
            );
          }
        });
      }
    }
  };

  const ModalState: Record<
    SickLeaveModalOpenState,
    { title?: string; body?: ReactElement; footer?: ReactElement }
  > = {
    [SickLeaveModalOpenState.REQUESTING_SICK_LEAVE]: {
      title: t("detail.sickLeave.uploadTitle"),
      body: (
        <div>
          {t("detail.sickLeave.requestDesc", {
            lessonInfo: `${dayjs(lesson.startTime).tz().format(
              "YYYY-MM-DD"
            )}, ${dayjs(lesson.startTime).tz().format("HH:mm")}-${dayjs(
              lesson.endTime
            ).tz().format("HH:mm")}, ${lesson.venues.map((v) =>
              getLocalizedString(v.name_en, v.name_tc)
            )}`,
          })}
        </div>
      ),
      footer: (
        <>
          <GeneralButton
            onClick={() =>
              setLocalModalOpenState(
                SickLeaveModalOpenState.UPLOAD_DOCTOR_PROOF
              )
            }
          >
            {t("detail.sickLeave.upload")}
          </GeneralButton>
        </>
      ),
    },
    [SickLeaveModalOpenState.REQUESTING_DOCTOR_PROOF]: {
      title: t("detail.sickLeave.uploadTitle"),
      body: (
        <div>
          {t("detail.sickLeave.uploadDesc", {
            lessonInfo: `${dayjs(lesson.startTime).tz().format(
              "YYYY-MM-DD"
            )}, ${dayjs(lesson.startTime).tz().format("HH:mm")}-${dayjs(
              lesson.endTime
            ).tz().format("HH:mm")}`,
          })}
        </div>
      ),
      footer: (
        <>
          <GeneralButton
            onClick={() =>
              setLocalModalOpenState(
                SickLeaveModalOpenState.UPLOAD_DOCTOR_PROOF
              )
            }
          >
            {t("detail.sickLeave.upload")}
          </GeneralButton>
        </>
      ),
    },
    [SickLeaveModalOpenState.UPLOAD_DOCTOR_PROOF]: {
      title: t("detail.sickLeave.uploadTitle"),
      body: (
        <PhotoUploadCard
          cardCaption={t("register:placeholder.upload_photo")}
          imageUrl={documentUrl}
          height={200}
          onImageUrlChange={handleUploadToS3}
        />
      ),
      footer: (
        <GeneralButton onClick={handleUploadDoctorProof}>
          {t("common:button.submit")}
        </GeneralButton>
      ),
    },
    [SickLeaveModalOpenState.SUCCESS_UPLOAD_PROOF]: {
      body: <div>{t("detail.sickLeave.waitingApproval")}</div>,
      footer: (
        <GeneralButton onClick={onClose}>{t("common:button.ok")}</GeneralButton>
      ),
    },
  };
  const getInitialOpenState = () => {
    if (lesson.attendance?.status === ProgrammeLessonAttendanceStatus.Pending)
      return SickLeaveModalOpenState.REQUESTING_SICK_LEAVE;
    if (
      lesson.attendance?.status ===
      ProgrammeLessonAttendanceStatus.SickLeavePending &&
      !lesson.attendance?.sickLeave?.documentLink
    )
      return SickLeaveModalOpenState.REQUESTING_DOCTOR_PROOF;
    if (
      lesson.attendance?.status ===
      ProgrammeLessonAttendanceStatus.SickLeavePending &&
      lesson.attendance?.sickLeave
    )
      return SickLeaveModalOpenState.SUCCESS_UPLOAD_PROOF;
    return SickLeaveModalOpenState.REQUESTING_SICK_LEAVE;
  };
  const [openState, setLocalModalOpenState] =
    useState<SickLeaveModalOpenState | null>(getInitialOpenState());
  const [loading, setLoading] = useState(false);

  const cleanModal = useCallback(() => {
    onClose();
    setLocalModalOpenState(null);
    setLoading(false);
  }, [modalProps]);

  return openState ? (
    <BaseModal {...modalProps}>
      <Styled.CustomModalCard>
        <div className="w-full flex flex-col items-center h-full px-8 md:px-16 py-12 gap-9">
          {ModalState[openState].title && (
            <>
              <Styled.Title>{ModalState[openState].title}</Styled.Title>
            </>
          )}

          {ModalState[openState].body && (
            <Styled.ModalBody className="flex-1 w-full">
              {ModalState[openState].body}
            </Styled.ModalBody>
          )}
          <div
            className={cn(
              "flex flex-col",
              "lg:justify-center",
              "button-group",
              "gap-4",
              "w-full"
            )}
          >
            {ModalState[openState].footer}
          </div>
        </div>

        <button
          type="button"
          onClick={cleanModal}
          className={cn(
            "absolute",
            "top-[-36.04px]",
            "right-0",
            "lg:top-0",
            "lg:right-[-42px]"
          )}
        >
          <CrossSvg />
        </button>
      </Styled.CustomModalCard>
    </BaseModal>
  ) : (
    <></>
  );
};

export default ProgramSickLeaveModal;
