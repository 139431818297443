import { useEffect, useState } from "react";
import {
  getGolfMapByFacility,
  listVenueByFacility,
} from "../../network/facility";
import { GolfVenueOptions } from "../../models/Facility";
import i18n from "../../../i18n";
import { useCommonFetcher } from "../Common/CommonViewModel";
import { Venue } from "../../models/Venue";
import { GolfMap } from "../../models/GolfMap";

export const useGolfMapViewModel = ({ facilityId = "" }) => {
  const { fetcher } = useCommonFetcher();

  // Golf maps
  const [golfMaps, setGolfMaps] = useState([]);
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [curMapIndex, setCurMapIndex] = useState(0);

  useEffect(() => {
    getGolfMap();
  }, []);

  const getGolfMap = async () => {
    const res = await fetcher({
      api: () => getGolfMapByFacility({ facilityId }),
      skipErrorHandle: true,
    });
    console.log("Get Golf Map: ", res);
    setGolfMaps(res?.result?.maps?.map((map) => new GolfMap(map)) ?? []);
  };

  const toogleGolfMapModel = (visible) => {
    setMapModalVisible(visible);
  };

  const onPressPrevious = () => {
    // handle negative reminder issue
    let newIndex =
      curMapIndex - 1 < 0 ? curMapIndex - 1 + golfMaps.length : curMapIndex - 1;
    setCurMapIndex(newIndex % golfMaps.length);
  };

  const onPressNext = () => {
    setCurMapIndex((curMapIndex + 1) % golfMaps.length);
  };

  return {
    golfMaps,
    mapModalVisible,
    curMapIndex,
    toogleGolfMapModel,
    onPressPrevious,
    onPressNext,
  };
};
