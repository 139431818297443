import { authedInstance } from ".";

export const getQRCodeExpiryConfig = async () => {
  const res = await authedInstance.get("/v1/app/qrCode/timeout");
  console.log("Get QRCode Expiry Config: ", res.data);
  return res.data;
}

export const getQRCodeKey = async () => {
  const res = await authedInstance.get("/v1/app/qrCode/whisper");
  console.log("Get QRCode Key: ", res.data);
  return res.data;
};


export const getQrCodeFromApi = async ({isForced = false}) => {
  const res = await authedInstance.get(`/v1/app/qrCode/get?force=${isForced}`);
  console.log("Get QRCode Key: ", res.data);
  return res.data;
}
  
