import RegisterPageContainer from "pageComponents/register/RegisterPageContainer";
import cn from "classnames";
import VerifyMobile from "pages/RegistrationScreen/VerifyMobile";

const MobileVerification = () => {
  return (
    <RegisterPageContainer>
      <div
        className={cn(
          "h-full min-h-[90vh] flex flex-col align-center p-[36px] overflow-y-auto bg-[#fff] rounded-[15px]",
          "lg:flex-row",
          "lg:p-[15px]"
        )}
      >
        <VerifyMobile />
      </div>
    </RegisterPageContainer>
  );
};

export default MobileVerification;
