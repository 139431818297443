import React, { useMemo } from "react";
import dayjs from "common/utils/dayjs";
import { ReactComponent as BadgeActiveSvg } from "../../assets/bundle-offer-badge-active.svg";
import { ReactComponent as BadgeInActiveSvg } from "../../assets/bundle-offer-badge-inactive.svg";
import Styled from "./Styled";
import { useTranslation } from "react-i18next";
import CardBase, { VoucherListCardBaseProps } from "./CardBase";

export type CashVoucherCardProps = VoucherListCardBaseProps & {
  disableBundle: boolean;
  title: string | number;
  description?: string;
  expiredDate?: string | Date;
  redeemedDate?: string | Date;
  voucherId: string;
  onRedeemClick?: () => void;
};

const CashVoucherCard = (props: CashVoucherCardProps) => {
  const {
    disableBundle = false,
    voucherId,
    title: price,
    description,
    expiredDate,
    redeemedDate,
    onRedeemClick,
    disabled,
    ...cardBaseProps
  } = props;
  const { t } = useTranslation();

  const expiryDateInString = useMemo(() => {
    if (expiredDate == null) {
      return "";
    }
    return dayjs(expiredDate).tz().format("DD/MM/YYYY");
  }, [expiredDate]);

  const redeemedDateInString = useMemo(() => {
    if (redeemedDate == null) {
      return "";
    }
    return dayjs(redeemedDate).tz().format("DD/MM/YYYY");
  }, [redeemedDate]);

  return (
    <CardBase {...cardBaseProps} disabled={disabled}>
      <div className="content h-full flex flex-col lg:flex-row lg:justify-between">
        <div className="detail-left max-w-[400px] flex flex-col ">
          <Styled.DetailTitle className="mb-[5px]">
            {t("voucher:detail.amount", { amount: price })}
          </Styled.DetailTitle>
          {voucherId && (
            <pre>{t("voucher:detail.ref", { ref: voucherId })}</pre>
          )}
          {description && <pre>{description}</pre>}
          {disabled && (
            <p className="mt-[10px]">
              {redeemedDate == null
                ? t("voucher:detail.expiry").replace(
                  "{{date}}",
                  expiryDateInString
                )
                : t("voucher:detail.redeem").replace(
                  "{{date}}",
                  redeemedDateInString
                )}
            </p>
          )}
          <div className="h-[19px]" />
          {onRedeemClick && (
            <Styled.RedeemButton
              type="button"
              className="mb-5"
              disabled={disabled}
              onClick={onRedeemClick}
            >
              {t("voucher:button.redeem")}
            </Styled.RedeemButton>
          )}
        </div>
        <div className="detail-right flex-shrink-0 flex flex-col lg:items-end justify-between">
          {disabled === true ? null : (
            <Styled.ExpiryLabel className="mr-[15px]">
              <span>
                {/** Prevent html escape special character */}
                {t("voucher:detail.expiry").replace(
                  "{{date}}",
                  expiryDateInString
                )}
              </span>
            </Styled.ExpiryLabel>
          )}
          {disableBundle ? null : disabled ? (
            <BadgeInActiveSvg />
          ) : (
            <BadgeActiveSvg />
          )}
        </div>
      </div>
      <div className="h-[20px]" />
    </CardBase>
  );
};

export default CashVoucherCard;
