import React, { forwardRef, useEffect, useRef } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

type SelectOptionsButtonProps = {
  onEnterClassTable: () => void;
};
const SelectOptionsButton = forwardRef(
  ({
    onEnterClassTable,
  }: SelectOptionsButtonProps & React.HTMLProps<HTMLAnchorElement>) => {
    const { t } = useTranslation();
    return (
      <a href="#program_class_table">
        <button
          className={cn(
            "md:hidden",
            "w-[203px] h-[57px] rounded-[50px] bg-white shadow-[0px_0px_12px_0px_#00000080]",
            "fixed bottom-[174px] left-[50%] -translate-x-1/2"
          )}
          onClick={onEnterClassTable}
        >
          <p
            className={cn(
              "text-app-orange text-lg font-medium leading-[37px] tracking-[0px] text-center",
              "flex justify-center items-center gap-[9px]"
            )}
          >
            <slot> {t("booking:facilityDetail.selectOptions")}</slot>
            <svg
              width="17"
              height="19"
              viewBox="0 0 17 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 10.1777L8.91534 17.0931L15.8307 10.1777"
                stroke="#C85D38"
                stroke-width="2.06512"
                stroke-linecap="round"
              />
              <path
                d="M2 1.91602L8.91534 8.83136L15.8307 1.91602"
                stroke="#FDB39A"
                stroke-width="2.06512"
                stroke-linecap="round"
              />
            </svg>
          </p>
        </button>
      </a>
    );
  }
);

export default SelectOptionsButton;
