import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useVoucherNavigation = () => {
  const [linkedVoucherId, setLinkedVoucherId] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const voucherId = searchParams.get("voucher");
    if (voucherId != null) {
      setLinkedVoucherId(voucherId);
    }
  }, []);

  return {
    linkedVoucherId,
    setLinkedVoucherId,
  };
};
