import { authedInstance, instance } from ".";

export async function getUser() {
  const res = await authedInstance.get("/v1/user/get");
  console.log("Get user res: ", res.data, authedInstance.getUri());
  return res.data;
}

export async function getUserWithToken(token) {
  const res = await instance.get("/v1/user/get",{
    headers: {
      Authorization: 'Bearer ' + token 
    }
   });
  console.log("Get user res: ", res.data, instance.getUri());
  return res.data;
}

export async function getUserWithSession(sessionId) {
  const res = await authedInstance.get("/v1/user/get", { sessionId });
  console.log("Get user res: ", res.data, authedInstance.getUri());
  return res.data;
}