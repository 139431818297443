import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";

import { useDropzone } from "react-dropzone";
import avatarBgPng from "../../assets/bg-avatar-placeholder.png";
import avatarAddPng from "../../assets/avatar-upload-btn.png";
import { useUploadFile } from "hooks/useUploadFile";
import _ from "lodash";
import { Url } from "url";

const AvatarContainer = styled.button`
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  /* overflow: hidden; */
`;

const ProfileImage = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
`;

type Props = {
  defaultImageUrl?: string;
  title?: string;
  imageUrl?: string | null;
  onImageUrlChange: (url?: string, file?: File) => void;
};

const AvatarUploadCircle = (props: Props) => {
  const { defaultImageUrl, imageUrl, onImageUrlChange } = props;

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles?.[0];
    const targetUri = URL.createObjectURL(file);
    onImageUrlChange(targetUri, file);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    onDrop,
    useFsAccessApi: true,
  });

  return (
    <section id="upload-photo-card">
      <button type="button" {...getRootProps({ className: "dropzone" })}>
        <AvatarContainer>
          {imageUrl || defaultImageUrl ? (
            <ProfileImage
              src={
                !_.isEmpty(imageUrl) ? (imageUrl as string) : defaultImageUrl
              }
            />
          ) : (
            <img src={avatarBgPng} alt="avatar-background" />
          )}
          <img
            src={avatarAddPng}
            alt="avatar-add"
            className="absolute right-0 bottom-0 z-50 w-[32px] h-[32px]"
          />
        </AvatarContainer>
        <input {...getInputProps()} />
      </button>
    </section>
  );
};

export default AvatarUploadCircle;
