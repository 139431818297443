import { DispatchWithoutAction, useContext, useEffect, useState } from "react";
import {
  getFacilityById,
  listFacility,
  listFacilityCategory,
} from "../network/facility";
import { Facility } from "../models/Facility";
import ImageRestaurantPhoto from "../assets/img-restaurantPhoto.png";
import IconRestaurant from "../assets/ic-restaurant.png";
import BgBistro from "../assets/bg_bistro.png";
import BistroLogo from "../assets/bistro_logo.png";
import _ from "lodash";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { IGlobalProviderContext } from "common/models/Common";
import { useCommonFetcher } from "./Common/CommonViewModel";

const EXTRA_FACILITY = {
  _id: "sports_bistro",
  name_en: "SPORTS bistro",
  name_tc: "SPORTS bistro",
  thumbnail_photo_ref: ImageRestaurantPhoto,
  background_image: BgBistro,
  category_icon: IconRestaurant,
  logo: BistroLogo,
  is_extra: true,
  external_url_en: "https://book.bistrochat.com/sportsbistro-saisha?l=en_US",
  external_url_zh: "https://book.bistrochat.com/sportsbistro-saisha?l=zh_TW",
};

export const EXTRA_FACILITY_LIST = [EXTRA_FACILITY];

interface ViewmodelProps {
  enableExtraFacility?: boolean;
}

interface IGetFacilityDatail {
  facilityId: string;
}

export const useDiscoverViewModel = (
  props: ViewmodelProps = {
    enableExtraFacility: false,
  }
) => {
  const { enableExtraFacility } = props;
  const { fetcher } = useCommonFetcher();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const [facilityList, setFacilityList] = useState<Facility[]>([]);
  const [facilityDetail, setFacilityDetail] = useState<Facility>();

  useEffect(() => {
    getFacilityList();
  }, []);

  const getFacilityList = async () => {
    //error handled by display no record found
    const res = await fetcher({ api: listFacility, skipErrorHandle: true });

    if (res?.success) {
      const data = res?.result?.map(
        (facility: Facility) => new Facility(facility)
      );
      // Group golf facility logic
      const groupedFacilityList = _.uniqBy(data, (facility: Facility) => {
        if (facility?.shouldGroupByCategory) {
          return facility?.category?._id; // unique golf category
        } else {
          return facility?._id; // unique facility
        }
      });
      const extraFacility = [
        ...groupedFacilityList,
        ...EXTRA_FACILITY_LIST.map((item) => new Facility(item)),
      ];
      setFacilityList(
        enableExtraFacility ? extraFacility : groupedFacilityList
      );
    }

    return res;
  };

  const getFacilityDetail = async ({ facilityId }: IGetFacilityDatail) => {
    const res = await fetcher({ api: () => getFacilityById({ facilityId }) });
    if (res?.success) {
      const data = new Facility(res?.result);
      setFacilityDetail(data);

      return { success: true, result: data };
    }

    return res;
  };

  return {
    facilityList,
    facilityDetail,
    getFacilityList,
    getFacilityDetail,
  };
};
