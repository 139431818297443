import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useSearchParams } from "react-router-dom";
import { Venue, Timeslot } from "models/Facility";
import { IFacility } from "common/models/Facility";
import { Dayjs } from "dayjs";
import { useVoucherNavigation } from "hooks/useVoucherNavigation";
export interface IFacilityContextType {
  facility: IFacility;
  setFacility: (data: object) => void;
  venue: Venue;
  setVenue: (data: Venue) => void;
  selectedDate: string;
  setSelectedDate: (date: string | Dayjs) => void;
  selectedTimeslots: Map<string, Timeslot>;
  setSelectedTimeslots: (slot: Map<string, Timeslot>) => void;
  bookingIds: string[];
  setBookingIds: (ids: any) => void;
  linkedVoucherId: string | null;
  setLinkedVoucherId: (id: string) => void;
  selectedAttendees: number;
  setSelectedAttendees: (num: number) => void;
  selectedPurposeId: string;
  setSelectedPurposeId: (id: string) => void;
}

const FacilityScreen = () => {
  const { linkedVoucherId, setLinkedVoucherId } = useVoucherNavigation();
  const [facility, setFacility] = useState<IFacility>();
  const [venue, setVenue] = useState<Venue>();
  const [selectedDate, setSelectedDate] = useState<string[]>();
  const [selectedTimeslots, setSelectedTimeslots] = useState<
    Map<string, Timeslot>
  >(new Map());
  const [bookingIds, setBookingIds] = useState<string[]>();
  const [selectedAttendees, setSelectedAttendees] = useState<number>(1);
  const [selectedPurposeId, setSelectedPurposeId] = useState<string>("");

  return (
    <main>
      <Outlet
        context={{
          facility,
          setFacility,
          venue,
          setVenue,
          selectedDate,
          setSelectedDate,
          selectedTimeslots,
          setSelectedTimeslots,
          bookingIds,
          setBookingIds,
          linkedVoucherId,
          setLinkedVoucherId,
          selectedAttendees,
          setSelectedAttendees,
          selectedPurposeId,
          setSelectedPurposeId,
        }}
      />
    </main>
  );
};

export default FacilityScreen;
