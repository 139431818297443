import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import theme from "utils/theme";
import PageHeader from "./PageHeader";
import Typography from "./Typography";

interface PageProps {
  banner?: string;
  title?: string;
  screenHeader?: string;
  disableBg?: boolean;
  children: React.ReactNode;
}

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

const Content = styled.div<{ disableBg: boolean }>`
  background-color: ${(props) => (props.disableBg ? "none" : theme.lightCyan)};
  border-radius: 15px;
  width: 100%;
  max-width: 1280px;
  height: auto;
  padding: ${(props) =>
    props.disableBg ? "0px 20px 240px" : "50px 50px 240px"};

  @media ${breakpoint.mobile} {
    padding: ${(props) =>
      props.disableBg ? "0px 20px 200px" : "20px 20px 200px"};
  }
`;

const PageContainer = ({
  children,
  banner,
  screenHeader,
  title,
  disableBg = false,
}: PageProps) => {
  return (
    <div>
      {banner && title && <PageHeader src={banner} title={title} />}
      <Container>
        <div className="mx-[20px] h-[40px] md:h-[70px] flex items-center">
          {screenHeader && (
            <Typography fontWeight="500">{screenHeader}</Typography>
          )}
        </div>
        <Content disableBg={disableBg}>{children}</Content>
      </Container>
    </div>
  );
};

export default PageContainer;
