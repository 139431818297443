import React from "react";
import { useTranslation } from "react-i18next";
import Styled from "./Styled";
import cn from "classnames";

type Props = {
  paymentStatus: string;
  paymentMethod: string;
};

export const PaymentStatusLabel = ({
  paymentStatus,
}: {
  paymentStatus: string;
}) => {
  const { t } = useTranslation("myBooking");
  switch (paymentStatus) {
    case "completed": {
      return (
        <Styled.PaymentStatusLabel className="!bg-[#008E5B]">
          {t("detail.confirmed")}
        </Styled.PaymentStatusLabel>
      );
    }
    case "pending": {
      return (
        <Styled.PaymentStatusLabel className="!bg-[#008E5B]">
          {t("detail.pending")}
        </Styled.PaymentStatusLabel>
      );
    }
    case "confirm":
    case "confirmed": {
      return (
        <Styled.PaymentStatusLabel className="!bg-[#008E5B]">
          {t("detail.confirmed")}
        </Styled.PaymentStatusLabel>
      );
    }
    case "cancelled": {
      return (
        <Styled.PaymentStatusLabel className="!bg-[#008E5B]">
          {t("detail.cancelled")}
        </Styled.PaymentStatusLabel>
      );
    }
    default:
      return <div>{paymentStatus}</div>;
  }
};

const BookingDetailPaymentStatusCard = (props: Props) => {
  const { paymentStatus, paymentMethod } = props;
  const { t } = useTranslation("myBooking");
  return (
    <Styled.SharedCard
      className={cn(
        "w-full px-[22px] py-[22px]",
        "lg:px-[37px]",
        "lg:py-[22px]"
      )}
    >
      <div className="w-full flex flex-col">
        <div
          className={cn(
            "w-full flex flex-row flex-wrap items-center gap-4 md:gap-5"
          )}
        >
          <Styled.PaymentStatusText>
            {`${t("detail.paymentStatus")}`}
          </Styled.PaymentStatusText>
          <PaymentStatusLabel paymentStatus={paymentStatus} />
        </div>
        <div className="h-[22.67px]" />
        <div className="md:flex">
          <Styled.PaymentStatusText>
            {t("detail.paymentType")}
          </Styled.PaymentStatusText>
          <div className="md:w-[20px]" />
          <Styled.PaymentStatusText>{`${paymentMethod}`}</Styled.PaymentStatusText>
        </div>
      </div>
    </Styled.SharedCard>
  );
};

export default BookingDetailPaymentStatusCard;
