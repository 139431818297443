import { authedInstance, instance } from ".";

export async function applyPromoCode({
  code,
  bookingType,
  // orderAmount
}) {
  const res = await authedInstance.post(`v1/app/promoCode/`, {
    code,
    bookingType,
    // orderAmount,
  });

  return res.data;
}

export async function manualReleasePromoCodeTicket({ promoCodeTicketId }) {
  const res = await authedInstance.delete(
    `/v1/app/promoCode/ticket/${promoCodeTicketId}/release`
  );

  return res.data;
}

export async function checkPromoCode({ code, bookingType, orderAmount }) {
  const res = await authedInstance.post(`/v1/app/promoCode/check`, {
    code,
    bookingType,
    orderAmount,
  });

  return res.data;
}

export async function estimatePromoCodePrice({ code, orderAmount }) {
  const res = await authedInstance.post("/v1/app/promoCode/estimate", {
    code,
    orderAmount,
  });

  console.log(res);
  return res.data;
}

export async function getPaymentStatus(id) {
  const res = await instance.get(`v1/common/payment/${id}`);
  console.log("Get payment status res: ", res.data, instance.getUri());
  return res.data;
}
