import { authedInstance, instance } from ".";

export const listBookingVoucher = async (payload) => {
  const res = await authedInstance.post("/v1/app/orderVouchers", payload);
  console.log("list booking voucher: ", res.data);
  return res.data;
};

export const listAvailableVoucher = async (payload) => {
  const res = await authedInstance.get("v1/app/availableVouchers?" + new URLSearchParams(payload));
  console.log("list available voucher: ", res.data);
  return res.data;
};

export const listUnavailableVoucher = async (payload) => {
  const res = await authedInstance.get("v1/app/unavailableVouchers?" + new URLSearchParams(payload));
  console.log("list unavailable voucher: ", res.data);
  return res.data;
};

export const redeemAdmissionPass = async (payload) => {
  const res = await authedInstance.post("v1/app/admissionPass", payload);
  console.log("redeem admission voucher: ", res.data);
  return res.data;
}
