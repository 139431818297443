import { authedInstance, instance } from ".";

export async function updateProfile(payload) {
  console.log("Update Profile: ", payload);
  const res = await authedInstance.put("/v1/user/update", payload);
  console.log("Update Profile: ", res.data);
  return res.data;
}

export async function verifyUser(payload, token) {
  console.log("Update Profile: ", payload);
  let res = {};
  if (token) {
    res = await instance.put("/v1/user/update", payload, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  } else {
    res = await authedInstance.put("/v1/user/update", payload);
  }
  console.log("Update Profile: ", res.data);
  return res.data;
}

export async function removeGuardian() {
  const res = await authedInstance.put("/v1/user/removeGuardian");
  console.log("Delete Guardian:", res.data);
  return res.data;
}

export async function updateMobile(payload) {
  console.log("Update Mobile: ", payload);
  const res = await authedInstance.put("/v1/user/updateMobile", payload);

  return res.data;
}
