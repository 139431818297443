import i18n from "../../../i18n";
import { isEmptyValues } from "../../../utils/common";
import { getLocalisedString } from "../../utils/stringHelper";
import { FacilityPurpose } from "./FacilityPurpose";

export enum FacilitySportType {
  GOLF = "GF",
  PICKLEBALL = "PB",
  TENNIS = "TS",
  BASKETBALL = "BB",
  FOOTBALL = "FL",
  RUNNING = "RG",
  LACROSSE = "LC",
  CYCLING = "CY",
  ROLLER = "RR",
  PADDLE_TENNIS = "PT",
}

export interface IFacility {
  _id: string;
  booking_per_timeslot: number;
  guest_per_booking: number;
  desc_en: string;
  desc_tc: string;
  name_en: string;
  name_tc: string;
  thumbnail_photo_ref: string;
  max_age: number;
  min_age: number;
  numOfVenue: number;
  photos_ref: Array<string>;
  remarks: string;
  rules: FacilityRules[];
  tandc: TAndC;
  venue: Array<string>;
  venue_capacity: number;
  zone: string | undefined;
  open_time_en: string | undefined;
  open_time_tc: string | undefined;
  sport_type: FacilitySportType;
  category: {
    name_en: string;
    name_tc: string;
    _id: string;
    thumbnail_photo_ref: string;
  };
  category_icon: string;
  is_extra?: boolean;
  external_url_en?: string;
  external_url_zh?: string;

  combined_venue_setting: boolean;
  purpose: FacilityPurpose[] | undefined;

  isShowRefNum?: boolean;

  readonly categoryName: string | undefined;
  readonly categoryThumbnail: string | undefined;
  readonly open_time: string | undefined;
  readonly golf_open_time: string; // Hardcode open time for home page display
  readonly isMultiVenueFacility: boolean;
  readonly shouldGroupByCategory: boolean; // shd be grouped by facility category in full facility list

  getName: () => string;
  getDesc: () => string;
  isGolf: () => boolean;
  requireKeyMembers: () => boolean;
  isHeadCount: () => boolean;
}

export interface FacilityRules {
  day: "string";
  total: number;
  peak: number;
}

export interface TAndC {
  desc_en: string;
  desc_tc: string;
  title_en: string;
  title_tc: string;
}

export class Facility implements IFacility {
  _id: string;
  booking_per_timeslot: number;
  guest_per_booking: number;
  desc_en: string;
  desc_tc: string;
  name_en: string;
  name_tc: string;
  thumbnail_photo_ref: string;
  max_age: number;
  min_age: number;
  numOfVenue: number;
  photos_ref: Array<string>;
  remarks: string;
  rules: FacilityRules[];
  tandc: TAndC;
  venue: Array<string>;
  venue_capacity: number;
  zone: string | undefined;
  open_time_en: string | undefined;
  open_time_tc: string | undefined;
  sport_type: FacilitySportType;
  category: {
    name_en: string;
    name_tc: string;
    _id: string;
    thumbnail_photo_ref: string;
  };
  category_icon: string;
  is_extra?: boolean;
  external_url_en?: string;
  external_url_zh?: string;

  combined_venue_setting: boolean;
  purpose: FacilityPurpose[] | undefined;

  isShowRefNum?: boolean;

  constructor({
    _id,
    name_en,
    name_tc,
    guest_per_booking,
    venue_capacity,
    booking_per_timeslot,
    desc_en,
    desc_tc,
    open_time_en,
    open_time_tc,
    sport_type,
    thumbnail_photo_ref,
    photos_ref,
    zone,
    venue,
    numOfVenue,
    rules,
    max_age,
    min_age,
    remarks,
    tandc,
    category,
    category_icon,
    combined_venue_setting,
    purpose,
    is_extra,
    external_url_en,
    external_url_zh,
    isShowRefNum,
  }: any) {
    this._id = _id;
    this.name_en = name_en;
    this.name_tc = name_tc;
    this.guest_per_booking = guest_per_booking;
    this.venue_capacity = venue_capacity;
    this.booking_per_timeslot = booking_per_timeslot;
    this.desc_en = desc_en;
    this.desc_tc = desc_tc;
    this.open_time_en = open_time_en;
    this.open_time_tc = open_time_tc;
    this.sport_type = sport_type;
    this.thumbnail_photo_ref = thumbnail_photo_ref;
    this.photos_ref = photos_ref;
    this.zone = zone;
    this.venue = venue;
    this.numOfVenue = numOfVenue;
    this.rules = rules;
    this.max_age = max_age;
    this.min_age = min_age;
    this.remarks = remarks;
    this.tandc = tandc;
    this.category = category;
    this.category_icon = category_icon;
    this.is_extra = is_extra;
    this.external_url_en = external_url_en;
    this.external_url_zh = external_url_zh;

    this.combined_venue_setting = combined_venue_setting;

    if (!isEmptyValues(purpose)) {
      this.purpose = purpose.map(
        (facilityPurpose: any) => new FacilityPurpose(facilityPurpose)
      );
    }

    this.isShowRefNum = isShowRefNum;
  }

  getName(): string {
    return i18n.language === "en" ? this.name_en : this.name_tc;
  }

  getDesc(): string {
    return i18n.language === "en" ? this.desc_en : this.desc_tc;
  }

  isGolf() {
    return this.sport_type === FacilitySportType.GOLF;
  }

  get categoryName(): string | undefined {
    return getLocalisedString(this.category?.name_en, this.category?.name_tc);
  }

  get categoryThumbnail(): string | undefined {
    return this.category?.thumbnail_photo_ref;
  }

  get isBallGame(): boolean {
    const facilityName = String(this.name_en).toLocaleUpperCase();
    return (
      facilityName.includes("TENNIS") ||
      facilityName.includes("PADEL") ||
      facilityName.includes("PICKLEBALL") ||
      facilityName.includes("BASKETBALL")
    );
  }

  isHeadCount() {
    return this.booking_per_timeslot > 1;
  }

  requireKeyMembers() {
    const facilityName = String(this.name_en).toLocaleUpperCase();
    return false;
    // facilityName.includes("FOOTBALL")
  }

  get open_time(): string | undefined {
    return getLocalisedString(this.open_time_en, this.open_time_tc);
  }

  get golf_open_time(): string {
    return `${i18n.getFixedT(i18n.language)(
      "booking:facility.golfOpeningHour"
    )}`;
  }

  get isMultiVenueFacility(): boolean {
    return this.combined_venue_setting;
  }

  get shouldGroupByCategory(): boolean {
    // shd be grouped by facility category in full facility list
    return (
      this.sport_type === FacilitySportType.GOLF ||
      this.sport_type === FacilitySportType.CYCLING ||
      this.sport_type === FacilitySportType.RUNNING
    );
  }
}
