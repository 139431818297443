import React, {
  DispatchWithoutAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import BaseModal, { BaseModalProps } from "components/common/Modal/BaseModal";
import ModalCard from "components/common/Modal/ModalCard";
import GeneralButton from "components/common/Button";
import SecondaryButton from "components/common/SecondaryButton";
import { ReactComponent as CrossSvg } from "../../../assets/white-cross.svg";
import Styled from "./Styled";
import TextField from "../../common/Textfield";
import {
  IMonthlyProgrammeBookingViewModel,
  IMonthlyProgrammeViewModel,
} from "models/Programme";
import { appearGlobalError } from "common/context/requests/globalRequest";
import { IGlobalProviderContext } from "../../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";

export enum WaitListModalOpenState {
  CONFIRM_WAITING = "CONFIRM_WAITING",
  CONFIRM_WAITING_SUCCESS = "CONFIRM_WAITING_SUCCESS",
  REMOVE_FROM_WAITING = "REMOVE_FROM_WAITING",
  REMOVE_FROM_WAITING_SUCCESS = "REMOVE_FROM_WAITING_SUCCESS",
}

type Props = {
  onApplyWaitingList: (
    classId: string,
    dispatch: DispatchWithoutAction
  ) => Promise<{ success: boolean }>;
  onDropoutWaitingList: (classId: string) => Promise<{ success: boolean }>;
  open: boolean;
  initialOpenState?: WaitListModalOpenState;
  onClose: () => void;
  classId?: string;
};

const ProgramWaitListModal = (props: Props & BaseModalProps) => {
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { classId, onApplyWaitingList, onDropoutWaitingList, ...modalProps } =
    props;
  const { t } = useTranslation("monthlyProgram");

  const [openState, setOpenState] = useState<WaitListModalOpenState | null>(
    props.initialOpenState ?? null
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.open) {
      setOpenState(
        props.initialOpenState ?? WaitListModalOpenState.CONFIRM_WAITING
      );
    }
  }, [props.open]);

  const onConfirmAction = async () => {
    if (!classId) return;
    try {
      setLoading(true);
      if (openState === WaitListModalOpenState.CONFIRM_WAITING) {
        const result =
          onApplyWaitingList &&
          (await onApplyWaitingList(classId, globalDispatch));

        result?.success
          ? setOpenState(WaitListModalOpenState.CONFIRM_WAITING_SUCCESS)
          : cleanModal();
      } else if (openState === WaitListModalOpenState.REMOVE_FROM_WAITING) {
        const result =
          onDropoutWaitingList && (await onDropoutWaitingList(classId));
        result?.success
          ? setOpenState(WaitListModalOpenState.REMOVE_FROM_WAITING_SUCCESS)
          : cleanModal();
      }
    } catch (e) {
      // close this model and open error model
      cleanModal();
      if (e instanceof Error) {
        console.log("🚀 ~ onConfirm ~ e:", e);
        appearGlobalError(globalDispatch, e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const cleanModal = useCallback(() => {
    modalProps.onClose();
    setOpenState(null);
    setLoading(false);
  }, [modalProps]);

  return openState ? (
    <BaseModal {...modalProps}>
      <ModalCard maxHeight={300} maxWidth={632}>
        <div className="h-[100px] lg:h-[100px]" />
        <div className="w-full px-[56px]">
          <Styled.FieldTitle>
            {openState === WaitListModalOpenState.CONFIRM_WAITING_SUCCESS &&
              t("monthlyProgramDetail.modalTextOnList")}
            {openState === WaitListModalOpenState.REMOVE_FROM_WAITING_SUCCESS &&
              t("monthlyProgram:monthlyProgramDetail.modalTextDropoutSuccess")}
            {openState === WaitListModalOpenState.CONFIRM_WAITING &&
              t("monthlyProgramDetail.modalTextFullyBooked")}
            {openState === WaitListModalOpenState.REMOVE_FROM_WAITING &&
              t("monthlyProgram:monthlyProgramDetail.modalTextAlreadyOnList")}
          </Styled.FieldTitle>
        </div>
        <div className="h-[30px]" />
        <div
          className={cn(
            "w-full px-[56px] flex flex-col",
            "lg:flex-row-reverse",
            "lg:justify-center",
            "button-group"
          )}
        >
          {openState === WaitListModalOpenState.CONFIRM_WAITING_SUCCESS ||
          openState === WaitListModalOpenState.REMOVE_FROM_WAITING_SUCCESS ? (
            <>
              <div className="w-full lg:w-[133px]">
                <GeneralButton width="100%" onClick={cleanModal}>
                  {t("monthlyProgramDetail.btnOk")}
                </GeneralButton>
              </div>
            </>
          ) : (
            <>
              <div className="w-full lg:w-[133px]">
                <GeneralButton
                  width="100%"
                  type="button"
                  onClick={onConfirmAction}
                  disabled={loading}
                >
                  {openState === WaitListModalOpenState.CONFIRM_WAITING
                    ? t("monthlyProgramDetail.btnJoin")
                    : t("monthlyProgramDetail.btnConfirm")}
                </GeneralButton>
              </div>
              <div className="h-[30px] lg:h-0 lg:w-[30px]" />
              <div className="w-full lg:w-[133px]">
                <SecondaryButton width="100%" onClick={cleanModal}>
                  {t("monthlyProgramDetail.btnBack")}
                </SecondaryButton>
              </div>
              <div className="h-[42.6px] lg:hidden" />
            </>
          )}
        </div>
        <button
          type="button"
          onClick={cleanModal}
          className={cn(
            "absolute",
            "top-[-36.04px]",
            "right-0",
            "lg:top-0",
            "lg:right-[-42px]"
          )}
        >
          <CrossSvg />
        </button>
      </ModalCard>
    </BaseModal>
  ) : (
    <></>
  );
};

export default ProgramWaitListModal;
