import styled from "styled-components";

const StyledImage = styled.img<{ width?: string; height?: string }>`
  border-radius: 50px;
  width: ${(props) => props.width ?? "35px"};
  height: ${(props) => props.height ?? "35px"};
  object-fit: cover;
`;

const ProfileImage = ({ ...props }) => {
  return <StyledImage {...props} />;
};

export default ProfileImage;
