import ProfileLayout from "layouts/ProfileLayout/ProfileLayout";
import { Outlet } from "react-router-dom";

const ChangePasswordScreen = () => {
  return (
    <ProfileLayout>
      <div className="w-full flex flex-col">
        <Outlet />
      </div>
    </ProfileLayout>
  );
};

export default ChangePasswordScreen;
