import { ICoachContextType } from "models";
import React, { useEffect } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import CoachingBookingDetailPage from "./CoachingBookingDetailPage";
import _ from "lodash";
import { ROUTE_BASE } from "Route";

const CoachBookingDetailScreen = () => {
  const navigate = useNavigate();
  const { coachId } = useParams();
  const { bookingInfo } = useOutletContext<ICoachContextType>();

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ bookingInfo:", bookingInfo);
    if (_.isEmpty(bookingInfo)) {
      navigate(`${ROUTE_BASE.PRIVATE_COACH}/${coachId}/booking`);
    }
  }, [bookingInfo]);
  return bookingInfo ? <CoachingBookingDetailPage /> : <></>;
};

export default CoachBookingDetailScreen;
