import { HeadlineNews } from "models";
import TitleList from "pageComponents/headlineNews/TitleList";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { ResponsiveUI } from "utils/responsive";
import { IHeadlineNewsScreen } from "./HeadlineNewsScreen";
import Styled from "./Styled";
import { ROUTE_BASE } from "Route";
import HeadlineNewsDetail from "./HeadlineNewsDetail";
import TempLoading from "components/common/TempLoading";
import GlobalLoading from "components/common/GlobalLoading";
import { useTranslation } from "react-i18next";

const HeadlineNewsList = () => {
  const { newsId } = useParams();
  const navigate = useNavigate();
  const { headlineNews } = useOutletContext<IHeadlineNewsScreen>();
  const {t} = useTranslation('common')

  const handleClickNews = (news: HeadlineNews) => {
    navigate("../" + news._id);
  };
  return (
    <>
      <Styled.Wrapper>
        <Styled.Container>
          <ResponsiveUI
            desktop={
              <>
                <div className="grid grid-cols-[minmax(auto,340px)_1fr] h-full ">
                  <TitleList
                    headlineNews={headlineNews}
                    handleClickNews={handleClickNews}
                    className="overflow-y-scroll"
                  />
                  <HeadlineNewsDetail />
                </div>
              </>
            }
            mobile={
             headlineNews.length > 0 ? <TitleList
                headlineNews={headlineNews}
                handleClickNews={handleClickNews}
                className="h-full overflow-y-auto"
              /> : <div className="py-10 px-6">{t('comingSoon')}</div>
            }
          />
        </Styled.Container>
      </Styled.Wrapper>
    </>
  );
};

export default HeadlineNewsList;
