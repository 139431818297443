import React, { useEffect, useMemo, useState } from "react";
import Styled from "./Styled";
import { VoucherTypeSearch } from "./VoucherListPage";
import HeaderTitle from "components/common/HeaderTitle";
import { Voucher } from "models/Voucher";

import {
  AdmissionVoucherCard,
  CashVoucherCard,
  CashVoucherCardProps,
  CoachVoucherCard,
  CoachVoucherCardProps,
  FacilityVoucherCard,
  FacilityVoucherCardProps,
  PassVoucherCard,
  PassVoucherCardProps,
  useVoucherCardModel,
} from "components/VoucherListingCard";

import {
  AdmissionVoucher,
  CashVoucher,
  CoachVoucher,
  ExtractVoucherType,
  FacilityVoucher,
  PassVoucher,
} from "models/Voucher";
import { useTranslation } from "react-i18next";
import { VoucherType } from "common/viewModels/VoucherViewModel";
import { Select, SelectOption } from "components/common/Select";
import { WindowSize } from "utils/responsive";
import _ from "lodash";

type Props = {
  unavailableVouchers: Voucher[];
  refreshVoucherList: (type: string) => void;
};

const RedeemedVoucherList = ({
  unavailableVouchers,
  refreshVoucherList,
}: Props) => {
  const { t } = useTranslation();
  const { getVoucherCardProps } = useVoucherCardModel();
  const [filter, setFilter] = useState("all");

  const windowSize = WindowSize();

  const typeConditionMap = useMemo(() => {
    const isAll = filter == null || filter == "all";
    return {
      isAll: isAll,
      includeCoach: isAll || filter.match(VoucherType.COACH),
      includeCash: isAll || filter.match(VoucherType.CASH),
      // includeAdmission: isAll || filter.match(VoucherType.ADMISSION),
      includeFacility: isAll || filter.match(VoucherType.FACILITY),
      // includeDayPass: isAll || filter.match(VoucherType.DAY_PASS),
      // includeMonthlyPass: isAll || filter.match(VoucherType.MONTHLY_PASS),
      // includeAnnualPass: isAll || filter.match(VoucherType.ANNUAL_PASS),
    };
  }, [filter]);

  /**
   * Start of Filtered voucher lists (by type)
   */
  const coachFilteredList: ExtractVoucherType<CoachVoucher>[] = useMemo(() => {
    return unavailableVouchers.filter(
      (each) => each?.voucher?.type === "coach"
    ) as ExtractVoucherType<CoachVoucher>[];
  }, [unavailableVouchers]);

  const cashFilteredList: ExtractVoucherType<CashVoucher>[] = useMemo(() => {
    return unavailableVouchers.filter(
      (each) => each?.voucher?.type === "cash"
    ) as ExtractVoucherType<CashVoucher>[];
  }, [unavailableVouchers]);

  const admissionPassFilteredList: ExtractVoucherType<AdmissionVoucher>[] =
    useMemo(() => {
      return unavailableVouchers.filter(
        (each) => each?.voucher?.type === "admission"
      ) as ExtractVoucherType<AdmissionVoucher>[];
    }, [unavailableVouchers]);

  const facilityPassFilteredList: ExtractVoucherType<FacilityVoucher>[] =
    useMemo(() => {
      return unavailableVouchers.filter(
        (each) => each?.voucher?.type === "facility"
      ) as ExtractVoucherType<FacilityVoucher>[];
    }, [unavailableVouchers]);

  const dayPassFilteredList: ExtractVoucherType<PassVoucher>[] = useMemo(() => {
    return unavailableVouchers.filter(
      (each) => each?.voucher?.type === "day"
    ) as ExtractVoucherType<PassVoucher>[];
  }, [unavailableVouchers]);

  const monthlyPassFilteredList: ExtractVoucherType<PassVoucher>[] =
    useMemo(() => {
      return unavailableVouchers.filter(
        (each) => each?.voucher?.type === "monthly"
      ) as ExtractVoucherType<PassVoucher>[];
    }, [unavailableVouchers]);

  const annualPassFilteredList: ExtractVoucherType<PassVoucher>[] =
    useMemo(() => {
      return unavailableVouchers.filter(
        (each) => each?.voucher?.type === "annual"
      ) as ExtractVoucherType<PassVoucher>[];
    }, [unavailableVouchers]);

  /**
   * End of filtered voucher lists
   */

  useEffect(() => {
    refreshVoucherList(filter);
  }, [filter, refreshVoucherList]);

  return (
    <React.Fragment>
      <div className="w-full flex justify-end">
        <div className="w-full md:w-[304px] md:w-content md:flex items-center">
          <p className="text-base font-normal leading-6 tracking-[0px] text-start">
            {t("myBooking:listing.type")}
          </p>
          <Select
            className="mt-[5px] md:mt-0 md:ml-[15px]"
            sx={{ width: windowSize === "mobile" ? "100%" : 257 }}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <SelectOption value="all">
              {t("voucher:filterOption.all")}
            </SelectOption>
            {Object.values(VoucherType).map((each) => (
              <SelectOption key={each} value={each}>
                {t(`voucher:filterOption.${each}`)}
              </SelectOption>
            ))}
          </Select>
        </div>
      </div>
      <div className="h-[20px]" />
      {unavailableVouchers.length <= 0 && (
        <p>{t("myBooking:listing.noRecords")}</p>
      )}
      {typeConditionMap.includeCoach && coachFilteredList.length > 0 && (
        <Styled.CategoryWrapper>
          <HeaderTitle title={t("voucher:type.coach")} />
          <div className="h-[30px]" />
          <div className="flex flex-col gap-y-[20px]">
            {coachFilteredList.map((each) => {
              const cardProps = getVoucherCardProps(
                each
              ) as CoachVoucherCardProps;
              return (
                <CoachVoucherCard
                  {...cardProps}
                  disabled
                  key={each.voucherId}
                />
              );
            })}
          </div>
        </Styled.CategoryWrapper>
      )}

      {typeConditionMap.includeCash && cashFilteredList.length > 0 && (
        <Styled.CategoryWrapper>
          <HeaderTitle title={t("voucher:type.cash")} />
          <div className="h-[30px]" />
          <div className="flex flex-col gap-y-[20px]">
            {cashFilteredList.map((each) => {
              const cardProps = getVoucherCardProps(
                each
              ) as CashVoucherCardProps;
              return (
                <CashVoucherCard key={each.voucherId} {...cardProps} disabled />
              );
            })}
          </div>
        </Styled.CategoryWrapper>
      )}

      {/* {typeConditionMap.includeAdmission &&
        admissionPassFilteredList.length > 0 && (
          <Styled.CategoryWrapper>
            <HeaderTitle title={t("voucher:type.admission")} />
            <div className="h-[30px]" />
            <div className="flex flex-col gap-y-[20px]">
              {admissionPassFilteredList.map((each) => (
                <AdmissionVoucherCard
                  key={each.voucherId}
                  disabled
                  startDate={each?.voucher?.startDate}
                  endDate={each?.voucher?.endDate}
                  disableBundle={_.isEmpty(each?.voucher?.packageTransaction)}
                />
              ))}
            </div>
          </Styled.CategoryWrapper>
        )} */}

      {typeConditionMap.includeFacility &&
        facilityPassFilteredList.length > 0 && (
          <Styled.CategoryWrapper>
            <HeaderTitle title={t("voucher:type.facility")} />
            <div className="h-[30px]" />
            <div className="flex flex-col gap-y-[20px]">
              {facilityPassFilteredList.map((each) => {
                const cardProps = getVoucherCardProps(
                  each
                ) as FacilityVoucherCardProps;
                return (
                  <FacilityVoucherCard
                    key={each.voucherId}
                    {...cardProps}
                    disabled
                  />
                );
              })}
            </div>
          </Styled.CategoryWrapper>
        )}

      {/* {typeConditionMap.includeDayPass && dayPassFilteredList.length > 0 && (
        <Styled.CategoryWrapper>
          <HeaderTitle title={t("voucher:type.day")} />
          <div className="h-[30px]" />
          <div className="flex flex-col gap-y-[20px]">
            {dayPassFilteredList.map((each) => {
              const cardProps = getVoucherCardProps(
                each
              ) as PassVoucherCardProps;
              return (
                <PassVoucherCard key={each.voucherId} {...cardProps} disabled />
              );
            })}
          </div>
        </Styled.CategoryWrapper>
      )} */}

      {/* {typeConditionMap.includeMonthlyPass &&
        monthlyPassFilteredList.length > 0 && (
          <Styled.CategoryWrapper>
            <HeaderTitle title={t("voucher:type.monthly")} />
            <div className="h-[30px]" />
            <div className="flex flex-col gap-y-[20px]">
              {monthlyPassFilteredList.map((each) => {
                const cardProps = getVoucherCardProps(
                  each
                ) as PassVoucherCardProps;
                return (
                  <PassVoucherCard
                    key={each.voucherId}
                    {...cardProps}
                    disabled
                  />
                );
              })}
            </div>
          </Styled.CategoryWrapper>
        )} */}

      {/* {typeConditionMap.includeAnnualPass &&
        annualPassFilteredList.length > 0 && (
          <Styled.CategoryWrapper>
            <HeaderTitle title={t("voucher:type.annual")} />
            <div className="h-[30px]" />
            <div className="flex flex-col gap-y-[20px]">
              {annualPassFilteredList.map((each) => {
                const cardProps = getVoucherCardProps(
                  each
                ) as PassVoucherCardProps;
                return (
                  <PassVoucherCard
                    key={each.voucherId}
                    {...cardProps}
                    disabled
                  />
                );
              })}
            </div>
          </Styled.CategoryWrapper>
        )} */}
    </React.Fragment>
  );
};

export default RedeemedVoucherList;
