import React from "react";
import styled from "styled-components";

import InputBase from "@mui/material/InputBase";
import SelectBase, {
  SelectProps as MuiSelectProps,
} from "@mui/material/Select";
import { ReactComponent as ArrowDownSvg } from "../../../assets/select-arrow-down.svg";
import SelectOption from "./SelectOption";
import { TextFieldLabel } from "../Textfield";

type SelectProps<T> = MuiSelectProps<T> & {
  title?: string;
};

const Select = function (props: SelectProps<string>) {
  const {
    title,
    className,
    children,
    label,
    placeholder,
    error,
    required,
    ...relevantProps
  } = props;

  return (
    <div className={className}>
      {title && <TextFieldLabel>{title}</TextFieldLabel>}
      <SelectBase
        {...relevantProps}
        fullWidth
        displayEmpty
        input={<CustomSelect />}
        IconComponent={ArrowDownSvg}
        style={{ textOverflow: "initial" }}
      >
        <SelectOption value="" disabled>
          {placeholder || ""}
        </SelectOption>
        {children}
      </SelectBase>
    </div>
  );
};

const CustomSelect = styled(InputBase)`
  border-radius: 10px;
  border: 1px solid var(--palette-ss-black, #4a4a4a);
  background: var(--palette-ss-white, #fff);
  text-overflow: initial;

  & .MuiInputBase-input {
    padding: 9px 12px 12px;
    position: relative;
    width: 100%;
    vertical-align: middle;
    color: ${(props) =>
      props.value === "" ? "#a9a9a9" : "var(--palette-ss-000, #000)"};
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-overflow: initial;
  }
  & .MuiMenuItem-root {
    z-index: 9999;
    text-overflow: initial;
  }

  & .MuiSelect-icon {
    top: calc(50% - 0.3em);
    text-overflow: initial;
  }
`;

export default Select;
