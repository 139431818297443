import _ from "lodash";
import { authedInstance, instance } from ".";
import { Facility } from "../models/Facility";
import { FacilityCategory } from "../models/FacilityCategory";
import { GolfMap } from "../models/GolfMap";
import { MonthlyProgram, MonthlyProgramClass } from "../models/MonthlyProgram";
import { Timeslot } from "../models/Timeslot";
import { Venue } from "../models/Venue";

// export async function listMonthlyProgramCategory() {
//   const res = await authedInstance.get("/v1/app/facility/category");
//   console.log("List Facility Category: ", res.data);
//   return res.data.map((category) => new FacilityCategory(category));
// }

export async function listMonthlyProgram({ limit }) {
  const res = await instance.get(
    `v1/app/monthly-programme/programme?limit=${limit}`
  );
  console.log("List MonthlyProgram: ", res?.data?.result);
  return {
    result: res.data?.result?.map(
      (monthlyProgram) => new MonthlyProgram(monthlyProgram)
    ),
    total: res.data?.total,
  };
}

export async function listMonthlyProgramByCategory({
  categoryId,
  minAge,
  maxAge,
  keyword,
  weekDays,
  skip,
  limit,
  isSearchMyAge,
}) {
  const filter = { categoryId };
  if (minAge) filter.ageStart = minAge;
  if (maxAge) filter.ageEnd = maxAge;
  if (keyword) filter.name = keyword;
  if (weekDays && weekDays.length > 0) filter.weekDays = _.join(weekDays, ",");
  if (skip) filter.skip = skip;
  if (limit) filter.limit = limit;
  if (isSearchMyAge) filter.isSearchMyAge = isSearchMyAge;
  const res = await authedInstance.get(
    `v1/app/monthly-programme/programme?${new URLSearchParams(
      filter
    ).toString()}`
  );
  console.log("List MonthlyProgram By Category: ", res.data);
  return {
    result: res?.data?.result.map(
      (monthlyProgram) => new MonthlyProgram(monthlyProgram)
    ),
    total: res?.data?.total,
  };
}

export async function listClassesByProgrammeId({ programmeId }) {
  const res = await authedInstance.get(
    "v1/app/monthly-programme/programme/class?programmeId=" + programmeId
  ); //'659cc0825f11b12de1bc2ebb');
  console.log("List class By programmeId: ", res.data);
  return res?.data;
}

export async function createClassEnrollment(payload) {
  const res = await authedInstance.post(
    "v1/app/monthly-programme/programme/class/enroll?createPayment=true",
    payload
  );
  console.log("Enroll class res: ", res.data);
  return res?.data;
}

export async function createClassPayment(order_id) {
  const res = await authedInstance.post(
    `v1/app/monthly-programme/programme/class/enroll/${order_id}/payment`
  );
  return res.data;
}

export async function waitlistingClass({ classId }) {
  const res = await authedInstance.post(
    `v1/app/monthly-programme/programme/class/${classId}/waitingList`
  );
  console.log("Waitlisting class res: ", res.data);
  return res?.data?.result;
}

export async function dropoutClassWaitingList({ classId }) {
  const res = await authedInstance.delete(
    `v1/app/monthly-programme/programme/class/${classId}/waitingList`
  );
  console.log("Waitlisting class res: ", res.data);
  return res?.data;
}

export async function listEnrollment({ past = false, status }) {
  const res = await authedInstance.get(
    `v1/app/monthly-programme/programme/enroll?expired=${past}${
      status ? `&status=${status}` : ""
    }`
  );
  console.log("List enrollment res: ", res.data, authedInstance.getUri());
  return res.data;
}

export async function getProgramDetail(programId) {
  const res = await authedInstance.get(
    `/v1/app/monthly-programme/programme/${programId}`
  );
  console.log("get program detail: ", res.data, authedInstance.getUri());
  return res.data;
}

export async function requestSickLeave({ lessonId, documentLink }) {
  const res = await authedInstance.post(
    `/v1/app/monthly-programme/programme/lesson/${lessonId}/sickleave`,
    { documentLink }
  );
  console.log("request sickleave res: ", res);
  return res.data;
}

export const getPendingPaymentStatus = async (id) => {
  const res = await authedInstance.get(
    `/v1/app/monthly-programme/programme/class/userBookingStatus/${id}`
  );
  return res.data;
};
