import { FormHelperText } from "@mui/material";
import Card from "components/common/Card";
import Form from "components/common/Form";
import Typography from "components/common/Typography";
import {
  IChangePasswordPayload,
  useChangePasswordFormModel,
} from "components/form/ChangePasswordForm/ChangePasswordFormModel";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";
import cn from "classnames";
import { WindowSize } from "utils/responsive";
import Space from "components/common/Space";
import GeneralButton from "components/common/Button";
import { useAuthViewModel } from "common/viewModels/User/AuthViewModel";
import { IAuthViewmodel } from "common/models/User";
import { useNavigate } from "react-router-dom";

const ChangePasswordPage = () => {
  const { t } = useTranslation("profile");
  const navigate = useNavigate();
  const { formInitialValues, formValidateSchema } =
    useChangePasswordFormModel();
  const { changePassword } = useAuthViewModel() as IAuthViewmodel;
  const windowSize = WindowSize();
  const formRef = useRef(null);

  const onFormSubmit = async (data: IChangePasswordPayload) => {
    const payload = {
      old_password: data?.oldPassword,
      new_password: data?.newPassword,
    };

    const res = await changePassword(payload);
    if (res?.success) {
      navigate("../success");
    }
  };

  return (
    <Card
      bgColor={theme.lightCyan}
      maxHeight={"none"}
      style={{ padding: 35, width: "100%" }}
    >
      <div>
        <Typography fontSize="20px" fontWeight="500">
          {t("index.changePassword")}
        </Typography>
        <Space size="30px" />
        <Form
          innerRef={formRef}
          initialValues={formInitialValues}
          validationSchema={formValidateSchema}
          onSubmit={onFormSubmit}
        >
          {(formik) => {
            return (
              <Form.Element
                id="change_password_form"
                //   className={cn("w-full h-full pt-[24px]")}
              >
                <div
                  className={cn("w-full flex flex-col gap-[20px]", "lg:w-1/2")}
                >
                  <Form.Field.TextField
                    id="oldPassword"
                    name="oldPassword"
                    type="password"
                    title={t("changePassword.currentPassword")}
                    placeholder={t("changePassword.currentPassword")}
                    style={{
                      width: windowSize === "screen1024" ? "297px" : "100%",
                    }}
                  />
                  <Form.Field.TextField
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    title={t("changePassword.newPassword")}
                    placeholder={t("changePassword.newPassword")}
                    style={{
                      width: windowSize === "screen1024" ? "297px" : "100%",
                    }}
                  />
                  {formik.errors.newPassword && (
                    <FormHelperText error>
                      {t("register:err.invalid_password")}
                    </FormHelperText>
                  )}
                  <Form.Field.TextField
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    type="password"
                    title={t("changePassword.confirmPassword")}
                    placeholder={t("changePassword.confirmPassword")}
                    style={{
                      width: windowSize === "screen1024" ? "297px" : "100%",
                    }}
                  />
                  {formik.errors.confirmNewPassword && (
                    <FormHelperText error>
                      {t("register:err.invalid_confirm_password")}
                    </FormHelperText>
                  )}

                  <GeneralButton
                    type="submit"
                    style={{
                      width: windowSize === "screen1024" ? "297px" : "100%",
                    }}
                  >
                    {t("common:button.submit")}
                  </GeneralButton>
                </div>
              </Form.Element>
            );
          }}
        </Form>
      </div>
    </Card>
  );
};

export default ChangePasswordPage;
