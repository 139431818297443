import React from "react";
import cn from "classnames";
import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import Typography from "./Typography";
import theme from "utils/theme";

const TitleContainer = styled.div`
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media ${breakpoint.mobile} {
    width: calc(100% - 60px);
  }
`;

const GradientOverlay = styled.div`
  z-index: 50;
  border-radius: 0px 0px 80px 80px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  @media (${breakpoint.mobile}) {
    border-radius: 0px 0px 30px 30px;
  }
`;

type Props = {
  src: string;
  title?: string;
  fontSize?: number;
};
const PageHeader = ({ title, src }: Props) => {
  return (
    <div
      className={cn(
        "relative",
        "w-full",
        "max-w-[1280px]",
        "h-[106.88px]",
        "grid",
        "place-items-center",
        "md:h-[200px]",
        "md:m-auto"
      )}
    >
      <div className={cn("absolute", "bottom-0", "w-full")}>
        <img
          className={cn(
            "w-full",
            "h-[176px]",
            "object-cover",
            "rounded-bl-[30px]",
            "rounded-br-[30px]",
            "md:rounded-bl-[80px]",
            "md:rounded-br-[80px]",
            "md:h-[315px]"
          )}
          src={src}
        />
        <GradientOverlay className="absolute top-0" />
      </div>
      {title != null && (
        <TitleContainer>
          <Typography
            fontSize="40px"
            mFontSize="17px"
            fontWeight="700"
            color={theme.white}
            textAlign="center"
            style={{ fontStyle: "italic" }}
          >
            {title}
          </Typography>
        </TitleContainer>
      )}
    </div>
  );
};

export default PageHeader;
