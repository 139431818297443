import React, { useMemo } from "react";
import { string, object, InferType, ref } from "yup";
import { validatePassword } from "common/utils/stringHelper";

const changePasswordYupSchema = object({
  oldPassword: string().required(),
  newPassword: string()
    .required()
    .test("valid-password", "not valid password", (value) => {
      return !!validatePassword(value);
    }),
  confirmNewPassword: string()
    .required()
    .oneOf([ref("newPassword"), ""], "password must match"),
});

export type IChangePasswordPayload = InferType<typeof changePasswordYupSchema>;

export const useChangePasswordFormModel = (
  payload?: IChangePasswordPayload
) => {
  const formInitialValues = useMemo(
    () => ({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    }),
    [payload]
  );

  return { formInitialValues, formValidateSchema: changePasswordYupSchema };
};
