import { useState } from "react";
import { getNotificationLastRead } from "../../network/notification";

export const useNotificationViewModel = () => {
  const [hvNewMessage, setNewMessage] = useState(false)
  const getNotificationStatus = async () => { 
    try {
      const res = await getNotificationLastRead();
      if (res?.success) {
        setNewMessage(res?.result.newMessage)
      }
    } catch (e) {
      console.log("getNotificationLastRead exception", e);
    }
  };

  return {
    hvNewMessage,
    getNotificationStatus,
  };
}