import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import IconLoader from "../../assets/IconLoader";
import PageHeader from "../../components/common/PageHeader";
import FilterView from "./Components/FilterView";
import ItemBox from "./Components/ItemBox";

import { getLocalisedString } from "common/utils/stringHelper";
import { useCategoryListViewModel } from "common/viewModels/FacilityBooking/CategoryListViewModel";
import GeneralButton from "components/common/Button";
import BaseModal from "components/common/Modal/BaseModal";
import { FormikHelpers, useFormik } from "formik";
import { Category, ICategoryListViewModel } from "models";
import {
  IMonthlyProgrammeViewModel,
  ProgrammeListByCategoryFilter,
} from "models/Programme";
import { array, bool, number, object, string } from "yup";
import { IProgramOutletContextType } from "./MonthlyProgramScreen";
import { FilterIcon, Row, Wapper } from "./Styled";
import MonthlyProgramBanner from "assets/bg-monthly-program-banner.png";
import { WindowSize } from "utils/responsive";
import cn from "classnames";
import Space from "components/common/Space";
import { IGlobalProviderContext } from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { ROUTE_BASE } from "Route";
import _ from "lodash";
import PageContainer from "components/common/PageContainer";
import Typography from "components/common/Typography";
import theme from "utils/theme";
import SearchInput from "components/common/SearchInput";
import { useMonthlyProgramViewModel } from "common/viewModels/MonthlyProgramViewModel";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { Modules } from "common/models/Category";

export const MAX_NUMBER_PER_PAGE = 4;

export type ProgrammeListByCategoryFilterForm =
  ProgrammeListByCategoryFilter & {
    weekDays: number[];
  };

export type ProgrammerListPayload = ProgrammeListByCategoryFilterForm & {
  categoryId: string;
};

const FormSchema = object<ProgrammeListByCategoryFilterForm>({
  minAge: number(),
  maxAge: number(),
  isSearchMyAge: bool(),
  keyword: string(),
  weekdays: array().of(number()),
});

const formInitialValue: ProgrammeListByCategoryFilterForm = {
  weekDays: [],
};

const MonthlyProgramByCategory = () => {
  // GET /programme/?categoryId = id
  const { t } = useTranslation("monthlyProgram");
  const { id: categoryId } = useParams();
  const windowSize = WindowSize();
  const { currentUser } = useContext(UserViewModelContext);
  const [snapFilterValue, setSnapFliterValue] =
    useState<ProgrammeListByCategoryFilterForm>(formInitialValue);
  const [filterOption, setFilterOption] =
    useState<ProgrammeListByCategoryFilter>();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const {
    monthlyProgramList,
    ageLimitError,
    getMonthlyProgramListByCategory,
    setAgeLimitError,
  } = useMonthlyProgramViewModel() as IMonthlyProgrammeViewModel;
  const { categoryList, getCategoryList } =
    useCategoryListViewModel() as ICategoryListViewModel;
  const navigate = useNavigate();

  const currentCategory = categoryList?.find(
    (category) => category._id === categoryId
  );

  useEffect(() => {
    if (categoryId) {
      getCategoryList(Modules.MONTHLY_PROGRAM);
      getMonthlyProgramListByCategory(
        {
          categoryId,
          skip: 0,
          limit: undefined,
        },
        globalDispatch
      );
    }
  }, [categoryId]);

  const handerShowFilter = () => {
    setShowFilter(true);
  };

  const [showFilter, setShowFilter] = useState(true);

  const processPayload = (payload: ProgrammerListPayload) => {
    if (payload.isSearchMyAge) {
      delete payload.minAge;
      delete payload.maxAge;
    } else {
      const isMinAgeEmpty = _.isEmpty(payload.minAge);
      const isMaxAgeEmpty = _.isEmpty(payload.maxAge);
      if (isMinAgeEmpty || isMaxAgeEmpty) {
        payload.minAge = isMinAgeEmpty ? payload.maxAge : payload.minAge;
        payload.maxAge = isMaxAgeEmpty ? payload.minAge : payload.maxAge;
      }
    }
    return payload;
  };

  const onFilter = async (
    filterParam: ProgrammeListByCategoryFilterForm,
    helpers: FormikHelpers<ProgrammeListByCategoryFilterForm>
  ) => {
    if (categoryId) {
      const payload = processPayload({
        categoryId,
        ...filterParam,
        skip: 0,
        limit: undefined,
      });
      const res = await getMonthlyProgramListByCategory(
        {
          ...payload,
        },
        globalDispatch
      );

      if (res?.success) {
        setFilterOption(payload);
        setShowFilter(false);
        setSnapFliterValue(filterParam);
      }
    }
  };

  const formik = useFormik({
    initialValues: formInitialValue,
    validationSchema: FormSchema,
    onSubmit: onFilter,
  });

  return currentCategory ? (
    <PageContainer
      title={getLocalisedString(
        currentCategory.name_en,
        currentCategory.name_tc
      )}
      banner={currentCategory?.thumbnail_photo_ref ?? MonthlyProgramBanner}
      screenHeader={`${t(
        "monthlyProgram:monthlyProgramList.screenHeader"
      )} (${getLocalisedString(
        currentCategory.name_en,
        currentCategory.name_tc
      )})`}
      disableBg
    >
      <Wapper>
        <div>
          <Row
            display="flex"
            wrap="wrap"
            direction={windowSize === "mobile" ? "column" : "row"}
          >
            <SearchInput
              placeholder={t("monthlyProgram:placeholder.search")}
              name="keyword"
              value={formik.values.keyword ?? ""}
              onChange={formik.handleChange}
              onClickClear={() => {
                formik.resetForm();
                formik.handleSubmit();
              }}
            ></SearchInput>
            <div className={cn(windowSize === "mobile" ? ["flex flex-1"] : [])}>
              <GeneralButton
                type="primary"
                style={{
                  width: windowSize === "mobile" ? "100%" : "",
                  height: 50,
                  marginRight: 20,
                  borderRadius: 10,
                }}
                onClick={() => formik.handleSubmit()}
              >
                {t("monthlyProgram:placeholder.search")}
              </GeneralButton>

              <FilterIcon onClick={handerShowFilter}>
                <IconLoader.IconFilter />
              </FilterIcon>
            </div>
            <BaseModal open={showFilter}>
              <FilterView
                onCloseFilterView={() => setShowFilter(false)}
                formik={formik}
                isLoggedIn={!!currentUser}
                ageLimitError={ageLimitError}
                setAgeLimitError={setAgeLimitError}
                resetPreviousValue={() => formik.setValues(snapFilterValue)}
              />
            </BaseModal>
          </Row>
        </div>
        <ItemBox programmes={monthlyProgramList.list} />
      </Wapper>
    </PageContainer>
  ) : (
    <></>
  );
};

export default MonthlyProgramByCategory;
