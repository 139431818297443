import React, { useContext } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import BaseModal from "components/common/Modal/BaseModal";
import GeneralButton from "components/common/Button";
import ModalCard from "components/common/Modal/ModalCard";
import { ReactComponent as CrossSvg } from "../../../assets/white-cross.svg";
import Styled from "./Styled";
import Typography from "components/common/Typography";
import theme from "utils/theme";

type Props = {
  title: string;
  open: boolean;
  message?: string;
  confirmCallback: (() => void | Promise<void>) | null;
  closeModal: () => void;
};

const GeneralConformModal = (props: Props) => {
  const { t } = useTranslation("common");
  const { title, message, open, confirmCallback, closeModal } = props;

  const onConfirmWithClose = async () => {
    if (confirmCallback) {
      await confirmCallback();
      closeModal();
    }
  };

  return (
    <BaseModal open={open} onClose={closeModal}>
      <ModalCard maxWidth={480} maxHeight={300}>
        <div
          className={cn(
            "mt-[69px] px-[32px] flex flex-col justify-center items-center",
            "lg:mt-[58px]"
          )}
        >
          <Styled.Title>{title}</Styled.Title>
          {message && (
            <Typography
              fontSize="14px"
              color={theme.orange}
              style={{ marginTop: 10 }}
            >
              {message}
            </Typography>
          )}
        </div>
        <div className="h-[40px]" />
        <div className="w-full px-[32px] lg:px-[56px]">
          {confirmCallback != null && (
            <GeneralButton fullWidth type="button" onClick={onConfirmWithClose}>
              {t("button.confirm")}
            </GeneralButton>
          )}
          {confirmCallback == null && (
            <GeneralButton fullWidth type="button" onClick={closeModal}>
              {t("button.ok")}
            </GeneralButton>
          )}
        </div>
        <div className="h-[55.8px] lg:hidden" />
        <button
          type="button"
          onClick={closeModal}
          className={cn(
            "absolute",
            "top-[-36.04px]",
            "right-0",
            "lg:top-0",
            "lg:right-[-42px]"
          )}
        >
          <CrossSvg />
        </button>
      </ModalCard>
    </BaseModal>
  );
};

export default GeneralConformModal;
