import styled from "styled-components";
import theme from "utils/theme";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 193px;
`;

const LanguageBtn = styled.div`
  width: fit-content;
  height: auto;
  padding: 5px;
  border: 2px solid #00000020;
  border-radius: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default {
  Container,
  Logo,
  LanguageBtn,
};
