import cn from "classnames";
import BookingStep from "pageComponents/booking/BookingStep";
import Styled from "./Styled";
import IconLoader from "assets/IconLoader";
import Typography from "components/common/Typography";
import { useTranslation } from "react-i18next";
import GeneralButton from "components/common/Button";
import theme from "utils/theme";
import Space from "components/common/Space";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { ROUTE_BASE } from "Route";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import scopeLargePng from "../../assets/bg-payment-slope-large.png";
import scopeSmallPng from "../../assets/bg-payment-slope-small.png";
import { IPaymentContext } from "../../@types/common";
import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import { getCorrectUrl } from "navigators/Router";
import { TransactionTypoeV2 } from "common/models/Payment";

const PaymentSuccess = () => {
  const { t } = useTranslation("booking");
  const { refNo, bookingType } = useOutletContext() as IPaymentContext;
  const navigate = useNavigate();
  const { state } = useLocation(); //state for free order

  useEffect(() => {
    if (_.isEmpty(refNo) && !state?.isFreeOrder) {
      navigate(`/${ROUTE_BASE.MY_BOOKINGS}`);
    }
  }, []);

  const isPackage = useMemo(() => {
    const type = state?.isFreeOrder ? state?.transType : bookingType;
    return type === TransactionTypoeV2.PACKAGE;
  }, [bookingType]);

  const handleNavigate = (type: string) => {
    let path = `/${ROUTE_BASE.MY_BOOKINGS}`;
    let searchParam = "";

    switch (type) {
      case TransactionTypoeV2.PACKAGE:
        path = `/${ROUTE_BASE.VOUCHER}`;
        break;
      case TransactionTypoeV2.FACILITY:
        searchParam = ProgramTypes.Facility;
        break;
      case TransactionTypoeV2.COACH:
        searchParam = ProgramTypes.Coach;
        break;
      case TransactionTypoeV2.EVENT:
        searchParam = ProgramTypes.Event;
        break;
      case TransactionTypoeV2.PROGRAM:
        searchParam = ProgramTypes.Program;
        break;
    }

    navigate({
      pathname: path,
      search: searchParam
        ? createSearchParams({
            cat: searchParam,
          }).toString()
        : "",
    });
  };

  return (
    <div className="mx-[20px] md:mx-0">
      <BookingStep type="success" />
      <Styled.ResultContainer className="relative">
        <IconLoader.IconSuccess />
        <Space width="80px" />
        <div>
          <Typography color={theme.black} fontSize="40px" fontWeight="600">
            {t("bookingDetail.success")}
          </Typography>
          <Space size="30px" />
          <Typography fontWeight="600">
            {isPackage
              ? t("bookingDetail.yourPurchaseIsConfirmed")
              : t("bookingDetail.yourBookingIsConfirmed")}
          </Typography>
          {!_.isEmpty(refNo) && state?.isFreeOrder !== true && (
            <Typography>{t("bookingDetail.RefNo", { no: refNo })}</Typography>
          )}
          <Space size="40px" />
          <div className="w-full md:max-w-[211px]">
            <GeneralButton
              onClick={() => handleNavigate(bookingType ?? state?.transType)}
              fullWidth
              style={{ zIndex: 100 }}
            >
              {isPackage
                ? t("bookingDetail.viewMyVoucher")
                : t("bookingDetail.viewMyBooking")}
            </GeneralButton>
          </div>
        </div>
        <img
          src={scopeLargePng}
          className={cn(
            "hidden md:block",
            "absolute bottom-0 right-0",
            "w-[80vw] h-[230px]"
          )}
        />
        <img
          src={scopeSmallPng}
          className={cn(
            "md:hidden",
            "absolute bottom-0 right-0",
            "w-full h-[300px]"
          )}
        />
      </Styled.ResultContainer>
    </div>
  );
};

export default PaymentSuccess;
