import React from "react";
import Styled from "./Styled";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { ResponsiveUI, WindowSize } from "utils/responsive";

type MyPastListingCardProps = {
  title: string;
  status?: string;
} & React.HTMLProps<HTMLDivElement>;
const MyPastListingCard = ({
  title,
  status,
  children,
  ...otherProps
}: MyPastListingCardProps) => {
  const { t } = useTranslation("myBooking");
  const renderStatus =
    status === "CONFIRMED" || status === "CONFIRM"
      ? t("detail.completed")
      : t("detail.cancelled");
  return (
    <Styled.PastListing {...otherProps}>
      <ResponsiveUI
        desktop={
          <>
            <div className={cn("flex justify-between")}>
              <Styled.TitleText>{title}</Styled.TitleText>
              <div className="text-[#4A4A4A} py-[5px] px-[8px] bg-[#D1D1D1] rounded-[5px] w-fit h-fit">
                {renderStatus}
              </div>
            </div>
            {children}
          </>
        }
        mobile={
          <>
            <div className={cn("flex justify-between")}>
              <Styled.TitleText>{title}</Styled.TitleText>
            </div>
            {children}
            <div className="mt-2 text-[#4A4A4A} py-[5px] px-[8px] bg-[#D1D1D1] rounded-[5px] w-fit">
              {renderStatus}
            </div>
          </>
        }
      />
    </Styled.PastListing>
  );
};

export default MyPastListingCard;
