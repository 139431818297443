import Styled from "./Styled";
import Typography from "components/common/Typography";

interface SectionProps {
  children: React.ReactNode;
  title: string;
}

const Section = ({ title, children }: SectionProps) => {
  return (
    <div className="mt-[40px]">
      <Styled.InfoTitle>{title}</Styled.InfoTitle>
      <Typography>{children}</Typography>
    </div>
  );
};

export default Section;
