import {
  getCountryOptionFromCountryCode,
  useGetCountryOptions,
} from "common/utils/countryHelper";
import { Select, SelectOption } from "./Select";
import Textfield from "components/common/Textfield";
import cn from "classnames";
import Space from "./Space";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { CountryCode } from "libphonenumber-js";
import { useFieldConfigViewModel } from "common/viewModels/Common/FieldConfigViewModel";
import { IFieldConfigViewModel } from "common/models/Common/FieldConfig";
import { getLocalisedString } from "common/utils/stringHelper";

interface PhoneNumberInputProps {
  phoneNum: string;
  setPhoneNum?: (phoneNumber: string) => void;
  region: string;
  setRegion?: (region: CountryCode) => void;
  disabled?: boolean;
}

const PhoneNumberInput = ({
  phoneNum,
  setPhoneNum,
  region,
  setRegion,
  disabled,
}: PhoneNumberInputProps) => {
  const { t } = useTranslation("login");
  const { countryCodeList } =
    useFieldConfigViewModel() as IFieldConfigViewModel;

  return (
    <Grid container spacing={1}>
      <Grid item xs="auto">
        <Select
          disabled={disabled}
          value={region}
          onChange={(e) =>
            setRegion && setRegion(e.target.value as CountryCode)
          }
          renderValue={(selected) => {
            return `${selected}`;
          }}
        >
          {countryCodeList?.map((country) => {
            return (
              <SelectOption value={country.code} key={country.code}>
                {`${country.code} ${getLocalisedString(
                  country.name_en,
                  country.name_zh
                )}`}
              </SelectOption>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs>
        <Textfield
          disabled={disabled}
          placeholder={t("field.phone_number")}
          value={phoneNum}
          onChange={(e) => setPhoneNum && setPhoneNum(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default PhoneNumberInput;
