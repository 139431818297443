import FacilityBanner from "assets/bg-facility-banner.png";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { IFacility } from "common/models/Facility";
import { getLocalisedString } from "common/utils/stringHelper";
import { useDiscoverViewModel } from "common/viewModels/DiscoverViewModel";
import { useFacilityListViewModel } from "common/viewModels/FacilityBooking/FacilityListViewModel";
import { useVenueListViewModel } from "common/viewModels/FacilityBooking/VenueListViewModel";
import ReadMoreBox from "components/boxs/ReadMoreBox";
import PageContainer from "components/common/PageContainer";
import Typography from "components/common/Typography";
import _, { chain } from "lodash";
import { IDiscoverViewModel } from "models/Discover";
import { IFacilityListViewModel, IVenueListViewModel } from "models/Facility";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { IGlobalProviderContext } from "../../@types/common";
import { IFacilityContextType } from "./FacilityScreen";
import { Helmet } from "react-helmet";
import Styled from "./Styled";

// exclusive to golf categoryimport { Helmet } from "react-helmet";
import { removeHtmlTag } from "utils/common";
import i18n from "i18n";
import { getLocalizedPageTitleWithPrefix } from "utils/seo";

const FacilityCategoryPage = () => {
  const navigate = useNavigate();
  const { facilityId } = useParams();
  const { t } = useTranslation("booking");
  const { globalDispatch } = useContext(
    GlobalContextStore as any
  ) as IGlobalProviderContext;
  const { setFacility, facility, setVenue } =
    useOutletContext<IFacilityContextType>();
  const { getFacilityDetail } = useDiscoverViewModel() as IDiscoverViewModel;
  const { facilityList, getFacilityList } =
    useFacilityListViewModel() as IFacilityListViewModel;
  const { venueList } = useVenueListViewModel({
    facilityId,
  }) as IVenueListViewModel;

  const getMetaDescription = () => {
    const desc = getLocalisedString(facility?.desc_en, facility?.desc_tc);
    return removeHtmlTag(desc);
  };

  useEffect(() => {
    if (_.isEmpty(facility)) {
      const fetcher = async () => {
        const res = await getFacilityDetail({
          facilityId: facilityId,
          dispatch: globalDispatch,
        });

        if (res?.success) {
          setFacility(res?.result);
        } else {
          navigate("../");
        }
      };
      fetcher();
    }
  }, []);

  useEffect(() => {
    // if check the facilityId is not a golf facility on deep link, navigate back (maybe)
    // any navigation should not go to this page
    // to prevent any possible issue

    if (
      facility?.shouldGroupByCategory &&
      facility?.category &&
      _.isEmpty(facilityList)
    ) {
      console.log(
        "===facility?.category?._id",
        facility,
        facility?.category?._id
      );
      getFacilityList(facility?.category?._id);
    }
  }, [facility]);

  const displayList = useMemo(() => {
    if (!facility) return [];
    if (facility?.shouldGroupByCategory) {
      return facilityList?.map((item) => {
        return {
          title: getLocalisedString(item?.name_en, item?.name_tc),
          id: item?._id,
          img: item?.thumbnail_photo_ref,
          capacity: item?.venue_capacity,
          open_time: getLocalisedString(item?.open_time_en, item?.open_time_tc),
        };
      });
    } else {
      return venueList?.map((venue) => {
        return {
          title: getLocalisedString(venue?.name_en, venue?.name_tc),
          id: venue?._id,
          img: venue?.thumbnail_photo_ref,
          capacity: facility?.venue_capacity,
          open_time: "",
        };
      });
    }
  }, [venueList, facilityList, i18n.language]);

  return (
    <>
      <Helmet>
        <title>
          {facility?.shouldGroupByCategory
            ? getLocalizedPageTitleWithPrefix({
                en: facility?.category?.name_en,
                tc: facility?.category?.name_tc,
              })
            : getLocalizedPageTitleWithPrefix({
                en: facility?.name_en,
                tc: facility?.name_tc,
              })}
        </title>
        <meta name="description" content={getMetaDescription()} />
      </Helmet>
      <PageContainer
        title={
          facility?.shouldGroupByCategory
            ? getLocalisedString(
                facility?.category?.name_en,
                facility?.category?.name_tc
              )
            : getLocalisedString(facility?.name_en, facility?.name_tc) ??
              t("headerTitle.facilityBooking")
        }
        banner={
          facility?.shouldGroupByCategory
            ? facility?.category?.thumbnail_photo_ref
            : facility?.thumbnail_photo_ref ?? FacilityBanner
        }
        screenHeader={`${t("headerTitle.facilityBooking")} (${
          facility?.shouldGroupByCategory
            ? getLocalisedString(
                facility?.category?.name_en,
                facility?.category?.name_tc
              )
            : getLocalisedString(facility?.name_en, facility?.name_tc) ??
              t("headerTitle.facilityBooking")
        })`}
        disableBg
      >
        <Styled.VenueWrapper>
          {_.isEmpty(displayList) ? (
            <Typography>{t("common:comingSoon")}</Typography>
          ) : (
            displayList?.map((item) => (
              <ReadMoreBox
                title={item?.title}
                desc={
                  facility?.shouldGroupByCategory
                    ? item?.open_time
                    : t("venue.capacity", { capacity: item?.capacity })
                }
                id={item.id}
                key={item?.id}
                img={item.img}
                onClick={() => {
                  if (facility?.shouldGroupByCategory) {
                    // each facilityList item does't return the name of category, so inject using cached facility (best effort)

                    const foundedFacility = _.find(facilityList, [
                      "_id",
                      item?.id,
                    ]) as IFacility;
                    const facilityWithCategoryName = _.set(
                      foundedFacility,
                      "category",
                      {
                        ...foundedFacility?.category,
                        name_en: facility?.category?.name_en,
                        name_tc: facility?.category?.name_tc,
                      }
                    );

                    setFacility(facilityWithCategoryName);
                    navigate(`../${item?.id}/timeslot`);
                  } else {
                    navigate(`../${facilityId}/timeslot`);
                  }
                }}
              />
            ))
          )}
        </Styled.VenueWrapper>
      </PageContainer>
    </>
  );
};

export default FacilityCategoryPage;
