import styled from "styled-components";
import { size } from "../../../utils/responsive";
import theme from "utils/theme";

const GoToTnCButton = styled.p``;

const TncTypography = styled.p`
  color: var(--palette-ss-accent-color, #443e36);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  & .ss-button {
    color: ${theme.orange};
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
  }

  & .ss-break-line {
    display: none;
    @media (min-width: ${size.screen1024}px) {
      display: block;
    }
  }
`;

const FormSelectHoc = (Select: React.FC<any>) => styled(Select)`
  & .MuiInputBase-root {
    /* border-color: var(--palette-ss-black) !important; */
  }
  & .MuiInputBase-input {
    padding: 9.3px 14px !important;
  }
`;

const FormTitleText = styled.span`
  color: var(--palette-ss-000, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: ${size.screen1024}px) {
    font-size: 40px;
  }
`;

const VerificationWarning = styled.div`
  background-color: #fff7f9;
  border: 1px solid ${theme.flowerRed};
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  align-items: center;
`;

export default {
  GoToTnCButton,
  TncTypography,
  FormSelectHoc,
  FormTitleText,
  VerificationWarning,
};
