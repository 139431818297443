import React, { useCallback, useState } from "react";
import { useUserVerificationFormModel } from "./UserVerificationFormModel";
import Form, { FormSubmitCallback } from "../../common/Form/Form";
import Button from "../../common/Button";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import GenderSelect from "../../GenderSelect/GenderSelect";
import PhotoUploadCard from "../../common/PhotoUploadCard/PhotoUploadCard";
import Checkbox from "../../common/Checkbox";
import Styled from "../UserRegistrationForm/Styled";
import AvatarUploadCircle from "../../AvatarUploadCircle/AvatarUploadCircle";
import { NewUser } from "./UserVerificationFormModel";
import { WindowSize } from "../../../utils/responsive";
import Typography from "components/common/Typography";
import { BasicDatePicker } from "components/common/DatePicker";
import { districtList } from "../../../common/models/User";
import UserNameTitleSelect from "../UserRegistrationForm/UserNameTitleSelect";
import dayjs from "common/utils/dayjs";
import theme from "utils/theme";
import _ from "lodash";
import GeneralButton from "../../common/Button";
import saishaLogoPng from "assets/ic-shishaLogo.png";
import { ICurrentUser } from "../../../@types/common";
import IconClose from "assets/ic-close.svg";
import IconVerification from "assets/ic-verification.svg";

type Props = {
  userInfo: ICurrentUser | null;
  onSubmit: (value: NewUser) => void;
  onBack: () => void;
};
type Ref = HTMLFormElement;

const UserVerificationForm = React.forwardRef<Ref, Props>((props, ref) => {
  // states
  const windowSize = WindowSize();
  const { t } = useTranslation("register");
  const { formInitialValues, formValidateSchema } =
    useUserVerificationFormModel(props?.userInfo);

  // callbacks
  const onFormSubmit: FormSubmitCallback<typeof formInitialValues> =
    useCallback(
      async (value) => props.onSubmit(value as unknown as NewUser),
      [props]
    );

  const StyledFormSelect = Styled.FormSelectHoc(Form.Field.Select);

  return (
    <Form
      innerRef={ref as any}
      initialValues={formInitialValues}
      validationSchema={formValidateSchema}
      onSubmit={onFormSubmit}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {(formik) => {
        const formSubmitDisabled = !formik.isValid || !formik.dirty;

        return (
          <Form.Element
            id="user_verification_form"
            className={cn("w-full h-full pt-[24px]")}
          >
            <div
              className={cn(
                "w-full",
                "h-[calc(100%-5px)]",
                "overflow-y-auto",
                "flex",
                "flex-col",
                "px-[36px]"
              )}
            >
              <div className="flex justify-end mb-[30px] w-[100%]">
                <img
                  src={IconClose}
                  className="cursor-pointer w-[20px] h-[20px]"
                  onClick={props.onBack}
                />
              </div>
              <div className="w-full flex flex-col items-center mb-[20px]">
                <img src={saishaLogoPng} className="w-[193px]" />
                <Styled.FormTitleText>
                  {t("profile:personalInfo.accountVerification")}
                </Styled.FormTitleText>
              </div>
              <div className="w-full flex flex-col items-end gap-[20px]">
                <div
                  className={cn(
                    "w-full flex flex-col items-center",
                    "lg:flex-row"
                  )}
                >
                  <div
                    className={cn(
                      "w-full flex flex-col items-center justify-center",
                      "lg:w-1/2",
                      "lg:pr-[66px]",
                      "lg:justify-end"
                    )}
                  >
                    <AvatarUploadCircle
                      defaultImageUrl={props.userInfo?.signed_profile_pic_ref}
                      imageUrl={formik.values.profile_pic_ref}
                      onImageUrlChange={(url, file) => {
                        formik.setFieldValue("profile_pic_ref", url);
                        formik.setFieldValue("profile_pic_file", file);
                      }}
                    />
                    <div>
                      {formik.errors.profile_pic_ref && (
                        <Typography
                          fontSize="12px"
                          color={theme.error}
                          textAlign="center"
                        >
                          {t("register:field.profile")}
                        </Typography>
                      )}
                      <div
                        className={cn(
                          "bg-[#f2f2f2] p-[20px] rounded-[15px] my-[20px]",
                          "w-full md:w-[250px]"
                        )}
                      >
                        <Typography
                          fontSize="12px"
                          color={theme.secondary}
                          textAlign="center"
                        >
                          {t("register:profile_upload.message")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div
                    className={cn(
                      "w-full flex flex-col items-center gap-[20px]",
                      "lg:w-1/2"
                    )}
                  >
                    <Form.Field.TextField
                      id="username"
                      name="username"
                      title={t("field.username")}
                      placeholder={t("placeholder.username")}
                      value={formik.values.username}
                      style={{
                        width: windowSize === "screen1024" ? "297px" : "100%",
                      }}
                    />
                    <UserNameTitleSelect
                      selectedTitle={formik.values.title}
                      setTitle={(title) => formik.setFieldValue("title", title)}
                    />
                    <Form.Field.TextField
                      id="enName"
                      name="enName"
                      title={t("field.en_name")}
                      placeholder={t("placeholder.en_name")}
                      style={{
                        width: windowSize === "screen1024" ? "297px" : "100%",
                      }}
                    />
                    <Form.Field.TextField
                      id="zhName"
                      name="zhName"
                      title={t("field.zh_name")}
                      placeholder={t("placeholder.zh_name")}
                      style={{
                        width: windowSize === "screen1024" ? "297px" : "100%",
                      }}
                    />
                  </div>
                </div>
                <div
                  className={cn("w-full flex flex-col gap-[20px]", "lg:w-1/2")}
                >
                  <BasicDatePicker
                    id="dateOfBirth"
                    name="dateOfBirth"
                    title={t("field.dob")}
                    disableFuture
                    maxDate={dayjs()}
                    defaultValue={formik.values.dateOfBirth}
                    textFieldProps={{
                      style: {
                        width: windowSize === "screen1024" ? "297px" : "100%",
                        height: "44.6px !important",
                      },
                    }}
                    onChange={(e) => formik.setFieldValue("dateOfBirth", e)}
                  />
                  <div className="w-[201px]">
                    <GenderSelect
                      required
                      gender={formik.values.gender}
                      setGender={(gender) =>
                        formik.setFieldValue("gender", gender)
                      }
                    />
                  </div>
                  <Form.Field.TextField
                    id="phoneNum"
                    name="phoneNum"
                    title={t("field.phone")}
                    placeholder={t("placeholder.phone")}
                    disabled
                    style={{
                      width: windowSize === "screen1024" ? "297px" : "100%",
                    }}
                  />
                  <StyledFormSelect
                    id="district"
                    name="district"
                    title={`${t("field.district")}*`}
                    placeholder={t("placeholder.district.empty")}
                    style={{
                      width: windowSize === "screen1024" ? "297px" : "100%",
                    }}
                  >
                    {districtList.map((each) => (
                      <Form.Field.SelectOption key={each} value={each}>
                        {t(`placeholder.district.${each}`)}
                      </Form.Field.SelectOption>
                    ))}
                  </StyledFormSelect>
                </div>
                <div className="email-container relative w-full flex flex-col gap-[20px] lg:w-1/2">
                  <div className="w-full flex justify-between gap-[15px] md:gap-[26.27px] items-end lg:w-[395px] lg:justify-start">
                    <div className="flex-grow">
                      <Form.Field.TextField
                        id="email"
                        name="email"
                        title={t("field.email")}
                        placeholder={t("placeholder.email")}
                        disabled
                        style={{
                          width: windowSize === "screen1024" ? "297px" : "100%",
                        }}
                      />
                    </div>
                  </div>

                  <Styled.VerificationWarning>
                    <img src={IconVerification} className="mr-[5px]" />
                    <Typography fontSize="12px" color={theme.flowerRed}>
                      {t("profile:personalInfo.pleaseFillAccountInformation")}
                    </Typography>
                  </Styled.VerificationWarning>
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse mt-[20px] mx-[36px] lg:mx-0">
              <div className="w-full flex flex-col flex-nowrap lg:w-1/2">
                <Button
                  type="submit"
                  disabled={formSubmitDisabled}
                  style={{
                    width: windowSize === "screen1024" ? "297px" : "100%",
                  }}
                >
                  {t("common:button.submit")}
                </Button>
              </div>
            </div>
          </Form.Element>
        );
      }}
    </Form>
  );
});

export default UserVerificationForm;
