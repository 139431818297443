import { useEffect, useState } from "react";
import { listFacility, listFacilityByCategory } from "../../network/facility";
import { appearGlobalError } from "../../context/requests/globalRequest";
import _ from "lodash";
import { useCommonFetcher } from "../Common/CommonViewModel";
import { Facility } from "../../models/Facility";

const rowSize = 3;

export const useFacilityListViewModel = () => {
  const { fetcher } = useCommonFetcher();
  const [facilityList, setFacilityList] = useState([]);
  const [facilityRows, setFacilityRows] = useState([]);

  useEffect(() => {
    // Group categories into rows
    let tmpRows = [];
    for (let i = 0; i < facilityList.length; i += rowSize) {
      tmpRows.push(facilityList.slice(i, i + rowSize));
    }
    setFacilityRows(tmpRows);
  }, [facilityList]);

  const getFacilityList = async (categoryId) => {
    if (categoryId) {
      // List facility by category
      const res = await fetcher({
        api: () => listFacilityByCategory({ categoryId }),
      });
      const data = res?.result
        ?.filter((facility) => !!facility.isShow)
        .map((facility) => new Facility(facility));
      setFacilityList(data);
      return res;
    } else {
      // List all facility - error handled by display no record found
      // Shd group golf
      const res = await fetcher({ api: listFacility, skipErrorHandler: true });
      const data = res?.result?.map((facility) => new Facility(facility));
      const tmpFacilityList = _.uniqBy(data, (facility) => {
        if (facility?.shouldGroupByCategory) {
          return facility?.category?._id; // unique golf category
        } else {
          return facility?._id; // unique facility
        }
      });
      setFacilityList(tmpFacilityList);
      return res;
    }
  };

  return {
    facilityList,
    facilityRows,
    getFacilityList,
  };
};
