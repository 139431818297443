import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import theme from "utils/theme";

const OtpCard = styled.div`
  width: 100%;
  height: auto;
  border-radius: 5px;
  background: #e8e8e8;
  padding: 10px 21px;

  @media ${breakpoint.mobile} {
    max-width: 278px;
  }
`;

const OtpCardTitleText = styled.span`
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Noto Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
`;

const OtpInputLabelListContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

const OtpInputLabelContainer = styled.div`
  width: 36px;
  height: 41px;
  background: #ffffff;
  /* IGH-BoxBorder */
  border-radius: 5px;
  display: grid;
  place-items: center;

  &:hover {
    cursor: text;
  }

  & .otp-text {
    font-size: 25px;
    line-height: 16px;
    color: var(--palette-ss-black);

    &.focus {
      padding-top: 2px;
      &:after {
        content: "";
        width: 0;
        border: 1px solid var(--palette-ss-black);
        animation-name: flash;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }
    @keyframes flash {
      from { opacity: 0},
      50% { opacity: 1 }
      to { opacity: 0},
    }
  }
`;

const ZeroSizeInput = styled.input`
  width: 0px;
  height: 0px;
  display: hidden;
`;

const ResendButton = styled.button`
  color: ${theme.orange};
  font-family: Noto Sans;
  font-size: 11.2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.112px;
  text-decoration-line: underline;
`;

const ResendButtonPosition = styled.div`
  width: 100%;
  margin-top: 21.6px;
  display: grid;
  place-items: center;
`;

export default {
  OtpCard,
  OtpCardTitleText,
  OtpInputLabelListContainer,
  OtpInputLabelContainer,
  ZeroSizeInput,
  ResendButtonPosition,
  ResendButton,
};
