import React, { useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import AddGuestListModal from "components/modals/AddGuestListModal/AddGuestListModal";
import { isEmail } from "../../utils/validator";
import { ReactComponent as CrossSvg } from "../../assets/mybooking/remove-cross.svg";
import Styled from "./Styled";
import { EventBooking, Guest } from "../../models";
import { useModal } from "hooks/useModal";
import { FacilityBookingOrder } from "common/models/FacilityBookingOrder";
import { CoachBooking } from "models/PrivateCoach";
import { ProgramBooking } from "models/Programme";
import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import GeneralConformModal from "components/modals/GeneralConformModal/GeneralConformModal";

type Props = {
  isPastBooking: boolean; // deprecated
  isAllowEditGuest: boolean;
  bookingType: string;
  booking: FacilityBookingOrder | EventBooking | CoachBooking | ProgramBooking;
  guests: Guest[];
  guestLimit: number;
  addGuestByCsvString?: (
    csvString: string
  ) => Promise<{ success: boolean; message: string }>;
  removeGuest: (guestToRemove: Guest) => Promise<void>;
};

const BookingDetailGuestCard = (props: Props) => {
  const {
    guests,
    guestLimit,
    addGuestByCsvString,
    removeGuest,
    booking,
    bookingType,
    isPastBooking,  // deprecated
    isAllowEditGuest
  } = props;

  const { t } = useTranslation("myBooking");
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();

  const [removeModalMember, setRemoveModalMember] = useState<Guest | null>(
    null
  );

  const handleRemoveGuest = async () => {
    if (removeModalMember) {
      await removeGuest(removeModalMember);
      setRemoveModalMember(null);
    }
  };

  const submitNewGuestListCallback = async (list: string) => {
    // validate
    const emails = list.split(",");
    for (const email of emails) {
      if ((await isEmail(email)) === false) {
        return {
          success: false,
          error: { message: `Invalid email: '${email} is not an email format` },
        };
      }
    }

    try {
      if (addGuestByCsvString != null) {
        const result = await addGuestByCsvString(list);
        if (!result.success) {
          return {
            success: false,
            error: result,
          };
        }
        console.log("🚀 ~ submitNewGuestListCallback ~ result:", result);
        return { success: result.success, error: { message: result.message } };
      }
      return { success: true } as const;
    } catch (e) {
      console.log("🚀 ~ submitNewGuestListCallback ~ e:", e);
      return {
        success: false,
        error: {
          message: `${
            e instanceof Error
              ? e?.message
              : t("common:error.internalServerError")
          }`,
        },
      };
    }
  };

  const getGuestLimit = () => {
    if (bookingType === ProgramTypes.Event) {
      return (booking as EventBooking).attendees_count - 1;
    }
    if (bookingType === ProgramTypes.Facility) {
      return (
        (booking as FacilityBookingOrder).bookings[0]?.facility
          ?.guest_per_booking || 0
      );
    }
    if (bookingType === ProgramTypes.Coach) {
      const coachBooking = booking as CoachBooking;
      if (coachBooking.lessonType === "1to1") return 0;
      if (coachBooking.lessonType === "1to2") return 1;
      if (coachBooking.lessonType === "1to3") return 2;
    }
    return 0;
  };

  return (
    <Styled.SharedCard
      className={cn(
        "w-full px-[22px] py-[22px]",
        "lg:px-[37px]",
        "lg:py-[22px]"
      )}
    >
      <div className="w-full flex flex-col">
        <Styled.KeyMemberTitle>{t("detail.guest")}</Styled.KeyMemberTitle>
        {guests.length > 0 && <div className="h-[20px]" />}
        <div className="flex flex-col gap-y-[20px]">
          {guests.map((member, index) => (
            <div className="flex flex-col " key={member.user_id}>
              <Styled.KeyMemberSubtitle>{`${t("detail.guest")} ${
                index + 1
              }`}</Styled.KeyMemberSubtitle>
              <div className="h-[7.43px]" />
              <div className="flex flex-col gap-[10px] lg:flex-row">
                <Styled.KeyMemberEmailContainer>
                  {member.email}
                </Styled.KeyMemberEmailContainer>
                {isAllowEditGuest && (
                  <Styled.RemoveGuestButton
                    type="button"
                    onClick={() => {
                      setRemoveModalMember(member);
                    }}
                  >
                    <CrossSvg />
                    <div className="w-[5px]" />
                    <slot>{t("detail.remove")}</slot>
                  </Styled.RemoveGuestButton>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="h-[20px]" />
      {isAllowEditGuest && (
        <Styled.GuestListModelButton
          type="button"
          onClick={handleModalOpen}
          disabled={!isAllowEditGuest || guestLimit - guests.length <= 0}
        >
          {`+ ${t("detail.addMoreGuest")}`}
        </Styled.GuestListModelButton>
      )}
      <AddGuestListModal
        id="add-guest-list-modal"
        open={modalOpen}
        onClose={handleModalClose}
        onAddGuestList={submitNewGuestListCallback}
        guestListLimit={guestLimit - guests.length}
      />
      {removeModalMember && (
        <GeneralConformModal
          title={t("detail.removeGuest", { email: removeModalMember.email })}
          open={!!removeModalMember}
          confirmCallback={handleRemoveGuest}
          closeModal={() => {
            setRemoveModalMember(null);
          }}
        />
      )}
    </Styled.SharedCard>
  );
};

export default BookingDetailGuestCard;
