export const APPEAR_GLOBAL_ERROR = "APPEAR_GLOBAL_ERROR";
export const DISAPPEAR_GLOBAL_ERROR = "DISAPPEAR_GLOBAL_ERROR";

export const APPEAR_GLOBAL_LOADING = "APPEAR_GLOBAL_LOADING";
export const DISAPPEAR_GLOBAL_LOADING = "DISAPPEAR_GLOBAL_LOADING";

export const APPEAR_ACCOUNT_VERIFICATION = "APPEAR_ACCOUNT_VERIFICATION";
export const DISAPPEAR_ACCOUNT_VERIFICATION = "DISAPPEAR_ACCOUNT_VERIFICATION";

export const APPEAR_PHONE_NUMBER_VERIFICATION =
  "APPEAR_PHONE_NUMBER_VERIFICATION";
export const DISAPPEAR_PHONE_NUMBER_VERIFICATION =
  "DISAPPEAR_PHONE_NUMBER_VERIFICATION";

export const SET_KIOSK_CERT = "SET_KIOSK_CERT";

export const PUSH_GLOBAL_BLOCKING = "PUSH_GLOBAL_BLOCKING";
export const POP_GLOBAL_BLOCKING = "POP_GLOBAL_BLOCKING";
