import { useWeatherViewModel } from "common/viewModels/WeatherViewModel";
import { IWeatherViewModel } from "models/Weather";
import React, { useEffect } from "react";
import { ReactComponent as HumiditySvg } from "../../assets/weatherIcons/humidity.svg";

const Weather = () => {
  const {
    fetchWeatherInfo,
    weatherIconCodeList,
    weatherWarningCodeList,
    humidity,
    temperature,
  } = useWeatherViewModel() as IWeatherViewModel;
  useEffect(() => {
    fetchWeatherInfo();
  }, []);
  return (
    <div className="flex h-9 bg-white rounded-[5px] px-2 py-1">
      <>
        {weatherWarningCodeList.map((code) => {
          return (
            <img
              src={require(`../../assets/weatherIcons/${code}.gif`)}
              key={code}
              alt={`weather info code: ${code}`}
              className="max-h-full mr-1"
            />
          );
        })}
      </>

      <div className="mx-1 leading-[9px]">
        <div className="leading-5">{temperature}°</div>
        <div className="flex text-[9px] items-center">
          <HumiditySvg />
          {humidity}%
        </div>
      </div>

      <>
        {weatherIconCodeList.map((code) => {
          return (
            <img
              src={require(`../../assets/weatherIcons/${code}.png`)}
              key={code}
              alt={`weather info code: ${code}`}
              className="max-h-full "
            />
          );
        })}
      </>
    </div>
  );
};

export default Weather;
