import { IFlieUploadViewModel } from "common/models/S3/FileUpload";
import { getPresignedURL, uploadImageToS3 } from "common/network/auth";
import { useFileUploadViewModel } from "common/viewModels/S3/FileUploadViewModel";

export const useUploadFile = () => {
  const { uploadFileWithPresignUrl } =
    useFileUploadViewModel() as IFlieUploadViewModel;

  const requestPresignedURLByFile = async (file: File, uri: string) => {
    try {
      return new Promise<any>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = async (e) => {
          try {
            const data = await uploadFileWithPresignUrl(
              e?.target?.result as ArrayBuffer
            );

            resolve(data);
          } catch (error) {
            reject(error);
            console.log("uplaod error", error);
          }
        };
      });
    } catch (error) {
      console.log("request presigned Url fail", error);
      throw error as Error;
    }
  };

  return { requestPresignedURLByFile };
};
