import React, { useContext, useState } from "react";
import Styled from "./Styled";
import { Select, SelectOption } from "components/common/Select";
import { useMyPastBookingListViewModel } from "common/viewModels/MyBooking/MyPastBookingListViewModel";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import {
  IGlobalProviderContext,
  IMyPastBookingListViewModel,
  IUserViewModelContext,
} from "../../@types/common";
import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import MyPastListingCard from "components/MyBookingListingCard/MyPastListingCard/MyPastListingCard";
import dayjs from "common/utils/dayjs";
import { getLocalizedString } from "utils/locale";
import {
  MyCoachListingCard,
  MyEventListingCard,
  MyFacilityListingCard,
  MyProgramListingCard,
} from "components/MyBookingListingCard";
import { FacilityBooking } from "common/models/FacilityBooking";
import { MonthlyProgrammeLesson } from "common/models/MonthlyProgram";
import { useTranslation } from "react-i18next";
import { ROUTE_BASE } from "Route";
import { useNavigate } from "react-router-dom";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import cn from "classnames";
import { WindowSize } from "utils/responsive";
import HeaderTitle from "components/common/HeaderTitle";

const MyPastBooking = () => {
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { currentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { t } = useTranslation("myBooking");
  const navigate = useNavigate();
  const windowSize = WindowSize();

  const {
    pastMonthlyProgramBookingList,
    pastCoachLessonBookingList,
    pastEventBookingList,
    pastFacilityBookingList,
    selectedProgramType,
    filterStatus,
    setFilterStatus,
    switchBookingListTab,
  } = useMyPastBookingListViewModel({
    isLoggedIn: !!currentUser,
    dispatch: globalDispatch,
  }) as IMyPastBookingListViewModel;

  const isSelectedListEmpty = () => {
    switch (selectedProgramType) {
      case ProgramTypes.Program:
        return pastMonthlyProgramBookingList.length === 0;
      case ProgramTypes.Coach:
        return pastCoachLessonBookingList.length === 0;
      case ProgramTypes.Event:
        return pastEventBookingList.length === 0;
      case ProgramTypes.Facility:
        return pastFacilityBookingList.length === 0;
      default:
        return true;
    }
  };

  return (
    <div className="w-full">
      <div
        className={cn(
          "w-full flex flex-col h-30 items-start mb-4 gap-7",
          "xl:h-[49px] xl:flex-row justify-end xl:items-center"
        )}
      >
        <div className="w-full xl:flex items-center">
          <Styled.FilterLabel>{t("listing.type")}</Styled.FilterLabel>
          <div className="flex-1 xl:ml-[15px]">
            <Select
              className=""
              sx={{
                width: "100%",
                maxWidth: windowSize === "screen1280" ? 257 : "100%",
              }}
              value={selectedProgramType}
              onChange={(e) => switchBookingListTab(e.target.value)}
            >
              {Object.entries(ProgramTypes).map(([programType, value], idx) => {
                return (
                  <SelectOption value={value} key={idx}>
                    {t(`listing.${programType.toLowerCase()}`)}
                  </SelectOption>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="w-full xl:flex items-center">
          <Styled.FilterLabel>{t("listing.filterBy")}</Styled.FilterLabel>
          <div className="flex-1 xl:ml-[15px]">
            <Select
              sx={{ width: windowSize === "screen1280" ? 257 : "100%" }}
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              disabled={selectedProgramType === ProgramTypes.Event}
            >
              <SelectOption value="CONFIRMED,CANCELLED">
                {t("detail.all")}
              </SelectOption>
              <SelectOption value="CONFIRMED">
                {t("detail.completed")}
              </SelectOption>
              <SelectOption value="CANCELLED">
                {t("detail.cancelled")}
              </SelectOption>
            </Select>
          </div>
        </div>
      </div>
      <div>
        {isSelectedListEmpty() ? (
          <div>{t("listing.noRecords")}</div>
        ) : (
          <div className="flex flex-col gap-5">
            {selectedProgramType === ProgramTypes.Program && (
              <div className="mb-[44px]">
                <HeaderTitle title={t("myBooking:listing.program")} />
                <div className="h-5 md:h-[40px]" />
                <div className="flex flex-col gap-5">
                  {pastMonthlyProgramBookingList.map((program, idx) => {
                    return (
                      <MyProgramListingCard
                        isPastRecord={true}
                        status={program.status}
                        key={program._id}
                        programTitle={program.class.programme.titleEn}
                        coachNames={program.class.coaches.map(
                          (coach) => coach.coach_name_en
                        )}
                        startDate={
                          program.lessons.length > 0
                            ? new Date(program.lessons[0].startTime)
                            : ""
                        }
                        endDate={
                          program.lessons.length > 0
                            ? new Date(
                                program.lessons[
                                  program.lessons.length - 1
                                ].endTime
                              )
                            : ""
                        }
                        classTitle={program.class.titleEn}
                        lessons={program.lessons}
                        onClick={() => {
                          navigate(`${program._id}`, {
                            state: {
                              bookingType: ProgramTypes.Program,
                              // Replaced by backend returned isPastBooking flag
                              // isPastBooking: true,
                            },
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {selectedProgramType === ProgramTypes.Coach && (
              <div className="mb-[44px]">
                <HeaderTitle title={t("myBooking:listing.coach")} />
                <div className="h-5 md:h-[40px]" />
                <div className="flex flex-col gap-5">
                  {pastCoachLessonBookingList.map((order, idx) => {
                    return (
                      <MyCoachListingCard
                        isPastRecord={true}
                        status={order.status}
                        key={order._id}
                        coachName={`${getLocalizedString(
                          order?.coach_id?.coach_name_en,
                          order?.coach_id?.coach_name_zh
                        )} (${t(
                          `coaching:coachLessonType.${order?.lessonType}`
                        )})`}
                        date={order?.reserved_date}
                        times={order?.bookings}
                        location={getLocalizedString(
                          order?.venue_id?.name_en,
                          order?.venue_id?.name_tc
                        )}
                        onClick={() => {
                          navigate(`${order._id}`, {
                            state: {
                              bookingType: ProgramTypes.Coach,
                              // Replaced by backend returned isPastBooking flag
                              // isPastBooking: true,
                            },
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {selectedProgramType === ProgramTypes.Event && (
              <div className="mb-[44px]">
                <HeaderTitle title={t("myBooking:listing.event")} />
                <div className="h-5 md:h-[40px]" />
                <div className="flex flex-col gap-5">
                  {pastEventBookingList.map((eventBooking, idx) => {
                    return (
                      <MyEventListingCard
                        isPastRecord={true}
                        status={eventBooking.status}
                        key={eventBooking._id}
                        title={getLocalizedString(
                          eventBooking?.event?.title_en,
                          eventBooking?.event?.title_tc
                        )}
                        dates={eventBooking?.event?.event_date ?? []}
                        venue={getLocalizedString(
                          eventBooking?.event?.venue_en,
                          eventBooking?.event?.venue_tc
                        )}
                        onClick={() => {
                          navigate(`${eventBooking._id}`, {
                            state: {
                              bookingType: ProgramTypes.Event,
                              // Replaced by backend returned isPastBooking flag
                              // isPastBooking: true,
                            },
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {selectedProgramType === ProgramTypes.Facility && (
              <div className="mb-[44px]">
                <HeaderTitle title={t("myBooking:listing.facility")} />
                <div className="h-5 md:h-[40px]" />
                <div className="flex flex-col gap-5">
                  {pastFacilityBookingList.map((order, idx) => {
                    return (
                      <MyFacilityListingCard
                        isPastRecord={true}
                        status={order.status}
                        key={order._id}
                        title={order?.bookings[0]?.venue?.getName()}
                        reservedDate={order?.bookings[0]?.reserved_date}
                        dates={order.bookings.map(
                          (booking: FacilityBooking) => ({
                            startTime: booking.start_time,
                            endTime: booking.end_time,
                          })
                        )}
                        onClick={() => {
                          navigate(`${order._id}`, {
                            state: {
                              bookingType: ProgramTypes.Facility,
                              // Replaced by backend returned isPastBooking flag
                              // isPastBooking: true,
                            },
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyPastBooking;
