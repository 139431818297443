import styled from "styled-components";
import theme from "../../utils/theme";

interface CardProps {
  children: React.ReactNode;
  width?: string | number;
  height?: string | number;
  maxHeight?: string | number;
  bgColor?: string;
}

const CustomCard = styled.div<
  Pick<CardProps, "width" | "height" | "bgColor" | "maxHeight">
>`
  position: relative;
  width: ${(props) => props?.width ?? "100%"};
  height: ${(props) => props?.height ?? "100%"};
  max-height: ${(props) => props?.maxHeight ?? "100dvh"};
  background-color: ${(props) => props?.bgColor ?? theme.white};
  border-radius: 30px;
  overflow: hidden;
`;

const Card = ({
  children,
  ...remains
}: CardProps & React.HTMLProps<HTMLDivElement>) => {
  return <CustomCard {...remains}> {children} </CustomCard>;
};

export default Card;
