import React from "react";
import {
  FieldProps,
  Formik,
  FormikConfig,
  Field as FormikField,
  Form as FormikForm,
  FormikFormProps,
  FormikHelpers,
  FormikValues,
} from "formik";
import { get } from "lodash";

// import { is, generateErrors } from 'shared/utils/validation';

import Field from "./Field";

export type FormSubmitCallback<Values> = (
  values: Values,
  formikHelpers: FormikHelpers<Values>
) => void | Promise<any>;

const Form = function <T extends FormikValues>(props: FormikConfig<T>) {
  return <Formik {...props} />;
};

Form.Element = function Element(props: FormikFormProps) {
  return <FormikForm noValidate {...props} />;
};

type FieldBaseProps = {
  name: string;
  onChange?: (...args: any[]) => void;
};

function withFormField<T extends FieldBaseProps>(
  FieldComponent: React.ComponentType<T>
) {
  return function (props: T) {
    const { name, onChange, ...remainProps } = props;
    return (
      <FormikField name={name}>
        {({ field, form: { touched, errors, setFieldValue } }: FieldProps) => (
          <FieldComponent
            {...(field as any)}
            {...(remainProps as any)}
            name={name}
            // error={get(touched, name) && get(errors, name)}
            // onChange={(inputEvent) => {
            //   if (onChange == null) {
            //     return setFieldValue(name, inputEvent.currentTarget.value);
            //   }
            //   return onChange(inputEvent);
            // }}
          />
        )}
      </FormikField>
    );
  };
}

Form.Field = {
  TextField: withFormField(Field.TextField),
  Select: withFormField(Field.Select),
  SelectOption: Field.SelectOption,
  Checkbox: withFormField(Field.CheckBox),
  DatePicker: withFormField(Field.DatePicker),
  TimePicker: withFormField(Field.TimePicker),
  DateTimePicker: withFormField(Field.DateTimePicker),
};

Form.initialValues = (data: any, getFieldValues: any) =>
  getFieldValues((key: string, defaultValue = "") => {
    const value = get(data, key);
    return value === undefined || value === null ? defaultValue : value;
  });

// Form.is = is

export default Form;
