import { GolfMaps } from "models";
import { useEffect, useRef, useState } from "react";
import Slider, { CustomArrowProps } from "react-slick";
import Typography from "components/common/Typography";
import { getLocalisedString } from "common/utils/stringHelper";
import Space from "components/common/Space";
import Styled from "./Styled";
import IconLeftArrow from "assets/ic-BannerArrowLeft.svg";
import IconRightArrow from "assets/ic-BannerArrowRight.svg";

interface SliderProps {
  data: GolfMaps[];
}

const BaySlider = ({ data }: SliderProps) => {
  const NextArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return (
      <Styled.BannerLeftArrow onClick={onClick}>
        <img src={IconLeftArrow} />
      </Styled.BannerLeftArrow>
    );
  };

  const PrevArrow = (props: CustomArrowProps) => {
    const { className, style, onClick } = props;
    return (
      <Styled.BannerRightArrow onClick={onClick}>
        <img src={IconRightArrow} />
      </Styled.BannerRightArrow>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="slider-container">
      <Styled.GolfBaySlider {...settings}>
        {data?.map((map) => (
          <div key={map?._id} className="slider-track">
            <img src={map?.golfmap_image_ref} />
            <Space size="10px" />
            <Typography>
              {getLocalisedString(map?.golfmap_desc_en, map?.golfmap_desc_tc)}
            </Typography>
          </div>
        ))}
      </Styled.GolfBaySlider>
    </div>
  );
};

export default BaySlider;
