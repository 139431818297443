import Typography from "components/common/Typography";
import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import theme from "utils/theme";

const GreyContainer = styled.div`
  margin: 0 auto;
  background-color: ${theme.lightCyan};
  border-radius: 15px;
  width: 100%;
  height: auto;
  padding: 30px 50px 100px;
  max-width: 1280px;

  @media ${breakpoint.mobile} {
    padding: 30px 30px 100px;
  }
`;

const InfoTitle = styled.div`
  display: block;
  width: 100%;
  float: left;
  position: relative;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
  &:before {
    content: "";
    display: inline-block;
    height: 23px;
    width: 9px;
    position: absolute;
    left: -40px;
    top: 5px;
    background: #00c8f3;
  }
  @media ${breakpoint.mobile} {
    &:before {
      left: -30px;
    }
  }
`;

const StepBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  float: left;
  font-weight: 500;
  margin: 30px 0px 0px;
`;

const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 130px;
  span {
    width: 37px;
    height: 37px;
    display: inline-block;
    border-radius: 50%;
    background: #8c8c8c;
    margin-bottom: 10px;
    position: relative;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media ${breakpoint.mobile} {
    font-size: 12px;
    span {
      width: 25px;
      height: 25px;
    }
  }
`;

const StepWapper = styled.div`
  display: flow-root;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;

const Divider = styled.hr<{ isRedLine: boolean }>`
  border-top: ${(props) =>
    props.isRedLine ? "2px solid #EB4309" : "2px solid #30861b"};
  width: calc(50% - 150px);
  margin-top: 20px;
  @media ${breakpoint.mobile} {
    width: calc(50% - 100px);
  }
`;

const AttendeesboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c1c1c1;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 2px #c1c1c1 inset;
  padding: 20px;

  @media ${breakpoint.mobile} {
    box-shadow: none;
    border-radius: 0px;
    padding: 20px 20px 32px;
  }
`;

const Attendeesbox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    border: none;
    align-items: center;
  }
`;

const AttendeesItem = styled.div`
  width: calc(100% - 140px);
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  @media ${breakpoint.mobile} {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
`;

const NumberField = styled.div`
  display: flex;
  width: 140px;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  justify-content: space-between;
  font-weight: 500;
  background: #f2e6e2;

  @media ${breakpoint.mobile} {
    width: 100%;
    text-align: center;
  }
`;

const NumberButton = styled.div`
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 32px;
  background: #c85d38;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 30px;
`;

const ReceiptBox = styled.div`
  flex-shrink: 0;
  background: #fff;
  border-radius: 10px;
  width: 300px;
  padding: 30px 40px;
  height: fit-content;
  overflow: visible;

  @media ${breakpoint.screen1024} {
    width: 357px;
  }

  @media ${breakpoint.mobile} {
    background: none;
    padding: 0;
    border-radius: 0;
    width: 100%;
  }
`;

const UnderlineText = styled(Typography)<{ disabled: boolean }>`
  text-decoration: underline;
  color: ${(props) => (props.disabled ? theme.secondary : theme.orange)};
  cursor: pointer;
`;

const ModalContainer = styled.div`
  background-color: ${theme.white};
  width: fit-content;
  height: auto;
  min-width: 250px;
  border-radius: 30px;
  padding: 20px;
`;

export default {
  GreyContainer,
  InfoTitle,
  StepBox,
  StepItem,
  StepWapper,
  Divider,
  AttendeesboxWrapper,
  Attendeesbox,
  AttendeesItem,
  NumberField,
  NumberButton,
  ReceiptBox,
  UnderlineText,
  ModalContainer,
};
