import styled from "styled-components";

const Card = styled.div`
  position: relative;
  width: 100dvw;
  max-width: 632px;
  height: 100dvh;
  max-height: 383px;
  background: var(--palette-ss-white, #fff);
  border-radius: 30px;
`;

const Title = styled.span`
  color: var(--palette-ss-000, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.491px; /* 88.305% */
`;

const FieldTitle = styled.p`
  color: var(--palette-ss-black, #4a4a4a);
  margin-bottom: 10px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export default {
  Card,
  Title,
  FieldTitle,
};
