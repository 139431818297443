import React from "react";

// width: 250px;
//   height: 50px;
//   border: 1px solid #8c8c8c;
//   border-radius: 10px;
//   padding: 0px 15px;
//   @media ${breakpoint.mobile} {
//     width: 100%;
//     margin-bottom: 15px;
//   }

export const ClearSvg = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.2908 2.56066C21.8766 1.97487 21.8766 1.02513 21.2908 0.43934C20.705 -0.146447 19.7552 -0.146447 19.1695 0.43934L10.9999 8.60886L2.83041 0.43934C2.24463 -0.146447 1.29488 -0.146447 0.709093 0.43934C0.123306 1.02513 0.123306 1.97487 0.709093 2.56066L8.87861 10.7302L0.708871 18.8999C0.123085 19.4857 0.123085 20.4355 0.708871 21.0212C1.29466 21.607 2.24441 21.607 2.83019 21.0212L10.9999 12.8515L19.1697 21.0212C19.7555 21.607 20.7052 21.607 21.291 21.0212C21.8768 20.4355 21.8768 19.4857 21.291 18.8999L13.1213 10.7302L21.2908 2.56066Z"
        fill="black"
      />
    </svg>
  );
};

type SearchInputProps = {
  onClickClear?: () => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const SearchInput = React.forwardRef((props: SearchInputProps, ref) => {
  return (
    <div className="relative w-full md:w-[250px]">
      <input
        className="w-full md:w-[250px]  h-[50px] border-[1px] border-[#8c8c8c] rounded-[10px] pl-4 pr-10"
        {...props}
        ref={ref as React.Ref<HTMLInputElement>}
      />
      {props.onClickClear && (
        <button
          className="absolute right-3 top-0 h-full"
          onClick={props.onClickClear}
        >
          <ClearSvg className="h-4" />
        </button>
      )}
    </div>
  );
});

export default SearchInput;
