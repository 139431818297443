import styled from "styled-components";
import { breakpoint, size } from "../../utils/responsive";
import theme from "utils/theme";
import ImageStatusPromptBg from "assets/bg-statusPrompt.png";
import ImageStatusPromptBgMobile from "assets/bg-statusPromptMobile.png";

const PageContainer = styled.div`
  width: 100vw;
  //   height: 100dvh;
  //   height: auto;

  padding: 26px 20px 48px;
  z-index: 10;
  max-width: 1070.4px;
  @media (min-width: ${size.screen1024}px) {
    padding: 50px 0px 50px 0px;
  }
`;

const StatusPromptCotnainer = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${ImageStatusPromptBg});
  width: 840px;
  height: auto;
  max-width: calc(100% - 40px);
  height: 500px;
  border-radius: 30px;
  padding: 20px;

  @media ${breakpoint.mobile} {
    background-image: url(${ImageStatusPromptBgMobile});
    width: 100%;
    height: 80%;
  }
`;

export default {
  PageContainer,
  StatusPromptCotnainer,
};
