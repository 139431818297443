import React, { useCallback, useState } from "react";
import cn from "classnames";
import GeneralButton from "components/common/Button";
import Styled from "./Styled";
import { useTranslation } from "react-i18next";
import { Divider, Collapse } from "@mui/material";
import Typography from "components/common/Typography";

interface ReceiptProps {
  className?: React.HTMLProps<HTMLDivElement>["className"];
  totalAmount?: number;
  receiptComponent: React.ReactNode;
  handlePayment: () => void;
  disabledPayment?: boolean;
}

const Receipt = ({
  className,
  totalAmount,
  receiptComponent,
  handlePayment,
  disabledPayment = false,
}: ReceiptProps) => {
  const { t } = useTranslation(["booking", "common"]);
  const [isShowDetail, setIsShowDetail] = useState(false);

  const toggleDetail = useCallback(
    () => setIsShowDetail((prev) => !prev),
    [setIsShowDetail]
  );

  return (
    <Styled.ReceiptBox className={className}>
      <div className="hidden md:block">{receiptComponent}</div>
      <div className="h-[30px]" />
      <Divider />
      <div className="h-[22px]" />
      <div className="flex justify-between items-center">
        <Typography
          className="hidden md:block"
          fontSize="16px"
          fontWeight="500"
        >
          {t("bookingDetail.totalAmount")}
        </Typography>
        <Typography fontSize="20px" fontWeight="500">
          {t("common:price", { price: totalAmount })}
        </Typography>
      </div>
      <button className="md:hidden" type="button" onClick={toggleDetail}>
        <p
          className={cn(
            "text-app-grey-text text-sm font-normal leading-5 tracking-normal text-left",
            "flex items-center gap-1"
          )}
        >
          <slot>Details</slot>
          <svg
            className={cn(isShowDetail ? "" : "rotate-180")}
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1.04297L6.04395 6.08691L11.0879 1.04297"
              stroke="#8C8C8C"
              stroke-linecap="round"
            />
          </svg>
        </p>
      </button>
      <Collapse in={isShowDetail} className="mt-[20px]">
        <section>{receiptComponent}</section>
      </Collapse>
      <div className="hidden justify-center mt-[30px] md:flex">
        <GeneralButton
          onClick={handlePayment}
          disabled={disabledPayment}
          width="150px"
        >
          {t("bookingDetail.payment")}
        </GeneralButton>
      </div>
    </Styled.ReceiptBox>
  );
};

export default Receipt;
