import Typography from "components/common/Typography";
import theme from "utils/theme";
import Styled from "./Styled";
import Checkbox from "components/common/Checkbox";
import SimpleModal from "components/common/Modal/SimpleTextModal";
import { useState } from "react";
import { getLocalisedString } from "common/utils/stringHelper";
import CloseIconModal from "components/common/Modal/CloseIconModal";
import { useTranslation } from "react-i18next";
import Space from "components/common/Space";

interface TncCheckboxProps {
  id: string;
  name: string;
  text: string;
  value: boolean;
  onChange: () => void;
  clickableText?: string;
}

const FacilityRuleCheckbox = ({
  id,
  name,
  text,
  value,
  onChange,
  clickableText,
}: TncCheckboxProps) => {
  const { t } = useTranslation("booking");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <div className="flex-shrink-0">
        <Checkbox id={id} name={name} onChange={onChange} checked={value} />
      </div>
      <div className="flex flex-col">
        <Typography fontSize="14px">{text}</Typography>
        <Typography
          fontSize="14px"
          color={theme.orange}
          className="inline cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          {" " + clickableText}
        </Typography>
      </div>

      <CloseIconModal needResponsive={true} open={isOpen} onClose={() => setIsOpen(false)}>
        <Typography color={theme.black} fontSize="20px" fontWeight="500">
          {t("facilityRule.title")}
        </Typography>
        <Space size="15px" />
        <Typography>{t("facilityRule.desc")}</Typography>
        <div className="py-[20px]">
          <Typography fontWeight="500">
            {t("facilityRule.operatingHours")}
          </Typography>
        </div>
        <Typography>{t("facilityRule.operatingHoursContent")}</Typography>

        <div className="py-[20px]">
          <Typography fontWeight="500">
            {t("facilityRule.generalUseRules")}
          </Typography>
        </div>

        {Array.from(Array(15).keys()).map((item, idx) => (
          <div key={item}>
            <Typography>{t(`facilityRule.rules${item + 1}`)}</Typography>
            {item + 1 === 2 && (
              <div className="ml-[15px]">
                <Typography>
                  {t(`facilityRule.rules${item + 1}Extend`)}
                </Typography>
              </div>
            )}
          </div>
        ))}

        <Space size="20px" />
        <Typography>{t("facilityRule.generalUseRulesContent")}</Typography>
      </CloseIconModal>
    </div>
  );
};

export default FacilityRuleCheckbox;
