import React from "react";
import { uniqueId } from "lodash";

import { useTranslation } from "react-i18next";
import TextField from "../Textfield";

import { FieldError, StyledField } from "./styles";
import {
  BasicDatePicker,
  BasicDateTimePicker,
  BasicTimePicker,
} from "../DatePicker";
import Checkbox from "../Checkbox";
import { Select, SelectOption } from "../Select";

type FormFieldProps = {
  id: string;
  className?: string;
  label?: string;
  tip?: string;
  error?: string;
  name: string;
  required?: boolean;
};

const generateField = <T,>(FormComponent: React.ComponentType<T>) => {
  function FieldComponent({
    id,
    className,
    label,
    tip,
    error,
    name,
    required,
    ...otherProps
  }: T & FormFieldProps) {
    const { t } = useTranslation();
    const fieldId = id || uniqueId("form-field-");

    return (
      <StyledField
        className={className}
        // hasLabel={!!label}
        data-testid={name ? `form-field:${name}` : "form-field"}
      >
        <FormComponent
          id={fieldId}
          error={!!error}
          name={name}
          {...(otherProps as T)}
        />
        {error && <FieldError>{t(error)}</FieldError>}
      </StyledField>
    );
  }

  return FieldComponent;
};

export default {
  TextField: generateField(TextField),
  Select: generateField(Select),
  SelectOption: SelectOption,
  // TextArea: generateField(BackgroundTextArea),
  CheckBox: generateField(Checkbox),
  // Password: generateField(BackgroundPasswordField),
  // // TextEditor: generateField(TextEditor),
  DatePicker: generateField(BasicDatePicker),
  TimePicker: generateField(BasicTimePicker),
  DateTimePicker: generateField(BasicDateTimePicker),
  // IOSSwitch: generateField(IOSSwitch),
};
