import React from "react";
import Styled from "./Styled";
import theme from "utils/theme";

export type VoucherListCardBaseProps = {
  color?: "primary" | "secondary";
  disabled?: boolean;
};

type Props = {
  children: React.ReactNode;
};

const backgroundColorMap = {
  primary: "#FFF",
  secondary: "#F5F5F5",
  disabled: "#EAEAEA",
};

const borderColorMap = {
  primary: theme.orange,
  secondary: theme.orange,
  disabled: "#8C8C8C",
};

const CardBase = (props: Props & VoucherListCardBaseProps) => {
  const { color = "primary", disabled } = props;
  return (
    <Styled.Card
      backgroundColor={
        disabled ? backgroundColorMap["disabled"] : backgroundColorMap[color]
      }
      borderColor={
        disabled ? borderColorMap["disabled"] : borderColorMap[color]
      }
    >
      {props.children}
    </Styled.Card>
  );
};

export default CardBase;
