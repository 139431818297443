import { Divider } from "@mui/material";
import Typography from "components/common/Typography";
import Space from "components/common/Space";
import styled from "styled-components";
import theme from "utils/theme";

type FieldType = {
  title: string;
  titleLabel?: React.ReactNode;
  value?: string | null;
  rightTitle?: string;
  rightClick?: (setValue: boolean) => void;
  editComponent?: React.ReactNode;
  isEdit?: boolean;
};

const FieldName = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  algins-items: center;
`;

const EditButton = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
`;

const InfoField = ({
  title,
  titleLabel,
  value,
  rightClick,
  rightTitle,
  editComponent,
  isEdit,
}: FieldType) => {
  return (
    <div style={{ margin: "10px 0px" }}>
      <div className="flex flex-col justify-between gap-[10px] lg:flex-row">
        <div className="left">
          <FieldName>
            <Typography fontSize="14px" fontWeight="500">
              {title}
            </Typography>
            {titleLabel}
          </FieldName>
          <Space size="15px" />
          {isEdit ? (
            editComponent
          ) : (
            <>
              <Typography fontSize="16px" color={theme.grey}>
                {value ?? "N/A"}
              </Typography>
              <Space size="8px" />
            </>
          )}
        </div>
        <div className="right">
          <div className="h-full flex flex-col justify-end">
            {rightTitle && rightClick && (
              <EditButton
                fontSize="14px"
                fontWeight="500"
                color={theme.orange}
                onClick={rightClick}
              >
                {rightTitle}
              </EditButton>
            )}
          </div>
        </div>
      </div>
      <Space size="12px" />
      <Divider />
    </div>
  );
};

export default InfoField;
