import { ROUTE_BASE } from "Route";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const PaymentScreen = () => {
  const navigate = useNavigate();
  const [refNo, setRefNo] = useState<string>();
  const [failReason, setFailReason] = useState<string>();
  const [bookingType, setBookingType] = useState<string>();

  return (
    <div>
      <Outlet
        context={{
          refNo,
          setRefNo,
          failReason,
          setFailReason,
          bookingType,
          setBookingType,
        }}
      />
    </div>
  );
};

export default PaymentScreen;
