import { useEffect, useState } from "react";
import {
  listConfirmedFacilityBooking,
  listMyFacilityBooking,
} from "../../network/booking";
import { listEventBookings } from "../../network/event";
import {
  appearGlobalError,
  appearGlobalLoading,
  disappearGlobalError,
  disappearGlobalLoading,
} from "../../context/requests/globalRequest";
import { listConfirmedCoachBooking } from "../../network/coachLesson";
import { listEnrollment } from "../../network/monthlyProgram";
import { useCommonFetcher } from "../Common/CommonViewModel";
import { FacilityBookingOrder } from "../../models/FacilityBookingOrder";

export const ProgramTypes = {
  Program: "program",
  Coach: "coach",
  Event: "event",
  Facility: "facility",
};

export const useMyBookingListViewModel = ({ isLoggedIn = false, dispatch }) => {
  const { fetcher } = useCommonFetcher();
  const [selectedProgramType, setSelectedProgramType] = useState(
    ProgramTypes.Program
  );
  const [monthlyProgramBookingList, setMonthlyProgramBookingList] = useState(
    []
  );
  const [coachLessonBookingList, setCoachLessonBookingList] = useState([]);
  const [eventBookingList, setEventBookingList] = useState([]);
  const [facilityBookingList, setFacilityBookingList] = useState([]);

  useEffect(() => {
    refreshBookingList();
  }, [selectedProgramType, isLoggedIn]);

  const refreshBookingList = async () => {
    if (!!isLoggedIn) {
      // Only get booking record on login
      switch (selectedProgramType) {
        case ProgramTypes.Program:
          await getMyProgramBookings();
          break;
        case ProgramTypes.Coach:
          await getMyCoachBookingList();
          break;
        case ProgramTypes.Event:
          await getMyEventBookings();
          break;
        case ProgramTypes.Facility:
          await getMyFacilityBookingList();
      }
    }
  };

  const switchBookingListTab = (bookingType) => {
    switch (bookingType) {
      case ProgramTypes.Program:
      case ProgramTypes.Coach:
      case ProgramTypes.Event:
      case ProgramTypes.Facility:
        setSelectedProgramType(bookingType);
      default:
        break;
    }
  };

  const getMyProgramBookings = async () => {
    const res = await fetcher({ api: () => listEnrollment({}) });
    setMonthlyProgramBookingList(
      res.result.map((el) => ({ ...el, lessons: el.lessons }))
    );
    return res;
  };

  const getMyCoachBookingList = async () => {
    const res = await fetcher({ api: () => listConfirmedCoachBooking() });
    setCoachLessonBookingList(res?.result);
  };

  const getMyEventBookings = async () => {
    const res = await fetcher({ api: () => listEventBookings() });
    setEventBookingList(res.result);
    return res;
  };

  const getMyFacilityBookingList = async () => {
    const res = await fetcher({ api: () => listConfirmedFacilityBooking() });
    setFacilityBookingList(
      res?.result?.map((order) => new FacilityBookingOrder(order))
    );
    return res;
  };

  return {
    monthlyProgramBookingList,
    coachLessonBookingList,
    eventBookingList,
    facilityBookingList,
    selectedProgramType,
    switchBookingListTab,
    refreshBookingList,
  };
};
