import React from "react";

type Props = {
  onClick?: () => void;
};

const NavPageOverlay = (props: Props) => {
  return <div className="block fixed z-[999] inset-0 bg-black/70" {...props} />;
};

export default NavPageOverlay;
