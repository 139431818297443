import React from "react";
import styled from "styled-components";
import Typography from "./Typography";
import IconHeaderTitle from "../../assets/ic-headerTitle.svg";
import theme from "../../utils/theme";
import Space from "./Space";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface TitleProps {
  title: string;
  inverted?: boolean;
}

const HeaderTitle = ({ title, inverted }: TitleProps) => {
  return (
    <Container>
      <img src={IconHeaderTitle} />
      <Space width="5px" />
      <Typography
        color={inverted ? theme.white : theme.black}
        fontSize="18px"
        whiteSpace="nowrap"
      >
        {title}
      </Typography>
    </Container>
  );
};

export default HeaderTitle;
