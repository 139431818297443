import parsePhoneNumberFromString, {
  CountryCode,
  getCountryCallingCode,
  parsePhoneNumberWithError,
} from "libphonenumber-js";
import { CountryOption } from "../models/CountryOption";

export const getE164Format = (number: string, country: string): string => {
  return `${country}${number}`;
  // try {
  //   const parsedPhoneNumber = parsePhoneNumberWithError(number, {
  //     defaultCountry: country,
  //   });

  //   return parsedPhoneNumber?.number;
  // } catch (err) {
  //   return `+${getCountryCallingCode(country) ?? "852"}${number}`;
  // }
};
