import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import cn from "classnames";
import saishaLoginPng from "../../assets/login-decorate-large.png";
import LoginBackground from "../../components/LoginBackground/LoginBackground";
import Card from "../../components/common/Card";
import Styled from "./Styled";
import { UserViewModelContext } from "../../common/viewModels/UserViewModel";
import { IUserViewModelContext } from "../../@types/common";
import TempLoading from "../../components/common/TempLoading";
import Typography from "components/common/Typography";

enum PageSearchParamsKey {
  callbackUrl = "callbackUrl",
}

const DefaultPageSearchParams: Record<PageSearchParamsKey, string> = {
  callbackUrl: "",
};

type ContextType = { setIsLoading: Dispatch<SetStateAction<boolean>> };

const LoginPageControl = () => {
  // router
  const [searchParams] = useSearchParams(DefaultPageSearchParams);
  const navigate = useNavigate();
  const location = useLocation();

  // states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentUser, isRetriving } =
    useContext<IUserViewModelContext>(UserViewModelContext);

  // effects
  useEffect(() => {
    if (
      isRetriving === false &&
      currentUser != null &&
      !location?.pathname?.includes("reset-password")
    ) {
      const callBackUrl = searchParams.get(PageSearchParamsKey.callbackUrl);
      if (callBackUrl != null && callBackUrl.length > 0) {
        navigate(callBackUrl);
      } else {
        navigate("/");
      }
    }
  }, [currentUser, isRetriving, navigate, searchParams]);

  // empty page for loading local auth status
  if (isRetriving === true) return <></>;

  return (
    <>
      <LoginBackground className={cn("grid", "place-items-center")}>
        <Styled.PageContainer>
          <Card width="100%" height="100%">
            {isLoading ? (
              <Styled.LoadingContainer>
                <TempLoading />
              </Styled.LoadingContainer>
            ) : (
              <>
                <div
                  className={cn(
                    "w-full",
                    "h-full",
                    "p-[36px]",
                    "overflow-y-auto",
                    "flex",
                    "flex-col",
                    "items-center",
                    "gap-8",
                    "lg:p-[15px]",
                    "lg:flex-row",
                    "lg:gap-0"
                  )}
                >
                  <div
                    className={cn(
                      "w-full",
                      // "pt-[15px]",
                      "h-full",
                      "flex",
                      "flex-col",
                      "justify-center",
                      "items-center",
                      "lg:pt-[30px]",
                      "lg:w-1/2"
                    )}
                  >
                    <Outlet context={{ setIsLoading }} />
                  </div>
                  <div
                    className={cn("relative", "w-full", "h-full", "lg:w-1/2")}
                  >
                    <Styled.DecorateImg src={saishaLoginPng} />
                    <Styled.LoginImageCaption
                      className={cn(
                        "absolute",
                        "bottom-14",
                        "left-1/2",
                        "-translate-x-1/2",
                        "whitespace-nowrap"
                      )}
                    >
                      A lifestyle of boundless discoveries
                    </Styled.LoginImageCaption>
                  </div>
                </div>
              </>
            )}
          </Card>
        </Styled.PageContainer>
      </LoginBackground>
    </>
  );
};

export function useLoginLoading() {
  return useOutletContext<ContextType>();
}

export default LoginPageControl;
