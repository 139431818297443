import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import EventPage from "./EventPage";
import EventDetailPage from "./EventDetailPage";
import EventBookingPage from "./EventBookingPage";

const RegistrationRouter: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <EventPage />,
    },
    {
      // to prevent hitting url /event/booking enter /event/:id route
      path: "/booking",
      element: <Navigate to="/event" />,
    },
    {
      path: "/booking/:id",
      element: <EventBookingPage />,
    },
    {
      path: "/:id",
      element: <EventDetailPage />,
    },
    {
      path: "*",
      element: <Navigate to="/event" />,
    },
  ]);
};

export default RegistrationRouter;
