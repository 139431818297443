import { SaiShaAPIFailureResponse } from "../../@types/common";

export interface ILatestNewViewModel {
    getLatestNews: (limit?: number, skip?: number, skipLoading?: boolean) => Promise<void>;
    getFilteredLatestNews: (
      keyword: string,
      limit?: number,
      skip?: number
    ) => Promise<void>;
    getLatestNewsDetail: (id: string) => Promise<{ result: ILatestNew, success: boolean } | SaiShaAPIFailureResponse<object>>;
    latestNews: ILatestNew[];
    latestNewsCount: number | null;
    latestNewsDetail: ILatestNew;
}

export interface ILatestNew {
    createAt: string;
    desc_en: string;
    desc_zh: string;
    end_datentime: string;
    start_datentime: string;
    id: string;
    status: string;
    sub_title_en: string;
    sub_title_zh: string;
    thumbnail_ref_en: string;
    thumbnail_ref_zh: string;
    title_en: string;
    title_zh: string;
    updatedAt: string;
    _id: string;
}

export class LatestNew implements ILatestNew {
    createAt: string;
    desc_en: string;
    desc_zh: string;
    end_datentime: string;
    start_datentime: string;
    id: string;
    status: string;
    sub_title_en: string;
    sub_title_zh: string;
    thumbnail_ref_en: string;
    thumbnail_ref_zh: string;
    title_en: string;
    title_zh: string;
    updatedAt: string;
    _id: string;
  
    constructor({
        createAt,
        desc_en,
        desc_zh,
        end_datentime,
        start_datentime,
        id,
        status,
        sub_title_en,
        sub_title_zh,
        thumbnail_ref_en,
        thumbnail_ref_zh,
        title_en,
        title_zh,
        updatedAt,
        _id,
    }: any) {
      this.createAt = createAt;
      this.desc_en = desc_en;
      this.desc_zh = desc_zh;
      this.end_datentime = end_datentime;
      this.start_datentime = start_datentime;
      this.id = id;
      this.status = status;
      this.sub_title_en = sub_title_en;
      this.sub_title_zh = sub_title_zh;
      this.thumbnail_ref_en = thumbnail_ref_en;
      this.thumbnail_ref_zh = thumbnail_ref_zh;
      this.title_en = title_en;
      this.title_zh = title_zh;
      this.updatedAt = updatedAt;
      this._id = _id;
    }
  }
  