import Typography from "../Typography";
import Checkbox from "../Checkbox";
import theme from "utils/theme";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface ClickableCheckboxProps {
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickText?: () => void;
  text: string;
  clickableText?: any;
  endText?: string;
  enableShowMore?: boolean;
}

const ClickableCheckbox = ({
  value,
  onChange,
  onClickText,
  text,
  clickableText,
  endText,
  enableShowMore,
}: ClickableCheckboxProps) => {
  const { t } = useTranslation("common");
  const [isShowMore, setIsShowMore] = useState<boolean>(
    enableShowMore ?? false
  );

  return (
    <div className="flex justify-start gap-[10px] mb-[5px]">
      <div className="flex-shrink-0">
        <Checkbox id="tnc" name="tnc" value={value} onChange={onChange} />
      </div>
      <div className="mt-[5px]">
        <Typography fontSize="14px" style={{ display: "inline" }}>
          {text}
        </Typography>
        <Typography
          color={theme.orange}
          style={{ cursor: "pointer", display: "inline" }}
          onClick={onClickText}
          fontSize="14px"
        >
          {clickableText}
        </Typography>
        {isShowMore ? (
          <Typography
            fontSize="14px"
            color={theme.blue}
            fontWeight="600"
            className="cursor-pointer"
            onClick={() => setIsShowMore(!isShowMore)}
          >
            {t("button.showMore")}
          </Typography>
        ) : (
          <Typography style={{ display: "inline" }} fontSize="14px">
            {endText}
          </Typography>
        )}
      </div>
    </div>
  );
};
export default ClickableCheckbox;
