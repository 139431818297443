import { useEffect, useState } from "react";

export const useCountDownViewModel = () => {
  const [isCountDown, setIsCountDown] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!isCountDown) {
      return;
    }

    if (!timeLeft) {
      setIsCountDown(false);
      return;
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, isCountDown]);

  function startCountDown() {
    setTimeLeft(59);
    setIsCountDown(true);
  }

  return { isCountDown, timeLeft, startCountDown };
};
