import React, { useCallback, useContext, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as _ from "lodash";
import dayjs from "common/utils/dayjs";
import { useTranslation } from "react-i18next";
import { getLocalisedString } from "common/utils/stringHelper";
import { useEventBookingViewModel } from "common/viewModels/EventBooking/EventBookingViewModel";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { useEventBookingConfirmationViewModel } from "common/viewModels/EventBooking/EventBookingConfirmationViewModel";
import { confirmModalContext } from "contexts/ConfirmModalProvider";
import { Event, EventType } from "models";
import PromoCodeBox from "components/boxs/PromoCodeBox";
import Section from "pageComponents/booking/Section";
import BookingBase, {
  ProgramTypeKeys,
} from "pageComponents/booking/BookingBase";
import AttendessSelect from "pageComponents/booking/AttendessSelect";
import { ROUTE_BASE } from "Route";
import { createPayment } from "utils/common";
import theme from "utils/theme";
import {
  IEventBookingViewModel,
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../@types/common";
// import IconLoader from "../../assets/IconLoader";
// import Checkbox from "../../components/common/Checkbox";
import Typography from "../../components/common/Typography";
import Styled from "./Styled";
import { useVoucherViewModel } from "common/viewModels/VoucherViewModel";
import { IVoucherViewModel } from "models/Voucher";
import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import ReceiptDiscount from "pageComponents/booking/ReceiptDiscount";
import { getCorrectUrl } from "navigators/Router";

export const SlotItem: React.FC<{ slots: string[] }> = ({ slots }) => {
  return (
    <ul>
      {_.map(slots, (slot) => {
        return <li>{slot}</li>;
      })}
    </ul>
  );
};

const EventBookingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id: eventId } = useParams();
  const state = location?.state;
  const currentEvent: Event = state?.item;
  const { openModal: openConfirmModal } = useContext(confirmModalContext);
  // const [isAgree, setIsAgree] = useState<boolean>(false);
  const { t } = useTranslation("common");

  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { currentUser, isRetriving } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const {
    numOfAttendees,
    maxAttendeesPerBooking,
    promoCode,
    appliedVouchers,
    onPressAddAttendees,
    onPressSubtractAttendees,
    applyPromoCode,
    removePromoCode,
    applyVoucher,
    removeVoucher,
    // getSubtotalPrice,
    getTotalPrice,
    getBookingSummary,
  } = useEventBookingViewModel({
    event: currentEvent,
    dispatch: globalDispatch,
  }) as IEventBookingViewModel;
  const { confirmBooking } = useEventBookingConfirmationViewModel();
  const { getBookingVouchers, bookingVouchers } = useVoucherViewModel({
    dispatch: globalDispatch,
  }) as IVoucherViewModel;

  const handlePayment = useCallback(async () => {
    const summary = getBookingSummary();

    // Query the create Booking
    const bookingRequest = {
      ...summary?.payload,
      platform: "WEB",
    };

    const bookingRes = await confirmBooking(bookingRequest);

    if (bookingRes.success === true) {
      createPayment(bookingRes, globalDispatch, navigate, getTotalPrice() ?? 0);
    } else if (!bookingRes?.needVerification) {
      openConfirmModal({ title: bookingRes?.error });
    } else if (bookingRes?.isRedirectHandle) {
      navigate(getCorrectUrl(`${ROUTE_BASE.EVENT}/${eventId}`));
    }
  }, [globalDispatch, getBookingSummary, confirmBooking, openConfirmModal, t]);

  const formattedDateList = useMemo(() => {
    if (currentEvent == null) {
      return [];
    }
    return currentEvent.event_date.map((timeSlot) => {
      const startDate = dayjs(timeSlot?.start_date);
      const endDate = dayjs(timeSlot?.end_date);
      const dateString =
        startDate.tz().format("DD/MM/YYYY, HH:mm - ") + endDate.tz().format("HH:mm");

      return dateString;
    });
  }, [currentEvent]);

  const refreshVoucherList = useCallback(() => {
    if (currentEvent && currentUser && isRetriving === false) {
      getBookingVouchers({
        bookingType: ProgramTypes.Event,
        category: currentEvent?.event_type?._id,
      });
    }
  }, [currentEvent, currentUser, isRetriving]);

  const bookingSummary = currentEvent
    ? getBookingSummary().bookingSummary
    : null;

  if (location.state == null || location.state.item == null) {
    navigate(ROUTE_BASE.EVENT);
  }

  return (
    <main>
      {/* <Styled.Wrapper>
        <Styled.EventDetailContent>
          <Styled.BookingBox>
            <Styled.BookingInfo>
              <Styled.BookingInfoTitle>
                {t("event:booking.promoCode")}/{t("event:booking.voucher")}
              </Styled.BookingInfoTitle>
              <Styled.Paragraph>
                <Styled.PromoBox>
                  <Styled.Row
                    style={{ color: "#4A4A4A", marginBottom: "20px" }}
                  >
                    {t("event:booking.enterYour")}{" "}
                    {t("event:booking.promoCode")}
                  </Styled.Row>
                  <Styled.PromoInputBox>
                    <Styled.PromoInput
                      placeholder={t("event:booking.promoCode")}
                    ></Styled.PromoInput>
                    <Styled.PromoBtn>
                      {t("event:booking.apply")}
                    </Styled.PromoBtn>
                  </Styled.PromoInputBox>
                  <Styled.Hr style={{ marginBottom: "20px" }} />
                  <Styled.Row style={{ color: "#4A4A4A" }}>
                    {t("event:booking.voucher")}
                  </Styled.Row>
                  <Styled.Row>
                    <Styled.PromoAddVoucher>
                      {t("event:booking.add")} {t("event:booking.voucher")}
                    </Styled.PromoAddVoucher>
                  </Styled.Row>
                </Styled.PromoBox>
              </Styled.Paragraph>
              <Styled.GroupField>
                <Checkbox
                  id="isAgree"
                  name="isAgree"
                  value={isAgree}
                  onChange={() => setIsAgree(!isAgree)}
                  style={{ float: "left" }}
                />
                <Styled.CheckboxLabel>
                  *{t("event:checkbox.accept")}{" "}
                  <span>{t("event:checkbox.note")}</span>
                </Styled.CheckboxLabel>
              </Styled.GroupField>
            </Styled.BookingInfo>
          </Styled.BookingBox>
        </Styled.EventDetailContent>
      </Styled.Wrapper> */}
      <div className="mx-[20px] md:mx-0">
        <BookingBase
          programTypes={ProgramTypes.Event as ProgramTypeKeys}
          orderInfoComponent={
            <React.Fragment>
              <h4 style={{ fontWeight: 500, marginBottom: "10px" }}>
                {getLocalisedString(
                  currentEvent?.title_en,
                  currentEvent?.title_tc
                )}
              </h4>
              <Styled.Paragraph>
                <SlotItem slots={formattedDateList} />
              </Styled.Paragraph>
            </React.Fragment>
          }
          remarks={getLocalisedString(
            currentEvent?.remarks_en,
            currentEvent?.remarks_tc
          )}
          tnc={currentEvent?.t_and_c}
          totalAmount={getTotalPrice() ?? 0}
          receiptComponent={
            <Styled.ReceivedInfo>
              <Typography color={theme.black} fontWeight="600">
                {getLocalisedString(
                  currentEvent?.title_en,
                  currentEvent?.title_tc
                )}
              </Typography>
              <div className="h-[10px]" />
              {formattedDateList.map((detail) => (
                <p key={detail}>{detail}</p>
              ))}
              <p>{bookingSummary?.venue}</p>
              <p>{`HK$ ${bookingSummary?.price}`}</p>
              {promoCode == null && appliedVouchers.length <= 0 ? null : (
                <React.Fragment>
                  <div className="h-[10px]" />
                  <ReceiptDiscount
                    promoCode={promoCode}
                    appliedVoucher={appliedVouchers}
                  />
                </React.Fragment>
              )}
            </Styled.ReceivedInfo>
          }
          handlePayment={handlePayment}
        >
          {currentEvent?.guest_invite_limit && (
            <Section title={t("booking:facilityDetail.selectQuantity")}>
              <AttendessSelect
                quantity={numOfAttendees}
                handleAdd={onPressAddAttendees}
                handleSub={onPressSubtractAttendees}
                maxQuota={maxAttendeesPerBooking}
              />
            </Section>
          )}
          <Section
            title={`${t("event:booking.promoCode")}/${t(
              "event:booking.voucher"
            )}`}
          >
            <PromoCodeBox
              code={promoCode}
              onApplyPromoCode={applyPromoCode}
              onRemovePromoCode={removePromoCode}
              voucherDisabled={getTotalPrice() <= 0}
              onApplyVoucher={applyVoucher}
              onRemoveVoucher={removeVoucher}
              refreshVoucherList={refreshVoucherList}
              selectedVouchers={appliedVouchers}
              availableVouchers={bookingVouchers}
            />
          </Section>
        </BookingBase>
      </div>
    </main>
  );
};

export default EventBookingPage;
