import { GlobalContextStore } from "common/context/providers/globalProvider";
import {
  appearGlobalLoading,
  disappearGlobalLoading,
} from "common/context/requests/globalRequest";
import { useMyPassViewModel } from "common/viewModels/MyPassViewModel";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import dayjs from "dayjs";
import { useSplashScreen } from "hooks/useSplashScreen";
import { getCorrectUrl } from "navigators/Router";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePageVisibility } from "react-page-visibility";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import { cn } from "utils/common";
import { WindowSize } from "utils/responsive";
import {
  IGlobalProviderContext,
  IMyPassViewModel,
  IUserViewModelContext,
} from "../../@types/common";
import qrLogoPng from "../../assets/qr-saisha-logo.png";

const SportPassLabel = (props: { currentUser: any }) => {
  const { currentUser } = props;
  const { t } = useTranslation("profile");
  return (
    <div className={cn("flex flex-col items-center gap-2.5")}>
      <span
        className={cn(
          "text-white text-sm md:text-xl font-normal text-center font-poppins",
          "bg-[#FF7925] px-[12.45px] w-max h-[34.85px] grid place-items-center"
        )}
      >
        {t(
          `profile:personalInfo.${currentUser?.sportpass?.type?.toLowerCase()}Pass`
        )}
      </span>
      <span className="text-sm md:text-xl font-medium leading-[19.07px] md:leading-[27.24px] text-left">
        {`${t("sportPass.expiryDate")} ${dayjs(
          currentUser?.sportpass?.expiry_date
        )
          .tz()
          .format("DD/MM/YYYY")}`}
      </span>
    </div>
  );
};

const MyPassScreen = () => {
  const {
    currentUser,
    isRetriving: isRetrivingUser,
    isCurrentPassExpired,
    refreshCurrentUser,
  } = useContext<IUserViewModelContext>(UserViewModelContext);
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const {
    qrCode,
    requestDoorAccessQRCode,
    requestDoorAccessQRCodeWithPreGen,
    expiredTimeInSecond,
  } = useMyPassViewModel() as IMyPassViewModel;
  const { t, i18n } = useTranslation();
  const visibility = usePageVisibility();
  const { isSplashScreen } = useSplashScreen();
  const navigate = useNavigate();
  const isMobile = WindowSize() === "mobile";

  useEffect(() => {
    if (isRetrivingUser === false && currentUser == null) {
      navigate(getCorrectUrl(ROUTE_BASE.AUTH + "/sign-in"), { replace: true });
    }
  }, [isRetrivingUser, currentUser, navigate]);

  useEffect(() => {
    if (!isSplashScreen && visibility === true && currentUser != null) {
      // requestDoorAccessQRCodeWithPreGen(false, true);

      refreshCurrentUser().then(() => {
        appearGlobalLoading(globalDispatch);
        requestDoorAccessQRCodeWithPreGen(false, true);
      });
    }

    // prevent currentUser inside the dependencies cause infinite refresh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSplashScreen, visibility]);

  if (isSplashScreen) return null;

  return (
    <div
      className={cn(
        "h-max min-h-[600px]",
        "px-5 mt-[40px] md:mt-[70px] font-noto-sans"
      )}
    >
      <div
        className={cn(
          "w-full h-auto max-w-[1280px] min-h-[242px] mx-auto px-8 md:h-full md:min-h-[680px] md:pt-[103px]",
          "flex flex-col justify-center items-center md:justify-start",
          "bg-[#F0FAFF] rounded-[15px]",
          "pt-[70px] pb-[80px]"
        )}
      >
        <div className="w-full max-w-[273px] text-sm font-medium leading-[19.07px] text-center text-app-black">
          {!!currentUser?.sportpass && !isCurrentPassExpired && (
            <SportPassLabel currentUser={currentUser} />
          )}
        </div>
        <div className="mt-5 h-max flex-shrink-1">
          {currentUser && qrCode && (
            <div className="relative p-2 bg-white">
              <QRCode level="H" size={isMobile ? 240 : 300} value={qrCode} />
              <div
                className={cn(
                  "h-[100px] w-[100px] md:h-[100px] md:w-[100px]",
                  "grid place-items-center",
                  "bg-white",
                  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                )}
              >
                <img src={qrLogoPng} width={100} height={100} alt="qr-logo" />
              </div>
            </div>
          )}
        </div>
        {expiredTimeInSecond !== undefined && expiredTimeInSecond >= 0 && (
          <p className="mt-5 text-sm md:text-xl text-app-black">
            {t("profile:personalInfo.qrCodeExpiredIn", {
              seconds: expiredTimeInSecond,
            })}{" "}
          </p>
        )}
      </div>
    </div>
  );
};

export default MyPassScreen;
