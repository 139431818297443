import styled from "styled-components";
import { useTranslation } from "react-i18next";

const GreyLabel = styled.div`
  padding: 3px 16px;
  border-radius: 16px;
  background-color: #8C8C8C;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
`

function GreyFullLabel() {
  const { t } = useTranslation("event");
  return (
    <GreyLabel>
      {t('category.full')}
    </GreyLabel>
  );
}

export default GreyFullLabel;