import { useNotificationDetailViewModel } from "common/viewModels/NotificationCenter/NotificationDetailViewModel";
import {
  INotificationDetailViewModel,
  NotificationType,
} from "models/Notification";
import React, { useEffect, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Styled from "./Styled";
import { NotificationCategoryTag } from "common/models/Notification";
import CategoryTag from "pageComponents/notifacation/CategoryTag";
import HTMLTypography from "components/common/HTMLTypography";
import cn from "classnames";
import dayjs from "common/utils/dayjs";
import theme from "utils/theme";
import GeneralButton from "components/common/Button";
import { ROUTE_BASE } from "Route";
import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import { useTranslation } from "react-i18next";
import MyTypography from "components/common/Typography";
import _ from "lodash";
import i18n from "i18n";
import PageBackButton from "components/common/PageBackButton";
//{ notificationType: NotificationType }
const NotificationDetail = () => {
  const { id, lang } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation("notification");
  const { notificationDetail, getNotificationDetail, getProgramType } =
    useNotificationDetailViewModel({
      notificationId: id,
    }) as INotificationDetailViewModel;

  useEffect(() => {
    getNotificationDetail().then((res) => {
      if (!res?.success) navigate(ROUTE_BASE.NOTIFICATION);
    });
  }, []);

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div
        className={cn("w-full", "hidden", "md:block", "mb-[25px]", "lg:block")}
      >
        <PageBackButton
          onClick={handleClickBack}
          text={t("monthlyProgram:monthlyProgramDetail.btnBack")}
        />
      </div>
      <Styled.PageContainer>
        {notificationDetail && (
          <>
            <div>
              {notificationDetail?.notification_category_tag && (
                <div className="pb-5">
                  <CategoryTag
                    tag={notificationDetail.notification_category_tag}
                  />
                </div>
              )}
              <MyTypography fontSize="20px" color={theme.black}>
                {notificationDetail.message?.title}
              </MyTypography>
              {notificationDetail.message?.body && (
                <HTMLTypography
                  content={notificationDetail.message.body}
                  className={cn(["font-light", `text-[${theme.grey}]`])}
                />
              )}
              <div
                className={cn(
                  `text-[${theme.orange}]`,
                  "text-xs",
                  "pt-2",
                  "pb-5"
                )}
              >
                {dayjs(notificationDetail.sent_at).tz().format("DD/MM/YYYY")}
              </div>
              <div>
                {notificationDetail.additional?.order_id && (
                  <GeneralButton
                    onClick={() => {
                      const id = notificationDetail.additional
                        ?.order_id as string;

                      navigate(
                        `/${i18n.language}/${ROUTE_BASE.MY_BOOKINGS}/${id}`,
                        {
                          state: {
                            bookingType: getProgramType(),
                            //isPastBooking: true,
                          },
                        }
                      );
                    }}
                  >
                    {t("title.viewBooking")}
                  </GeneralButton>
                )}
              </div>
            </div>
          </>
        )}
      </Styled.PageContainer>
    </>
  );
};

export default NotificationDetail;
