import React from "react";
import Styled from "./Styled";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconLoader from "assets/IconLoader";

export interface ReadMoreBoxItem {
  id: string;
  title: string;
  tag?: {
    title: string;
    color: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
  };
  timeSlot?: string[];
  onClick: () => void;
  img: string;
  desc: string;
}

const ReadMoreBox = ({
  id,
  title,
  tag,
  timeSlot,
  onClick,
  img,
  desc,
}: ReadMoreBoxItem) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  return (
    <Styled.BoxItem onClick={onClick}>
      <Styled.ItemImg img={img}></Styled.ItemImg>
      <Styled.ItemContent>
        {tag && (
          <Styled.ItemTag bg={tag.color}>
            <Styled.TagTitle>{tag.title}</Styled.TagTitle>
            {/* <item.tag.icon></item.tag.icon> */}
          </Styled.ItemTag>
        )}
        <Styled.ItemTitle>{title}</Styled.ItemTitle>
        <Styled.ItemDesc>{desc}</Styled.ItemDesc>
        <Styled.ItemLink>
          {t("button.readMore")}
          <IconLoader.IconLink />
        </Styled.ItemLink>
      </Styled.ItemContent>
    </Styled.BoxItem>
  );
};

export default ReadMoreBox;
