import PageHeader from "components/common/PageHeader";
import { LessonTypeInfo } from "models/PrivateCoach";
import Styled from "./Styled";
import GeneralButton from "components/common/Button";
import { useTranslation } from "react-i18next";
import HTMLTypography from "components/common/HTMLTypography";
import CoachBanner from "assets/bg-coach-banner.png";
import PageContainer from "components/common/PageContainer";
import avatarPlaceholder from "../../assets/bg-avatar-placeholder.png";

interface CoachBookingProps {
  children: React.ReactNode;
  coachLesson: any;
  lessonTypeInfo?: LessonTypeInfo;
}

const CoachBookingBase = ({
  children,
  coachLesson,
  lessonTypeInfo,
}: CoachBookingProps) => {
  const { t } = useTranslation("coaching");

  return (
    <PageContainer
      title={coachLesson?.coach?.getName()}
      banner={coachLesson?.facility?.thumbnail_ref ?? CoachBanner}
    >
      <Styled.Wapper>
        <Styled.RowDetail>
          <img
            src={coachLesson?.coach?.profile_pic_ref ?? avatarPlaceholder}
            className=" object-cover rounded-full object-center w-[150px] h-[150px]"
          />
          <Styled.DetailDes>
            <HTMLTypography
              content={coachLesson?.getDescription()}
              className="[&>*:first-child]:mt-0"
            />
          </Styled.DetailDes>
        </Styled.RowDetail>
        <Styled.RowDetail>
          <Styled.DetailBoxContent>
            <Styled.BoxContentHeader>
              {t("coaching:privateCoachingInfo.textTutorDetails")}
            </Styled.BoxContentHeader>
            {lessonTypeInfo?.one_to_one_price &&
            lessonTypeInfo?.one_to_one_price > 0 ? (
              <Styled.List>
                <Styled.Left>
                  {t("coaching:privateCoachingInfo.textPrivate")}
                </Styled.Left>
                <Styled.Right>
                  {t("privateCoachingInfo.pricePerLesson", {
                    price: lessonTypeInfo?.one_to_one_price ?? 0,
                  })}
                </Styled.Right>
              </Styled.List>
            ) : null}
            {lessonTypeInfo?.one_to_two_price &&
            lessonTypeInfo?.one_to_two_price > 0 ? (
              <Styled.List>
                <Styled.Left>
                  {t("coaching:privateCoachingInfo.textSemi")}
                </Styled.Left>
                <Styled.Right>
                  {t("privateCoachingInfo.pricePerLesson", {
                    price: lessonTypeInfo?.one_to_two_price ?? 0,
                  })}
                </Styled.Right>
              </Styled.List>
            ) : null}
            {lessonTypeInfo?.one_to_three_price &&
            lessonTypeInfo?.one_to_three_price > 0 ? (
              <Styled.List>
                <Styled.Left>
                  {t("coaching:privateCoachingInfo.text1to3")}
                </Styled.Left>
                <Styled.Right className="no-border">
                  {t("privateCoachingInfo.pricePerLesson", {
                    price: lessonTypeInfo?.one_to_three_price,
                  })}
                </Styled.Right>
              </Styled.List>
            ) : null}
          </Styled.DetailBoxContent>
        </Styled.RowDetail>
        {children}
      </Styled.Wapper>
    </PageContainer>
  );
};

export default CoachBookingBase;
