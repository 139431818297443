import {
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { useFacilityBookingDetailViewModel } from "common/viewModels/FacilityBooking/FacilityBookingDetailViewModel";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import Typography from "components/common/Typography";
import {
  IFacilityBookingDetailViewModel,
  IFacilityBookingConfirmationViewModel,
} from "models";
import BookingBase, {
  ProgramTypeKeys,
} from "pageComponents/booking/BookingBase";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import _ from "lodash";
import {
  getLocalisedString,
  validateEmailFormat,
} from "common/utils/stringHelper";
import Section from "pageComponents/booking/Section";
import AttendessSelect from "pageComponents/booking/AttendessSelect";
import CommonTextfield from "components/common/Textfield";
import Space from "components/common/Space";
import KeyMemberInput from "pageComponents/booking/KeyMemberInput";
import {
  convertToFormUrlEncoded,
  createPayment,
  isEmptyValues,
} from "utils/common";
import theme from "utils/theme";
import { useFacilityBookingConfirmationViewModel } from "common/viewModels/FacilityBooking/FacilityBookingConfirmationViewModel";
import SimpleModal from "components/common/Modal/SimpleTextModal";
import { IFacilityContextType } from "./FacilityScreen";
import PromoCodeBox from "components/boxs/PromoCodeBox";
import OrderInfoBase from "pageComponents/booking/OrderInfoBase";
import { useVoucherViewModel } from "common/viewModels/VoucherViewModel";
import { IVoucherViewModel } from "models/Voucher";
import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import { ROUTE_BASE } from "Route";
import {
  appearGlobalError,
  appearGlobalLoading,
} from "common/context/requests/globalRequest";
import { confirmModalContext } from "contexts/ConfirmModalProvider";
import { getCorrectUrl } from "navigators/Router";

interface ErrorModalState {
  isOpen: boolean;
  content: string;
}

const FacilityBookingPage = () => {
  const { t } = useTranslation("booking");
  const navigate = useNavigate();
  const [keyMemberError, setKeymemberError] = useState<string[]>(["", "", ""]);
  const [bindVoucherCompleted, setBindVoucherCompleted] = useState(false);

  const {
    venue,
    facility,
    selectedAttendees,
    selectedDate,
    selectedTimeslots,
    selectedPurposeId,
    bookingIds,
    linkedVoucherId,
  } = useOutletContext<IFacilityContextType>();

  const { currentUser, isRetriving } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const {
    keyMember1,
    keyMember2,
    keyMember3,
    promoCode,
    appliedVouchers,
    allowedVoucherTypes,
    getBookingDetails,
    applyPromoCode,
    removePromoCode,
    applyVoucher,
    removeVoucher,
    // getSubtotalPrice,
    getTotalPrice,
    setKeyMember1,
    setKeyMember2,
    setKeyMember3,
    checkIsBookingOwner,
    // validateForm,
    getBookingSummary,
  } = useFacilityBookingDetailViewModel({
    currentUser,
    facility,
    venue,
    selectedDate,
    selectedTimeslots,
    bookingIds,
    numOfAttendees: selectedAttendees,
    selectedPurpose: selectedPurposeId,
    dispatch: globalDispatch,
  }) as IFacilityBookingDetailViewModel;
  const { createBooking } =
    useFacilityBookingConfirmationViewModel() as IFacilityBookingConfirmationViewModel;

  const { getBookingVouchers, bookingVouchers } = useVoucherViewModel({
    dispatch: globalDispatch,
  }) as IVoucherViewModel;

  const { openModal } = useContext(confirmModalContext);

  const isDuplicateEmail = (text: string, id: number) => {
    switch (id) {
      case 0:
        return text === keyMember2 || text === keyMember3;
      case 1:
        return text === keyMember1 || text === keyMember3;
      case 2:
        return text === keyMember1 || text === keyMember2;
      default:
        return false;
    }
  };

  const keyMemberChecker = (keyMemberList: string[]) => {
    const snapError = [""];
    keyMemberList?.map((mem, idx) => {
      if (isEmptyValues(mem))
        snapError.push(t("bookingDetail.emptyEmailError"));
      else if (!validateEmailFormat(mem))
        snapError.push(t("bookingDetail.invalidEmailError"));
      else if (checkIsBookingOwner(mem))
        snapError.push(t("bookingDetail.inviteBookingOwnerError"));
      else if (isDuplicateEmail(mem, idx))
        snapError.push(t("bookingDetail.duplicatedKeyMemberError"));
      else snapError.push("");
    });

    snapError.shift();
    setKeymemberError(snapError);

    const checker = snapError.some((el) => !_.isEmpty(el));
    return checker;
  };

  const handlePayment = async () => {
    const keyMemberList = [keyMember1, keyMember2, keyMember3];
    const checker = facility.requireKeyMembers()
      ? keyMemberChecker(keyMemberList)
      : false;

    const summary = getBookingSummary();

    if (!checker) {
      // Query the create Booking
      const bookingRequest = {
        ...summary?.payload,
        platform: "WEB",
      };

      const bookingRes = await createBooking(bookingRequest);
      if (bookingRes.success === true) {
        appearGlobalLoading(globalDispatch);
        createPayment(
          bookingRes,
          globalDispatch,
          navigate,
          getTotalPrice() ?? 0
        );
      } else if (!bookingRes?.needVerification) {
        console.log("🚀 ~ handlePayment ~ bookingRes:", bookingRes);
        // appearGlobalError(globalDispatch, bookingRes?.error?.msg);
        openModal({
          title: bookingRes?.error?.msg,
          onConfirm: () => {
            if (
              !(
                bookingRes?.error.errorCode === -302 ||
                bookingRes?.error.errorCode === -303
              )
            ) {
              // TODO: go back to facility detail page after auto assign venue, check implementation
              // navigate(`${ROUTE_BASE.FACILITY_BOOKING}/facility/${facility._id}/timeslot`);
              navigate(-1);
            }
          },
        });
      } else if (bookingRes?.isRedirectHandle) {
        navigate(
          getCorrectUrl(
            `${ROUTE_BASE.FACILITY_BOOKING}/${facility._id}/timeslot`
          )
        );
      }
    }
  };

  const _orderInfo = (displayCoupon: boolean) => {
    return (
      <OrderInfoBase
        promoCode={promoCode}
        appliedVoucher={appliedVouchers}
        displayCoupon={displayCoupon}
      >
        <div>
          {_.map(getBookingDetails(), (detail) => (
            <div>
              <Typography color={theme.black} fontWeight="600">
                {getLocalisedString(venue?.name_en, venue?.name_tc)}
              </Typography>
              <Typography color={theme.grey}>{detail?.info}</Typography>
              {facility?.isHeadCount() && (
                <Typography color={theme.grey}>
                  {t("bookingDetail.attendees", { amount: selectedAttendees })}
                </Typography>
              )}
            </div>
          ))}
        </div>
      </OrderInfoBase>
    );
  };

  const _keyMemberSection = () => {
    return (
      <Section title={t("bookingDetail.keyMembers")}>
        {[...Array(3)].map((item, idx) => (
          <KeyMemberInput
            key={idx}
            memberNum={idx}
            error={keyMemberError[idx]}
            value={idx === 0 ? keyMember1 : idx === 1 ? keyMember2 : keyMember3}
            onChange={
              idx === 0
                ? setKeyMember1
                : idx === 1
                ? setKeyMember2
                : setKeyMember3
            }
          />
        ))}
      </Section>
    );
  };

  const _couponSection = () => {
    return (
      <Section
        title={`${t("event:booking.promoCode")}/${t("event:booking.voucher")}`}
      >
        <PromoCodeBox
          onApplyPromoCode={applyPromoCode}
          onRemovePromoCode={removePromoCode}
          voucherDisabled={
            getTotalPrice() <= 0 || allowedVoucherTypes.length <= 0
          }
          onApplyVoucher={applyVoucher}
          onRemoveVoucher={removeVoucher}
          selectedVouchers={appliedVouchers}
          availableVouchers={bookingVouchers}
          refreshVoucherList={refreshVoucherList}
          code={promoCode}
        />
      </Section>
    );
  };

  const refreshVoucherList = useCallback(() => {
    if (facility && currentUser && isRetriving === false) {
      getBookingVouchers({
        bookingType: ProgramTypes.Facility,
        category: facility?._id,
        timeslotType: allowedVoucherTypes,
        allowCash: getTotalPrice() > 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility, currentUser, isRetriving, allowedVoucherTypes]);

  useEffect(() => {
    const handleDeepLinkBooking = () => {
      console.log(venue, facility, selectedDate, selectedTimeslots);
      if (
        !venue &&
        !facility &&
        !selectedDate &&
        selectedTimeslots.size === 0
      ) {
        navigate(`../`);
      }
    };
    handleDeepLinkBooking();
  }, [venue, facility, selectedDate, selectedTimeslots]);

  useEffect(() => {
    refreshVoucherList();
  }, [refreshVoucherList]);

  /**
   * booking voucher observer for binding voucherId from search query into voucher list
   */
  useEffect(() => {
    if (bindVoucherCompleted === true) return;
    if (bookingVouchers.length <= 0) return;

    if (linkedVoucherId == null) return;
    const matchedVoucher = bookingVouchers.find((each) =>
      each.voucherId.match(linkedVoucherId)
    );
    if (matchedVoucher == null) return;
    applyVoucher(matchedVoucher);
    /** only do once after list is fetched */
    setBindVoucherCompleted(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookingVouchers,
    linkedVoucherId,
    bindVoucherCompleted,
    setBindVoucherCompleted,
  ]);

  return (
    <div className="pb-[100px]">
      <BookingBase
        programTypes={ProgramTypes.Facility as ProgramTypeKeys}
        orderInfoComponent={_orderInfo(false)}
        remarks={facility?.remarks}
        tnc={facility?.tandc}
        totalAmount={getTotalPrice() ?? 0}
        receiptComponent={_orderInfo(true)}
        receiptPolicyRemark={t("bookingDetail.cancelPolicyRemark")}
        handlePayment={handlePayment}
      >
        {/* {facility?.isHeadCount() && _quantiySection()} */}
        {_couponSection()}
        {facility?.requireKeyMembers() && _keyMemberSection()}
      </BookingBase>
    </div>
  );
};

export default FacilityBookingPage;
