import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";

export const Wapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  border-radius: 30px 30px 0px 0px;
  margin: 50px auto 0px;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  z-index: 2;
  overflow: hidden;
  background-color: #eff0e8;
  @media ${breakpoint.mobile} {
    padding: 10px;
  }
`;

export const Container = styled.div`
  width: 100%;
  background-color: #eff0e8;
  padding: 30px;
  padding-bottom: 60px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  .input {
    width: 250px;
  }

  @media ${breakpoint.mobile} {
    flex-direction: column;
    align-items: stretch;
    .input {
      width: 100%;
    }

    span {
      width: 100%;
      margin-bottom: -15px;
    }
  }
`;

export const Textfield = styled.input`
  width: 250px;
  background-color: #fff;
  padding: 0px 20px;
  border-radius: 10px;
  border: 1px solid #8c8c8c;
  height: 50px;
  &:focus-visible {
    outline: none;
  }
  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

export const Button = styled.button<{ type: string }>`
  padding: 0px 30px;
  height: 50px;
  border-radius: 10px;
  color: ${(p) => (p.type !== "primary" ? "#517E93" : "#fff")};
  background: ${(p) => (p.type !== "primary" ? "#fff" : "#517E93")};
  border: 1px solid #517e93;
  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 30px;
  padding: 0px 0px 30px;
  &.border {
    margin-bottom: 30px;
    border-bottom: 1px solid #d8d8d8;
  }

  @media ${breakpoint.mobile} {
    flex-direction: column;
    &.rtl {
      flex-direction: column-reverse;
    }
  }
`;

export const BoxImg = styled.div<{ bg: string }>`
  width: 50%;
  background-image: url(${(p) => p.bg});
  height: 380px;
  background-size: cover;
  border-radius: 10px;
  background-position: center;
  @media ${breakpoint.mobile} {
    width: 100%;
    height: 180px;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  width: 50%;
  gap: 20px;
  flex-direction: column;
  font-weight: 400;
  strong {
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
  }
  em {
    width: 100%;
    color: #8c8c8c;
    font-size: 14px;
  }

  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

export const BoxItem = styled.div`
  width: calc(33.33% - 20px);
  display: flex;
  gap: 10px;
  flex-direction: column;
  cursor: pointer;
  strong {
    font-size: 20px;
    font-weight: 500;
  }
  em {
    color: #8c8c8c;
    font-size: 14px;
  }
  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

export const BoxItemImg = styled.div<{ bg: string }>`
  background-image: url(${(p) => p.bg});
  width: 100%;
  height: 250px;
  border-radius: 10px;
  background-size: cover;
  @media ${breakpoint.mobile} {
    height: 180px;
  }
`;

export const ItemGroup = styled.div`
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  gap: 30px;
  &.border {
    margin-bottom: 30px;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 30px;
  }
`;

export const Paging = styled.div`
  display: block;
  text-align: center;
  margin: 50px 0px;
  width: 100%;
  float: left;
`;

export const PagingItem = styled.span`
  display: inline-block;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 38px;
  border: 1px solid #000;
  margin: 0px 5px;
  border-radius: 10px;
  cursor: pointer;
  &.active,
  &:hover {
    background: #c85d38;
    border-color: #c85d38;
    color: #fff;
  }

  &.active {
    cursor: default;
  }
  &.hide {
    display: none;
  }
`;

export const Content = styled.div`
  width: 70%;
  margin: 30px auto;
`;

export const Title = styled.div`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
`;
