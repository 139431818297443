import { useEffect, useState } from "react";
import { listEventBookings } from "../../network/event";
import {
  appearGlobalError,
  appearGlobalLoading,
  disappearGlobalError,
  disappearGlobalLoading,
} from "../../context/requests/globalRequest";
import { ProgramTypes } from "./MyBookingListViewModel";
import { listPastFacilityBooking } from "../../network/booking";
import { listPastCoachBooking } from "../../network/coachLesson";
import { listEnrollment } from "../../network/monthlyProgram";
import { FacilityBookingOrder } from "../../models/FacilityBookingOrder";
import { useCommonFetcher } from "../Common/CommonViewModel";

export const useMyPastBookingListViewModel = ({
  isLoggedIn = false,
  dispatch,
}) => {
  const { fetcher } = useCommonFetcher();
  const [selectedProgramType, setSelectedProgramType] = useState(
    ProgramTypes.Program
  );
  const [filterStatus, setFilterStatus] = useState("CONFIRMED,CANCELLED");
  const [pastMonthlyProgramBookingList, setPastMonthlyProgramBookingList] =
    useState([]);
  const [pastCoachLessonBookingList, setPastCoachLessonBookingList] = useState(
    []
  );
  const [pastEventBookingList, setPastEventBookingList] = useState([]);
  const [pastFacilityBookingList, setPastFacilityBookingList] = useState([]);

  useEffect(() => {
    refreshBookingRecords();
  }, [selectedProgramType, filterStatus, isLoggedIn]);

  const refreshBookingRecords = async () => {
    if (!!isLoggedIn) {
      // Only get booking record on login
      switch (selectedProgramType) {
        case ProgramTypes.Program:
          await getMyPastProgramBookings({ filterStatus });
          break;
        case ProgramTypes.Coach:
          await getMyPastCoachBookings({ filterStatus });
          break;
        case ProgramTypes.Event:
          await getMyPastEventBookings();
          break;
        case ProgramTypes.Facility:
          await getMyPastFacilityBookingList({ filterStatus: filterStatus });
          break;
      }
    }
  };

  const switchBookingListTab = (bookingType) => {
    switch (bookingType) {
      case ProgramTypes.Program:
      case ProgramTypes.Coach:
      case ProgramTypes.Event:
      case ProgramTypes.Facility:
        setSelectedProgramType(bookingType);
        break;
      default:
        break;
    }
  };

  const getMyPastCoachBookings = async () => {
    console.log("Get My Coach Booking List: ", filterStatus);
    const res = await fetcher({
      api: () => listPastCoachBooking({ status: filterStatus }),
    });
    setPastCoachLessonBookingList(res.result);
  };

  const getMyPastEventBookings = async () => {
    const res = await fetcher({ api: () => listEventBookings(true) });
    setPastEventBookingList(res.result);
  };

  const getMyPastFacilityBookingList = async ({ filterStatus }) => {
    console.log("Get My Facility Booking List: ", filterStatus);
    const res = await fetcher({
      api: () =>
        listPastFacilityBooking({
          status: filterStatus,
        }),
    });
    setPastFacilityBookingList(
      res?.result?.map((order) => new FacilityBookingOrder(order))
    );
  };

  const getMyPastProgramBookings = async ({ filterStatus }) => {
    console.log("check ", filterStatus);
    let status = undefined;
    if (filterStatus == "CONFIRMED") status = "CONFIRM";
    if (filterStatus == "CANCELLED") status = "CANCELLED";
    const res = await fetcher({
      api: () => listEnrollment({ past: true, status }),
    });
    setPastMonthlyProgramBookingList(
      res.result?.map((el) => ({ ...el, lessons: el.lessons }))
    );
  };

  return {
    pastMonthlyProgramBookingList,
    pastCoachLessonBookingList,
    pastEventBookingList,
    pastFacilityBookingList,
    selectedProgramType,
    filterStatus,
    switchBookingListTab,
    setFilterStatus,
    refreshBookingRecords,
  };
};
