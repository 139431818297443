import { Divider, DividerProps as MuiDividerProps } from "@mui/material";

type DividerProps = MuiDividerProps<any> & BaseDividerProps;

interface BaseDividerProps {
  space?: string;
  style?: React.CSSProperties;
}

const MyDivider = ({ space = "10px", ...props }: DividerProps) => {
  return (
    <Divider
      style={{
        marginTop: space,
        marginBottom: space,
      }}
      {...props}
    />
  );
};

export default MyDivider;
