import styled from "styled-components";

export default {
  BoxItem: styled.div`
    padding: 25px;
    width: 100%;
    height: 254px;
    max-width: 236px;
    background: #f0f9ff;
    border-radius: 10px;
    border-width: 0px 4px 4px 0px;
    border-style: solid;
    border-color: #c1d9e9;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `,

  ItemTitle: styled.p<{ color: string }>`
    height: 48px;
    font-family: Noto Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: ${(p) => p.color};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  `,

  ItemDate: styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  `,

  ItemTime: styled.div`
    color: #8c8c8c;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  `,

  ItemType: styled.span<{ bg: string }>`
    height: 40px;
    line-height: 40px;
    position: absolute;
    background: ${(p) => p.bg};
    padding: 0px 25px;
    border-radius: 0px 6.41px 6.41px 0px;
    color: #fff;
    left: 0px;
    bottom: 24px;
  `,

  GrayLabelContainer: styled.div`
    margin-top: 10px;
  `,

  GrayLabel: styled.span`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #829098;
    padding: 2px 6px 4px;
    background: #dee7ec;
    border-radius: 16px;
  `,
};
