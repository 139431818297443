import Slider from "react-slick";
import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import theme from "utils/theme";

const DownloadButton = styled.div`
  background-color: #ffffff30;
  border: 2px solid #fff;
  padding: 10px 20px;
  height: auto;
  width: fit-content;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const CustomSlider = styled(Slider)`
  margin-top: -35px;
  .slick-dots {
    bottom: 5%;
  }

  .slick-dots li button:before {
    color: #fff;
    font-size: 15px;

    @media ${breakpoint.mobile} {
      font-size: 11px;
    }
  }

  .slick-dots li.slick-active button:before {
    color: ${theme.orange};
  }

  .slick-slide img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const NextArrow = styled.img`
  width: 20px;
  height: 35px;
  top: 50%;
  right: 30px;
  position: absolute;
  z-index: 100;
  cursor: pointer;

  @media ${breakpoint.mobile} {
    display: none;
  }
`;

const PrevArrow = styled(NextArrow)`
  left: 30px;
`;

const SliderImage = styled.img`
  width: 100%;
  height: auto;
  margin: auto;
  border-radius: 0px 0px 80px 80px;
  cursor: pointer;

  @media ${breakpoint.mobile} {
    border-radius: 0px 0px 30px 30px;
  }
`;

export default {
  DownloadButton,
  CustomSlider,
  NextArrow,
  PrevArrow,
  SliderImage,
};
