import _ from "lodash";
import {
  IApplyPromoCodeProps,
  ICheckPromoCodeProps,
  IEstimatePromoCodeDiscountProps,
  IReleasePromoCodeProps,
  PromoCode,
} from "../../models/PromoCode";
import {
  applyPromoCode,
  checkPromoCode,
  estimatePromoCodePrice,
  manualReleasePromoCodeTicket,
} from "../../network/payment";
import { isEmptyValues, negativeToZero } from "../../utils/common";
import { useCommonFetcher } from "../Common/CommonViewModel";

export const usePromoCodeViewModel = () => {
  const { fetcher } = useCommonFetcher();

  const applyPromoCodeDiscount = async ({
    code,
    bookingType,
  }: IApplyPromoCodeProps) => {
    const res = await fetcher({
      api: () =>
        applyPromoCode({
          code,
          bookingType,
          // orderAmount: negativeToZero(orderAmount),
        }),
      skipErrorHandle: true,
    });

    if (res?.success) {
      return {
        success: true,
        promoCodeTicketId: res?.result?.promoCodeTicketId,
      };
    } else return res;
  };

  const releasePromoCodeTicket = async ({
    promoCodeTicketId,
  }: IReleasePromoCodeProps) => {
    const res = await fetcher({
      api: () => manualReleasePromoCodeTicket({ promoCodeTicketId }),
      skipErrorHandle: true,
    });
  };

  const checkPromoCodeAvailability = async ({
    code,
    bookingType,
    orderAmount,
  }: ICheckPromoCodeProps) => {
    const res = await fetcher({
      api: () =>
        checkPromoCode({
          code,
          bookingType,
          orderAmount: negativeToZero(orderAmount),
        }),
    });

    if (res?.success && res?.result) {
      return {
        success: true,
        promoCode: new PromoCode(res.result),
      };
    } else return { success: false };
  };

  const estimatePromoCodeDiscount = async ({
    promoCode,
    orderAmount,
  }: IEstimatePromoCodeDiscountProps) => {
    const res = await fetcher({
      api: () =>
        estimatePromoCodePrice({
          code: promoCode?.code,
          orderAmount: negativeToZero(orderAmount),
        }),
    });

    if (res?.success && res?.result) {
      const tmpPromoCode = _.cloneDeep(promoCode);
      tmpPromoCode.orderDiscountedAmount = negativeToZero(
        res?.result?.discountedAmount
      );
      tmpPromoCode.orderEnjoyedDiscount = negativeToZero(
        res?.result?.enjoyedDiscount
      );
      return {
        success: true,
        promoCode: tmpPromoCode,
      };
    } else return { success: false };
  };

  return {
    applyPromoCodeDiscount,
    releasePromoCodeTicket,
    checkPromoCodeAvailability,
    estimatePromoCodeDiscount,
  };
};
