import i18n from "i18n";

export const SEO_DEFAULT = {
  en: {
    title: "GO PARK Sports ︱GO PARK Sai Sha",
    description:
      "GO PARK Sports, Hong Kong's premier sports destination, is a diverse sports park located in GO PARK Sai Sha. Spanning over 1 million square feet, our state-of-the-art venues feature professional sports facilities tailored for more than 10 kinds of sports, including popular, newly emerged, and urban sports. Partnering with renowned sports associations and institutions, GO PARK Sports offers a wide range of classes and events. Visit Hong Kong's premier sports destination.",
  },
  zh: {
    title: "GO PARK Sports ︱西沙GO PARK",
    description:
      "位於西沙GO PARK內的GO PARK Sports廣達 100 萬呎，是為大眾設計的多元化運動公園。這裡提供專業運動場地，供十多種不同運動項目使用，涵蓋熱門、新興運動及城市運動。夥拍知名的體育總會及機構，GO PARK Sports帶來多樣化的課程與活動，必將成為運動愛好者的聚集熱點。",
  },
};

export const getLocalizedPageTitleWithPrefix = (
  options?: { en?: string; tc?: string } | { nameGetter: () => string }
) => {
  const defaultTitle =
    i18n.language === "en" ? SEO_DEFAULT.en.title : SEO_DEFAULT.zh.title;

  if (!options) return defaultTitle;

  if ("nameGetter" in options) {
    return `GO PARK Sports - ${options.nameGetter()}`;
  }

  const en = options?.en;
  const tc = options?.tc;

  return i18n.language === "en"
    ? en
      ? `GO PARK Sports - ${en}`
      : defaultTitle
    : tc
    ? `GO PARK Sports - ${tc}`
    : defaultTitle;
};
