import { createContext, useEffect, useReducer } from "react";
import GlobalReducer from "../reducers/globalReducer";
import { requestKioskCert } from "../../network/kiosk";
import { setKioskCert } from "../requests/globalRequest";
import config from "../../../config";

const globalInitState = {
  globalError: null,
  globalLoading: false,
  accountVerification: null,
  phoneNumberVerification: false,
  kioskCert: null,
  globalBlockingStack: [],
};

export const GlobalContextStore = createContext(globalInitState);

const GlobalProvider = ({ children }) => {
  const [globalState, globalDispatch] = useReducer(GlobalReducer, {
    ...globalInitState,
  });

  useEffect(() => {
    // if (config.IS_KIOSK) {
    //   requestKioskCert().then(res=>{
    //     if (res.success) setKioskCert(globalDispatch, res.result)
    //   })
    // }
  }, []);

  return (
    <GlobalContextStore.Provider value={{ globalState, globalDispatch }}>
      {children}
    </GlobalContextStore.Provider>
  );
};

export default GlobalProvider;
