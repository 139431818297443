import { generalInstance } from ".";

export const getWeatherInfo = async () => {
  const res = await generalInstance.get('https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=rhrread&lang=en');
  console.log("get weather info: ", res.data);
  return res.data;
};

export const getWeatherWarning = async () => {
  const res = await generalInstance.get('https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=warnsum&lang=en');
  console.log("get warnsum info: ", res.data);
  return res.data;
};