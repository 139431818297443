import { useEffect, useState } from "react";
import { getWeatherInfo, getWeatherWarning } from "../network/weather";

const targetLocation = 'Sai Kung'

export const useWeatherViewModel = () => {
  const [humidity, setHumidity] = useState(0)
  const [temperature, setTemperature] = useState(0)
  const [weatherIconCodeList, setWeatherIconCodeList] = useState([])
  const [weatherWarningCodeList, setWeatherWarningCodeList] = useState([])

  const fetchWeatherInfo = async () => {
    await fetchNormalWeatherInfo()
    await fetchWeatherWarning()
  }

  const fetchNormalWeatherInfo = async () => {
    try {
      const res = await getWeatherInfo()
      console.log('getWeatherInfo res ',res)
      setTemperature(res?.temperature?.data?.find(el=>el?.place==targetLocation)?.value)
      setHumidity(res?.humidity?.data?.find(el=>true)?.value)
      setWeatherIconCodeList(res?.icon)
    } catch (e) {
      console.log("fetchNormalWeatherInfo exception.", e?.message);
      if (e?.response?.data) {
        return e.response.data;
      }
      return { success: false, error: `Error: ${"" + e?.message}` };
    }
  }

  const fetchWeatherWarning = async () => {
    try {
      const res = await getWeatherWarning()
      console.log('warnsum res ',res, Object.values(res))
      setWeatherWarningCodeList(Object.values(res).map(el=>el.code).filter(el=>el!='CANCEL'))
    } catch (e) {
      console.log("fetchWeatherWarning exception.", e?.message);
      if (e?.response?.data) {
        return e.response.data;
      }
      return { success: false, error: `Error: ${"" + e?.message}` };
    }
  }

  return {
    fetchWeatherInfo,
    humidity,
    temperature,
    weatherIconCodeList,
    weatherWarningCodeList
  };
};
