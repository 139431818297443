import { getLocalisedString } from "common/utils/stringHelper";
import PageContainer from "components/common/PageContainer";
import Space from "components/common/Space";
import Typography from "components/common/Typography";
import TncSections from "pageComponents/tnc/TncSections";
import { useTranslation } from "react-i18next";

const TncPage = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <h1 className="text-2xl font-medium leading-[26px] tracking-[0px] text-left">
        {`GO PARK Sports – ${t(`navBar:footer.termsAndConditions`)}`}
      </h1>
      <Space size="20px" />

      <Typography>
        {getLocalisedString(
          "These terms and conditions (the “Terms”) outline the rules and regulations that apply to your application to join GO PARK Sports (GPS). You must read and understand the Terms carefully. By applying to become and/or successfully register as a user of GPS, you agree to be bound by the Terms.",
          "本條款及細則（以下簡稱「條款」）闡述了您申請加入GO PARK Sports（GPS）時適用的規則與規定。您必須仔細閱讀並理解這些條款。通過申請成為GPS的用戶或成功註冊，即表示您同意接受這些條款的約束。"
        )}
      </Typography>

      <TncSections />

      <Space size="20px" />
      <Typography>
        {getLocalisedString(
          "In case of any inconsistency or conflict between the Chinese and English versions of these terms and conditions, the English version shall prevail.",
          "如中文和英文版本的這些條款及條件有任何不一致或衝突，應以英文版本為準。"
        )}
      </Typography>
    </PageContainer>
  );
};

export default TncPage;
