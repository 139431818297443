import React from "react";
import ImageLoginBg from "../../assets/bg-login-flat.png";
import ImageLoginMobileBg from "../../assets/bg-login-flat-mobile.png";
import styled from "styled-components";
import { breakpoint } from "utils/responsive";

const Container = styled.div<{ fullScreen?: boolean }>`
  background-image: url(${ImageLoginBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  object-fit: cover;
  height: ${(props) => (props.fullScreen ? "auto" : "100vh")};
  width: 100%;
  z-index: 0;

  @media ${breakpoint.mobile} {
    background-image: url(${ImageLoginMobileBg});
  }
`;

type Props = React.HTMLAttributes<HTMLElement> & {
  children?: JSX.Element;
  fullScreen?: boolean;
};

const LoginBackground = (props: Props) => {
  const { children, ...remains } = props;
  if (children == null) return <Container {...remains} />;
  return <Container {...remains}>{props.children}</Container>;
};

export default LoginBackground;
