import AvatarUploadCircle from "components/AvatarUploadCircle/AvatarUploadCircle";
import Form from "components/common/Form";
import LogoCard from "components/common/cards/LogoCard";
import {
  NewUser,
  useUserRegistrationFormModel,
} from "components/form/UserRegistrationForm/UserRegistrationFormModel";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import UserNameTitleSelect from "components/form/UserRegistrationForm/UserNameTitleSelect";
import { BasicDatePicker } from "components/common/DatePicker";
import dayjs from "dayjs";
import GenderSelect from "components/GenderSelect/GenderSelect";
import Styled from "./Styled";
import {
  IRegistrationAccountViewModel,
  districtList,
} from "common/models/User";
import Typography from "components/common/Typography";
import ClickableCheckbox from "components/common/checkbox/ClickableCheckbox";
import { useCallback, useState } from "react";
import SimpleTextModal from "components/common/Modal/SimpleTextModal";
import GeneralButton from "components/common/Button";
import { FormSubmitCallback } from "components/common/Form/Form";
import { Gender } from "models";
import { ILoginInfo } from "./RegistrationStepControl";
import { MobileVerificationResult } from "common/models/Verification";
import { useRegisterAccountViewModel } from "common/viewModels/RegisterAccountViewModel";
import { isEmptyValues } from "common/utils/common";
import { useUploadFile } from "hooks/useUploadFile";
import { useNavigate } from "react-router-dom";
import ChildLabel from "./Components/ChildLabel";

const StyledFormSelect = Styled.FormSelectHoc(Form.Field.Select);

interface RegistrationFormProps {
  loginInfo: ILoginInfo;
  verificationMobileResult: MobileVerificationResult;
}

const RegistrationForm = ({
  loginInfo,
  verificationMobileResult,
}: RegistrationFormProps) => {
  const { t } = useTranslation("register");
  const navigate = useNavigate();
  const { register } =
    useRegisterAccountViewModel() as IRegistrationAccountViewModel;
  const { requestPresignedURLByFile } = useUploadFile();

  const [isTncConfirmed, setIsTncConfirmed] = useState<boolean>(false);
  const [isOpenTnc, setIsOpenTnc] = useState<boolean>(false);
  const [isOpenPrivacy, setIsOpenPrivacy] = useState<boolean>(false);
  const [isOpenPrivacyPolicy, setIsOpenPrivacyPolicy] =
    useState<boolean>(false);
  const [isSHKConfirmed, setIsSHKConfirmed] = useState<boolean>(false);

  const { formInitialValues, formValidateSchema } =
    useUserRegistrationFormModel({
      ...loginInfo,
      ...verificationMobileResult,
    });

  const onFormSubmit = async (user: NewUser) => {
    let profile_pic = undefined;
    if (
      !isEmptyValues(user.profile_pic_ref) &&
      !isEmptyValues(user.profile_pic_file)
    ) {
      const res = await requestPresignedURLByFile(
        user.profile_pic_file as File,
        user.profile_pic_ref as string
      );

      if (res?.success) profile_pic = res?.ref_url;
    }
    const userInfo = {
      username: user?.username,
      email: user?.email,
      password: user?.password,
      phoneNum: user?.phoneNum ?? "",
      address:
        user.district != null && user?.district.length > 0
          ? user.district
          : undefined,
      title: user?.title,
      name_en: user?.enName,
      name_zh:
        user.zhName != null && user.zhName.length > 0 ? user.zhName : undefined,
      gender: user?.gender
        ? user?.gender === "female"
          ? "F"
          : "M"
        : undefined,
      birthday: user?.dateOfBirth,
      profile_pic_ref: profile_pic,
      isAgree_SHKPclub_Terms: isSHKConfirmed,
      phoneOtpId: verificationMobileResult?.otpId,
      skipPhoneVerification: verificationMobileResult?.skipPhoneVerification,
    };

    register(userInfo).then((res) => {
      console.log("register result: ", res);
      if (res?.success) {
        navigate("greeting");
      }
    });
  };

  return (
    <LogoCard>
      <Form
        initialValues={formInitialValues}
        validationSchema={formValidateSchema}
        onSubmit={onFormSubmit}
      >
        {(formik) => {
          return (
            <Form.Element
              id="registration_form"
              className={cn(
                "w-full md:w-[600px] h-full md:p-[30px]",
                "flex flex-col gap-[20px]"
              )}
            >
              <div className="w-full h-full flex items-end justify-center gap-[60px] md:gap-[40px]">
                <AvatarUploadCircle
                  imageUrl={formik.values.profile_pic_ref}
                  onImageUrlChange={(url, file) => {
                    formik.setFieldValue("profile_pic_ref", url);
                    formik.setFieldValue("profile_pic_file", file);
                  }}
                />
                <div className="h-full w-full flex items-end">
                  <Form.Field.TextField
                    id="username"
                    name="username"
                    title={t("field.username")}
                    placeholder={t("placeholder.username")}
                  />
                </div>
              </div>
              <UserNameTitleSelect
                selectedTitle={formik.values.title}
                setTitle={(title) => formik.setFieldValue("title", title)}
              />
              <Form.Field.TextField
                id="enName"
                name="enName"
                title={t("field.en_name")}
                placeholder={t("placeholder.en_name")}
              />
              <Form.Field.TextField
                id="zhName"
                name="zhName"
                title={t("field.zh_name")}
                placeholder={t("placeholder.zh_name")}
              />

              <div>
                <div className="flex items-center gap-[5px] mb-[10px]">
                  <Typography fontSize="12px">{t("field.dob")}</Typography>
                  {verificationMobileResult?.skipPhoneVerification && (
                    <ChildLabel />
                  )}
                </div>
                <BasicDatePicker
                  id="dateOfBirth"
                  name="dateOfBirth"
                  disableFuture
                  maxDate={dayjs()}
                  minDate={
                    verificationMobileResult?.skipPhoneVerification
                      ? dayjs().subtract(18, "year")
                      : null
                  }
                  textFieldProps={{
                    style: {
                      //   width: windowSize === "screen1024" ? "297px" : "100%",
                      height: "44.6px !important",
                    },
                  }}
                  slotProps={{
                    textField: {
                      onKeyDown: (e) => {
                        e.preventDefault();
                      },
                    },
                  }}
                  onChange={(e) => formik.setFieldValue("dateOfBirth", e)}
                />
              </div>

              <GenderSelect
                gender={formik.values.gender}
                setGender={(gender) => formik.setFieldValue("gender", gender)}
              />

              <StyledFormSelect
                id="district"
                name="district"
                title={t("field.district")}
                placeholder={t("placeholder.district.empty")}
              >
                {districtList.map((each) => (
                  <Form.Field.SelectOption key={each} value={each}>
                    {t(`placeholder.district.${each}`)}
                  </Form.Field.SelectOption>
                ))}
              </StyledFormSelect>

              <Typography fontSize="14px">
                {t("common:registerTnc.termsConditionsIntro")}
              </Typography>

              <ClickableCheckbox
                text={t("common:registerTnc.termsConditionsStatement")}
                clickableText={t(
                  "common:registerTnc.termsConditionsStatementPressable"
                )}
                endText={t("common:registerTnc.termsConditionsStatementSecond")}
                value={isSHKConfirmed}
                onClickText={() => setIsOpenPrivacy(true)}
                onChange={(e) => setIsSHKConfirmed(e.target.checked)}
                enableShowMore
              />
              <ClickableCheckbox
                text={t("common:tnc.termsConditionsStatement")}
                clickableText={
                  <>
                    <span onClick={() => setIsOpenPrivacyPolicy(true)}>
                      {`${t(
                        "common:tnc.termsConditionsStatementPrivacyPressable"
                      )}`}
                    </span>
                    <span onClick={() => setIsOpenTnc(true)}>
                      {`${t(
                        "common:tnc.termsConditionsStatementTncPressable"
                      )}`}
                    </span>
                  </>
                }
                value={isTncConfirmed}
                onChange={(e) => setIsTncConfirmed(e.target.checked)}
              />

              <GeneralButton
                fullWidth
                type="submit"
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  formik.isSubmitting ||
                  !isTncConfirmed
                }
              >
                {t("common:button.submit")}
              </GeneralButton>
            </Form.Element>
          );
        }}
      </Form>

      <SimpleTextModal
        open={isOpenTnc}
        onClose={() => setIsOpenTnc(false)}
        title={t("common:tnc.title")}
        content={t("common:tnc.desc")}
        needResponsive={true}
      />
      <SimpleTextModal
        open={isOpenPrivacy}
        onClose={() => setIsOpenPrivacy(false)}
        title={t("common:registerTnc.title")}
        content={t("common:registerTnc.desc")}
        enableHtml
        needResponsive={true}
      />
      <SimpleTextModal
        open={isOpenPrivacyPolicy}
        onClose={() => setIsOpenPrivacyPolicy(false)}
        title={t("common:tnc.privacyTitle")}
        content={t("common:tnc.privacyDesc")}
        enableHtml
        needResponsive={true}
      />
    </LogoCard>
  );
};

export default RegistrationForm;
