import { UpcomingFacilityBooking } from "../../../@types/common";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getCorrectUrl } from "navigators/Router";
import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import { getLocalisedString } from "common/utils/stringHelper";
import _ from "lodash";
import dayjs from "common/utils/dayjs";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";
import { ROUTE_BASE } from "Route";
import Styled from "./Styled";

type Props = {
  booking: UpcomingFacilityBooking;
  tagColor?: string; // default theme.orange
};

const UpcomingFacilityCard = (props: Props) => {
  const { booking, tagColor = theme.orange } = props;
  const { t, i18n } = useTranslation("home");
  const navigate = useNavigate();

  const reservedDate: string = useMemo(
    () =>
      dayjs(booking?.from)
        .locale(getLocalisedString("en", "zh-hk"))
        .tz().format(getLocalisedString("DD/MM/YYYY (ddd)", "DD/MM/YYYY (dd)")),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [booking.from, i18n.language]
  );

  return (
    <div className="w-full flex justify-center">
      <Styled.BoxItem
        onClick={() =>
          navigate(
            getCorrectUrl(`${ROUTE_BASE.MY_BOOKINGS}/${booking.orderId}`),
            {
              state: { bookingType: ProgramTypes.Facility },
            }
          )
        }
      >
        <Styled.ItemTitle color={theme.orange} className="mb-2.5">
          {getLocalisedString(booking.venueNameEN, booking.venueNameTC)}
        </Styled.ItemTitle>
        <div>
          <Styled.ItemDate>{reservedDate}</Styled.ItemDate>
          <Styled.ItemTime>
            {dayjs(booking.from).tz().format("HH:mm") +
              "-" +
              dayjs(booking.to).tz().format("HH:mm")}
          </Styled.ItemTime>
          <Styled.ItemType bg={tagColor}>
            {t(`upcoming.${ProgramTypes.Facility}`)}
          </Styled.ItemType>
        </div>
      </Styled.BoxItem>
    </div>
  );
};

export default UpcomingFacilityCard;
