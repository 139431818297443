import theme from "utils/theme";
import Styled from "./Styled";
import { CircularProgress } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  useNavigate,
  useOutlet,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { ROUTE_BASE } from "Route";
import _ from "lodash";
import { IPaymentContext } from "../../@types/common";
import { usePaymentViewModel } from "common/viewModels/PaymentViewModel";
import { IPaymentModelContext } from "models";
import { useTranslation } from "react-i18next";
import dayjs from "common/utils/dayjs";
import config from "config";

const ProcessingPayment = () => {
  const { t } = useTranslation("booking");
  const { setRefNo, setFailReason, setBookingType } =
    useOutletContext() as IPaymentContext;
  const { getPaymentStatus } = usePaymentViewModel() as IPaymentModelContext;
  const navigate = useNavigate();
  const { id: txnId } = useParams();
  const [pollingEndTimeUnix, setPollingEndTimeUnix] = useState<number | null>(
    null
  );

  const onSuccess = useCallback(
    (orderNum: string, bookingType: string) => {
      // setRepeater(MAX_REPEAT);
      setRefNo(orderNum);
      setBookingType(bookingType);
      navigate("success");
    },
    [setRefNo, setBookingType, navigate]
  );

  const onFailed = useCallback(
    (orderNum: string, errorMsg: string) => {
      // setRepeater(MAX_REPEAT);
      setRefNo(orderNum);
      setFailReason(errorMsg);
      navigate("fail");
    },
    [setRefNo, setFailReason, navigate]
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    const pollingPaymentStatus = async () => {
      if (pollingEndTimeUnix == null || onSuccess == null || onFailed == null) {
        return; // break
      }
      if (dayjs().unix() >= pollingEndTimeUnix) {
        clearInterval(intervalId);
        onFailed("TIMEOUT", "error.requestTimeout");
        return;
      }
      const paymentStatus = await getPaymentStatus(txnId as string);
      if (paymentStatus) {
        if (!paymentStatus.success) {
          // TODO see if any error need to handle, if not let it continue to retry
        } else if (paymentStatus.success) {
          switch (paymentStatus.result.payment_status) {
            case "PAID": {
              onSuccess(
                paymentStatus?.result?.ordernum,
                paymentStatus?.result?.typeV2
              );
              break;
            }
            case "FAILED": {
              onFailed(
                paymentStatus.result.ordernum,
                "error.paymnetGaywayFail"
              );
              break;
            }
            case "EXPIRED": {
              onFailed(
                paymentStatus.result.ordernum,
                "error.transactionTimeout"
              );
              break;
            }
            case "CANCELLED": {
              onFailed(
                paymentStatus.result.ordernum,
                "error.transactionCancelled"
              );
              break;
            }
            default:
              //Do nothing
              break;
          }
        }
      }
    };
    if (_.isEmpty(txnId)) {
      navigate(`/${ROUTE_BASE.MY_BOOKINGS}`);
    } else if (!_.isEmpty(txnId)) {
      setPollingEndTimeUnix(dayjs().unix() + config.PAYMENT_RETRY_MAX_SECONDS);
      pollingPaymentStatus(); // call first time
      intervalId = setInterval(
        pollingPaymentStatus,
        config.PAYMENT_RETRY_INTERVAL_SECONDS * 1000 // ms
      );
    }
    return () => {
      if (intervalId != null) {
        clearInterval(intervalId);
      }
    };
  }, [
    pollingEndTimeUnix,
    onSuccess,
    onFailed,
    navigate,
    setBookingType,
    txnId,
    getPaymentStatus,
  ]);

  return (
    <Styled.Loading>
      <CircularProgress style={{ color: theme.orange }} />
    </Styled.Loading>
  );
};

export default ProcessingPayment;
