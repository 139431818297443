import i18n from "../../../i18n";

export interface INotificationMessage {
  title: string;
  body: string;
}

export interface IPushNotificationMessage {
  en: NotificationMessage;
  zh: NotificationMessage;

  readonly title: string;
  readonly body: string;
}

export class NotificationMessage implements INotificationMessage {
  title: string;
  body: string;

  constructor({ title, body }: any) {
    this.title = title;
    this.body = body;
  }
}

export class PushNotificationMessage implements IPushNotificationMessage {
  en: NotificationMessage;
  zh: NotificationMessage;

  constructor({ en, zh }: any) {
    this.en = new NotificationMessage(en);
    this.zh = new NotificationMessage(zh);
  }

  get title(): string {
    return i18n.language === "en" ? this.en.title : this.zh.title;
  }

  get body(): string {
    return i18n.language === "en" ? this.en.body : this.zh.body;
  }
}
