import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useSearchParams } from "react-router-dom";
import * as _ from "lodash";
import { Event, EventType, ICategoryListViewModel } from "models";
import { useEventViewModel } from "common/viewModels/EventViewModel";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { IEventViewModel, IGlobalProviderContext } from "../../@types/common";
import EventBanner from "assets/bg-event-banner.png";
import EventFilter from "./_components/EventFilter";
import EventBoxItem from "./_components/EventBoxItem";
import Styled from "./Styled";
import PageContainer from "components/common/PageContainer";
import { useTranslation } from "react-i18next";
import Typography from "components/common/Typography";
import { useCategoryListViewModel } from "common/viewModels/FacilityBooking/CategoryListViewModel";
import Pagination from "components/common/Pagination";
import { Modules } from "common/models/Category";
import { Helmet } from "react-helmet";
import { getLocalisedString } from "common/utils/stringHelper";
import { getLocalizedPageTitleWithPrefix } from "utils/seo";

const MAX_NUMBER_PER_PAGE = 8;

const EventPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation("event");

  const { eventList, eventType, getFilteredEventList, getEventType } =
    useEventViewModel() as IEventViewModel;
  const { categoryList, getCategoryList } =
    useCategoryListViewModel() as ICategoryListViewModel;
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const page = parseInt(searchParams.get("page") ?? "1", 10);
  const typeIdOrNull = searchParams.get("typeId") ?? "";
  const categoryIdOrNull = searchParams.get("categoryId") ?? "";
  const enableFilter = searchParams.get("enableFilter") === "true" ?? false;
  const search = searchParams.get("q") ?? null;
  const [isLoading, setLoading] = useState<boolean>(true);

  const handleSearch = useCallback(
    (args: { search: string }) => {
      // console.log("setSearch - typeIdOrNull :>", typeIdOrNull, eventType);
      searchParams.set("q", args.search);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleFilterType = useCallback(
    (selectedType: EventType | null) => {
      if (!selectedType) {
        searchParams.set("enableFilter", "false");
        searchParams.delete("typeId");
        searchParams.delete("categoryId");
        setSearchParams(searchParams);
        return;
      }
      if (selectedType?._id === typeIdOrNull) {
        searchParams.set("enableFilter", "false");
        searchParams.delete("typeId");
        searchParams.delete("categoryId");
        setSearchParams(searchParams);
      } else {
        //reset filter when selected type is not sport
        if (!selectedType?.isSport) searchParams.delete("categoryId");

        //only display filter when selected sport
        searchParams.set("enableFilter", selectedType?.isSport.toString());
        searchParams.set("typeId", selectedType?.id);
        setSearchParams(searchParams);
      }
    },
    [typeIdOrNull, searchParams, setSearchParams]
  );

  const handleFilterCategory = (categoryId: string | null) => {
    setSearchParams((prev) => {
      if (categoryId == null) {
        searchParams.delete("categoryId");
      } else {
        searchParams.set("categoryId", categoryId);
      }
      return prev;
    });
  };

  // first load of page
  useEffect(() => {
    setLoading(true);
    getCategoryList(Modules.EVENT);
    getEventType(globalDispatch)
      .then(() =>
        getFilteredEventList(search ?? "", typeIdOrNull, "", globalDispatch)
      )
      .then(() => setLoading(false));
  }, []);

  useEffect(() => {
    getFilteredEventList(
      search ?? "",
      typeIdOrNull,
      categoryIdOrNull,
      globalDispatch
    );
  }, [typeIdOrNull, search, categoryIdOrNull]);

  const _eventElement = (data?: any) => {
    return (
      <Styled.ItemWrapper>
        {_.map(data, (item: Event) => (
          <EventBoxItem key={item._id} item={item}></EventBoxItem>
        ))}
      </Styled.ItemWrapper>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {getLocalizedPageTitleWithPrefix({
            en: "Event ︱GO PARK Sai Sha",
            tc: "活動︱西沙GO PARK",
          })}
        </title>
      </Helmet>
      <PageContainer banner={EventBanner} title={t("title")}>
        {eventType && (
          <EventFilter
            eventTypeList={eventType}
            categoryList={categoryList}
            activeEventTypeId={typeIdOrNull}
            activeCategoryId={categoryIdOrNull}
            searchText={search ?? ""}
            enableFilter={enableFilter}
            onFilterTag={handleFilterType}
            onFilterCategory={handleFilterCategory}
            onSearchClick={handleSearch}
          />
        )}

        {!isLoading && (
          <Pagination
            data={eventList}
            itemContainer={_eventElement}
            itemPerPage={MAX_NUMBER_PER_PAGE}
          />
        )}
      </PageContainer>
    </>
  );
};

export default EventPage;
