import { useTranslation } from "react-i18next";
import Styled from "./Styled";
import IconLoader from "assets/IconLoader";

interface StepProps {
  type?: string;
}

const BookingStep = ({ type = "pending" }: StepProps) => {
  const { t } = useTranslation("event");
  return (
    <Styled.StepWapper>
      <Styled.StepBox>
        <Styled.StepItem>
          <span>
            <IconLoader.IconCheck />
          </span>
          {t("event:step.step1")}
        </Styled.StepItem>
        <Styled.Divider isRedLine={false} />
        <Styled.StepItem>
          <span>
            {type === "pending" ? (
              <IconLoader.IconEdit />
            ) : (
              <IconLoader.IconCheck />
            )}
          </span>
          {t("event:step.step2")}
        </Styled.StepItem>
        <Styled.Divider isRedLine={type === "fail"} />
        <Styled.StepItem>
          {type === "pending" ? (
            <span></span>
          ) : type === "fail" ? (
            <IconLoader.IconStepError />
          ) : (
            <IconLoader.IconCheck />
          )}

          {t("event:step.step3")}
        </Styled.StepItem>
      </Styled.StepBox>
    </Styled.StepWapper>
  );
};

export default BookingStep;
