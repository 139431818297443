import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";
import Styled from "./Styled";
import { useTranslation } from "react-i18next";
import saishaLogoPng from "assets/ic-shishaLogo.png";
import saishaRegisterPng from "../../assets/register-success-decorate.png";
import saishaMobileVerifyPng from "../../assets/verify-mobile-success-decorate.png";
import GeneralButton from "../../components/common/Button";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import RegisterPageContainer from "pageComponents/register/RegisterPageContainer";
import { getCorrectUrl } from "navigators/Router";
import { ROUTE_BASE } from "Route";

export enum GreetingType {
  IS_UPDATE_MOBILE = "isUpdateMobile",
  IS_VERIFY_MOBILE = "isVerifyMobile",
  IS_REGISTER_SUCCESS = "isRegisterSuccess",
}

export const RegistrationGreeting = () => {
  const { t, i18n } = useTranslation("register");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({ type: "" });
  const type = searchParams.get("type");

  const greetingInfo = useMemo(() => {
    switch (type) {
      case GreetingType.IS_UPDATE_MOBILE: {
        return {
          img: saishaMobileVerifyPng,
          title: t("profile:personalInfo.updatePhoneSuccessTitle"),
          message: t("profile:personalInfo.updatePhoneSuccessMsg"),
          buttonLabel: t("profile:personalInfo.backToProfile"),
          onClick: () => navigate(getCorrectUrl(`${ROUTE_BASE.PROFILE}/edit`)),
        };
      }
      case GreetingType.IS_VERIFY_MOBILE: {
        return {
          img: saishaRegisterPng,
          title: t("profile:personalInfo.verifyPhoneSuccessTitle"),
          message: t("profile:personalInfo.verifyPhoneSuccessMsg"),
          buttonLabel: t("profile:personalInfo.backToProfile"),
          onClick: () => navigate(getCorrectUrl(`${ROUTE_BASE.PROFILE}/edit`)),
        };
      }
      default: {
        return {
          img: saishaRegisterPng,
          title: t("registration_success.title"),
          message: t("registration_success.message"),
          buttonLabel: t("button.back_to_home"),
          onClick: () => navigateToHomePage(),
        };
      }
    }
  }, [i18n.language, type]);

  // callbacks
  const navigateToHomePage = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div
      className={cn(
        "h-full flex flex-col align-center p-[15px] overflow-y-auto bg-[#fff] rounded-[15px]",
        "lg:flex-row lg:items-center lg:justify-center"
      )}
    >
      <div className="w-full mt-4 flex flex-col items-center lg:w-1/2">
        <Styled.Logo src={saishaLogoPng} />
        <Styled.PendingApprovalTitle className="mt-[26px] lg:mt-[113px]">
          {greetingInfo?.title}
        </Styled.PendingApprovalTitle>
        <Styled.PendingApprovalBody className="mt-7 text-justify">
          {greetingInfo?.message}
        </Styled.PendingApprovalBody>
        <div className="w-full mt-[56px] lg:w-[297px]">
          <GeneralButton
            type="button"
            width="100%"
            onClick={greetingInfo?.onClick}
          >
            {greetingInfo?.buttonLabel}
          </GeneralButton>
        </div>
      </div>
      <div className="w-full flex items-center mt-[31px] lg:w-1/2 lg:mt-0 ">
        <Styled.DecorateImg src={greetingInfo?.img} alt="decorate-img" />
      </div>
    </div>
  );
};
