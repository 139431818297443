import { getLocalisedString } from "common/utils/stringHelper";
import { Banner } from "models";
import { CustomArrowProps } from "react-slick";
import { WindowSize } from "utils/responsive";
import Styled from "./Styled";
import IconSlideLeft from "assets/slide-left.svg";
import IconSlideRight from "assets/slide-right.svg";
import _ from "lodash";

interface BannerProps {
  bannerList: Banner[];
}

const BannerSlider = ({ bannerList }: BannerProps) => {
  const isMobile = WindowSize() === "mobile";

  const CustomPrevArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return <Styled.PrevArrow src={IconSlideLeft} onClick={onClick} />;
  };

  const CustomNextArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return <Styled.NextArrow src={IconSlideRight} onClick={onClick} />;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const handleClickBanner = (banner: Banner) => {
    const url = isMobile
      ? getLocalisedString(banner?.mobile_url_en, banner?.mobile_url_tc)
      : getLocalisedString(banner?.desktop_url_en, banner?.desktop_url_tc);

    if (!_.isEmpty(url)) window.open(url, "_blank");
  };

  return (
    <Styled.CustomSlider {...settings}>
      {bannerList.map((banner) => (
        <div key={banner._id}>
          <Styled.SliderImage
            onClick={() => handleClickBanner(banner)}
            src={
              isMobile
                ? getLocalisedString(
                    banner?.mobile_image_en,
                    banner?.mobile_image_tc
                  )
                : getLocalisedString(
                    banner?.desktop_image_en,
                    banner?.desktop_image_tc
                  )
            }
          />
        </div>
      ))}
    </Styled.CustomSlider>
  );
};

export default BannerSlider;
