import React, { useCallback, useMemo } from "react";
import Styled from "../Styled";
import * as _ from "lodash";
import { Category, Event, EventType } from "models";
import { useNavigate } from "react-router-dom";
import dayjs from "common/utils/dayjs";
import { getLocalisedString } from "common/utils/stringHelper";
import { useTranslation } from "react-i18next";
import Typography from "components/common/Typography";
import CategoryTag from "./CategoryTag";
import theme from "utils/theme";
import IconLoader from "assets/IconLoader";

const EventBoxItem: React.FC<{ item: Event }> = ({ item }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const formattedDateList = useMemo(() => {
    return item.event_date.slice(0, 2).map((timeSlot) => {
      const startDate = dayjs(timeSlot?.start_date);
      const endDate = dayjs(timeSlot?.end_date);
      const dateString =
        startDate.tz().format("ddd DD/MM/YYYY, HH:mm - ") + endDate.tz().format("HH:mm");

      return dateString;
    });
  }, [item.event_date]);

  const icon = item?.event_type?.thumbnailSelected;

  return (
    <Styled.BoxItem>
      {item?.isFull && (
        <Styled.StatusTag>
          <Typography color={theme.white}>
            {t("event:category.full")}
          </Typography>
        </Styled.StatusTag>
      )}
      <Styled.ItemImg
        img={getLocalisedString(item.thumbnail_en, item.thumbnail_tc)}
      />
      <Styled.ItemContent>
        {item.event_type != null && (
          <div className="absolute top-[-17px] left-[25px]">
            <CategoryTag
              type={
                item?.event_type?.isSport
                  ? { ...item?.category, thumbnailSelected: icon }
                  : item?.event_type
              }
              bgColor={item?.event_type?.bgColor}
            />
          </div>
        )}
        <div>
          <Styled.ItemTitle>
            {getLocalisedString(item.title_en, item.title_tc)}
          </Styled.ItemTitle>
          {formattedDateList.map((date) => (
            <Styled.ItemSlot key={date}>{date}</Styled.ItemSlot>
          ))}
        </div>
        <Styled.ItemLink
          onClick={() =>
            navigate(`${item._id}`, {
              state: { item: JSON.stringify(item) },
            })
          }
        >
          <p>{t("button.readMore")}</p>
          <IconLoader.IconLink />
        </Styled.ItemLink>
      </Styled.ItemContent>
    </Styled.BoxItem>
  );
};

export default EventBoxItem;
