import React, { useEffect } from "react";
import cn from "classnames";
import Card from "components/common/Card";
import theme from "utils/theme";
import { getLocalizedString } from "utils/locale";

const CancelPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <div className={cn("h-full", "m-[50px_20px_100px]", "md:m-[50px_0_0px]")}>
        <Card
          bgColor={theme.lightCyan}
          style={{ padding: 35, width: "100%" }}
          maxHeight="none"
        >
          <div
            className={cn(
              "flex flex-col",
              "w-full max-w-[879px]",
              "m-[0_auto]",
              "md:pb-[163px]",
              "font-poppins text-base font-normal leading-6 tracking-[0px] text-left text-app-black"
            )}
          >
            <h1 className="text-2xl font-medium leading-[26px] tracking-[0px] text-left">
              {getLocalizedString("Cancellation Policy", "取消政策")}
            </h1>
            <h2 className="mt-5 md:mt-[40px] text-xl font-medium leading-[26px] tracking-[0px] text-left;">
              {getLocalizedString(
                "Users can cancel facility bookings and private coaching lessons via The App based on the below policies:",
                "用戶可根據以下政策透過應用程式取消設施預訂和私人教練課程："
              )}
            </h2>
            <ul className="mt-2 pl-[16px] list-disc">
              <li>
                {getLocalizedString(
                  "Facility bookings and private coaching cancelled with forty-eight (48) hours or more notice is eligible for refund in full in the form of voucher(s) which will be added to the User’s account on The App within thirty (30) days.",
                  "預訂時間前四十八（48）小時或以上取消，將有資格獲得全額退款，以代金券形式在三十（30）天內添加至用戶在應用程式上的帳戶。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "Facility bookings and private coaching cancelled with less than forty-eight (48) hours’ notice will not be refunded.",
                  "預訂時間前少於四十八（48）小時取消，則不予退款。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "All regular classes and event enrolments are final upon payment and cannot be cancelled.",
                  "所有常規課程和活動報名一經付款即確認，不可取消。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "Cancellations due to sickness for regular classes and private lessons will only be refunded should the appropriate documentation be uploaded to The App within forty-eight (48) hours of the lesson time and approved by the GPS Management.",
                  "因病取消常規課程和私人課程，只有在課程時間後四十八（48）小時內上傳相關證明文件至應用程式並獲得GPS管理層批准，方可獲得退款。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "In the case of booking cancellation initiated by GPS for reasons including but not limited to inclement weather, facility maintenance, events, all bookings will be refunded in full in the form of voucher(s) which will be added to the User’s account on The App within thirty (30) days.",
                  "若GPS因包括但不限於惡劣天氣、設施維修或活動等原因主動取消預訂，所有預訂將全額退款，以代金券形式在三十（30）天內添加至用戶在應用程式上的帳戶"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "All regular classes, private lessons and events have different enrolment periods. It is advised that interested Users to refer to the Terms & Conditions of each specific class, lesson, or event.",
                  "所有常規課程、私人教練課程和活動均有不同的報名期限。建議有興趣的用戶參閱每個特定課程、課程或活動的條款及細則。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "All bookings for regular classes, coach lessons, and events are available on The App on a first-come-first-served basis, subject to availability.",
                  "所有常規課程、私人教練課程和活動的預訂均可通過應用程式按先到先得的原則進行，視庫存情況而定。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "All bookings (facility, regular class, private lesson & event) are only confirmed once the payment is processed and confirmed in full.",
                  "所有預訂（包括設施、常規課程、教練課程和活動）僅在付款全額處理並確認後才算確認。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "GPS Management reserves the right to reject or cancel bookings, limit the number of users accessing the venue, or refuse entry to any individual based on health considerations or other factors that the management deems relevant.",
                  "GPS管理層保留拒絕或取消任何預訂的權利，也可能基於健康考量或其他管理層認為相關的因素，限制某個人進入場地或拒絕其入場。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "In the event of inclement weather, GPS facilities will be closed for the safety of all users, staff members and third-party vendors. For closure and reopening details, please refer to the latest announcements on the mobile app.",
                  "若遇惡劣天氣，GPS設施將為了所有用戶及工作人員的安全而關閉。關閉和重新開放的具體詳情，請查看應用程式上的最新公告。"
                )}
              </li>
            </ul>
            <h2 className="mt-6 text-xl font-medium leading-[26px] tracking-[0px] text-left;">
              {getLocalizedString(
                "User’s bookings affected by inclement weather, please refer to the below policies:",
                "若用戶預訂受到惡劣天氣影響，可根據以下政策行事："
              )}
            </h2>
            <ul className="mt-2 pl-[16px] list-disc">
              <li>
                {getLocalizedString(
                  "regular classes, Private Lessons, and Events: GPS and its third-party vendors will decide if lessons or events are to continue in inclement weather.",
                  "常規課程、私人教練課程和活動：GPS及其第三方供應商將決定在惡劣天氣下是否繼續進行。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "Facility Bookings: Users can request free cancellation and a full refund for facility bookings during inclement weather via email to enquiry@goparksports.hk. Each case will be determined on a case-by-case basis.",
                  "設施預訂：用戶可在惡劣天氣期間透過電子郵件至enquiry@goparksports.hk申請免費取消並全額退款。每個案例將根據具體情況進行評估。"
                )}
              </li>
            </ul>
            <p className="mt-6">
              {getLocalizedString(
                "GPS has the right to amend the Booking & Cancellation Policy at our sole discretion without prior notice.",
                "GPS保留在未經預先通知的情況下修改預訂及取消政策的權利。"
              )}
            </p>
          </div>
        </Card>
      </div>
    </main>
  );
};

export default CancelPolicyPage;
