import React from "react";
import styled from "styled-components";
import theme from "../../utils/theme";

interface CardProps {
  children: React.ReactNode;
  maxWidth?: string;
  bgColor?: string;
  borderColor?: string;
  className?: React.HTMLProps<HTMLElement>["className"];
}

const Card = styled.div<CardProps>`
  border-bottom-width: 2px;
  border-right-width: 2px;
  border-radius: 20px;
  border-color: ${(props) => props.borderColor ?? theme.orange};
  border-style: solid;
  overflow: hidden;
  height: auto;
  width: 100%;
  background-color: ${(props) => props.bgColor ?? theme.white};
  max-width: ${(props) => props.maxWidth ?? "auto"};
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
`;

const CommonRoundedCornerCard = ({ children, ...props }: CardProps) => {
  return <Card {...props}>{children}</Card>;
};

export default CommonRoundedCornerCard;
