import {
  MonthlyProgramClass,
  ProgramClassStatus,
} from "common/models/MonthlyProgram";
import GeneralButton from "components/common/Button";
import SecondaryButton from "components/common/SecondaryButton";
import { useTranslation } from "react-i18next";

export const ProgramClassEnrollButton = ({
  programClass,
  onClickEnroll,
  isLogin,
}: {
  programClass: MonthlyProgramClass;
  onClickEnroll: (e: React.MouseEvent) => void;
  isLogin: boolean;
}) => {
  const { t } = useTranslation("monthlyProgram");

  // fall back to pending enroll
  if (programClass.isAgeAllowed === false) {
    return (
      <GeneralButton disabled={true}>
        {t("monthlyProgramDetail.ageExceed")}
      </GeneralButton>
    );
  } else {
    return (
      <GeneralButton
        disabled={
          !isLogin ||
          !programClass.isAgeAllowed ||
          [
            ProgramClassStatus.ENROLLED,
            ProgramClassStatus.PENDING,
            ProgramClassStatus.CANCELLED,
            ProgramClassStatus.FULL,
          ].includes(programClass.status) ||
          programClass.isEnrollmentClosed
        }
        onClick={onClickEnroll}
      >
        {t(`monthlyProgramDetail.enrollStatusBtn.${programClass.status}`)}
      </GeneralButton>
    );
  }
};
