import { Paper } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { TimePicker, TimePickerProps } from "@mui/x-date-pickers/TimePicker";
import {
  DateTimePicker,
  DateTimePickerProps,
} from "@mui/x-date-pickers/DateTimePicker";
import { Dayjs } from "dayjs";
import styled from "styled-components";
import TextField, { TextFieldProps } from "./Textfield";
import { ReactComponent as CalendarSvg } from "../../assets/calendar-btn.svg";
import {
  DateCalendar,
  DateCalendarProps,
} from "@mui/x-date-pickers/DateCalendar";
import theme from "utils/theme";
import { zhHK } from "@mui/x-date-pickers/locales";
import "dayjs/locale/zh-hk";
import "dayjs/locale/en";
import i18n from "i18n";
import { formatDateToString } from "common/utils/DateHelper";

type BaseDatePickerProps = {
  id: string;
  name: string;
  title?: string;
  textFieldProps?: TextFieldProps;
  style?: React.CSSProperties;
};

type TDatePickerProps = DatePickerProps<any> & BaseDatePickerProps;

type TTimePickerProps = TimePickerProps<any> & BaseDatePickerProps;

type TDateTimePickerProps = DateTimePickerProps<any> & BaseDatePickerProps;

type TDateCalendarPickerProps = DateCalendarProps<any> & BaseDatePickerProps;

const StyledPaper = styled(Paper)`
  & .Mui-selected {
    background-color: ${theme.orange} !important;
  }
`;

export function BasicDatePicker(props: TDatePickerProps) {
  const { title, textFieldProps = {}, onChange } = props;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language === "zh" ? "zh-hk" : "en"}
      localeText={
        i18n.language === "zh"
          ? zhHK.components.MuiLocalizationProvider.defaultProps.localeText
          : undefined
      }
    >
      <DatePicker
        slots={{
          textField: TextField,
          openPickerIcon: CalendarSvg,
          desktopPaper: StyledPaper,
          mobilePaper: StyledPaper,
        }}
        slotProps={{
          textField: { title, ...textFieldProps },
        }}
        format="DD/MM/YYYY"
        {...props}
        onChange={(v, c) =>
          onChange && onChange(formatDateToString(v, "YYYY-MM-DD"), c)
        }
      />
    </LocalizationProvider>
  );
}

export function BasicTimePicker(props: TTimePickerProps) {
  const { title, textFieldProps = {}, onChange } = props;
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language === "zh" ? "zh-hk" : "en"}
      localeText={
        i18n.language === "zh"
          ? zhHK.components.MuiLocalizationProvider.defaultProps.localeText
          : undefined
      }
    >
      <TimePicker
        slots={{
          textField: TextField,
          openPickerIcon: CalendarSvg,
          desktopPaper: StyledPaper,
          mobilePaper: StyledPaper,
        }}
        slotProps={{
          textField: {
            title,
            ...textFieldProps,
          } as TextFieldProps,
        }}
        {...props}
        onChange={(v, c) =>
          onChange && onChange(formatDateToString(v, "HH:mm:ssZ"), c)
        }
      />
    </LocalizationProvider>
  );
}

export function BasicDateTimePicker(props: TDateTimePickerProps) {
  const { title, textFieldProps = {}, onChange } = props;
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language === "zh" ? "zh-hk" : "en"}
      localeText={
        i18n.language === "zh"
          ? zhHK.components.MuiLocalizationProvider.defaultProps.localeText
          : undefined
      }
    >
      <DateTimePicker
        slots={{
          textField: TextField,
          openPickerIcon: CalendarSvg,
          desktopPaper: StyledPaper,
          mobilePaper: StyledPaper,
        }}
        slotProps={{
          textField: { title, ...textFieldProps },
        }}
        {...props}
        onChange={(v, c) =>
          onChange && onChange(formatDateToString(v, "YYYY-MM-DDTHH:mm:ssZ"), c)
        }
      />
    </LocalizationProvider>
  );
}

export const DateCalendarPicker = ({
  onChange,
  ...props
}: TDateCalendarPickerProps) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language === "zh" ? "zh-hk" : "en"}
      localeText={
        i18n.language === "zh"
          ? zhHK.components.MuiLocalizationProvider.defaultProps.localeText
          : undefined
      }
    >
      <DateCalendar
        {...props}
        onChange={(v, c) =>
          onChange && onChange(formatDateToString(v, "YYYY-MM-DD"), c)
        }
      />
    </LocalizationProvider>
  );
};
