import Typography from "components/common/Typography";
import styled from "styled-components";
import theme from "utils/theme";
import { TableCell } from "@mui/material";
import { breakpoint } from "utils/responsive";
import Slider from "react-slick";

const NoLineCell = styled(TableCell)`
  && {
    border-bottom: none;
  }
`;
interface CellProps {
  isOdd: boolean;
}

const Cell = styled(TableCell)<CellProps>`
  background-color: ${(props) => (props.isOdd ? theme.white : "#f5f5f5")};
`;

const TableCard = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  height: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 16px 40px;

  @media ${breakpoint.mobile} {
    padding: 15px;
  }
`;

const TableTitle = styled.div<{ boldTitle?: boolean }>`
  width: 100%;
  height: auto;
  background-color: ${theme.cyan};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: ${(props) => (props.boldTitle ? "16px 20px" : "16px 40px")};

  @media ${breakpoint.mobile} {
    padding: 15px;
  }
`;

const TimeLabel = styled.div<{
  disabled: boolean;
  selected: boolean;
  isGuest: boolean;
}>`
  background-color: ${(props) =>
    props.selected ? theme.orange : props.disabled ? "#8C8C8C" : theme.white};
  border: 1px solid #c1c1c1;
  width: 125px;
  cursor: ${(props) =>
    props.disabled ? "not-allowed" : props.isGuest ? "not-allowed" : "pointer"};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  border-radius: 5px;
  position: relative;

  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

const ImageStar = styled.img`
  position: absolute;
  top: -5px;
  right: -3px;
`;

const TncCheckboxText = styled(Typography)`
  && {
    display: inline;
  }
`;

const BannerLeftArrow = styled.div`
  top: 50%;
  right: 0;
  position: absolute;
  margin-right: 15px;
  z-index: 100;
  cursor: pointer;
  height: 55px;
  width: 55px;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BannerRightArrow = styled(BannerLeftArrow)`
  left: 0;
  margin-left: 15px;
`;

const GolfBaySlider = styled(Slider)`
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default {
  TableCard,
  TableTitle,
  TimeLabel,
  ImageStar,
  TncCheckboxText,
  NoLineCell,
  Cell,
  BannerLeftArrow,
  BannerRightArrow,
  GolfBaySlider,
};
