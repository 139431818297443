import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import ChangePasswordPage from "./ChangePasswordPage";
import ChangePasswordSuccess from "./ChangePasswordSuccess";
import ChangePasswordScreen from "./ChangePasswordScreen";

const ChangePasswordRoute: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <ChangePasswordScreen />,
      children: [
        {
          path: "/",
          element: <ChangePasswordPage />,
        },
        {
          path: "/success",
          element: <ChangePasswordSuccess />,
        },
        {
          path: "*",
          element: <Navigate to={"./"} />,
        },
      ],
    },
  ]);
};

export default ChangePasswordRoute;
