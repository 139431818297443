import styled from "styled-components";
import { breakpoint, size } from "../../utils/responsive";
import theme from "utils/theme";
import GeneralButton from "components/common/Button";

const SharedCard = styled.div`
  border-radius: 10px;
  background: #fff;
`;

const MainInfoTitle = styled.span`
  color: var(--palette-ss-000, #000);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.491px; /* 165.571% */
`;

const MainInfoContent = styled.span`
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`;

const BookingMainImage = styled.img`
  flex-shrink: 0;
  border-radius: 10px;
  background: #d9d9d9;
  width: 100%;

  @media (min-width: ${size.screen1024}px) {
    width: 139.095px;
    height: 92px;
  }
`;

const DiscountText = styled.span`
  color: #039f54;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`;

const ReferenceText = styled.span`
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.491px; /* 165.571% */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
`;

const PaymentStatusText = ReferenceText;

const PaymentStatusLabel = styled.span`
  border-radius: 40px;
  padding: 2.67px 13.33px;
  background: grey;
  color: var(--palette-ss-white, #fff);
  text-align: center;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.667px; /* 166.667% */
`;

const TotalAmountText = styled.span`
  color: var(--palette-ss-000, #000);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.491px; /* 147.174% */
`;

const GuestListModelButton = styled.button`
  color: ${theme.orange};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;

  &:disabled {
    color: #8c8c8c;
  }
`;

const KeyMemberTitle = MainInfoTitle;

const KeyMemberSubtitle = styled(MainInfoContent)`
  line-height: 20px;
`;

const KeyMemberEmailContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 7.433px 14.867px;
  align-items: center;
  gap: 14.867px;
  border-radius: 7.433px;
  border: 0.743px solid var(--palette-ss-black, #4a4a4a);
  background: var(--palette-grey-text, #8c8c8c);
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: ${size.screen1024}px) {
    width: 429px;
  }
`;

const RemoveGuestButton = styled.button`
  color: #517e93;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  display: flex;
  align-items: center;
`;

const AccessPassButton = styled(GeneralButton)`
  && {
    border-radius: 10px;
    background-color: ${theme.orange};
    height: 92px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${theme.orange};
    }

    @media ${breakpoint.mobile} {
      height: auto;
    }
  }
`;

export default {
  MainInfoTitle,
  MainInfoContent,
  SharedCard,
  BookingMainImage,
  DiscountText,
  ReferenceText,
  PaymentStatusText,
  PaymentStatusLabel,
  TotalAmountText,
  KeyMemberTitle,
  KeyMemberSubtitle,
  KeyMemberEmailContainer,
  GuestListModelButton,
  RemoveGuestButton,
  AccessPassButton,
};
