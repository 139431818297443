import React from "react";
import Card from "components/common/Card";
import Typography from "../Typography";
import Styled from "./Styled";
import saishaLogoPng from "assets/ic-shishaLogo.png";
import theme from "utils/theme";
import LoginBackground from "components/LoginBackground/LoginBackground";
import cn from "classnames";
import Space from "../Space";
import { useTranslation } from "react-i18next";
import IconLanguage from "assets/ic-language.svg";
import { useNavigate } from "react-router-dom";

interface LogoCardProps {
  children: React.ReactNode;
  title?: string;
  enableChangeLang?: boolean;
}

const LogoCard = ({ children, title, enableChangeLang }: LogoCardProps) => {
  const { i18n, t } = useTranslation();
  const { pathname } = window.location;
  const navigate = useNavigate();

  const LanguageButton = () => {
    const changeLanguage = () => {
      const lang = i18n.language === "en" ? "zh" : "en";
      localStorage.setItem("language", lang);
      navigate(`/${lang}/${pathname.split("/").slice(2).join("/")}`);
    };

    return (
      <Styled.LanguageBtn onClick={changeLanguage}>
        <img src={IconLanguage} className="mr-[5px]" />
        <Typography>{t("login:button.language")}</Typography>
      </Styled.LanguageBtn>
    );
  };

  return (
    <LoginBackground fullScreen className={cn("grid", "place-items-center")}>
      <Styled.Container>
        <div className="w-full flex justify-end block mb-[30px] md:hidden">
          <LanguageButton />
        </div>
        <Styled.Logo src={saishaLogoPng} className="mb-[26px]" />
        {title && (
          <Typography fontSize="30px" textAlign="center" color={theme.black}>
            {title}
          </Typography>
        )}
        <Space size="30px" />
        {children}
        <div className="w-full hidden mt-[30px] md:block">
          <LanguageButton />
        </div>
      </Styled.Container>
    </LoginBackground>
  );
};

export default LogoCard;
