import { useEffect, useState } from "react";
import {
  getFacilityById,
  listFacility,
  listFacilityCategory,
} from "../network/facility";
import {
  appearGlobalError,
  appearGlobalLoading,
  disappearGlobalLoading,
} from "../context/requests/globalRequest";
import {
  listClassesByProgrammeId,
  listMonthlyProgram,
  listMonthlyProgramByCategory,
  waitlistingClass,
  getPendingPaymentStatus as getPendingPaymentStatusAPI,
  dropoutClassWaitingList,
} from "../network/monthlyProgram";
import { useCommonFetcher } from "./Common/CommonViewModel";
import { MonthlyProgramClass } from "../models/MonthlyProgram";
import _, { min } from "lodash";

export const ProgramClassStatus = {
  PENDING: "PENDING",
  OPEN_ENROLL: "OPEN_ENROLL",
  ENROLLED: "ENROLLED",
  WAIT_LIST: "WAIT_LIST",
  ALREADY_ON_LIST: "ALREADY_ON_LIST",
  CANCELLED: "CANCELLED",
  FULL: "FULL",
};

export const useMonthlyProgramViewModel = () => {
  const { fetcher } = useCommonFetcher();
  const [cachedProgrammeId, setCachedProgrammeId] = useState(null);
  const [monthlyProgramList, setMonthlyProgramList] = useState({
    list: [],
    total: 0,
  });
  const [programClassList, setProgramClassList] = useState([]);
  const [ageLimitError, setAgeLimitError] = useState(false);

  const getMonthlyProgramList = async (limit = 3) => {
    const res = await listMonthlyProgram({ limit });
    setMonthlyProgramList({ list: res.result, total: res.total });
  };

  const getMonthlyProgramListByCategory = async (payload, dispatch) => {
    try {
      if (ageLimitErrorChecker(payload.maxAge, payload.minAge)) {
        setAgeLimitError(true);
        throw Error;
      } else {
        appearGlobalLoading(dispatch);
        setAgeLimitError(false);
        const res = await listMonthlyProgramByCategory(
          Object.fromEntries(
            Object.entries(payload).filter(([_, v]) => v != null)
          )
        );
        setMonthlyProgramList({ list: res.result, total: res.total });
        disappearGlobalLoading(dispatch);
        return { success: true };
      }
    } catch (e) {
      //only display prompt when api return error
      if (!_.isEmpty(e?.response)) {
        disappearGlobalLoading(dispatch);
        if (dispatch) appearGlobalError(dispatch, e?.response?.data?.msg);
      }

      return { success: false };
    }
  };

  const getClassInfoByProgrammeId = async (programmeId = cachedProgrammeId) => {
    setCachedProgrammeId(programmeId);

    const res = await fetcher({
      api: () => listClassesByProgrammeId({ programmeId }),
    });

    if (res?.success) {
      const classList = res?.result.map(
        (monthlyProgramClass) => new MonthlyProgramClass(monthlyProgramClass)
      );
      setProgramClassList(classList);
    }

    return res;
  };

  const applyWaitingList = async (classId, dispatch) => {
    try {
      await waitlistingClass({ classId });
      await getClassInfoByProgrammeId();
      return {success: true}
    } catch (e) {
      if (dispatch) appearGlobalError(dispatch, e?.response?.data?.msg);
      return {success: false}
    }
  };

  const dropoutWaitingList = async (classId) => {
    const res = await fetcher({
      api: () => dropoutClassWaitingList({ classId }),
    });
    await getClassInfoByProgrammeId();
    return res;
    
  };

  const ageLimitErrorChecker = (maxAge, minAge) => {
    if (maxAge && minAge) return _.toNumber(minAge) > _.toNumber(maxAge);

    return false;
  };

  //Api will throw an error if any pending transactions is found to avoid double booking
  const getPendingPaymentStatus = async (id) => {
    const res = await fetcher({ api: () => getPendingPaymentStatusAPI(id) });
    return res;
  };

  return {
    monthlyProgramList,
    programClassList,
    ageLimitError,
    setAgeLimitError,
    getClassInfoByProgrammeId,
    getMonthlyProgramList,
    getMonthlyProgramListByCategory,
    applyWaitingList,
    dropoutWaitingList,
    getPendingPaymentStatus,
  };
};
