import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import PrivateCoachingPage from "./PrivateCoachingPage";
import CoachingListPage from "./CoachingListPage";
import CoachingDetailPage from "./CoachingDetailPage";
import { ROUTE_BASE } from "Route";
import CoachingBookingPage from "./CoachingBookingPage";
import CoachingBookingDetailPage from "./CoachingBookingDetailPage";
import CoachingScreen from "./CoachingScreen";
import CoachBookingDetailScreen from "./CoachBookingDetailScreen";

const PrivateCoachingRouter: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <CoachingScreen />,
      children: [
        {
          path: "/",
          element: <Navigate to={`../category`} />,
        },
        {
          path: "/category",
          element: <PrivateCoachingPage />,
        },
        {
          path: "/category/:id",
          element: <CoachingListPage />,
        },
        {
          path: "/coach/:coachId",
          element: <CoachingDetailPage />,
        },
        {
          path: "/coach/:coachId/booking",
          element: <CoachingBookingPage />,
        },
        {
          path: "/coach/:coachId/booking/confirm",
          element: <CoachBookingDetailScreen />,
        },
        {
          path: "*",
          element: <Navigate to={"../"} />,
        },
      ],
    },
  ]);
};

export default PrivateCoachingRouter;
