import React, { useCallback, useContext, useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { IUserViewModelContext } from "../../@types/common";
import { myBookingRoutes } from "./MyBookingRouter";
import Styled from "./Styled";
import { ROUTE_BASE } from "Route";
import { useSplashScreen } from "hooks/useSplashScreen";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import PageBackButton from "components/common/PageBackButton";
import { useBackButton } from "hooks/useBackButton";
import ProfileLayout from "layouts/ProfileLayout/ProfileLayout";
import { getCorrectUrl } from "navigators/Router";
import GeneralButton from "components/common/Button";
import theme from "utils/theme";
import IconQrcodeWhite from "assets/ic-qrCodeWhite.svg";
import { ResponsiveUI } from "utils/responsive";
import _ from "lodash";

export interface IMyBookingScreenContext {
  setIsDisplayPassBtn: (isDisplay: boolean) => void;
}

const MyBookingScreen = () => {
  const { t } = useTranslation("myBooking");
  const navigate = useNavigate();
  const { bookingId } = useParams();

  const [isDisplayPassBtn, setIsDisplayPassBtn] = useState<boolean>(false);

  const { isShowBack, setIsShowBack } = useBackButton();
  const { currentUser, isRetriving: isRetrivingUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { isSplashScreen } = useSplashScreen();

  const back = useCallback(() => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(myBookingRoutes[0].path);
    }
  }, [navigate]);

  useEffect(() => {
    if (isRetrivingUser === false && currentUser == null) {
      navigate(getCorrectUrl(ROUTE_BASE.AUTH + "/sign-in"), { replace: true });
    }
  }, [isRetrivingUser, currentUser, navigate]);

  useEffect(() => {
    if (!bookingId) {
      setIsDisplayPassBtn(false);
    }
  }, [bookingId]);

  if (isSplashScreen) return null;

  return (
    <ProfileLayout>
      <main>
        <div className="flex flex-col">
          <div className="flex justify-between items-center mb-[25px]">
            {isShowBack ? (
              <div className={cn("w-full", "hidden", "lg:block")}>
                <PageBackButton
                  onClick={back}
                  text={t("monthlyProgram:monthlyProgramDetail.btnBack")}
                />
              </div>
            ) : null}
          </div>
          <Styled.PageContainer>
            <Outlet context={{ setIsShowBack, setIsDisplayPassBtn }} />
          </Styled.PageContainer>
        </div>
      </main>
    </ProfileLayout>
  );
};

export default MyBookingScreen;
