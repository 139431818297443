import { ROUTE_BASE } from "Route";
import i18n from "i18n";

export const navToLogin = () => {
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("accessToken");
  window.location.href = `/${i18n.language}/${ROUTE_BASE.AUTH}/sign-in`;
};

export const getCorrectUrl = (url: string) => {
  return `/${i18n.language}/${url}`;
};
