import { useState } from "react";
import {
  getBanners as getBannersAPI,
  getLatestNews as getLatestNewsAPI,
  getHeadlineNews as getHeadlineNewsAPI,
} from "../network/home";
import { getEventList as getEventListAPI } from "../network/event";
import { appearGlobalError } from "../context/requests/globalRequest";
import { listConfirmedFacilityBooking, listUpcomingBookingAPI } from "../network/booking";
import { listCoachLesson } from "../network/coachLesson";
import { useCommonFetcher } from "./Common/CommonViewModel";

export const useHomeInfoViewModel = (dispatch) => {
  const { fetcher } = useCommonFetcher();
  const [bannerList, setBannerList] = useState([]);
  const [headlineNews, setHeadlineNews] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [latestNewsCount, setLatestNewsCount] = useState(null); // web need this for none item check
  const [coachLessonList, setCoachLessonList] = useState([]);

  const [upcomingBooking, setUpcomingBooking] = useState([]);

  const getHeadlineNews = async (limit = 10) => {
    const res = await fetcher({api: () => getHeadlineNewsAPI({ limit })});
    if(res?.success){
      setHeadlineNews(res?.result);
    }
    return res;
  };

  const getLatestNews = async (limit = 10, skip = 0) => {
    const res = await fetcher({api: () => getLatestNewsAPI({ limit, skip}), skipLoading: true, skipErrorHandle: true});
    setLatestNews(res.result);
    setLatestNewsCount(res?.total);
    return res.success;
  };

  const getFilteredLatestNews = async (keywords, limit = 10, skip = 0) => {
    const res = await fetcher({api: () => getLatestNewsAPI({ title: keywords, limit, skip }), skipErrorHandle: true});
    setLatestNews(res.result);
    setLatestNewsCount(res?.total);
    return res.success;
  };

  const getCoachLessonList = async () => {
    var res = await listCoachLesson();
    setCoachLessonList(res);
  };

  const getUpcomingBookingList = async (dispatch = null, limit = 3, skip = 0) => {
    try {
      const res = await listUpcomingBookingAPI({ limit, skip })
      setUpcomingBooking(res);
    } catch {
      // appearGlobalError(dispatch, e?.response?.data?.msg);
    }
  };

  const getBannerList = async (dispatch) => {
    try {
      const res = await getBannersAPI();
      setBannerList(res.result);
      return res.success;
    } catch (e) {
      // appearGlobalError(dispatch, e?.response?.data?.msg);
      return false;
    }
  };

  return {
    bannerList,
    headlineNews,
    latestNews,
    latestNewsCount,
    coachLessonList,
    upcomingBooking,
    getBannerList,
    getHeadlineNews,
    getLatestNews,
    getFilteredLatestNews,
    getCoachLessonList,
    getUpcomingBookingList,
  };
};
