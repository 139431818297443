import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import HeadlineNewsList from "./HeadlineNewsList";
import HeadlineNewsScreen from "./HeadlineNewsScreen";
import { ResponsiveUI } from "utils/responsive";
import HeadlineNewsDetail from "./HeadlineNewsDetail";
import { ROUTE_BASE } from "Route";

const HeadlineNewsRouter: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <HeadlineNewsScreen />,
      children: [
        {
          path: "/:newsId",
          element: (
            <ResponsiveUI
              desktop={<HeadlineNewsList />}
              mobile={<HeadlineNewsDetail />}
            />
          ),
        },
        {
          path: "/",
          element: <HeadlineNewsList />,
        },
        {
          path: "*",
          element: <Navigate to={ROUTE_BASE.HEADLINE_NEWS} />,
        },
      ],
    },
  ]);
};

export default HeadlineNewsRouter;
