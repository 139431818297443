import { ROUTE_BASE } from "Route";
import Card from "components/common/Card";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import theme from "utils/theme";
import cn from "classnames";

const GuestLoginCard = () => {
  const { t } = useTranslation("profile");
  return (
    <Card
      bgColor={theme.lightCyan}
      className="font-poppins"
      style={{ padding: 35, width: "100%" }}
    >
      {t("index.dontYouHaveAnAccount")}
      <Link
        to={`/${ROUTE_BASE.REGISTER}`}
        className={cn(`text-app-orange`, "underline", "ml-[5px]")}
      >
        {t("index.registerNow")}
      </Link>
    </Card>
  );
};

export default GuestLoginCard;
