import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CommonRoundedCornerCard from "../../components/common/CommonRoundedCornerCard";
import { UserViewModelContext } from "../../common/viewModels/UserViewModel";
import ProfileImage from "../../components/common/images/ProfileImage";
import Typography from "../../components/common/Typography";
import { useTranslation } from "react-i18next";
import Image from "../../components/common/Image";
import IconMemberVerified from "../../assets/ic-memberVerified.svg";
import IconGuest from "../../assets/bg-avatar-placeholder.png";
import Space from "../../components/common/Space";
import Label from "../../components/common/Label";
import theme from "../../utils/theme";
import dayjs from "common/utils/dayjs";
import { Divider } from "@mui/material";
import ProfileImageModal from "components/modals/ProfileImageModal/ProfileImageModal";
import { useModal } from "hooks/useModal";
import { ROUTE_BASE } from "Route";
import { WindowSize } from "utils/responsive";
import GeneralButton from "../../components/common/Button";
import Styled from "./Styled";
import IconVerification from "assets/ic-verification.svg";
import IconPendingAccount from "assets/ic-pendingAccount.svg";
import IconUnverifed from "assets/ic-unverified.svg";
import { getCorrectUrl } from "navigators/Router";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { IGlobalProviderContext } from "common/models/Common";
import ChildLabel from "pages/RegistrationScreen/Components/ChildLabel";

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`;

const MenuButton = styled(Typography)<{ selected?: boolean }>`
  && {
    cursor: pointer;
    margin-bottom: 10px;
    color: ${theme.black};
    font-size: 16px;
    ${(props) => props.selected && `color: ${theme.lightOrange}`}
  }
`;

const ProfileNav = () => {
  const { t, i18n } = useTranslation("profile");
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, logout, refreshCurrentUser } =
    useContext(UserViewModelContext);
  const { globalDispatch } = useContext(
    GlobalContextStore as any
  ) as IGlobalProviderContext;
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();

  const MENU_LIST = useMemo(() => {
    return currentUser
      ? [
          // { title: "notificationSetting", path: "/fake" },
          // { title: "sportPass", path: "profile/my-sport-pass" },
          // { title: "personalDevelopment", path: "/fake" },
          { title: "myActivity", path: ROUTE_BASE.MY_BOOKINGS },
          { title: "voucher", path: ROUTE_BASE.VOUCHER },
          /* { title: "helpCenter", path: "/profile/help" }, */
        ]
      : [
          /* { title: "helpCenter", path: "/profile/help" } */
        ];
  }, [currentUser]);

  const handleVerify = async () => {
    currentUser?.status !== "VERIFIED"
      ? await refreshCurrentUser(
          {
            checkIsNewUser: true,
          },
          () => navigate(getCorrectUrl(`${ROUTE_BASE.PROFILE}/verification`))
        )
      : navigate(getCorrectUrl(`${ROUTE_BASE.PROFILE}/mobile-verification`), {
          state: { isUpdate: true },
        });
  };

  return (
    <CommonRoundedCornerCard>
      <Container>
        <button
          type="button"
          onClick={() => {
            return currentUser?.signed_profile_pic_ref
              ? handleModalOpen()
              : undefined;
          }}
        >
          <ProfileImage
            src={currentUser?.signed_profile_pic_ref ?? IconGuest}
            width="90px"
            height="90px"
          />
        </button>
        <ProfileImageModal
          open={modalOpen}
          onClose={handleModalClose}
          image={currentUser?.signed_profile_pic_ref}
        />
        <div style={{ display: "flex" }}>
          <Typography fontSize="20px" fontWeight={500} color={theme.black}>
            {i18n.language === "en"
              ? currentUser?.name_en
              : currentUser?.name_zh}
          </Typography>
          <Space width="5px" />
          {currentUser?.status === "VERIFIED" && (
            <Image src={IconMemberVerified} />
          )}
        </div>
        {currentUser?.memberId && (
          <Typography
            fontSize="12px"
            fontWeight={400}
            color={theme.orange}
            className="mb-[5px]"
          >
            {t("userId", { id: currentUser?.memberId })}
          </Typography>
        )}
        {currentUser?.skipPhoneVerification === true && <ChildLabel />}
        {currentUser && currentUser?.status !== "VERIFIED" && (
          <Label bgColor={theme.flowerRed}>
            <div className="flex items-center">
              <img src={IconUnverifed} className="mr-[5px]" />
              <Typography color={theme.white}>
                {t("personalInfo.unverified")}
              </Typography>
            </div>
          </Label>
        )}
        {!!currentUser?.sportpass?.type &&
          dayjs().isBefore(dayjs(currentUser?.sportpass?.expiry_date)) && (
            <>
              <Label bgColor={theme.lightOrange}>
                <Typography color={theme.white}>
                  {t(
                    `personalInfo.${currentUser?.sportpass?.type?.toLowerCase()}Pass`
                  )}
                </Typography>
              </Label>
              <Space size="10px" />
              <Typography fontSize="14px">
                {`${t("personalInfo.expiryDate")}${dayjs(
                  currentUser?.sportpass?.expiry_date
                )
                  .tz()
                  .format("DD/MM/YYYY")}`}
              </Typography>
            </>
          )}
        {currentUser && <Space size="25px" />}
        {currentUser &&
          (currentUser?.status !== "VERIFIED" ||
            !currentUser?.isPhoneNumVerified) && (
            <Styled.VerificationButton onClick={handleVerify}>
              {currentUser?.status !== "VERIFIED"
                ? t("personalInfo.accountVerification")
                : t("personalInfo.phoneNumberValidation")}
              <img
                src={
                  currentUser?.status === "NEW" ||
                  !currentUser?.isPhoneNumVerified
                    ? IconVerification
                    : IconPendingAccount
                }
                className="ml-[5px]"
              />
            </Styled.VerificationButton>
          )}
        {currentUser && (
          <Styled.EditProfileButton
            onClick={() =>
              navigate(`/${i18n.language}/${ROUTE_BASE.PROFILE}/edit`)
            }
          >
            {t("index.editPersonalInfo")}
          </Styled.EditProfileButton>
        )}

        {MENU_LIST.length > 0 && <Divider style={{ width: "100%" }} />}
        <Space size="15px" />

        <div className="w-full text-center md:text-start">
          {MENU_LIST.map((item) => (
            <MenuButton
              key={item.title}
              selected={location?.pathname?.includes(item.path)}
              onClick={() => navigate(`/${i18n.language}/${item.path}`)}
            >
              {t(`index.${item.title}`)}
            </MenuButton>
          ))}
          <Divider style={{ marginBottom: 10 }} />
          {!currentUser && (
            <>
              <MenuButton
                onClick={() => {
                  navigate(`/${i18n.language}/${ROUTE_BASE.REGISTER}`);
                }}
              >
                {t("index.register")}
              </MenuButton>
              <MenuButton
                onClick={() => {
                  navigate(`/${i18n.language}/${ROUTE_BASE.AUTH}/sign-in`);
                }}
              >
                {t("index.accountLogin")}
              </MenuButton>
            </>
          )}
          {currentUser && (
            <>
              <MenuButton
                selected={location?.pathname?.includes(
                  ROUTE_BASE.CHANGE_PASSWORD
                )}
                onClick={() =>
                  navigate(getCorrectUrl(ROUTE_BASE.CHANGE_PASSWORD))
                }
              >
                {t("index.changePassword")}
              </MenuButton>
              <MenuButton onClick={() => logout(globalDispatch)}>
                {t("index.logout")}
              </MenuButton>
            </>
          )}
        </div>
      </Container>
    </CommonRoundedCornerCard>
  );
};

export default ProfileNav;
