import React, { useState, useEffect, useContext } from "react";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import PageHeader from "../../components/common/PageHeader";
import { useCategoryListViewModel } from "common/viewModels/FacilityBooking/CategoryListViewModel";
import { FacilityCategory } from "common/models/FacilityCategory";
import CategoryList from "pageComponents/facility/CategoryList";
import { ICategoryListViewModel } from "models";
import { ROUTE_BASE } from "Route";
import CoachBanner from "assets/bg-coach-banner.png";
import { IGlobalProviderContext } from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import PageContainer from "components/common/PageContainer";
import { Modules } from "common/models/Category";

const PrivateCoachingPage = () => {
  const { t } = useTranslation("coaching");
  const navigate = useNavigate();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );

  // categoryList for desktop
  // categoryRows for responsive (same as app)
  const { categoryList, getCategoryList } =
    useCategoryListViewModel() as ICategoryListViewModel;

  useEffect(() => {
    getCategoryList(Modules.COACH);
  }, []);

  return (
    <>
      <PageContainer
        title={t("privateCoachingList.screenHeader")}
        banner={CoachBanner}
        disableBg
      >
        <CategoryList
          categoryList={categoryList}
          onClickCategory={(facilityCategoryItem) =>
            navigate(`../category/${facilityCategoryItem._id}`)
          }
        />
      </PageContainer>
    </>
  );
};

export default PrivateCoachingPage;
