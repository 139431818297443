import React, { useState } from "react";
import BaseModal from "./BaseModal";
import Typography from "../Typography";
import Styled from "./Styled";
import GeneralButton from "../Button";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";
import Space from "../Space";
import IconClose from "../../../assets/ic-close.svg";

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  cancelBtnLael?: string;
  confirmBtnLabel?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmModal = ({
  children,
  open,
  cancelBtnLael,
  confirmBtnLabel,
  onClose,
  onConfirm,
}: ModalProps) => {
  const { t } = useTranslation("common");

  return (
    <BaseModal open={open} onClose={onClose}>
      <Styled.SimpleModalCotnainer>
        <div className="flex justify-end mb-[15px]">
          <img src={IconClose} className="cursor-pointer" onClick={onClose} />
        </div>
        <div>
          {children}
          <Space size="50px" />
          <div className="flex justify-center">
            <GeneralButton onClick={onClose} bgColor={theme.orange}>
              {cancelBtnLael ?? t("button.cancel")}
            </GeneralButton>
            <Space width="15px" />
            <GeneralButton onClick={onConfirm}>
              {confirmBtnLabel ?? t("button.confirm")}
            </GeneralButton>
          </div>
        </div>
      </Styled.SimpleModalCotnainer>
    </BaseModal>
  );
};

export default ConfirmModal;
