import React, { useMemo } from "react";
import dayjs from "common/utils/dayjs";
import Styled from "./Styled";
import { PastBookingProps } from "..";
import MyPastListingCard from "../MyPastListingCard/MyPastListingCard";
import { useTranslation } from "react-i18next";
import { getDayOfWeekSlug } from "common/utils/DateHelper";

type Props = {
  coachName: string;
  date: string | Date;
  times: {
    coach_start_time: string;
    coach_end_time: string;
  }[];
  location: string;
  onClick?: () => void;
} & PastBookingProps;

const MyCoachListingCard = (props: Props) => {
  const { coachName, date, times, location, onClick, isPastRecord, status } =
    props;

  const { t } = useTranslation();

  const dateInFormat = useMemo(() => {
    const dayOfWeekSlug = t(
      `common:dateFormat.isoWeekday.${getDayOfWeekSlug(date)}`
    );
    return dayjs(date ?? dayjs()).tz().format("DD/MM/YYYY") + ` (${dayOfWeekSlug})`;
  }, [t, date]);

  return isPastRecord ? (
    <MyPastListingCard title={coachName} status={status} onClick={onClick}>
      <div>{dateInFormat}</div>
      {times?.map((time, idx) => (
        <div key={idx}>
          {dayjs(time?.coach_start_time ?? dayjs()).tz().format("HH:mm") +
            "-" +
            dayjs(time?.coach_end_time ?? dayjs()).tz().format("HH:mm")}
        </div>
      ))}
      <div>{location}</div>
    </MyPastListingCard>
  ) : (
    <Styled.Card onClick={onClick}>
      <div className="w-full px-[20px] pt-[22px] pb-[28px] flex flex-col">
        <Styled.TitleText>{coachName}</Styled.TitleText>
        <div className="h-[5px]"></div>
        <Styled.BodyText>{dateInFormat}</Styled.BodyText>
        {times?.map((time, idx) => (
          <Styled.BodyText key={idx}>
            {dayjs(time?.coach_start_time ?? dayjs()).tz().format("HH:mm") +
              "-" +
              dayjs(time?.coach_end_time ?? dayjs()).tz().format("HH:mm")}
          </Styled.BodyText>
        ))}
        <Styled.BodyText>{location}</Styled.BodyText>
      </div>
    </Styled.Card>
  );
};

export default MyCoachListingCard;
