import IconLoader from "assets/IconLoader";
import { useTranslation } from "react-i18next";
import Typography from "../Typography";
import theme from "utils/theme";
import Space from "../Space";

interface ButtonProps {
  onClick?: () => void;
  isMore?: boolean;
}

const ReadmoreButton = ({ onClick, isMore = false }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center cursor-pointer" onClick={onClick}>
      <Typography color={theme.seaBlue} fontSize="18px">
        {isMore ? t("common:button.more") : t("common:button.readMore")}
      </Typography>
      <Space width="5px" />
      <IconLoader.IconLink />
    </div>
  );
};

export default ReadmoreButton;
