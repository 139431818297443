import { GlobalContextStore } from "common/context/providers/globalProvider";
import { IGlobalProviderContext } from "common/models/Common";
import { useFacilityVenueDetailViewModel } from "common/viewModels/FacilityBooking/FacilityVenueDetailViewModel";
import { IFacilityVenueDetailViewModel } from "models";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_BASE } from "Route";

import { getLocalisedString } from "common/utils/stringHelper";
import { Helmet } from "react-helmet";
import { removeHtmlTag } from "utils/common";
import { getLocalizedPageTitleWithPrefix } from "utils/seo";

export type IFacilityDetailContextType = {
  facilityDetailViewModel: IFacilityVenueDetailViewModel;
};

export const facilityDetailContext =
  React.createContext<IFacilityDetailContextType | null>(null);

const FacilityDetailProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { facilityId } = useParams();
  const { globalDispatch } = useContext(
    GlobalContextStore as any
  ) as IGlobalProviderContext;
  const navigate = useNavigate();
  const facilityDetailViewModel = useFacilityVenueDetailViewModel({
    facilityId,
    dispatch: globalDispatch,
  }) as unknown as IFacilityVenueDetailViewModel;

  useEffect(() => {
    if (!facilityId) {
      navigate(ROUTE_BASE.FACILITY_BOOKING);
      return;
    }
  }, []);

  useEffect(() => {
    const error = facilityDetailViewModel.facilityError;
    if (error?.success === false) {
      navigate(ROUTE_BASE.FACILITY_BOOKING);
    }
  }, [facilityDetailViewModel.facilityError]);

  const getMetaDescription = () => {
    const { facility } = facilityDetailViewModel;
    const localizedFacility = facility?.desc_en ?? facility?.desc_tc ?? "";
    return removeHtmlTag(localizedFacility);
  };

  return (
    <facilityDetailContext.Provider value={{ facilityDetailViewModel }}>
      <Helmet>
        <title>
          {getLocalizedPageTitleWithPrefix({
            en: facilityDetailViewModel.facility?.name_en,
            tc: facilityDetailViewModel.facility?.name_tc,
          })}
        </title>
        <meta name="description" content={getMetaDescription()} />
      </Helmet>
      {facilityDetailViewModel.facility && children}
    </facilityDetailContext.Provider>
  );
};

export default FacilityDetailProvider;
