import { Dispatch, SetStateAction, useState } from "react";
import { useOutletContext } from "react-router-dom";

type ContextType = {
  isShowBack: boolean;
  setIsShowBack: Dispatch<SetStateAction<boolean>>;
};

export function useBackButton() {
  const [isShowBack, setIsShowBack] = useState<boolean>(false);
  return { isShowBack, setIsShowBack };
}

export function useBackButtonContext() {
  return useOutletContext<ContextType>();
}
