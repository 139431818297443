import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import LoginScreen from "./LoginScreen";
import { ResetPassword } from "./ResetPassword";
import { ROUTE_BASE } from "../../Route";
import LoginHome from "./LoginHome";
import ForgotPassword from "./ForgotPassword";

const RegistrationRouter: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <LoginScreen />,
      children: [
        {
          path: "/",
          element: <Navigate to={`${ROUTE_BASE.AUTH}/sign-in`} />,
        },
        {
          path: "sign-in",
          element: <LoginHome />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        {
          path: "*",
          element: <Navigate to={`/${ROUTE_BASE.AUTH}/sign-in`} />,
        },
      ],
    },
  ]);
};

export default RegistrationRouter;
