import { IHeadlineNewsViewModel } from "common/models/HeadlineNew";
import { useHeadlineNewsViewModel } from "common/viewModels/HeadlineNewsViewModel";
import { useHomeInfoViewModel } from "common/viewModels/HomeInfoViewModel";
import Space from "components/common/Space";
import { HeadlineNews, IHomeInfoViewModel } from "models";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
export type IHeadlineNewsScreen = {
  headlineNews: HeadlineNews[];
};

const HeadlineNewsScreen = () => {
  const { headlineNews, getHeadlineNews } =
    useHeadlineNewsViewModel() as IHeadlineNewsViewModel;

  useEffect(() => {
    getHeadlineNews();
  }, []);

  return (
    <div className="pb-[100px] flex flex-col">
      <Space size="30px"></Space>
      <Outlet
        context={{
          headlineNews,
        }}
      />
      <Space size="30px"></Space>
    </div>
  );
};

export default HeadlineNewsScreen;
