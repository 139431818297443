import React, { useCallback, useMemo, useRef } from "react";
import cn from "classnames";
import { useContainerDimensions } from "../../../hooks/useContainerDimensions";
import { useScrollDimensions } from "../../../hooks/useScrollDimensions";
import { ReactComponent as LeftSvg } from "../../../assets/navbar-scroll-left-btn.svg";
import { ReactComponent as RightSvg } from "../../../assets/navbar-scroll-right-btn.svg";
import NavSliderItem, { NavItemProps } from "./NavSliderItem";
import Styled from "./Styled";

export type NavProps = {
  items: (NavItemProps & { key: string })[];
};

const NavSlider = (props: NavProps & React.HTMLProps<HTMLDivElement>) => {
  const containerRef = useRef<HTMLDivElement>();
  const scrollRef = useRef<HTMLDivElement>();
  const [containerWidth] = useContainerDimensions(containerRef);
  const [scrollWidth] = useScrollDimensions(scrollRef);
  const { items } = props;

  const scrollLeft = useCallback(() => {
    if (scrollRef.current != null) {
      scrollRef.current.scrollBy({
        left: -scrollRef.current.scrollWidth / 3,
        behavior: "smooth",
      });
    }
  }, []);
  const scrollRight = useCallback(() => {
    if (scrollRef.current != null) {
      // console.log(ref.current.scroll);
      scrollRef.current.scrollBy({
        left: scrollRef.current.scrollWidth / 3,
        behavior: "smooth",
      });
    }
  }, []);

  const isScrollDisplay = useMemo(() => {
    if (containerWidth < scrollWidth) {
      return true;
    }
    return false;
  }, [containerWidth, scrollWidth]);

  return (
    <Styled.Container
      ref={containerRef}
      className={cn("w-full relative", isScrollDisplay ? "px-[31px]" : null)}
    >
      <button
        type="button"
        onMouseDown={scrollLeft}
        className={cn(
          "absolute left-0 top-[calc(50%-8px)] -translate-y-1/2",
          isScrollDisplay ? "block" : "hidden"
        )}
      >
        <LeftSvg />
      </button>
      <button
        type="button"
        onMouseDown={scrollRight}
        className={cn(
          "absolute right-0 top-[calc(50%-8px)] -translate-y-1/2",
          isScrollDisplay ? "block" : "hidden"
        )}
      >
        <RightSvg />
      </button>
      <Styled.ScrollDiv
        ref={scrollRef}
        className="w-full overflow-x-auto overflow-y-hidden"
      >
        <div className="w-full min-w-max">
          <div className="w-full flex flex-start gap-[30px]">
            {items.map((itemProps) => {
              const { key, ...rest } = itemProps;
              return <NavSliderItem key={key} {...rest} />;
            })}
          </div>
        </div>
      </Styled.ScrollDiv>
    </Styled.Container>
  );
};

export default NavSlider;
