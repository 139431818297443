import styled from "styled-components";
import { breakpoint, size } from "../../utils/responsive";
import theme from "utils/theme";

const backgroundColor = "#E0E0DC";

const PageBackgroundContainer = styled.div`
  background-color: ${backgroundColor};
  width: 100%;
  height: auto;
  max-width: 1280px;
  min-height: calc(100vh - 120px - 163px);
  margin: 0 auto;
  padding: 20px;
  @media (min-width: ${size.screen1024}px) {
    padding: 50px;
  }
`;

const PageContainer = styled.div`
  /** Ref: https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container */
  min-width: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: ${theme.lightCyan};
  padding: 22px 26px 24px;
`;

const FilterLabel = styled.div`
  color: var(--palette-ss-000, #000);
  flex-shrink: 0;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  @media (max-width: 1280px) {
    text-align: start;
  }
`;

const CancelBookingButton = styled.button`
  width: 100%;
  padding: 6.1px 33.55px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 15.25px;
  border-radius: 10.675px;
  background: #eb4309;
  color: var(--palette-ss-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 36.6px; /* 228.75% */

  @media (min-width: ${size.screen1024}px) {
    width: fit-content;
  }
`;

export default {
  PageBackgroundContainer,
  PageContainer,
  FilterLabel,
  CancelBookingButton,
};
