import i18n from "../../../i18n";
import { Facility } from "../Facility";

export interface IMonthlyProgram {
    _id: string;
    thumbnailEn: string;
    thumbnailZh: string;
    titleEn: string;
    titleZh: string;
    descriptionEn: string;
    descriptionZh: string;
    photos: string[];
    remarksEn?: string;
    remarksZh?: string;
    facility?: Facility;
    tncDescriptionEn?: string;
    tncDescriptionZh?: string;
    tncTitleEn?: string;
    tncTitleZh?: string;
    bannerEn?: string;
    bannerZh?: string;

    readonly title: string;
    readonly description: string;
    readonly thumbnail: string;
    readonly banner?: string;
    readonly remarks?: string;
    readonly tncTitle?: string;
    readonly tncDescription?: string;
}

export class MonthlyProgram implements IMonthlyProgram {
    _id: string;
    thumbnailEn: string;
    thumbnailZh: string;
    titleEn: string;
    titleZh: string;
    descriptionEn: string;
    descriptionZh: string;
    photos: string[];
    remarksEn?: string;
    remarksZh?: string;
    facility?: Facility;
    tncDescriptionEn?: string;
    tncDescriptionZh?: string;
    tncTitleEn?: string;
    tncTitleZh?: string;
    bannerEn?: string;
    bannerZh?: string;

    constructor({
        _id, 
        thumbnailEn, 
        thumbnailZh, 
        titleEn, 
        titleZh, 
        descriptionEn,
        descriptionZh,
        photos,
        remarksEn,
        remarksZh,
        facility,
        tncDescriptionEn,
        tncDescriptionZh,
        tncTitleEn,
        tncTitleZh,
        bannerEn,
        bannerZh
    }: any) {
        this._id = _id
        this.thumbnailEn = thumbnailEn,
        this.thumbnailZh = thumbnailZh,
        this.titleEn = titleEn
        this.titleZh = titleZh
        this.descriptionEn = descriptionEn
        this.descriptionZh = descriptionZh
        this.photos = photos
        this.remarksEn = remarksEn
        this.remarksZh = remarksZh
        this.facility = facility ? new Facility(facility) : undefined
        this.tncDescriptionEn = tncDescriptionEn
        this.tncDescriptionZh = tncDescriptionZh
        this.tncTitleEn = tncTitleEn
        this.tncTitleZh = tncTitleZh
        this.bannerEn = bannerEn
        this.bannerZh = bannerZh
    }

    get title(): string {
        return i18n.language === "en" ? this.titleEn : this.titleZh
    }

    get description(): string {
        return i18n.language === "en" ? this.descriptionEn : this.descriptionZh
    }

    get thumbnail(): string {
        return i18n.language === "en" ? this.thumbnailEn : this.thumbnailZh
    }

    get remarks(): string | undefined {
        return i18n.language === "en" ? this.remarksEn : this.remarksZh
    }

    get tncTitle(): string | undefined {
        return i18n.language === "en" ? this.tncTitleEn : this.tncTitleZh
    }
    
    get tncDescription(): string | undefined {
        return i18n.language === "en" ? this.tncDescriptionEn : this.tncDescriptionZh
    }

    get banner(): string | undefined {
        return i18n.language === "en" ? this.bannerEn : this.bannerZh
    }
  }
  