export interface IMonthlyProgramLesson {
    date: string;
    startTime: string;
    endTime: string;
    isFuture: boolean;
    status?: string;
}

export class MonthlyProgrammeLesson implements IMonthlyProgramLesson {
    date: string;
    startTime: string;
    endTime: string;
    isFuture: boolean;
    status: string;

    constructor({
        date,
        startTime,
        endTime,
        isFuture,
        status
    }: any) {
        this.date = date;
        this.startTime = startTime;
        this.endTime = endTime;
        this.isFuture = isFuture;
        this.status = status;
    }
}