import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import _ from "lodash";
import dayjs from "common/utils/dayjs";
import { useBackButtonContext } from "hooks/useBackButton";
import {
  BookingDetailGuestCard,
  BookingDetailKeyMemberCard,
  BookingDetailReceipt,
  BookingDetailPaymentStatusCard,
  BookingDetailReferenceCard,
  BookingDetailSummaryCard,
} from "../../components/MyBookingDetailCard";
import { IMyBookingDetailViewModel } from "../../@types/common";
import { confirmModalContext } from "../../contexts/ConfirmModalProvider";
import { useMyBookingDetailViewModel } from "../../common/viewModels/MyBooking/MyBookingDetailViewModel";
import { ProgramTypes } from "../../common/viewModels/MyBooking/MyBookingListViewModel";
import { FacilityBookingOrder } from "../../common/models/FacilityBookingOrder";
import { FacilityBooking } from "../../common/models/FacilityBooking";
import { UserViewModelContext } from "../../common/viewModels/UserViewModel";
import { GlobalContextStore } from "../../common/context/providers/globalProvider";
import { getLocalizedString } from "../../utils/locale";
import {
  IUserViewModelContext,
  IGlobalProviderContext,
} from "../../@types/common";
import { ROUTE_BASE } from "../../Route";
import { EventBooking, Guest } from "../../models";
import Styled from "./Styled";
import { BookingPriceDetail, ProgramBooking } from "models/Programme";
import { CoachBooking } from "models/PrivateCoach";
import { Voucher } from "models/Voucher";
import LessonAttendanceItem, {
  LessonAttendanceProps,
} from "components/MyBookingListingCard/MyProgramListingCard/LessonAttendanceItem";
import BookingDetailProgramAttendanceCard from "components/MyBookingDetailCard/BookingDetailProgramAttendanceCard";
import { PromoCodeDiscountType } from "models/PromoCode";
import {
  ReceiptPromoCodeProps,
  ReceiptVoucherProps,
} from "components/MyBookingDetailCard/BookingDetailReceipt";
import Typography from "components/common/Typography";
import GeneralButton from "components/common/Button";
import theme from "utils/theme";
import { getCorrectUrl } from "navigators/Router";
import IconQrcodeWhite from "assets/ic-qrCodeWhite.svg";
import { IMyBookingScreenContext } from "./MyBookingScreen";

const promoCodeFromBookingPriceDetail = (
  bookingPriceDetail: BookingPriceDetail[]
) => {
  const usedPromoCode =
    bookingPriceDetail?.find((price) => price.type === "promoCode") ?? null;

  if (usedPromoCode) {
    const promoCode = {
      ...usedPromoCode.promoCodeSnapshot!,
      discountAmount: usedPromoCode.discountAmount
        ? parseFloat(usedPromoCode.discountAmount as string)
        : 0,
    };
    return promoCode as ReceiptPromoCodeProps;
  }
  return null;
};
const vouchersFromBookingPriceDetail = (
  bookingPriceDetail: BookingPriceDetail[]
) => {
  return (
    (bookingPriceDetail
      ?.filter((priceDetail) => priceDetail.type === "voucher")
      .map((voucher) => ({
        voucherName: (voucher.voucherSnapshot as Voucher).voucherId,
        voucherPrice: voucher.discountAmount as string,
      })) as ReceiptVoucherProps[]) || ([] as ReceiptVoucherProps[])
  );
};
type Booking = {
  id: string;
  bookingDate: string;
  bookingTime: string;
  bookingType?: string;
  price?: number;
};

export const MyBookingDetailPage: React.FC = () => {
  const { t, i18n } = useTranslation("myBooking");
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ location.state :", location.state);
    if (location.state == null || location.state == null) {
      navigate(`/${ROUTE_BASE.FACILITY_BOOKING}`);
    }
  }, [location, location.state, navigate]);
  const state = location?.state;
  const bookingType = (state && state["bookingType"]) ?? null;
  // Replaced by backend returned isPastBooking flag
  // const isPastBooking = (state && state["isPastBooking"]) ?? false;

  const { currentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { openModal: openConfirmModal, closeModal: closeConfirmModal } =
    useContext(confirmModalContext);

  const {
    checkIsBookingOwner,
    bookingRecord,
    getBookingGuestList,
    addGuests,
    guestLimit,
    isPastBooking,
    isAllowCancel,
    isAllowEditGuest,
    paymentMethod,
    // updateGuest,
    removeGuest,
    cancelBooking,
    requestProgramLessonSickLeave,
  } = useMyBookingDetailViewModel({
    currentUser,
    bookingId: params["bookingId"],
    bookingType,
    dispatch: globalDispatch,
  }) as IMyBookingDetailViewModel;
  const { setIsShowBack } = useBackButtonContext();
  const { setIsDisplayPassBtn } = useOutletContext() as IMyBookingScreenContext;

  const cancelBookingCallback = useCallback(() => {
    openConfirmModal({
      title: t("detail.cancelBookingPlaceHolder"),
      message:
        bookingType === ProgramTypes.Facility
          ? t("detail.cancelBookingRemark")
          : "",
      onConfirm: handleCancelbooking,
    });
  }, [openConfirmModal, cancelBooking, t]);

  const openModelAfterCancelBooking = (isSuccess: boolean) => {
    openConfirmModal({
      title: isSuccess
        ? t("detail.cancelBookingSuccess")
        : t("detail.cancelBookingFailed"),
      onConfirm: () => {
        isSuccess &&
          navigate({
            pathname: "../",
            search: createSearchParams({
              cat: bookingType,
            }).toString(),
          });
      },
    });
  };

  const handleCancelbooking = async () => {
    const res = await cancelBooking();
    setTimeout(() => openModelAfterCancelBooking(res?.success));
  };

  if (location.state?.bookingType == null) {
    navigate(`/${ROUTE_BASE.MY_BOOKINGS}`);
  }

  const getGuestList = useCallback(
    ({ isKeyMember } = { isKeyMember: false }) => {
      const guestList = getBookingGuestList();
      console.log("🚀 ~ guestList:", guestList);
      switch (bookingType) {
        case ProgramTypes.Facility:
          return (
            guestList?.filter((guest) => guest?.key_member == isKeyMember) ?? []
          );
        case ProgramTypes.Event:
          return isKeyMember ? [] : guestList;
        case ProgramTypes.Coach:
          return isKeyMember ? [] : guestList;

        default:
          return [];
      }
    },
    [bookingType, getBookingGuestList]
  );

  // const isCancelAllowed = useMemo(() => {
  //   if (isPastBooking || !bookingType) return false;
  //   switch (bookingType) {
  //     case ProgramTypes.Coach:
  //     case ProgramTypes.Facility:
  //       return true;
  //     default:
  //       return false;
  //   }
  // }, [bookingType, bookingRecord, isPastBooking]);

  const refNo = useMemo(() => {
    if (bookingType == null) {
      return "";
    }
    switch (bookingType) {
      case ProgramTypes.Program:
        return (bookingRecord as ProgramBooking)?.order.ordernum;
      case ProgramTypes.Coach:
        return (bookingRecord as CoachBooking)?.ordernum;
      case ProgramTypes.Event:
        return (bookingRecord as EventBooking)?.ref_number;
      case ProgramTypes.Facility:
        return (bookingRecord as FacilityBookingOrder)?.ordernum;
      default:
        return "";
    }
  }, [bookingType, bookingRecord]);

  const paymentStatus = useMemo(() => {
    let status: string;
    switch (bookingType) {
      case ProgramTypes.Program:
        status = (bookingRecord as ProgramBooking)?.status;
        break;
      case ProgramTypes.Coach:
        status = (bookingRecord as CoachBooking)?.status;
        break;
      case ProgramTypes.Event:
        status = (bookingRecord as EventBooking)?.status;
        break;

      case ProgramTypes.Facility:
        status = (bookingRecord as FacilityBookingOrder)?.status;
        break;
      default:
        status = "";
    }
    /* return status ? t(`detail.${status?.toLowerCase()}`) : ""; */
    return status?.toLowerCase();
  }, [bookingType, bookingRecord, t]);

  const paymentType = useMemo(() => {
    switch (bookingType) {
      case ProgramTypes.Program:
        return (
          (bookingRecord as ProgramBooking)?.transaction?.paymethod ?? "N/A"
        );
      case ProgramTypes.Coach:
        return (bookingRecord as CoachBooking)?.transaction?.paymethod ?? "N/A";
      case ProgramTypes.Event:
        return (bookingRecord as EventBooking)?.transaction?.paymethod ?? "N/A";
      case ProgramTypes.Facility:
        return _.isEmpty(
          (bookingRecord as FacilityBookingOrder)?.transaction?.paymethod
        )
          ? "N/A"
          : (bookingRecord as FacilityBookingOrder)?.transaction?.paymethod;

      default:
        return "";
    }
  }, [bookingType, bookingRecord]);

  const totalAmount = useMemo(() => {
    switch (bookingType) {
      case ProgramTypes.Program:
        return (bookingRecord as ProgramBooking)?.amount;
      case ProgramTypes.Coach:
        return (bookingRecord as CoachBooking)?.amount;
      case ProgramTypes.Event:
        return (bookingRecord as EventBooking)?.amount;

      case ProgramTypes.Facility:
        return (bookingRecord as FacilityBookingOrder)?.amount;

      default:
        return "";
    }
  }, [bookingType, bookingRecord]);

  const receiptProps = useMemo(() => {
    let thumbnail: string | undefined = undefined;
    let receiptChildren: React.ReactNode | undefined = undefined;
    let courseName = "";
    let promoCode: {
      code: string;
      discountType: PromoCodeDiscountType;
      discountAmount: number;
      discountPercentage?: number;
    } | null = null;
    let bookings: Booking[] = [];
    let vouchers: { voucherName: string; voucherPrice: string }[] = [];
    switch (bookingType) {
      case ProgramTypes.Program: {
        const programBooking = bookingRecord as ProgramBooking | null;
        thumbnail =
          i18n.language === "en"
            ? programBooking?.class.programme.thumbnailEn
            : programBooking?.class.programme.thumbnailZh;
        if (programBooking) {
          const month = dayjs(programBooking.class.classYearMonth).tz().format(
            "MMM"
          );
          courseName = getLocalizedString(
            programBooking.class.titleEn + " " + month,
            programBooking.class.titleZh + " " + month
          );

          vouchers = vouchersFromBookingPriceDetail(programBooking.priceDetail);

          promoCode = promoCodeFromBookingPriceDetail(
            programBooking.priceDetail
          );

          /* const usedPromoCode =
            programBooking.priceDetail.find(
              (price) => price.type === "promoCode"
            ) ?? null;


          if (usedPromoCode) {
            promoCode = {
              ...usedPromoCode.promoCodeSnapshot!,
              discountAmount: usedPromoCode.discountAmount
                ? parseFloat(usedPromoCode.discountAmount as string)
                : 0,
            };
          } */

          bookings = programBooking.lessons.map((lesson) => {
            const startDate = dayjs(lesson.startTime);
            const endDate = dayjs(lesson.endTime);
            return {
              id: `${lesson.startTime}-${lesson.endTime}`,
              bookingDate: startDate.tz().format("DD/MM/YYYY"),
              bookingTime:
                startDate.tz().format("HH:mm - ") + endDate.tz().format("HH:mm"),
            };
          });
        }
        receiptChildren = (
          <>
            <div>
              {t("booking:bookingDetail.pricePerLesson", {
                price: programBooking?.class.pricing,
              })}
            </div>
            {programBooking?.class.coaches.map((coach) => {
              return (
                <div key={coach._id}>
                  {getLocalizedString(coach.coach_name_en, coach.coach_name_zh)}
                </div>
              );
            })}
          </>
        );
        break;
      }
      case ProgramTypes.Coach: {
        const coachLesson = bookingRecord as CoachBooking | null;
        if (coachLesson) {
          courseName = getLocalizedString(
            coachLesson.coach_id.coach_name_en,
            coachLesson.coach_id.coach_name_zh
          );
          /* const promoCodeDetail = coachLesson.bookingPriceDetail.find(
            (priceDetail) => priceDetail.type === "promoCode"
          );
          const snapshot = promoCodeDetail?.promoCodeSnapshot;
           promoCode = snapshot
            ? {
                code: snapshot.code,
                discountType: snapshot.discountType,
                discountAmount: promoCodeDetail.discountAmount as number,
                discountPercentage: snapshot.discountPercentage,
              }
            : null; */
          promoCode = promoCodeFromBookingPriceDetail(
            coachLesson.bookingPriceDetail
          );
          vouchers = vouchersFromBookingPriceDetail(
            coachLesson.bookingPriceDetail
          );
          bookings = coachLesson.booking_id.map((booking) => {
            const startDate = dayjs(booking?.start_time);
            const endDate = dayjs(booking?.end_time);
            return {
              id: `${booking?.coach_start_time}_${booking?.coach_end_time}`,
              bookingDate: startDate.tz().format("DD/MM/YYYY"),
              bookingTime:
                startDate.tz().format("HH:mm - ") + endDate.tz().format("HH:mm"),
              price: booking.amount,
            };
          });
        }
        break;
      }
      case ProgramTypes.Event: {
        const eventBooking = bookingRecord as EventBooking | null;
        thumbnail =
          i18n.language === "en"
            ? eventBooking?.event.thumbnail_en
            : eventBooking?.event.thumbnail_tc;
        courseName = getLocalizedString(
          eventBooking?.event?.title_en ?? "",
          eventBooking?.event?.title_tc ?? ""
        );

        if (eventBooking) {
          vouchers = vouchersFromBookingPriceDetail(eventBooking.price_detail);
          promoCode = promoCodeFromBookingPriceDetail(
            eventBooking.price_detail
          );
        }

        bookings =
          eventBooking?.event?.event_date.map((event) => {
            const startDate = dayjs(event?.start_date);
            const endDate = dayjs(event?.end_date);
            return {
              id: `${event.start_date}_${event.end_date}`,
              bookingDate: startDate.tz().format("DD/MM/YYYY"),
              bookingTime:
                startDate.tz().format("HH:mm - ") + endDate.tz().format("HH:mm"),
            };
          }) ?? [];
        receiptChildren = (
          <>
            {
              <div>
                {t("event:zonePrice", {
                  price: eventBooking?.event.price,
                  zone: eventBooking?.event.zone?.map((z) => z.name),
                })}
              </div>
            }
            <div>
              {t("detail.attendees", {
                amount: eventBooking?.attendees_count,
              })}
            </div>
          </>
        );
        break;
      }
      case ProgramTypes.Facility: {
        const facilityBookingOrder =
          bookingRecord as FacilityBookingOrder | null;
        console.log(
          "🚀 ~ receiptProps ~ facilityBookingOrder:",
          facilityBookingOrder
        );

        courseName = facilityBookingOrder?.bookings
          ?.find(() => true)
          ?.venue?.getName();
        const bookingPriceDetail =
          facilityBookingOrder?.bookingPriceDetail as BookingPriceDetail[];
        if (bookingPriceDetail) {
          vouchers = vouchersFromBookingPriceDetail(bookingPriceDetail);
          promoCode = promoCodeFromBookingPriceDetail(bookingPriceDetail);
        }
        bookings =
          (facilityBookingOrder?.bookings as FacilityBooking[])?.map(
            (order, index) => {
              const reservedDate = dayjs(order.reserved_date).tz().format(
                "DD/MM/YYYY"
              );
              const timeSlot = `${dayjs(order?.start_time).tz().format(
                "HH:mm"
              )}-${dayjs(order?.end_time).tz().format("HH:mm")}`;
              return {
                id: `${index}`,
                bookingDate: reservedDate,
                bookingTime: timeSlot,
                bookingType:
                  order.isPeakHour && t("booking:bookingDetail.peak"),
                price: order.amount,
              };
            }
          ) ?? [];
        receiptChildren = (
          <div>
            {facilityBookingOrder?.bookings?.[0]?.attendees > 1 && (
              <div className="flex justify-between w-full">
                <div>
                  {t("detail.attendees", {
                    amount: facilityBookingOrder?.bookings?.[0]?.attendees,
                  })}
                </div>
                <Typography fontWeight="500">
                  {t("common:price", {
                    price: facilityBookingOrder?.bookings?.[0]?.amount
                      ? facilityBookingOrder?.bookings?.[0]?.amount *
                      facilityBookingOrder?.bookings?.[0]?.attendees
                      : "N/A",
                  })}
                </Typography>
              </div>
            )}
          </div>
        );
        break;
      }
      default:
        break;
    }
    return {
      thumbnail,
      courseName,
      promoCode: promoCode ?? undefined,
      bookings,
      vouchers,
      children: receiptChildren,
      bookingType,
    };
  }, [bookingType, bookingRecord, t]);

  useLayoutEffect(() => setIsShowBack(true), [setIsShowBack]);

  useEffect(() => {
    if (bookingType === ProgramTypes.Event) setIsDisplayPassBtn(true);
    else setIsDisplayPassBtn(false);
  }, [bookingType]);

  return bookingRecord ? (
    <div className="flex flex-col text-lg">
      <BookingDetailReceipt {...receiptProps} />
      <div className="h-[20px]" />
      <BookingDetailReferenceCard refNo={refNo} />
      <div className="h-[20px]" />
      <BookingDetailPaymentStatusCard
        paymentStatus={paymentStatus}
        paymentMethod={paymentMethod}
      />
      <div className="h-[20px]" />
      <BookingDetailSummaryCard totalAmount={totalAmount} />

      {/**these cards depend of types of booking and time of booking */}
      {checkIsBookingOwner() && (
        <>
          {/**program only show attendance */}
          {bookingType === ProgramTypes.Program && (
            <>
              <div className="h-[20px]" />
              <BookingDetailProgramAttendanceCard
                programLessons={(bookingRecord as ProgramBooking)?.lessons}
                requestProgramLessonSickLeave={requestProgramLessonSickLeave}
              />
            </>
          )}
          {/**non programme booking may show guest, key member and cancel button */}
          {bookingType !== ProgramTypes.Program && (
            <>
              {getGuestList({ isKeyMember: true }).length > 0 && (
                <>
                  <div className="h-[20px]" />
                  <BookingDetailKeyMemberCard
                    keyMembers={getGuestList({ isKeyMember: true })}
                  />
                </>
              )}
              <div className="h-[20px]" />
              {bookingRecord && (
                <BookingDetailGuestCard
                  isPastBooking={isPastBooking} // deprecated
                  isAllowEditGuest={isAllowEditGuest}
                  bookingType={bookingType}
                  booking={bookingRecord}
                  guests={getGuestList({ isKeyMember: false })}
                  guestLimit={guestLimit}
                  addGuestByCsvString={addGuests}
                  removeGuest={removeGuest}
                />
              )}
              {isAllowCancel && (
                <div className="mt-[36.1px]">
                  <Styled.CancelBookingButton
                    type="button"
                    onClick={cancelBookingCallback}
                  >
                    {`${t("detail.cancelBooking")}`}
                  </Styled.CancelBookingButton>
                </div>
              )}
              <div className="h-[44.8px]" />
            </>
          )}
        </>
      )}
    </div>
  ) : (
    <></>
  );
};
