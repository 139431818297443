import {
  authedInstance,
  generalInstanceWithBaseUrl,
  instance,
  kioskPaymentInstance,
} from ".";
import config from "../../config";
/*
-----BEGIN CERTIFICATE-----
MIIETTCCAjUCAQEwDQYJKoZIhvcNAQELBQAwaTELMAkGA1UEBhMCSEsxEDAOBgNV
BAgMB1NoYSBUaW4xEDAOBgNVBAcMB1NoYSBUaW4xEzARBgNVBAoMClplbnNpcyBM
dGQxITAfBgNVBAMMGHNhaXNoYXRlc3QuYmxvY2twcm9vZi5haTAeFw0yNDA4MjAw
MzU2MTlaFw0zNDA4MTgwMzU2MTlaMHAxCzAJBgNVBAYTAkhLMRAwDgYDVQQIDAdT
aGEgVGluMRAwDgYDVQQHDAdTaGEgVGluMRMwEQYDVQQKDApaZW5zaXMgTHRkMSgw
JgYDVQQDDB9zYWlzaGF0ZXN0LmJsb2NrcHJvb2YuYWkgY2xpZW50MIIBIjANBgkq
hkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlF/N9vhSlOOQhrz8dbsbweE0iaOP/DUa
WQyMSh5M8CFFHWEUFZr8/CquIvlkTnpYHl170aWoEDGkCTLaBinIxthgT4fHF90h
jgcvw+Q7C7n2GzFrS5+UaGxlGJYN014rQBpcqwCM9yRqYY7iptbL34TX2mJyzT5I
7W7KxI1bcOQCEyK7Wt5T7dj5SycqURh9fRw9nFyPzKB+bBMFGuW5Z6odlOfWLrwI
r+q/PEsBZ9PBmT1fVvcnyAc/GA/AqXQp51qiH5HSqqfSlBsECkv52UJEsBmHz4IR
RIypDzxnwxC7hkiKf/s9r37lca1n7HsQbu+2LVublB0zsN9ZN0dP6QIDAQABMA0G
CSqGSIb3DQEBCwUAA4ICAQBN5Aofs+jfim0V7sfJPHgXgB1ByQBo9UaFtQii/K/t
fckXiNAPS/BuJBPeMsEwj3C7VditpBTm3TDFW6cZbSupZtvRpdcQXPxt6LahkpvG
cfQlv8UxduVxcBDimSm90L3AuD3+ODCyoyumIHeUidNaTbs2brILTJucLxci0lU0
9n84NUwdtmwvaIMT4MwCbTSAfUBIE04Qc5sXPgLb2SMMAhVSQvz3yie3VYknXJYT
alloOUM77jy+uBTdmdD2K8F6keG9XsFt84jCvvBWZ0IfbM0QL0/toH8r2BXewode
Ow+J6rzQkhno2bilOTqe4oopTG/qS4xU6ZdpxO/u60ojfdzjFQUwVX6smPs6SR+q
upwQauot4ap44AoBssnx3+UrCH8wz0X46XfmbLD4lYl1yco34Dl/79hmrJDzMzta
b3Y393rrAmxNrsli09qCkseVAIAS5/3rbqyTGAT3T69N4Ldk77OmhSf3Sf5W/79q
zLGFZjCMZq5g0FQ4dNg98VyUjmHzCwiTQ5SOJSw3Sxrso+Nsu8xJ0u+Xiz5t3MWQ
5fR6Ol9FHFFPSbO665FFvaYR+PHEtcTyvMKKoksFlBOCXsnkaUz+ydJz0DY/tgSm
pqBYnVckf5EZelQ1KTUKcgrcea7gSXTwOYl2R6d1ewn1jZEZHmZe8ODQ5/z4x0BF
5g==
-----END CERTIFICATE-----
*/
export async function requestKioskCert(kioskName = "", password = "") {
  const res = await instance.post("/v1/app/kiosk/cert", {
    kiosk_id: kioskName, // || config.KIOSK_ID,
    password: password,
  });
  console.log("Login res: ", res.data, instance.getUri());
  return {
    cert: res.data.result.client_public_key,
    a8Ip: res.data.result.a8_ip,
  };
}

export async function getKioskPresignedURL() {
  try {
    const response = await generalInstanceWithBaseUrl.post(
      "v1/app/kiosk/transaction/requestPresignedUrl/" + config.KIOSK_ID,
      {
        category: "transactions",
        MIMEType: "txt",
      }
    );
    return response.data.result;
  } catch (e) {
    console.error("Failure!");
    console.error(e);
  }
}

export async function uploadKioskLogToS3(url, buffer) {
  try {
    const response = await generalInstanceWithBaseUrl.put(url, buffer, {
      headers: {
        "content-type": "",
      },
    });
    return response;
  } catch (e) {
    console.error("Failure to upload log to s3");
    console.error(e.message);
  }
}

export async function createKioskBooking(payload) {
  delete payload.mobileOS;
  const res = await authedInstance.post(
    "v1/app/kiosk/booking?createPayment=true",
    { ...payload, kiosk_id: config.KIOSK_ID }
  );
  return res.data;
}

export async function requestKioskPayment(method, amount) {
  const res = await kioskPaymentInstance.get(config.API_ROOT);
  return res.data;
}
