import Typography from "components/common/Typography";
import styled from "styled-components";
import theme from "utils/theme";

const LinkTextButton = styled(Typography)`
  && {
    color: ${theme.orange};
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default {
  LinkTextButton,
};
