import styled from "styled-components";

const PastListing = styled.div`
  font-family: Poppins;
  background-color: #eaeaea;

  border-bottom-width: 2px;
  border-right-width: 2px;
  border-radius: 10px;
  border-color: #8c8c8c;
  border-style: solid;

  padding: 22px 34px 28px 37px;
`;
const TitleText = styled.span`
  font-family: Poppins;
  color: var(--palette-ss-000, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.491px; /* 165.571% */
`;

export default { PastListing, TitleText };
