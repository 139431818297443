import GeneralConformModal from "components/modals/GeneralConformModal/GeneralConformModal";
import { createContext, useState } from "react";

type DefaultContext = {
  // title: string;
  // message: string | null;
  // confirmCallback: null | (() => void | Promise<void>);
  // open: boolean;
  openModal: (operation: {
    title: string;
    message?: string;
    onConfirm?: () => void | Promise<void>;
  }) => void;
  closeModal: () => void;
};

export const confirmModalContext = createContext<DefaultContext>({
  openModal: () => console.log("open click"),
  closeModal: () => console.log("close click"),
});

type Props = {
  children: React.ReactElement | React.ReactElement[];
};
const ConfirmModalProvider = (props: Props) => {
  const { children } = props;
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [callBack, setCallBack] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);

  const openModal = (operation: {
    title: string;
    message?: string;
    onConfirm?: () => void | Promise<void>;
  }) => {
    const { title, message, onConfirm } = operation;
    setTitle(title);
    if (message != null) {
      setMessage(message);
    }
    if (onConfirm != null) {
      setCallBack(() => onConfirm);
    }
    setOpen(true);
  };

  const closeModal = () => {
    setTitle("");
    setMessage("");
    setCallBack(null);
    setOpen(false);
  };

  return (
    <confirmModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {children}
      <GeneralConformModal
        title={title}
        message={message}
        open={open}
        confirmCallback={callBack}
        closeModal={closeModal}
      />
    </confirmModalContext.Provider>
  );
};

export default ConfirmModalProvider;
