import { ROUTE_BASE } from "Route";
import { useNotificationListViewModel } from "common/viewModels/NotificationCenter/NotificationListViewModel";
import {
  INotificationListViewModel,
  NotificationType,
} from "models/Notification";
import React, { useEffect } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Styled from "./Styled";
import NotificationItem from "pageComponents/notifacation/NotificationItem";
import InfiniteScroll, {
  Props as InfiniteScrollProps,
} from "react-infinite-scroll-component";
import { INotificationListOutletContextType } from "./NotificationScreen";
import { useTranslation } from "react-i18next";
import { ChatbotBody } from 'pages/Chatbot'

type LoadingWithFallbackProps = {
  isLoading: boolean;
} & InfiniteScrollProps;
const LoadingWithFallback = ({
  isLoading,
  dataLength,
  ...props
}: LoadingWithFallbackProps) => {
  const { t } = useTranslation("notification");
  return isLoading === false && dataLength === 0 ? (
    <div>{t("noMessage")}</div>
  ) : (
    <InfiniteScroll dataLength={dataLength} {...props}>
      {props.children}
    </InfiniteScroll>
  );
};

const NotificationList = () => {
  const params = useParams();
  const navigate = useNavigate();
  console.log("🚀 ~ NotificationList ~ params:", params);
  const {
    notificationListViewModel: {
      generalNotificationList,
      messageNotificationList,
      generalNotificationListHasNextPage,
      messageNotificationListHasNextPage,
      getGeneralNotificationList,
      getMessageNotificationList,
    },
    generalLoading,
    messageLoading,
  } = useOutletContext<INotificationListOutletContextType>();

  useEffect(() => {
    if (
      params.type !== NotificationType.GENERAL &&
      params.type !== NotificationType.MESSAGE &&
      params.type !== NotificationType.CHATBOT
    ) {
      navigate("../" + NotificationType.GENERAL);
      return;
    }
  }, []);
  return (
    <Styled.PageContainer>
      <main>
        {params.type === NotificationType.GENERAL && (
          <LoadingWithFallback
            loader={<></>}
            isLoading={generalLoading}
            hasMore={generalNotificationListHasNextPage}
            next={() =>
              getGeneralNotificationList({
                handledLoading: true,
                nextPage: true,
              })
            }
            dataLength={generalNotificationList.length}
          >
            <div className="flex flex-col gap-5">
              {generalNotificationList.map((notification) => {
                return (
                  <NotificationItem
                    onClick={() => navigate(`${notification._id}`)}
                    notificationType={NotificationType.GENERAL}
                    notification={notification}
                    key={notification._id}
                  />
                );
              })}
            </div>
          </LoadingWithFallback>
        )}
        {params.type === NotificationType.MESSAGE && (
          <LoadingWithFallback
            isLoading={messageLoading}
            loader={<></>}
            hasMore={messageNotificationListHasNextPage}
            next={() =>
              getMessageNotificationList({
                handledLoading: true,
                nextPage: true,
              })
            }
            dataLength={messageNotificationList.length}
          >
            <div className="flex flex-col gap-5">
              {messageNotificationList.map((notification) => {
                return (
                  <NotificationItem
                    onClick={() => navigate(`${notification._id}`)}
                    notificationType={NotificationType.MESSAGE}
                    notification={notification}
                    key={notification._id}
                  />
                );
              })}
            </div>
          </LoadingWithFallback>
        )}
        {params.type === NotificationType.CHATBOT && (
          <ChatbotBody />
        )}
      </main>
    </Styled.PageContainer>
  );
};

export default NotificationList;
