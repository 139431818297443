import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Select, SelectOption } from "../../components/common/Select";
import PageHeader from "../../components/common/PageHeader";

import {
  Wapper,
  Row,
  BoxImg,
  BoxContent,
  BoxItem,
  BoxItemImg,
  ItemGroup,
  Container,
  SearchContainer,
  Textfield,
  Button,
  Paging,
  PagingItem,
} from "./Styled";
import { IHomeInfoViewModel, LatestNew } from "models";
import { getLocalisedString } from "common/utils/stringHelper";
import { useHomeInfoViewModel } from "common/viewModels/HomeInfoViewModel";
import dayjs from "common/utils/dayjs";
import { ROUTE_BASE } from "Route";
import HTMLTypography from "components/common/HTMLTypography";
import Pagination from "components/common/Pagination";
import { WindowSize } from "utils/responsive";
import Space from "components/common/Space";
import PageContainer from "components/common/PageContainer";
import GeneralButton from "components/common/Button";
import theme from "utils/theme";
import SearchInput from "components/common/SearchInput";
import { LatestNewsViewModel } from "common/viewModels/LatestNewViewModel";
import { ILatestNew, ILatestNewViewModel } from "common/models/LatestNew";
import { Helmet } from "react-helmet";
import { getLocalizedPageTitleWithPrefix } from "utils/seo";

interface RenderItemProps {
  numberOfItem: number;
  items: ILatestNew[];
}

const RenderItem = ({ items, numberOfItem }: RenderItemProps) => {
  const windowSize = WindowSize();
  const navigate = useNavigate();

  const numberOfCheck = numberOfItem === 14 ? 7 : 4;
  let box1;
  let box2;
  const box3 = [];
  const box4 = [];

  const handleClickNews = (id: string, item: ILatestNew) => {
    navigate(`../${id}`);
  };

  for (let i = 0; i < _.size(items); i++) {
    const item = items[i];
    const title = getLocalisedString(item?.title_en, item?.title_zh);
    const desc = getLocalisedString(item?.desc_en, item?.desc_zh);
    const img = getLocalisedString(
      item?.thumbnail_ref_en,
      item?.thumbnail_ref_zh
    );
    const date = dayjs(item?.updatedAt)
      .tz()
      .format("DD/MM/YYYY");
    const id = item?._id;

    if (windowSize === "mobile") {
      if (i === 0) {
        box1 = (
          <Row
            className="border cursor-pointer"
            onClick={() => handleClickNews(id, item)}
          >
            <BoxImg bg={img} />
            <BoxContent>
              <strong>{title}</strong>
              <HTMLTypography content={desc} />
              <em>{date}</em>
            </BoxContent>
          </Row>
        );
      } else {
        box3.push(
          <BoxItem onClick={() => handleClickNews(id, item)}>
            <BoxItemImg bg={img} />
            <strong>{title}</strong>
            <em>{date}</em>
          </BoxItem>
        );
      }
    } else {
      if (i === 0) {
        box1 = (
          <Row
            className="border cursor-pointer"
            onClick={() => handleClickNews(id, item)}
          >
            <BoxImg bg={img} />
            <BoxContent>
              <strong>{title}</strong>
              <HTMLTypography content={desc} />
              <em>{date}</em>
            </BoxContent>
          </Row>
        );
      } else if (i === numberOfCheck) {
        box2 = (
          <Row
            className="border rtl cursor-pointer"
            onClick={() => handleClickNews(id, item)}
          >
            <BoxContent>
              <strong>{title}</strong>
              <HTMLTypography content={desc} />
              <em>{date}</em>
            </BoxContent>
            <BoxImg bg={img} />
          </Row>
        );
      } else if (i > 0 && i < numberOfCheck) {
        box3.push(
          <BoxItem onClick={() => handleClickNews(id, item)}>
            <BoxItemImg bg={img} />
            <strong>{title}</strong>
            <em>{date}</em>
          </BoxItem>
        );
      } else {
        box4.push(
          <BoxItem
            className="cursor-pointer"
            onClick={() => handleClickNews(id, item)}
          >
            <BoxItemImg bg={img} />
            <strong>{title}</strong>
            <em>{date}</em>
          </BoxItem>
        );
      }
    }
  }

  return (
    <>
      {box1 && box1}
      {_.size(box3) > 0 && (
        <ItemGroup className="border">{_.map(box3, (b) => b)}</ItemGroup>
      )}
      {box2 && box2}
      {_.size(box4) > 0 && <ItemGroup>{_.map(box4, (b) => b)}</ItemGroup>}
    </>
  );
};

type Props = object;

const MAX_NUMBER_PER_PAGE = 14;

const News = (props: Props) => {
  const { t } = useTranslation("home");
  const { getLatestNews, latestNews, getFilteredLatestNews } =
    LatestNewsViewModel() as ILatestNewViewModel;
  const [keyword, setKeyword] = useState<string>("");

  useEffect(() => {
    getLatestNews(undefined, 0, false);
  }, []);

  const handleSearch = (keyword: string) => {
    getFilteredLatestNews(keyword, undefined);
  };

  const handleInputChange = (e: any) => {
    setKeyword(e.target.value);
  };

  const _newElement = (data?: any) => {
    return <RenderItem items={data} numberOfItem={MAX_NUMBER_PER_PAGE} />;
  };

  return (
    <>
      <Helmet>
        <title>
          {getLocalizedPageTitleWithPrefix({
            en: "Class Highlights︱GO PARK Sai Sha",
            tc: "課程推薦︱西沙GO PARK",
          })}
        </title>
      </Helmet>
      <PageContainer title={t("news.title")} banner="/new.svg">
        <SearchContainer>
          <SearchInput
            id="search"
            name="search"
            value={keyword}
            onChange={handleInputChange}
            onClickClear={() => {
              handleInputChange({ target: { value: "" } });
              handleSearch("");
            }}
            placeholder={t("common:search.placeholder")}
          />

          <GeneralButton onClick={() => handleSearch(keyword)}>
            {t("common:search.placeholder")}
          </GeneralButton>
        </SearchContainer>

        <Pagination
          data={latestNews}
          itemContainer={_newElement}
          itemPerPage={MAX_NUMBER_PER_PAGE}
        />
      </PageContainer>
    </>
  );
};

export default News;
