import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import CancelPolicyPage from "./CancelPolicyPage";
import TncPage from "./TncPage";

const TncRouter = () => {
  return useRoutes([
    {
      path: "/",
      element: <TncPage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicyPage />,
    },
    {
      path: "/cancel-policy",
      element: <CancelPolicyPage />,
    },
    {
      path: "*",
      element: <Navigate to={"/"} />,
    },
  ]);
};

export default TncRouter;
