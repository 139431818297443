import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Textfield from "../components/common/Textfield";
import { useContext } from "react";
import { UserViewModelContext } from "../common/viewModels/UserViewModel";
import GeneralButton from "../components/common/Button";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Banner from "../pageComponents/home/Banner";
import Top from "../pageComponents/home/Top";
import Upcoming from "../pageComponents/home/Upcoming";
import News from "../pageComponents/home/News";
import UpcomingEvent from "../pageComponents/home/UpcomingEvent";
import Adv from "../pageComponents/home/Adv";
import { useHomeInfoViewModel } from "common/viewModels/HomeInfoViewModel";
import { IEventViewModel, IHomeInfoViewModel } from "models";
import { useEventViewModel } from "common/viewModels/EventViewModel";
import { useDiscoverViewModel } from "common/viewModels/DiscoverViewModel";
import { IDiscoverViewModel } from "models/Discover";
import { IGlobalProviderContext } from "../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { useMonthlyProgramViewModel } from "common/viewModels/MonthlyProgramViewModel";
import { IMonthlyProgrammeViewModel } from "models/Programme";
import Space from "components/common/Space";
import HeadlineNewsModal from "components/modals/HeadlineNewsModal/HeadlineNewsModal";

const Wapper = styled.div`
  display: flow-root;
  width: 100%;
  // max-width: 1280px;
  border-radius: 30px;
  margin: 30px auto;
`;

const Home = () => {
  const { currentUser, isRetriving, logout } = useContext(UserViewModelContext);
  const { t } = useTranslation("common");
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const navigate = useNavigate();
  const {
    getBannerList,
    getHeadlineNews,
    getLatestNews,
    getUpcomingBookingList,
    headlineNews,
    latestNews,
    bannerList,
    upcomingBooking,
    coachLessonList,
    getCoachLessonList,
  } = useHomeInfoViewModel() as IHomeInfoViewModel;
  const { getEventList, eventList } = useEventViewModel() as IEventViewModel;
  // const { facilityList, getFacilityList, getFacilityDetail } =
  //   useDiscoverViewModel() as IDiscoverViewModel;
  const { getMonthlyProgramList, monthlyProgramList } =
    useMonthlyProgramViewModel() as IMonthlyProgrammeViewModel;

  useEffect(() => {
    getBannerList(globalDispatch);
    getHeadlineNews();
    getLatestNews();
    getEventList(globalDispatch);
    getMonthlyProgramList(4);
    getCoachLessonList();
    if (currentUser?._id) {
      getUpcomingBookingList();
    }
  }, [currentUser?._id]);

  // empty page for loading local auth status
  if (isRetriving === true) return <></>;

  return (
    <div className="max-w-[1280px] w-full flex-col m-auto">
      {/* Ref: https://github.com/femioladeji/react-slideshow/issues/222#issuecomment-1786331081 */}
      {bannerList.length > 0 && <Banner bannerList={bannerList} />}
      <Space size="30px" />
      <News latestNews={latestNews} />
      <Wapper>
        <UpcomingEvent eventList={eventList} />
      </Wapper>
      {/* <Wapper>
        <Top
          facilityList={facilityList}
          getFacilityDetail={getFacilityDetail}
          globalDispatch={globalDispatch}
          programmeList={monthlyProgramList.list}
          privateCoachList={coachLessonList}
        />
      </Wapper> */}
      {currentUser != null && upcomingBooking?.length > 0 && (
        <Upcoming bookingList={upcomingBooking} />
      )}
      <Adv />
      <HeadlineNewsModal headlineNews={headlineNews} />
    </div>
  );
};

export default Home;
