import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import cn from "classnames";
import saishaLogoPng from "assets/ic-shishaLogo.png";
import Form from "../../components/common/Form/Form";
import Button from "../../components/common/Button";
import { useLoginLoading } from "./LoginScreen";
import { loginMethod } from "./LoginHome";
import PhoneNumberInput from "components/common/PhoneNumberInput";
import LogoCard from "components/common/cards/LogoCard";
import ButtonGroup from "components/common/ButtonGroup";
import Space from "components/common/Space";
import Textfield from "components/common/Textfield";
import { useAuthViewModel } from "common/viewModels/User/AuthViewModel";
import { IAuthViewmodel } from "common/models/User";
import { CountryCode } from "libphonenumber-js";
import { appearGlobalError } from "common/context/requests/globalRequest";
import { IGlobalProviderContext } from "common/models/Common";
import { GlobalContextStore } from "common/context/providers/globalProvider";

const METHOD_OPTION = [
  {
    label: "login:forgetPwMethod.mobile",
    value: loginMethod.MOBILE,
  },
  {
    label: "login:forgetPwMethod.email",
    value: loginMethod.EMAIL,
  },
];

const ForgotPassword = () => {
  const { t } = useTranslation("login");
  const navigate = useNavigate();
  const { setIsLoading } = useLoginLoading();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );

  const { forgetPasswordWithEmail, forgetPasswordWithMobile } =
    useAuthViewModel() as IAuthViewmodel;
  const [searchParams, setSearchParams] = useSearchParams({
    method: loginMethod.MOBILE,
  });
  const method = searchParams.get("method");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [country, setCountry] = useState<string>("+852");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (method !== loginMethod.MOBILE && method !== loginMethod.EMAIL) {
      setSearchParams({ method: loginMethod.MOBILE });
    }
  }, [method]);

  const onSubmit = async () => {
    const result =
      method === loginMethod.MOBILE
        ? await forgetPasswordWithMobile({ phoneNumber, country })
        : await forgetPasswordWithEmail({ email });

    if (result?.success) {
      appearGlobalError(globalDispatch, t("login:resetPasswordEmailSent"));
      navigate(`../sign-in`);
    }
  };

  const _renderMobile = () => {
    return (
      <>
        <PhoneNumberInput
          phoneNum={phoneNumber}
          setPhoneNum={setPhoneNumber}
          region={country}
          setRegion={setCountry}
        />
        <Space size="30px" />
        <Button
          fullWidth
          disabled={!phoneNumber || !country}
          onClick={onSubmit}
        >
          {t("common:button:submit")}
        </Button>
      </>
    );
  };

  const _renderEmail = () => {
    return (
      <>
        <Textfield
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t("login:field.email")}
        />
        <Space size="30px" />
        <Button fullWidth disabled={!email} onClick={onSubmit}>
          {t("common:button:submit")}
        </Button>
      </>
    );
  };

  return (
    <LogoCard title={t("title.forgot_pw")}>
      <div className="w-full md:w-[300px]">
        <ButtonGroup
          options={METHOD_OPTION}
          selected={method as string}
          setSelected={(selected) => setSearchParams({ method: selected })}
        />
        <Space size="20px" />

        {method === loginMethod.MOBILE ? _renderMobile() : _renderEmail()}
      </div>
    </LogoCard>
  );
};

export default ForgotPassword;
