import Space from "components/common/Space";
import Textfield from "components/common/Textfield";
import LogoCard from "components/common/cards/LogoCard";
import { useTranslation } from "react-i18next";
import Styled from "./Styled";
import GeneralButton from "components/common/Button";
import { useCallback, useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { loginMethod } from "pages/LoginScreen/LoginHome";
import Form from "components/common/Form";
import { InferType, object, string } from "yup";
import { FormikHelpers } from "formik";
import { useAuthViewModel } from "common/viewModels/User/AuthViewModel";
import { IAuthViewmodel } from "common/models/User";

const LoginFormYupSchema = object({
  email: string().required(),
  password: string().required(),
});

type LoginForm = InferType<typeof LoginFormYupSchema>;

const EmailLogin = () => {
  const { t } = useTranslation("login");
  const navigate = useNavigate();
  const { loginWithEmail } = useAuthViewModel() as IAuthViewmodel;

  const initialFormState = useMemo<LoginForm>(
    () => ({ email: "", password: "" }),
    []
  );

  const onForgotPasswordClick = useCallback(() => {
    navigate({
      pathname: "../forgot-password",
      search: `?${createSearchParams({ method: loginMethod.EMAIL })}`,
    });
  }, [navigate]);

  const onLoginFormSubmit = async (value: LoginForm) => {
    const { email, password } = value;
    await loginWithEmail({ email, password });
  };

  return (
    <Form
      initialValues={initialFormState}
      onSubmit={onLoginFormSubmit}
      validationSchema={LoginFormYupSchema}
    >
      {(formik) => (
        <LogoCard title={t("email_login_instruction")}>
          <div className="w-full md:w-[300px]">
            <Form.Element id="user_login_form">
              <Form.Field.TextField
                id="email"
                name="email"
                placeholder={t("login:field.email")}
              />
              <Space size="20px" />
              <Form.Field.TextField
                id="password"
                name="password"
                type="password"
                placeholder={t("login:field.password")}
              />
              <div className="flex justify-end mt-[10px] mb-[20px]">
                <Styled.LinkTextButton onClick={onForgotPasswordClick}>
                  {t("button.forgot_pw")}
                </Styled.LinkTextButton>
              </div>
              <GeneralButton
                fullWidth
                type="submit"
                disabled={
                  !formik.dirty || !formik.isValid || formik.isSubmitting
                }
              >
                {t("button.login")}
              </GeneralButton>
            </Form.Element>
          </div>
        </LogoCard>
      )}
    </Form>
  );
};

export default EmailLogin;
