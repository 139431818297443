import React, { useCallback } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ReactComponent as CopySvg } from "../../assets/ctrl-c.svg";
import Styled from "./Styled";
import { Tooltip } from "@mui/material";

type Props = {
  refNo: string;
};

const BookingDetailMainInfoCard = (props: Props) => {
  const { refNo } = props;

  const { t } = useTranslation("myBooking");
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = useCallback(() => {
    navigator.clipboard.writeText(refNo);
    enqueueSnackbar({ message: t("common:button.copy.success") });
  }, [refNo, enqueueSnackbar]);

  return (
    <Styled.SharedCard
      className={cn("w-full p-[22px]", "lg:px-[37px] lg:py-[15px]")}
    >
      <div className="w-full flex items-center">
        <Styled.ReferenceText>{`${t(
          "detail.refNo"
        )} ${refNo}`}</Styled.ReferenceText>
        <div className="w-[10px]" />
        <Tooltip title="Copy">
          <button type="button" onClick={copyToClipBoard}>
            <CopySvg />
          </button>
        </Tooltip>
      </div>
    </Styled.SharedCard>
  );
};

export default BookingDetailMainInfoCard;
