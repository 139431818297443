import React from "react";
import { CircularProgress } from "@mui/material";
import theme from "utils/theme";

const TempLoading = () => {
  return (
    <CircularProgress size={88} sx={{ color: theme.orange }} thickness={3} />
  );
};

export default TempLoading;
