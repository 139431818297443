import i18n from "i18n";

export class FacilityCategory {
  // Pending: Thumbnail Image
  constructor({ _id, name_en, name_tc, facility_list, thumbnail_photo_ref }) {
    console.log("Facility Category: ", _id, name_en, name_tc, facility_list);
    this._id = _id;
    this.name_en = name_en;
    this.name_tc = name_tc;
    this.facility_list = facility_list;
    this.thumbnail_photo_ref = thumbnail_photo_ref;
  }

  getName() {
    return i18n.language === "en" ? this.name_en : this.name_tc;
  }
}
