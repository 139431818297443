import { useState } from "react";
import {
  getSportpass as getSportpassAPI,
  buySportpass as buySportpassAPI,
  getSportpassHistory as getSportpassHistoryAPI,
} from "../network/sportpass";
import { appearGlobalError, appearGlobalLoading, disappearGlobalLoading } from "../context/requests/globalRequest";
import config from "../../config";
import { getDateBetween, getDateIsBefore } from "../utils/DateHelper";
import { listAvailableVoucher, listBookingVoucher, listUnavailableVoucher, redeemAdmissionPass as redeemAdmissionPassApi } from "../network/voucher";
import { ProgramTypes } from "./MyBooking/MyBookingListViewModel";
import { TimeslotType } from "./FacilityBooking/FacilityBookingDetailViewModel";

export const VoucherType = config.HIDE_SPORT_PASS ? {
  CASH: 'cash',
  FACILITY: 'facility',
  COACH: 'coach',
  EVENT: 'event'
} : {
  CASH: 'cash',
  FACILITY: 'facility',
  COACH: 'coach',
  DAY_PASS: 'day',
  MONTHLY_PASS: 'monthly',
  ANNUAL_PASS: 'annual',
  ADMISSION: 'admission',
  EVENT: 'event'
}

export const VoucherFilterOption = config.HIDE_SPORT_PASS ? {
  CASH: 'cash',
  FACILITY: 'facility',
  COACH: 'coach',
  EVENT: 'event'
} : {
  CASH: 'cash',
  FACILITY: 'facility',
  COACH: 'coach',
  DAY_PASS: 'day',
  // MONTHLY_PASS: 'monthly',
  ANNUAL_PASS: 'annual',
  ADMISSION: 'admission',
  EVENT: 'event'
}

export const VoucherCategory = config.HIDE_SPORT_PASS && config.HIDE_BUNDLE ? {
  ALL : 'all',
  FACILITY : 'facility',
  COACH : 'coach',
  PROGRAMME : 'programme',
  EVENT : 'event',
} : config.HIDE_BUNDLE ? {
  ALL : 'all',
  FACILITY : 'facility',
  COACH : 'coach',
  PROGRAMME : 'programme',
  EVENT : 'event',
  SPORT_PASS : 'sport_pass',
} : config.HIDE_SPORT_PASS ? {
  ALL : 'all',
  FACILITY : 'facility',
  COACH : 'coach',
  PROGRAMME : 'programme',
  EVENT : 'event',
  PACKAGE : 'package',
} : {
  ALL : 'all',
  FACILITY : 'facility',
  COACH : 'coach',
  PROGRAMME : 'programme',
  EVENT : 'event',
  SPORT_PASS : 'sport_pass',
  PACKAGE : 'package',
}

const pageSize = 8


export const useVoucherViewModel = ({ dispatch }) => {
  const [availableVouchers, setAvailableVouchers] = useState([])
  const [unavailableVouchers, setUnavailableVouchers] = useState([])
  const [bookingVouchers, setBookingVouchers] = useState([])

  const getAvailableVouchers = async ({page = null, type, hideLoading}) => {
    try {
      if (!hideLoading) appearGlobalLoading(dispatch)
      let payload = {}
      if (page != null && page >= 0) {
        payload.limit = pageSize
        payload.skip = pageSize * page
      }
      if (type) payload.type = type
      const res = await listAvailableVoucher(payload)
      if (!hideLoading) disappearGlobalLoading(dispatch)
      if (res?.success && res?.result) {
        if (page != null && page > 0)
          setAvailableVouchers([...availableVouchers, ...res.result])
        else
          setAvailableVouchers(res.result);
        return res.result?.length >= pageSize
      } else {
        throw new Error("Unknown exception. getAvailableVouchers success")
      }
    } catch (e) {
      if (!hideLoading) disappearGlobalLoading(dispatch)
      appearGlobalError(dispatch, e?.response?.data?.msg);
      return false;
    }
  }

  const getExpiredVouchers = async ({page = null, type, hideLoading}) => {
    try {
      if (!hideLoading) appearGlobalLoading(dispatch)
      let payload = {}
      if (page != null && page >= 0) {
        payload.limit = pageSize
        payload.skip = pageSize * page
      }
      if(type) payload.type = type
      const res = await listUnavailableVoucher(payload)
      if (!hideLoading) disappearGlobalLoading(dispatch)
      if (res?.success && res?.result) {
        if (page != null && page > 0)
          setUnavailableVouchers(unavailableVouchers=>[...unavailableVouchers, ...res.result])
        else
          setUnavailableVouchers(res.result);
        return res.result?.length >= pageSize
      } else {
        throw new Error("Unknown exception. getExpiredVouchers success")
      }
    } catch (e) {
      if (!hideLoading) disappearGlobalLoading(dispatch)
      appearGlobalError(dispatch, e?.response?.data?.msg);
      return false;
    }
  }

  const getBookingVouchers = async ({bookingType, category, coachlesson, coachType, timeslotType, allowCash}) => {
    try {
      appearGlobalLoading(dispatch)
      let types = [{type: 'cash', category: 'all'}]
      if (bookingType === ProgramTypes.Event) {
        types = types.concat([{type: 'cash', category: 'event'}])
        if (category) types = types.concat([{type: 'event', eventType: category}])
      } else if (bookingType === ProgramTypes.Facility) {
        let filters = []
        if (allowCash) filters.push({type: 'cash', category: 'facility'})
        if (timeslotType.includes(TimeslotType.PEAK)) filters.push({type: 'facility', category, peakHour: true})
        if (timeslotType.includes(TimeslotType.NORMAL)) filters.push({type: 'facility', category, peakHour: false})
        types = types.concat(filters)
      } else if (bookingType === ProgramTypes.Program) {
        types = types.concat([{type: 'cash', category: 'programme'}])
      } else if (bookingType === ProgramTypes.Coach) {
        let privacy = 'group'
        switch(coachType) {
          case '1to1':
            privacy = 'private'
            break
          case '1to2':
            privacy = 'semi'
        }
        let filters = [{type: 'coach', coachlesson, privacy}]
        if (allowCash) filters.push({type: 'cash', category: 'coach'})
        types = types.concat(filters)
      } else if ([VoucherType.DAY_PASS, VoucherType.MONTHLY_PASS, VoucherType.ANNUAL_PASS].includes(bookingType)) {
        types = [{type: bookingType}]
      }
      // else if (bookingType === 'package') {
      //   types = types.concat([{type: 'cash', category: 'package'}])
      // }
      const res = await listBookingVoucher({types})
      disappearGlobalLoading(dispatch)
      if (res?.success && res?.result) {
        setBookingVouchers(res.result);
      } else {
        throw new Error("Unknown exception. getBookingVouchers success")
      }
    } catch (e) {
      disappearGlobalLoading(dispatch)
      appearGlobalError(dispatch, e?.response?.data?.msg);
      return false;
    }
  };

  const redeemAdmissionPass = async ({voucherId}) => {
    console.log('redeem ',voucherId)
    try {
      appearGlobalLoading(dispatch)
      const res = await redeemAdmissionPassApi({voucherId})
      disappearGlobalLoading(dispatch)
      if (res?.success) {
        return res
      } else {
        throw new Error("Unknown exception. redeemAdmissionPass success")
      }
    } catch (e) {
      disappearGlobalLoading(dispatch)
      appearGlobalError(dispatch, e?.response?.data?.msg);
      return false;
    }
  }
  

  return {
    bookingVouchers,
    availableVouchers,
    unavailableVouchers,
    getBookingVouchers,
    getAvailableVouchers,
    getExpiredVouchers,
    redeemAdmissionPass,
  };
};
