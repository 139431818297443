import { Switch, SwitchProps } from "@mui/material";
import theme from "utils/theme";
import Typography from "../Typography";

interface SwitchCheckboxProps extends SwitchProps {
  label: string;
}

const SwitchCheckbox = ({ label, onChange }: SwitchCheckboxProps) => {
  return (
    <div className="flex items-center">
      <Switch
        onChange={onChange}
        sx={{
          "&.MuiSwitch-root .MuiSwitch-switchBase": {
            color: "white",
          },

          "&.MuiSwitch-root .Mui-checked": {
            color: "white",
          },

          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              transform: "translateX(16px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                backgroundColor: theme.orange,
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
          },
        }}
      />
      <Typography>{label}</Typography>
    </div>
  );
};

export default SwitchCheckbox;
