import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import theme from "utils/theme";

const BackButton = styled.button`
  color: ${theme.orange};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  text-decoration-line: underline;
`;

type Props = {
  text: string;
  onClick: () => void;
};

const PageBackButton = (props: Props) => {
  const { text, onClick } = props;
  const navigate = useNavigate();
  const back = useCallback(() => {
    if (onClick != null) {
      onClick();
    } else if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    }
  }, [navigate, onClick]);

  return (
    <BackButton type="button" onClick={back}>
      {`< ${text}`}
    </BackButton>
  );
};

export default PageBackButton;
