import React, { useContext } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { ROUTE_BASE } from "../../Route";
import FacilityScreen from "./FacilityScreen";
import FacilityCategoryPage from "./FacilityCategoryPage";
import FacilityDetailPage from "./FacilityDetailPage";
import FacilityBookingPage from "./FacilityBookingPage";
import { IUserViewModelContext } from "../../@types/common";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import useAuthUser from "hooks/useAuthUser";
import FacilityListPage from "./FacilityListPage";
import { getCorrectUrl } from "navigators/Router";
import FacilityBayPage from "./FacilityBayPage";
import FacilityDetailProvider from "./FacilityDetailProvider";

const FacilityRouter: React.FC = () => {
  const { isGuest, isRetriving } = useAuthUser();
  console.log("🚀 ~ isRetriving:", isRetriving);
  return useRoutes([
    {
      path: "/",
      element: <FacilityScreen />,
      children: isRetriving
        ? []
        : [
            {
              path: "/",
              element: <FacilityListPage />,
            },
            {
              path: "/category/:facilityId",
              element: <FacilityCategoryPage />,
            },
            {
              path: "/:facilityId/timeslot",
              element: (
                <FacilityDetailProvider>
                  <FacilityDetailPage />,
                </FacilityDetailProvider>
              ),
            },
            {
              path: "/venue/:venueId/booking",
              element: !isGuest ? (
                <FacilityBookingPage />
              ) : (
                <Navigate to={getCorrectUrl(ROUTE_BASE.AUTH + "/sign-in")} />
              ),
            },

            {
              path: "*",
              element: <Navigate to={`../`} />,
            },
          ],
    },
  ]);
};

export default FacilityRouter;
