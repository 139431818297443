import ReactPaginate from "react-paginate";
import React, { ReactElement, useEffect, useState } from "react";
import Styled from "./Styled";
import { WindowSize } from "utils/responsive";
import Space from "../Space";
import _ from "lodash";
import Typography from "../Typography";
import { useTranslation } from "react-i18next";

type PaginationProps = {
  data: any;
  itemContainer: (data: any) => React.ReactNode;
  itemPerPage: number;
};

const Pagination = ({ data, itemContainer, itemPerPage }: PaginationProps) => {
  const { t } = useTranslation("common");
  const [page, setPage] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>([]);
  const isMobile = WindowSize() === "mobile";

  useEffect(() => {
    setFilterData(
      data.filter((item: any, index: number) => {
        return index >= page * itemPerPage && index < (page + 1) * itemPerPage;
      })
    );
  }, [page, data]);

  useEffect(() => {
    setPage(0);
  }, [data]);

  return (
    <>
      {_.isEmpty(filterData) ? (
        <Typography>{t("common:comingSoon")}</Typography>
      ) : (
        itemContainer(filterData)
      )}

      <Space size="20px" />

      <Styled.Paginate
        activeClassName={"active"}
        forcePage={page}
        onPageChange={(event) => setPage(event.selected)}
        breakLabel="..."
        pageCount={Math.ceil(data?.length / itemPerPage)}
        previousLabel={"<"}
        nextLabel={">"}
        pageRangeDisplayed={isMobile ? 1 : 3}
        marginPagesDisplayed={isMobile ? 1 : 3}
      />
    </>
  );
};

export default Pagination;
