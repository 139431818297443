import i18n from "i18n";
import { Coach } from "./Coach";
import { Facility } from "../Facility";

export class CoachLesson {
  constructor({
    _id,
    name_en,
    name_zh,
    coach_id,
    desc_en,
    desc_zh,
    facility_id,
    created_time,
    created_by,
    updated_time,
    updated_by,
  }) {
    this._id = _id;
    this.name_en = name_en;
    this.name_zh = name_zh;
    this.coach = coach_id ? new Coach(coach_id) : null;
    this.desc_en = desc_en;
    this.desc_zh = desc_zh;
    this.facility = facility_id ? new Facility(facility_id) : null;
    this.created_time = created_time;
    this.created_by = created_by;
    this.updated_time = updated_time;
    this.updated_by = updated_by;

    this.getName = this.getName.bind(this);
    this.getDescription = this.getDescription.bind(this);
  }

  getName() {
    return i18n.language === "en" ? this.coach?.coach_name_en : this.coach?.coach_name_zh;
  }

  getDescription() {
    return i18n.language === "en" ? this.desc_en : this.desc_zh;
  }
}
