import cn from "classnames";
import { getLocalisedString } from "common/utils/stringHelper";
import EnquiryNav from "components/EnquiryNav/EnquiryNav";
import config from "../config";

export const ChatbotBody = () => {
  return (
    <div className="h-full flex-1 rounded-[20px] md:pt-[18.5px] md:pb-[80px] md:bg-[#F0FAFF] md:px-3 md:overflow-hidden">
      <div
        className={cn(
          "w-full flex justify-center h-full overflow-y-auto",
          "p-[15px_15px_100px] md:px-0 md:pb-10 md:m-0"
        )}
      >
        <iframe
          src={getLocalisedString(
            `${config.CHATBOT_ROOT}/en/bot/goparksports`,
            `${config.CHATBOT_ROOT}/zh-hk/bot/goparksports`
          )}
          height={window.innerHeight - 116.5}
          width="100%"
        ></iframe>
      </div>
    </div>
  )
}

const Chatbot = () => {
  return (
    <div className={cn("flex gap-[60px]", "md:px-[50px] md:pt-[70px]")}>
      <div className="hidden md:block w-[280px] flex-shrink-0">
        <EnquiryNav />
      </div>
      <ChatbotBody />
    </div>
  );
};

export default Chatbot;
