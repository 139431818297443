import dayjs from "common/utils/dayjs";
import React, { useMemo } from "react";
import cn from "classnames";
import Styled from "./Styled";
import { ReactComponent as GreenTickSvg } from "../../../assets/green-tick.svg";
import crossIcon from "../../../assets/ic-cross.png";
import { ProgrammeLessonAttendanceStatus } from "common/models/MonthlyProgram";
import { useTranslation } from "react-i18next";
import { Venue } from "common/models/Venue";
import { ProgramLessonSickLeave } from "models/Programme";
import { ReactComponent as DocumentSvg } from "../../../assets/document.svg";
import _ from "lodash";
import { getDayOfWeekSlug } from "common/utils/DateHelper";

export type LessonAttendanceProps = {
  date: string;
  startTime: string;
  endTime: string;
  venues?: Venue[];
  status: string;
  attendanceStatus?: ProgrammeLessonAttendanceStatus;
  customStatusDisplay?: {
    [S in ProgrammeLessonAttendanceStatus]?: React.ReactNode;
  };
  sickLeave?: ProgramLessonSickLeave;
  onRequestSickLeave?: () => void;
};

const LessonAttendanceItem = (
  props: LessonAttendanceProps & React.HTMLAttributes<HTMLDivElement>
) => {
  // eslint-disable-next-line react/prop-types
  const {
    date,
    startTime,
    endTime,
    venues,
    status,
    attendanceStatus,
    className,
    sickLeave,
    onRequestSickLeave,
    customStatusDisplay,
  } = props;
  const { t } = useTranslation("myBooking");

  const dateInFormat = useMemo(() => {
    const dayOfWeekSlug = getDayOfWeekSlug(date);

    return `${t(`common:dateFormat.isoWeekday.${dayOfWeekSlug}`)} ${dayjs(
      date
    ).tz().format("DD/MM/YYYY")}`;
  }, [t, date]);

  const startTimeInFormat = useMemo(
    () => dayjs(startTime).tz().format("HH:mm"),
    [startTime]
  );
  const endTimeInFormat = useMemo(
    () => dayjs(endTime).tz().format("HH:mm"),
    [endTime]
  );

  const shouldShowSlRequest =
    onRequestSickLeave &&
    (attendanceStatus === ProgrammeLessonAttendanceStatus.SickLeavePending ||
      attendanceStatus === ProgrammeLessonAttendanceStatus.Pending);

  const defaultStatusDisplay = {
    [ProgrammeLessonAttendanceStatus.Present]: (
      <>
        {t("detail.checkedIn")}
        <GreenTickSvg />
      </>
    ),
    [ProgrammeLessonAttendanceStatus.Absent]: (
      <div className="flex items-center gap-1">
        <div>{t("detail.checkedIn")}</div>
        <img src={crossIcon} className="h-[12px]" />
      </div>
    ),
    [ProgrammeLessonAttendanceStatus.SickLeavePending]:
      !sickLeave?.documentLink ? (
        <div className="text-[#EB4309]">
          {t("detail.sickLeave.uploadRequired")}
        </div>
      ) : (
        <>{t("detail.sickLeave.uploadSucceed")}</>
      ),
    [ProgrammeLessonAttendanceStatus.SickLeave]: (
      <div>{t("detail.sickLeave.approved")}</div>
    ),
    [ProgrammeLessonAttendanceStatus.Pending]: (
      <div>{t("detail.checkedIn")}</div>
    ),
  };

  return (
    <div className={cn("w-full flex flex-col", className)}>
      <div className="flex justify-between gap-2">
        <Styled.ClassItemsText1>{`${dateInFormat}, ${startTimeInFormat} - ${endTimeInFormat} ${venues && venues.length > 0
            ? `, ${venues?.map((v) => v.name_en)}`
            : ""
          }`}</Styled.ClassItemsText1>
        {status !== "CANCELLED" && shouldShowSlRequest && (
          <Styled.RequestSickLeaveButton onClick={onRequestSickLeave}>
            {t("detail.sickLeave.requestTitle")}
          </Styled.RequestSickLeaveButton>
        )}
      </div>
      <Styled.ClassItemsText2>
        <div className="flex flex-col gap-[6px]">
          {status === "CANCELLED"
            ? t("detail.cancelled")
            : attendanceStatus &&
            (_.get(customStatusDisplay, attendanceStatus) ??
              defaultStatusDisplay[attendanceStatus])}
        </div>
      </Styled.ClassItemsText2>
    </div>
  );
};

export default LessonAttendanceItem;
