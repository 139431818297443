import { useTranslation } from "react-i18next";
import Styled from "./Styled";
import cn from "classnames";
import theme from "utils/theme";

interface AttendessProps {
  quantity: number;
  maxQuota?: number;
  remainingQuota?: number;
  handleAdd: () => void;
  handleSub: () => void;
}

const AttendessSelect = ({
  quantity,
  handleAdd,
  handleSub,
  maxQuota,
  remainingQuota,
}: AttendessProps) => {
  const { t } = useTranslation("booking");
  return (
    <Styled.AttendeesboxWrapper>
      <Styled.Attendeesbox>
        <Styled.AttendeesItem>
          {t("bookingDetail.numOfAttendees")}
        </Styled.AttendeesItem>
        <div className="max-md:w-full">
          <Styled.NumberField>
            <Styled.NumberButton onClick={handleSub}>-</Styled.NumberButton>
            {quantity}
            <Styled.NumberButton onClick={handleAdd}>+</Styled.NumberButton>
          </Styled.NumberField>
          {maxQuota && (
            <div className="text-xs text-center text-red-500 mt-1">
              {t("bookingDetail.maxQuota", { quota: maxQuota })}
            </div>
          )}
        </div>
      </Styled.Attendeesbox>
      {remainingQuota && (
        <div
          className={cn(
            "text-xs",
            `text-[${theme.orange}]`,
            "mt-2",
            "max-md:text-center"
          )}
        >
          {t("bookingDetail.remainingQuota", { quota: remainingQuota })}
        </div>
      )}
      {maxQuota && (
        <div
          className={cn(
            "text-xs",
            `text-[${theme.orange}]`,
            "mt-2",
            "max-md:text-center"
          )}
        >
          {t("bookingDetail.numOfAttendeesRemark")}
        </div>
      )}
    </Styled.AttendeesboxWrapper>
  );
};

export default AttendessSelect;
