import { Voucher } from "models";
import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import Typography from "components/common/Typography";
import theme from "utils/theme";
import { getVoucherName } from "utils/voucher";
import { IPromoCode } from "common/models/PromoCode";

type Props = {
  appliedVoucher: Voucher[];
  promoCode: IPromoCode | null;
};

const ReceiptDiscount = (props: Props) => {
  const { promoCode, appliedVoucher } = props;
  const { t } = useTranslation();
  return (
    <div className="container">
      {promoCode == null ? null : (
        <div className="">
          <Typography color={theme.black} fontWeight="600">
            {t("booking:bookingDetail.promoCodeApplied")}
          </Typography>
          <div className="h-[5px]" />
          <div
            className={cn(
              "w-full flex justify-between gap-[5px] flex-wrap",
              "lg:gap-2"
            )}
          >
            <span>{`${promoCode.code} (${promoCode.discount})`}</span>
            <span className="text-[#039f54]">{`-HK$${promoCode.enjoyedDiscount}`}</span>
          </div>
        </div>
      )}
      {promoCode != null || appliedVoucher.length > 0 ? (
        <div className="h-[20px]" />
      ) : null}
      {appliedVoucher.length <= 0 ? null : (
        <div>
          <Typography color={theme.black} fontWeight="600">
            {t("booking:bookingDetail.voucherApplied")}
          </Typography>
          <div className="h-[5px]" />
          {appliedVoucher.map((each) => {
            return (
              <div
                key={each.voucherId}
                className={cn(
                  "w-full flex justify-between gap-[5px] flex-wrap",
                  "lg:gap-2"
                )}
              >
                <span>{`${getVoucherName(each)} (-HK$${each.voucher?.value ?? 0})`}</span>
                <span className="text-[#039f54]">{`-HK$${each.voucher?.value ?? 0}`}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ReceiptDiscount;
