import { useVoucherNavigation } from "hooks/useVoucherNavigation";
import { BookingInfo } from "models/PrivateCoach";
import { useState } from "react";
import { Outlet } from "react-router-dom";

const CoachingScreen = () => {
  const [bookingInfo, setBookingInfo] = useState<BookingInfo>();
  const { linkedVoucherId, setLinkedVoucherId } = useVoucherNavigation();

  return (
    <main className="pb-[100px]">
      <Outlet
        context={{
          bookingInfo,
          setBookingInfo,
          linkedVoucherId,
          setLinkedVoucherId,
        }}
      />
    </main>
  );
};

export default CoachingScreen;
