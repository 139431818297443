import { useState } from "react";
import { useCommonFetcher } from "./Common/CommonViewModel";
import {
    getHeadlineNews as getHeadlineNewsAPI,
    getHeadlineNewsById,
  } from "../network/home";
import { HeadlineNews } from "common/models/HeadlineNew";

export const useHeadlineNewsViewModel = () => {
    const { fetcher } = useCommonFetcher();
    const [headlineNews, setHeadlineNews] = useState<HeadlineNews[]>([]);
    const [headlineNewsDetail, setHeadlineNewsDetail] = useState<HeadlineNews>();

    const getHeadlineNews = async (limit = 10) => {
        const res = await fetcher({api: () => getHeadlineNewsAPI({ limit }), skipErrorHandle: true});
        if(res?.success){
          setHeadlineNews(res?.result);
        }
        return res;
    };

    const getHeadlineNewsDetail = async (id: string) => {
        const res = await fetcher({api: () => getHeadlineNewsById({ id })});
        if(res?.success){
            setHeadlineNewsDetail(res?.result);
        }
        return res;
    }

    return {
        headlineNews,
        headlineNewsDetail,
        getHeadlineNews,
        getHeadlineNewsDetail,
    }
}