import styled from "styled-components";
import { breakpoint } from "utils/responsive";

const Container = styled.div`
  width: 60%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;

  @media ${breakpoint.mobile} {
    width: 100%;
    height: 80vh;
  }
`;

export default {
  Container,
};
