import Button from "../../components/common/Button";
import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import theme from "utils/theme";

const SportPassItemBox = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const SportPassBox = styled.div<{ bg?: string }>`
  width: 361px;
  height: 165px;
  border-radius: 20px;
  background-image: url(${(p) => p.bg});
  background-size: cover;
  color: #fff;
  padding: 20px 30px;
  margin-bottom: 30px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .bottom {
    position: absolute;
    bottom: 20px;
    left: 30px;
    padding: 0px 15px;
    height: 26px;
    border-radius: 16px;
    background: #fff;
    color: #000;
    display: inline-block;
    font-size: 12.5px;
    line-height: 25px;
  }
  .expired {
    position: absolute;
    right: -50px;
    height: 60px;
    line-height: 40px;
    bottom: -15px;
    background: #000;
    display: inline-block;
    padding: 0px 50px;
    transform: rotate(-35deg);
    font-size: 14px;
  }
  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

const SportPassBoxRight = styled.div`
  /* width: %; */
  display: flex;
  align-items: center;
  Button {
    margin-bottom: 25px;
    margin-left: 30px;
  }
  @media (max-width: 991px) {
    width: 100%;
    Button {
      margin-left: 0px;
      margin-bottom: 50px;
      width: 100%;
    }
  }
`;

const VerificationButton = styled(Button)`
  && {
    background-color: ${theme.white};
    border: 1px solid ${theme.flowerRed};
    color: ${theme.flowerRed};
    border-radius: 30px;
    width: 100%;
    margin-bottom: 20px;

    &:hover {
      background-color: ${theme.white};
    }
  }
`;

const EditProfileButton = styled(Button)`
  && {
    background-color: ${theme.blue};
    border-radius: 30px;
    width: 100%;
    margin-bottom: 20px;
  }
`;

export default {
  SportPassItemBox,
  SportPassBox,
  SportPassBoxRight,
  VerificationButton,
  EditProfileButton,
};
