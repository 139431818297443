import { Divider } from "@mui/material";
import { ROUTE_BASE } from "Route";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import CommonRoundedCornerCard from "../../components/common/CommonRoundedCornerCard";
import Space from "../../components/common/Space";
import Typography from "../../components/common/Typography";
import theme from "../../utils/theme";

interface MenuList {
  readonly title: string;
  readonly path: string;
}

const Container = styled.div`
  min-width: 280px;
  max-width: 100%;
  height: auto;
  padding: 40px;
  padding-bottom: 50px;
`;

const MenuButton = styled(Typography) <{ selected?: boolean }>`
  && {
    cursor: pointer;
    color: ${theme.black};
    font-size: 16px;
    &:hover {
      color: ${theme.lightOrange};
    }
    ${(props) => props.selected && `color: ${theme.lightOrange}`}
  }
`;

const NotificationNav = () => {
  const { t, i18n } = useTranslation("notification");
  const location = useLocation();
  console.log(location.pathname);
  const MENU_LIST: MenuList[] = [
    { title: "aiChatbot", path: "chatbot" },
    { title: "general", path: "general" },
    { title: "message", path: "message" },
  ];

  return (
    <CommonRoundedCornerCard>
      <Container>
        <Typography
          fontSize="20px"
          color={theme.black}
          className="text-center md:text-start pb-4"
        >
          {t("title.notification")}
        </Typography>
        <Divider style={{ width: "100%" }} />
        <Space size="20px" />

        <div className="w-full text-center md:text-start">
          {MENU_LIST.map((item, idx) => (
            <MenuButton
              key={item.title}
              selected={location.pathname.match(item.path) !== null}
            >
              <Link to={item.path}>{t(`title.${item.title}`)}</Link>
              {MENU_LIST.length - 1 !== idx && <Space size="30px" />}
            </MenuButton>
          ))}
        </div>
      </Container>
    </CommonRoundedCornerCard>
  );
};

export default NotificationNav;
