import { createContext, useContext, useRef, useState } from "react";
import {
  login as loginAPI,
  logout as logoutAPI,
  register as registerAPI,
  forgetPassword as forgetPasswordAPI,
  resetPassword as resetPasswordAPI,
  kioskEmailLogin as kioskLoginAPI,
  kioskOtpLogin as kioskOtpLoginAPI,
  requestKioskOtp,
  kioskOtpLogin,
  validateSession,
  validateSessionWithToken,
  fetchRefreshToken,
} from "../network/auth";
import { useEffect } from "react";
import {
  getDefaults,
  resetDefaults,
  saveDefaults,
  userDefaultKeys,
} from "../../utils/UserDefaultHelper";
import {
  getUser as getUserAPI,
  getUserWithToken as getUserWithTokenAPI,
} from "../network/user";
import { navToLogin, routeCodes } from "../../navigators/Router";
import { listBooking as listBookingAPI } from "../network/booking";
import { authedInstance } from "../network";
import {
  appearAccountVerification,
  appearGlobalError,
  appearGlobalLoading,
  disappearAccountVerification,
  disappearGlobalLoading,
  pushGlobalBlocking,
  popGlobalBlocking,
  appearPhoneNumberVerification,
} from "../context/requests/globalRequest";
import dayjs from "../utils/dayjs";
import { getDateIsBefore } from "../utils/DateHelper";
import { setDefaults, useTranslation } from "react-i18next";
import config from "../../config";
import { unregisterDeviceToken } from "../network/notification";
import { GlobalContextStore } from "../context/providers/globalProvider";
import { getLocalisedString } from "common/utils/stringHelper";
import _ from "lodash";
import { useCommonFetcher } from "./Common/CommonViewModel";

export const UserViewModelContext = createContext();

export const UserViewModelProvider = ({ children, onLogout }) => {
  const { t } = useTranslation("login");
  const [currentUser, setCurrentUser] = useState(null);
  const [cachedUsers, setCachedUsers] = useState([]);
  const [biometricList, setBiometricList] = useState([]);
  const [isRetriving, setIsRetriving] = useState(true);
  const [userListLoading, setIsUserListLoading] = useState(true);
  const [currentUserLoading, setIsCurrentUserLoading] = useState(true);
  const [myBookings, setMyBookings] = useState([]);
  const [isCurrentPassExpired, setIsCurrentPassExpired] = useState(true);
  const [isSwitchingUser, setIsSwitchingUser] = useState(false);
  const [showBiometricNotice, setShowBiometricNotice] = useState(false);
  const [showBiometricResult, setShowBiometricResult] = useState(false);
  const [biometricRegisterTitle, setBiometricRegisterTitle] = useState(null);
  const [biometricRegisterMsg, setBiometricRegisterMsg] = useState(null);
  const tmpCred = useRef(null);
  const [sessionCountdown, setSessionCountdown] = useState(-1);
  const sessionCountdownTimer = useRef();
  const prevUser = useRef(null);
  const [switching, setSwitching] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [userInitValidated, setUserInitValidated] = useState(false);

  const { globalDispatch } = useContext(GlobalContextStore);
  const { fetcher } = useCommonFetcher();

  useEffect(() => {
    getDefaults(userDefaultKeys.BIOMETRIC_LIST).then((list) => {
      setBiometricList(list ?? []);
    });
    getDefaults(userDefaultKeys.CURRENT_USER).then((user) => {
      console.log("retrived user from local storage ", user);
      if (user) {
        authedInstance.defaults.headers.Authorization =
          "Bearer " + user?.access_token;
        setCurrentUser(user);
      } else {
        setIsCurrentUserLoading(false);
      }
      getDefaults(userDefaultKeys.CACHED_USERS)
        .then((userList) => {
          setCachedUsers(userList?.filter((item) => item) ?? []);
        })
        .finally(() => setIsUserListLoading(false));
    });
  }, []);

  useEffect(() => {
    console.log("biometricList: ", biometricList);
  }, [biometricList]);

  useEffect(() => {
    console.log("cached users: ", cachedUsers);
  }, [cachedUsers]);

  useEffect(() => {
    console.log("useEffect current user: ", currentUser);
    checkIsSportPassExpired();
    if (config.IS_KIOSK) {
      console.log("user changed ", prevUser.current, currentUser);
      if (prevUser.current == null && currentUser) {
        //on login
        setSessionTimer(true);
      } else if (currentUser == null) {
        //after logout
        if (sessionCountdownTimer.current)
          clearInterval(sessionCountdownTimer.current);
        setSessionCountdown(0);
      }
    }

    if (prevUser.current?.email != currentUser?.email) {
      console.log(
        `switched user from ${prevUser.current?.email} to ${currentUser?.email}`
      );
    }

    // if (
    //   currentUser &&
    //   prevUser.current &&
    //   currentUser?.memberId != prevUser.current?.memberId
    // ) {
    //   //switched from other acc
    //   //refreshCurrentUser (which called updateSavedUser) required prevUser.current to be up-to-date, for inherite access/refresh token from current user
    //   console.log("is switching from other acc");
    //   prevUser.current = currentUser;
    //   refreshCurrentUser(true);
    // }
    if (currentUser) {
      let index = biometricList.findIndex(
        (el) =>
          el?.email &&
          el.email?.toLowerCase() == currentUser?.email?.toLowerCase()
      );
      if (
        index >= 0 &&
        (biometricList[index].signed_profile_pic_ref !=
          currentUser.signed_profile_pic_ref ||
          biometricList[index].username != currentUser.username)
      ) {
        let newList = [...biometricList];
        newList[index].signed_profile_pic_ref =
          currentUser.signed_profile_pic_ref;
        newList[index].username = currentUser.username;
        newList[index].isHidden = false;
        saveDefaults(userDefaultKeys.BIOMETRIC_LIST, newList);
        setBiometricList(newList);
      }
    }

    prevUser.current = currentUser;
  }, [currentUser]);

  useEffect(() => {
    if (sessionCountdown == 0 && currentUser && config.IS_KIOSK) {
      if (sessionCountdownTimer.current)
        clearInterval(sessionCountdownTimer.current);
      logout(globalDispatch, t("session_expired"));
    }
  }, [sessionCountdown]);

  useEffect(() => {
    setIsRetriving(currentUserLoading && userListLoading);
  }, [currentUserLoading, userListLoading]);

  const pauseSessionCountdown = () => {
    if (sessionCountdownTimer.current)
      clearInterval(sessionCountdownTimer.current);
  };

  const resumeSessionCountdown = () => {
    if (config.IS_KIOSK) setSessionTimer();
  };

  const setSessionTimer = (reset = false) => {
    if (!config.IS_KIOSK) return;
    if (reset) setSessionCountdown(config.KIOSK_SESSION_TIMEOUT);
    if (sessionCountdownTimer.current)
      clearInterval(sessionCountdownTimer.current);
    sessionCountdownTimer.current = setInterval(() => {
      setSessionCountdown((time) => {
        return time > 0 ? time - 1 : 0;
      });
    }, 1000);
  };

  const updateSavedUser = async (user, remove = false) => {
    const defaultedUser = await getDefaults(userDefaultKeys.CURRENT_USER);
    const isDifferentUser = defaultedUser?.memberId != user?.memberId; //FIXME: what if user request hard edit the memberId at remote?
    const updatedUser =
      isDifferentUser || !user
        ? user
        : {
            access_token: defaultedUser?.access_token,
            refresh_token: defaultedUser?.refresh_token, //keep only the token, other info should be updated from user
            ...user,
          };

    const cachedUsers = (await getDefaults(userDefaultKeys.CACHED_USERS)) ?? [];
    console.log(
      "updateSavedUser check 1 ",
      cachedUsers,
      isDifferentUser,
      defaultedUser,
      updatedUser
    );
    if (
      cachedUsers.some(
        (savedUser) => savedUser.memberId == updatedUser?.memberId
      )
    ) {
      //cached user included
      if (!remove) {
        const newUserList = cachedUsers.map((savedUser) =>
          savedUser._id == user._id ? updatedUser : savedUser
        );
        await saveDefaults(userDefaultKeys.CACHED_USERS, newUserList);
        setCachedUsers(newUserList);
      }
    } else if (!remove) {
      //add new user
      if (updatedUser) {
        await saveDefaults(userDefaultKeys.CACHED_USERS, [
          ...cachedUsers,
          updatedUser,
        ]);
        setCachedUsers([...cachedUsers, updatedUser]);
      }
    }
    await saveDefaults(
      userDefaultKeys.CURRENT_USER,
      remove ? null : updatedUser
    );
    setCurrentUser(remove ? null : updatedUser);
    return updatedUser;
  };

  const guestLogin = async () => {
    authedInstance.defaults.headers.Authorization = undefined;
    await updateSavedUser(null);
  };

  // For biometric login only
  // App Login With Email / Mobile Migrated to Auth View Model
  const login = async ({
    dispatch,
    email,
    password,
    lang = "en",
    platform = "web",
    isUsingAuth = false,
  }) => {
    try {
      const payload = {
        accountType: "email",
        email,
        password,
        lang,
        platform, // "app" | "web" | "kiosk"
      };

      appearGlobalLoading(dispatch);
      const res = await loginAPI(payload);
      if (res.success) {
        const { access_token, refresh_token } = res;
        const userRes = await getUserWithTokenAPI(access_token);
        if (userRes.success && userRes.user) {
          if (
            biometricList.some(
              (el) => el.email?.toLowerCase() == email?.toLowerCase()
            )
          ) {
          } else {
            tmpCred.current = {
              email,
              ath: password,
              signed_profile_pic_ref: userRes.user?.signed_profile_pic_ref,
              username: userRes.user?.username,
            };
            setShowBiometricNotice(true);
          }
          const user = {
            ...userRes.user,
            access_token,
            refresh_token,
            sessionId: userRes.user._id,
          };
          await updateSavedUser(user);
          // checkUserAccountStatus(user);
          checkShouldDisplayWarningPrompt({
            user,
            checkIsNewUser: true,
            checkIsMobileVerified: true,
          });

          if (
            !!biometricList.find(
              (el) =>
                el?.email &&
                el.email?.toLowerCase() == email?.toLowerCase() &&
                el.allow
            )
          ) {
            updateBiometricSettingForUser(true, { ath: password }, user);
          }
          disappearGlobalLoading(dispatch);
          return { success: true };
        }
      }
    } catch (e) {
      console.log("Login Exception", e);
      let errorMsg = !_.isEmpty(e?.response?.data?.error?.localizedMessage)
        ? getLocalisedString(
            e?.response?.data?.error?.localizedMessage?.en,
            e?.response?.data?.error?.localizedMessage?.zh
          )
        : e?.response?.data?.msg;

      disappearGlobalLoading(dispatch);
      if (isUsingAuth && e?.response?.status == 400) {
        appearGlobalError(
          dispatch,
          `${errorMsg ? errorMsg + "\n" : ""}${t("removeBiometric")}`
        );
        removeBiometricSetting({ email });
      } else {
        appearGlobalError(dispatch, errorMsg);
      }
    }
    return { success: false };
  };

  const onAppLoginSuccess = async (loginRes) => {
    try {
      const { access_token, refresh_token, password } = loginRes;
      const userRes = await fetcher({
        api: () => getUserWithTokenAPI(access_token),
        skipLoading: true,
      });

      if (userRes?.success && userRes?.user) {
        const email = userRes?.user?.email;
        if (
          biometricList.some(
            (el) => el.email?.toLowerCase() == email?.toLowerCase()
          )
        ) {
        } else {
          tmpCred.current = {
            email,
            ath: password,
            signed_profile_pic_ref: userRes.user?.signed_profile_pic_ref,
            username: userRes.user?.username,
          };
          setShowBiometricNotice(true);
        }
        const user = {
          ...userRes.user,
          access_token,
          refresh_token,
          sessionId: userRes.user._id,
        };
        await updateSavedUser(user);
        // checkUserAccountStatus(user);
        checkShouldDisplayWarningPrompt({
          user,
          checkIsNewUser: true,
          checkIsMobileVerified: true,
        });

        if (
          !!biometricList.find(
            (el) =>
              el?.email &&
              el.email?.toLowerCase() == email?.toLowerCase() &&
              el.allow
          )
        ) {
          updateBiometricSettingForUser(true, { ath: password }, user);
        }
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (err) {
      // Non api returned error
      appearGlobalError(globalDispatch);
      return { success: false, err };
    }
  };

  const loginKiosk = async ({ dispatch, email, password }) => {
    try {
      appearGlobalLoading(dispatch);
      const res = await kioskLoginAPI(email, password);
      if (res.success) {
        const { access_token, refresh_token } = res;
        const userRes = await getUserWithTokenAPI(access_token);
        if (userRes.success && userRes.user) {
          const user = {
            ...userRes.user,
            access_token,
            refresh_token,
            sessionId: userRes.user._id,
          };
          await updateSavedUser(user);
          // checkUserAccountStatus(user);
          checkShouldDisplayWarningPrompt({
            user,
            checkIsNewUser: true,
            checkIsMobileVerified: true,
          });
          disappearGlobalLoading(dispatch);
          return { success: true };
        }
      }
    } catch (e) {
      console.log("Login Exception", e);
      disappearGlobalLoading(dispatch);
      appearGlobalError(dispatch, e?.response?.data?.msg);
    }
    return { success: false };
  };

  const loginKioskOtp = async ({ dispatch, otp }) => {
    try {
      appearGlobalLoading(dispatch);
      const res = await kioskOtpLogin(otp);
      if (res.success) {
        const { access_token, refresh_token } = res;
        const userRes = await getUserWithTokenAPI(access_token);
        if (userRes.success && userRes.user) {
          const user = {
            ...userRes.user,
            access_token,
            refresh_token,
            sessionId: userRes.user._id,
          };
          await updateSavedUser(user);
          // checkUserAccountStatus(user);
          checkShouldDisplayWarningPrompt({
            user,
            checkIsNewUser: true,
            checkIsMobileVerified: true,
          });
          disappearGlobalLoading(dispatch);
          return { success: true };
        }
      }
    } catch (e) {
      console.log("Login Exception", e);
      disappearGlobalLoading(dispatch);
      appearGlobalError(dispatch, e?.response?.data?.msg);
    }
    return { success: false };
  };

  const logout = async (dispatch, message = null, refreshFail = false) => {
    const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
    console.log("logout", currentUser);
    try {
      pushGlobalBlocking(dispatch, "logout");
      // current user fire logout won't show alert but continue to do the logout flow once to make sure the user was navigated to login screen
      if (message && currentUser)
        appearGlobalError(
          dispatch,
          message + (currentUser?.username ? ` (${currentUser?.username})` : "")
        );
      if (!refreshFail) {
        if (config.IS_APP) await unregisterDeviceToken();
        await logoutAPI(currentUser?.refresh_token);
      }
      // resetDefaults()
      // setCachedUsers([])
    } catch (e) {
      console.log("logout err ", e);
    } finally {
      const cachedUsers =
        (await getDefaults(userDefaultKeys.CACHED_USERS)) ?? [];
      // FIXME - above line of code still may have sync issue that the CACHED_USERS get updated in other function right after we get here
      // FIXME - use local db to avoid full list get and full list save. So that each item can be mutated(upsert/remove) seperately
      const newUserList = cachedUsers.filter(
        (savedUser) => savedUser._id != currentUser._id
      );
      await saveDefaults(userDefaultKeys.CACHED_USERS, newUserList);
      setCachedUsers(newUserList);

      await updateSavedUser(null, true);
      if (onLogout) onLogout();
      popGlobalBlocking(dispatch, "logout");
    }
  };

  const forgetPassword = async ({ email, dispatch }) => {
    try {
      const payload = {
        email,
        accountType: "email",
        recaptcha_token: "123",
      };
      appearGlobalLoading(dispatch);
      const res = await forgetPasswordAPI(payload);
      disappearGlobalLoading(dispatch);
      return res;
    } catch (e) {
      disappearGlobalLoading(dispatch);
      appearGlobalError(dispatch, e?.response?.data?.msg?.message);
      return { success: false };
    }
  };

  const resetPassword = async ({ password, token, dispatch }) => {
    try {
      const payload = {
        new_password: password,
        token: token,
        action: "set_app_pw",
      };
      appearGlobalLoading(dispatch);
      const res = await resetPasswordAPI(payload);
      disappearGlobalLoading(dispatch);
      return res;
    } catch (e) {
      disappearGlobalLoading(dispatch);
      appearGlobalError(dispatch, e?.response?.data?.msg);
      return { success: false };
    }
  };

  const checkIsSportPassExpired = () => {
    var isExpired = true;
    let expiryDate = dayjs(currentUser?.sportpass?.expiry_date);
    if (!!currentUser && !!expiryDate) {
      isExpired = !getDateIsBefore(dayjs(Date.now()), expiryDate);
    }
    setIsCurrentPassExpired(isExpired);
  };

  const getMyBookings = async (past = false, dispatch) => {
    try {
      if (!currentUser) return [];
      const res = await listBookingAPI({ past });
      return res.success;
    } catch (e) {
      appearGlobalError(dispatch, e?.response?.data?.msg);
      return false;
    }
  };

  // Set checkIsNewUser to true for user account status checking
  const refreshCurrentUser = async (props) => {
    try {
      const {
        checkIsNewUser = false,
        checkIsMobileVerified = false,
        handleNewUserNavigation = null,
      } = props ?? {};
      appearGlobalLoading(globalDispatch);
      const userRes = await getUserAPI();
      if (userRes.success && userRes.user) {
        const user = userRes.user;
        user.sessionId = user._id;
        await updateSavedUser(user);
        let res = { success: true };
        console.log(
          "On User Refreshed: ",
          checkIsNewUser,
          checkIsMobileVerified
        );
        if (checkIsNewUser || checkIsMobileVerified) {
          // const isNewUser = checkUserAccountStatus(user, handleNavigation);
          const isNewUser = checkShouldDisplayWarningPrompt({
            user,
            checkIsNewUser,
            checkIsMobileVerified,
            handleNewUserNavigation,
          });
          res.isNewUser = isNewUser;
        }
        disappearGlobalLoading(globalDispatch);
        return res; // {success: boolean, isNewUser?: boolean}
      }
    } catch (err) {
      console.log("refreshCurrentUser err: ", err);
      disappearGlobalLoading(globalDispatch);
      return { success: false, err };
    }
  };

  const checkShouldDisplayWarningPrompt = ({
    user,
    checkIsNewUser = false,
    checkIsMobileVerified = false,
    handleNewUserNavigation = null,
  }) => {
    let isNewUser = false;
    if (checkIsNewUser) {
      isNewUser = checkUserAccountStatus(user, handleNewUserNavigation);
    }
    if (!isNewUser && checkIsMobileVerified) {
      checkPhoneNumberVerified(user);
    }
    return isNewUser;
  };

  const checkUserAccountStatus = (user, handleNavigation = null) => {
    console.log("check user account status: ", user?.status);
    switch (user?.status) {
      case "NEW":
        if (handleNavigation) handleNavigation();
        else appearAccountVerification(globalDispatch, true);
        return true;
      case "PENDING":
      case "REJECTED":
        appearAccountVerification(globalDispatch, false);
        return true;
      default:
        disappearAccountVerification(globalDispatch);
        return false;
    }
  };

  const checkPhoneNumberVerified = (user) => {
    const isPhoneNumberVerified = user?.isPhoneNumVerified; // verified user
    const isSkippedPhoneNumberVerification = user?.skipPhoneVerification; // skipped verification on registration
    if (!isPhoneNumberVerified && !isSkippedPhoneNumberVerification) {
      // User created before mobile login implementation
      appearPhoneNumberVerification(globalDispatch);
    }
  };

  const switchUser = async ({ memberId, callback = () => {}, dispatch }) => {
    console.log("switch to user ", memberId, switching);
    if (switching) return;
    setSwitching(true);
    pushGlobalBlocking(dispatch, "switchUser");
    const cachedUsers = (await getDefaults(userDefaultKeys.CACHED_USERS)) ?? [];
    const newUser = cachedUsers?.find((el) => el?.memberId == memberId);
    //switched from other acc
    //refreshCurrentUser (which called updateSavedUser) required prevUser.current to be up-to-date, for inherite access/refresh token from current user
    console.log("is switch to new user. newUser", newUser);
    // const updatedUser = await updateSavedUser(newUser)

    let cacheCurrentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
    await saveDefaults(userDefaultKeys.CURRENT_USER, newUser);
    //NOTE: Do not setCurrentUser here, let refreshCurrentUser do it. because many view component useEffect listening currentUser change, and it will cause multiple fetch during switching user
    //NOTE: let refreshCurrentUser -> setCurrentUser if fetch success. if fetch not success, let interceptor handle it. Avoid state concurrent mutation updateSavedUser > refreshCurrentUser > updateSavedUser

    // NOTE: below works depends on the validateCurrentToken blocking the switchUser until the token is validated
    //-------- Switch User approach 1 ----------
    const res = await refreshCurrentUser({
      checkIsNewUser: true,
      checkIsMobileVerified: true,
    });
    console.log("[switchUser] after fetch user. response", res);
    if (res.success) {
      try {
        callback();
      } catch {}
    } else if (res.err && res.err.response && res.err.response.status !== 401) {
      // API error, show alert and restore the action
      appearGlobalError(dispatch, res.err?.response?.data?.msg);
      await saveDefaults(userDefaultKeys.CURRENT_USER, cacheCurrentUser);
    } else {
      // token fail. let interceptor handle it
    }
    setSwitching(false);
    popGlobalBlocking(dispatch, "switchUser");
    //-------- End Switch User approach 1 ----------

    //-------- Switch User approach 2 ----------
    /*
    try {
      const userRes = await getUserWithSessionAPI(newUser?._id);
      console.log('[switchUser] after fetch user. response', userRes);
      if (userRes.success && userRes.user) {
        const user = userRes.user
        await updateSavedUser(user);
        // Ideally, sessionId should be set after login. Just guarantee sessionId set when switching user
        const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER)
        if (currentUser) {
          currentUser.sessionId = currentUser._id
          await saveDefaults(userDefaultKeys.CURRENT_USER, currentUser)
        }
        try {
          callback()
        } catch { }
      }
    } catch (err) {
      console.log("getUserWithSession err: ", err);
      if(err && err.response && err.response.status !== 401) {
        // API error, show alert and rollback the current user
        appearGlobalError(dispatch, err?.response?.data?.msg)
        await saveDefaults(userDefaultKeys.CURRENT_USER, cacheCurrentUser);
      } else {
        // token fail. let interceptor handle it
      }
      // return { success: false, err };
    } finally {
      popGlobalBlocking(dispatch, "switchUser")
    }
    */
    //-------- End Switch User approach 2 ----------
  };

  // NOTE: userDefaultKeys.CURRENT_USER should already updated. when this function called, the user default still not yet overwrite by updateSavedUser()
  const updateCurrentUserToken = async (access_token, refresh_token) => {
    const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);

    // update current user default
    const updatedUser = { ...currentUser, access_token, refresh_token };
    await saveDefaults(userDefaultKeys.CURRENT_USER, updatedUser);

    // update cached users default
    const cachedUsers = (await getDefaults(userDefaultKeys.CACHED_USERS)) ?? [];
    const newUserList = cachedUsers.map((savedUser) =>
      savedUser._id === updatedUser._id ? updatedUser : savedUser
    );
    await saveDefaults(userDefaultKeys.CACHED_USERS, newUserList);

    // update context provider state
    setCachedUsers(newUserList);
    setCurrentUser(updatedUser);
    // console.log("updateCurrentUserToken updatedUser", updatedUser);
  };

  const updateBiometricSetting = async (allow, info) => {
    if (showBiometricNotice) setShowBiometricNotice(false);
    let biometricList =
      (await getDefaults(userDefaultKeys.BIOMETRIC_LIST)) ?? [];
    let newList = [...biometricList];
    const index = biometricList.findIndex(
      (el) =>
        el?.email &&
        el.email?.toLowerCase() == currentUser?.email?.toLowerCase()
    );
    if (index >= 0) {
      //existing entry
      if (!allow) {
        let { ath, ...newCred } = newList[index];
        newList[index] = { ...newCred, allow };
      } else {
        newList[index] = { ...newList[index], allow, ...info };
      }
      newList[index].isHidden = false;
    } else {
      //new entry
      if (!allow) {
        let { ath, ...newCred } = tmpCred.current;
        newList = newList.concat({ ...newCred, allow });
      } else {
        newList = newList.concat({ ...tmpCred.current, allow });
      }
    }
    await saveDefaults(userDefaultKeys.BIOMETRIC_LIST, newList);
    setBiometricList(newList);
  };

  const updateBiometricSettingForUser = async (allow, info, user) => {
    if (showBiometricNotice) setShowBiometricNotice(false);
    let biometricList =
      (await getDefaults(userDefaultKeys.BIOMETRIC_LIST)) ?? [];
    let newList = [...biometricList];
    const index = biometricList.findIndex(
      (el) => el?.email && el.email?.toLowerCase() == user?.email?.toLowerCase()
    );
    if (index >= 0) {
      //existing entry
      console.log(
        "jk check updateBiometricSettingForUser ",
        allow,
        biometricList,
        biometricList[index]
      );
      if (!allow) {
        let { ath, ...newCred } = newList[index];
        newList[index] = { ...newCred, allow };
      } else {
        newList[index] = { ...newList[index], allow, ...info };
      }
      newList[index].isHidden = false;
    } else {
      //new entry
      if (!allow) {
        let { ath, ...newCred } = tmpCred.current;
        newList = newList.concat({ ...newCred, allow });
      } else {
        newList = newList.concat({ ...tmpCred.current, allow });
      }
    }
    await saveDefaults(userDefaultKeys.BIOMETRIC_LIST, newList);
    setBiometricList(newList);
  };

  const removeBiometricSetting = async (info) => {
    if (showBiometricNotice) setShowBiometricNotice(false);
    var newList = (await getDefaults(userDefaultKeys.BIOMETRIC_LIST)) ?? [];
    let index = newList.findIndex(
      (el) => el?.email && el.email?.toLowerCase() == info?.email?.toLowerCase()
    );
    if (index >= 0) {
      newList[index].isHidden = true;
    }
    await saveDefaults(userDefaultKeys.BIOMETRIC_LIST, newList);
    setBiometricList(newList);
  };

  const showBiometricRegisterResult = (title, message) => {
    setBiometricRegisterTitle(title);
    setBiometricRegisterMsg(message);
    setShowBiometricResult(true);
  };
  const hideBiometricRegisterResult = () => {
    setShowBiometricResult(false);
    setBiometricRegisterTitle(null);
    setBiometricRegisterMsg(null);
  };

  const validateCurrentToken = async () => {
    pushGlobalBlocking(globalDispatch, "validateCurrentToken");
    try {
      //backward compatibility
      const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
      if (currentUser && !currentUser.sessionId) {
        currentUser.sessionId = currentUser._id;
        await saveDefaults(userDefaultKeys.CURRENT_USER, currentUser);
      }

      await validateSession();
    } finally {
      popGlobalBlocking(globalDispatch, "validateCurrentToken");
    }
  };

  const validateCachedUserToken = async (
    message,
    dispatch,
    platform = "app"
  ) => {
    if (isValidating) return;
    setIsValidating(true);
    pushGlobalBlocking(dispatch, "validateUserTokens");
    const cachedUsers = (await getDefaults(userDefaultKeys.CACHED_USERS)) ?? [];
    var usersToLogout = [],
      newUserList = [];
    for (var i = 0; i < cachedUsers.length; i++) {
      var user = cachedUsers[i];
      try {
        const validateRes = await validateSessionWithToken(user?.access_token);
        newUserList.push(user);
      } catch (e) {
        if (e?.response?.status == 401) {
          try {
            const refreshRes = await fetchRefreshToken({
              refresh_token: user?.refresh_token,
              platform,
            });
            newUserList.push({
              ...user,
              access_token: refreshRes?.access_token,
              refresh_token: refreshRes?.refresh_token,
              sessionId: user?._id,
            });
          } catch (e) {
            usersToLogout.push(user);
          }
        }
      }
    }
    //skip further processing when any of cached users failed to perform validation (err with code != 401)
    if (usersToLogout.length + newUserList.length != cachedUsers.length) {
      popGlobalBlocking(dispatch, "validateUserTokens");
      setIsValidating(false);

      appearGlobalError(
        dispatch,
        t("common:error.internalServerError"),
        "validation"
      );
      return;
    }
    await saveDefaults(userDefaultKeys.CACHED_USERS, newUserList);
    setCachedUsers(newUserList);

    const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER);
    const updatedUser = newUserList.find(
      (el) => el?.memberId == currentUser?.memberId
    );
    if (currentUser) {
      await saveDefaults(userDefaultKeys.CURRENT_USER, updatedUser);
      setCurrentUser(updatedUser);

      authedInstance.defaults.headers.Authorization =
        "Bearer " + updatedUser?.access_token;
    }

    popGlobalBlocking(dispatch, "validateUserTokens");
    if (usersToLogout.length > 0) {
      appearGlobalError(
        dispatch,
        message + `(${usersToLogout.map((el) => el?.username).join(", ")})`
      );
      if (currentUser && !updatedUser && onLogout) {
        onLogout();
      }
    }
    setIsValidating(false);
    setUserInitValidated(true);
  };

  const currentAllowBiometricSetting =
    biometricList.find(
      (el) =>
        el?.email &&
        currentUser?.email?.toLowerCase() == el.email?.toLowerCase()
    )?.allow ?? false;

  return (
    <UserViewModelContext.Provider
      value={{
        currentUser,
        cachedUsers,
        isRetriving,
        isCurrentPassExpired,
        myBookings,
        showBiometricNotice,
        biometricList: biometricList.filter((el) => !el.isHidden),
        currentAllowBiometricSetting,
        showBiometricResult,
        biometricRegisterTitle,
        biometricRegisterMsg,
        sessionCountdown,
        isValidating,
        userInitValidated,
        showBiometricRegisterResult,
        hideBiometricRegisterResult,
        guestLogin,
        login,
        onAppLoginSuccess,
        logout,
        forgetPassword,
        resetPassword,
        getMyBookings,
        refreshCurrentUser,
        switchUser,
        setCachedUsers,
        updateCurrentUserToken,
        setShowBiometricNotice,
        updateBiometricSetting,
        loginKiosk,
        loginKioskOtp,
        pauseSessionCountdown,
        resumeSessionCountdown,
        validateCurrentToken,
        validateCachedUserToken,
      }}
    >
      {children}
    </UserViewModelContext.Provider>
  );
};
