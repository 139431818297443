import AccountStatusPrompt from "pageComponents/register/AccountStatusPrompt";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import _ from "lodash";
import { UserViewModelContext } from "common/viewModels/UserViewModel";

const GlobalVerifyAccountModal = () => {
  const { globalState, globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { currentUser, refreshCurrentUser } = useContext(
    UserViewModelContext
  ) as IUserViewModelContext;

  return (
    <AccountStatusPrompt
      isOpen={
        !_.isEmpty(globalState?.accountVerification) ||
        (currentUser && globalState?.phoneNumberVerification)
      }
      isPending={globalState?.accountVerification?.isNewUser === false}
      isVerifyPhone={globalState?.phoneNumberVerification}
    />
  );
};

export default GlobalVerifyAccountModal;
