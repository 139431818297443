import React, { Children } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from "lodash";
import { WindowSize } from "utils/responsive";
import { HeadlineNews } from "models";
import HTMLTypography from "../HTMLTypography";
import styled from "styled-components";
import IconLoader from "assets/IconLoader";
import { useTranslation } from "react-i18next";
import IconNews from "../../../assets/ic-navBar-news.svg";
import Image from "../Image";

const StyledSlideLeft = styled(IconLoader.IconSlideLeft)`
  path {
    fill: #c85d38;
  }
`;
const StyledSlideRight = styled(IconLoader.IconSlideRight)`
  path {
    fill: #c85d38;
  }
`;

const StyledDotsContainer = styled.div`
  li button:before {
    font-size: 12px;
    color: white;
    opacity: 1;
  }
  li.slick-active button:before {
    font-size: 12px;
    color: #c85d38;
    opacity: 1;
  }
`;

const settings = {
  dots: false,
  infinite: true,
  adaptiveHeight: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <StyledSlideLeft />,
  nextArrow: <StyledSlideRight />,
  responsive: [
    {
      breakpoint: 860,
      settings: {
        dots: true,
        appendDots: (dots: any[]) => (
          <StyledDotsContainer
            style={{
              marginBottom: "-200px",
              padding: "10px",
            }}
          >
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </StyledDotsContainer>
        ),
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <></>,
        nextArrow: <></>,
      },
    },
  ],
};

type HeadlineCarouselProps = {
  items: HeadlineNews[];
} & React.HTMLAttributes<HTMLElement>;
const HeadlineCarousel = ({ items, children }: HeadlineCarouselProps) => {
  const { t } = useTranslation("home");
  return (
    <Slider {...settings}>
      {items &&
        _.map(items, (slide) => (
          <div>
            <div className="flex flex-col items-center max-h-[300px] px-2">
              <Title className="mb-4 whitespace-nowrap overflow-hidden text-ellipsis w-full">
                {slide.title_en}
              </Title>
              <div className="overflow-auto w-full">
                <HTMLTypography content={slide.desc_en} className="" />
                <div className="flex pt-8">
                  <span className="mr-[5px]">{t("headline.tips1")}</span>
                  <Image src={IconNews} />
                  <span className="ml-[5px]">{t("headline.tips2")}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      {children}
    </Slider>
  );
};

const Title = styled.span`
  color: var(--palette-ss-000, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  flex-shrink: 0;
`;
export default HeadlineCarousel;
