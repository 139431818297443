import React from "react";
import styled from "styled-components";

interface LabelProps {
  bgColor: string;
  children: React.ReactNode;
}

const StyledLabel = styled.div<LabelProps>`
  background-color: ${(props) => props.bgColor};
  padding: 5px 10px;
`;

const Label = ({ children, ...props }: LabelProps) => {
  return <StyledLabel {...props}>{children}</StyledLabel>;
};

export default Label;
