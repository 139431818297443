import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import BaseModal, { BaseModalProps } from "components/common/Modal/BaseModal";
import Styled from "./Styled";
import IconClose from "../../../assets/ic-whiteClose.svg";

type Props = {
  image: string;
  open: boolean;
  onClose: () => void;
};

const ProfileImageModal = (props: Props & BaseModalProps) => {
  const { image, ...modalProps } = props;

  return (
    <BaseModal {...modalProps}>
      <Styled.Container>
        <div>
          <div className="flex justify-end mb-[10px]">
            <img
              src={IconClose}
              className="cursor-pointer"
              onClick={() => modalProps.onClose()}
            />
          </div>
          <img
            src={image}
            className="max-w-[90vw] max-h-[90vh] object-contain"
          />
        </div>
      </Styled.Container>
    </BaseModal>
  );
};

export default ProfileImageModal;
