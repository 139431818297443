import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { ROUTE_BASE } from "../../Route";
import NotificationScreen from "./NotificationScreen";
import NotificationList from "./NotificationList";
import NotificationDetail from "./NotificationDetail";
import NotificationNav from "pageComponents/notifacation/NotificationNav";
import { ResponsiveUI } from "utils/responsive";
import { NotificationType } from "models";

const NotificationRouter: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <NotificationScreen />,
      children: [
        {
          path: "/",
          element: (
            <ResponsiveUI
              desktop={<Navigate to={`./${NotificationType.CHATBOT}`} />}
              mobile={<NotificationNav />}
            />
          ),
        },
        {
          path: "/:type",
          element: <NotificationList />,
        },
        {
          path: "/:type/:id",
          element: <NotificationDetail />,
        },
        {
          path: "*",
          element: <Navigate to={ROUTE_BASE.NOTIFICATION} />,
        },
      ],
    },
  ]);
};

export default NotificationRouter;
