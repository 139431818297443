import _ from "lodash";

export const isEmptyValues = (value) => {
    return (
      value === undefined ||
      value === null ||
      value === NaN ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  };

export const negativeToZero = (value) => {
  if(_.isFinite(value)) 
    return value < 0 ? 0 : value;
  else
    return 0;
}