import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import New from "./New";
import NewDetail from "./NewDetail";
import { ROUTE_BASE } from "Route";

const NewRouter: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <New />,
    },
    {
      path: "/:id",
      element: <NewDetail />,
    },
    {
      path: "*",
      element: <Navigate to={ROUTE_BASE.NEWS} />,
    },
  ]);
};

export default NewRouter;
