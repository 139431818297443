import Select from "./Select";
import SelectOption from "./SelectOption";

interface DropdownSelect {
  options: {
    label: string;
    value: string;
  }[];
  value: string | undefined;
  onChange: (data: string) => void;
  placeholder?: string;
  className?: string;
}

const DropdownSelect = ({
  options,
  onChange,
  value,
  ...props
}: DropdownSelect) => {
  return (
    <Select value={value} onChange={(e) => onChange(e.target.value)} {...props}>
      {Object.entries(options).map(([key, value], idx) => {
        return (
          <SelectOption value={value?.value} key={idx}>
            {value?.label}
          </SelectOption>
        );
      })}
    </Select>
  );
};

export default DropdownSelect;
