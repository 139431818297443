import { useState } from "react";
import {
  getSportpass as getSportpassAPI,
  buySportpass as buySportpassAPI,
  getSportpassHistory as getSportpassHistoryAPI,
} from "../network/sportpass";
import { appearGlobalError } from "../context/requests/globalRequest";
import config from "../../config";
import { getDateBetween, getDateIsBefore } from "../utils/DateHelper";
import dayjs from "../utils/dayjs";
import { getMPayLanguage } from "../utils/locale";
import { getLocalisedString } from "../utils/stringHelper";
import { isEmptyValues } from "../../utils/common";
import _ from "lodash";
import { useCommonFetcher } from "./Common/CommonViewModel";
import { createKioskBooking } from "../network/kiosk";

export const useSportpassViewModel = ({ isRenew = false }) => {
  const { fetcher } = useCommonFetcher();
  const [sportpassList, setSportpassList] = useState([]);
  const [sportpassHistory, setSportpassHistory] = useState([]);

  const getSportpassList = async () => {
      const res = await fetcher({api: getSportpassAPI, skipErrorHandle: true});
      if(res?.success){
        if (isRenew) {
          // Only display annual & monthly passes for renewal flow
          setSportpassList(
            res?.result?.filter(
              (sportpass) =>
                sportpass.sportpass_type === "ANNUAL" ||
                sportpass.sportpass_type === "MONTHLY"
            )
          );
        } else {
          setSportpassList(res?.result);
        }
      }
      return res;
  };

  const getSportpassHistory = async () => {
    const res = await fetcher({api: getSportpassHistoryAPI});
    if(res?.success) setSportpassHistory(_.filter(res?.result, (passHistory) => !isEmptyValues(passHistory?.sportpass)));
    return res;
  };

  const enableRenew = (data) => {
    if (data?.type === "ANNUAL" || data?.type === "MONTHLY") {
      const renewModalDate =
        data?.type === "ANNUAL"
          ? config.ANNUAL_SPORTPASS_RENEW_MODLA
          : config.MONTHLY_SPORTPASS_RENEW_MODLA;

      const startDate = dayjs(data?.expiry_date).subtract(
        renewModalDate,
        "day"
      );

      return getDateBetween(dayjs(Date.now()), startDate, data?.expiry_date);
    }
    // } else if (data?.type === "DAY") {
    //   return true;
    // }
  };

  const purchaseSportpass = async (payload) => {
    const res = config.IS_KIOSK ? await createKioskBooking({
      ...payload,
      lang: getMPayLanguage(),
      type: 'SPORTPASS'
    }) : await fetcher({api: () => buySportpassAPI({
      ...payload,
      lang: getMPayLanguage(),
    })});

    return res;
  };

  return {
    sportpassList,
    sportpassHistory,
    getSportpassList,
    enableRenew,
    purchaseSportpass,
    getSportpassHistory,
  };
};
