import LoginBackground from "../../components/LoginBackground/LoginBackground";
import cn from "classnames";
import Styled from "./Styled";
import Card from "components/common/Card";

interface RegisterPageContainerProps {
  children: React.ReactNode;
  fullScreen?: boolean;
}

const RegisterPageContainer = ({
  children,
  fullScreen,
}: RegisterPageContainerProps) => {
  return (
    <LoginBackground className={cn("grid", "place-items-center")} fullScreen>
      <Styled.PageContainer>{children}</Styled.PageContainer>
    </LoginBackground>
  );
};

export default RegisterPageContainer;
