import React, { useState, useCallback, useMemo } from "react";
import cn from "classnames";
import dayjs from "common/utils/dayjs";
import DotDivider from "components/common/DotDivider";
import { ReactComponent as BadgeActiveSvg } from "../../assets/bundle-offer-badge-active.svg";
import { ReactComponent as BadgeInActiveSvg } from "../../assets/bundle-offer-badge-inactive.svg";
import Styled from "./Styled";
import ShowMoreButton from "./ShowMoreButton";
import { WindowSize } from "../../utils/responsive";
import { useTranslation } from "react-i18next";
import CardBase, { VoucherListCardBaseProps } from "./CardBase";

const mockVouchers = ["Voucher 1", "Voucher 2", "Voucher 3"];

{
  /*
   * !!!Important: Dont delete it
   * Cannot found collapsed content in app's code, hidden first
   * Ref: https://gitlab.zensis.com/saisha/saisha-client/blob/develop/src/components/views/voucher/CashCard.js#L204
   */
}
const VoucherBox = ({ name, index }: { name: string; index: number }) => {
  return (
    <div
      className={cn(
        "w-full",
        "h-full",
        "flex",
        "flex-col",
        "gap-[10px]",
        "py-[20px]",
        "px-[6px]",
        "border-[#D5D5D5] border-solid",
        "lg:w-1/2",
        "lg:flex-row",
        "lg:h-[42px]",
        "lg:my-[8px]",
        "lg:px-0",
        "lg:flex-row",
        "lg:items-center",
        "lg:justify-between",
        "lg:border-b-0",
        index === 0 ? "lg:border-r" : null,
        index === 0 ? "lg:pr-[26px]" : "lg:pr-[24px]"
      )}
    >
      <span className={cn(index !== 0 ? "lg:ml-[27px]" : "lg:ml-[13px]")}>
        {name}
      </span>
      <Styled.RedeemButton type="button" disabled={false}>
        {"Redeem"}
      </Styled.RedeemButton>
    </div>
  );
};

export type CoachVoucherCardProps = VoucherListCardBaseProps & {
  title: string;
  description?: string;
  expiredDate?: string | Date;
  redeemedDate?: string | Date;
  disableBundle: boolean;
  voucherId?: string;
  onShowMoreDetailsClick: () => void;
  onRedeemClick?: () => void;
};

const CoachVoucherCard = (props: CoachVoucherCardProps) => {
  const {
    voucherId,
    title,
    description,
    expiredDate,
    redeemedDate,
    onShowMoreDetailsClick,
    onRedeemClick,
    disableBundle = false,
    disabled,
    ...cardBaseProps
  } = props;

  const { t } = useTranslation();

  const [isDetail, setIsDetail] = useState<boolean>(false);
  const windowSize = WindowSize();

  const expiryDateInString = useMemo(() => {
    if (expiredDate == null) {
      return "";
    }
    return dayjs(expiredDate).tz().format("DD/MM/YYYY");
  }, [expiredDate]);

  const redeemedDateInString = useMemo(() => {
    if (redeemedDate == null) {
      return "";
    }
    return dayjs(redeemedDate).tz().format("DD/MM/YYYY");
  }, [redeemedDate]);

  return (
    <CardBase {...cardBaseProps} disabled={disabled}>
      <div
        className={cn(
          "content",
          "h-full",
          "flex",
          "flex-col",
          "lg:flex-row",
          "lg:justify-between"
        )}
      >
        <div className="detail-left flex flex-col">
          <Styled.DetailTitle className="mb-[5px]">{title}</Styled.DetailTitle>
          {voucherId && (
            <pre>{t("voucher:detail.ref", { ref: voucherId })}</pre>
          )}
          {description && (
            <pre>
              <p>{description}</p>
            </pre>
          )}
          {disabled && (
            <p className="mt-[10px]">
              {redeemedDate == null
                ? t("voucher:detail.expiry").replace(
                  "{{date}}",
                  expiryDateInString
                )
                : t("voucher:detail.redeem").replace(
                  "{{date}}",
                  redeemedDateInString
                )}
            </p>
          )}
          <div className="h-[10px]" />
          {onShowMoreDetailsClick == null ? null : (
            <>
              <Styled.ShowDetailButton
                type="button"
                onClick={onShowMoreDetailsClick}
              >
                {t("voucher:button.seeMore")}
              </Styled.ShowDetailButton>
              <div className="h-[19px]" />
            </>
          )}
          {onRedeemClick == null ? null : (
            <>
              <Styled.RedeemButton
                type="button"
                disabled={disabled}
                onClick={onRedeemClick}
              >
                {t("voucher:button.redeem")}
              </Styled.RedeemButton>
              <div className="h-[19px]" />
            </>
          )}
        </div>
        <div className="detail-right flex-shrink-0 flex flex-col gap-[15px] lg:items-end justify-between">
          {disabled === true ? null : (
            <Styled.ExpiryLabel className="mr-[15px]">
              <span>
                {/** Prevent html escape special character */}
                {t("voucher:detail.expiry").replace(
                  "{{date}}",
                  expiryDateInString
                )}
              </span>
            </Styled.ExpiryLabel>
          )}
          {disableBundle ? null : disabled ? (
            <BadgeInActiveSvg />
          ) : (
            <BadgeActiveSvg />
          )}
        </div>
      </div>
      <div className="h-[20px]" />
      {/*
       * !!!Important: Dont delete it
       * Cannot found collapsed content in app's code, hidden first
       * Ref: https://gitlab.zensis.com/saisha/saisha-client/blob/develop/src/components/views/voucher/CashCard.js#L204
       */}
      {/* <div className={cn("content", isDetail ? "block" : "hidden")}>
        <DotDivider className="hidden lg:block" />
        <div className="h-[20px] hidden lg:block" />
        <div
          className={cn(
            "rounded-[10px]",
            "border",
            "px-[10px]",
            "border-[#D5D5D5]",
            "border-solid"
          )}
        >
          {mockVouchers
            .reduce((acc, currValue, currIndex, array) => {
              const size = windowSize === "screen1024" ? 2 : 1;
              if (currIndex % size === 0) {
                acc.push(array.slice(currIndex, currIndex + size));
              }
              return acc;
            }, [] as string[][])
            .map(([first, second], i) => (
              <div
                key={`row_${i}`}
                className={cn(
                  "w-full",
                  "flex",
                  "flex-col",
                  "lg:flex-row",
                  "lg:justify-between",
                  i !== 0 ? "border-t border-[#D5D5D5] border-solid" : null
                )}
              >
                <VoucherBox name={first} index={0} />
                {second != null && <VoucherBox name={second} index={1} />}
              </div>
            ))}
        </div>
        <div className="h-[30px]" />
      </div> */}
      {/* <ShowMoreButton
        open={isDetail}
        onClick={() => setIsDetail((state) => !state)}
      /> */}
    </CardBase>
  );
};

export default CoachVoucherCard;
