import i18n from "../../i18n";

export const getMPayLanguage = () => {
  switch (i18n.language) {
    case "en":
      return "en_US";
    case "zh":
      return "zh_TW";
    default:
      return "en_US";
  }
};
