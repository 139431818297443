import { instance } from ".";

export async function requestPhoneNumberOTP({ mobile, lang }) {
  console.log(mobile, lang);
  const res = await instance.get(
    `/v1/app/otp/mobile?${new URLSearchParams({
      mobile: mobile,
      lang: lang,
    }).toString()}`
  );
  console.log("Request phone number otp: ", res);
  return res.data;
}

export async function verifyPhoneNumberOTP({ mobile, otp }) {
  const res = await instance.post("/v1/app/otp/mobile/verify", {
    mobile: mobile,
    otp: otp,
  });

  return res.data;
}
