import { Voucher } from "models";
import { IPromoCode } from "common/models/PromoCode";
import { useTranslation } from "react-i18next";
import ReceiptDiscount from "./ReceiptDiscount";

interface OrderInfoProps {
  children: React.ReactNode;
  promoCode: IPromoCode | null;
  appliedVoucher: Voucher[];
  displayCoupon: boolean;
}

const OrderInfoBase = ({
  children,
  promoCode,
  appliedVoucher,
  displayCoupon,
}: OrderInfoProps) => {
  return (
    <div>
      {children}
      {displayCoupon && (
        <ReceiptDiscount
          promoCode={promoCode}
          appliedVoucher={appliedVoucher}
        />
      )}
    </div>
  );
};

export default OrderInfoBase;
