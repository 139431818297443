import styled from "styled-components";
import theme from "utils/theme";

// temp: change to common card later
const Card = styled.div`
  border-bottom-width: 2px;
  border-right-width: 2px;
  border-radius: 10px;
  border-color: ${theme.orange};
  border-style: solid;
  height: auto;
  width: 100%;
  background-color: var(--palette-ss-white);
  max-width: auto;
`;

const TitleText = styled.span`
  color: var(--palette-ss-000, #000);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.491px; /* 165.571% */
`;

const BodyText = styled.span`
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`;

export default {
  Card,
  TitleText,
  BodyText,
};
