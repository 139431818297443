import styled from "styled-components";
import { size } from "../../../utils/responsive";
import theme from "utils/theme";

// temp: change to common card later
const Card = styled.div`
  border-bottom-width: 2px;
  border-right-width: 2px;
  border-radius: 10px;
  border-color: ${theme.orange};
  border-style: solid;
  height: auto;
  width: 100%;
  background-color: var(--palette-ss-white);
  max-width: auto;
`;

const TitleText = styled.span`
  color: var(--palette-ss-000, #000);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 165.571% */
`;

const SubTitleText = styled.span`
  color: var(--palatte-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;

const BodyText = styled.span`
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px dashed #d5d5d5;
`;

const ClassRoundedContainer = styled.div<{ remainder: number }>`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #d5d5d5;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr;

  & .tile {
    border-bottom: 1px solid #d5d5d5;
    padding: 7px 10px;

    // last child
    &:nth-last-child(1) {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  @media (min-width: ${size.screen1024}px) {
    grid-template-columns: repeat(3, 1fr);
    & .tile {
      border-right: 1px solid #d5d5d5;

      // first row
      &:nth-child(-n + 3) {
        padding-top: 0px;
      }

      // last row
      &:nth-last-child(-n + ${(props) => props.remainder}) {
        border-bottom: none;
        padding-bottom: 0;
      }

      // first column
      &:nth-child(3n + 1) {
        padding-left: 0;
        padding-left: 10px;
      }

      // last column
      &:nth-child(3n) {
        padding-right: 0;
        margin-right: 10px;
        border-right: none;
      }
    }
  }
`;

const ClassItemsText1 = styled.span`
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
`;

const ClassItemsText2 = styled.span`
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
`;

const RequestSickLeaveButton = styled.button`
  color: ${theme.orange};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-align: start;
`;

export default {
  Card,
  TitleText,
  SubTitleText,
  BodyText,
  Divider,
  ClassRoundedContainer,
  ClassItemsText1,
  ClassItemsText2,
  RequestSickLeaveButton,
};
