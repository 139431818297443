import styled from "styled-components";
import TextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material/TextField";
import theme from "../../utils/theme";

type StyledProps = MuiTextFieldProps & {
  error?: boolean;
  width?: string | number;
  height?: string | number;
};

export type TextFieldProps = StyledProps & {
  title?: string;
};

const CustomTextfield = styled(TextField)<StyledProps>`
  width: ${(props) => (props.width ? props.width : "100%")};
  border-radius: 5px;

  & .MuiOutlinedInput-root {
    border-radius: 10px;
    height: ${(props) => (props.multiline ? "auto" : "44.6px")};
    background-color: var(--palette-ss-white);

    input {
      padding: 7.5px 14px !important;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: var(--palette-ss-black) !important;
    }

    &.Mui-focused fieldset {
      border: 1px solid ${theme.secondary} !important;
    }
    &.Mui-disabled {
      background-color: #f2f2f2 !important;
    }
    &.Mui-disabled fieldset {
      border: 1px solid ${theme.secondary} !important;
    }
    &:hover fieldset {
      border: 1px solid ${theme.secondary} !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--palette-ss-black);
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: var(--palette-ss-black) !important;
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${theme.error};
    }
  }
  & .MuiFormHelperText-root {
    line-height: 1.3;
    margin-top: 8px;
    &.Mui-error {
      color: ${theme.error};
    }
  }
`;

export const TextFieldLabel = styled.p`
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  margin-bottom: 7.43px;
`;

const CommonTextfield = ({
  title,
  error = false,
  width,
  ...remainProps
}: TextFieldProps) => {
  return (
    <div>
      {title && <TextFieldLabel>{title}</TextFieldLabel>}
      <CustomTextfield error={error} width={width} {...remainProps} focused />
    </div>
  );
};

export default CommonTextfield;
