import React, { useCallback, useMemo, useState } from "react";
import qs from "qs";
import Styled from "./Styled";
import { VoucherTypeSearch } from "./VoucherListPage";
import { useNavigate } from "react-router-dom";
import HeaderTitle from "components/common/HeaderTitle";
import { EventVoucher, Voucher } from "models/Voucher";
import { useSnackbar } from "notistack";
import _ from "lodash";

import {
  AdmissionVoucherCard,
  CashVoucherCard,
  CashVoucherCardProps,
  CoachVoucherCard,
  CoachVoucherCardProps,
  EventVoucherCard,
  FacilityVoucherCard,
  FacilityVoucherCardProps,
  PassVoucherCard,
  PassVoucherCardProps,
  useVoucherCardModel,
} from "components/VoucherListingCard";

import {
  AdmissionVoucher,
  CashVoucher,
  CoachVoucher,
  ExtractVoucherType,
  FacilityVoucher,
  PassVoucher,
} from "models/Voucher";
import { useTranslation } from "react-i18next";
import { ROUTE_BASE } from "Route";
import { Facility } from "common/models/Facility";
import { SportPass } from "models";
import { getLocalisedString } from "common/utils/stringHelper";
import { getCorrectUrl } from "navigators/Router";

type Props = {
  tab: VoucherTypeSearch;
  availableVouchers: Voucher[];
  sportpassList: SportPass[];
  sportPassRenewable: boolean;
  setRedeemVoucherId: (voucherId: string) => void;
};

const ActiveVoucherList = ({
  tab,
  availableVouchers,
  sportpassList,
  sportPassRenewable,
  setRedeemVoucherId,
}: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const { getVoucherCardProps } = useVoucherCardModel();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const typeConditionMap = useMemo(() => {
    const isAll = tab == null || tab == "";
    return {
      isAll: isAll,
      includeCoach: isAll || tab.match(VoucherTypeSearch.COACH),
      includeCash: isAll || tab.match(VoucherTypeSearch.CASH),
      includeAdmission: isAll || tab.match(VoucherTypeSearch.ADMISSION),
      includeEvent: isAll || tab.match(VoucherTypeSearch.EVENT),
      includeFacility: isAll || tab.match(VoucherTypeSearch.FACILITY),
      // includeDayPass: isAll || tab.match(VoucherTypeSearch.DAY_PASS),
      // includeMonthlyPass: isAll || tab.match(VoucherTypeSearch.MONTHLY_PASS),
      // includeAnnualPass: isAll || tab.match(VoucherTypeSearch.ANNUAL_PASS),
    };
  }, [tab]);

  /**
   * Start of Filtered voucher lists (by type)
   */
  const coachFilteredList: ExtractVoucherType<CoachVoucher>[] = useMemo(() => {
    return availableVouchers.filter(
      (each) => each?.voucher?.type === "coach"
    ) as ExtractVoucherType<CoachVoucher>[];
  }, [availableVouchers]);

  const cashFilteredList: ExtractVoucherType<CashVoucher>[] = useMemo(() => {
    return availableVouchers.filter(
      (each) => each?.voucher?.type === "cash"
    ) as ExtractVoucherType<CashVoucher>[];
  }, [availableVouchers]);

  const admissionPassFilteredList: ExtractVoucherType<AdmissionVoucher>[] =
    useMemo(() => {
      return availableVouchers.filter(
        (each) => each?.voucher?.type === "admission"
      ) as ExtractVoucherType<AdmissionVoucher>[];
    }, [availableVouchers]);

  const eventPassFilteredList: ExtractVoucherType<EventVoucher>[] =
    useMemo(() => {
      return availableVouchers.filter(
        (each) => each?.voucher?.type === "event"
      ) as ExtractVoucherType<EventVoucher>[];
    }, [availableVouchers]);

  const facilityPassFilteredList: ExtractVoucherType<FacilityVoucher>[] =
    useMemo(() => {
      return availableVouchers.filter(
        (each) => each?.voucher?.type === "facility"
      ) as ExtractVoucherType<FacilityVoucher>[];
    }, [availableVouchers]);

  const dayPassFilteredList: ExtractVoucherType<PassVoucher>[] = useMemo(() => {
    return availableVouchers.filter(
      (each) => each?.voucher?.type === "day"
    ) as ExtractVoucherType<PassVoucher>[];
  }, [availableVouchers]);

  const monthlyPassFilteredList: ExtractVoucherType<PassVoucher>[] =
    useMemo(() => {
      return availableVouchers.filter(
        (each) => each?.voucher?.type === "monthly"
      ) as ExtractVoucherType<PassVoucher>[];
    }, [availableVouchers]);

  const annualPassFilteredList: ExtractVoucherType<PassVoucher>[] =
    useMemo(() => {
      return availableVouchers.filter(
        (each) => each?.voucher?.type === "annual"
      ) as ExtractVoucherType<PassVoucher>[];
    }, [availableVouchers]);

  /**
   * End of filtered voucher lists
   */

  return (
    <React.Fragment>
      {typeConditionMap.includeCoach && coachFilteredList.length > 0 && (
        <Styled.CategoryWrapper>
          <HeaderTitle title={t("voucher:type.coach")} />
          <div className="h-[30px]" />
          <div className="flex flex-col gap-y-[20px]">
            {coachFilteredList.map((each) => {
              const cardProps = getVoucherCardProps(
                each
              ) as CoachVoucherCardProps;
              return (
                <CoachVoucherCard
                  {...cardProps}
                  key={each.voucherId}
                  onShowMoreDetailsClick={() => {
                    navigate(
                      `/${i18n.language}/${ROUTE_BASE.PRIVATE_COACH}/coach/${
                        each.voucher.coachlesson._id
                      }?${qs.stringify({
                        voucher: each.voucherId,
                      })}`
                    );
                  }}
                />
              );
            })}
          </div>
        </Styled.CategoryWrapper>
      )}

      {availableVouchers.length <= 0 && (
        <p>{t("myBooking:listing.noRecords")}</p>
      )}
      <div className="h-[20px]" />

      {typeConditionMap.includeCash && cashFilteredList.length > 0 && (
        <Styled.CategoryWrapper>
          <HeaderTitle title={t("voucher:type.cash")} />
          <div className="h-[30px]" />
          <div className="flex flex-col gap-y-[20px]">
            {cashFilteredList.map((each) => {
              const cardProps = getVoucherCardProps(
                each
              ) as CashVoucherCardProps;
              return <CashVoucherCard key={each.voucherId} {...cardProps} />;
            })}
          </div>
        </Styled.CategoryWrapper>
      )}

      {typeConditionMap.includeAdmission &&
        admissionPassFilteredList.length > 0 && (
          <Styled.CategoryWrapper>
            <HeaderTitle title={t("voucher:type.admission")} />
            <div className="h-[30px]" />
            <div className="flex flex-col gap-y-[20px]">
              {admissionPassFilteredList.map((each) => (
                <AdmissionVoucherCard
                  key={each.voucherId}
                  voucherId={each?.voucherId}
                  expiredDate={each?.expiredAt}
                  startDate={each?.voucher?.startDate}
                  endDate={each?.voucher?.endDate}
                  disableBundle={_.isEmpty(each?.voucher?.packageTransaction)}
                  onRedeemClick={() => setRedeemVoucherId(each?.voucherId)}
                />
              ))}
            </div>
          </Styled.CategoryWrapper>
        )}

      {typeConditionMap.includeEvent && eventPassFilteredList.length > 0 && (
        <Styled.CategoryWrapper>
          <HeaderTitle title={t("voucher:type.event")} />
          <div className="h-[30px]" />
          <div className="flex flex-col gap-y-[20px]">
            {eventPassFilteredList.map((each) => (
              <EventVoucherCard
                key={each.voucherId}
                voucherId={each?.voucherId}
                title={getLocalisedString(
                  each?.voucher?.eventType?.name_en,
                  each?.voucher?.eventType?.name_tc
                )}
                expiredDate={each?.expiredAt}
                disableBundle={_.isEmpty(each?.voucher?.packageTransaction)}
                onRedeemClick={() =>
                  navigate(
                    `/${i18n.language}/${ROUTE_BASE.EVENT}?${qs.stringify({
                      typeId: each?.voucher?.eventType?._id,
                    })}`
                  )
                }
              />
            ))}
          </div>
        </Styled.CategoryWrapper>
      )}

      {typeConditionMap.includeFacility &&
        facilityPassFilteredList.length > 0 && (
          <Styled.CategoryWrapper>
            <HeaderTitle title={t("voucher:type.facility")} />
            <div className="h-[30px]" />
            <div className="flex flex-col gap-y-[20px]">
              {facilityPassFilteredList.map((each) => {
                const facility = new Facility(each?.voucher?.category as any);
                const cardProps = getVoucherCardProps(
                  each
                ) as FacilityVoucherCardProps;

                return (
                  <FacilityVoucherCard
                    key={each.voucherId}
                    {...cardProps}
                    onRedeemClick={() =>
                      navigate(
                        facility.isGolf && facility.isGolf()
                          ? getCorrectUrl(
                              `${
                                ROUTE_BASE.FACILITY_BOOKING
                              }/category/${facility?._id}?${qs.stringify({
                                voucher: each.voucherId,
                              })}`
                            )
                          : getCorrectUrl(
                              `${
                                ROUTE_BASE.FACILITY_BOOKING
                              }/${facility?._id}/timeslot?${qs.stringify({
                                voucher: each.voucherId,
                              })}`
                            )
                      )
                    }
                  />
                );
              })}
            </div>
          </Styled.CategoryWrapper>
        )}

      {/* {typeConditionMap.includeDayPass && dayPassFilteredList.length > 0 && (
        <Styled.CategoryWrapper>
          <HeaderTitle title={t("voucher:type.day")} />
          <div className="h-[30px]" />
          <div className="flex flex-col gap-y-[20px]">
            {dayPassFilteredList.map((each) => {
              const cardProps = getVoucherCardProps(
                each
              ) as PassVoucherCardProps;
              const pass = sportpassList.find(
                (pass) =>
                  pass.sportpass_type.toLowerCase() === each.voucher.type
              );
              return (
                <PassVoucherCard
                  key={each.voucherId}
                  {...cardProps}
                  allowRedeem={sportPassRenewable}
                  onRedeemClick={() =>
                    navigate(
                      `/${i18n.language}/${
                        ROUTE_BASE.SPORT_PASS
                      }/buy-pass/${pass?._id}?${qs.stringify({
                        voucher: each.voucherId,
                      })}`,
                      {
                        state: { item: JSON.stringify(pass) },
                      }
                    )
                  }
                />
              );
            })}
          </div>
        </Styled.CategoryWrapper>
      )} */}

      {/* {typeConditionMap.includeMonthlyPass &&
        monthlyPassFilteredList.length > 0 && (
          <Styled.CategoryWrapper>
            <HeaderTitle title={t("voucher:type.monthly")} />
            <div className="h-[30px]" />
            <div className="flex flex-col gap-y-[20px]">
              {monthlyPassFilteredList.map((each) => {
                const cardProps = getVoucherCardProps(
                  each
                ) as PassVoucherCardProps;
                const pass = sportpassList.find(
                  (pass) =>
                    pass.sportpass_type.toLowerCase() === each.voucher.type
                );

                return (
                  <PassVoucherCard
                    key={each.voucherId}
                    {...cardProps}
                    allowRedeem={sportPassRenewable}
                    onRedeemClick={() =>
                      navigate(
                        `${
                          ROUTE_BASE.SPORT_PASS
                        }/buy-pass/${pass?._id}?${qs.stringify({
                          voucher: each.voucherId,
                        })}`,
                        {
                          state: { item: JSON.stringify(pass) },
                        }
                      )
                    }
                  />
                );
              })}
            </div>
          </Styled.CategoryWrapper>
        )} */}

      {/* {typeConditionMap.includeAnnualPass &&
        annualPassFilteredList.length > 0 && (
          <Styled.CategoryWrapper>
            <HeaderTitle title={t("voucher:type.annual")} />
            <div className="h-[30px]" />
            <div className="flex flex-col gap-y-[20px]">
              {annualPassFilteredList.map((each) => {
                const cardProps = getVoucherCardProps(
                  each
                ) as PassVoucherCardProps;
                const pass = sportpassList.find(
                  (pass) =>
                    pass.sportpass_type.toLowerCase() === each.voucher.type
                );
                return (
                  <PassVoucherCard
                    key={each.voucherId}
                    {...cardProps}
                    allowRedeem={sportPassRenewable}
                    onRedeemClick={() =>
                      navigate(
                        `/${i18n.language}/${
                          ROUTE_BASE.SPORT_PASS
                        }/buy-pass/${pass?._id}?${qs.stringify({
                          voucher: each.voucherId,
                        })}`,
                        {
                          state: { item: JSON.stringify(pass) },
                        }
                      )
                    }
                  />
                );
              })}
            </div>
          </Styled.CategoryWrapper>
        )} */}
    </React.Fragment>
  );
};

export default ActiveVoucherList;
