import Typography from "components/common/Typography";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";
import Styled from "./Styled";
import IconChild from "assets/ic-child.svg";
import Space from "components/common/Space";

const ChildLabel = () => {
  const { t } = useTranslation("register");
  return (
    <Styled.ChildLabel>
      <Typography color={theme.white} fontSize="12px">
        {t("child")}
      </Typography>
      <Space width="5px" />
      <img src={IconChild} />
    </Styled.ChildLabel>
  );
};

export default ChildLabel;
