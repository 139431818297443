import styled from "styled-components";
import theme from "utils/theme";

const Card = styled.div<{ backgroundColor?: string; borderColor?: string }>`
  width: 100%;
  height: inherit;
  border-radius: 10px;
  border-right: 2px solid ${(props) => props.borderColor ?? theme.orange};
  border-bottom: 2px solid ${(props) => props.borderColor ?? theme.orange};
  background: ${(props) => props.backgroundColor ?? "#FFF"};
  overflow: hidden;
  padding-top: 22px;

  /** font */
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */

  & pre {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 137.5%
  }

  & .content {
    padding-left: 37px;
    padding-right: 23px;
  }
`;

const DetailTitle = styled.div`
  color: var(--palette-ss-000, #000);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.491px; /* 165.571% */
`;

const ExpiryLabel = styled.span`
  width: min-content;
  border-radius: 15px;
  background: #eaebe6;
  padding: 4px 10px;
  color: var(--000, #000);
  font-family: Noto Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  white-space: nowrap;
`;

const ShowDetailButton = styled.button`
  width: max-content;
  color: ${theme.orange};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
`;

const RedeemButton = styled.button`
  width: 103px;
  padding: 3.997px 21.984px;
  border-radius: 6.995px;
  background: ${theme.blue};
  color: var(--palette-ss-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.983px; /* 171.305% */

  &:disabled {
    background: var(--palette-ss-grey-text, #8c8c8c);
  }
`;

export default {
  Card,
  DetailTitle,
  ExpiryLabel,
  ShowDetailButton,
  RedeemButton,
};
