import LoginBackground from "../../components/LoginBackground/LoginBackground";
import {
  Outlet,
  redirect,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import cn from "classnames";
import Card from "../../components/common/Card";
import CommonRoundedCornerCard from "components/common/CommonRoundedCornerCard";
import Space from "components/common/Space";
import NotificationLayout from "layouts/NotificationLayout/NotificationLayout";
import Styled from "./Styled";
import { useContext, useEffect, useState } from "react";
import { WindowSize } from "utils/responsive";
import { ROUTE_BASE } from "Route";
import { INotificationListViewModel } from "models/Notification";
import { useNotificationListViewModel } from "common/viewModels/NotificationCenter/NotificationListViewModel";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { IUserViewModelContext } from "../../@types/common";
import { useTranslation } from "react-i18next";

export type INotificationListOutletContextType = {
  notificationListViewModel: INotificationListViewModel;
  generalLoading: boolean;
  messageLoading: boolean;
};
const NotificationScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // lang
  const { i18n } = useTranslation("common");
  const { currentUser, isRetriving } = useContext(
    UserViewModelContext
  ) as IUserViewModelContext;

  const [generalLoading, setGeneralLoading] = useState<boolean>(true);
  const [messageLoading, setMessageLoading] = useState<boolean>(true);
  const notificationListVM =
    useNotificationListViewModel() as INotificationListViewModel;

  /* const { isPending: generalLoading } = useQuery({
    queryKey: ["getGeneralNotificationList"],
    queryFn: () =>
      notificationListVM.getGeneralNotificationList({ handledLoading: true }),
  });
  const { isPending: messageLoading } = useQuery({
    queryKey: ["getMessageNotificationList"],
    queryFn: () =>
      notificationListVM.getMessageNotificationList({ handledLoading: true }),
  }); */
  useEffect(() => {
    if (currentUser) {
      notificationListVM
        .getGeneralNotificationList({ handledLoading: true })
        .then(() => setGeneralLoading(false));
      notificationListVM
        .getMessageNotificationList({ handledLoading: true })
        .then(() => setMessageLoading(false));
    }
    if (isRetriving === false && !currentUser) {
      navigate(`/${i18n.language}/${ROUTE_BASE.AUTH}/sign-in`);
    }
  }, [currentUser, isRetriving]);

  return (
    <NotificationLayout>
      {!isRetriving ? (
        <Outlet
          context={{
            notificationListViewModel: notificationListVM,
            generalLoading,
            messageLoading,
          }}
        />
      ) : (
        <></>
      )}
    </NotificationLayout>
  );
};

export default NotificationScreen;
