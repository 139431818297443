import ModalCard from "components/common/Modal/ModalCard";
import styled from "styled-components";
import { size } from "utils/responsive";
const ModalBody = styled.div`
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const Title = styled.span`
  color: var(--palette-ss-000, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
`;

const CustomModalCard = styled.div`
  position: relative;
  width: calc(100dvw - 42px);
  background: var(--palette-ss-white, #fff);
  border-radius: 30px;
  max-width: 632px;
`;

export default { ModalBody, Title, CustomModalCard };
