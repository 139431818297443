import React from "react";
import styled from "styled-components";
import cn from "classnames";

interface HTMLProps extends React.HTMLProps<HTMLDivElement> {
  content: string;
}

const HTMLTypography = ({ content, className }: HTMLProps) => {
  return (
    <RevertedHtmlTypography
      dangerouslySetInnerHTML={{ __html: content }}
      className={cn(className)}
    />
  );
};

// need to know the principle behind revert keyword, but it appears to work and revert back the tailwind preflight
// https://tailwindcss.com/docs/preflight
const RevertedHtmlTypography = styled.div`
  * {
    all: revert;
  }
`;
export default HTMLTypography;
