import { ReactComponent as IconSport } from "./sport.svg";
import { ReactComponent as IconCamp } from "./camp.svg";
import { ReactComponent as IconFoodAndDrink } from "./food-drink.svg";
import { ReactComponent as IconMusic } from "./music.svg";
import { ReactComponent as IconFilter } from "./filter.svg";
import { ReactComponent as IconAddress } from "./address.svg";
import { ReactComponent as IconCheck } from "./check.svg";
import { ReactComponent as IconEdit } from "./edit.svg";
import { ReactComponent as IconSuccess } from "./success.svg";
import { ReactComponent as IconFail } from "./fail.svg";
import { ReactComponent as IconNav } from "./ic-nav.svg";
import { ReactComponent as IconUnCheck } from "./uncheck.svg";
import { ReactComponent as IconSlideLeft } from "./slide-left.svg";
import { ReactComponent as IconSlideRight } from "./slide-right.svg";
import { ReactComponent as IconLink } from "./ic-linkArrow.svg";
import { ReactComponent as IconGuest } from "./guest.svg";
import { ReactComponent as IconAge } from "./age.svg";
import { ReactComponent as IconPeople } from "./people.svg";
import { ReactComponent as IconStepError } from "./ic-stepError.svg";

export default {
  IconSport,
  IconCamp,
  IconFoodAndDrink,
  IconMusic,
  IconFilter,
  IconAddress,
  IconCheck,
  IconEdit,
  IconSuccess,
  IconNav,
  IconFail,
  IconUnCheck,
  IconSlideLeft,
  IconSlideRight,
  IconLink,
  IconGuest,
  IconAge,
  IconPeople,
  IconStepError,
};
