import { Voucher } from "models";
import i18n from "../i18n";
import { getLocalizedString } from "./locale";

export function getVoucherName(voucher: Voucher) {
  switch (voucher.voucher?.type) {
    case "cash":
    case "facility":
    case "admission":
    case "day":
    case "monthly":
    case "annual":
      return voucher.voucherId;
    case "coach":
      return getLocalizedString(
        voucher.voucher.coachlesson.coach_id.coach_name_en,
        voucher.voucher.coachlesson.coach_id.coach_name_zh
      );
    default:
      return voucher.voucherId;
  }
}
