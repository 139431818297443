import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import * as _ from "lodash";
import IconLoader from "../../../assets/IconLoader";

import {
  ItemWrapper,
  ItemTitle,
  ItemContentBox,
  ItemDes,
  ItemLink,
  Paging,
  PagingItem,
  BoxItem,
} from "../Styled";
import HTMLTypography from "components/common/HTMLTypography";
import { getLocalizedString } from "utils/locale";
import { MAX_NUMBER_PER_PAGE } from "../MonthlyProgramByCategory";
import { MonthlyProgram } from "common/models/MonthlyProgram";
import Space from "components/common/Space";
import { ROUTE_BASE } from "Route";
import ReadmoreButton from "components/common/buttons/ReadmoreButton";
import Pagination from "components/common/Pagination";

interface MonthlyProgramItemBoxProps {
  programmes: MonthlyProgram[];
}

const ItemBox = (props: MonthlyProgramItemBoxProps) => {
  const { programmes } = props;

  const navigate = useNavigate();

  const handleReadMore = (programId: string) => {
    navigate(`../program/${programId}`);
  };

  const _monthlyProgramElement = (data: any) => {
    return (
      <ItemWrapper>
        {_.map(data, (program) => (
          <BoxItem>
            <ItemContentBox
              style={{ display: "flex", flexDirection: "column" }}
            >
              <ItemTitle>
                {getLocalizedString(program.titleEn, program.titleZh)}
              </ItemTitle>
              <div className="d-flex flex-1 overflow-y-hidden text-[#4a4a4a]">
                <HTMLTypography
                  content={getLocalizedString(
                    program.descriptionEn,
                    program.descriptionZh
                  )}
                ></HTMLTypography>
              </div>
              <ReadmoreButton onClick={() => handleReadMore(program._id)} />
            </ItemContentBox>
          </BoxItem>
        ))}
      </ItemWrapper>
    );
  };

  return (
    <Pagination
      data={programmes}
      itemContainer={_monthlyProgramElement}
      itemPerPage={MAX_NUMBER_PER_PAGE}
    />
  );
};

export default ItemBox;
