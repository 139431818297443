import { useEffect, useMemo, useState } from "react";
import { getLocalisedString } from "../../utils/stringHelper";
import { applyPromoCode as applyPromoCodeAPI } from "../../network/payment";
import {
  appearGlobalError,
  appearGlobalLoading,
  disappearGlobalLoading,
} from "../../context/requests/globalRequest";
import { VoucherType } from "../VoucherViewModel";
import { usePromoCodeViewModel } from "../Payment/PromoCodeViewModel";
import { isEmptyValues, negativeToZero } from "../../utils/common";
import { PromoCodeBookingType } from "../../models/PromoCode";

export const EventPromoCodeType = {
  CONSTANT: "CONSTANT",
  PERCENTAGE: "PERCENTAGE",
};

export const useMonthlyProgramBookingViewModel = ({
  programClass,
  dispatch,
}) => {
  const { checkPromoCodeAvailability, estimatePromoCodeDiscount } =
    usePromoCodeViewModel();
  const [currentClass, setCurrentClass] = useState(programClass);
  const [promoCode, setPromoCode] = useState(null);
  const [appliedVouchers, setVouchers] = useState([]);

  const subtotalPrice = useMemo(() => {
    return (
      currentClass?.pricing * programClass?.lessons?.length ??
      currentClass.pricing
    );
  }, [currentClass?.pricing, programClass?.lessons]);

  const subtotalPriceWithVoucher = useMemo(() => {
    return (
      subtotalPrice -
      appliedVouchers.reduce((acc, voucher) => acc + voucher?.voucher?.value, 0)
    );
  }, [subtotalPrice, appliedVouchers]);

  useEffect(() => {
    updateEstimatedPromoCodePrice();
  }, [subtotalPriceWithVoucher]);

  const applyPromoCode = async (code) => {
    const res = await checkPromoCodeAvailability({
      code,
      bookingType: PromoCodeBookingType.PROGRAMME,
      orderAmount: subtotalPriceWithVoucher * 100,
    });

    if (res?.success && !isEmptyValues(res?.promoCode)) {
      setPromoCode(res?.promoCode);
    } else {
      setPromoCode(null);
    }
  };

  const updateEstimatedPromoCodePrice = async () => {
    if (!isEmptyValues(promoCode)) {
      const res = await estimatePromoCodeDiscount({
        promoCode: promoCode,
        orderAmount: subtotalPriceWithVoucher * 100,
      });

      if (res?.success && !isEmptyValues(res?.promoCode)) {
        setPromoCode(res?.promoCode);
      } else {
        setPromoCode(null);
      }
    }
  };

  const removePromoCode = () => {
    setPromoCode(null);
  };

  const applyVoucher = async (voucher) => {
    if (appliedVouchers.some((el) => el?.voucherId == voucher?.voucherId))
      return;
    let appliedVoucher = voucher;
    if (
      !appliedVoucher.voucher.value &&
      appliedVoucher?.voucher?.type != VoucherType.CASH
    ) {
      appliedVoucher.voucher.value = getSubtotalPrice();
    }
    setVouchers([...appliedVouchers, appliedVoucher]);
  };

  const removeVoucher = (vouchers) => {
    if (vouchers) {
      if (Array.isArray(vouchers)) {
        setVouchers(
          appliedVouchers.filter(
            (el) =>
              !vouchers.some((voucher) => voucher?.voucherId == el?.voucherId)
          )
        );
      } else {
        setVouchers(
          appliedVouchers.filter((el) => vouchers?.voucherId != el?.voucherId)
        );
      }
    } else {
      setVouchers([]);
    }
  };

  const getSubtotalPrice = () => {
    return Math.max(0, subtotalPrice);
  };

  const getTotalPrice = () => {
    let total = promoCode
      ? subtotalPriceWithVoucher - (promoCode?.enjoyedDiscount ?? 0)
      : subtotalPriceWithVoucher;
    return Math.max(0, total);
  };

  const getBookingSummary = () => {
    var bookingSummary = {
      classId: currentClass?._id,
      programmeName:
        getLocalisedString(
          currentClass?.programme?.titleEn,
          currentClass?.programme?.titleZh
        ) ?? undefined,
      lessons: currentClass?.lessons,
      coaches: currentClass?.coaches,
      pricing: currentClass?.pricing,
      subtotalPrice: getSubtotalPrice(),
      totalPrice: getTotalPrice(),
      remarks:
        getLocalisedString(currentClass?.remarksEn, currentClass?.remarksZh) ??
        undefined,
      tnc: {
        title:
          getLocalisedString(
            currentClass?.programme?.tncTitleEn,
            currentClass?.programme?.tncTitleZh
          ) ?? undefined,
        desc:
          getLocalisedString(
            currentClass?.programme?.tncDescriptionEn,
            currentClass?.programme?.tncDescriptionZh
          ) ?? undefined,
      },
    };

    if (promoCode) {
      bookingSummary.promoCode = promoCode;
    }

    // TODO: Voucher implementation
    if (appliedVouchers.length > 0) {
      bookingSummary.vouchers = appliedVouchers;
    }

    return {
      bookingSummary,
      payload: getConfirmBookingPayload(),
    };
  };

  const getConfirmBookingPayload = () => {
    var payload = {
      classId: currentClass?._id,
      // totalAmount: getTotalPrice(),
    };

    if (promoCode) {
      payload.promocode = promoCode.code;
      // payload.promoCodeTicket = promoCode.promoCodeTicketId;
    }

    if (appliedVouchers.length > 0) {
      payload.voucher = appliedVouchers.map((el) => el.voucherId);
    }

    return payload;
  };

  return {
    currentClass,
    appliedVouchers,
    promoCode,
    applyPromoCode,
    removePromoCode,
    getSubtotalPrice,
    getTotalPrice,
    getBookingSummary,
    applyVoucher,
    removeVoucher,
  };
};
