import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Select, SelectOption } from "../../components/common/Select";
import { Wapper, Container, ItemBox, ItemImg, ItemContent } from "./Styled";
import { useCategoryListViewModel } from "common/viewModels/FacilityBooking/CategoryListViewModel";
import { FacilityCategory } from "common/models/FacilityCategory";
import CategoryList from "pageComponents/facility/CategoryList";
import { ICategoryListViewModel } from "models";
import PageHeader from "components/common/PageHeader";
import MonthlyProgramBanner from "assets/bg-monthly-program-banner.png";
import { ResponsiveUI } from "utils/responsive";
import { IGlobalProviderContext } from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import PageContainer from "components/common/PageContainer";
import { Modules } from "common/models/Category";

interface FacilityCategoryDisplay {
  id: string;
  nameEn: string;
  nameTc: string;
  img: string;
}

type Props = object;

const MonthlyProgram = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // categoryList for desktop
  // categoryRows for responsive (same as app)
  const { categoryList, categoryRows, getCategoryList } =
    useCategoryListViewModel() as ICategoryListViewModel;

  useEffect(() => {
    getCategoryList(Modules.MONTHLY_PROGRAM);
  }, []);

  return (
    <PageContainer
      title={t("monthlyProgram:monthlyProgramList.screenHeader")}
      banner={MonthlyProgramBanner}
      disableBg
    >
      <CategoryList
        categoryList={categoryList}
        onClickCategory={(facilityCategoryItem) =>
          navigate(facilityCategoryItem._id)
        }
      />
    </PageContainer>
  );
};

export default MonthlyProgram;
