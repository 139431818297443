import {
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import {
  ICoachContextType,
  ICoachLessonBookingConfirmationViewModel,
  ICoachLessonBookingDetailViewModel,
} from "models/PrivateCoach";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import _ from "lodash";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { useCoachLessonBookingDetailViewModel } from "common/viewModels/CoachLessionBooking/CoachLessonBookingDetailViewModel";
import BookingBase, {
  ProgramTypeKeys,
} from "pageComponents/booking/BookingBase";
import Typography from "components/common/Typography";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";
import BookingGuestInvite from "pageComponents/booking/BookingGuestInvite";
import { createPayment } from "utils/common";
import { useCoachLessonBookingConfirmationViewModel } from "common/viewModels/CoachLessionBooking/CoachLessonBookingConfirmationViewModel";
import PromoCodeBox from "components/boxs/PromoCodeBox";
import Section from "pageComponents/booking/Section";
import OrderInfoBase from "pageComponents/booking/OrderInfoBase";
import { useVoucherViewModel } from "common/viewModels/VoucherViewModel";
import { IVoucherViewModel } from "models/Voucher";
import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import { ROUTE_BASE } from "Route";
import { appearGlobalError } from "common/context/requests/globalRequest";
import { confirmModalContext } from "contexts/ConfirmModalProvider";
import { getCorrectUrl } from "navigators/Router";

const CoachingBookingDetailPage = () => {
  const { t } = useTranslation("coaching");
  const { coachId } = useParams();
  const navigate = useNavigate();
  const { bookingInfo, linkedVoucherId } =
    useOutletContext<ICoachContextType>();
  const { currentUser, isRetriving } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const [bindVoucherCompleted, setBindVoucherCompleted] = useState(false);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ bookingInfo:", bookingInfo);
    if (_.isEmpty(bookingInfo)) {
      navigate(-1);
    }
  }, [bookingInfo]);

  const {
    coachLesson,
    selectedDate,
    selectedTimeslots,
    selectedLessonType,
    pricePerLesson,
    bookingIds,
  } = bookingInfo || {};
  const {
    promoCode,
    appliedVouchers,
    guests,
    allowCash,
    // allowVoucher,
    getGuestLimit,
    getBookingDetails,
    getSubtotalPrice,
    getTotalPrice,
    applyPromoCode,
    removePromoCode,
    addGuests,
    removeGuest,
    getBookingSummary,
    applyVoucher,
    removeVoucher,
    allowVoucher,
  } = useCoachLessonBookingDetailViewModel({
    currentUser,
    coachLesson,
    selectedDate,
    selectedTimeslots,
    selectedLessonType,
    pricePerLesson,
    bookingIds,
    dispatch: globalDispatch,
  }) as ICoachLessonBookingDetailViewModel;
  const { createBooking } =
    useCoachLessonBookingConfirmationViewModel() as ICoachLessonBookingConfirmationViewModel;
  const { getBookingVouchers, bookingVouchers } = useVoucherViewModel({
    dispatch: globalDispatch,
  }) as IVoucherViewModel;

  const { openModal } = useContext(confirmModalContext);

  const refreshVoucherList = useCallback(() => {
    if (bookingInfo && currentUser && isRetriving === false) {
      getBookingVouchers({
        bookingType: ProgramTypes.Coach,
        coachlesson: coachLesson?._id,
        coachType: selectedLessonType,
        allowCash,
      });
    }
  }, [
    bookingInfo,
    currentUser,
    isRetriving,
    allowCash,
    coachLesson,
    selectedLessonType,
  ]);

  const _orderInfo = (displayCoupon: boolean) => {
    return (
      <OrderInfoBase
        promoCode={promoCode}
        appliedVoucher={appliedVouchers}
        displayCoupon={displayCoupon}
      >
        <div>
          <Typography fontWeight={500} color={theme.black}>
            {coachLesson?.getName()}
          </Typography>
          <Typography>{`${coachLesson?.coach?.getName()} (${t(
            `coaching:coachLessonType.${selectedLessonType}`
          )})`}</Typography>
          {getBookingDetails()?.map((item, idx) => (
            <Typography key={idx}>{item.info}</Typography>
          ))}
        </div>
      </OrderInfoBase>
    );
  };

  const _guestSection = () => {
    return (
      <BookingGuestInvite
        guests={guests}
        addGuestAvailableCount={getGuestLimit() - guests.length}
        addGuests={addGuests}
        removeGuest={removeGuest}
      />
    );
  };

  const _CouponSection = () => {
    return (
      <Section
        title={`${t("event:booking.promoCode")}/${t("event:booking.voucher")}`}
      >
        <PromoCodeBox
          code={promoCode}
          onApplyPromoCode={applyPromoCode}
          onRemovePromoCode={removePromoCode}
          voucherDisabled={getTotalPrice() <= 0 || !allowVoucher}
          onApplyVoucher={applyVoucher}
          onRemoveVoucher={removeVoucher}
          selectedVouchers={appliedVouchers}
          availableVouchers={bookingVouchers}
          refreshVoucherList={refreshVoucherList}
        />
      </Section>
    );
  };

  const handlePayment = async () => {
    const summary = getBookingSummary();

    // Query the create Booking
    const bookingRequest = {
      ...summary?.payload,
      platform: "WEB",
    };
    const bookingRes = await createBooking(bookingRequest);

    if (bookingRes?.success === true) {
      createPayment(bookingRes, globalDispatch, navigate, getTotalPrice() ?? 0);
    } else if (!bookingRes?.needVerification) {
      // appearGlobalError(globalDispatch, bookingRes?.error?.msg);
      openModal({
        title: bookingRes?.error?.msg,
        onConfirm: () => {
          if (
            !(
              bookingRes?.error?.errorCode === -302 ||
              bookingRes?.error?.errorCode === -303
            )
          ) {
            navigate(-1);
          }
        },
      });
    } else if (bookingRes?.isRedirectHandle) {
      navigate(
        getCorrectUrl(`${ROUTE_BASE.PRIVATE_COACH}/coach/${coachId}/booking`)
      );
    }
  };

  useEffect(() => {
    if (_.isEmpty(bookingInfo)) {
      navigate(-1);
    }
    refreshVoucherList();
  }, [refreshVoucherList, bookingInfo, navigate]);

  /**
   * booking voucher observer for binding voucherId from search query into voucher list
   */
  useEffect(() => {
    if (bindVoucherCompleted === true) return;
    if (bookingVouchers.length <= 0) return;
    if (linkedVoucherId == null) return;
    const matchedVoucher = bookingVouchers.find((each) =>
      each.voucherId.match(linkedVoucherId)
    );
    if (matchedVoucher == null) return;

    applyVoucher(matchedVoucher);
    /** only do once after list is fetched */
    setBindVoucherCompleted(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    linkedVoucherId,
    bookingVouchers,
    bindVoucherCompleted,
    setBindVoucherCompleted,
  ]);

  return (
    <BookingBase
      programTypes={ProgramTypes.Coach as ProgramTypeKeys}
      orderInfoComponent={_orderInfo(false)}
      // remarks={facility?.remarks}
      // tnc={facility?.tandc}
      totalAmount={getTotalPrice() ?? 0}
      receiptComponent={_orderInfo(true)}
      handlePayment={handlePayment}
    >
      {_CouponSection()}
      {getGuestLimit() > 0 && _guestSection()}
    </BookingBase>
  );
};

export default CoachingBookingDetailPage;
