import { useContext } from "react";
import {
  AuthTypes,
  IChangePasswordFormSubmit,
  IEmailForgetPasswordFormSubmit,
  IEmailLoginSubmit,
  IMobileForgetPasswordFormSubmit,
  IMobileLoginSubmit,
  LoginPlatforms,
  LoginRequestProps,
} from "../../models/User/Auth";
import {
  ChangePassword as ChangePasswordApi,
  forgetPassword,
  login as loginApi,
} from "../../network/auth";
import { getE164Format } from "../../utils/phoneHelper";
import { useCommonFetcher } from "../Common/CommonViewModel";
import { UserViewModelContext, UserViewModelProvider } from "../UserViewModel";
import { GlobalContextStore } from "../../context/providers/globalProvider";
import {
  appearGlobalLoading,
  disappearGlobalLoading,
} from "../../context/requests/globalRequest";
import { IGlobalProviderContext } from "../../models/Common";
import i18n from "../../../i18n";

export const useAuthViewModel = () => {
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { fetcher } = useCommonFetcher();
  const { onAppLoginSuccess } = useContext(UserViewModelContext);

  const login = async (payload: LoginRequestProps) => {
    appearGlobalLoading(globalDispatch);

    const loginRes = await fetcher({
      api: () => loginApi(payload),
      skipLoading: true,
    });

    if (loginRes.success) {
      const getUserRes = await onAppLoginSuccess({
        ...loginRes,
        password: payload.password,
      });
      disappearGlobalLoading(globalDispatch);
      return getUserRes;
    }
    disappearGlobalLoading(globalDispatch);
    return loginRes;
  };

  const loginWithEmail = async ({
    email,
    password,
    platform = LoginPlatforms.WEB,
  }: IEmailLoginSubmit) => {
    const res = await login({
      accountType: "email",
      email,
      password,
      lang: "en",
      platform, // "app" | "web"
    });

    return res;
  };

  const loginWithMobile = async ({
    country,
    phoneNumber,
    password,
    platform = LoginPlatforms.WEB,
  }: IMobileLoginSubmit) => {
    const mobile = getE164Format(phoneNumber, country);

    const res = await login({
      accountType: "email",
      mobile,
      password,
      lang: "en",
      platform,
    });

    return res;
  };

  const forgetPasswordWithEmail = async ({
    email,
  }: IEmailForgetPasswordFormSubmit) => {
    const res = await fetcher({
      api: () =>
        forgetPassword({
          email,
          accountType: "email",
        }),
    });

    return res;
  };

  const forgetPasswordWithMobile = async ({
    country,
    phoneNumber,
  }: IMobileForgetPasswordFormSubmit) => {
    const mobile = getE164Format(phoneNumber, country);

    const res = await fetcher({
      api: () =>
        forgetPassword({
          mobile,
          accountType: "email",
          lang: i18n.language === "zh" ? "tc" : "en",
        }),
    });

    return res;
  };

  const changePassword = async (payload: IChangePasswordFormSubmit) => {
    const res = await fetcher({ api: () => ChangePasswordApi(payload) });
    return res;
  };

  return {
    loginWithEmail,
    loginWithMobile,
    forgetPasswordWithEmail,
    forgetPasswordWithMobile,
    changePassword,
  };
};
