import { PushNotificationMessage } from "../Notification";


// currently only order_id is useful
type AdditionalAttributes = {
  order_id?: string, 
  headline_news_id?: string, 
  user_id?: string, 
}

export enum NotificationCategory {
  FACILITY_BOOKING = "FACILITY_BOOKING",
  PRIVATE_COACHING = "PRIVATE_COACHING",
  MONTHLY_PROGRAMME = "MONTHLY_PROGRAMME",
  EVENT = "EVENT",
  HEADLINE_NEWS = "HEADLINE_NEWS",
  APP_USER = "APP_USER",
  LOCKER = "LOCKER",
}

export enum NotificationCategoryTag {
  EventBooking = "EVENT_BOOKING",
  FacilityBooking = "FACILITY_BOOKING",
  MonthlyProgramme = "MONTHLY_PROGRAMME",
  PrivateCoaching = "PRIVATE_COACHING",
  Others = "OTHERS",
}

export interface INotificationHistory {
  _id: string;
  message?: PushNotificationMessage;
  notification_category?: NotificationCategory;
  sent_at: string;
  additional?: AdditionalAttributes;

  readonly notification_category_tag?: NotificationCategoryTag;
}

export class NotificationHistory implements INotificationHistory {
  _id: string;
  message?: PushNotificationMessage;
  notification_category?: NotificationCategory;
  sent_at: string;
  additional?: AdditionalAttributes;

  constructor({
    _id,
    message,
    notification_category,
    sent_at,
    additional,
  }: any) {
    this._id = _id;
    this.message = message ? new PushNotificationMessage(message) : undefined;
    this.notification_category = notification_category;
    this.sent_at = sent_at;
    this.additional = additional;
  }

  get notification_category_tag(): NotificationCategoryTag | undefined {
    if (!this.notification_category) return undefined;
    switch (this.notification_category) {
      case NotificationCategory.FACILITY_BOOKING:
        return NotificationCategoryTag.FacilityBooking;
      case NotificationCategory.EVENT:
        return NotificationCategoryTag.EventBooking;
      case NotificationCategory.MONTHLY_PROGRAMME:
        return NotificationCategoryTag.MonthlyProgramme;
      case NotificationCategory.PRIVATE_COACHING:
        return NotificationCategoryTag.PrivateCoaching;
      default:
        return NotificationCategoryTag.Others;
    }
  }
}
