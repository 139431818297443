import React, { useMemo } from "react";
import { InferType, date, object, string, mixed } from "yup";
import { Gender } from "../../../models/Gender";
import { UserTitle } from "common/models/User";
import { validatePassword } from "common/utils/stringHelper";
import { MobileVerificationResult } from "common/models/Verification";

const newUserYupSchema = object({
  profile_pic_ref: string(),
  profile_pic_file: mixed(),
  title: string().oneOf(Object.values(UserTitle)).required(),
  username: string().required(),
  enName: string().required(),
  zhName: string(),
  dateOfBirth: string().required(),
  gender: string().oneOf(Object.values(Gender)).nullable(),
  phoneNum: string().required(),
  district: string(),
  email: string().email().required(),
  password: string()
    .required()
    .test("valid-password", "not valid password", (value) => {
      return !!validatePassword(value);
    }),
});

export type NewUser = InferType<typeof newUserYupSchema>;

interface IintialValue extends MobileVerificationResult {
  email: string;
  password: string;
}

export const useUserRegistrationFormModel = (value?: IintialValue) => {
  const formInitialValues = useMemo(
    () => ({
      profile_pic_ref: "",
      profile_pic_file: {},
      username: "",
      enName: "",
      zhName: "",
      dateOfBirth: "",
      title: UserTitle.Mr,
      gender: null,
      district: "",
      phoneNum: value?.parsedPhoneNum ?? "",
      email: value?.email ?? "",
      password: value?.password ?? "",
    }),
    [value]
  );

  return { formInitialValues, formValidateSchema: newUserYupSchema };
};
