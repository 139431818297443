import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import ProcessingPayment from "./ProcessingPayment";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFail from "./PaymentFail";
import PaymentScreen from "./PaymentScreen";
import { ROUTE_BASE } from "Route";

const PaymentRouter: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <PaymentScreen />,
      children: [
        {
          path: "/:id",
          element: <ProcessingPayment />,
        },
        {
          path: "/:id/success",
          element: <PaymentSuccess />,
        },
        {
          path: "/:id/fail",
          element: <PaymentFail />,
        },
        {
          path: "/",
          element: <Navigate to={`/${ROUTE_BASE.MY_BOOKINGS}`} />,
        },
        {
          path: "*",
          element: <Navigate to={`/${ROUTE_BASE.MY_BOOKINGS}`} />,
        },
      ],
    },
  ]);
};
export default PaymentRouter;
