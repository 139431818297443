import CommonRoundedCornerCard from "components/common/CommonRoundedCornerCard";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Styled from "./styled";
import MyDivider from "components/common/Divider";
import Space from "components/common/Space";
import Typography from "components/common/Typography";
import theme from "utils/theme";

const MENU_LIST = [
  { title: "generalEnquiry.faq", path: "general/faq" },
  { title: "generalEnquiry.aiChatbot", path: "general/chatbot" },
];

const EnquiryNav = () => {
  const { t, i18n } = useTranslation("profile");
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <CommonRoundedCornerCard>
      <Styled.Container>
        <div className="w-full flex justify-center mb-[23px] md:justify-start">
          <Typography fontSize="20px" fontWeight={400} color={theme.black}>
            {t("profile:index.generalEnquiry")}
          </Typography>
          <Space width="5px" />
        </div>

        {MENU_LIST.length > 0 && <MyDivider style={{ width: "100%" }} />}
        <Space size="23px" />

        <div className="flex flex-col gap-[23px] w-full text-center md:text-start">
          {MENU_LIST.map((item) => (
            <Styled.MenuButton
              key={item.title}
              selected={location?.pathname?.includes(item.path)}
              onClick={() =>
                navigate(`/${i18n.language}/${item.path}`, {
                  state: { selected: true },
                })
              }
            >
              {t(`profile:${item.title}`)}
            </Styled.MenuButton>
          ))}
        </div>
      </Styled.Container>
    </CommonRoundedCornerCard>
  );
};

export default EnquiryNav;
