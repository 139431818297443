import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import ProfileScreen from "./ProfileScreen";
import MySportPassScreen from "./MySportPassScreen";
import PersonalInfo from "./PersonalInfo";
import GuardianList from "./GuardianList";
import MyBookingScreen from "pages/MyBookingScreen/MyBookingScreen";
import ProfileNav from "pageComponents/profile/ProfileNav";
import { ResponsiveUI } from "utils/responsive";
import HelpCenter from "./HelpCenter";
import useAuthUser from "hooks/useAuthUser";
import Card from "components/common/Card";
import GuestLoginCard from "pageComponents/profile/GuestLoginCard";
import { ROUTE_BASE } from "Route";
import AccountVerification from "./AccountVerification";
import MobileVerification from "./MobileVerification";

const ProfileRouter: React.FC = () => {
  const { isGuest } = useAuthUser();
  return useRoutes([
    {
      path: "/",
      element: <ProfileScreen />,
      children: [
        {
          path: "/",
          element: (
            <ResponsiveUI
              desktop={isGuest ? <GuestLoginCard /> : <></>}
              mobile={<ProfileNav />}
            />
          ),
        },
        {
          path: "/edit",
          element: <PersonalInfo />,
        },
        {
          path: "/verification",
          element: <AccountVerification />,
        },
        {
          path: "/mobile-verification",
          element: <MobileVerification />,
        },
        // {
        //   path: "/my-sport-pass",
        //   element: <MySportPassScreen />,
        // },
        {
          path: "/guardian",
          element: <GuardianList />,
        },
        /* {
          path: "/help",
          element: <HelpCenter />,
        }, */
        {
          path: "*",
          element: <Navigate to={`/${ROUTE_BASE.PROFILE}`} />,
        },
      ],
    },
  ]);
};

export default ProfileRouter;
