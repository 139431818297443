import {
  APPEAR_GLOBAL_ERROR,
  DISAPPEAR_GLOBAL_ERROR,
  APPEAR_GLOBAL_LOADING,
  DISAPPEAR_GLOBAL_LOADING,
  APPEAR_ACCOUNT_VERIFICATION,
  DISAPPEAR_ACCOUNT_VERIFICATION,
  SET_KIOSK_CERT,
  PUSH_GLOBAL_BLOCKING,
  POP_GLOBAL_BLOCKING,
  APPEAR_PHONE_NUMBER_VERIFICATION,
  DISAPPEAR_PHONE_NUMBER_VERIFICATION,
} from "../actions/globalAction";

const GlobalReducer = (state, action) => {
  switch (action.type) {
    case APPEAR_GLOBAL_ERROR:
      if (action.payload) {
        return {
          ...state,
          globalError: {
            ...action.payload,
          },
        };
      }
      return {
        ...state,
        globalError: {
          message: action.message,
        },
      };
    case DISAPPEAR_GLOBAL_ERROR:
      return { ...state, globalError: null };
    case APPEAR_GLOBAL_LOADING:
      return { ...state, globalLoading: true };
    case DISAPPEAR_GLOBAL_LOADING:
      return { ...state, globalLoading: false };
    case APPEAR_ACCOUNT_VERIFICATION:
      return {
        ...state,
        accountVerification: {
          isNewUser: action.isNewUser, // true for status NEW, false for status PENDING
        },
      };
    case DISAPPEAR_ACCOUNT_VERIFICATION:
      return { ...state, accountVerification: null };
    case APPEAR_PHONE_NUMBER_VERIFICATION:
      return {
        ...state,
        phoneNumberVerification: true,
      };
    case DISAPPEAR_PHONE_NUMBER_VERIFICATION:
      return {
        ...state,
        phoneNumberVerification: false,
      };
    case SET_KIOSK_CERT:
      return { ...state, kioskCert: action.kioskCert };
    case PUSH_GLOBAL_BLOCKING:
      return {
        ...state,
        globalBlockingStack: [...state.globalBlockingStack, action.blockingFor],
      };
    case POP_GLOBAL_BLOCKING:
      return {
        ...state,
        globalBlockingStack: removeFirstDuplicate(
          state.globalBlockingStack,
          action.blockingFor
        ),
      };
  }
};

const removeFirstDuplicate = (arr, blockingFor) => {
  // Initial array
  let clone = [...arr];

  // searching and removing
  const index = clone.indexOf(blockingFor);
  if (index !== -1) {
    clone.splice(index, 1);
  }
  return clone;
};

const withLogging = (reducer) => {
  return (state, action) => {
    console.log("Action dispatched:", action);
    console.log("Previous state:", { ...state });
    const newState = reducer(state, action);
    console.log("New state:", { ...newState });
    return newState;
  };
};

// export default withLogging(GlobalReducer);
export default GlobalReducer;
