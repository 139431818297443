import PageHeader from "components/common/PageHeader";
import ImageBanner from "assets/bg-tnc-banner.png";
import React from "react";
import Space from "components/common/Space";
import { useTranslation } from "react-i18next";

import { ReactComponent as IconFacebook } from "../assets/ic-facebook.svg";
import { ReactComponent as IconInstagram } from "../assets/ic-instagram.svg";
import styled, { css } from "styled-components";
import Typography from "components/common/Typography";
import theme from "utils/theme";
import PageContainer from "components/common/PageContainer";

const ContactUs = () => {
  const { t } = useTranslation("navBar");

  const _urlText = (title: string, urlText: string, url: string) => {
    return (
      <div className="flex">
        <Typography>{`${title}: `}</Typography>
        <Typography
          color={theme.orange}
          style={{
            textDecorationLine: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(url, "_blank");
          }}
        >
          {urlText}
        </Typography>
      </div>
    );
  };
  return (
    <PageContainer
      title={t("footer.contactSaiShaSportClub")}
      banner={ImageBanner}
    >
      <Typography>{t("contactUs:title")}</Typography>

      <Space size="30px" />

      <div>
        {_urlText(
          "Instagram",
          "@goparksaisha",
          "https://www.instagram.com/goparksaisha/?hl=en"
        )}
        {_urlText(
          "Facebook",
          "GO PARK Sai Sha",
          "https://www.facebook.com/goparksaishahk/"
        )}
        <div className="flex">
          <Typography>{t("contactUs:email")}</Typography>
          <Typography
            color={theme.orange}
            style={{
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location.href = "mailto:enquiry@goparksports.hk";
            }}
          >
            enquiry@goparksports.hk
          </Typography>
        </div>
        <Typography>
          {t("contactUs:address", {
            address: "9 Hoi Ying Road, Tai Po, New Territories, Hong Kong",
          })}
        </Typography>
      </div>
    </PageContainer>
  );
};

const baseStyles = css`
  cursor: pointer;
  width: 48px;
  height: 48px;
  path {
    fill: black;
  }
`;

const StyledFacebook = styled(IconFacebook)`
  ${baseStyles}
`;
const StyledInstagram = styled(IconInstagram)`
  ${baseStyles}
`;

export default ContactUs;
