import { authedInstance, instance } from ".";
import { CoachLesson } from "../models/CoachLesson/CoachLesson";
import { CoachLessonInfoResponse } from "../models/CoachLesson/CoachLessonInfoResponse";
import { Timeslot } from "../models/Timeslot";
import { getLocalisedString } from "../utils/stringHelper";

export async function listCoachLesson() {
  const res = await instance.get("/v1/app/coach/lesson");

  return res.data?.result?.map((coachLesson) => new CoachLesson(coachLesson));
}

export async function listCoachLessonByCategory({ categoryId }) {
  const res = await instance.get(
    `/v1/app/coach/lesson?${new URLSearchParams({
      category_list: categoryId,
    })}`
  );

  console.log("List Coach Lesson By Category: ", res.data.result);
  return res.data?.result?.map((coachLesson) => new CoachLesson(coachLesson));
}

export async function getCoachLessonDetail({ coachLessonId, selectedDate }) {
  const res = await instance.post(`/v1/app/coach/${coachLessonId}`, {
    selected_date: selectedDate,
  });

  return res?.data;
}

export async function queryCoachLessonTimeslot({ coachLessonId, date }) {
  console.log(coachLessonId, date);
  const res = await authedInstance.get(
    `/v1/app/coach/timeslot/${coachLessonId}?${new URLSearchParams({
      selected_date: date,
    })}`
  );
  console.log(res);

  return res.data?.result?.map((timeslot) => new Timeslot(timeslot));
}

export async function holdCoachTimeslot({
  coachLessonId,
  selectedDate,
  lessonType,
  selectedTimeslots,
}) {
  const reqBody = {
    lesson_id: coachLessonId,
    reserved_date: selectedDate,
    lessonType,
    coach_booking: selectedTimeslots,
  };

  console.log("Hold timeslot: ", reqBody);
  const res = await authedInstance.post("/v1/app/coach/booking/hold", reqBody);

  console.log(res.data);
  // Return booking ids
  return res?.data?.result?.assignVenueResult?.map((booking) => booking?._id);
}

export async function createCoachBooking(payload) {
  console.log("Create Coach Booking: ", payload);
  const res = await authedInstance.post("/v1/app/coach/lesson/book", payload);
  console.log(res);
  return res?.data;
}

export async function listCoachBooking(queryParams) {
  try {
    console.log("List My Coach Booking", queryParams);
    let apiEndpoint = "/v1/app/coach/list/coachLessonBooking";
    if (queryParams != null) {
      apiEndpoint += `?${new URLSearchParams(queryParams).toString()}`;
    }
    const res = await authedInstance.get(apiEndpoint);
    return res.data;
  } catch (err) {
    throw err;
  }
}

export async function listConfirmedCoachBooking() {
  try {
    // Upcomming Booking: Confirmed + Reserved Date >= today
    const res = await listCoachBooking({
      listByTime: "UPCOMING", // Upcoming Booking
      sort: "expiryTime", // sort by expiry time
      order: "asc",
    });
    return res;
  } catch (err) {
    throw err;
  }
}

export async function listPastCoachBooking({ status }) {
  try {
    // Upcomming Booking: Confirmed / Cancelled + Reserved Date < today
    const res = await listCoachBooking({
      status,
      listByTime: "PAST", // Past Booking
      sort: "expiryTime", // sort by expiry time
      order: "desc",
    });
    return res;
  } catch (err) {
    throw err;
  }
}

export async function getCoachBookingDetails({ orderId }) {
  try {
    const res = await authedInstance.get(
      `/v1/app/coach/coachLessonBooking/${orderId}`
    );
    return res.data.result;
  } catch (err) {
    throw err;
  }
}

export async function updateCoachLessonGuestlist({ orderId, guestlist }) {
  try {
    const res = await authedInstance.post(
      "/v1/app/coachLessonBooking/updateGuestList",
      {
        order_id: orderId,
        guestlist,
      }
    );
    return res;
  } catch (err) {
    let errorMsg = getLocalisedString(
      err?.response?.data?.error?.localizedMessage?.en,
      err?.response?.data?.error?.localizedMessage?.zh
    );
    throw new Error(errorMsg);
  }
}

export async function cancelCoachBooking({ orderId }) {
  try {
    const res = await authedInstance.post(
      `/v1/app/cancel/coachLessonBooking/${orderId}`
    );
    console.log("Cancel booking: ", res);
    return res.data;
  } catch (err) {
    throw err;
  }
}
