import { useContext, useMemo, useState } from "react";
import { IGlobalProviderContext } from "../models/Common";
import { GlobalContextStore } from "../context/providers/globalProvider";
import { AxiosError } from "axios";
import {
  appearGlobalError,
  appearGlobalLoading,
  disappearGlobalLoading,
} from "../context/requests/globalRequest";
import { getLocalisedString } from "../utils/stringHelper";
import {
  register as registerAPI,
  validateLoginInfo as validateLoginInfoAPI,
} from "../network/auth";
import { IRegistrationFormSubmit, IValidateLoginInfo } from "../models/User";
import { useCommonFetcher } from "./Common/CommonViewModel";

export const useRegisterAccountViewModel = () => {
  const { fetcher } = useCommonFetcher();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );

  const [profile_pic_ref, setProfileImageRef] = useState(null);
  const [username, setUsername] = useState(null);
  const [enName, setEnName] = useState(null);
  const [zhName, setZhName] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [title, setTitle] = useState("");
  const [gender, setGender] = useState(null);
  const [district, setDistrict] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const registerAccountForm = useMemo(
    () => ({
      profile_pic_ref: profile_pic_ref ?? "",
      username: username ?? "",
      enName: enName ?? "",
      zhName: zhName ?? "",
      dateOfBirth: dateOfBirth ?? null,
      title: title ?? "",
      gender: gender ?? null,
      district: district ?? "",
      phoneNum: phoneNum ?? "",
      email: email ?? "",
      password: password ?? "",
    }),
    [
      profile_pic_ref,
      username,
      enName,
      zhName,
      dateOfBirth,
      title,
      gender,
      district,
      phoneNum,
      email,
      password,
    ]
  );

  const register = async (registrationForm: IRegistrationFormSubmit) => {
    const res = await fetcher({
      api: () =>
        registerAPI({
          ...registrationForm,
          preferredLanguage: "en",
          isVip: false,
          accountType: "email",
        }),
    });

    if (res?.success) {
      return res;
    }
  };

  const validateLoginInfo = async (payload: IValidateLoginInfo) => {
    const res = await fetcher({ api: () => validateLoginInfoAPI(payload) });

    return res;
  };

  return {
    registerAccountForm,
    setProfileImageRef,
    setUsername,
    setEnName,
    setZhName,
    setDateOfBirth,
    setTitle,
    setGender,
    setDistrict,
    setPhoneNum,
    setEmail,
    setPassword,
    register,
    validateLoginInfo,
  };
};
