import React, { useState, useEffect, useRef, useCallback } from "react";
import Styled from "../Styled";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { getLocalisedString } from "common/utils/stringHelper";
import { EventType, FacilityCategory } from "models";
import IconLoader from "../../../assets/IconLoader";
import EventTypeAllOn from "assets/event-type-all-on.svg";
import EventTypeAllOff from "assets/event-type-all-off.svg";
import CategoryFilterView, {
  CategoryFilterViewProps,
} from "./CategoryFilterView";
import Typography from "components/common/Typography";
import SearchInput, { ClearSvg } from "components/common/SearchInput";
import Space from "components/common/Space";
import theme from "utils/theme";

interface OnFilter {
  eventTypeList: EventType[];
  categoryList: FacilityCategory[];
  activeEventTypeId?: string | null;
  activeCategoryId?: string | null;
  searchText?: string;
  enableFilter: boolean;
  onFilterTag: (selectedType: EventType | null) => void | Promise<void>;
  onFilterCategory: CategoryFilterViewProps["onFilterCategory"];
  onSearchClick?: (args: { search: string }) => void | Promise<void>;
}

const EventFilter: React.FC<OnFilter> = ({
  eventTypeList,
  categoryList,
  activeEventTypeId,
  activeCategoryId,
  searchText,
  enableFilter,
  onFilterTag,
  onFilterCategory,
  onSearchClick,
}) => {
  const { t } = useTranslation("common");

  const textInputRef = useRef<HTMLInputElement | null>(null);
  const elementARef = useRef<HTMLDivElement | null>(null);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [hovered, setHovered] = useState<string>("");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        elementARef.current &&
        !elementARef.current.contains(event.target as Node)
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [elementARef]);

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleSubmit = useCallback(() => {
    let search = "";
    if (textInputRef != null) {
      search = textInputRef.current?.value || "";
    }
    if (onSearchClick) {
      onSearchClick({ search });
    }
  }, [textInputRef, onSearchClick]);

  const handleClear = useCallback(() => {
    if (textInputRef.current?.value != null) {
      textInputRef.current.value = "";
      if (onSearchClick) {
        onSearchClick({ search: "" });
      }
    }
  }, [textInputRef, onSearchClick]);

  useEffect(() => {
    if (searchText != null && textInputRef != null && textInputRef.current) {
      textInputRef.current.value = searchText;
    }
  }, [searchText, textInputRef]);

  const _categoryFilter = () => (
    <Styled.FilterWrapper ref={elementARef}>
      <Styled.FilterIcon onClick={handleShowFilter}>
        <IconLoader.IconFilter />
      </Styled.FilterIcon>
      <CategoryFilterView
        show={showFilter}
        categoryList={categoryList}
        activeCategoryId={activeCategoryId}
        onCloseFilterView={() => setShowFilter(false)}
        onFilterCategory={onFilterCategory}
      />
    </Styled.FilterWrapper>
  );

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="relative mb-4 flex pr-[10px]">
          <SearchInput
            ref={textInputRef}
            placeholder={t("event:filter.inputPlaceholder")}
            onClickClear={handleClear}
          />
          <div className="md:hidden ml-[10px]">
            {enableFilter && _categoryFilter()}
          </div>
        </div>
        <Styled.Button
          type="primary"
          className="button-search"
          onClick={handleSubmit}
        >
          {t("event:filter.buttonSearch")}
        </Styled.Button>

        <div className="hidden md:block">
          {enableFilter && _categoryFilter()}
        </div>
      </div>

      <Styled.Overlay className={showFilter ? "" : "hide"}></Styled.Overlay>

      <Styled.TagWrapper>
        <Styled.Tag
          onClick={() => onFilterTag(null)}
          onMouseOver={() => setHovered("all")}
          onMouseOut={() => setHovered("")}
          className={!activeEventTypeId ? "active" : ""}
        >
          {t("event:category.all")}
        </Styled.Tag>
        {_.map(eventTypeList, (tag, index) => (
          <Styled.Tag
            key={tag._id}
            onClick={() => onFilterTag(tag)}
            onMouseOver={() => setHovered(tag._id)}
            onMouseOut={() => setHovered("")}
            className={tag._id === activeEventTypeId ? "active" : ""}
          >
            {tag.thumbnail && (
              <img
                src={
                  tag._id === activeEventTypeId || hovered === tag._id
                    ? tag.thumbnailSelected
                    : tag.thumbnail
                }
                className="mr-[10px] w-[20px] h-[20px]"
              />
            )}
            {getLocalisedString(tag.name_en, tag.name_tc)}
          </Styled.Tag>
        ))}
      </Styled.TagWrapper>
    </>
  );
};

export default EventFilter;
