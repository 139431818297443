import RegisterPageContainer from "pageComponents/register/RegisterPageContainer";
import cn from "classnames";
import UserVerificationForm from "components/form/UserVerificationForm/UserVerificationForm";
import { useContext, useEffect, useRef, useState } from "react";
import { NewUser } from "components/form/UserVerificationForm/UserVerificationFormModel";
import { ICurrentUser, IUserViewModelContext } from "../../@types/common";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import dayjs from "common/utils/dayjs";
import { useProfileInfoViewModel } from "common/viewModels/myProfile/UpdateProfileInfoViewModel";
import { IGlobalProviderContext } from "common/models/Common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import AccountStatusPrompt from "pageComponents/register/AccountStatusPrompt";
import { ROUTE_BASE } from "Route";
import { useTranslation } from "react-i18next";
import SimpleTextModal from "components/common/Modal/SimpleTextModal";
import { isEmptyValues } from "common/utils/common";
import { useUploadFile } from "hooks/useUploadFile";

const AccountVerification = () => {
  const $formRef = useRef(null);
  const { i18n, t } = useTranslation("profile");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({
    token: "",
    hideMenu: "",
  });
  const token = searchParams.get("token"); //token for app use
  const hideMenu = searchParams.get("hideMenu"); //hidemenu for verify
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { requestPresignedURLByFile } = useUploadFile();
  const { currentUser, isRetriving } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { verifyUserInfo, getUserInfoWithToken } = useProfileInfoViewModel();
  const [userInfo, setUserInfo] = useState<ICurrentUser | null>(null);
  const [isOpenResultModal, setIsOpenResultModal] = useState<boolean>(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<boolean>(false);

  useEffect(() => {
    const getUser = async () => {
      const res = await getUserInfoWithToken(token, globalDispatch);
      if (res?.success) {
        setUserInfo(res?.user);
      } else if (res?.isTokenExpired) {
        setIsOpenErrorModal(true);
      }
    };

    if (token && hideMenu === "true") {
      getUser();
    } else if (!_.isEmpty(currentUser)) {
      setUserInfo(currentUser);
    } else {
      handleBack();
    }
  }, []);

  const handleVerification = async (user: NewUser) => {
    let profile_pic = undefined;
    if (
      !isEmptyValues(user.profile_pic_ref) &&
      !isEmptyValues(user.profile_pic_file)
    ) {
      const res = await requestPresignedURLByFile(
        user.profile_pic_file as File,
        user.profile_pic_ref as string
      );

      if (res?.success) profile_pic = res?.ref_url;
    }

    const payload = {
      username: user?.username,
      phoneNum: user?.phoneNum ?? "",
      address: user?.district ?? "",
      title: user?.title,
      name_en: user?.enName,
      name_zh: user?.zhName,
      gender: user?.gender ? (user?.gender === "female" ? "F" : "M") : "",
      birthday: dayjs(user?.dateOfBirth)
        .tz()
        .format("YYYY-MM-DD"),
      profile_pic_ref: profile_pic,
    };

    console.log("submit verify user", payload);
    const res = await verifyUserInfo(payload, globalDispatch, token);

    if (res?.success) {
      setIsOpenResultModal(true);
    } else if (res?.isTokenExpired) {
      setIsOpenErrorModal(true);
    }
  };

  const handleBack = () => {
    navigate(`/${i18n.language}/${ROUTE_BASE.PROFILE}/edit`);
  };

  const handleTokenExpired = () => {
    setIsOpenErrorModal(false);
    navigate(`/${i18n.language}/auth/sign-in`);
  };

  return (
    <RegisterPageContainer fullScreen>
      <div
        className={cn(
          "w-full",
          "h-auto",
          "pt-[25px] pb-[46px]",
          "bg-[#fff]",
          "overflow-y-auto",
          "rounded-[50px]"
        )}
      >
        {/* Form */}
        <div className="w-full h-[calc(100%-50px)] overflow-y-auto">
          <UserVerificationForm
            ref={$formRef}
            userInfo={userInfo}
            onSubmit={(user) => handleVerification(user)}
            onBack={handleBack}
          />
        </div>
      </div>

      <AccountStatusPrompt
        isOpen={isOpenResultModal}
        extraOnClose={() => {
          setIsOpenResultModal(false);
          handleBack();
        }}
        isPending
      />

      <SimpleTextModal
        content={t("personalInfo.tokenExpiredError")}
        open={isOpenErrorModal}
        onClose={handleTokenExpired}
      />
    </RegisterPageContainer>
  );
};

export default AccountVerification;
