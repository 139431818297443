import styled from "styled-components";
import { breakpoint, size } from "../../utils/responsive";

const Logo = styled.img`
  width: 193px;
`;

const DecorateImg = styled.img`
  height: 100%;
  width: 100%;
`;

const PendingApprovalTitle = styled.span`
  color: var(--palette-ss-000, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${breakpoint.desktop} {
    font-size: 31px;
  }
`;

const PendingApprovalBody = styled.span`
  width: 100%;
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (min-width: ${size.screen1024}px) {
    width: 384px;
    font-size: 18px;
  }
`;

const FormSelectHoc = (Select: React.FC<any>) => styled(Select)`
  & .MuiInputBase-root {
    /* border-color: var(--palette-ss-black) !important; */
  }
  & .MuiInputBase-input {
    padding: 9.3px 14px !important;
  }
`;

export default {
  Logo,
  DecorateImg,
  PendingApprovalTitle,
  PendingApprovalBody,
  FormSelectHoc,
};
