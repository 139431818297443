import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";
import theme from "utils/theme";

export const Wapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  border-radius: 30px;
  flex-wrap: nowrap;
  flex-direction: column;
  z-index: 2;
  font-family: Poppins;

  @media ${breakpoint.mobile} {
    padding: 0 20px;
  }
`;

export const ContentWrapper = styled.div`
  border-radius: 30px;
  min-height: 1000px;
  width: 100%;
  display: flex;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 30px;
  /*   align-self: self-start;
  margin-left: 50px;
  margin-bottom: 26px; */
`;

export const Container = styled.div`
  width: 100%;
  background-color: #eff0e8;
  padding: 100px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  @media ${breakpoint.mobile} {
    padding: 30px 0px;
    gap: 10px;
  }
`;

export const ItemBox = styled.div`
  width: 210px;
  height: 210px;
  overflow: hidden;
  position: relative;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }

  @media ${breakpoint.mobile} {
    width: 110px;
    height: 100px;
  }
`;

export const ItemImg = styled.div<{ bg: string }>`
  width: 170px;
  height: 170px;
  background-image: url(${(p) => p.bg});
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 40px;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
    border-radius: 40px;
  }

  @media ${breakpoint.mobile} {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    &:before {
      border-radius: 20px;
    }
  }
`;

export const ItemContent = styled.div`
  height: 100%;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  cursor: pointer;
  text-align: center;
  @media ${breakpoint.mobile} {
    font-size: 14px;
  }
`;

export const SearchInput = styled.input`
  width: 250px;
  height: 50px;
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  padding: 0px 15px;
  @media ${breakpoint.mobile} {
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const Button = styled.button<{ type: string }>`
  padding: 0px 30px;
  height: 50px;
  border-radius: 10px;
  color: ${(p) => (p.type !== "primary" ? "#517E93" : "#fff")};
  background: ${(p) => (p.type !== "primary" ? "#fff" : "#517E93")};
  border: 1px solid #517e93;
  margin: 0px 20px 0px 10px;

  &.button-search {
    @media ${breakpoint.mobile} {
      width: calc(100% - 70px);
      margin-left: 0px;
    }
  }
`;

export const FilterIcon = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: ${theme.seaBlue};
  color: #fff;
  text-align: center;
  svg {
    display: inline;
  }
`;

export const FilterWapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const ImgIcon = styled.img`
  display: inline;
`;

export const FilterViewWapper = styled.div`
  font-family: Poppins;
  display: block;
  position: relative;
  width: 630px;
  padding: 20px 35px;
  margin-top: 20px;
  left: 0px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
  z-index: 10;
  &.hide {
    display: none;
  }

  .group-button-action {
    position: static;
  }

  @media ${breakpoint.mobile} {
    width: 100%;
    position: fixed;
    height: 70vh;
    bottom: 0;
    z-index: 5;
    padding-bottom: 100px;
    overflow-y: auto;
    animation: showFilter 0.5s;

    .group-button-action {
      position: fixed;
      bottom: 15px;
      width: 100%;
      left: 0;
      text-align: center;
    }

    @keyframes showFilter {
      from {
        transform: translateY(100%);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
`;

export const Category = styled.span`
  float: left;
  cursor: pointer;
  color: #000;
  padding: 0px 20px;
  border-radius: 30px;
  height: 45px;
  line-height: 45px;
  background-color: #e3e3e3;
  font-size: 18px;
  &:hover,
  &.active {
    color: #fff;
    background-color: ${theme.orange};
  }
`;

export const Row = styled.div<{
  display?: string;
  direction?: string;
  gap?: string;
  wrap?: string;
  content?: string;
  margin?: string;
}>`
  display: ${(p) => (p.display ? p.display : "block")};
  margin: ${(p) => (p.margin ? p.margin : "30px 0px")};
  width: 100%;
  flex-direction: ${(p) => (p.direction ? p.direction : "row")};
  gap: ${(p) => (p.gap ? p.gap : "10px")};
  flex-wrap: ${(p) => (p.wrap ? p.wrap : "nowrap")};
  justify-content: ${(p) => (p.content ? p.content : "flex-start")};
`;

export const Box = styled.div<{ width?: string }>`
  width: ${(p) => (p.width ? p.width : "100%")};
  &.linked {
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 2px;
      background-color: #000000;
    }
  }
`;

export const Label = styled.span``;

export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  float: left;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
`;

export const BoxItem = styled.div`
  display: block;
  width: calc(25% - 22.5px);
  height: 330px;
  background: #fff;
  border-radius: 10px;
  border-width: 0px 4px 4px 0px;
  border-style: solid;
  border-color: ${theme.orange};
  transition: all 0.2s;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
  }

  @media (max-width: 1199px) {
    width: calc(33.33% - 22.5px);
  }

  @media (max-width: 991px) {
    width: calc(50% - 20px);
  }

  @media ${breakpoint.mobile} {
    width: 100%;
    height: 250px;
    &:hover {
      box-shadow: none;
      transform: scale(1);
    }
  }
`;

export const ItemContentBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 25px;
`;

export const ItemTitle = styled.h3`
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  overflow: hidden;
`;

export const ItemDes = styled.div`
  color: #4a4a4a;
`;

export const ItemLink = styled.a`
  display: flex;
  gap: 5px;
  align-items: center;
  color: #0096b7;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
`;

export const Paging = styled.div`
  display: block;
  text-align: center;
  margin: 50px 0px;
  width: 100%;
  float: left;
`;

export const PagingItem = styled.span`
  display: inline-block;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 38px;
  border: 1px solid #000;
  margin: 0px 5px;
  border-radius: 10px;
  cursor: pointer;
  &.active,
  &:hover {
    background: ${theme.orange};
    border-color: ${theme.orange};
    color: #fff;
  }

  &.active {
    cursor: default;
  }
  &.hide {
    display: none;
  }
`;

export const SelectClassBox = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 30px 40px;
  height: fit-content;
  overflow: visible;

  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;
