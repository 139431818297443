import React, { useContext, useEffect, useState } from "react";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { IUserViewModelContext } from "../@types/common";

export const useSplashScreen = () => {
  const { isRetriving } =
    useContext<IUserViewModelContext>(UserViewModelContext);

  return {
    isSplashScreen: isRetriving === true,
  };
};
