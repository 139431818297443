import React, { Children } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from "lodash";
import { WindowSize } from "utils/responsive";

const settings = {
  //centerMode: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 860,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

type CarouselProps = {
  item?: string[];
} & React.HTMLAttributes<HTMLElement>;
const Carousel = ({ item, children }: CarouselProps) => {
  return (
    <Slider {...settings}>
      {item &&
        _.map(item, (slide) => (
          <div className="img-box h-full">
            <img src={slide} className="max-h-[400px] m-auto" />
          </div>
        ))}
      {children}
    </Slider>
  );
};

export default Carousel;
