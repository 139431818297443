import { Facility } from "./Facility";
import { Venue } from "./Venue";

export class FacilityBooking {
  constructor({
    _id,
    facility_id,
    venue_id,
    status,
    reserved_date,
    start_time,
    end_time,
    isPeakHour,
    amount,
    attendees,
    guestlist,
  }) {
    this._id = _id;
    this.facility = facility_id ? new Facility(facility_id) : null;
    this.venue = venue_id ? new Venue(venue_id) : null;
    this.status = status;
    this.reserved_date = reserved_date;
    this.start_time = start_time;
    this.end_time = end_time;
    this.isPeakHour = isPeakHour;
    this.amount = amount;
    this.attendees = attendees;
    this.guestlist = guestlist;
  }
}
