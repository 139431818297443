import { useEffect, useState } from "react";
import {
  getGolfMapByFacility,
  listVenueByFacility,
} from "../../network/facility";
import { useCommonFetcher } from "../Common/CommonViewModel";
import { Venue } from "../../models/Venue";
import { GolfMap } from "../../models/GolfMap";

export const useVenueListViewModel = ({ facilityId = "", isGolf = false }) => {
  const { fetcher } = useCommonFetcher();
  const [venueList, setVenueList] = useState([]);

  // Golf maps
  const [golfMaps, setGolfMaps] = useState([]);
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [curMapIndex, setCurMapIndex] = useState(0);

  const definedBays = [
    // {
    //   _id: "all",
    //   name: i18n.getFixedT()("booking:golfBayOptions.all")
    // },
    {
      _id: "lg1-lg20",
      name: "LG1 - LG20",
    },
    {
      _id: "lg21-lg39",
      name: "LG21 - LG39",
    },
    {
      _id: "g1-g22",
      name: "G1 - G22",
    },
  ];

  useEffect(() => {
    getVenueList();
    if (isGolf) {
      getGolfMap();
    }
  }, []);

  const getVenueList = async () => {
    try {
      var res = await fetcher({
        api: () => listVenueByFacility({ facilityId }),
      });
      setVenueList(res?.result?.map((venue) => new Venue(venue)) ?? []);
    } catch (e) {
      //dont need error handle
      console.log("get venue error - ", e);
    }
  };

  const getVenueListWithRefNumber = async (ref_number) => {
    try {
      var res = await fetcher({
        api: () => listVenueByFacility({ facilityId, ref_number }),
      });
      setVenueList(res?.result?.map((venue) => new Venue(venue)) ?? []);
    } catch (err) {
      console.log("Get Golf Map error: ", err);
    }
  };

  const getGolfMap = async () => {
    try {
      var res = await getGolfMapByFacility({ facilityId });
      console.log("Get Golf Map: ", res);
      setGolfMaps(res?.result?.maps?.map((map) => new GolfMap(map)) ?? []);
    } catch (err) {
      console.log("Get Golf Map error: ", err);
    }
  };

  const toogleGolfMapModel = (visible) => {
    setMapModalVisible(visible);
  };

  const onPressPrevious = () => {
    // handle negative reminder issue
    let newIndex =
      curMapIndex - 1 < 0 ? curMapIndex - 1 + golfMaps.length : curMapIndex - 1;
    setCurMapIndex(newIndex % golfMaps.length);
  };

  const onPressNext = () => {
    setCurMapIndex((curMapIndex + 1) % golfMaps.length);
  };

  return {
    venueList,
    definedBays,
    golfMaps,
    mapModalVisible,
    curMapIndex,
    toogleGolfMapModel,
    onPressPrevious,
    onPressNext,
    getVenueList,
    getVenueListWithRefNumber,
  };
};
