import { useContext, useState } from "react";
import { getNotificationDetail as getNotificationDetailAPI } from "../../network/notification";
import { NotificationCategoryTag } from "../../models/Notification";
import { ProgramTypes } from "../MyBooking/MyBookingListViewModel";
import { useCommonFetcher } from "../Common/CommonViewModel";

export const useNotificationDetailViewModel = ({ notificationId }) => {
  const { fetcher } = useCommonFetcher();
  const [notificationDetail, setNotificationDetail] = useState(null);

  const getNotificationDetail = async () => {
    const res = await fetcher({api: ()=> getNotificationDetailAPI({ notificationId })});
    if(res?.success) setNotificationDetail(res?.result);
    return res;
  };

  const getProgramType = () => {
    switch (notificationDetail?.notification_category_tag) {
      case NotificationCategoryTag.EventBooking:
        return ProgramTypes.Event;
      case NotificationCategoryTag.FacilityBooking:
        return ProgramTypes.Facility;
      case NotificationCategoryTag.MonthlyProgramme:
        return ProgramTypes.Program;
      case NotificationCategoryTag.PrivateCoaching:
        return ProgramTypes.Coach;
      default:
        return null;
    }
  };

  return {
    notificationDetail,
    getNotificationDetail,
    getProgramType,
  };
};
