import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import Styled from "./Styled";

type Props = {
  keyMembers: {
    user_id: string;
    email: string;
  }[];
};

const BookingDetailKeyMemberCard = (props: Props) => {
  const { keyMembers } = props;
  const { t } = useTranslation("myBooking");

  return (
    <Styled.SharedCard
      className={cn(
        "w-full px-[22px] py-[22px]",
        "lg:px-[37px]",
        "lg:py-[22px]"
      )}
    >
      <div className="w-full flex flex-col">
        <Styled.KeyMemberTitle>{t("detail.keyMembers")}</Styled.KeyMemberTitle>
        {keyMembers.length > 0 && <div className="h-[20px]" />}
        <div className="flex flex-col gap-y-[20px]">
          {keyMembers.map((member, index) => (
            <div className="flex flex-col " key={member.user_id}>
              <Styled.KeyMemberSubtitle>{`${t("detail.keyMember")} ${
                index + 1
              }`}</Styled.KeyMemberSubtitle>
              <div className="h-[7.43px]" />
              <Styled.KeyMemberEmailContainer>
                {member.email}
              </Styled.KeyMemberEmailContainer>
            </div>
          ))}
        </div>
      </div>
      <div className="h-[20px]" />
    </Styled.SharedCard>
  );
};

export default BookingDetailKeyMemberCard;
