import i18n from "../../i18n";

export const wrapEvenly = (string = "test ing!") => {
  let medianIndex = Math.floor(string.length / 2);
  if (string.charAt(medianIndex) != " ") {
    let disToPrev = string
      .substring(0, medianIndex + 1)
      .split("")
      .reverse()
      .join()
      .indexOf(" ");
    let disToNext = string.substring(medianIndex, string.length).indexOf(" ");
    if (disToNext == -1 && disToPrev == -1) return string;
    if (disToPrev == -1 || disToNext < disToPrev) medianIndex += disToNext;
    else medianIndex -= disToPrev;
  }

  return (
    string.substring(0, medianIndex) +
    "\n" +
    string.substring(medianIndex + 1, string.length)
  );
};

export const getLocalisedString = (enString, zhString, handleNull = false ) => {
  switch (i18n.language) {
    case "en":
      if(handleNull) return enString || "";
      return enString;
    case "zh":
      if(handleNull) return zhString || "";
      return zhString;
    default:
      if(handleNull) return enString || "";
      return enString;
  }
};

export const isChinese = (str) => {
  const pattern = /[\u4e00-\u9fa5]/; // Matches Chinese characters
  return pattern.test(str);
};

export const validateEmailFormat = (email) => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) != null
  );
};

export const validatePassword = (pw) => {
  // Shd contains at least 1 letter, 1 number
  // Shd be at least 8 characters long
  return String(pw).match(/^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/)
}
