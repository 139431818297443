import styled from "styled-components";
import visitMorePng from "../../assets/visit-more-bg.png";
import SearchBarPng from "../../assets/search-bar-bg.png";

const MainContainer = styled.div`
  background-image: url(${visitMorePng});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  width: inherit;
  border-radius: 20px;
  min-height: 617px;
  z-index: 0;
  padding-top: 61px;
`;

const PhoneImage = styled.img`
  width: 225.999px;
  height: 291.197px;
`;

const Title = styled.p`
  color: var(--palette-ss-000, #000);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.491px; /* 165.571% */
`;

const QrCodeContainer = styled.div`
  width: 101px;
  height: 101px;
`;

const SearchBar = styled.div`
  width: 227px;
  height: 50px;
  background-image: url(${SearchBarPng});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--palette-ss-black-text, #32394c);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  white-space: nowrap;
  padding-top: 16px;
  padding-left: 23px;
`;

const StoreImage = styled.img`
  width: 108.911px;
  height: 37.308px;
`;

export default {
  MainContainer,
  PhoneImage,
  Title,
  QrCodeContainer,
  SearchBar,
  StoreImage,
};
