import React, { useMemo } from "react";
import dayjs from "common/utils/dayjs";
import Styled from "./Styled";
import { PastBookingProps } from "..";
import MyPastListingCard from "../MyPastListingCard/MyPastListingCard";

type Props = {
  title: string;
  reservedDate: string | Date;
  dates: { startTime: string; endTime: string }[];
  onClick: () => void;
} & PastBookingProps;

const MyFacilityListingCard = (props: Props) => {
  const { title, reservedDate, dates, onClick, status, isPastRecord } = props;

  const dateInFormat = useMemo(() => {
    return dayjs(reservedDate).tz().format("DD/MM/YYYY (ddd)");
  }, [reservedDate]);

  const timeSlotsInFormat = useMemo(() => {
    return dates.map((date) => {
      const startTime = dayjs(date.startTime);
      const endTime = dayjs(date.endTime);
      return startTime.tz().format("HH:mm") + "-" + endTime.tz().format("HH:mm");
    });
  }, [dates]);

  return isPastRecord ? (
    <MyPastListingCard title={title} status={status} onClick={onClick}>
      <div>{dateInFormat}</div>
      {timeSlotsInFormat.map((timeSlot) => (
        <div key={timeSlot}>{timeSlot}</div>
      ))}
    </MyPastListingCard>
  ) : (
    <Styled.Card onClick={onClick}>
      <div className="w-full px-[20px] pt-[22px] pb-[28px] flex flex-col">
        <Styled.TitleText>{title}</Styled.TitleText>
        <div className="h-[5px]"></div>
        <Styled.BodyText>{dateInFormat}</Styled.BodyText>
        {timeSlotsInFormat.map((timeSlot) => (
          <Styled.BodyText key={timeSlot}>{timeSlot}</Styled.BodyText>
        ))}
      </div>
    </Styled.Card>
  );
};

export default MyFacilityListingCard;
