import { useEffect, useState } from "react";
import {
  getCoachLessonDetail,
  holdCoachTimeslot,
  queryCoachLessonTimeslot,
} from "../../network/coachLesson";
import dayjs from "../../utils/dayjs";
import config from "../../../config";
import { formatDateToString, getTimeIsAfter } from "../../utils/DateHelper";
import {
  appearAccountVerification,
  appearGlobalError,
  appearGlobalLoading,
  disappearGlobalLoading,
} from "../../context/requests/globalRequest";
import { getLocalisedString } from "../../utils/stringHelper";
import { isEmptyValues } from "../../utils/common";
import { useCommonFetcher } from "../Common/CommonViewModel";
import { CoachLessonInfoResponse } from "common/models/CoachLesson/CoachLessonInfoResponse";
import _ from "lodash";

export const useCoachLessonDetailViewModel = ({
  coachLessonId = "",
  dispatch,
}) => {
  const { fetcher } = useCommonFetcher();
  const [coachLesson, setCoachLesson] = useState();
  const [lessonTypeInfo, setLessonTypeInfo] = useState();
  // Default date set to tomorrow
  const [dateSelected, setDateSelected] = useState(
    formatDateToString(dayjs().add(1, "day"))
  );
  const [timeslots, setTimeslots] = useState([]);
  const [selectedTimeslots, setSelectedTimeslots] = useState(new Map());
  const [selectedLessonType, setSelectedLessonType] = useState("");

  useEffect(() => {
    console.log("Selected Date: ", dateSelected);
    if (dateSelected != "") {
      refreshData();
    }
  }, [dateSelected]);

  const refreshData = async () => {
    appearGlobalLoading(dispatch);
    setLessonTypeInfo();
    setTimeslots([]);
    setSelectedTimeslots(new Map());
    await getCoachLesson();
    await getCoachTimeslotByDate(true);
    disappearGlobalLoading(dispatch);
  };

  const getCoachLesson = async () => {
    const res = await fetcher({
      api: () =>
        getCoachLessonDetail({
          coachLessonId,
          selectedDate: dayjs.tz(dateSelected).toISOString(),
        }),
    });

    if (res?.success) {
      const coachLessonRes = new CoachLessonInfoResponse(res?.result);
      if (
        !_.isEmpty(coachLessonRes?.coachLesson) &&
        !_.isEmpty(coachLessonRes?.lessonType)
      ) {
        setCoachLesson(coachLessonRes?.coachLesson);
        setLessonTypeInfo(coachLessonRes?.lessonType);
      } else {
        return {
          success: false,
        };
      }
    }

    return res;
  };

  const onDateSelected = (date) => {
    setDateSelected(date);
  };

  const getCoachTimeslotByDate = async () => {
    try {
      const res = await queryCoachLessonTimeslot({
        coachLessonId,
        date: dayjs.tz(dateSelected).toISOString(),
      });
      setTimeslots(res ?? []);

      return res;
    } catch (err) {}
  };

  const getSelectedLessonTypePrice = () => {
    switch (selectedLessonType) {
      case "1to1":
        return lessonTypeInfo?.one_to_one_price;
      case "1to2":
        return lessonTypeInfo?.one_to_two_price;
      case "1to3":
        return lessonTypeInfo?.one_to_three_price;
      default:
        return 0;
    }
  };

  const onPressTimeslot = (timeslot) => {
    let timeslotIdentifier = timeslot.getDisplayTimeslot();
    let tmpSelectedTimeslots = new Map(selectedTimeslots);
    if (selectedTimeslots.has(timeslotIdentifier)) {
      tmpSelectedTimeslots.delete(timeslotIdentifier);
    } else if (checkContiniousTimeSlot(timeslot)) {
      tmpSelectedTimeslots.set(timeslotIdentifier, timeslot);
    }

    // Sort selected timeslots by start_time
    // FIXME: Better compare as date instead
    const sortedTimeslots = new Map(
      [...tmpSelectedTimeslots].sort(
        ([key_a, timeslot_a], [key_b, timeslot_b]) =>
          getTimeIsAfter(timeslot_a?.start_time, timeslot_b?.start_time)
            ? 1
            : -1
      )
    );

    setSelectedTimeslots(sortedTimeslots);
  };

  const checkContiniousTimeSlot = (timeslot) => {
    if (selectedTimeslots.size === 0) return true;
    if (selectedTimeslots.size >= config.NUM_OF_SLOT_CAN_BE_SELECT_AT_ONCE)
      return false;
    let continiousTimeSlot = false;
    selectedTimeslots.forEach((selectedTimeslot) => {
      if (
        selectedTimeslot.start_time === timeslot.end_time ||
        selectedTimeslot.end_time === timeslot.start_time
      ) {
        continiousTimeSlot = true;
        return;
      }
    });
    return continiousTimeSlot;
  };

  const holdTimeslot = async ({ dispatch }) => {
    try {
      appearGlobalLoading(dispatch);
      var timeslots = Array.from(selectedTimeslots?.values()).map(
        (timeslot) => {
          return {
            coach_start_time: timeslot?.start_time,
            coach_end_time: timeslot?.end_time,
          };
        }
      );
      const res = await holdCoachTimeslot({
        coachLessonId: coachLesson?._id,
        selectedDate: dayjs.tz(dateSelected).toISOString(),
        lessonType: selectedLessonType,
        selectedTimeslots: timeslots,
      });
      if (res) {
        disappearGlobalLoading(dispatch);
        setSelectedTimeslots(new Map());
      } else {
        throw new Error("Unknown exception. holdTimeslot failed");
      }
      return res;
    } catch (err) {
      setSelectedTimeslots(new Map());
      getCoachTimeslotByDate();
      disappearGlobalLoading(dispatch);

      const error = err?.response?.data?.error;
      const errorCode = error?.errorCode;
      console.log(error);
      if (errorCode === -1001 || errorCode === -1002) {
        // -1001 for pending, -1002 for new user
        appearAccountVerification(dispatch, errorCode === -1002 ? true : false);
      } else {
        const errorMsg = !isEmptyValues(error)
          ? getLocalisedString(
              error?.localizedMessage?.en,
              error?.localizedMessage?.zh,
              true
            )
          : err.message + "";
        appearGlobalError(dispatch, errorMsg);
      }

      throw err;
    }
  };

  return {
    coachLesson,
    lessonTypeInfo,
    dateSelected,
    timeslots,
    selectedTimeslots,
    selectedLessonType,
    getCoachLesson,
    onDateSelected,
    getCoachTimeslotByDate,
    onPressTimeslot,
    setSelectedLessonType,
    getSelectedLessonTypePrice,
    holdTimeslot,
  };
};
