import { useState, useEffect, useMemo } from "react";
import { getLocalisedString } from "../../utils/stringHelper";
import { VoucherType } from "../VoucherViewModel";
import { usePromoCodeViewModel } from "../Payment/PromoCodeViewModel";
import { isEmptyValues } from "../../utils/common";
import { PromoCodeBookingType } from "../../models/PromoCode";
export const EventPromoCodeType = {
  CONSTANT: "CONSTANT",
  PERCENTAGE: "PERCENTAGE",
};

export const useEventBookingViewModel = ({ event, dispatch }) => {
  const { checkPromoCodeAvailability, estimatePromoCodeDiscount } =
    usePromoCodeViewModel();
  const [currentEvent, setCurrentEvent] = useState(event);
  const [numOfAttendees, setNumberOfAttendees] = useState(1);
  const [appliedVouchers, setVouchers] = useState([]);
  const [promoCode, setPromoCode] = useState(null);
  const maxAttendeesPerBooking = (event?.guest_invite_limit ?? 0) + 1;

  const subtotalPrice = useMemo(() => {
    return currentEvent?.price * numOfAttendees;
  }, [currentEvent?.price, numOfAttendees]);

  const subtotalPriceWithVoucher = useMemo(() => {
    return (
      subtotalPrice -
      appliedVouchers.reduce((acc, voucher) => acc + voucher?.voucher?.value, 0)
    );
  }, [subtotalPrice, appliedVouchers]);

  useEffect(() => {
    updateEstimatedPromoCodePrice();
  }, [subtotalPriceWithVoucher]);

  const onPressAddAttendees = () => {
    console.log("OnPressAdd");
    let newNumOfAttendees = numOfAttendees + 1;
    if (newNumOfAttendees <= maxAttendeesPerBooking) {
      setNumberOfAttendees(newNumOfAttendees);
    }
  };

  const onPressSubtractAttendees = () => {
    let newNumOfAttendees = numOfAttendees - 1;
    if (newNumOfAttendees >= 1) {
      setNumberOfAttendees(newNumOfAttendees);
    }
  };

  const applyPromoCode = async (code) => {
    const res = await checkPromoCodeAvailability({
      code,
      bookingType: PromoCodeBookingType.EVENT,
      orderAmount: subtotalPriceWithVoucher * 100,
    });

    if (res?.success && !isEmptyValues(res?.promoCode)) {
      setPromoCode(res?.promoCode);
    } else {
      setPromoCode(null);
    }
  };

  const updateEstimatedPromoCodePrice = async () => {
    if (!isEmptyValues(promoCode)) {
      const res = await estimatePromoCodeDiscount({
        promoCode: promoCode,
        orderAmount: subtotalPriceWithVoucher * 100,
      });

      if (res?.success && !isEmptyValues(res?.promoCode)) {
        setPromoCode(res?.promoCode);
      } else {
        setPromoCode(null);
      }
    }
  };

  const applyVoucher = async (voucher) => {
    if (appliedVouchers.some((el) => el?.voucherId == voucher?.voucherId))
      return;
    let appliedVoucher = voucher;
    if (
      !appliedVoucher.voucher.value &&
      appliedVoucher?.voucher?.type != VoucherType.CASH
    ) {
      appliedVoucher.voucher.value = currentEvent?.price;
    }
    setVouchers([...appliedVouchers, appliedVoucher]);
  };

  const removeVoucher = (vouchers) => {
    if (vouchers) {
      if (Array.isArray(vouchers)) {
        setVouchers(
          appliedVouchers.filter(
            (el) =>
              !vouchers.some((voucher) => voucher?.voucherId == el?.voucherId)
          )
        );
      } else {
        setVouchers(
          appliedVouchers.filter((el) => vouchers?.voucherId != el?.voucherId)
        );
      }
    } else {
      setVouchers([]);
    }
  };

  const removePromoCode = () => {
    setPromoCode(null);
  };

  const getSubtotalPrice = () => {
    return Math.max(0, subtotalPrice);
  };

  const getTotalPrice = () => {
    let total = promoCode
      ? subtotalPriceWithVoucher - (promoCode?.enjoyedDiscount ?? 0)
      : subtotalPriceWithVoucher;
    return Math.max(0, total);
  };

  const getBookingSummary = () => {
    var bookingSummary = {
      eventName: getLocalisedString(
        currentEvent.title_en,
        currentEvent.title_tc
      ),
      event_date: currentEvent?.event_date,
      venue:
        getLocalisedString(currentEvent.venue_en, currentEvent.venue_tc) ??
        undefined,
      price: currentEvent?.price,
      subtotalPrice: getSubtotalPrice(),
      totalPrice: getTotalPrice(),
      remarks:
        getLocalisedString(currentEvent.remarks_en, currentEvent.remarks_tc) ??
        undefined,
      tnc:
        getLocalisedString(
          currentEvent?.t_and_c?.desc_en,
          currentEvent?.t_and_c?.desc_tc
        ) ?? undefined,
      tncTitle: getLocalisedString(
        currentEvent?.t_and_c?.title_en,
        currentEvent?.t_and_c?.title_tc
      ),
      zone: currentEvent?.zone,
    };

    if (currentEvent?.isHeadCount || true) {
      bookingSummary.numOfAttendees = numOfAttendees;
    }

    if (promoCode) {
      bookingSummary.promoCode = promoCode;
    }

    // TODO: Voucher implementation
    if (appliedVouchers.length > 0) {
      bookingSummary.vouchers = appliedVouchers;
    }

    return {
      bookingSummary,
      payload: getConfirmBookingPayload(),
    };
  };

  const getConfirmBookingPayload = () => {
    var payload = {
      event_id: currentEvent?._id,
      ticket_count: numOfAttendees,
      // totalAmount: getTotalPrice(),
    };

    if (promoCode) {
      payload.promocode = promoCode.code;
      // payload.promoCodeTicket = promoCode.promoCodeTicketId;
    }

    // TODO: Voucher implementation
    if (appliedVouchers.length > 0) {
      payload.voucher = appliedVouchers.map((el) => el.voucherId);
    }

    return payload;
  };

  return {
    currentEvent,
    numOfAttendees,
    maxAttendeesPerBooking,
    appliedVouchers,
    promoCode,
    onPressAddAttendees,
    onPressSubtractAttendees,
    applyPromoCode,
    removePromoCode,
    applyVoucher,
    removeVoucher,
    getSubtotalPrice,
    getTotalPrice,
    getBookingSummary,
  };
};
