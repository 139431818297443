import styled from "styled-components";

const StyledImage = styled.img`
  width: ${(props) => (props.height ? props.height : "25px")};
  height: ${(props) => (props.width ? props.width : "25px")};
  resize-mode: contain;
`;

const Image = ({ ...props }) => {
  return <StyledImage {...props} />;
};

export default Image;
