import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import PageBackButton from "components/common/PageBackButton";
import ProfileLayout from "layouts/ProfileLayout/ProfileLayout";
import phonePng from "../../assets/visit-more-phone.png";
import QrCodePlaceholderPng from "../../assets/qrcode-placeholder.png";
import QrCodeCoverPng from "../../assets/saisha-qrcode-cover.png";
import AppStorePng from "../../assets/app-store-btn.png";
import PlayStorePng from "../../assets/play-store-btn.png";
import Styled from "./Styled";

const MoreInformationScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const back = useCallback(() => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate("/profile");
    }
  }, [navigate]);
  return (
    <ProfileLayout>
      <div className="w-full flex flex-col">
        <div className={cn("w-full", "hidden", "mb-[25px]", "lg:block")}>
          <PageBackButton
            onClick={back}
            text={t("monthlyProgram:monthlyProgramDetail.btnBack")}
          />
        </div>
        <main>
          <Styled.MainContainer className="flex flex-col items-center">
            <Styled.Title>
              Please download our app to get more information!
            </Styled.Title>
            <div className="h-[34px]" />
            <div className="flex flex-col lg:flex-row lg:justify-center">
              <Styled.PhoneImage src={phonePng} />
              <div className="h-[30px]" />
              <div className="flex flex-col items-center">
                <Styled.QrCodeContainer className="relative">
                  <img src={QrCodePlaceholderPng} width="100%" height="100%" />
                  <img
                    src={QrCodeCoverPng}
                    className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                    width={33.667}
                    height={33.667}
                  />
                </Styled.QrCodeContainer>
                <div className="h-[21px]" />
                <Styled.SearchBar>
                  <slot>{"GO PARK Sports"}</slot>
                </Styled.SearchBar>
                <div className="h-[20.7px]" />
                <div className="flex justify-center">
                  <button type="button">
                    <Styled.StoreImage src={AppStorePng} />
                  </button>
                  <div className="w-[18.8px]" />
                  <button type="button">
                    <Styled.StoreImage src={PlayStorePng} />
                  </button>
                </div>
              </div>
            </div>
          </Styled.MainContainer>
        </main>
      </div>
    </ProfileLayout>
  );
};

export default MoreInformationScreen;
