import IconLoader from "assets/IconLoader";
import GeneralButton from "components/common/Button";
import PageContainer from "components/common/PageContainer";
import Space from "components/common/Space";
import Typography from "components/common/Typography";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";
import scopeLargePng from "assets/bg-payment-slope-large.png";
import scopeSmallPng from "assets/bg-payment-slope-small.png";
import Styled from "./Styled";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const EnquirySuccessPage = () => {
  const { t } = useTranslation("enquiry");
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.enableAccess) navigate("../");
  }, []);

  return (
    <Styled.ResultContainer className="relative">
      <IconLoader.IconSuccess />
      <Space width="80px" />
      <div>
        <Typography color={theme.black} fontSize="40px" fontWeight="600">
          {t("success.title")}
        </Typography>
        <Space size="30px" />
        <Typography fontWeight="600">{t("success.content")}</Typography>
      </div>
      <img
        src={scopeLargePng}
        className={cn(
          "hidden md:block",
          "absolute bottom-0 right-0",
          "w-[80vw] h-[230px]"
        )}
      />
      <img
        src={scopeSmallPng}
        className={cn(
          "md:hidden",
          "absolute bottom-0 right-0",
          "w-full h-[300px]"
        )}
      />
    </Styled.ResultContainer>
  );
};

export default EnquirySuccessPage;
