import { Grid } from "@mui/material";
import { GolfBay, IFacility } from "common/models/Facility";
import DropdownSelect from "components/common/Select/DropdownSelect";
import Space from "components/common/Space";
import Typography from "components/common/Typography";
import Styled from "pages/FacilityScreen/Styled";

import IconRemark from "assets/ic-orangeCircleRemark.svg";
import theme from "utils/theme";
import { useTranslation } from "react-i18next";

type GenericVenuePickerProps = {
  facility: IFacility;
  onClickRemark: (isOpened: boolean) => void;
  selectedVenueId?: string;
  setSelectedVenue: (venue: string) => void;
  venueOptions: { label: string; value: string }[];
};

type GolfVenuePickerProps = {
  definedBays: GolfBay[];
  selectedBay: string;
  onClickRemark: (isOpened: boolean) => void;
  onSelectBayFilter: (refNum: string) => void;
  shouldDisplayVenuePicker: boolean;
};

type VenuePickerProps = GenericVenuePickerProps & GolfVenuePickerProps;

type VenuePickerPropsByType<IsGolf extends boolean> = IsGolf extends true
  ? GenericVenuePickerProps & GolfVenuePickerProps
  : GenericVenuePickerProps;

type GolfVenuePickerProps2 = VenuePickerPropsByType<true>;
type OtherVenuePickerProps = VenuePickerPropsByType<false>;

const VenuePicker = (props: VenuePickerProps) => {
  const {
    facility,
    selectedBay,
    onClickRemark,
    selectedVenueId: selectedVenue,
    setSelectedVenue,
    venueOptions,
    onSelectBayFilter: onSelectFilter,
    definedBays,
    shouldDisplayVenuePicker,
  } = props;
  const isGolf = facility?.isGolf && facility.isGolf();

  const { t } = useTranslation("booking");

  const onBayFilterClick = (bay: GolfBay) => {
    onSelectFilter(bay._id);
  };

  // useEffect(() => {
  //   if (isGolf) {
  //     if (definedBays?.length > 0 && facility.isShowRefNum) {
  //       getVenueListWithRefNumber(definedBays[0]?._id);
  //       setFilter(bayOptions.find((item) => item._id === definedBays[0]?._id));
  //     } else {
  //       getVenueList();
  //     }
  //   }
  // }, []);

  return (
    <>
      {isGolf ? (
        <div className="flex items-center pb-3">
          <Typography fontWeight="500" className="flex-1">
            {t("facilityDetail.selectCourtInstruction")}
          </Typography>

          <img
            src={IconRemark}
            onClick={() => onClickRemark(true)}
            className="md:hidden cursor-pointer "
          />
        </div>
      ) : (
        <Typography fontWeight="500" className="flex-1">
          {t("facilityDetail.selectCourtInstruction")}
        </Typography>
      )}
      {isGolf && facility.isShowRefNum && (
        <div className="flex">
          <div className="flex flex-1 gap-2 flex-wrap">
            {definedBays?.map((bay, idx) => (
              <Grid item xs="auto" key={idx}>
                <Styled.RedBordedLabel
                  onClick={() => onBayFilterClick(bay)}
                  selected={bay?._id === selectedBay}
                >
                  <Typography
                    color={
                      bay?._id === selectedBay ? theme.white : theme.orange
                    }
                  >
                    {bay?.name}
                  </Typography>
                </Styled.RedBordedLabel>
              </Grid>
            ))}
          </div>
          {isGolf && (
            <img
              src={IconRemark}
              onClick={() => onClickRemark(true)}
              className="hidden md:block cursor-pointer"
            />
          )}
        </div>
      )}

      <Space size="20px" />
      {shouldDisplayVenuePicker && (
        <DropdownSelect
          options={venueOptions}
          value={selectedVenue ?? ""}
          placeholder={t("common:dropdownPlaceholder")}
          onChange={(newItem) => setSelectedVenue(newItem)}
          className="max-w-[420px]"
        />
      )}
    </>
  );
};
export default VenuePicker;
