import { useContext } from "react";
import { appearGlobalError } from "../../context/requests/globalRequest";
import { getPresignedURL, uploadImageToS3 } from "../../network/auth";
import { isEmptyValues } from "../../utils/common";
import { useCommonFetcher } from "../Common/CommonViewModel";
import { GlobalContextStore } from "../../context/providers/globalProvider";
import { IGlobalProviderContext } from "../../models/Common";

export const useFileUploadViewModel = () => {
  const { fetcher } = useCommonFetcher();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );

  const uploadFileWithPresignUrl = async (arrBuffer: ArrayBuffer) => {
    const presignUrlRes = await fetcher({
      api: () => getPresignedURL(false, "image"),
    });
    if (!presignUrlRes?.success) return presignUrlRes; // gen presigned url failed

    const {
      presign_url, // Presigned URL for file upload
      ref_url, // File Reference URL to return
    } = presignUrlRes?.result || {};

    if (isEmptyValues(presign_url) || isEmptyValues(ref_url)) {
      // Error: Empty resign url || Empty ref url
      appearGlobalError(globalDispatch);
      return { success: false };
    }

    const uploadToS3Res = await fetcher({
      api: () => uploadImageToS3(presign_url, arrBuffer),
    });

    if (uploadToS3Res?.success != false)
      return { success: true, ref_url }; // upload success
    else return uploadToS3Res; // upload error { success: false }
  };

  return { uploadFileWithPresignUrl };
};
