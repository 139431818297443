import { useEffect, useState } from "react";

/**
 * Ref: https://stackoverflow.com/questions/43817118/how-to-get-the-width-of-a-react-element
 * @param myRef
 * @returns {[number, number]} [width, height]
 */
export const useContainerDimensions = (
  myRef: React.MutableRefObject<HTMLDivElement | undefined>
) => {
  const [dimensions, setDimensions] = useState([0, 0]);

  useEffect(() => {
    const getDimensions = () => [
      myRef?.current?.offsetWidth ?? 0,
      myRef?.current?.offsetHeight ?? 0,
    ];

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};
