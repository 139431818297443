import { useState } from "react";
import {
  getEventList as getEventListAPI,
  getEventDetail as getEventDetailAPI,
  getEventType as getEventTypeAPI,
  listEventBookings,
  listPastEventBookings,
  getPendingPaymentStatus as getPendingPaymentStatusAPI,
} from "../network/event";
import { appearGlobalError } from "../context/requests/globalRequest";
import { Event } from "../models/Event";
import { AxiosError } from "axios";
import { useCommonFetcher } from "./Common/CommonViewModel";

interface IFilteredEventListPayload {
  keySearch?: string;
  type?: string;
  category?: string;
}

export const useEventViewModel = () => {
  const { fetcher } = useCommonFetcher();
  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);
  const [eventList, setEventList] = useState([]);
  const [eventType, setEventType] = useState([]);
  const [myEventBookings, setMyEventBookings] = useState([]);
  const [myPastEventBookings, setMyPastEventBookings] = useState([]);

  const getEventList = async (dispatch: any) => {
    try {
      const res = await getEventListAPI();
      setEventList(res.result.map((event: any) => new Event(event)));
      return res.success;
    } catch (e) {
      if (e instanceof AxiosError) {
        appearGlobalError(dispatch, e?.response?.data?.msg);
        return false;
      }
    }
  };

  const getEventType = async (dispatch: any) => {
    try {
      const res = await getEventTypeAPI();
      setEventType(res.result);
      return res.success;
    } catch (e) {
      if (e instanceof AxiosError) {
        appearGlobalError(dispatch, e?.response?.data?.msg);
        return false;
      }
    }
  };

  const getEventDetail = async (id: string, dispatch: any) => {
    const res = await fetcher({ api: () => getEventDetailAPI(id) });
    if (res?.success) {
      setCurrentEvent(new Event(res?.result));
    }

    return res;
  };

  const getFilteredEventList = async (
    keywords: string,
    typeId: string,
    categoryId: string,
    dispatch: any
  ) => {
    try {
      console.log("getFilteredEventList ", keywords, typeId, categoryId);
      const payload: IFilteredEventListPayload = {};
      if (keywords) payload.keySearch = keywords;
      if (typeId) payload.type = typeId;
      if (categoryId) payload.category = categoryId;
      const res = await getEventListAPI(payload);
      setEventList(res.result.map((event: any) => new Event(event)));
      return res.success;
    } catch (e) {
      if (e instanceof AxiosError) {
        appearGlobalError(dispatch, e?.response?.data?.msg);
        return false;
      }
    }
  };

  const getMyEventBookings = async (dispatch: any) => {
    try {
      const res = await listEventBookings();
      setMyEventBookings(res.result);
      return res;
    } catch (e) {
      if (e instanceof AxiosError) {
        appearGlobalError(dispatch, e?.response?.data?.msg);
        return { success: false };
      }
    }
  };

  const getMyPastEventBookings = async (dispatch: any) => {
    try {
      const res = await listPastEventBookings();
      setMyPastEventBookings(res.result);
      return res;
    } catch (e) {
      if (e instanceof AxiosError) {
        appearGlobalError(dispatch, e?.response?.data?.msg);
        return { success: false };
      }
    }
  };

  //Api will throw an error if any pending transactions is found to avoid double booking
  const getPendingPaymentStatus = async (id: string) => {
    const res = await fetcher({ api: () => getPendingPaymentStatusAPI(id) });
    return res;
  };

  return {
    currentEvent,
    eventList,
    eventType,
    myEventBookings,
    getEventDetail,
    getEventList,
    getFilteredEventList,
    getEventType,
    getMyEventBookings,
    getMyPastEventBookings,
    getPendingPaymentStatus,
  };
};
