import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as _ from "lodash";
import IconLoader from "../../assets/IconLoader";
import { getLocalisedString } from "common/utils/stringHelper";
import dayjs from "common/utils/dayjs";
import { ResponsiveUI, breakpoint } from "utils/responsive";
import { useNavigate } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import Gallary from "./Gallary";
import theme from "utils/theme";
import { Category, Event, EventType } from "models";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { IUserViewModelContext } from "../../@types/common";
import CategoryTag from "pages/Event/_components/CategoryTag";
import Space from "components/common/Space";
import ReadmoreButton from "components/common/buttons/ReadmoreButton";
import { getCorrectUrl } from "navigators/Router";
import GreyFullLabel from "pages/Event/_components/GreyFullLabel";
import Stack from "@mui/material/Stack";

const Container = styled.div`
  display: flex;
  background-color: ${theme.white};
  height: auto;
  width: 100%;

  @media ${breakpoint.mobile} {
    flex-direction: column;
  }
`;

const ListContainer = styled.div`
  background: rgba(255, 255, 255, 0.6);
  width: 50%;
  padding: 30px;
  @media ${breakpoint.desktop} {
    width: 100%;
  }

  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  span {
    font-size: 25px;
    font-weight: 600;
    display: inline-block;
    border-bottom: 3px solid #000;
  }
`;

const ItemWapper = styled.div`
  display: flex;
  width: 100%;
  float: left;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 30px;
  align-items: flex-start;
`;

const BoxItem = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 135px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.2s;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 639px) {
    height: auto;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const ItemImg = styled.div<{ img: string }>`
  background: url(${(p) => p.img});
  width: 135px;
  height: 135px;
  border-radius: 6px;
  background-size: cover;
  @media (max-width: 639px) {
    width: 220px;
    height: 220px;
    border-radius: 20px;
    margin: 0 auto;
  }
`;

const ItemContent = styled.div`
  width: calc(100% - 165px);
  margin-top: -8px;
  @media (max-width: 639px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const ItemTitle = styled.div`
  color: #000;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 10px;
`;

const ItemRaw = styled.div`
  color: #8c8c8c;
`;

const Link = styled.div`
  color: ${theme.seaBlue};
  font-size: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
`;

interface EventProps {
  eventList: Event[];
}

const UpcomingEvent = ({ eventList }: EventProps) => {
  const navigate = useNavigate();
  const { currentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { t } = useTranslation("common");

  return (
    <Container>
      <ResponsiveUI desktop={<Gallary />} mobile={<div />} />
      <ListContainer>
        <Title>
          <span>{t("home:event.upcoming")}</span>

          <ReadmoreButton onClick={() => navigate(ROUTE_BASE.EVENT)} isMore />
        </Title>
        <ItemWapper>
          {_.map(eventList, (item, i) => {
            const color = item?.event_type?.bgColor;
            if (Number(i) < 3)
              return (
                <BoxItem
                  onClick={() => navigate(`${ROUTE_BASE.EVENT}/${item?._id}`)}
                >
                  <ItemImg
                    img={getLocalisedString(
                      item?.thumbnail_en,
                      item?.thumbnail_tc
                    )}
                  />
                  <ItemContent>
                    <ItemTitle>
                      {getLocalisedString(item.title_en, item.title_tc)}
                    </ItemTitle>
                    <ItemRaw>
                      {dayjs(item.event_date[0].start_date).tz().format(
                        "DD/MM/YYYY"
                      )}
                    </ItemRaw>
                    <Space size="10px" />
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <CategoryTag
                        type={item?.category ?? item?.event_type}
                        bgColor={color}
                      />
                      {item.isFull && <GreyFullLabel />}
                    </Stack>
                  </ItemContent>
                </BoxItem>
              );
          })}
        </ItemWapper>
      </ListContainer>
      <ResponsiveUI desktop={<div />} mobile={<Gallary />} />
    </Container>
  );
};

export default UpcomingEvent;
