import { useTranslation } from "react-i18next";
import cn from "classnames";
import LogoCard from "components/common/cards/LogoCard";
import GeneralButton from "components/common/Button";
import { Divider } from "@mui/material";
import Typography from "components/common/Typography";
import Space from "components/common/Space";
import Styled from "./Styled";
import { WindowSize } from "utils/responsive";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loginMethod } from "pages/LoginScreen/LoginHome";
import { useCallback } from "react";

const Home = () => {
  const { t } = useTranslation("login");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({ method: "" });
  const isMobile = WindowSize() === "mobile";

  const handleClickMethod = (method: string) => {
    setSearchParams({ method });
  };

  const onRegisterClick = useCallback(() => {
    navigate("/register");
  }, [navigate]);

  return (
    <LogoCard title={t("title.login")}>
      <GeneralButton
        fullWidth={isMobile}
        width="300px"
        onClick={() => handleClickMethod(loginMethod.MOBILE)}
      >
        {t("button.login_with_mobile")}
      </GeneralButton>
      <Space size="20px" />
      <Divider className="w-full md:w-[300px]">
        <Typography className="mr-[25px]">{t("login_or_alt")}</Typography>
      </Divider>
      <Space size="20px" />
      <GeneralButton
        fullWidth={isMobile}
        width="300px"
        onClick={() => handleClickMethod(loginMethod.EMAIL)}
      >
        {t("button.login_with_email")}
      </GeneralButton>
      <div
        className={cn(
          "flex",
          "justify-between",
          "w-full",
          "md:w-[300px]",
          "mt-[10px]"
        )}
      >
        <Typography fontSize="12px">{t("login:no_account")}</Typography>
        <Styled.LinkTextButton onClick={onRegisterClick}>
          {t("login:button.register")}
        </Styled.LinkTextButton>
      </div>
    </LogoCard>
  );
};

export default Home;
