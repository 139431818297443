import cn from "classnames";
import Space from "components/common/Space";
import Typography from "components/common/Typography";
import EnquiryNav from "components/EnquiryNav/EnquiryNav";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import theme from "utils/theme";
import { breakpoint } from "utils/responsive";

const Container = styled.div`
  margin: 0 auto;
`;

const Content = styled.div`
  background-color: ${theme.lightCyan};
  border-radius: 15px;
  width: 100%;
  height: auto;
  padding: 50px 50px 240px;

  @media ${breakpoint.mobile} {
    padding: 20px 20px 200px;
  }
`;

const FAQ = () => {
  const { t } = useTranslation("faq");
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const hideMenu: boolean = searchParams.get("hideMenu") === "true";

  return (
    <div className={cn("min-h-[400px] px-[50px] flex gap-[60px]")}>
      <div
        className={cn(
          { hidden: hideMenu || location.state?.selected },
          "w-full md:block md:w-[280px] flex-shrink-0"
        )}
      >
        <div className="h-[40px] md:h-[70px]" />
        <EnquiryNav />
      </div>
      <div
        className={cn(
          { hidden: !(location.state?.selected || hideMenu) },
          "flex-1 md:block mt-[40px] md:mt-[70px]"
        )}
      >
        <Container>
          <Content>
            <h1 className="text-2xl font-medium leading-[26px] tracking-[0px] text-left">
              {t("faq")}
            </h1>
            {Array.from(Array(5).keys()).map((item, idx) => (
              <div className="my-[25px]" key={idx}>
                <Typography fontWeight="500" fontSize="20px">
                  {t(`question${idx + 1}`)}
                </Typography>
                <Space size="15px" />
                <Typography>{t(`answer${idx + 1}`)}</Typography>
              </div>
            ))}
          </Content>
        </Container>
      </div>
    </div>
  );
};

export default FAQ;
