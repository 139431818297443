import { Facility } from "common/models/Facility";
import { useGolfMapViewModel } from "common/viewModels/FacilityBooking/GolfMapViewModel";
import PageContainer from "components/common/PageContainer";
import { IGolfMapViewModel } from "models";
import FacilityBanner from "assets/bg-facility-banner.png";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getLocalizedString } from "utils/locale";
import PageBackButton from "components/common/PageBackButton";
import BaySlider from "./BaySlider";

const GolfMap = ({
  facilityDetail,
  handleCloseMap,
}: {
  facilityDetail: Facility;
  handleCloseMap: () => void;
}) => {
  const { golfMaps } = useGolfMapViewModel({
    facilityId: facilityDetail._id,
  }) as IGolfMapViewModel;

  const { t } = useTranslation("booking");
  return (
    <PageContainer
      title={facilityDetail?.getName() ?? t("headerTitle.facilityBooking")}
      banner={facilityDetail?.thumbnail_photo_ref ?? FacilityBanner}
      screenHeader={`${t("headerTitle.facilityBooking")} (${
        getLocalizedString(facilityDetail?.name_en, facilityDetail?.name_tc) ??
        t("headerTitle.facilityBooking")
      })`}
    >
      <div>
        <PageBackButton
          onClick={() => handleCloseMap()}
          text={t("common:button.back")}
        />
        <div className="h-8" />
        <BaySlider data={golfMaps} />
      </div>
    </PageContainer>
  );
};

export default GolfMap;
