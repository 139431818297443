import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as _ from "lodash";
import ImageHomeEvent from "../../assets/img-home-event.png";

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Image = styled.img`
  objact-fit: cover;
  width: 100%;
  height: 100%;
`;

const Gallary = () => {
  return (
    <Container>
      <Image src={ImageHomeEvent} />
    </Container>
  );
};

export default Gallary;
