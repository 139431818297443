import { Backdrop, CircularProgress } from "@mui/material";
import TempLoading from "components/common/TempLoading";
import React from "react";
import styled from "styled-components";

const GlobalLoading = ({ loading }: { loading: boolean }) => {
  console.log("🚀 ~ GlobalLoading ~ loading:", loading);
  if (!loading) return null;
  return (
    <Backdrop
      style={{
        transitionDelay: "500ms",
      }}
      sx={{
        color: "#fff",
        zIndex: (theme) => 99999,
      }}
      open={loading}
    >
      <TempLoading />
    </Backdrop>
  );
};

export default GlobalLoading;
