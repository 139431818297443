import React from "react";
import Styled from "./Styled";
import cn from "classnames";

export type NavItemProps = {
  label: string;
  onClick: React.MouseEventHandler;
  isActive: boolean;
};

const NavSliderItem = (props: NavItemProps) => {
  return (
    <Styled.NavItemBox
      className={cn({ active: props.isActive })}
      onClick={props.onClick}
    >
      {props.label}
    </Styled.NavItemBox>
  );
};

export default NavSliderItem;
