import LoginBackground from "../../components/LoginBackground/LoginBackground";
import { Outlet } from "react-router-dom";
import cn from "classnames";
import Styled from "./Styled";
import Card from "../../components/common/Card";
import RegisterPageContainer from "pageComponents/register/RegisterPageContainer";

const RegistrationScreen = () => {
  return (
    <RegisterPageContainer>
      <div
        className={cn(
          "h-full flex flex-col align-center p-[36px] overflow-y-auto bg-[#fff] rounded-[15px] min-h-[calc(100vh-100px)]",
          "lg:flex-row lg:items-center lg:justify-center",
          "lg:p-[15px]"
        )}
      >
        <Outlet />
      </div>
    </RegisterPageContainer>
  );
};

export default RegistrationScreen;
