import { Category, EventType } from "models";
import { useMemo } from "react";
import theme from "utils/theme";
import Typography from "components/common/Typography";
import Box from '@mui/material/Box';
import { getLocalisedString } from "common/utils/stringHelper";
import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import { over } from "lodash";

const Container = styled.div<{ bgColor?: string }>`
  width: fit-content;
  max-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgColor};
  border-radius: 20px;
  height: 40px;
  padding: 0 20px;

  @media ${breakpoint.mobile} {
    max-width: 200px;
  }
`

interface TagProps {
  type: Category | EventType;
  bgColor?: string;
}

const CategoryTag = ({ type, bgColor = "rgb(255,121,37)" }: TagProps) => {

  return (
    <Container bgColor={bgColor}>
      {type.thumbnailSelected && (
        <img
          src={type.thumbnailSelected}
          className="mr-[10px] w-[20px] h-[20px]"
        />
      )}
      <Typography
        color={theme.white}
        style={{
          // lineHeight: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        textAlign="center"
      >
        {getLocalisedString(type?.name_en, type?.name_tc)}
      </Typography>
    </Container>
  );
};

export default CategoryTag;
