/**
 * Ref: https://gitlab.zensis.com/saisha/saisha-client/blob/develop/src/components/screens/BookingVoucherScreen.js
 */
import React, { useMemo, useState } from "react";
import _ from "lodash";
import cn from "classnames";
import BaseModal from "components/common/Modal/BaseModal";
import { useTranslation } from "react-i18next";
import { Voucher } from "models/Voucher";
import DropdownSelect from "components/common/Select/DropdownSelect";
import { getLocalizedString } from "utils/locale";
import { VoucherType } from "common/viewModels/VoucherViewModel";
import {
  CashVoucherCard,
  CashVoucherCardProps,
  CoachVoucherCard,
  CoachVoucherCardProps,
  EventVoucherCard,
  EventVoucherProps,
  FacilityVoucherCard,
  FacilityVoucherCardProps,
  PassVoucherCard,
  PassVoucherCardProps,
  useVoucherCardModel,
} from "components/VoucherListingCard";
import IconInformation from "assets/ic-information.svg";

type Props = {
  open: boolean;
  onClose: () => void;
  bookingVouchers: Voucher[];
  appliedVouchers: Voucher[];
  applyVoucher: (voucher: Voucher) => void;
};

// https://gitlab.zensis.com/saisha/saisha-client/blob/develop/src/components/screens/BookingVoucherScreen.js#L61
const VoucherCard = ({
  voucher,
  applyVoucher,
}: {
  voucher: Voucher;
  applyVoucher: Props["applyVoucher"];
}) => {
  const { getVoucherCardProps } = useVoucherCardModel();
  switch (voucher.voucher?.type) {
    case VoucherType.CASH: {
      const props = getVoucherCardProps(voucher) as CashVoucherCardProps;
      return (
        <CashVoucherCard
          {...props}
          color="secondary"
          // disableBundle TODO: only package flow display bundle
          onRedeemClick={() => applyVoucher(voucher)}
        />
      );
    }
    case VoucherType.FACILITY: {
      const props = getVoucherCardProps(voucher) as FacilityVoucherCardProps;
      return (
        <FacilityVoucherCard
          {...props}
          color="secondary"
          onRedeemClick={() => applyVoucher(voucher)}
        />
      );
    }
    case VoucherType.COACH: {
      const props = getVoucherCardProps(voucher) as CoachVoucherCardProps;
      return (
        <CoachVoucherCard
          {...props}
          // disableBundle TODO: only package flow display bundle
          color="secondary"
          onRedeemClick={() => applyVoucher(voucher)}
        />
      );
    }
    case VoucherType.DAY_PASS:
    case VoucherType.MONTHLY_PASS:
    case VoucherType.ANNUAL_PASS: {
      const props = getVoucherCardProps(voucher) as PassVoucherCardProps;
      return (
        <PassVoucherCard
          {...props}
          color="secondary"
          onRedeemClick={() => applyVoucher(voucher)}
        />
      );
    }
    case VoucherType.EVENT: {
      const props = getVoucherCardProps(voucher) as EventVoucherProps;
      return (
        <EventVoucherCard
          {...props}
          // disableBundle TODO: only package flow display bundle
          color="secondary"
          onRedeemClick={() => applyVoucher(voucher)}
        />
      );
    }
    default:
      return null;
  }
};

const BookingVoucherModal = (props: Props) => {
  const { applyVoucher, bookingVouchers, appliedVouchers, ...modalProps } =
    props;

  const { t } = useTranslation();

  const [filter, setFilter] = useState("all");

  const remainingVouchers = useMemo(
    () =>
      bookingVouchers.filter(
        (el) =>
          !appliedVouchers.some(
            (voucher) => voucher?.voucherId == el?.voucherId
          )
      ),
    [bookingVouchers, appliedVouchers]
  );

  const filterOptions = useMemo(() => {
    const options = _.uniq(
      remainingVouchers?.map((el) => el?.voucher?.type as string)
    );
    options.unshift("all");
    return options;
  }, [remainingVouchers]);

  return (
    <BaseModal {...modalProps}>
      <div
        className={cn(
          "block",
          "bg-white",
          "fixed",
          "z-[900]",
          "overflow-y-auto",
          "text-white",
          "bottom-0",
          "right-0",
          "w-full",
          // sync with event filter popup
          "h-[70vh]",
          "rounded-[20px_20px_0px_0px]",
          "animate-show-navbar-vertical", // animation from tailwind config
          "md:top-0",
          "md:w-9/12",
          "md:max-w-[435px]",
          "md:h-screen",
          "md:rounded-[30px_0_0px_30px]",
          "md:animate-show-navbar-horizontal" // animation from tailwind config
        )}
      >
        <div className={cn("h-full", "flex flex-col", "p-[58px_16px_30px]")}>
          <div className="pl-[34px] flex justify-between md:pr-[34px]">
            <p className="text-3xl font-medium leading-[26px] text-left text-black text-Poppin">
              {getLocalizedString(
                `${t("event:booking.add")} ${t("voucher:title")}`,
                t("event:booking.add") + t("voucher:title")
              )}
            </p>
            <button type="button" onClick={modalProps.onClose}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.292894 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292894 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L10.7478 9.3336L19.7885 0.292893C20.179 -0.0976311 20.8122 -0.0976311 21.2027 0.292893C21.5933 0.683417 21.5933 1.31658 21.2027 1.70711L12.162 10.7478L21.2023 19.7881C21.5929 20.1786 21.5929 20.8118 21.2023 21.2023C20.8118 21.5929 20.1786 21.5929 19.7881 21.2023L10.7478 12.162L1.70751 21.2023C1.31698 21.5929 0.683816 21.5929 0.293292 21.2023C-0.0972327 20.8118 -0.0972327 20.1786 0.293292 19.7881L9.3336 10.7478L0.292894 1.70711Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
          <div className="h-[40px]" />
          <div className="px-[34px]">
            <DropdownSelect
              options={filterOptions.map((each) => ({
                label: t(`voucher:filterOption.${each}`),
                value: each,
              }))}
              value={filter}
              onChange={setFilter}
            />
          </div>
          <div className="h-[26.48px]" />
          <div className="h-full px-[34px] overflow-y-auto">
            <div className=" flex flex-col gap-8">
              {remainingVouchers
                .filter(
                  (each) => filter === "all" || each.voucher?.type === filter
                )
                .map((each) => {
                  return (
                    <VoucherCard
                      key={each.voucherId}
                      voucher={each}
                      applyVoucher={applyVoucher}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default BookingVoucherModal;
