import {
  APPEAR_GLOBAL_ERROR,
  DISAPPEAR_GLOBAL_ERROR,
  APPEAR_GLOBAL_LOADING,
  DISAPPEAR_GLOBAL_LOADING,
  APPEAR_ACCOUNT_VERIFICATION,
  DISAPPEAR_ACCOUNT_VERIFICATION,
  APPEAR_PHONE_NUMBER_VERIFICATION,
  DISAPPEAR_PHONE_NUMBER_VERIFICATION,
  PUSH_GLOBAL_BLOCKING,
  POP_GLOBAL_BLOCKING,
} from "../actions/globalAction";

export const appearGlobalError = (dispatch, message, type = null) => {
  if (type) {
    dispatch({ type: APPEAR_GLOBAL_ERROR, payload: { message, type } });
  } else {
    dispatch({ type: APPEAR_GLOBAL_ERROR, message });
  }
};

export const disappearGlobalError = (dispatch) => {
  dispatch({ type: DISAPPEAR_GLOBAL_ERROR });
};

export const appearGlobalLoading = (dispatch) => {
  dispatch({ type: APPEAR_GLOBAL_LOADING });
};

export const disappearGlobalLoading = (dispatch) => {
  dispatch({ type: DISAPPEAR_GLOBAL_LOADING });
};

export const appearAccountVerification = (dispatch, isNewUser) => {
  dispatch({ type: APPEAR_ACCOUNT_VERIFICATION, isNewUser });
};

export const disappearAccountVerification = (dispatch) => {
  dispatch({ type: DISAPPEAR_ACCOUNT_VERIFICATION });
};

export const appearPhoneNumberVerification = (dispatch) => {
  dispatch({ type: APPEAR_PHONE_NUMBER_VERIFICATION });
};

export const disappearPhoneNumberVerification = (dispatch) => {
  dispatch({ type: DISAPPEAR_PHONE_NUMBER_VERIFICATION });
};

export const setKioskCert = (dispatch, cert) => {
  dispatch({ type: DISAPPEAR_ACCOUNT_VERIFICATION, kioskCert: cert });
};

export const pushGlobalBlocking = (dispatch, blockingFor) => {
  dispatch({ type: PUSH_GLOBAL_BLOCKING, blockingFor });
};

export const popGlobalBlocking = (dispatch, blockingFor) => {
  dispatch({ type: POP_GLOBAL_BLOCKING, blockingFor });
};
