import { GlobalContextStore } from "common/context/providers/globalProvider";
import { getLocalisedString } from "common/utils/stringHelper";
import { useMonthlyProgramBookingConfirmationViewModel } from "common/viewModels/MonthlyProgramBooking/MonthlyProgramBookingConfirmationViewModel";
import { useMonthlyProgramBookingViewModel } from "common/viewModels/MonthlyProgramBooking/MonthlyProgramBookingViewModel";
import PromoCodeBox from "components/boxs/PromoCodeBox";
import {
  IMonthlyProgramBookingConfirmationViewModel,
  IMonthlyProgrammeBookingViewModel,
} from "models/Programme";
import BookingBase, {
  ProgramTypeKeys,
} from "pageComponents/booking/BookingBase";
import Section from "pageComponents/booking/Section";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { createPayment } from "utils/common";
import {
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../@types/common";
import ProgramOrderInfo from "./Components/booking/ProgramOrderInfo";
import { MonthlyProgramClass } from "common/models/MonthlyProgram";
import { useVoucherViewModel } from "common/viewModels/VoucherViewModel";
import { IVoucherViewModel } from "models/Voucher";
import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { appearGlobalError } from "common/context/requests/globalRequest";
import { getCorrectUrl } from "navigators/Router";
import { ROUTE_BASE } from "Route";

const MonthlyProgramBooking = ({
  programClass,
}: {
  programClass: MonthlyProgramClass;
}) => {
  console.log("🚀 ~ programClass:", programClass.lessons);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { programId } = useParams();
  /* const { programClass }: { programClass: MonthlyProgramClass } =
    location.state; */
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { currentUser, isRetriving } =
    useContext<IUserViewModelContext>(UserViewModelContext);

  const {
    promoCode,
    appliedVouchers,
    applyPromoCode,
    removePromoCode,
    getBookingSummary,
    getTotalPrice,
    applyVoucher,
    removeVoucher,
  } = useMonthlyProgramBookingViewModel({
    programClass,
    dispatch: globalDispatch,
  }) as IMonthlyProgrammeBookingViewModel;
  const { confirmBooking } =
    useMonthlyProgramBookingConfirmationViewModel() as IMonthlyProgramBookingConfirmationViewModel;

  const { getBookingVouchers, bookingVouchers } = useVoucherViewModel({
    dispatch: globalDispatch,
  }) as IVoucherViewModel;

  const handleEnrollment = async () => {
    const payload = promoCode
      ? {
          classId: programClass._id,
          promocode: promoCode?.code,
          // promoCodeTicket: promoCode?.promoCodeTicketId,
          voucher:
            appliedVouchers.length > 0
              ? appliedVouchers.map((voucher) => voucher.voucherId!)
              : undefined,
          platform: "WEB",
        }
      : {
          classId: programClass._id,
          voucher:
            appliedVouchers.length > 0
              ? appliedVouchers.map((voucher) => voucher.voucherId!)
              : undefined,
          platform: "WEB",
        };
    const res = await confirmBooking(payload);
    console.log("🚀 ~ handleEnrollment ~ res:", res);
    if (res?.success === true) {
      await createPayment(res, globalDispatch, navigate, getTotalPrice() ?? 0);
    } else if (!res?.needVerification) {
      appearGlobalError(globalDispatch, res?.error);
    } else if (res?.isRedirectHandle) {
      navigate(
        getCorrectUrl(`${ROUTE_BASE.MONTHLY_PROGRAMME}/program/${programId}`)
      );
    }
  };

  const bookingSummary = getBookingSummary().bookingSummary;
  const {
    vouchers,
    promoCode: AppliedCode,
    ...commonSummaryProps
  } = bookingSummary;

  const programmeTnc = useMemo(() => {
    const tncs = programClass.programme
      ? {
          desc_en: programClass.programme?.tncDescriptionEn || "",
          desc_tc: programClass.programme?.tncDescriptionZh || "",
          title_en: programClass.programme?.tncTitleEn || "",
          title_tc: programClass.programme?.tncTitleZh || "",
        }
      : undefined;

    if (tncs) {
      const isAllTncFieldEmpty = Object.values(tncs).every((value) => !value);
      return isAllTncFieldEmpty ? undefined : tncs;
    }
    return tncs;
  }, [programClass]);

  const refreshVoucherList = useCallback(() => {
    if (!!programClass && currentUser && isRetriving === false) {
      getBookingVouchers({ bookingType: ProgramTypes.Program });
    }
  }, [currentUser, isRetriving, programClass]);

  return (
    <>
      <BookingBase
        programTypes={ProgramTypes.Program as ProgramTypeKeys}
        orderInfoComponent={
          <ProgramOrderInfo
            {...commonSummaryProps}
            className="flex flex-col w-full gap-3"
          />
        }
        tnc={programmeTnc}
        isRequireGeneralTncConfirm
        remarks={getLocalisedString(
          programClass.remarksEn,
          programClass.remarksZh
        )}
        handlePayment={handleEnrollment}
        receiptComponent={
          <ProgramOrderInfo
            {...commonSummaryProps}
            voucher={appliedVouchers}
            promoCode={promoCode ?? undefined}
            className="flex flex-col w-full gap-3 font-['poppins']"
          />
        }
        totalAmount={bookingSummary.totalPrice}
      >
        <>
          <Section
            title={`${t("event:booking.promoCode")}/${t(
              "event:booking.voucher"
            )}`}
          >
            <PromoCodeBox
              code={promoCode ?? undefined}
              onApplyPromoCode={applyPromoCode}
              onRemovePromoCode={removePromoCode}
              voucherDisabled={bookingSummary.totalPrice <= 0}
              selectedVouchers={appliedVouchers}
              availableVouchers={bookingVouchers}
              onApplyVoucher={applyVoucher}
              onRemoveVoucher={removeVoucher}
              refreshVoucherList={refreshVoucherList}
            />
          </Section>
        </>
      </BookingBase>
    </>
  );
};

export default MonthlyProgramBooking;
