import styled from "styled-components";
import { size } from "../../utils/responsive";
import theme from "utils/theme";

const PageBackgroundContainer = styled.div`
  background-color: ${theme.lightCyan};
  width: 100%;
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  min-height: calc(100vh - 120px - 163px);
  padding: 20px;
  @media (min-width: ${size.screen1024}px) {
    padding: 50px;
  }
`;

const PageContainer = styled.div`
  /** Ref: https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container */
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 20px;
  background: ${theme.lightCyan};
  padding: 22px 26px 24px;
`;

const CategoryWrapper = styled.div`
  margin-bottom: 44px;
`;

const RedeemButton = styled.button`
  padding: 6.1px 33.55px;
  border-radius: 10.675px;
  background: ${theme.blue};
  color: var(--palette-ss-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 36.6px; /* 228.75% */
`;

export default {
  PageBackgroundContainer,
  PageContainer,
  CategoryWrapper,
  RedeemButton,
};
