export interface ITimeslot {
  start_time: string;
  end_time: string;
  isOccupied: boolean;
  isPeakHour: boolean;
  isUnavailable: boolean;
  price: number;
  remainingQuota?: number;

  getDisplayTimeslot: () => string;
  bookingUnavailable: () => boolean;
}

export class Timeslot implements ITimeslot {
  start_time: string;
  end_time: string;
  isOccupied: boolean;
  isPeakHour: boolean;
  isUnavailable: boolean;
  price: number;
  remainingQuota?: number;

  constructor({
    start_time,
    end_time,
    isOccupied,
    isPeakHour,
    isUnavailable,
    price,
    remainingQuota,
  }: any) {
    this.start_time = start_time;
    this.end_time = end_time;
    this.isOccupied = isOccupied;
    this.isPeakHour = isPeakHour;
    this.isUnavailable = isUnavailable;
    this.price = price;
    this.remainingQuota = remainingQuota;
  }

  getDisplayTimeslot() {
    return `${this.start_time}-${this.end_time}`;
  }

  bookingUnavailable() {
    return this.isOccupied || this.isUnavailable;
  }
}
