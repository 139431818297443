import React, { useState, useEffect, useRef, useContext } from "react";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  useSearchParams,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import PageHeader from "../../components/common/PageHeader";

import { Wapper, Row } from "./Styled";
import { useCoachLessonListViewModel } from "common/viewModels/CoachLessionBooking/CoachLessonListViewModel";
import { ICoachLessonListViewModel } from "models/PrivateCoach";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import {
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../@types/common";
import {
  ItemWapper,
  BoxItem,
  ItemAva,
  ItemContentBox,
  ItemDes,
  ItemTitle,
  Paging,
  PagingItem,
} from "./Styled";
import { ROUTE_BASE } from "Route";
import CoachBanner from "assets/bg-coach-banner.png";
import { useCategoryListViewModel } from "common/viewModels/FacilityBooking/CategoryListViewModel";
import { FacilityCategory, ICategoryListViewModel } from "models";
import { getLocalisedString } from "common/utils/stringHelper";
import Typography from "components/common/Typography";
import Space from "components/common/Space";
import PageContainer from "components/common/PageContainer";
import Pagination from "components/common/Pagination";
import { Modules } from "common/models/Category";

interface Item {
  title: string;
  id: number;
  des: string;
  img: string;
  date: string;
}
const bgs = [
  "/bgs/1.svg",
  "/bgs/2.svg",
  "/bgs/3.svg",
  "/bgs/4.svg",
  "/bgs/5.svg",
  "/bgs/6.svg",
];

const randomBg = () => {
  return _.sample(bgs) as string;
};

const MAX_NUMBER_PER_PAGE = 4;

const CoachingListPage = () => {
  const { t } = useTranslation("coaching");
  const { id: categoryId } = useParams();
  const navigate = useNavigate();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { coachLessonList, getCoachLessonList } = useCoachLessonListViewModel({
    categoryId,
    dispatch: globalDispatch,
  }) as ICoachLessonListViewModel;
  const { categoryList, getCategoryList } =
    useCategoryListViewModel() as ICategoryListViewModel;
  const [category, setCategory] = useState<FacilityCategory>();

  useEffect(() => {
    if (categoryId != null) {
      getCoachLessonList();
      getCategoryList(Modules.COACH);
    }
  }, []);

  useEffect(() => {
    const foundCategory = _.find(categoryList, ["_id", categoryId]);
    if (!_.isEmpty(foundCategory)) setCategory(foundCategory);
  }, [categoryList]);

  const _coachElement = (data: any) => {
    return (
      <ItemWapper>
        {_.map(data, (item) => (
          <BoxItem onClick={() => navigate(`../coach/${item._id}`)}>
            <ItemAva bg={randomBg()}>
              <img
                src={item?.coach?.profile_pic_ref}
                className="object-cover"
              />
            </ItemAva>
            <ItemContentBox>
              <ItemTitle>{item.coach?.getName()}</ItemTitle>
              <ItemDes>{item.coach?.getTitle()}</ItemDes>
            </ItemContentBox>
          </BoxItem>
        ))}
      </ItemWapper>
    );
  };

  return (
    <PageContainer
      title={
        getLocalisedString(category?.name_en, category?.name_tc) ??
        t("privateCoachingList.screenHeader")
      }
      banner={category?.thumbnail_photo_ref ?? CoachBanner}
      screenHeader={
        !_.isEmpty(category)
          ? `${t("privateCoachingList.screenHeader")} (${getLocalisedString(
              category?.name_en,
              category?.name_tc
            )})`
          : ""
      }
      disableBg
    >
      <Wapper>
        <Pagination
          data={coachLessonList}
          itemContainer={_coachElement}
          itemPerPage={MAX_NUMBER_PER_PAGE}
        />
      </Wapper>
    </PageContainer>
  );
};

export default CoachingListPage;
