import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as OrangeCrossSvg } from "../../assets/orange-cross-btn.svg";

const CardContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  background: #f5f5f5;
  padding: 23px 32px 29px;
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  & .status-text {
    color: var(--palette-grey-text, #8c8c8c);
  }
`;

type Props = {
  email: string;
  name_en: string;
  name_zh: string;
  status: boolean;
  onCancel?: (email: string) => void | Promise<void>;
};

const GuardianListCard = (props: Props) => {
  const { onCancel } = props;
  const { t, i18n } = useTranslation("profile");

  const guardianName = useMemo(() => {
    return i18n.language === "zh" ? props.name_zh : props.name_en;
  }, [i18n, props]);

  const statusText = useMemo(() => {
    return props?.status
      ? t("personalInfo.approved")
      : t("personalInfo.pending");
  }, [t, props]);

  return (
    <CardContainer className="flex justify-between items-start">
      <div className="flex flex-col gap-[10px]">
        <p>{guardianName}</p>
        <p>{props.email}</p>
        <p className="status-text">{statusText}</p>
      </div>
      <button type="button" onClick={() => onCancel && onCancel(props.email)}>
        <OrangeCrossSvg />
      </button>
    </CardContainer>
  );
};

export default GuardianListCard;
