import { NotificationHistory } from "common/models/Notification";
import React from "react";
import dayjs from "common/utils/dayjs";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import theme from "utils/theme";
import HTMLTypography from "components/common/HTMLTypography";
import { NotificationType } from "models/Notification";
import CategoryTag from "./CategoryTag";

type NotificationItemProps = {
  notification: NotificationHistory;
  notificationType: NotificationType;
} & React.HTMLProps<HTMLDivElement>;

const NotificationItem = ({
  notification,
  notificationType,
  onClick,
}: NotificationItemProps) => {
  const { t } = useTranslation("notification");
  return (
    <div className="flex flex-col bg-white px-8 py-4 rounded-[5px]">
      {notificationType === NotificationType.MESSAGE && (
        <div className="flex justify-between pb-2">
          <>
            {" "}
            {notification?.notification_category_tag && (
              <CategoryTag tag={notification.notification_category_tag} />
            )}
            <div className="max-md:hidden">
              {dayjs(notification.sent_at).tz().format("DD/MM/YYYY")}
            </div>
          </>
        </div>
      )}
      <div
        className="font-medium cursor-pointer  flex justify-between"
        onClick={onClick}
      >
        {/**don't know why can't use theme.orange for this*/}
        <div className={cn(`hover:text-[#CB5D38]`)}>
          {notification.message?.title}
        </div>
        {notificationType === NotificationType.GENERAL && (
          <div className="max-md:hidden flex-shrink-0 text-xs font-light">
            {dayjs(notification.sent_at).tz().format("DD/MM/YYYY")}
          </div>
        )}
      </div>
      {notificationType === NotificationType.MESSAGE &&
        notification.message?.body && (
          <HTMLTypography
            content={notification.message.body}
            className={cn([
              "font-light",
              `text-[${theme.grey}]`,
              "max-md:hidden",
              "line-clamp-6",
            ])}
          ></HTMLTypography>
        )}
      {/* notification.additional && <div>{notification.additional}</div> */}
      <div
        className={cn([
          "md:hidden",
          "pt-2",
          "text-xs",
          "font-light",
          `text-[${theme.grey}]`,
        ])}
      >
        {dayjs(notification.sent_at).tz().format("DD/MM/YYYY")}
      </div>
    </div>
  );
};

export default NotificationItem;
