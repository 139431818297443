import { useTranslation } from "react-i18next";
import Section from "./Section";
import Space from "components/common/Space";
import CommonTextfield from "components/common/Textfield";
import IconBlueRemove from "assets/ic-blueRemove.svg";
import theme from "utils/theme";
import Styled from "./Styled";
import Typography from "components/common/Typography";
import { useState } from "react";
import SimpleModal from "components/common/Modal/SimpleTextModal";
import AddGuestModal from "./AddGuestModal";
import AddGuestListModal from "components/modals/AddGuestListModal/AddGuestListModal";

interface GuestInviteProps {
  guests: { email: string }[];
  addGuestAvailableCount: number;
  addGuests: (data: string) => Promise<any>;
  removeGuest: (data: { email: string }) => Promise<void>;
}

const BookingGuestInvite = ({
  guests,
  addGuestAvailableCount,
  addGuests,
  removeGuest,
}: GuestInviteProps) => {
  const { t } = useTranslation("myBooking");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Section title={t("detail.guest")}>
      <div>
        {guests?.map((item, idx) => (
          <div key={idx} className="mb-[20px]">
            {t("myBooking:detail.guest")} {idx + 1}
            <div className="flex">
              <CommonTextfield disabled value={item?.email} />
              <div className="flex items-center ml-[10px] cursor-pointer">
                <img src={IconBlueRemove} />
                <Space width="5px" />
                <Typography
                  color={theme.blue}
                  onClick={() => removeGuest(item)}
                >
                  {t("myBooking:detail.remove")}
                </Typography>
              </div>
            </div>
          </div>
        ))}
        <Styled.UnderlineText
          onClick={() => (addGuestAvailableCount < 1 ? null : setIsOpen(true))}
          disabled={addGuestAvailableCount < 1}
        >
          {`+ ${t("myBooking:detail.addMoreGuest")}`}
        </Styled.UnderlineText>
      </div>

      <AddGuestListModal
        id="add-guest-list-modal"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onAddGuestList={addGuests}
        guestListLimit={addGuestAvailableCount}
      />
    </Section>
  );
};

export default BookingGuestInvite;
