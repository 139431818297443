import { GlobalContextStore } from "../context/providers/globalProvider";
import {
  appearGlobalError,
  appearGlobalLoading,
  disappearGlobalLoading,
} from "../context/requests/globalRequest";
import { IEnquiryFormSubmit, IEnquiryFormViewModel } from "../models/Enquiry";
import { IGlobalProviderContext } from "../models/Common";
import { createEnquiryFormAPI } from "../network/enquiry";
import { DispatchWithoutAction, useContext } from "react";
import { AxiosError } from "axios";
import { getLocalisedString } from "../utils/stringHelper";
import { useCommonFetcher } from "./Common/CommonViewModel";

export const useEnquiryViewModel = () => {
    const { fetcher } = useCommonFetcher();
  
    const createEnquiryForm = async (payload: IEnquiryFormSubmit) => {
      const res = await fetcher({api: () => createEnquiryFormAPI(payload)});
      return res;
    };
    
    return {
      createEnquiryForm,
    };
  };
  