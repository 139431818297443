export enum AddressDistrict {
  ISLANDS = "ISLANDS",
  KWAI_TSING = "KWAI_TSING",
  NORTH = "NORTH",
  SAI_KUNG = "SAI_KUNG",
  SHA_TIN = "SHA_TIN",
  TAI_PO = "TAI_PO",
  TSUEN_WAN = "TSUEN_WAN",
  TUEN_MUN = "TUEN_MUN",
  YUEN_LONG = "YUEN_LONG",
  KOWLOON_CITY = "KOWLOON_CITY",
  KWUN_TONG = "KWUN_TONG",
  SHAM_SHUI_PO = "SHAM_SHUI_PO",
  WONG_TAI_SIN = "WONG_TAI_SIN",
  YAU_TSIM_MONG = "YAU_TSIM_MONG",
  CENTRAL_AND_WESTERN = "CENTRAL_AND_WESTERN",
  EASTERN = "EASTERN",
  SOUTHERN = "SOUTHERN",
  WAN_CHAI = "WAN_CHAI",
  OTHERS = "OTHERS",
}

export const districtList: AddressDistrict[] = [
  AddressDistrict.ISLANDS,
  AddressDistrict.KWAI_TSING,
  AddressDistrict.NORTH,
  AddressDistrict.SAI_KUNG,
  AddressDistrict.SHA_TIN,
  AddressDistrict.TAI_PO,
  AddressDistrict.TSUEN_WAN,
  AddressDistrict.TUEN_MUN,
  AddressDistrict.YUEN_LONG,
  AddressDistrict.KOWLOON_CITY,
  AddressDistrict.KWUN_TONG,
  AddressDistrict.SHAM_SHUI_PO,
  AddressDistrict.WONG_TAI_SIN,
  AddressDistrict.YAU_TSIM_MONG,
  AddressDistrict.CENTRAL_AND_WESTERN,
  AddressDistrict.EASTERN,
  AddressDistrict.SOUTHERN,
  AddressDistrict.WAN_CHAI,
  AddressDistrict.OTHERS,
];
