import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { ROUTE_BASE } from "../../Route";
import RegistrationScreen from "./RegistrationScreen";
import { RegistrationGreeting } from "./RegistrationGreeting";
import RegistrationStepControl from "./RegistrationStepControl";

const RegistrationRouter: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <RegistrationScreen />,
      children: [
        {
          path: "/",
          element: <RegistrationStepControl />,
        },
        {
          path: "/greeting",
          element: <RegistrationGreeting />,
        },
        {
          path: "*",
          element: <Navigate to={`/${ROUTE_BASE.REGISTER}`} />,
        },
      ],
    },
  ]);
};

export default RegistrationRouter;
