import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import LoginRouter from "./pages/LoginScreen/LoginRouter";
import RegistrationRouter from "./pages/RegistrationScreen/RegistrationRouter";
import ProfileRouter from "./pages/ProfileScreen/ProfileRouter";
import EventRouter from "./pages/Event/EventRouter";
import SportPassRouter from "./pages/SportPass/SportPassRouter";
import MyBookingRouter from "./pages/MyBookingScreen/MyBookingRouter";
import NewRouter from "./pages/New/NewRouter";
import DiscoverRouter from "./pages/Discover/DiscoverRouter";
import MonthlyProgramRouter from "./pages/MonthlyProgram/MonthlyProgramRouter";
import VoucherRouter from "pages/VoucherScreen/VoucherRouter";
import MoreInformationScreen from "pages/MoreInformationScreen/MoreInformationScreen";
import FacilityRouter from "./pages/FacilityScreen/FacilityRouter";
import PrivateCoachingRouter from "./pages/PrivateCoaching/PrivateCoachingRouter";
import PaymentRouter from "pages/Payment/PaymentRouter";
import HeadlineNewsRouter from "pages/HeadlineNews/HeadlineNewsRouter";
import PackageRouter from "pages/Package/PackageRouter";
import NotificationRouter from "pages/Notification/NotificationRouter";
import TncRouter from "pages/Tnc/TncRouter";
import ContactUs from "pages/ContactUs";
import FAQ from "pages/FAQ";
import LanguagePath from "utils/LanuagePath";
import { useTranslation } from "react-i18next";
import EnquiryRouter from "pages/Enquiry/EnquiryRouter";
import Chatbot from "pages/Chatbot";
import MyPassScreen from "pages/MyPassScreen/MyPassScreen";
import ChangePasswordRoute from "pages/ChangePassword/ChangePasswordRouter";
export enum ROUTE_BASE {
  AUTH = "auth",
  REGISTER = "register",
  PROFILE = "profile",
  NOTIFICATION = "notification",
  EVENT = "event",
  HEADLINE_NEWS = "headline-news",
  NEWS = "classhighlights",
  DISCOVER = "discover",
  SPORT_PASS = "sport-pass",
  MY_BOOKINGS = "my-bookings",
  MY_PASS = "my-pass",
  VOUCHER = "voucher",
  DOWNLOAD_APP = "download-app",
  FACILITY_BOOKING = "facility",
  PAYMENT = "payment",
  MONTHLY_PROGRAMME = "regulargroupclasses",
  PRIVATE_COACH = "private-coach",
  PACKAGE_OFFER = "package-offer",
  TNC = "tnc",
  CONTACT_US = "contact-us",
  FAQ = "general",
  ENQUIRY = "enquiry",
  CHANGE_PASSWORD = "change-password",
}

const CustomRoutes = () => {
  const { i18n } = useTranslation();
  return (
    <Routes>
      <Route path="/" element={<Navigate to={`/${i18n.language}`} replace />} />
      <Route path=":lang" element={<LanguagePath />}>
        <Route path="" element={<Home />} />
        <Route path={`${ROUTE_BASE.AUTH}/*`} element={<LoginRouter />} />
        <Route
          path={`${ROUTE_BASE.REGISTER}/*`}
          element={<RegistrationRouter />}
        />
        <Route
          path={`${ROUTE_BASE.NOTIFICATION}/*`}
          element={<NotificationRouter />}
        />
        <Route path={`${ROUTE_BASE.PROFILE}/*`} element={<ProfileRouter />} />
        <Route path={`${ROUTE_BASE.EVENT}/*`} element={<EventRouter />} />
        <Route
          path={`${ROUTE_BASE.HEADLINE_NEWS}/*`}
          element={<HeadlineNewsRouter />}
        />
        <Route path={`${ROUTE_BASE.NEWS}/*`} element={<NewRouter />} />
        {/* <Route path={`${ROUTE_BASE.DISCOVER}/*`} element={<DiscoverRouter />} /> */}
        {/* <Route
          path={`${ROUTE_BASE.SPORT_PASS}/*`}
          element={<SportPassRouter />}
        /> */}
        <Route
          path={`${ROUTE_BASE.MONTHLY_PROGRAMME}/*`}
          element={<MonthlyProgramRouter />}
        />
        <Route
          path={`${ROUTE_BASE.PRIVATE_COACH}/*`}
          element={<PrivateCoachingRouter />}
        />
        <Route path={`${ROUTE_BASE.VOUCHER}/*`} element={<VoucherRouter />} />
        <Route
          path={`${ROUTE_BASE.DOWNLOAD_APP}`}
          element={<MoreInformationScreen />}
        />
        <Route path={`${ROUTE_BASE.MY_PASS}`} element={<MyPassScreen />} />
        <Route
          path={`${ROUTE_BASE.MY_BOOKINGS}/*`}
          element={<MyBookingRouter />}
        />
        <Route
          path={`${ROUTE_BASE.FACILITY_BOOKING}/*`}
          element={<FacilityRouter />}
        />
        <Route path={`${ROUTE_BASE.PAYMENT}/*`} element={<PaymentRouter />} />
        {/* <Route
          path={`${ROUTE_BASE.PACKAGE_OFFER}/*`}
          element={<PackageRouter />}
        /> */}
        <Route path={`${ROUTE_BASE.TNC}/*`} element={<TncRouter />} />
        <Route path={`${ROUTE_BASE.CONTACT_US}/*`} element={<ContactUs />} />
        <Route path={`${ROUTE_BASE.ENQUIRY}/*`} element={<EnquiryRouter />} />
        <Route
          path={`${ROUTE_BASE.CHANGE_PASSWORD}/*`}
          element={<ChangePasswordRoute />}
        />
        <Route path={ROUTE_BASE.FAQ}>
          <Route path={`faq`} element={<FAQ />} />
          <Route path={`chatbot`} element={<Chatbot />} />
          <Route
            path="*"
            element={<Navigate to={`/${i18n.language}`} replace />}
          />
        </Route>
        <Route
          path="*"
          element={<Navigate to={`/${i18n.language}`} replace />}
        />
      </Route>
    </Routes>
  );
};

export default CustomRoutes;
