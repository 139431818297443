import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  useSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { Select, SelectOption } from "../../components/common/Select";
import PageHeader from "../../components/common/PageHeader";

import { Wapper, Content, Title, Row } from "./Styled";
import { LatestNew } from "models";
import { ROUTE_BASE } from "Route";
import { getLocalisedString } from "common/utils/stringHelper";
import HTMLTypography from "components/common/HTMLTypography";
import Space from "components/common/Space";
import PageContainer from "components/common/PageContainer";
import { LatestNewsViewModel } from "common/viewModels/LatestNewViewModel";
import { ILatestNewViewModel } from "common/models/LatestNew";
import { removeHtmlTag } from "utils/common";
import { getLocalizedPageTitleWithPrefix } from "utils/seo";

const NewDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { latestNewsDetail, getLatestNewsDetail } =
    LatestNewsViewModel() as ILatestNewViewModel;

  useEffect(() => {
    getLatestNewsDetail(id as string).then((res) => {
      if (!res?.success) navigate("../");
    });
  }, []);

  const getMetaDescription = () => {
    const description = getLocalisedString(
      latestNewsDetail?.desc_en,
      latestNewsDetail?.desc_zh
    );
    return removeHtmlTag(description);
  };

  return (
    <>
      <Helmet>
        <title>
          {getLocalizedPageTitleWithPrefix({
            en: latestNewsDetail?.title_en,
            tc: latestNewsDetail?.title_zh,
          })}
        </title>
        <meta name="description" content={getMetaDescription()} />
      </Helmet>
      <PageContainer
        title={getLocalisedString(
          latestNewsDetail?.title_en,
          latestNewsDetail?.title_zh
        )}
        banner={getLocalisedString(
          latestNewsDetail?.thumbnail_ref_en,
          latestNewsDetail?.thumbnail_ref_zh
        )}
      >
        <Title>
          {getLocalisedString(
            latestNewsDetail?.sub_title_en,
            latestNewsDetail?.sub_title_zh
          )}
        </Title>
        <Row>
          <HTMLTypography
            content={getLocalisedString(
              latestNewsDetail?.desc_en,
              latestNewsDetail?.desc_zh
            )}
          />
        </Row>
        <Space size="163px" />
      </PageContainer>
    </>
  );
};

export default NewDetail;
