import { getPaymentStatus as getPaymentStatusReq } from "../network/payment";

export const usePaymentViewModel = () => {
  /**
   * get payment status of transaction id
   * @param {string} id transaction id from the create booking API response
   * @returns payment status
   */
  const getPaymentStatus = async (id) => {
    try {
      const res = await getPaymentStatusReq(id);
      return res;
    } catch (e) {
      console.log("getPaymentStatusReq exception", e);
      if (e.response.data) {
        return e.response.data;
      }
      return {
        success: false,
        error: e.message,
      };
    }
  };

  return {
    getPaymentStatus,
  };
};
