import React, { useCallback, useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useBackButton } from "hooks/useBackButton";
import PageBackButton from "components/common/PageBackButton";
import Styled from "./Styled";
import ProfileLayout from "layouts/ProfileLayout/ProfileLayout";
import { IUserViewModelContext } from "../../@types/common";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { useSplashScreen } from "hooks/useSplashScreen";
import { ROUTE_BASE } from "Route";
import { getCorrectUrl } from "navigators/Router";

const VoucherScreen = () => {
  const { isShowBack, setIsShowBack } = useBackButton();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { currentUser, isRetriving: isRetrivingUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { isSplashScreen } = useSplashScreen();

  const back = useCallback(() => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate("/voucher");
    }
  }, [navigate]);

  useEffect(() => {
    if (isRetrivingUser === false && currentUser == null) {
      navigate(getCorrectUrl(ROUTE_BASE.AUTH + "/sign-in"), { replace: true });
    }
  }, [isRetrivingUser, currentUser, navigate]);

  if (isSplashScreen) return null;

  return (
    <ProfileLayout>
      <main>
        <div className="flex flex-col">
          {isShowBack ? (
            <div className={cn("w-full", "hidden", "mb-[25px]", "lg:block")}>
              <PageBackButton
                onClick={back}
                text={t("monthlyProgram:monthlyProgramDetail.btnBack")}
              />
            </div>
          ) : null}
          <Styled.PageContainer>
            <Outlet context={{ setIsShowBack, currentUser }} />
          </Styled.PageContainer>
        </div>
      </main>
    </ProfileLayout>
  );
};

export default VoucherScreen;
