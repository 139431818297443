import { TAndC } from "../Facility";
import { Zone } from "../Event";
import { getLocalisedString } from "../../utils/stringHelper";

type TRegistrationTarget = "ANNUAL" | "MONTHLY" | "DAY" | "VERIFIED";

export interface IEvent {
  _id: string;
  title_en: string;
  title_tc: string;
  desc_en?: string;
  desc_tc?: string;
  category: Category;
  registration_target: TRegistrationTarget | TRegistrationTarget[];
  publish_start_date: string;
  publish_end_date: string;
  price: number;
  event_date: EventDate[];
  status: string;
  created_at: string;
  updated_at: string;
  approve_at: string;
  admission_target: string;
  allow_guest_invite: boolean;
  guest_invite_limit: number;
  max_age: number;
  thumbnail_en: string;
  thumbnail_tc: string;
  banner_en: string;
  banner_tc: string;
  event_type: EventType;
  venue_en: string;
  venue_tc: string;
  remarks_en?: string;
  remarks_tc?: string;
  t_and_c?: TAndC;
  zone?: Zone[];
  isFull: boolean;

  readonly title: string;
  readonly desc: string | undefined;
  readonly thunbnail: string;
  readonly banner: string;
  readonly venue: string;
  readonly remarks: string | undefined;
}

export interface Category {
  created_by: string;
  created_time: string;
  facility_list: string[];
  id: string;
  name_en: string;
  name_tc: string;
  updated_by: string;
  updated_time: string;
  _id: string;
}

export interface EventType {
  displayIndex: number;
  id: string;
  isSport: boolean;
  name_en: string;
  name_tc: string;
  thumbnail: string;
  thumbnailSelected: string;
  _id: string;
}

export interface EventDate {
  start_date: string;
  end_date: string;
}

export class Event implements IEvent {
  _id: string;
  title_en: string;
  title_tc: string;
  desc_en?: string;
  desc_tc?: string;
  category: Category;
  registration_target: TRegistrationTarget | TRegistrationTarget[];
  publish_start_date: string;
  publish_end_date: string;
  price: number;
  event_date: EventDate[];
  status: string;
  created_at: string;
  updated_at: string;
  approve_at: string;
  admission_target: string;
  allow_guest_invite: boolean;
  guest_invite_limit: number;
  max_age: number;
  thumbnail_en: string;
  thumbnail_tc: string;
  banner_en: string;
  banner_tc: string;
  event_type: EventType;
  venue_en: string;
  venue_tc: string;
  remarks_en?: string;
  remarks_tc?: string;
  t_and_c?: TAndC;
  zone?: Zone[];
  isFull: boolean;

  constructor({
    _id,
    title_en,
    title_tc,
    desc_en,
    desc_tc,
    category,
    registration_target,
    publish_start_date,
    publish_end_date,
    price,
    event_date,
    status,
    created_at,
    updated_at,
    approve_at,
    admission_target,
    allow_guest_invite,
    guest_invite_limit,
    max_age,
    thumbnail_en,
    thumbnail_tc,
    banner_en,
    banner_tc,
    event_type,
    venue_en,
    venue_tc,
    remarks_en,
    remarks_tc,
    t_and_c,
    zone,
    isFull,
  }: any) {
    this._id = _id;
    this.title_en = title_en;
    this.title_tc = title_tc;
    this.desc_en = desc_en;
    this.desc_tc = desc_tc;
    this.category = category;
    this.registration_target = registration_target;
    this.publish_start_date = publish_start_date;
    this.publish_end_date = publish_end_date;
    this.price = price;
    this.event_date = event_date;
    this.status = status;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.approve_at = approve_at;
    this.admission_target = admission_target;
    this.allow_guest_invite = allow_guest_invite;
    this.guest_invite_limit = guest_invite_limit;
    this.max_age = max_age;
    this.thumbnail_en = thumbnail_en;
    this.thumbnail_tc = thumbnail_tc;
    this.banner_en = banner_en;
    this.banner_tc = banner_tc;
    this.event_type = event_type;
    this.venue_en = venue_en;
    this.venue_tc = venue_tc;
    this.remarks_en = remarks_en;
    this.remarks_tc = remarks_tc;
    this.t_and_c = t_and_c;
    this.zone = zone;
    this.isFull = isFull;
  }

  get title(): string {
    return getLocalisedString(this.title_en, this.title_tc);
  }

  get desc(): string | undefined {
    return getLocalisedString(this.desc_en, this.desc_tc);
  }

  get thunbnail(): string {
    return getLocalisedString(this.thumbnail_en, this.thumbnail_tc);
  }

  get banner(): string {
    return getLocalisedString(this.banner_en, this.banner_tc);
  }

  get venue(): string {
    return getLocalisedString(this.venue_en, this.venue_tc);
  }

  get remarks(): string | undefined {
    return getLocalisedString(this.title_en, this.title_tc);
  }
}
