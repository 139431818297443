import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as _ from "lodash";
import { ResponsiveUI, WindowSize, breakpoint } from "utils/responsive";
import theme from "utils/theme";
import Typography from "components/common/Typography";
import Slider, { CustomArrowProps } from "react-slick";
import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import { useNavigate } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import { getCorrectUrl } from "navigators/Router";
import GeneralButton from "components/common/Button";
import IconRightArrow from "assets/ic-orangeSliderArrowRight.svg";
import IconLeftArrow from "assets/ic-orangeSliderArrowLeft.svg";
import {
  UpcomingBooking,
  UpcomingCoachLesson,
  UpcomingEvent,
  UpcomingFacilityBooking,
  UpcomingMonthlyProgram,
} from "../../@types/common";
import {
  UpcomingCoachCard,
  UpcomingEventCard,
  UpcomingFacilityCard,
  UpcomingProgramCard,
} from "./upcoming-cards";
import { UpcomingBookingType } from "models";

const Container = styled.div`
  background: rgba(255, 255, 255, 0.5);
  height: auto;
  width: 100%;
  padding: 40px;
`;

const StyledSlider = styled(Slider)`
  width: calc(100% - 120px);

  @media ${breakpoint.mobile} {
    width: calc(100% - 60px);
  }

  .slick-slide {
    padding: 0 10px;
    width: 25%;
  }
`;

const NextArrow = styled.img`
  top: 50%;
  right: -70px;
  position: absolute;
  width: 60px;
  height: 60px;
  z-index: 100;
  cursor: pointer;

  @media ${breakpoint.mobile} {
    width: 30px;
    height: 30px;
    right: -40px;
  }
`;

const PrevArrow = styled(NextArrow)`
  left: -70px;

  @media ${breakpoint.mobile} {
    width: 30px;
    height: 30px;
    left: -40px;
  }
`;

interface UpcomingProps {
  bookingList: UpcomingBooking[];
  bookingType?: string;
}

const Upcoming = ({
  bookingList,
  bookingType = ProgramTypes.Facility, //TODO: Using Facility Booking Order info
}: UpcomingProps) => {
  const { t } = useTranslation("home");
  const navigate = useNavigate();
  const isMobile = WindowSize() === "mobile";

  const CustomPrevArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return <PrevArrow src={IconLeftArrow} onClick={onClick} />;
  };

  const CustomNextArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return <NextArrow src={IconRightArrow} onClick={onClick} />;
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 3,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const tagColor = useMemo(() => {
    switch (bookingType) {
      case ProgramTypes.Program:
        return "#00AA00";
      case ProgramTypes.Coach:
        return "#FF3366";
      case ProgramTypes.Event:
        return "#204EE5";
      case ProgramTypes.Facility:
        return "#FF7925";
      default:
        return "#FF7925";
    }
  }, [bookingType]);

  return (
    <div>
      <div className="my-[20px] flex justify-center">
        <Typography
          fontSize="35px"
          mFontSize="24px"
          fontWeight="700"
          color={theme.black}
        >
          {t("booking.title")}
        </Typography>
      </div>
      <Container>
        <div className="flex justify-center">
          <StyledSlider {...settings}>
            {_.map(bookingList, (item) => {
              switch (item.type) {
                case UpcomingBookingType.FACILITY:
                  return (
                    <UpcomingFacilityCard
                      booking={item as UpcomingFacilityBooking}
                    />
                  );
                case UpcomingBookingType.COACH_LESSON:
                  return (
                    <UpcomingCoachCard booking={item as UpcomingCoachLesson} />
                  );
                case UpcomingBookingType.EVENT:
                  return <UpcomingEventCard booking={item as UpcomingEvent} />;
                case UpcomingBookingType.MONTHLY_PROGRAM:
                  return (
                    <UpcomingProgramCard
                      booking={item as UpcomingMonthlyProgram}
                    />
                  );
                default:
                  return null;
              }
            })}
          </StyledSlider>
        </div>

        <div className="flex justify-center mt-[40px]">
          <GeneralButton
            onClick={() => navigate(getCorrectUrl(ROUTE_BASE.MY_BOOKINGS))}
          >
            {t("event.more")}
          </GeneralButton>
        </div>
      </Container>
    </div>
  );
};

export default Upcoming;
