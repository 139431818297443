import * as _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import classNames from "classnames";
import GeneralButton from "components/common/Button";
import { Formik, FormikBag, FormikHelpers, FormikProps, Field } from "formik";
import { ProgrammeListByCategoryFilter } from "models/Programme";
import { ReactComponent as CrossSvg } from "../../../assets/white-cross.svg";
import {
  Box,
  Category,
  FilterViewWapper,
  Label,
  Row,
  SearchInput,
} from "../Styled";
// import Checkbox from "components/common/Checkbox";
import { ProgrammeListByCategoryFilterForm } from "../MonthlyProgramByCategory";
import { ReactComponent as UncheckedToggle } from "../../../assets/grey-toggle-unchecked.svg";
import { ReactComponent as CheckedToggle } from "../../../assets/orange-toggle-checked.svg";
import Typography from "components/common/Typography";
import theme from "utils/theme";

const days = [
  {
    id: 1,
    name: "mon",
  },
  {
    id: 2,
    name: "tue",
  },
  {
    id: 3,
    name: "wed",
  },
  {
    id: 4,
    name: "thu",
  },
  {
    id: 5,
    name: "fri",
  },
  {
    id: 6,
    name: "sat",
  },
  {
    id: 7,
    name: "sun",
  },
];
interface FilterViewProps {
  onCloseFilterView: () => void;
  formik: FormikProps<ProgrammeListByCategoryFilterForm>;
  isLoggedIn: boolean;
  ageLimitError: boolean;
  setAgeLimitError: (error: boolean) => void;
  resetPreviousValue: () => void;
}

interface Category {
  id: number;
  name: string;
  active: boolean;
}

const FilterView: React.FC<FilterViewProps> = ({
  onCloseFilterView,
  formik,
  isLoggedIn,
  ageLimitError,
  setAgeLimitError,
  resetPreviousValue,
}) => {
  const { t } = useTranslation("monthlyProgram");

  const handleSelectDay = (
    formik: FormikProps<ProgrammeListByCategoryFilterForm>,
    id: number | "all"
  ) => {
    const selectedWeekDays = formik.values.weekDays;
    const indexOfSelectedDay = selectedWeekDays?.findIndex(
      (weekDay) => weekDay === id
    );

    if (id === "all") {
      formik.setFieldValue("weekDays", []);
      return;
    }
    if (indexOfSelectedDay >= 0) {
      formik.setFieldValue(
        "weekDays",
        selectedWeekDays.filter((weekDay) => weekDay !== id)
      );
    } else {
      formik.setFieldValue("weekDays", [...selectedWeekDays, id]);
    }
  };

  const isSearchMyAge = formik.values.isSearchMyAge ?? false;

  return (
    <FilterViewWapper>
      <Row display="flex" direction="column">
        <Label>{t("monthlyProgram:placeholder.search")}</Label>
        <SearchInput
          placeholder={t("monthlyProgram:placeholder.search")}
          name="keyword"
          value={formik.values.keyword ?? ""}
          onChange={formik.handleChange}
        ></SearchInput>
      </Row>
      <Row display="flex" direction="column">
        <Label>{t("monthlyProgram:monthlyProgramFilter.textAgeRange")}</Label>
        {isLoggedIn && (
          <Row
            display="flex"
            margin="0px 0px"
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Label style={{ color: "#8C8C8C" }}>
              {t("monthlyProgram:monthlyProgramFilter.searchMyAge")}
            </Label>
            <Checkbox
              name="isSearchMyAge"
              icon={<UncheckedToggle />}
              checkedIcon={<CheckedToggle />}
              checked={isSearchMyAge}
              onChange={(e: any) =>
                formik.setFieldValue("isSearchMyAge", e.target.checked)
              }
            />
          </Row>
        )}
        {!isSearchMyAge && (
          <Row display="flex" margin="0px 0px">
            <Box width="calc(50% - 40px)">
              <SearchInput
                placeholder={t(
                  "monthlyProgram:monthlyProgramFilter.textMinimum"
                )}
                name="minAge"
                value={formik.values.minAge ?? ""}
                onChange={formik.handleChange}
              ></SearchInput>
            </Box>
            <Box width="80px" className="linked"></Box>
            <Box width="calc(50% - 40px)">
              <SearchInput
                placeholder={t(
                  "monthlyProgram:monthlyProgramFilter.textMaximum"
                )}
                name="maxAge"
                value={formik.values.maxAge ?? ""}
                onChange={formik.handleChange}
              ></SearchInput>
            </Box>
          </Row>
        )}
        {ageLimitError && (
          <Typography color={theme.error} fontSize="14px">
            {t("monthlyProgram:error.minAgeLessThanMaxAge")}
          </Typography>
        )}
      </Row>
      <Row display="flex" wrap="wrap">
        <Label>{t("monthlyProgram:monthlyProgramFilter.textDay")}</Label>

        <Row display="flex" wrap="wrap" margin="0px 0px">
          <Category
            key={"all"}
            onClick={() => handleSelectDay(formik, "all")}
            className={formik.values.weekDays.length === 0 ? "active" : ""}
          >
            {t(`monthlyProgramFilter.allDays`)}
          </Category>
          {_.map(days, (day) => (
            <Category
              key={day.id}
              onClick={() => handleSelectDay(formik, day.id)}
              className={
                formik.values.weekDays.findIndex(
                  (weekDay) => weekDay === day.id
                ) >= 0
                  ? "active"
                  : ""
              }
            >
              {t(`common:dateFormat.isoWeekday.${day.name}`)}
            </Category>
          ))}
        </Row>
      </Row>
      <Row display="flex" content="space-between">
        <div
          className="cursor-pointer text-app-orange"
          onClick={() => {
            formik.resetForm();
            setAgeLimitError(false);
            resetPreviousValue();
          }}
        >
          {t("monthlyProgramFilter.textReset")}
        </div>
        <GeneralButton
          type="submit"
          onClick={() => formik.handleSubmit()}
          disabled={!_.isEmpty(formik.errors)}
        >
          {t("monthlyProgramFilter.textConfirm")}
        </GeneralButton>
      </Row>

      <button
        type="button"
        onClick={() => {
          onCloseFilterView();
          resetPreviousValue();
        }}
        className={classNames(
          "absolute",
          "top-[-36.04px]",
          "right-0",
          "lg:top-0",
          "lg:right-[-42px]"
        )}
      >
        <CrossSvg />
      </button>
    </FilterViewWapper>
  );
};

export default FilterView;
