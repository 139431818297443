import {
  MonthlyProgram,
  MonthlyProgramClass,
} from "common/models/MonthlyProgram";
import { useMonthlyProgramViewModel } from "common/viewModels/MonthlyProgramViewModel";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import {
  IMonthlyProgrammeViewModel,
  ProgrammeListByCategoryFilter,
} from "models/Programme";
import { DispatchWithoutAction, useContext } from "react";
import { Outlet } from "react-router-dom";
import {
  IGlobalProviderContext,
  IUserViewModelContext,
  SaiShaAPIFailureResponse,
} from "../../@types/common";

export type IProgramOutletContextType = Pick<
  IMonthlyProgrammeViewModel,
  | "monthlyProgramList"
  | "programClassList"
  | "getMonthlyProgramListByCategory"
  | "applyWaitingList"
  | "dropoutWaitingList"
  | "getClassInfoByProgrammeId"
>;

const MonthlyProgramScreen = () => {
  /* 
    TODO: get currentUser before render
          context with outlet
  */
  const {
    monthlyProgramList,
    programClassList,
    getClassInfoByProgrammeId,
    applyWaitingList,
    dropoutWaitingList,
    getMonthlyProgramListByCategory,
  } = useMonthlyProgramViewModel() as IMonthlyProgrammeViewModel;

  /**seemed (isRetrivingUser = false + currentUser = null )= guest */
  const { currentUser, isRetriving: isRetrivingUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  return !isRetrivingUser ? (
    <main className="pb-[100px]">
      <Outlet
        context={{
          monthlyProgramList,
          programClassList,
          getClassInfoByProgrammeId,
          applyWaitingList,
          dropoutWaitingList,
          getMonthlyProgramListByCategory,
        }}
      />
    </main>
  ) : (
    <div></div>
  );
};

export default MonthlyProgramScreen;
