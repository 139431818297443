import { getLocalisedString } from "../../utils/stringHelper";

export interface IFacilityPurpose {
    _id: string;
    purpose_en: string;
    purpose_tc: string;

    readonly name: string;
}

export class FacilityPurpose implements IFacilityPurpose {
    _id: string;
    purpose_en: string;
    purpose_tc: string;

    constructor({ _id, purpose_en, purpose_tc }: any) {
        this._id = _id;
        this.purpose_en = purpose_en;
        this.purpose_tc = purpose_tc;
    }

    get name(): string {
        return getLocalisedString(this.purpose_en, this.purpose_tc);
    }
}
