import Styled from "./Styled";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { TimeTable } from "models/Facility";
import { useTranslation } from "react-i18next";
import _, { pick } from "lodash";
import { useMemo } from "react";

const TIMETABLE_ORDER = ["MON", "SAT"];

// false

const emptyTable: TimeTable[] = TIMETABLE_ORDER.map((day) => ({
  day,
  normal_price: null,
  peak_hour_price: null,
  booking_interval: 0,
  start_time: "",
  end_time: "",
  timeslot: [],
}));
interface PriceProps {
  header?: Array<string>;
  timetable: TimeTable[];
}

const TimeSlotPriceTable = ({ timetable }: PriceProps) => {
  const { t } = useTranslation(["booking", "common"]);

  const orderedTimetable = useMemo(() => {
    if (_.isEmpty(timetable)) {
      return emptyTable;
    }
    const orderedReturn = _.reduce<string, TimeTable[]>(
      TIMETABLE_ORDER,
      (matched, day) => {
        const dayToDateFormatString: { [key: string]: string } = {
          mon: "weekday",
          sat: "weekend",
        };
        let matchedDay = timetable.find((days) => days.day === day);

        if (matchedDay) {
          matchedDay = Object.assign({}, matchedDay, {
            day: dayToDateFormatString[day.toLowerCase()] || day.toLowerCase(),
          });
          return [...matched, matchedDay];
        }
        return matched;
      },
      []
    );
    return orderedReturn;
  }, [timetable]);

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <Styled.NoLineCell
              style={{ width: "20%", minWidth: "120px" }}
            ></Styled.NoLineCell>
            <TableCell style={{ width: "40%" }}>
              {t("facilityDetail.normalHour")}
            </TableCell>
            <TableCell style={{ width: "40%" }}>
              {t("facilityDetail.peakHour")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderedTimetable?.map((time, idx) => (
            <TableRow key={time?.day}>
              <Styled.NoLineCell>
                {t(`common:dateFormat.isoWeekday.${time?.day.toLowerCase()}`)}
              </Styled.NoLineCell>
              <Styled.Cell>
                {!_.isNull(time?.normal_price)
                  ? time.normal_price > 0
                    ? t("common:price", { price: time?.normal_price })
                    : t("common:free")
                  : "/"}
              </Styled.Cell>
              <Styled.Cell>
                {!_.isNull(time?.peak_hour_price)
                  ? time?.peak_hour_price > 0
                    ? t("common:price", { price: time?.peak_hour_price })
                    : t("common:free")
                  : "/"}
              </Styled.Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TimeSlotPriceTable;
