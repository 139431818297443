import { useMemo } from "react";
import CardBase, { VoucherListCardBaseProps } from "./CardBase";
import dayjs from "common/utils/dayjs";
import { useTranslation } from "react-i18next";
import Styled from "./Styled";
import { ReactComponent as BadgeActiveSvg } from "assets/bundle-offer-badge-active.svg";
import { ReactComponent as BadgeInActiveSvg } from "assets/bundle-offer-badge-inactive.svg";

export type EventVoucherProps = VoucherListCardBaseProps & {
  voucherId: string;
  title?: string;
  description?: string;
  expiredDate?: string | Date;
  startDate?: string | Date;
  endDate?: string | Date;
  onRedeemClick?: () => void;
  disableBundle: boolean;
};

const EventVoucherCard = ({
  voucherId,
  title,
  expiredDate,
  onRedeemClick,
  disableBundle,
  ...cardBaseProps
}: EventVoucherProps) => {
  const { t } = useTranslation();

  const expiryDateInString = useMemo(() => {
    if (expiredDate == null) {
      return "";
    }
    return dayjs(expiredDate).tz().format("DD/MM/YYYY");
  }, [expiredDate]);

  return (
    <CardBase {...cardBaseProps}>
      <div className="content h-full flex flex-col lg:flex-row lg:justify-between">
        <div className="detail-left flex flex-col">
          {voucherId && (
            <pre>{t("voucher:detail.ref", { ref: voucherId })}</pre>
          )}

          <p>{t("voucher:restriction.eventType", { type: title })}</p>

          <Styled.ExpiryLabel className="mt-[10px]">
            <span>
              {/** Prevent html escape special character */}
              {t("voucher:detail.expiry").replace(
                "{{date}}",
                expiryDateInString
              )}
            </span>
          </Styled.ExpiryLabel>

          {onRedeemClick == null ? null : (
            <>
              <div className="h-[20px]" />
              <div className="">
                <Styled.RedeemButton
                  type="button"
                  //   disabled={disabled}
                  onClick={onRedeemClick}
                >
                  {t("voucher:button.redeem")}
                </Styled.RedeemButton>
              </div>
            </>
          )}
        </div>
        <div className="h-[20px] lg:hidden" />
        <div className="detail-right flex-shrink-0 flex flex-col lg:items-end justify-between">
          {disableBundle ? null : <BadgeActiveSvg />}
        </div>
      </div>
      <div className="h-[25px]" />
    </CardBase>
  );
};

export default EventVoucherCard;
