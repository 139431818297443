import dayjs from "dayjs";
import _ from "lodash";

var isoWeek = require("dayjs/plugin/isoWeek");

dayjs.extend(isoWeek);

var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

const formatDateToString = (
  dayjsObj, // Dayjs object
  dateFormat = "YYYY-MM-DD",
  locale = "en"
) => {
  if(_.isString(dayjsObj)) {
    return dayjsObj;
  }else{
    if (!dayjsObj) {
      return "";
    }
    if (!(dayjsObj?.isValid && dayjsObj?.isValid())) {
      return "";
    }
    return dayjsObj.tz("Asia/Hong_Kong").locale(locale).format(dateFormat);
  }
};

const getTimeDiffFrom = (time, timeFrom, unit = "seconds") => {
  return dayjs(time).diff(timeFrom, unit);
};

const getDateIsBefore = (date, startDate, unit = "day") => {
  return dayjs(date).isBefore(startDate, unit);
};

const getDateIsAfter = (date, startDate, unit = "day") => {
  return dayjs(date).isAfter(startDate, unit);
};

const getDateBetween = (date, startDate, endDate, unit = "day") => {
  return dayjs(date).isBetween(startDate, endDate, unit);
};

const getDayOfWeek = (date) => {
  console.log("Date of week: ", date, dayjs(date).tz().isoWeekday());
  return dayjs(date).tz().isoWeekday();
};

const getTimeIsAfter = (time_a, time_b) => {
  // Compare HH:mm
  return (
    dayjs(`2000-01-01 ${time_a}`).subtract(dayjs(`2000-01-01 ${time_b}`)) > 0
  );
};

// Map common:dateFormat.isoWeekday for localized version
const getDayOfWeekSlug = (date) => {
  const isoWeekIndex = getDayOfWeek(date);
  switch (isoWeekIndex) {
    case 1:
      return "mon";
    case 2:
      return "tue";
    case 3:
      return "wed";
    case 4:
      return "thur";
    case 5:
      return "fri";
    case 6:
      return "sat";
    case 7:
      return "sun";
  }
};

export {
  formatDateToString,
  getTimeDiffFrom,
  getDateBetween,
  getDateIsBefore,
  getTimeIsAfter,
  getDayOfWeekSlug,
};
