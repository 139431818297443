import React from "react";
import Space from "components/common/Space";
import ProfileNav from "pageComponents/profile/ProfileNav";
import Styled from "./Styled";
import { WindowSize } from "utils/responsive";

type Props = {
  children: React.ReactNode;
};

const ProfileLayout = (props: Props) => {
  const windowSize = WindowSize();
  return (
    <Styled.PageBackgroundContainer>
      <div className="flex flex-row">
        {windowSize !== "mobile" ? (
          <>
            <div className="flex-shrink-0 h-max">
              <ProfileNav />
            </div>
            <Space width="50px" />
          </>
        ) : (
          <></>
        )}
        <div className="min-w-0 w-full">{props.children}</div>
      </div>
    </Styled.PageBackgroundContainer>
  );
};

export default ProfileLayout;
