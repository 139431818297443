import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import BaseModal, { BaseModalProps } from "components/common/Modal/BaseModal";
import ModalCard from "components/common/Modal/ModalCard";
import GeneralButton from "components/common/Button";
import SecondaryButton from "components/common/SecondaryButton";
import { ReactComponent as CrossSvg } from "../../../assets/white-cross.svg";
import Styled from "./Styled";
import TextField from "../../common/Textfield";

type SuccessCallbackResult = {
  success: true;
};
type FailureCallbackResult = {
  success: false;
  error: {
    message: string;
  };
};

type Props = {
  onAddGuardian?: (
    email: string
  ) => Promise<SuccessCallbackResult | FailureCallbackResult>;
  open: boolean;
  onClose: () => void;
};

const AddGuardianModal = (props: Props & BaseModalProps) => {
  const { onAddGuardian, ...modalProps } = props;
  const { t } = useTranslation("profile");
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const onConfirm = async () => {
    if (onAddGuardian) {
      const result = await onAddGuardian(value);
      if (result.success) {
        modalProps.onClose();
      } else {
        setError(result.error.message);
      }
    }
  };

  const cleanModal = useCallback(() => {
    setValue("");
    setError("");
    modalProps.onClose();
  }, [modalProps, setValue, setError]);

  useEffect(() => {
    if (modalProps.open === false) {
      setValue("");
      setError("");
    }
  }, [modalProps.open, cleanModal]);

  return (
    <BaseModal {...modalProps}>
      <ModalCard maxHeight={383} maxWidth={632}>
        <div className={cn("mt-[52px] flex justify-center", "lg:mt-[58px]")}>
          <Styled.Title>{t("personalInfo.editGuardian")}</Styled.Title>
        </div>
        <div className="my-[25px] h-[30px] px-[30px]">
          <Styled.FieldTitle>
            {t("personalInfo.editGuardianMsg")}
          </Styled.FieldTitle>
        </div>
        <div className="w-full px-[30px]">
          <Styled.FieldTitle>{t("personalInfo.email")}</Styled.FieldTitle>
          <TextField
            className="w-full"
            placeholder={t("personalInfo.email")}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          {error == null ? null : (
            <span className="mt-4 text-red-700">{error}</span>
          )}
        </div>
        <div className="h-[30px]" />
        <div
          className={cn(
            "w-full px-[56px] flex flex-col",
            "lg:flex-row-reverse",
            "lg:justify-start"
          )}
        >
          <div className="w-full lg:w-[133px]">
            <GeneralButton
              width="100%"
              type="button"
              onClick={onConfirm}
              disabled={!value.length}
            >
              {t("common:button.confirm")}
            </GeneralButton>
          </div>
          <div className="h-[30px] lg:h-0 lg:w-[30px]" />
          <div className="w-full lg:w-[133px]">
            <SecondaryButton width="100%" onClick={cleanModal}>
              {t("common:button.cancel")}
            </SecondaryButton>
          </div>
          <div className="h-[42.6px] lg:hidden" />
        </div>
        <button
          type="button"
          onClick={modalProps.onClose}
          className={cn(
            "absolute",
            "top-[-36.04px]",
            "right-0",
            "lg:top-0",
            "lg:right-[-42px]"
          )}
        >
          <CrossSvg />
        </button>
      </ModalCard>
    </BaseModal>
  );
};

export default AddGuardianModal;
