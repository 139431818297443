import Typography from "components/common/Typography";
import LogoCard from "components/common/cards/LogoCard";
import { useTranslation } from "react-i18next";
import Styled from "./Styled";
import theme from "utils/theme";
import { InferType, object, string } from "yup";
import { useContext, useEffect, useMemo } from "react";
import { FormikHelpers } from "formik";
import Form from "components/common/Form";
import PhoneNumberInput from "components/common/PhoneNumberInput";
import Space from "components/common/Space";
import GeneralButton from "components/common/Button";
import { useMobileVerificationViewModel } from "common/viewModels/Verification/MobileVerificationViewModel";
import {
  IMobileVerificationViewModel,
  MobileVerificationResult,
} from "common/models/Verification";
import { CountryCode } from "libphonenumber-js";
import OtpValidationCard from "components/OtpValidationCard/OtpValidateCard";
import SwitchCheckbox from "components/common/checkbox/SwitchCheckbox";
import GuardianLabel from "./Components/GuardianLabel";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { getCorrectUrl } from "navigators/Router";
import { ROUTE_BASE } from "Route";
import { GreetingType } from "./RegistrationGreeting";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { WindowSize } from "utils/responsive";
import _ from "lodash";

interface IVerifyMobileProps {
  setCurrentStep?: (step: number) => void;
  setVerificationMobileResult?: (result: MobileVerificationResult) => void;
}

const VerifyMobile = ({
  setCurrentStep,
  setVerificationMobileResult,
}: IVerifyMobileProps) => {
  const { t } = useTranslation("login");
  const isMobile = WindowSize() === "mobile";
  const navigate = useNavigate();
  const { state } = useLocation();
  const { currentUser } = useContext(UserViewModelContext);
  const {
    country,
    phoneNumber,
    countdownTime,
    otp,
    isShowOtpCard,
    otpCorrect,
    showOtpResult,
    isFormValid,
    byPassVerification,
    otpVerificationResult,
    setOtp,
    setCountry,
    setPhoneNumber,
    requestPhoneNumberOTP,
    verifyPhoneNumberOTP,
    setByPassVerification,
    getUnverifiedPhoneNumberPayload,
    submitUpdateMobile,
  } = useMobileVerificationViewModel({
    allowByPassVerification: true,
  }) as IMobileVerificationViewModel;

  const onOtpComplete = async () => {
    await verifyPhoneNumberOTP();
  };

  const onSubmit = async () => {
    if (setVerificationMobileResult && setCurrentStep) {
      setVerificationMobileResult(
        byPassVerification
          ? getUnverifiedPhoneNumberPayload()
          : otpVerificationResult
      );
      setCurrentStep(2);
    } else if (state?.isUpdate) {
      const isVerifiedPhone = currentUser?.isPhoneNumVerified;
      const res = await submitUpdateMobile();
      if (res?.success) {
        navigate({
          pathname: getCorrectUrl(`${ROUTE_BASE.REGISTER}/greeting`),
          search: createSearchParams({
            type: isVerifiedPhone
              ? GreetingType.IS_UPDATE_MOBILE
              : GreetingType.IS_VERIFY_MOBILE,
          }).toString(),
        });
      }
    }
  };

  return (
    <LogoCard
      title={
        byPassVerification
          ? t("guardian_mobile_login_instruction")
          : t("mobile_otp_register_instruction")
      }
    >
      <div className="w-full md:w-[600px]">
        {!byPassVerification && (
          <Typography textAlign="center" className="mb-[40px]">
            {t("mobile_otp_register_detail")}
          </Typography>
        )}
        <div className="flex items-center justify-start w-full gap-[10px] mb-[10px]">
          <Typography fontSize="12px">{t("field.phone_number")}</Typography>
          <GuardianLabel disabled={!byPassVerification} />
        </div>
        <div className="flex flex-col md:flex-row w-full gap-[10px]">
          <PhoneNumberInput
            phoneNum={phoneNumber}
            setPhoneNum={(phoneNum) => setPhoneNumber(phoneNum)}
            region={country}
            setRegion={(region) => setCountry(region)}
          />
          {!byPassVerification && (
            <div className="pl-[95px] w-full md:w-fit md:pl-[0px]">
              <GeneralButton
                onClick={requestPhoneNumberOTP}
                disabled={!!countdownTime || _.isEmpty(phoneNumber)}
                fullWidth={isMobile}
              >
                {countdownTime ? countdownTime : t("button.verify")}
              </GeneralButton>
            </div>
          )}
        </div>
        <Space size="10px" />
        {!state?.isUpdate && (
          <SwitchCheckbox
            label={t("i_am_child_checkbox")}
            onChange={(e, checked) => setByPassVerification(checked)}
          />
        )}
        {isShowOtpCard && (
          <div className="mt-[30px]">
            <OtpValidationCard
              size={6}
              title={t("register:verification.otp")}
              countdownTime={countdownTime}
              resendText={t("register:button.resend_otp")}
              onResendClick={requestPhoneNumberOTP}
              otp={otp}
              setOtp={setOtp}
              onOtpComplete={onOtpComplete}
              otpCorrect={otpCorrect}
              showOtpResult={showOtpResult}
            />
          </div>
        )}
        <Space size="30px" />
        <GeneralButton fullWidth disabled={!isFormValid} onClick={onSubmit}>
          {t("common:button:submit")}
        </GeneralButton>
      </div>
    </LogoCard>
  );
};

export default VerifyMobile;
