import CloseIconModal from "components/common/Modal/CloseIconModal";
import { useContext, useMemo, useState } from "react";
import IconClose from "assets/ic-close.svg";
import BaseModal from "components/common/Modal/BaseModal";
import Styled from "./Styled";
import IconNeedImprovement from "assets/ic-statusNeedImprovement.png";
import IconPending from "assets/ic-statusPendingApproval.png";
import IconVerification from "assets/ic-statusVerification.png";
import { useTranslation } from "react-i18next";
import Typography from "components/common/Typography";
import GeneralButton from "components/common/Button";
import Space from "components/common/Space";
import theme from "utils/theme";
import { WindowSize } from "utils/responsive";
import { useNavigate } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import { IGlobalProviderContext } from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import {
  disappearAccountVerification,
  disappearPhoneNumberVerification,
} from "common/context/requests/globalRequest";
import { getCorrectUrl } from "navigators/Router";

interface PromptProps {
  isOpen: boolean;
  extraOnClose?: () => void;
  isPending?: boolean;
  isVerifyPhone?: boolean;
}

const AccountStatusPrompt = ({
  isPending = false,
  isVerifyPhone,
  isOpen,
  extraOnClose,
}: PromptProps) => {
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { t, i18n } = useTranslation("profile");
  const navigate = useNavigate();
  const isMobileScreen = WindowSize() === "mobile";

  const promptInfo = useMemo(() => {
    if (isPending) {
      return {
        img: IconPending,
        title: t("personalInfo.pendingApproval"),
        subtitle: t("personalInfo.pendingApprovalSubtitle"),
        message: t("personalInfo.pendingApprovalMsg"),
        action: t("personalInfo.taskActionNow"),
        onClose: () => {
          disappearAccountVerification(globalDispatch);
        },
      };
    } else if (isVerifyPhone) {
      return {
        img: IconVerification,
        title: t("personalInfo.phoneNumberValidation"),
        message: t("personalInfo.phoneNumberValidationMsg"),
        action: t("personalInfo.taskActionNow"),
        onClick: () => {
          disappearPhoneNumberVerification(globalDispatch);
          navigate(getCorrectUrl(`${ROUTE_BASE.PROFILE}/mobile-verification`), {
            state: { isUpdate: true },
          });
        },
        onClose: () => {
          disappearPhoneNumberVerification(globalDispatch);
        },
      };
    } else {
      return {
        img: IconNeedImprovement,
        title: t("personalInfo.needImprovement"),
        message: t("personalInfo.needImprovementDesc"),
        action: t("personalInfo.taskActionNow"),
        onClick: () => {
          disappearAccountVerification(globalDispatch);
          navigate(getCorrectUrl(`${ROUTE_BASE.PROFILE}/verification`));
        },
        onClose: () => {
          disappearAccountVerification(globalDispatch);
        },
      };
    }
  }, [isPending, isVerifyPhone]);

  return (
    <BaseModal
      open={isOpen}
      onClose={() => {
        promptInfo?.onClose && promptInfo?.onClose();
        extraOnClose && extraOnClose();
      }}
    >
      <Styled.StatusPromptCotnainer>
        <div className="flex justify-end">
          <img
            src={IconClose}
            className="cursor-pointer"
            onClick={() => {
              promptInfo?.onClose && promptInfo?.onClose();
              extraOnClose && extraOnClose();
            }}
          />
        </div>
        <div className="h-[95%] md:mx-[40px] flex flex-col justify-center md:flex-row items-center">
          <img src={promptInfo?.img} className="h-[180px] w-[180px]" />

          <Space width="40px" />
          <div className="w-[75%]">
            <Typography
              fontSize="40px"
              mFontSize="35px"
              fontWeight="600"
              color={theme.black}
              textAlign={isMobileScreen ? "center" : "start"}
            >
              {promptInfo?.title}
            </Typography>
            {promptInfo?.subtitle && (
              <div className="mt-[10px]">
                <Typography
                  fontWeight="600"
                  textAlign={isMobileScreen ? "center" : "start"}
                >
                  {promptInfo?.subtitle}
                </Typography>
              </div>
            )}
            <Space size="10px" />
            <Typography textAlign={isMobileScreen ? "center" : "start"}>
              {promptInfo?.message}
            </Typography>
            <Space size="15px" />
            {promptInfo?.onClick && (
              <GeneralButton
                fullWidth={isMobileScreen}
                onClick={promptInfo?.onClick}
              >
                {promptInfo?.action}
              </GeneralButton>
            )}
          </div>
        </div>
      </Styled.StatusPromptCotnainer>
    </BaseModal>
  );
};

export default AccountStatusPrompt;
