import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";
import theme from "utils/theme";

export const Wrapper = styled.div`
  font-family: Poppins;
  display: flex;
  width: 100%;
  max-width: 1280px;
  border-radius: 30px;
  margin: 0px auto;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #def5ff;
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 343px); /* 283 + space = 30px * 2, hardcoded*/
  flex: 1;
  background-color: ${theme.lightCyan};
`;

export default { Wrapper, Container };
