import Elements from "./SectionElements";
import Styled from "./Styled";

const TncSections = () => {
  return (
    <Styled.OrderList>
      {Elements.Section1()}
      {Elements.Section2()}
      {Elements.Section3()}
      {Elements.Section4()}
      {Elements.Section5()}
      {Elements.Section6()}
      {Elements.Section7()}
      {Elements.Section8()}
    </Styled.OrderList>
  );
};

export default TncSections;
