import { Typography } from "@mui/material";
import HTMLTypography from "components/common/HTMLTypography";
import { HeadlineNews } from "models";
import React from "react";
import { getLocalizedString } from "utils/locale";
import theme from "utils/theme";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { IHeadlineNewsScreen } from "pages/HeadlineNews/HeadlineNewsScreen";

type HeadlineNewsDetailProps = {
  news?: HeadlineNews;
} & React.HTMLProps<HTMLElement>;
const Detail = ({ news, className }: HeadlineNewsDetailProps) => {
  const { t, i18n } = useTranslation("home");
  const { headlineNews } = useOutletContext<IHeadlineNewsScreen>();

  return news ? (
    <div className={cn("overflow-auto p-8", className)}>
      <Typography
        color={theme.black}
        fontSize="20px"
        fontWeight="500"
        className="pb-5"
      >
        {getLocalizedString(news.title_en, news.title_zh)}
      </Typography>
      <HTMLTypography
        content={getLocalizedString(news.desc_en, news.desc_zh)}
      ></HTMLTypography>
    </div>
  ) : (
    <div className="p-8 text-lg">{headlineNews.length === 0 ? t('common:comingSoon') : t("headline.placeholder")}</div>
  );
};

export default Detail;
