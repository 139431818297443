import styled from "styled-components";
import theme from "utils/theme";

const GuardianLabel = styled.div`
  background-color: #3f3a3a;
  border-radius: 20px;
  padding: 3px 5px;
  width: fit-content;
`;

const ChildLabel = styled.div`
  background-color: ${theme.orange};
  border-radius: 20px;
  padding: 3px 5px;
  width: fit-content;
  display: flex;
  align-items: center;
`;

export default {
  GuardianLabel,
  ChildLabel,
};
