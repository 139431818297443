import Typography from "components/common/Typography";
import styled from "styled-components";
import theme from "utils/theme";

export default {
  Container: styled.div`
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 34px 28px 31px 24px;
  `,
  MenuButton: styled(Typography)<{ selected?: boolean }>`
    && {
      cursor: pointer;
      color: ${theme.black};
      font-size: 16px;
      @media (min-width: 861px) {
        ${(props) => props.selected && `color: ${theme.lightOrange}`}
      }
    }
  `,
};
