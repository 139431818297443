import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";
import theme from "utils/theme";

const Row = styled.div`
  display: flex;
  margin: 10px 0px;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flow-root;
  width: 100%;
  max-width: 1280px;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  @media ${breakpoint.mobile} {
    padding: 30px auto 100px;
  }
`;

const SearchInput = styled.input`
  width: 250px;
  height: 50px;
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  padding: 0px 15px;
  @media ${breakpoint.mobile} {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const Button = styled.button<{ type?: string }>`
  padding: 0px 30px;
  height: 50px;
  border-radius: 10px;
  color: ${(p) => (p.type !== "primary" ? theme.blue : "#fff")};
  background: ${(p) => (p.type !== "primary" ? "#fff" : theme.blue)};
  border: 1px solid ${theme.blue};
  margin: 0px 20px 0px 10px;

  &.button-search {
    @media ${breakpoint.mobile} {
      width: 100%;
      margin-left: 0px;
    }
  }
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
`;

const FilterIcon = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: ${theme.seaBlue};
  color: #fff;
  text-align: center;
  svg {
    display: inline;
  }
`;

const FilterWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const ImgIcon = styled.img`
  display: inline;
`;

const TagWrapper = styled.div`
  width: 100%;
  margin: 30px 0px;
  display: flex;
  flex-wrap: wrap;

  @media ${breakpoint.mobile} {
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }
`;

const Tag = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  height: 45px;
  width: fit-content;
  padding: 10px 25px;
  border-radius: 30px;
  background-color: #e3e3e3;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  color: #8c8c8c;
  font-size: 18px;
  &:hover,
  &.active {
    background: ${theme.orange};
    color: #fff;
  }
`;

const FilterView = styled.div`
  z-index: 1000;
  position: absolute;
  display: block;
  width: 360px;
  padding: 30px;
  margin-top: 20px;
  left: 0px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
  &.hide {
    display: none;
  }

  .group-button-action {
    position: static;
  }

  @media ${breakpoint.mobile} {
    width: 100%;
    position: fixed;
    height: 70vh;
    bottom: 0;
    z-index: 5;
    padding-bottom: 100px;
    overflow-y: auto;
    animation: showFilter 0.5s;

    .group-button-action {
      position: fixed;
      bottom: 15px;
      width: 100%;
      left: 0;
      text-align: center;
    }

    @keyframes showFilter {
      from {
        transform: translateY(100%);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
`;

const Overlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4;
  &.hide {
    display: none;
  }

  @media ${breakpoint.mobile} {
    display: block;
  }
`;

const Category = styled.span`
  margin: 10px;
  float: left;
  cursor: pointer;
  color: #000;
  font-weight: bold;
  &:hover,
  &.active {
    color: ${theme.orange};
  }

  @media ${breakpoint.mobile} {
    width: 100%;
    padding: 15px;
    margin: 0px;
    border-bottom: 1px solid #ddd;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
`;

const BoxItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: calc(25% - 22.5px);
  background: #fff;
  border-radius: 20px;
  border-bottom-width: 4px;
  border-right-width: 4px;
  border-style: solid;
  border-color: ${theme.orange};
  overflow: hidden;
  transition: all 0.2s;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.075);
  }

  @media (max-width: 1199px) {
    width: calc(33.33% - 22.5px);
  }

  @media (max-width: 991px) {
    width: calc(50% - 20px);
  }

  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

const ItemImg = styled.div<{ img: string }>`
  width: 100%;
  height: 190px;
  background-image: url(${(p) => p.img});
  background-size: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const ItemContent = styled.div`
  width: 100%;
  position: relative;
  padding: 25px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ItemTag = styled.div<{ bg: string; width: string; height: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  padding: 0px 20px;
  color: #fff;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: auto;
`;

const ItemTitle = styled.h3`
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  height: auto;
  overflow: hidden;
  margin-bottom: 15px;
`;

const ItemSlot = styled.div`
  color: #4a4a4a;
`;

const ItemLink = styled.a`
  display: flex;
  gap: 5px;
  align-items: center;
  color: ${theme.seaBlue};
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
`;

const Paging = styled.div`
  display: block;
  text-align: center;
  margin: 50px 0px;
  width: 100%;
  float: left;
  min-height: 50px;
`;

const PagingItem = styled.span`
  display: inline-block;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 38px;
  border: 1px solid #000;
  margin: 0px 5px;
  border-radius: 10px;
  cursor: pointer;
  &.active,
  &:hover {
    background: #c85d38;
    border-color: #c85d38;
    color: #fff;
  }

  &.active {
    cursor: default;
  }
  &.hide {
    display: none;
  }
`;

const Title = styled.div`
  display: block;
  font-size: 30px;
  margin-bottom: 50px;
  width: 100%;
  float: left;
`;

const Paragraph = styled.div`
  display: block;
  margin-bottom: 30px;
  width: 100%;
  float: left;
`;

const DetailIcon = styled.div`
  display: block;
  width: 45px;
  height: 45px;
  float: left;
`;

const DetailItem = styled.div`
  display: block;
  width: calc(100% - 45px);
  padding: 0px 10px;
  float: left;
`;

const EventDetailContent = styled.div`
  display: block;
  width: 60%;
  float: left;
  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

const Hr = styled.div`
  display: block;
  width: 100%;
  float: left;
  height: 1px;
  background: #ddd;
  margin: 30px 0px 40px;
`;

const CheckboxLabel = styled.div`
  display: inline-flex;
  flex-direction: column;
  float: left;
  span {
    padding-left: 7px;
    color: #c85d38;
  }
  margin-bottom: 30px;
  @media ${breakpoint.mobile} {
    display: inline;
    float: none;
  }
`;

const GroupField = styled.div`
  display: block;
  width: 100%;
  float: left;
  @media ${breakpoint.mobile} {
    margin-bottom: 30px;
  }
`;

const StepBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  float: left;
  font-weight: 500;
  margin: 30px 0px 0px;
`;

const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 130px;
  span {
    width: 37px;
    height: 37px;
    display: inline-block;
    border-radius: 50%;
    background: #8c8c8c;
    margin-bottom: 10px;
    position: relative;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media ${breakpoint.mobile} {
    font-size: 12px;
    span {
      width: 25px;
      height: 25px;
    }
  }
`;

const Divider = styled.hr`
  border-top: 2px solid #30861b;
  width: calc(50% - 150px);
  margin-top: 20px;
  @media ${breakpoint.mobile} {
    width: calc(50% - 100px);
  }
`;

const StepWrapper = styled.div`
  display: flow-root;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;

const SuccessBox = styled.div`
  display: flex;
  padding: 75px 0px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 100px;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    gap: 30px;
  }
`;

const SuccessTextBox = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakpoint.mobile} {
    text-align: center;
  }
`;

const ReceivedInfo = styled.div`
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StatusTag = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #00000080;
  border: 1px solid #808080;
  border-radius: 5px;
  padding: 6px 10px;
`;

export default {
  Row,
  SearchInput,
  Button,
  FilterIcon,
  ImgIcon,
  Wrapper,
  TagWrapper,
  Tag,
  FilterWrapper,
  FilterView,
  Category,
  ItemWrapper,
  BoxItem,
  ItemImg,
  ItemContent,
  ItemTag,
  ItemTitle,
  ItemSlot,
  ItemLink,
  Overlay,
  Paging,
  PagingItem,
  Title,
  Paragraph,
  DetailIcon,
  DetailItem,
  EventDetailContent,
  CheckboxLabel,
  Hr,
  GroupField,
  StepBox,
  StepItem,
  StepWrapper,
  Divider,
  SuccessBox,
  SuccessTextBox,
  ReceivedInfo,
  StatusTag,
};
