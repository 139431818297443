import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import theme from "utils/theme";

const ResultContainer = styled.div`
  background-color: ${theme.lightCyan};
  border-radius: 15px;
  width: 100%;
  height: auto;
  min-height: 60vh;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0px 200px;
  margin: 40px auto 0px;

  @media (${breakpoint.mobile}) {
    flex-direction: column;
    padding: 36px auto;
    text-align: center;
  }
`;

const Loading = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default {
  ResultContainer,
  Loading,
};
