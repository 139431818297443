import styled from "styled-components";
import { size } from "../../utils/responsive";
import theme from "utils/theme";
import { ButtonGroup } from "@mui/material";

const Logo = styled.img`
  width: 193px;
`;

const PageContainer = styled.div`
  width: 100vw;
  height: 100dvh;
  max-width: 1070.4px;
  padding: 26px 20px 48px;
  z-index: 10;

  @media (min-width: ${size.screen1024}px) {
    padding: 105px 0px 100px 0px;
  }
`;

const DecorateImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

const LoginImageCaption = styled.span`
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
  opacity: 0.8;
  @media (min-width: 861px) {
    font-size: 20px;
  }
  @media (min-width: 1070px) {
    font-size: 25px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  @media (min-width: ${size.screen1024}px) {
    width: 297px;
  }
`;

const LoginTitle = styled.p`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const RegisterRemindText = styled.p`
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const GoToForgotPasswordButton = styled.button`
  color: ${theme.orange};
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
`;

const GoToRegisterButton = styled.button`
  color: ${theme.orange};
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
`;

const LoadingContainer = styled.div`
  height: 669px;
  width: 100%;
  display: grid;
  place-items: center;
`;

const MethodButtonGroup = styled(ButtonGroup)`
  && {
    color: ${theme.orange};
    border: 1px solid ${theme.orange};
  }
`;

export default {
  Logo,
  PageContainer,
  DecorateImg,
  FormContainer,
  LoginTitle,
  RegisterRemindText,
  GoToForgotPasswordButton,
  GoToRegisterButton,
  LoadingContainer,
  LoginImageCaption,
  MethodButtonGroup,
};
