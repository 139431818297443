import dayjs from "../../utils/dayjs";
import { useState, useEffect, useMemo } from "react";
import {
  getFacilityDetail,
  getFacilityTimeslots,
  getTimetableByFacility,
  getVenueDetail,
  listVenueByFacility,
} from "../../network/facility";
import {
  holdTimeslot,
  holdTimeslotWithAutoAssignVenue,
} from "../../network/booking";
import {
  appearGlobalLoading,
  appearGlobalError,
  disappearGlobalLoading,
  appearAccountVerification,
} from "../../context/requests/globalRequest";
import config from "../../../config";
import { getLocalisedString } from "../../utils/stringHelper";
import { formatDateToString, getTimeIsAfter } from "../../utils/DateHelper";
import _ from "lodash";
import { isEmptyValues } from "../../../utils/common";
import { Facility } from "../../models/Facility";
import i18n from "../../../i18n";
import { useCommonFetcher } from "../Common/CommonViewModel";
import { Timeslot } from "../../models/Timeslot";
import { Venue } from "../../models/Venue";

export const useFacilityVenueDetailViewModel = ({ facilityId, dispatch }) => {
  const { fetcher } = useCommonFetcher();

  const [facility, setFacility] = useState(null);
  const [facilityError, setFacilityError] = useState(null);
  const [venueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null); // venue id
  const [dateSelected, setDateSelected] = useState(
    formatDateToString(dayjs(), "YYYY-MM-DD")
  );
  const [timetable, setTimetable] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState("");
  const [timeslots, setTimeslots] = useState([]);
  const [selectedTimeslots, setSelectedTimeslots] = useState(new Map());
  const [numOfAttendees, setNumberOfAttendees] = useState(1);
  const [onlyOneVenue, setOnlyOneVenue] = useState(false);

  const definedBays = [
    {
      _id: "all",
      name: i18n.getFixedT()("booking:golfBayOptions.all"),
    },
    {
      _id: "lg1-lg20",
      name: "LG1 - LG20",
    },
    {
      _id: "lg21-lg39",
      name: "LG21 - LG39",
    },
    {
      _id: "g1-g22",
      name: "G1 - G22",
    },
  ];

  const [filter, setFilter] = useState(definedBays[0]._id);

  const shouldDisplayVenuePicker = useMemo(() => {
    return !facility?.isShowRefNum || filter !== definedBays[0]._id;
  }, [facility, filter]);

  const venueOptions = useMemo(() => {
    if (isEmptyValues(venueList)) return [];
    let optionList = venueList?.map((venue) => {
      return {
        label: venue.getName(),
        value: venue._id,
      };
    });

    return facility?.isShowRefNum
      ? optionList
      : [
          {
            label: i18n.getFixedT()("booking:facilityDetail.anyCourt"),
            value: "",
          },
          ...optionList,
        ];
  }, [facility, venueList]);

  // Guest per booking + self
  const maxAttendeesPerBooking = (facility?.guest_per_booking ?? 0) + 1;

  // Remaining Quota of seleted timeslots
  const remainingQuota = useMemo(() => {
    let minQuota =
      _.minBy(Array.from(selectedTimeslots?.values()), "remainingQuota")
        ?.remainingQuota ?? 0;
    return minQuota;
  }, [selectedTimeslots]);

  // Actual num of attendees available for booking
  const maxAttendeesAvailable = useMemo(() => {
    return Math.min(remainingQuota, maxAttendeesPerBooking);
  }, [remainingQuota]);

  useEffect(() => {
    getFacility();
  }, []);

  useEffect(() => {
    getFacilityVenueList();
  }, [filter]);

  useEffect(() => {
    if (maxAttendeesAvailable < numOfAttendees) {
      setNumberOfAttendees(1);
    }
  }, [maxAttendeesAvailable]);

  useEffect(() => {
    onVenueListChanged();
  }, [venueOptions]);

  useEffect(() => {
    console.log("Selected Date: ", dateSelected, selectedVenue);
    refreshData();
    // if (dateSelected != "") {
    //   setTimeslots([]);
    //   setSelectedTimeslots(new Map());
    //   getFacilityTimeTable();
    // }
    //  if(!isEmptyValues(venueList)){
    //   getVenueTimeslotByDate(true);
    // }
  }, [dateSelected, selectedVenue]);

  const refreshData = () => {
    // Refresh timeslot & timetable related data
    if (dateSelected != "") {
      setTimeslots([]);
      setSelectedTimeslots(new Map());
      getFacilityTimeTable();
    }
    //  if(!isEmptyValues(venueList)){
    getVenueTimeslotByDate(true);
    // }
  };

  const onVenueListChanged = () => {
    console.log(
      "On Venue Options changed: ",
      venueOptions,
      isEmptyValues(venueOptions)
    );

    // Handle not call get timeslot if venue list is empty
    if (!isEmptyValues(venueOptions)) {
      if (filter === definedBays[0]?._id && facility?.isShowRefNum) {
        onSelectVenue(null);
      } else {
        onSelectVenue(_.first(venueOptions)?.value ?? null);
      }
    }
  };

  const onDateSelected = (date) => {
    console.log("On Date Selected", date);
    setDateSelected(date);
  };

  const getFacility = async () => {
    try {
      const res = await getFacilityDetail({ facilityId });
      console.log("Get Facility: ", res);
      setFacility(new Facility(res?.result?.facility));
      setFacilityError(null);
    } catch (err) {
      console.log(err);
      setFacility(null);
      setFacilityError(err?.response?.data || err);
    }
  };

  const getFacilityVenueList = async () => {
    const res = await fetcher({
      api:
        filter !== definedBays[0]?._id
          ? () => listVenueByFacility({ facilityId, ref_number: filter })
          : () => listVenueByFacility({ facilityId }),
    });

    if (res?.success) {
      const venues = res?.result?.map((venue) => new Venue(venue)) ?? [];
      setVenueList(venues);
      setOnlyOneVenue(venues?.length === 1);
    } else {
      setVenueList([]);
      setOnlyOneVenue(false);
    }

    return res;
  };

  const getFacilityVenueDetail = async (venue_id) => {
    var res = await fetcher({
      api: () => getVenueDetail({ venueId: venue_id }),
    });

    return new Venue(res.result);
  };

  const getFacilityTimeTable = async () => {
    try {
      const res = await getTimetableByFacility({
        facilityId: facilityId,
        selectedDate: dayjs.tz(dateSelected).toISOString(),
      });
      setTimetable(res ?? []);
    } catch (err) {
      setTimetable([]);
    }
  };

  const getVenueTimeslotByDate = async (displayLoading = false) => {
    if (venueList == []) return;
    const res = await fetcher({
      api: () =>
        getFacilityTimeslots({
          facilityId: facilityId,
          venueId: selectedVenue,
          date: dayjs.tz(dateSelected).toISOString(),
        }),
      skipErrorHandle: true,
      // skipLoading: !displayLoading,
    });
    setTimeslots(res?.result?.map((timeslot) => new Timeslot(timeslot)) ?? []);
  };

  const onSelectFilter = (bayId) => {
    setFilter(bayId);
    // if (bayId === definedBays[0]?._id) setSelectedVenue("");
  };

  const onSelectVenue = (venueId) => {
    console.log("On Select Venue: ", venueId);
    setSelectedVenue(venueId);
  };

  const onPressTimeslot = (timeslot) => {
    let timeslotIdentifier = timeslot.getDisplayTimeslot();
    let tmpSelectedTimeslots = new Map(selectedTimeslots);
    if (selectedTimeslots.has(timeslotIdentifier)) {
      tmpSelectedTimeslots.delete(timeslotIdentifier);
    } else if (checkContiniousTimeSlot(timeslot)) {
      tmpSelectedTimeslots.set(timeslotIdentifier, timeslot);
    }

    // Sort selected timeslots by start_time
    // FIXME: Better compare as date instead
    const sortedTimeslots = new Map(
      [...tmpSelectedTimeslots].sort(
        ([key_a, timeslot_a], [key_b, timeslot_b]) =>
          getTimeIsAfter(timeslot_a?.start_time, timeslot_b?.start_time)
            ? 1
            : -1
      )
    );

    setSelectedTimeslots(sortedTimeslots);
  };

  const checkContiniousTimeSlot = (timeslot) => {
    if (selectedTimeslots.size === 0) return true;
    if (selectedTimeslots.size >= config.NUM_OF_SLOT_CAN_BE_SELECT_AT_ONCE)
      return false;
    let continiousTimeSlot = false;
    selectedTimeslots.forEach((selectedTimeslot) => {
      if (
        selectedTimeslot.start_time === timeslot.end_time ||
        selectedTimeslot.end_time === timeslot.start_time
      ) {
        continiousTimeSlot = true;
        return;
      }
    });
    return continiousTimeSlot;
  };

  const onSelectPurpose = (purpose) => {
    setSelectedPurpose(purpose);
  };

  const onPressAddAttendees = () => {
    console.log("OnPressAdd");
    let newNumOfAttendees = numOfAttendees + 1;
    if (newNumOfAttendees <= maxAttendeesAvailable)
      setNumberOfAttendees(newNumOfAttendees);
  };

  const onPressSubtractAttendees = () => {
    let newNumOfAttendees = numOfAttendees - 1;
    if (newNumOfAttendees >= 1) {
      setNumberOfAttendees(newNumOfAttendees);
    }
  };

  const getSubtotalPrice = () => {
    let total =
      Array.from(selectedTimeslots?.values()).reduce(
        (sum, timeslot) => sum + timeslot.price,
        0
      ) * numOfAttendees;
    return Math.max(0, total);
  };

  const validateForm = () => {
    return (
      selectedTimeslots.size > 0 &&
      ((numOfAttendees >= 1 && numOfAttendees <= maxAttendeesAvailable) ||
        !facility?.isHeadCount()) &&
      (!isEmptyValues(selectedPurpose) || !facility.isMultiVenueFacility)
    );
  };

  const reserveTimeslot = async ({ dispatch }) => {
    try {
      appearGlobalLoading(dispatch);

      const isAutoAssignVenue = isEmptyValues(selectedVenue);

      let holdTimeslotPayload = {
        facility_id: facility?._id,
        reserved_date: dayjs.tz(dateSelected).toISOString(),
        type: "APP_BOOKING",
        timeslot: Array.from(selectedTimeslots?.values()).map((timeslot) => {
          return {
            start_time: timeslot?.start_time,
            end_time: timeslot?.end_time,
          };
        }),
      };

      if (!isAutoAssignVenue) {
        holdTimeslotPayload.venue_id = selectedVenue;
      }

      if (facility?.isHeadCount()) {
        holdTimeslotPayload.attendees = numOfAttendees;
      }

      const res = await fetcher({
        api: isAutoAssignVenue
          ? () => holdTimeslotWithAutoAssignVenue(holdTimeslotPayload)
          : () => holdTimeslot(holdTimeslotPayload),
      });
      console.log(res);

      const bookingIds = res?.result?.mongoInsertedId?.map(
        (booking) => booking._id
      );
      const venueId = _.find(
        res?.result?.mongoInsertedId,
        (booking) => !isEmptyValues(booking?.venue_id)
      )?.venue_id;

      console.log(bookingIds, venueId);
      if (bookingIds.length === 0) {
        console.warn("No booking is created");
      }

      const venueDetailRes = await getFacilityVenueDetail(venueId);

      disappearGlobalLoading(dispatch);
      setSelectedTimeslots(new Map());
      return {
        bookingIds: res?.result?.mongoInsertedId?.map((booking) => booking._id),
        venue: venueDetailRes,
      };
    } catch (err) {
      console.log("reserveTimeslot exception", err);
      setSelectedTimeslots(new Map());
      getVenueTimeslotByDate();
      disappearGlobalLoading(dispatch);

      const error = err?.response?.data?.error;
      const errorCode = error?.errorCode;
      console.log(error);
      if (errorCode === -1001 || errorCode === -1002) {
        // -1001 for pending, -1002 for new user
        appearAccountVerification(dispatch, errorCode === -1002 ? true : false);
      } else {
        const errorMsg = !isEmptyValues(error)
          ? getLocalisedString(
              error?.localizedMessage?.en,
              error?.localizedMessage?.zh,
              true
            )
          : e.message + "";
        appearGlobalError(dispatch, errorMsg);
      }
      throw err;
    }
  };

  return {
    facility,
    facilityError,
    filter,
    selectedVenue,
    definedBays,
    shouldDisplayVenuePicker,
    venueList,
    venueOptions,
    dateSelected,
    timetable,
    timeslots,
    selectedTimeslots,
    selectedPurpose,
    numOfAttendees,
    maxAttendeesPerBooking,
    remainingQuota,
    onlyOneVenue,
    refreshData,
    onSelectFilter,
    onSelectVenue,
    onDateSelected,
    getVenueTimeslotByDate,
    onPressTimeslot,
    onSelectPurpose,
    onPressAddAttendees,
    onPressSubtractAttendees,
    getSubtotalPrice,
    validateForm,
    reserveTimeslot,
  };
};
