import {
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { useCoachLessonDetailViewModel } from "common/viewModels/CoachLessionBooking/CoachLessionDetailViewModel";
import {
  ICoachContextType,
  ICoachLessonDetailViewModel,
} from "models/PrivateCoach";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import CoachBookingBase from "pageComponents/privateCoach/CoachBookingBase";
import ColorBannerCard from "pageComponents/facility/ColorBannerCard";
import { useTranslation } from "react-i18next";
import Typography from "components/common/Typography";
import Space from "components/common/Space";
import GeneralButton from "components/common/Button";
import theme from "utils/theme";
import { DateCalendarPicker } from "components/common/DatePicker";
import config from "config";
import TimeSelectTable from "pageComponents/facility/TimeSelectTable";
import Divider from "components/common/Divider";
import { Select, SelectOption } from "components/common/Select";
import DropdownSelect from "components/common/Select/DropdownSelect";
import TncCheckbox from "pageComponents/facility/TncCheckbox";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { ROUTE_BASE } from "Route";
import { formatDateToString } from "common/utils/DateHelper";
import dayjs from "common/utils/dayjs";
interface lessonTypeOptionProps {
  label: string;
  value: string;
}

const CoachingBookingPage = () => {
  const { id: categoryId, coachId } = useParams();
  const { setBookingInfo } = useOutletContext<ICoachContextType>();
  const navigate = useNavigate();
  const { t } = useTranslation("booking");
  const { currentUser, refreshCurrentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const [lessonTypeOptions, setLessonTypeOptions] = useState<
    lessonTypeOptionProps[]
  >([]);
  const [acceptTnc, setAcceptTnc] = useState<boolean>(false);
  const [acceptAgeLimit, setAcceptAgeLimit] = useState<boolean>(false);
  const [isReservingTimeslot, setIsReservingTimeslot] = useState(false);
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const {
    coachLesson,
    lessonTypeInfo,
    timeslots,
    selectedTimeslots,
    dateSelected,
    selectedLessonType,
    holdTimeslot,
    onDateSelected,
    onPressTimeslot,
    getCoachLesson,
    getCoachTimeslotByDate,
    setSelectedLessonType,
    getSelectedLessonTypePrice,
  } = useCoachLessonDetailViewModel({
    coachLessonId: coachId,
    dispatch: globalDispatch,
  }) as ICoachLessonDetailViewModel;

  useEffect(() => {
    getCoachLesson().then((res) => {
      if (!res?.success) navigate("../");
    });
    getCoachTimeslotByDate().then((res) => console.log("===res", res));
    onDateSelected(formatDateToString(dayjs().add(1, "day"), "YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    const options = [];
    if (lessonTypeInfo != null) {
      if (lessonTypeInfo?.one_to_one_price > 0) {
        options.push({
          label: t("coaching:coachLessonType.1to1"),
          value: "1to1",
        });
      }
      if (lessonTypeInfo?.one_to_two_price > 0) {
        options.push({
          label: t("coaching:coachLessonType.1to2"),
          value: "1to2",
        });
      }

      if (lessonTypeInfo?.one_to_three_price > 0) {
        options.push({
          label: t("coaching:coachLessonType.1to3"),
          value: "1to3",
        });
      }
    }

    setLessonTypeOptions(options);
  }, [lessonTypeInfo]);

  const handleGoToBookingDetail = async () => {
    setIsReservingTimeslot(true);
    const userChecker = await refreshCurrentUser({
      checkIsNewUser: true,
    });

    if (userChecker?.success && userChecker?.isNewUser === false) {
      try {
        const mongoInsertedId = await holdTimeslot({
          dispatch: globalDispatch,
        });

        setBookingInfo({
          coachLesson,
          lessonTypeInfo,
          selectedDate: dateSelected,
          selectedTimeslots,
          selectedLessonType,
          pricePerLesson: getSelectedLessonTypePrice(),
          bookingIds: mongoInsertedId ?? [],
        });

        navigate(`../coach/${coachId}/booking/confirm`);
      } catch (err) {
        // Note: ViewModel handled show error
        console.log(err);
      }
    }
    setIsReservingTimeslot(false);
  };

  return (
    <CoachBookingBase coachLesson={coachLesson} lessonTypeInfo={lessonTypeInfo}>
      <ColorBannerCard title={t("facilityDetail.selectOptions")}>
        <Typography>{t("facilityDetail.bookingfeeDesc")}</Typography>
        <Space size="30px" />
        <DateCalendarPicker
          id="dateSelected"
          name="dateSelected"
          onChange={(e) => {
            onDateSelected(e);
          }}
          disablePast
          style={{ margin: 0 }}
          defaultValue={dayjs.tz(Date.now()).add(1, "day")}
          minDate={dayjs.tz(Date.now()).add(1, "day")} // Default as tomorrow, block today booking for coach
          maxDate={dayjs.tz().add(config.FACILITY_AVAILBLE_DAY - 1, "day")} //Dafault 14 days
        />
        {timeslots && (
          <TimeSelectTable
            timeslots={timeslots}
            selectedTimeslots={selectedTimeslots}
            onPressTimeslot={onPressTimeslot}
            dateSelected={dateSelected.toString()}
          />
        )}

        <Divider space="40px" />
        <Typography fontWeight="500" fontSize="16px">
          {t("coaching:privateCoachingInfo.type")}
        </Typography>
        <Space size="10px" />
        <DropdownSelect
          value={selectedLessonType}
          options={lessonTypeOptions}
          placeholder={t(
            "coaching:privateCoachingInfo.lessonTypePickerPlaceholder"
          )}
          onChange={setSelectedLessonType}
        />

        <div>
          <Divider space="40px" />
          {/* <TncCheckbox
            id="tnc"
            name="tnc"
            text={t("facilityDetail.termsConditionsStatement")}
            clickableText={t(
              "facilityDetail.termsConditionsStatementPressable"
            )}
            value={acceptTnc}
            onChange={() => setAcceptTnc((prev) => !prev)}
          />
          <TncCheckbox
            id="ageLimit"
            name="ageLimit"
            text={t("facilityDetail.ageLimitation")}
            clickableText={t("facilityDetail.ageLimitationPressable")}
            value={acceptAgeLimit}
            onChange={() => setAcceptAgeLimit((prev) => !prev)}
          />
          <Divider space="35px" /> */}

          {currentUser && (
            <div className="flex justify-between">
              <div>
                <Typography>
                  {t("coaching:privateCoachingInfo.completeRequiredField")}
                </Typography>
              </div>
              <GeneralButton
                disabled={
                  // !acceptAgeLimit ||
                  // !acceptTnc ||
                  selectedTimeslots.size === 0 ||
                  !selectedLessonType ||
                  isReservingTimeslot
                }
                onClick={handleGoToBookingDetail}
              >
                {t("coaching:privateCoachingInfo.btnNext")}
              </GeneralButton>
            </div>
          )}
        </div>
      </ColorBannerCard>
    </CoachBookingBase>
  );
};

export default CoachingBookingPage;
