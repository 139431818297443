import { DispatchWithoutAction } from "react";
import axios from "axios";
import {
  appearGlobalError,
  disappearGlobalLoading,
} from "common/context/requests/globalRequest";
import { getLocalisedString } from "common/utils/stringHelper";
import { SaiShaAPIResponse, SaiShaPaymentResult } from "../@types/common";
import { ROUTE_BASE } from "Route";
import i18n from "i18n";
import clsx, { ArgumentArray } from "classnames";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ArgumentArray) {
  return twMerge(clsx(inputs));
}

export const isEmptyValues = (value: any) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const convertToFormUrlEncoded = (jsonBody: any) => {
  const formBody = Object.keys(jsonBody)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(jsonBody[key])}`
    )
    .join("&");
  return formBody;
};

export async function createPayment(
  res: SaiShaAPIResponse<{ result: SaiShaPaymentResult }>,
  dispatch: DispatchWithoutAction,
  navigate: any,
  totalAmount: number
) {
  if (res?.success === true) {
    //create a hidden form element
    if (totalAmount <= 0) {
      disappearGlobalLoading(dispatch);
      navigate(`/${i18n.language}/${ROUTE_BASE.PAYMENT}/freeorder/success`, {
        state: {
          isFreeOrder: true,
          transType:
            res?.result?.CreatePaymentResult?.confirmedTransaction?.typeV2 ??
            "ET", //default event for free event dont return type
        },
      });
    } else if (res?.result?.CreatePaymentResult?.mPayGatewayUrl != null) {
      const paymentResult = res?.result;

      const {
        CreatePaymentResult: { result, mPayGatewayUrl },
      } = paymentResult || {};

      const form = document.createElement("form");
      form.method = "POST";
      form.action = mPayGatewayUrl as string;

      //create form fields for each data item
      for (const key in result) {
        const value = result[key];
        const input = document.createElement("input") as HTMLInputElement;
        input.setAttribute("type", "hidden");
        input.setAttribute("name", key);
        input.setAttribute("value", value);
        form.appendChild(input);
      }

      //append the form to the document and submit it
      document.body.appendChild(form);
      form.submit();
    }
  } else {
    appearGlobalError(
      dispatch,
      getLocalisedString(
        res?.error?.localizedMessage?.en,
        res?.error?.localizedMessage?.zh
      )
    );
  }
}

export function escapeHTMLtoPlainText(htmlStr: string) {
  return htmlStr.replace(/(<([^>]+)>)/gi, "");
}

export const removeHtmlTag = (text: string) => {
  return text ? text.replace(/<\/?[^>]+(>|$)/g, "").replace(/\n/g, "") : "";
};
