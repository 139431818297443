import { useState } from "react";
import VerifyMobile from "./VerifyMobile";
import LoginDetail from "./LoginDetail";
import { MobileVerificationResult } from "common/models/Verification";
import RegistrationForm from "./RegistrationForm";

export interface ILoginInfo {
  email: string;
  password: string;
}

const RegistrationStepControl = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [verificationMobileResult, setVerificationMobileResult] =
    useState<MobileVerificationResult>({
      dialCode: "",
      phoneNum: "",
      parsedPhoneNum: "",
      skipPhoneVerification: false,
    });
  const [loginInfo, setLoginInfo] = useState<ILoginInfo>({
    email: "",
    password: "",
  });

  switch (currentStep) {
    case 1:
      return (
        <VerifyMobile
          setCurrentStep={setCurrentStep}
          setVerificationMobileResult={setVerificationMobileResult}
        />
      );
    case 2:
      return (
        <LoginDetail
          setCurrentStep={setCurrentStep}
          verificationMobileResult={verificationMobileResult}
          setLoginInfo={setLoginInfo}
        />
      );
    case 3:
      return (
        <RegistrationForm
          verificationMobileResult={verificationMobileResult}
          loginInfo={loginInfo}
        />
      );
    default:
      return <VerifyMobile setCurrentStep={setCurrentStep} />;
  }
};

export default RegistrationStepControl;
