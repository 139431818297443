import styled from "styled-components";
import theme from "utils/theme";

const BoxItem = styled.div`
  display: block;
  width: calc(25% - 22.5px);
  height: 420px;
  background: #fff;
  border-radius: 12px;
  border-bottom-width: 4px;
  border-right-width: 4px;
  border-style: solid;
  border-color: ${theme.orange};
  transition: all 0.2s;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.075);
  }

  @media (max-width: 1199px) {
    width: calc(33.33% - 22.5px);
  }

  @media (max-width: 991px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 639px) {
    width: 100%;
  }
`;

const ItemImg = styled.div<{ img: string }>`
  width: 100%;
  height: 190px;
  background-image: url(${(p) => p.img});
  background-size: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const ItemContent = styled.div`
  width: 100%;
  height: 230px;
  position: relative;
  padding: 25px;
`;

const ItemTag = styled.span<{ bg: string }>`
  display: inline-block;
  position: absolute;
  left: 25px;
  top: -17px;
  height: 35px;
  background-repeat: no-repeat;
  padding: 0px 20px;
  font-size: 14px;
  background-position: center center;
  line-height: 32px;
  color: #fff;
  background-size: contain;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 35px;
    path {
      fill: ${(p) => p.bg};
    }
  }
`;

const TagTitle = styled.span`
  width: 100%;
  z-index: 2;
  position: relative;
`;

const ItemTitle = styled.h3`
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  overflow: hidden;
  margin-bottom: 5px;
`;

const ItemDesc = styled.div`
  color: #4a4a4a;
  height: 90px;
`;

const ItemLink = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${theme.seaBlue};
  font-family: Poppins;
  font-size: 18px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
`;

export default {
  BoxItem,
  ItemImg,
  ItemContent,
  ItemTag,
  TagTitle,
  ItemTitle,
  ItemDesc,
  ItemLink,
};
