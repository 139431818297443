import { forwardRef } from "react";
import Styled from "./Styled";
import theme from "utils/theme";
import Typography from "components/common/Typography";
import cn from "classnames";

interface TableProps extends React.HTMLProps<HTMLDivElement> {
  title: string;
  children: React.ReactNode;
  customContent?: boolean;
  boldTitle?: boolean;
}

export const SubTitle = ({ title }: { title: string }) => {
  return (
    <div
      className={cn(
        "bg-[#F1F1F1]",
        "border-b",
        "border-[#D8D8D8]",
        "border-solid",
        "h-auto",
        "w-full",
        "px-[20px]",
        "py-[10px]"
      )}
    >
      <Typography color={theme.blue} fontWeight={700} textAlign="justify">
        {title}
      </Typography>
    </div>
  );
};

export const Section = ({
  title,
  content,
}: {
  title?: string;
  content?: string;
}) => {
  return (
    <div
      className={cn(
        "bg-[#fff]",
        "border-b",
        "border-[#D8D8D8]",
        "border-solid",
        "px-[20px]",
        "py-[10px]"
      )}
    >
      <Typography fontWeight="700" textAlign="justify">
        {title}
      </Typography>
      <Typography textAlign="justify">{content}</Typography>
    </div>
  );
};

const ColorBannerCard = forwardRef<HTMLDivElement, TableProps>(
  (props: TableProps, ref) => {
    const { title, children, customContent, boldTitle, ...remainsHTMLProps } =
      props;
    return (
      <div {...remainsHTMLProps} ref={ref}>
        <Styled.TableTitle boldTitle={boldTitle}>
          {boldTitle ? (
            <Typography color={theme.blue} fontSize="20px" fontWeight="700">
              {title}
            </Typography>
          ) : (
            title
          )}
        </Styled.TableTitle>
        {customContent ? (
          children
        ) : (
          <Styled.TableCard>{children}</Styled.TableCard>
        )}
      </div>
    );
  }
);

export default ColorBannerCard;
