import { Grid } from "@mui/material";
import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import theme from "utils/theme";

const VenueWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
  margin-bottom: 30px;
`;

const FacilityDetailContainer = styled.div`
  background-color: ${theme.lightgreyBg};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: auto;
  padding: 30px;
  margin-top: 26px;
  padding-bottom: 100px;
`;

const BayContainer = styled.div`
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: auto;
  margin-top: 26px;
  display: flex;
`;

const BayListing = styled.div`
  border-top-left-radius: 15px;
  height: auto;
  padding: 25px;
  overflow: auto;

  @media ${breakpoint.desktop} {
    width: 50%;
  }

  @media ${breakpoint.mobile} {
    width: 100%;
    border-top-right-radius: 15px;
  }
`;

const BayDetail = styled.div`
  border-top-right-radius: 15px;
  width: 50%;
  height: auto;
  padding: 25px;

  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

const RedBordedLabel = styled.div<{ selected: boolean }>`
  border: 1px ${theme.orange} solid;
  background: ${(props) => (props.selected ? theme.orange : theme.white)};
  width: fit-content;
  border-radius: 25px;
  padding: 12px 20px;
  cursor: pointer;
`;

const BayCard = styled.div`
  border: 1px ${theme.grey} solid;
  background: ${theme.white};
  width: 100%;
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export default {
  VenueWrapper,
  FacilityDetailContainer,
  BayContainer,
  BayListing,
  BayDetail,
  RedBordedLabel,
  BayCard,
};
