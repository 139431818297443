import dayjs from 'dayjs';
import i18n from '../../../i18n';
import { Coach } from '../CoachLesson/Coach';
import { Facility } from '../Facility';
import { Venue } from '../Venue';
import { MonthlyProgram } from './MonthlyProgram';
import { MonthlyProgrammeLesson } from './MonthlyProgramLesson';
import { formatDateToString } from '../../utils/DateHelper';

export enum ProgramClassStatus {
    PENDING = 'PENDING',
    OPEN_ENROLL = 'OPEN_ENROLL',
    ENROLLED = 'ENROLLED',
    WAIT_LIST = 'WAIT_LIST',
    ALREADY_ON_LIST = 'ALREADY_ON_LIST',
    CANCELLED = 'CANCELLED',
    FULL = 'FULL'
}

export enum EEnrolmentRole {
    otherStudent = "OTHER_STUDENT",
    currentStudent= "CURRENT_STUDENT",
    waitingListStudent = "WAITING_LIST_STUDENT"
}

export interface IEnrollmentDate {
    currentStudent: string;
    otherStudent: string;
    waitingListStudent: string;
}
export interface IMonthlyProgramClass {
    _id: string;
    programmeId?: string;
    titleEn: string;
    titleZh: string;
    remarksEn?: string;
    remarksZh?: string;
    minAge: number;
    maxAge: number;
    quota: number;
    pricing: number;
    totalPrice: number;
    programme: MonthlyProgram;
    lessons: MonthlyProgrammeLesson[];
    coaches: Coach[];
    venues: Venue[];
    enrolmentStart?: string;
    enrolmentEnd?: string;
    classYearMonth: string;
    status: ProgramClassStatus;
    isAgeAllowed?: boolean;
    isEnrollmentClosed?: boolean;
    enrolmentRole?: string;
    enrolmentDates: IEnrollmentDate;

    readonly title?: string;
    readonly remarks?: string;
    readonly isAllowJoinWaitingList: boolean;
    readonly isAllowEnroll: boolean;
    readonly isAlreadyOnTheList: boolean;
    readonly formattedEnrollmentDates: IEnrollmentDate;
}

export class MonthlyProgramClass implements IMonthlyProgramClass {
    _id: string;
    titleEn: string;
    titleZh: string;
    remarksEn?: string;
    remarksZh?: string;
    minAge: number;
    maxAge: number;
    quota: number;
    pricing: number;
    totalPrice: number;
    programme: MonthlyProgram;
    lessons: MonthlyProgrammeLesson[];
    coaches: Coach[];
    venues: Venue[];
    enrolmentStart?: string;
    enrolmentEnd?: string;
    classYearMonth: string;
    status: ProgramClassStatus;
    isAgeAllowed?: boolean;
    isEnrollmentClosed?: boolean;
    enrolmentRole?: string;
    enrolmentDates: IEnrollmentDate;

    constructor({
        _id,
        programmeId,
        titleEn,
        titleZh,
        remarksEn,
        remarksZh,
        minAge,
        maxAge,
        quota,
        pricing,
        totalPrice,
        programme,
        lessons,
        coaches,
        venues,
        enrolmentStart,
        enrolmentEnd,
        classYearMonth,
        status,
        isAgeAllowed,
        isEnrollmentClosed,
        enrolmentRole,
        enrolmentDates,
    }: any) {
        this._id = _id;
        this.titleEn = titleEn;
        this.titleZh = titleZh;
        this.remarksEn = remarksEn;
        this.remarksZh = remarksZh;
        this.minAge = minAge;
        this.maxAge = maxAge;
        this.quota = quota;
        this.pricing = pricing;
        this.totalPrice = totalPrice;
        this.programme = new MonthlyProgram(programme);
        this.lessons = lessons.map((lesson: any) => new MonthlyProgrammeLesson(lesson));
        this.coaches = coaches.map((coach: any) => new Coach(coach));
        this.venues = venues.map((veune: any) => new Venue(veune));
        this.enrolmentStart = enrolmentStart;
        this.enrolmentEnd = enrolmentEnd;
        this.classYearMonth = classYearMonth;
        this.status = status;
        this.isAgeAllowed = isAgeAllowed;
        this.isEnrollmentClosed = isEnrollmentClosed;
        this.enrolmentRole = enrolmentRole,
        this.enrolmentDates = enrolmentDates
    }

    get title(): string | undefined {
        return i18n.language === 'en' ? this.titleEn : this.titleZh;
    }

    get remarks(): string | undefined {
        return i18n.language === 'en' ? this.remarksEn : this.remarksZh;
    }

    get isAllowJoinWaitingList(): boolean {
        return this.status === ProgramClassStatus.WAIT_LIST;
    }

    get isAllowEnroll(): boolean {
        return this.status === ProgramClassStatus.OPEN_ENROLL;
    }

    get isAlreadyOnTheList(): boolean {
        return this.status === ProgramClassStatus.ALREADY_ON_LIST;
    }

    get formattedEnrollmentDates(): IEnrollmentDate {
        const dateFormat = "DD/MM/YYYY"
        return {
            currentStudent: formatDateToString(dayjs(this.enrolmentDates.currentStudent), dateFormat),
            waitingListStudent: formatDateToString(dayjs(this.enrolmentDates.waitingListStudent), dateFormat),
            otherStudent: formatDateToString(dayjs(this.enrolmentDates.otherStudent), dateFormat)
        }
    }
}
