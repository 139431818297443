import { useEffect, useState } from "react";
import { listFacilityCategory } from "../../network/facility";
import { appearGlobalError } from "../../context/requests/globalRequest";
import { useCommonFetcher } from "../Common/CommonViewModel";
import { FacilityCategory } from "common/models/FacilityCategory";

export const categoryRowSize = 3;

export const useCategoryListViewModel = () => {
  const { fetcher } = useCommonFetcher();
  const [categoryList, setCategoryList] = useState([]);
  // For app display purpose
  const [categoryRows, setCategoryRows] = useState([]);

  useEffect(() => {
    // Group categories into rows
    let tmpRows = [];
    for (let i = 0; i < categoryList.length; i += categoryRowSize) {
      tmpRows.push(categoryList.slice(i, i + categoryRowSize));
    }
    setCategoryRows(tmpRows);
  }, [categoryList]);

  const getCategoryList = async (module) => {
      const res = await fetcher({ api: () => listFacilityCategory({module}), skipErrorHandler: true });
      const data = res?.result?.map((category) => new FacilityCategory(category)) ?? [];
      setCategoryList(data);
  };

  return {
    categoryList,
    categoryRows,
    getCategoryList,
  };
};
