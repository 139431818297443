import { GlobalContextStore } from "common/context/providers/globalProvider";
import { FacilityBooking } from "common/models/FacilityBooking";
import {
  ProgramTypes,
  useMyBookingListViewModel,
} from "common/viewModels/MyBooking/MyBookingListViewModel";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import {
  MyCoachListingCard,
  MyEventListingCard,
  MyFacilityListingCard,
  MyProgramListingCard,
} from "components/MyBookingListingCard";
import NavSlider from "components/common/NavSlider/NavSlider";
import { Select, SelectOption } from "components/common/Select";
import { useBackButtonContext } from "hooks/useBackButton";
import { useContext, useEffect, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  IGlobalProviderContext,
  IMyBookingListViewModel,
  IUserViewModelContext,
} from "../../@types/common";
import { ROUTE_BASE } from "../../Route";
import { getLocalizedString } from "../../utils/locale";
import Styled from "./Styled";
import MyPastRecord from "./MyPastBooking";
import dayjs from "common/utils/dayjs";

export const MyBookingListPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("myBooking");
  const [searchParams, setSearchParams] = useSearchParams({ cat: "" });
  const category = searchParams.get("cat");

  const { currentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { setIsShowBack } = useBackButtonContext();
  const {
    monthlyProgramBookingList,
    coachLessonBookingList,
    eventBookingList,
    facilityBookingList,
    switchBookingListTab,
  } = useMyBookingListViewModel({
    isLoggedIn: !!currentUser,
    dispatch: globalDispatch,
  }) as IMyBookingListViewModel;

  const items = useMemo(
    () => [
      {
        key: "program",
        label: t("listing.program"),
        onClick: () => setSearchParams({ cat: "program" }),
        isActive: !category || !!category?.match("program"),
      },
      {
        key: "coach",
        label: t("listing.coach"),
        onClick: () => setSearchParams({ cat: "coach" }),
        isActive: !!category?.match("coach"),
      },
      {
        key: "event",
        label: t("listing.event"),
        onClick: () => setSearchParams({ cat: "event" }),
        isActive: !!category?.match("event"),
      },
      {
        key: "facility",
        label: t("listing.facility"),
        onClick: () => setSearchParams({ cat: "facility" }),
        isActive: !!category?.match("facility"),
      },
      {
        key: "past-record",
        label: t("listing.pastRecord"),
        onClick: () => setSearchParams({ cat: "past-record" }),
        isActive: !!category?.match("past-record"),
      },
    ],
    [category, setSearchParams, t]
  );

  useEffect(() => {
    switch (category) {
      case "program": {
        switchBookingListTab(ProgramTypes.Program);
        break;
      }
      case "coach": {
        switchBookingListTab(ProgramTypes.Coach);
        break;
      }
      case "event": {
        switchBookingListTab(ProgramTypes.Event);
        break;
      }
      case "facility": {
        switchBookingListTab(ProgramTypes.Facility);
        break;
      }
      case "past-record":
        break;
      default:
        switchBookingListTab(ProgramTypes.Program);
    }
  }, [category, switchBookingListTab]);

  useLayoutEffect(() => setIsShowBack(false), [setIsShowBack]);

  return (
    <>
      <NavSlider items={items} />
      <div className="mt-[20px] flex flex-col gap-[20px]">
        {(!category || category?.match("program")) &&
          monthlyProgramBookingList.map((program) => {
            return (
              <MyProgramListingCard
                key={program._id}
                programTitle={program.class.programme.titleEn}
                coachNames={program.class.coaches.map(
                  (coach) => coach.coach_name_en
                )}
                startDate={new Date(program.lessons[0].startTime)}
                endDate={
                  new Date(program.lessons[program.lessons.length - 1].endTime)
                }
                classTitle={
                  program.class.titleEn +
                  ` ${dayjs(program.lessons[0].startTime).tz().format("MMM")}`
                }
                lessons={program.lessons}
                onClick={() =>
                  navigate(`${program._id}`, {
                    state: { bookingType: ProgramTypes.Program },
                  })
                }
              />
            );
          })}
        {category?.match("coach") &&
          coachLessonBookingList.map((order) => {
            return (
              <MyCoachListingCard
                key={order._id}
                coachName={`${getLocalizedString(
                  order?.coach_id?.coach_name_en,
                  order?.coach_id?.coach_name_zh
                )} (${t(`coaching:coachLessonType.${order?.lessonType}`)})`}
                date={order?.reserved_date}
                times={order?.bookings}
                location={getLocalizedString(
                  order?.venue_id?.name_en,
                  order?.venue_id?.name_tc
                )}
                onClick={() =>
                  navigate(`${order._id}`, {
                    state: { bookingType: ProgramTypes.Coach },
                  })
                }
              />
            );
          })}
        {category?.match("event") &&
          eventBookingList.map((eventBooking) => {
            return (
              <MyEventListingCard
                key={eventBooking._id}
                title={getLocalizedString(
                  eventBooking?.event?.title_en,
                  eventBooking?.event?.title_tc
                )}
                dates={eventBooking?.event?.event_date ?? []}
                venue={getLocalizedString(
                  eventBooking?.event?.venue_en,
                  eventBooking?.event?.venue_tc
                )}
                onClick={() =>
                  navigate(`${eventBooking._id}`, {
                    state: { bookingType: ProgramTypes.Event },
                  })
                }
              />
            );
          })}
        {category?.match("facility") &&
          facilityBookingList.map((order) => {
            return (
              <MyFacilityListingCard
                key={order._id}
                title={order?.bookings[0]?.venue?.getName()}
                reservedDate={order?.bookings[0]?.reserved_date}
                dates={order.bookings.map((booking: FacilityBooking) => ({
                  startTime: booking.start_time,
                  endTime: booking.end_time,
                }))}
                onClick={() =>
                  navigate(`${order._id}`, {
                    state: { bookingType: ProgramTypes.Facility },
                  })
                }
              />
            );
          })}

        {/** the list rendered above and noRecords text are mutually exclusive */}
        {(!category || category?.match("program")) &&
          monthlyProgramBookingList.length === 0 && (
            <p>{t("myBooking:listing.noRecords")}</p>
          )}
        {category?.match("coach") && coachLessonBookingList.length === 0 && (
          <p>{t("myBooking:listing.noRecords")}</p>
        )}
        {category?.match("event") && eventBookingList.length === 0 && (
          <p>{t("myBooking:listing.noRecords")}</p>
        )}
        {category?.match("facility") && facilityBookingList.length === 0 && (
          <p>{t("myBooking:listing.noRecords")}</p>
        )}
        {category?.match("past-record") && <MyPastRecord />}
      </div>
    </>
  );
};
