import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { useDropzone } from "react-dropzone";
import { TextFieldLabel } from "../Textfield";
import { useUploadFile } from "hooks/useUploadFile";
import Typography from "../Typography";
import { useTranslation } from "react-i18next";
import Space from "../Space";
import theme from "utils/theme";

const WaitForUploadCard = styled.button`
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--palette-ss-black, #4a4a4a);
  background: #fff;
  display: grid;
  place-items: center;
`;

const Caption = styled.span`
  color: ${theme.orange};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
`;

const CardIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
`;

const HKIDImage = styled.img`
  width: fit-content;
  height: 400px;
  border-radius: 10px;
  object-fit: contain;
`;

type Props = {
  width?: string | number;
  title?: string;
  height?: string | number;
  cardCaption: string;
  imageUrl?: string | null;
  errorMessage?: string;
  onImageUrlChange?: (url: string, file: File) => void;
};

const PhotoUploadCard = (props: Props) => {
  const {
    title,
    width = "100%",
    height = "100%",
    imageUrl,
    errorMessage,
    onImageUrlChange,
  } = props;
  const { t } = useTranslation();

  const cardCaption = useMemo(
    () =>
      props?.cardCaption?.charAt(0).toUpperCase() +
      props?.cardCaption?.slice(1),
    [props.cardCaption]
  );

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles?.[0];
    const targetUri = URL.createObjectURL(file);
    if (onImageUrlChange != null) {
      onImageUrlChange(targetUri, file);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    onDrop,
    useFsAccessApi: true,
  });

  return (
    <section id="upload-photo-card">
      <button type="button" {...getRootProps({ className: "dropzone" })}>
        <div className="flex flex-col items-start justify-start">
          {title != null && <TextFieldLabel>{title}</TextFieldLabel>}
          {imageUrl ? (
            <div className="flex justify-center w-full">
              <HKIDImage src={imageUrl} style={{ height }} />
            </div>
          ) : (
            <>
              {errorMessage && (
                <Typography fontSize="12px" color={theme.error}>
                  {errorMessage}
                </Typography>
              )}
              <WaitForUploadCard style={{ width, height }}>
                <CardIconContainer>
                  <CameraSvg />
                  <Caption>{cardCaption}</Caption>
                </CardIconContainer>
              </WaitForUploadCard>
            </>
          )}
          <Space size="15px" />
          <Typography fontSize="12px" textAlign="left">
            {t("register:profile_upload.desc")}
          </Typography>
          <input {...getInputProps()} />
        </div>
      </button>
    </section>
  );
};

const CameraSvg = () => (
  <svg
    width="66"
    height="54"
    viewBox="0 0 66 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_2300_8680" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.099 0C21.7156 0 19.6635 1.6823 19.1961 4.01942L18.1961 9.01942C18.1543 9.22836 18.1262 9.43635 18.1109 9.64258H5C2.23858 9.64258 0 11.8812 0 14.6426V48.9997C0 51.7611 2.23858 53.9997 5 53.9997H61C63.7614 53.9997 66 51.7612 66 48.9997V14.6426C66 11.8812 63.7614 9.64258 61 9.64258H47.8891C47.8738 9.43635 47.8457 9.22836 47.8039 9.01942L46.8039 4.01942C46.3365 1.6823 44.2844 0 41.901 0H24.099Z"
      />
    </mask>
    <path
      d="M19.1961 4.01942L17.235 3.62719L19.1961 4.01942ZM18.1961 9.01942L16.235 8.62719L18.1961 9.01942ZM18.1109 9.64258V11.6426C19.1583 11.6426 20.0283 10.8345 20.1055 9.78994L18.1109 9.64258ZM47.8891 9.64258L45.8945 9.78994C45.9717 10.8345 46.8417 11.6426 47.8891 11.6426V9.64258ZM47.8039 9.01942L45.8427 9.41165L45.8427 9.41167L47.8039 9.01942ZM46.8039 4.01942L44.8427 4.41165V4.41165L46.8039 4.01942ZM21.1573 4.41165C21.4377 3.00938 22.669 2 24.099 2V-2C20.7623 -2 17.8893 0.355218 17.235 3.62719L21.1573 4.41165ZM20.1573 9.41165L21.1573 4.41165L17.235 3.62719L16.235 8.62719L20.1573 9.41165ZM20.1055 9.78994C20.1146 9.66593 20.1317 9.53972 20.1573 9.41165L16.235 8.62719C16.177 8.917 16.1377 9.20677 16.1164 9.49522L20.1055 9.78994ZM5 11.6426H18.1109V7.64258H5V11.6426ZM2 14.6426C2 12.9857 3.34314 11.6426 5 11.6426V7.64258C1.13401 7.64258 -2 10.7766 -2 14.6426H2ZM2 48.9997V14.6426H-2V48.9997H2ZM5 51.9997C3.34315 51.9997 2 50.6566 2 48.9997H-2C-2 52.8657 1.13401 55.9997 5 55.9997V51.9997ZM61 51.9997H5V55.9997H61V51.9997ZM64 48.9997C64 50.6566 62.6569 51.9997 61 51.9997V55.9997C64.866 55.9997 68 52.8657 68 48.9997H64ZM64 14.6426V48.9997H68V14.6426H64ZM61 11.6426C62.6569 11.6426 64 12.9857 64 14.6426H68C68 10.7766 64.866 7.64258 61 7.64258V11.6426ZM47.8891 11.6426H61V7.64258H47.8891V11.6426ZM45.8427 9.41167C45.8683 9.53971 45.8854 9.66592 45.8945 9.78994L49.8836 9.49522C49.8623 9.20679 49.823 8.91701 49.765 8.62717L45.8427 9.41167ZM44.8427 4.41165L45.8427 9.41165L49.765 8.62719L48.765 3.62719L44.8427 4.41165ZM41.901 2C43.331 2 44.5623 3.00938 44.8427 4.41165L48.765 3.62719C48.1107 0.355217 45.2377 -2 41.901 -2V2ZM24.099 2H41.901V-2H24.099V2Z"
      fill="#A6A6A6"
      mask="url(#path-1-inside-1_2300_8680)"
    />
    <circle cx="33" cy="30" r="15" stroke="#A6A6A6" strokeWidth="2" />
    <path
      d="M6 10L7 7H13L14 10"
      stroke="#A6A6A6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PhotoUploadCard;
