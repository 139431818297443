import Typography from "components/common/Typography";
import theme from "utils/theme";
import Checkbox from "components/common/Checkbox";
import SimpleModal from "components/common/Modal/SimpleTextModal";
import SimpleTextModal from "components/common/Modal/SimpleTextModal";
import { useState } from "react";
import { getLocalisedString } from "common/utils/stringHelper";
import { useTranslation } from "react-i18next";

interface TNCData {
  desc_en?: string;
  desc_tc?: string;
  title_en?: string;
  title_tc?: string;
}

interface TncCheckboxProps {
  id: string;
  name: string;
  value: boolean;
  onChange: () => void;
  data?: TNCData;
}

const TncCheckbox = ({ id, name, value, onChange, data }: TncCheckboxProps) => {
  const { t } = useTranslation("common");

  const [isOpenTnc, setIsOpenTnc] = useState<boolean>(false);
  const [isOpenPrivacyPolicy, setIsOpenPrivacyPolicy] =
    useState<boolean>(false);

  const handleOnClick = (type: string) => {
    if (type === "tnc") {
      setIsOpenTnc(true);
    } else if (type === "privacyPolicy") {
      setIsOpenPrivacyPolicy(true);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <div className="flex-shrink-0">
        <Checkbox id={id} name={name} onChange={onChange} checked={value} />
      </div>
      <div className="flex flex-col">
        <Typography fontSize="14px">{`${t(
          "common:tnc.termsConditionsStatement"
        )}`}</Typography>
        <Typography
          fontSize="14px"
          color={theme.orange}
          className="inline cursor-pointer"
        >
          <span> </span>
          <span onClick={() => handleOnClick("privacyPolicy")}>
            {`${t("common:tnc.termsConditionsStatementPrivacyPressable")}`}
          </span>
          <span onClick={() => handleOnClick("tnc")}>
            {`${t("common:tnc.termsConditionsStatementTncPressable")}`}
          </span>
        </Typography>
      </div>

      <SimpleTextModal
        open={isOpenTnc}
        onClose={() => setIsOpenTnc(false)}
        title={t("common:tnc.title")}
        content={t("common:tnc.desc")}
        needResponsive={true}
      />
      <SimpleTextModal
        open={isOpenPrivacyPolicy}
        onClose={() => setIsOpenPrivacyPolicy(false)}
        title={t("common:tnc.privacyTitle")}
        content={t("common:tnc.privacyDesc")}
        enableHtml
        needResponsive={true}
      />
    </div>
  );
};

export default TncCheckbox;
