import styled from "styled-components";

const CustomModalCard = styled.div`
  position: relative;
  width: calc(100dvw - 42px);
  background: var(--palette-ss-white, #fff);
  border-radius: 30px;
  max-width: 632px;
`;
export default { CustomModalCard };
