import { useState } from "react";
import BaseModal from "./BaseModal";
import Typography from "../Typography";
import Styled from "./Styled";
import GeneralButton from "../Button";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";
import Space from "../Space";
import IconClose from "../../../assets/white-cross.svg";
import cn from "classnames";
import CloseIconModal from "./CloseIconModal";
import HTMLTypography from "../HTMLTypography";

interface ModalProps {
  title?: string;
  content: string;
  open: boolean;
  onClose: () => void;
  enableHtml?: boolean;
  classname?: string;
  needResponsive?: boolean;
}

const SimpleTextModal = ({
  title,
  content,
  open,
  enableHtml,
  onClose,
  needResponsive
}: ModalProps) => {
  const { t } = useTranslation("common");
  return (
    <CloseIconModal needResponsive={needResponsive} open={open} onClose={onClose}>
      <Styled.SimpleModalCotnainer>
        <div
          className={cn(
            "absolute",
            "top-[-36.04px]",
            "right-0",
            "lg:top-0",
            "lg:right-[-42px]",
            "cursor-pointer",
          )}
        >
          <img src={IconClose} onClick={onClose} />
        </div>
        <div>
          {title && (
            <Typography color={theme.black} fontSize="20px" fontWeight="500">
              {title}
            </Typography>
          )}
          {enableHtml ? (
            <HTMLTypography content={content} />
          ) : (
            <Typography>{content}</Typography>
          )}
          <Space size="50px" />
          <GeneralButton onClick={onClose} fullWidth>
            {t("button.ok")}
          </GeneralButton>
        </div>
      </Styled.SimpleModalCotnainer>
    </CloseIconModal>
  );
};

export default SimpleTextModal;
