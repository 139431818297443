/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import NavSlider from "components/common/NavSlider/NavSlider";

import { useBackButtonContext } from "hooks/useBackButton";
import {
  VoucherType as VoucherTypeConst,
  useVoucherViewModel,
} from "common/viewModels/VoucherViewModel";
import { IVoucherViewModel } from "models/Voucher";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import {
  IGlobalProviderContext,
  ISportPassViewModel,
  IUserViewModelContext,
} from "../../@types/common";
import { useTranslation } from "react-i18next";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { useSportpassViewModel } from "common/viewModels/SportpassViewModel";
import ActiveVoucherList from "./ActiveVoucherList";
import RedeemedVoucherList from "./RedeemedVoucherList";
import ConfirmModal from "components/common/Modal/ConfirmModal";
import Typography from "components/common/Typography";
import _ from "lodash";
import { ROUTE_BASE } from "Route";

export enum VoucherTypeSearch {
  ALL = "",
  CASH = "cash",
  FACILITY = "facility",
  COACH = "coach",
  DAY_PASS = "day-pass",
  MONTHLY_PASS = "monthly-pass",
  ANNUAL_PASS = "annual-pass",
  ADMISSION = "admission",
  EVENT = "event",
  REDEEMED = "redeemed",
}

const searchToVoucherType = (type: string) => {
  const targets = Object.entries(VoucherTypeSearch).filter(
    ([, value]) => value === type
  );
  if (targets.length === 0) return null;
  const [key] = targets[0];
  if (key === "ALL") return null;
  return VoucherTypeConst[key as keyof typeof VoucherTypeConst];
};

const VoucherListPage = () => {
  const { t, i18n } = useTranslation();

  const { setIsShowBack } = useBackButtonContext();
  const [searchParams, setSearchParams] = useSearchParams({ type: "" });
  const qstype = Object.values(VoucherTypeSearch).includes(
    (searchParams.get("type") as VoucherTypeSearch) ?? VoucherTypeSearch.ALL
  )
    ? (searchParams.get("type") as VoucherTypeSearch)
    : VoucherTypeSearch.ALL;

  const [passRenewable, setPassRenewable] = useState(false);
  const [redeemVoucherId, setRedeemVoucherId] = useState<string>("");

  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { currentUser, isCurrentPassExpired, refreshCurrentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const {
    availableVouchers,
    unavailableVouchers,
    getExpiredVouchers,
    getAvailableVouchers,
    redeemAdmissionPass,
  } = useVoucherViewModel({
    dispatch: globalDispatch,
  }) as IVoucherViewModel;
  const { enableRenew, sportpassList, getSportpassList } =
    useSportpassViewModel({ isRenew: false }) as ISportPassViewModel;
  const navigate = useNavigate();

  const items = useMemo(
    () => [
      {
        key: "all",
        label: t("voucher:filterOption.all"),
        onClick: () => setSearchParams({ type: VoucherTypeSearch.ALL }),
        isActive: !qstype,
      },
      {
        key: "cash",
        label: t("voucher:filterOption.cash"),
        onClick: () => setSearchParams({ type: VoucherTypeSearch.CASH }),
        isActive: !!qstype?.match("cash"),
      },
      {
        key: "facility",
        label: t("voucher:filterOption.facility"),
        onClick: () => setSearchParams({ type: VoucherTypeSearch.FACILITY }),
        isActive: !!qstype?.match(VoucherTypeSearch.FACILITY),
      },
      {
        key: "coach",
        label: t("voucher:filterOption.coach"),
        onClick: () => setSearchParams({ type: VoucherTypeSearch.COACH }),
        isActive: !!qstype?.match(VoucherTypeSearch.COACH),
      },
      // {
      //   key: "day-pass",
      //   label: t("voucher:filterOption.day"),
      //   onClick: () => setSearchParams({ type: VoucherTypeSearch.DAY_PASS }),
      //   isActive: !!qstype?.match(VoucherTypeSearch.DAY_PASS),
      // },
      // {
      //   key: "monthly-pass",
      //   label: t("voucher:filterOption.monthly"),
      //   onClick: () =>
      //     setSearchParams({ type: VoucherTypeSearch.MONTHLY_PASS }),
      //   isActive: !!qstype?.match(VoucherTypeSearch.MONTHLY_PASS),
      // },
      // {
      //   key: "annual-pass",
      //   label: t("voucher:filterOption.annual"),
      //   onClick: () => setSearchParams({ type: VoucherTypeSearch.ANNUAL_PASS }),
      //   isActive: !!qstype?.match(VoucherTypeSearch.ANNUAL_PASS),
      // },
      // {
      //   key: "admission",
      //   label: t("voucher:filterOption.admission"),
      //   onClick: () => setSearchParams({ type: VoucherTypeSearch.ADMISSION }),
      //   isActive: !!qstype?.match(VoucherTypeSearch.ADMISSION),
      // },
      {
        key: "event",
        label: t("voucher:filterOption.event"),
        onClick: () => setSearchParams({ type: VoucherTypeSearch.EVENT }),
        isActive: !!qstype?.match(VoucherTypeSearch.EVENT),
      },
      {
        key: "redeemed",
        label: t("voucher:button.redeem"),
        onClick: () => setSearchParams({ type: VoucherTypeSearch.REDEEMED }),
        isActive: !!qstype?.match(VoucherTypeSearch.REDEEMED),
      },
    ],
    [qstype, setSearchParams, t]
  );

  // https://gitlab.zensis.com/saisha/saisha-client/blob/develop/src/components/screens/profile/RedeemedVoucherScreen.js#L29
  const refreshRedeemedVouchers = useCallback((type: string) => {
    if (type != null && type != "all") {
      getExpiredVouchers({ type: type });
    } else {
      getExpiredVouchers({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSportpassList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // https://gitlab.zensis.com/saisha/saisha-client/blob/develop/src/components/screens/profile/VoucherScreen.js#L56
  useEffect(() => {
    const voucherType = searchToVoucherType(qstype);
    if (qstype === VoucherTypeSearch.ALL || voucherType === null) {
      getAvailableVouchers({});
    } else {
      getAvailableVouchers({ type: voucherType });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qstype]);

  useEffect(() => {
    setPassRenewable(
      (!currentUser?.sportpass ||
        isCurrentPassExpired ||
        enableRenew(currentUser?.sportpass)) ??
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isCurrentPassExpired]);

  useLayoutEffect(() => setIsShowBack(false), [setIsShowBack]);

  const handleRedeemAdmission = () => {
    redeemAdmissionPass({ voucherId: redeemVoucherId }).then((res) => {
      if (res) {
        refreshCurrentUser();
        navigate(`${i18n.language}/${ROUTE_BASE.PROFILE}/edit`);
      }
    });
    setRedeemVoucherId("");
  };

  return (
    <React.Fragment>
      <NavSlider items={items} />
      <div className="h-[32px]" />

      {qstype == VoucherTypeSearch.REDEEMED ? null : (
        <ActiveVoucherList
          tab={qstype}
          availableVouchers={availableVouchers}
          sportpassList={sportpassList}
          sportPassRenewable={passRenewable}
          setRedeemVoucherId={setRedeemVoucherId}
        />
      )}
      {qstype !== VoucherTypeSearch.REDEEMED ? null : (
        <RedeemedVoucherList
          unavailableVouchers={unavailableVouchers}
          refreshVoucherList={refreshRedeemedVouchers}
        />
      )}

      <ConfirmModal
        open={!_.isEmpty(redeemVoucherId)}
        onClose={() => setRedeemVoucherId("")}
        onConfirm={() => handleRedeemAdmission()}
      >
        <Typography>{t("voucher:confirmMsg")}</Typography>
      </ConfirmModal>
    </React.Fragment>
  );
};

export default VoucherListPage;
