import { useState } from "react";
import Typography from "components/common/Typography";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";
import IconTnc from "../assets/ic-tnc.svg";
import { TAndC } from "models";
import { getLocalisedString } from "common/utils/stringHelper";
import SimpleModal from "components/common/Modal/SimpleTextModal";
import cn from "classnames";

interface TncProps {
  data: TAndC;
  className?: string;
}

const TncLabel = ({ data, className }: TncProps) => {
  const { t } = useTranslation("booking");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div>
      <div
        className={cn(className, "flex items-center cursor-pointer")}
        onClick={() => setIsOpen(true)}
      >
        <Typography color={theme.orange} fontSize="14px">
          {t("bookingDetail.tAndC")}
        </Typography>
        <img src={IconTnc} className="ml-[5px]" />
      </div>
      <SimpleModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        content={getLocalisedString(data?.desc_en, data?.desc_tc)}
        title={getLocalisedString(data?.title_en, data?.title_tc)}
      />
    </div>
  );
};

export default TncLabel;
