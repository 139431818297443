import styled from "styled-components";
import theme from "utils/theme";

const Container = styled.div<{
  ref: React.MutableRefObject<HTMLDivElement | undefined>;
}>`
  border-bottom: 1px solid var(--palette-ss-grey-text, #8c8c8c);
`;

const NavItemBox = styled.div`
  color: var(--grey-text, #8c8c8c);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: nowrap;
  cursor: pointer;
  padding-bottom: 9px;

  &.active,
  &:hover {
    border-bottom: 3px solid ${theme.orange};
  }
`;

const ScrollDiv = styled.div<{
  ref: React.MutableRefObject<HTMLDivElement | undefined>;
}>`
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default {
  Container,
  NavItemBox,
  ScrollDiv,
};
