export enum PromoCodeDiscountType {
  PRICE = "PRICE",
  PERCENTAGE = "PERCENTAGE",
}

// view model level (copied from monthlyProgrammeViewModel)
export interface PromoCode {
  code: string;
  type: PromoCodeDiscountType;
  discount: number;
  // from viewModel
  orderDiscountedAmount: number;
  orderEnjoyedDiscount: number;
  promoCodeTicketId: string;
}

// return from api level
export interface PromoCodeDocument {
  // from api
  discountAmount: number;
  discountPercentage?: number;
  finalPrice: number;
  code: string;
  discountType: PromoCodeDiscountType;
}
