import { HeadlineNews } from "models";
import React from "react";
import Styled from "./Styled";
import cn from "classnames";

type TitleListProps = {
  headlineNews: HeadlineNews[];
  handleClickNews: (news: HeadlineNews) => void;
} & React.HTMLProps<HTMLElement>;
const TitleList = ({
  headlineNews,
  handleClickNews,
  ...props
}: TitleListProps) => {
  return (
    <ul className={cn([props.className], "bg-app-lightCyan")}>
      {headlineNews.map((news) => {
        return (
          <li
            key={news._id}
            className="border-b border-[#d8d8d8] border-solid py-10 px-6 cursor-pointer"
            onClick={() => handleClickNews(news)}
          >
            {news.title_en}
          </li>
        );
      })}
    </ul>
  );
};

export default TitleList;
