import React, { useCallback, useContext, useEffect, useState } from "react";
import cn from "classnames";
import ProfileCotainer from "../../pageComponents/profile/ProfileContainer";
import { useTranslation } from "react-i18next";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { Outlet, matchRoutes, useNavigate } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import PageBackButton from "components/common/PageBackButton";
import ProfileLayout from "layouts/ProfileLayout/ProfileLayout";
import _ from "lodash";

const profileRoutes = {
  index: "/profile/",
  mySportPass: "/profile/my-sport-pass/",
  others: "/profile/:proxy",
};

const ProfileScreen = () => {
  const { t, i18n } = useTranslation("profile");
  const navigate = useNavigate();
  const {
    currentUser,
    isRetriving: isRetrivingUser,
    refreshCurrentUser,
  } = useContext(UserViewModelContext);

  const isShowBackButton = React.useMemo(() => {
    if (!currentUser) return false;
    if (location.pathname != null) {
      const matches = matchRoutes(
        Object.values(profileRoutes).map((r) => ({ path: r })),
        location
      );
      if (
        matches != null &&
        matches.length > 0 &&
        matches[0].route.path !== profileRoutes.index &&
        matches[0].route.path !== profileRoutes.mySportPass
      ) {
        return true;
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const back = useCallback(() => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(`${i18n.language}/${ROUTE_BASE.PROFILE}`);
    }
  }, [navigate]);

  useEffect(() => {
    /*  if (isRetrivingUser === false && currentUser == null) {
      navigate(ROUTE_BASE.AUTH + "/sign-in", { replace: true });
    } */
    if (!_.isEmpty(currentUser)) refreshCurrentUser();
  }, []);

  return location?.pathname?.includes("verification") ? (
    <Outlet />
  ) : (
    <main>
      <ProfileLayout>
        <div className="w-full flex flex-col">
          {isShowBackButton ? (
            <div className={cn("w-full", "hidden", "mb-[25px]", "lg:block")}>
              <PageBackButton
                onClick={back}
                text={t("monthlyProgram:monthlyProgramDetail.btnBack")}
              />
            </div>
          ) : null}
          <Outlet />
        </div>
      </ProfileLayout>
    </main>
  );
};

export default ProfileScreen;
