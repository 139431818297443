import { use } from "i18next";
import { useCommonFetcher } from "./Common/CommonViewModel";
import {
    getLatestNews as getLatestNewsAPI, getLatestNewsById,
} from "../network/home";
import { useState } from "react";
import { ILatestNew } from "common/models/LatestNew";
import { LatestNew as LatestNewModel } from "common/models/LatestNew";

export const LatestNewsViewModel = () => {
    const { fetcher } = useCommonFetcher();
    const [latestNews, setLatestNews] = useState<ILatestNew[]>([]);
    const [latestNewsDetail, setLatestNewsDetail] = useState<ILatestNew>();
    const [latestNewsCount, setLatestNewsCount] = useState<number | null>(null); // web need this for none item check

    const getLatestNews = async (limit?: number, skip = 0, skipLoading = true) => {
        const res = await fetcher({api: () => getLatestNewsAPI({limit, skip}), skipLoading, skipErrorHandle: true});
        if(res?.success) {
            const data = res?.result?.map((news: ILatestNew) => new LatestNewModel(news));
            setLatestNews(data);
            setLatestNewsCount(res?.total);
        }
        return res;
    };
    
    const getFilteredLatestNews = async (keywords: string, limit?: number, skip = 0) => {
        const res = await fetcher({api: () => getLatestNewsAPI({ title: keywords, limit, skip }), skipErrorHandle: true});
        if(res?.success) {
            const data = res?.result?.map((news: ILatestNew) => new LatestNewModel(news));
            setLatestNews(data);
            setLatestNewsCount(res?.total);
        }
        return res;
    };

    const getLatestNewsDetail = async (id: string) => {
        const res = await fetcher({api: () => getLatestNewsById(id)});
        if(res?.success) {      
            const data = new LatestNewModel(res?.result);    
            setLatestNewsDetail(data);
        }
        return res;
    }

    return {
        latestNews,
        latestNewsCount,
        latestNewsDetail,
        getLatestNews,
        getFilteredLatestNews,
        getLatestNewsDetail,
    }
}