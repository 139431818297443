import { ROUTE_BASE } from "Route";
import { IUserViewModelContext } from "../@types/common";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCorrectUrl } from "navigators/Router";

const useAuthUser = (isNavigate?: boolean) => {
  const { currentUser, isRetriving } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const navigate = useNavigate();
  console.log("🚀 ~ currentUser, isRetriving:", currentUser, isRetriving);
  const isGuest = !currentUser && !isRetriving;

  useEffect(() => {
    // guest condition
    if (isNavigate && !currentUser && !isRetriving) {
      navigate(getCorrectUrl(ROUTE_BASE.AUTH + "/sign-in"));
    }
  }, [currentUser, isRetriving]);

  return {
    isRetriving,
    isGuest,
  };
};

export default useAuthUser;
