import Space from "components/common/Space";
import CommonTextfield from "components/common/Textfield";
import Typography from "components/common/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface KeyMemberProps {
  memberNum: number;
  value: string;
  onChange: (text: string) => void;
  error?: string;
}

const KeyMemberInput = ({
  memberNum,
  value,
  onChange,
  error,
}: KeyMemberProps) => {
  const { t } = useTranslation("booking");

  return (
    <div className="my-[10px]">
      <Typography>
        {t(`bookingDetail.keyMember${Number(memberNum) + 1}`)}
      </Typography>
      <Space size="10px" />
      <CommonTextfield
        style={{ width: 300 }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        helperText={error}
        error={error !== ""}
      />
    </div>
  );
};

export default KeyMemberInput;
