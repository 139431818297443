import { authedInstance, instance } from ".";

export async function getSportpass() {
  const res = await authedInstance.get("v1/app/sportpass");
  console.log("Get sportpass res: ", res.data, authedInstance.getUri());
  return res.data;
}

export async function getSportpassHistory() {
  const res = await authedInstance.get("v1/app/sportpass/record");
  console.log("Get sportpass history res: ", res.data, authedInstance.getUri());
  return res.data;
}

export async function buySportpass(payload) {
  const res = await authedInstance.post("v1/app/sportpass", payload);
  console.log("Buy sportpass res: ", res.data, authedInstance.getUri());
  return res.data;
}