import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import theme from "utils/theme";

const Wapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  border-radius: 30px;
  margin: 0px auto;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  z-index: 2;
  overflow: hidden;
  @media ${breakpoint.mobile} {
    padding: 0px;
  }
`;

const RowDetail = styled.div<{ align?: string }>`
  display: flex;
  margin: 10px 0px;
  width: 100%;
  padding: 20px 0px;
  justify-content: ${(p) => (p.align ? p.align : "flex-start")};

  gap: 20px;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    align-items: center;
    &.address {
      flex-direction: row;
    }
  }
`;

const DetailAva = styled.div`
  height: 150px;
  width: 150px;
`;

const DetailDes = styled.div`
  width: calc(65% - 175px);
  @media ${breakpoint.mobile} {
    width: 100%;
    &.address {
      width: calc(100% - 70px);
    }
  }
`;

const DetailBoxContent = styled.div`
  width: 65%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

const BoxContentHeader = styled.div`
  width: 100%;
  background-color: ${theme.cyan};
  height: 50px;
  padding-left: 25px;
  font-weight: 500;
  line-height: 50px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const List = styled.div`
  display: flex;
  background-color: #fff;
  color: #4a4a4a;
  @media ${breakpoint.mobile} {
    flex-direction: column;
  }
`;

const Left = styled.div`
  width: 25%;
  padding-left: 30px;
  height: 36px;
  line-height: 36px;
  font-weight: 500;
  @media ${breakpoint.mobile} {
    width: 100%;
    border-bottom: 1px solid #d8d8d8;
  }
`;

const Right = styled.div`
  width: 75%;
  padding-left: 30px;
  border-width: 0px 0px 1px 1px;
  border-color: #d8d8d8;
  height: 36px;
  line-height: 36px;
  border-style: solid;
  &.no-border {
    border-width: 0px 0px 0px 1px;
  }
  @media ${breakpoint.mobile} {
    width: 100%;
    border-width: 0px 0px 1px 0px;
    padding-left: 60px;
    &.no-border {
      border-width: 0px 0px 0px 0px;
    }
  }
`;

const Bottom = styled.div`
  width: 100%;
  padding-left: 30px;
  height: 50px;
  line-height: 50px;
  color: #8c8c8c;
  border-top: 1px solid #d8d8d8;
`;

export default {
  Wapper,
  RowDetail,
  DetailAva,
  DetailDes,
  DetailBoxContent,
  BoxContentHeader,
  List,
  Left,
  Right,
  Bottom,
};
