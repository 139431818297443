import { authedInstance, instance } from ".";

export async function getEventList(payload) {
  let res;
  if (payload)
    res = await instance.get(
      "/v1/app/event?" + new URLSearchParams(payload).toString()
    );
  else res = await instance.get("/v1/app/event");
  console.log("getEventList res: ", res.data, instance.getUri());
  return res.data;
}

export async function getEventDetail(id) {
  const res = await instance.get(`/v1/app/event/${id}`);
  console.log("getEventDetail res: ", res.data, instance.getUri());
  return res.data;
}

export async function listPastEventBookings() {
  return listEventBookings(true);
}

export async function listEventBookings(past = false) {
  const res = await authedInstance.get(`/v1/app/event-booking?expired=${past}`);
  console.log("listEventBookings res: ", res.data, authedInstance.getUri());
  return res.data;
}

export async function getEventType() {
  const res = await instance.get(`/v1/app/event-type/`);
  console.log("getEventType res: ", res.data, instance.getUri());
  return res.data;
}

export async function createEventBooking(payload) {
  console.log("Create Booking: ", payload);
  const res = await authedInstance.post(
    "v1/app/event-booking?createPayment=true",
    payload
  );
  console.log("Create Booking: ", res.data);
  return res.data;
}

export async function createEventPayment(order_id) {
  const res = await authedInstance.post(
    `/v1/app/event-booking/${order_id}/payment`
  );
  return res.data;
}

export const getPendingPaymentStatus = async (id) => {
  const res = await authedInstance.get(
    `/v1/app/event-booking/eventBookingStatus/${id}`
  );
  return res.data;
};
