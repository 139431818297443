import Typography from "components/common/Typography";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";
import Styled from "./Styled";

interface GuardianLabelProps {
  disabled: boolean;
}

const GuardianLabel = ({ disabled }: GuardianLabelProps) => {
  const { t } = useTranslation("profile");
  return disabled ? (
    <div />
  ) : (
    <Styled.GuardianLabel>
      <Typography color={theme.white} fontSize="12px">
        {t("personalInfo.guardian")}
      </Typography>
    </Styled.GuardianLabel>
  );
};

export default GuardianLabel;
