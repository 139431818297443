import cn from "classnames";
import BookingStep from "pageComponents/booking/BookingStep";
import Styled from "./Styled";
import IconLoader from "assets/IconLoader";
import Typography from "components/common/Typography";
import { useTranslation } from "react-i18next";
import GeneralButton from "components/common/Button";
import theme from "utils/theme";
import Space from "components/common/Space";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import { IPaymentContext } from "../../@types/common";
import { useEffect, useState } from "react";
import _ from "lodash";
import scopeLargePng from "../../assets/bg-payment-slope-large.png";
import scopeSmallPng from "../../assets/bg-payment-slope-small.png";

const PaymentFail = () => {
  const { t } = useTranslation("booking");
  const { failReason, refNo } = useOutletContext() as IPaymentContext;
  const navigate = useNavigate();

  useEffect(() => {
    if (_.isEmpty(refNo)) navigate(`/${ROUTE_BASE.MY_BOOKINGS}`);
  }, []);

  //   TODO - retry api
  return (
    <div className="mx-[20px] md:mx-0">
      <BookingStep type="fail" />
      <Styled.ResultContainer className="relative">
        <IconLoader.IconFail />
        <Space width="80px" />
        <div className="px-[20px]">
          <Typography color={theme.black} fontSize="40px" fontWeight="600">
            {t("bookingDetail.retry")}
          </Typography>
          <Space size="30px" />
          <Typography fontWeight="600">{t(failReason)}</Typography>
          {!_.isEmpty(refNo) && refNo !== "TIMEOUT" && (
            <Typography>{t("bookingDetail.RefNo", { no: refNo })}</Typography>
          )}
          <Space size="40px" />
          <div className="w-full md:max-w-[211px]">
            <GeneralButton
              onClick={() => navigate(ROUTE_BASE.MY_BOOKINGS)}
              fullWidth
              style={{ zIndex: 100 }}
            >
              {t("bookingDetail.viewMyBooking")}
            </GeneralButton>
          </div>
        </div>
        <img
          src={scopeLargePng}
          className={cn(
            "hidden md:block",
            "absolute bottom-0 right-0",
            "w-[80vw] h-[230px]"
          )}
        />
        <img
          src={scopeSmallPng}
          className={cn(
            "md:hidden",
            "absolute bottom-0 right-0",
            "w-full h-[300px]"
          )}
        />
      </Styled.ResultContainer>
    </div>
  );
};

export default PaymentFail;
