import styled from "styled-components";
import theme from "utils/theme";
import ReactPaginate from "react-paginate";
import { breakpoint } from "utils/responsive";

const Paginate = styled(ReactPaginate)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  li a {
    display: inline-block;
    position: relative;
    height: 40px;
    width: 40px;
  }

  li {
    text-align: center;
    height: 40px;
    width: 40px;
    line-height: 38px;
    border: 1px solid #000;
    margin: 0px 5px;
    border-radius: 10px;
    cursor: pointer;

    &.active,
    &:hover {
      background: ${theme.orange};
      border-color: ${theme.orange};
      color: #fff;
    }

    &.hide {
      display: none;
    }
  }
`;

export default {
  Paginate,
};
