import dayjs from "../../utils/dayjs";
import { useState, useEffect, useMemo } from "react";
import i18n from "i18n";
import { formatDateToString, getDayOfWeekSlug } from "../../utils/DateHelper";
import {
  getLocalisedString,
  validateEmailFormat,
} from "../../utils/stringHelper";
import _ from "lodash";
import { VoucherType } from "../VoucherViewModel";
import { usePromoCodeViewModel } from "../Payment/PromoCodeViewModel";
import { isEmptyValues } from "../../utils/common";
import { PromoCodeBookingType } from "../../models/PromoCode";

export const useCoachLessonBookingDetailViewModel = ({
  currentUser,
  coachLesson,
  selectedDate,
  selectedTimeslots,
  selectedLessonType,
  pricePerLesson,
  bookingIds,
  dispatch,
}) => {
  const { checkPromoCodeAvailability, estimatePromoCodeDiscount } =
    usePromoCodeViewModel();
  const [promoCode, setPromoCode] = useState(null);
  const [appliedVouchers, setVouchers] = useState([]);
  const [guests, setGuests] = useState([]);
  const timeslotCount = Array.from(selectedTimeslots?.values()).length;
  const [allowCash, setAllowCash] = useState(false);
  const [allowVoucher, setAllowVoucher] = useState(false);

  const subtotalPrice = useMemo(() => {
    return pricePerLesson * Array.from(selectedTimeslots?.values()).length;
  }, [pricePerLesson, selectedTimeslots]);

  const subtotalPriceWithVoucher = useMemo(() => {
    return (
      subtotalPrice -
      appliedVouchers.reduce((acc, voucher) => acc + voucher?.voucher?.value, 0)
    );
  }, [subtotalPrice, appliedVouchers]);

  useEffect(() => {
    updateVoucherValue([]);
  }, [selectedTimeslots]);

  useEffect(() => {
    updateEstimatedPromoCodePrice();
  }, [subtotalPriceWithVoucher]);

  useEffect(() => {
    setAllowCash(getTotalPrice() > 0);
    setAllowVoucher(
      appliedVouchers.filter((el) => el.voucher.type == VoucherType.COACH)
        .length < timeslotCount
    );
  }, [appliedVouchers]);

  useEffect(() => {
    console.log("allow cash ", allowCash);
  }, [allowCash]);

  const getGuestLimit = () => {
    switch (selectedLessonType) {
      case "1to3":
        return 2;
      case "1to2":
        return 1;
      default:
        return 0;
    }
  };

  const getBookingDetails = () => {
    return (
      Array.from(selectedTimeslots?.values()).map((timeslot) => {
        return {
          info: `${formatDateToString(
            dayjs.tz(selectedDate),
            getLocalisedString("YYYY-MM-DD (ddd)", "YYYY-MM-DD (dddd)"),
            getLocalisedString("en", "zh-hk")
          )}\n${timeslot.getDisplayTimeslot()}`,
          price: 100,
        };
      }) ?? []
    );
  };

  const applyPromoCode = async (code) => {
    const res = await checkPromoCodeAvailability({
      code,
      bookingType: PromoCodeBookingType.COACH,
      orderAmount: subtotalPriceWithVoucher * 100,
    });

    if (res?.success && !isEmptyValues(res?.promoCode)) {
      setPromoCode(res?.promoCode);
    } else {
      setPromoCode(null);
    }
  };

  const updateEstimatedPromoCodePrice = async () => {
    if (!isEmptyValues(promoCode)) {
      const res = await estimatePromoCodeDiscount({
        promoCode: promoCode,
        orderAmount: subtotalPriceWithVoucher * 100,
      });

      if (res?.success && !isEmptyValues(res?.promoCode)) {
        console.log("===res", res);
        setPromoCode(res?.promoCode);
      } else {
        setPromoCode(null);
      }
    }
  };

  const updateVoucherValue = (originalVouchers) => {
    // let vouchers = originalVouchers
    // if (vouchers.filter(el=>el.voucher.type == VoucherType.COACH).length >= timeslotCount) {  //all timeslot applied with coach voucher
    //   vouchers = vouchers.filter(el=>el.voucher.type != VoucherType.CASH)
    // }
    // setVouchers(vouchers);
    setVouchers(originalVouchers);
  };

  const applyVoucher = async (voucher) => {
    if (appliedVouchers.some((el) => el?.voucherId == voucher?.voucherId))
      return;
    let appliedVoucher = voucher;
    if (
      !appliedVoucher?.voucher?.value &&
      appliedVoucher?.voucher?.type != VoucherType.CASH
    ) {
      appliedVoucher.voucher.value = pricePerLesson;
    }
    updateVoucherValue([...appliedVouchers, appliedVoucher]);
  };

  const removeVoucher = (vouchers) => {
    if (vouchers) {
      if (Array.isArray(vouchers)) {
        updateVoucherValue(
          appliedVouchers.filter(
            (el) =>
              !vouchers.some((voucher) => voucher?.voucherId == el?.voucherId)
          )
        );
      } else {
        updateVoucherValue(
          appliedVouchers.filter((el) => vouchers?.voucherId != el?.voucherId)
        );
      }
    } else {
      updateVoucherValue([]);
    }
  };

  const removePromoCode = () => {
    setPromoCode(null);
  };

  const getSubtotalPrice = () => {
    return Math.max(0, subtotalPrice);
  };

  const getTotalPrice = () => {
    let total = promoCode
      ? subtotalPriceWithVoucher - (promoCode?.enjoyedDiscount ?? 0)
      : subtotalPriceWithVoucher;
    return Math.max(0, total);
  };

  const addGuests = async (csvInput) => {
    try {
      let guestsInput = csvInput?.split(",");
      let result = {};
      if (guestsInput?.some((email) => !validateEmailFormat(email))) {
        throw new Error(i18n.getFixedT()("myBooking:detail.invalidEmailError"));
      }
      /// For upserting facility booking guest list
      let tmpGuestList = _.cloneDeep(guests)?.map((guest) => {
        return { email: guest.email };
      });
      console.log(tmpGuestList);
      guestsInput?.forEach((guestEmail) => {
        if (checkIsBookingOwner(guestEmail)) {
          throw new Error(
            i18n.getFixedT()("myBooking:detail.inviteBookingOwnerError", {
              mail: guestEmail,
            })
          );
        }
        let index = tmpGuestList?.findIndex(
          (guest) =>
            String(guest.email).toLowerCase() ===
            String(guestEmail).toLowerCase()
        );
        if (index > -1) {
          // Existing
          throw new Error(
            i18n.getFixedT()("myBooking:detail.duplicatedEmailError", {
              mail: guestEmail,
            })
          );
        } else {
          // Non existing
          tmpGuestList?.push({
            email: guestEmail,
          });
        }
      });

      console.log(tmpGuestList, getGuestLimit());
      if (tmpGuestList.length > getGuestLimit()) {
        throw new Error(
          i18n.getFixedT()("myBooking:detail.guestLimitExceededError", {
            guestLimit: getGuestLimit(),
          })
        );
      }
      console.log(tmpGuestList);
      setGuests(tmpGuestList);
      return {
        success: true,
      };
    } catch (err) {
      // error handle in add guest modal
      return err.message;
    }
  };

  const removeGuest = async (guestToRemove) => {
    console.log("Remove Guest: ", guestToRemove);
    let tmpGuestList = _.cloneDeep(guests)?.map((guest) => {
      return { email: guest.email, key_member: guest.key_member };
    });
    let index = tmpGuestList?.findIndex(
      (guest) => guest.email === guestToRemove?.email
    );
    // Remove guest
    tmpGuestList.splice(index, 1);

    setGuests(tmpGuestList);
    console.log("Remove Guest: ", tmpGuestList, index);
  };

  const checkIsBookingOwner = (email) => {
    return (
      String(email).toLowerCase() == String(currentUser?.email).toLowerCase()
    );
  };

  const getBookingSummary = () => {
    var bookingSummary = {
      lessonName: coachLesson?.getName(),
      coachName: coachLesson?.coach?.getName(),
      lessonType: selectedLessonType,
      items: getBookingDetails(),
      pricePerLesson: pricePerLesson,
      totalPrice: getTotalPrice(),
    };

    // TODO: Promo Code Implementation
    if (promoCode) {
      bookingSummary.promoCode = promoCode;
    }

    // TODO: Voucher implementation
    if (appliedVouchers.length > 0) {
      bookingSummary.vouchers = appliedVouchers;
    }

    return {
      bookingSummary,
      payload: getConfirmBookingPayload(),
    };
  };

  const getConfirmBookingPayload = () => {
    var payload = {
      booking_id: bookingIds,
      lessonType: selectedLessonType,
      lesson_id: coachLesson?._id,
      guestlist: guests ?? [],
      remarks: "",
      // totalAmount: getTotalPrice(),
    };

    if (promoCode) {
      payload.promocode = promoCode.code;
      payload.promoCodeTicketId = promoCode.promoCodeTicketId;
    }

    // TODO: Voucher implementation
    if (appliedVouchers.length > 0) {
      payload.voucher = appliedVouchers.map((el) => el.voucherId);
    }

    return payload;
  };

  return {
    promoCode,
    appliedVouchers,
    guests,
    allowCash,
    allowVoucher,
    getGuestLimit,
    getBookingDetails,
    getSubtotalPrice,
    getTotalPrice,
    applyPromoCode,
    removePromoCode,
    addGuests,
    removeGuest,
    applyVoucher,
    removeVoucher,
    getBookingSummary,
  };
};
