import React from "react";
import { useTranslation } from "react-i18next";
import Styled from "./Styled";
import cn from "classnames";

type Props = {
  totalAmount: string;
};

const BookingDetailSummaryCard = (props: Props) => {
  const { totalAmount } = props;
  const { t } = useTranslation("myBooking");

  return (
    <Styled.SharedCard
      className={cn(
        "w-full px-[22px] pt-[21px] pb-[25px]",
        "lg:px-[37px]",
        "lg:py-[15px]"
      )}
    >
      <Styled.TotalAmountText>{`${t(
        "detail.totalAmount"
      )}\tHK$ ${totalAmount}`}</Styled.TotalAmountText>
    </Styled.SharedCard>
  );
};

export default BookingDetailSummaryCard;
