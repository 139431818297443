import styled from "styled-components";

const Card = styled.div`
  position: relative;
  width: 100dvw;
  max-width: 632px;
  height: 100dvh;
  max-height: 507px;
  background: var(--palette-ss-white, #fff);
  border-radius: 30px;
`;

const Title = styled.span`
  color: var(--palette-ss-000, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.491px; /* 88.305% */
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 234px;
  padding: 22px 25px;
  border-radius: 5px;
  background: #f7f7f7;

  &::placeholder {
    color: #c1c1c1;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }
`;

export default {
  Card,
  Title,
  TextArea,
};
