import styled from "styled-components";
import { size } from "../../utils/responsive";

const PageBackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  min-height: calc(100vh - 120px - 163px);
  padding: 20px 20px 100px;
  @media (min-width: ${size.screen1024}px) {
    padding: 50px 50px 100px;
  }
`;

const PageContainer = styled.div`
  /** Ref: https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container */
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 20px;
  padding: 22px 26px 24px;
`;

export default {
  PageBackgroundContainer,
  PageContainer,
};
