import Card from "components/common/Card";
import HeaderTitle from "components/common/HeaderTitle";
import GuardianListCard from "pageComponents/profile/GuardianListCard";
import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useTranslation, Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import theme from "utils/theme";
import AddGuardianModal from "components/modals/AddGuardianModal/AddGuardianModal";
import { useModal } from "hooks/useModal";
import { IGlobalProviderContext } from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { useProfileInfoViewModel } from "common/viewModels/myProfile/UpdateProfileInfoViewModel";
import { confirmModalContext } from "contexts/ConfirmModalProvider";
import { isEmail } from "utils/validator";
import { ROUTE_BASE } from "Route";

const AddGuardianButton = styled.button`
  color: ${theme.orange};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
`;

const GuardianList = () => {
  const { t, i18n } = useTranslation("profile");
  const location = useLocation();
  const navigate = useNavigate();
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { openModal: openConformModal } = useContext(confirmModalContext);
  const { editProfileInfo, deleteGuardian } = useProfileInfoViewModel();
  const { enqueueSnackbar } = useSnackbar();

  const createGuardian = useCallback(
    async (email: string) => {
      if ((await isEmail(email)) === false) {
        return {
          success: false,
          error: { message: `Invalid email: '${email} is not an email format` },
        };
      }
      const payload = { guardian: { email } };
      return editProfileInfo(payload, globalDispatch)
        .then((res) => {
          if (res) {
            openConformModal({ title: t("personalInfo.guardianSuccessMsg") });
            navigate(`/${i18n.language}/${ROUTE_BASE.PROFILE}/edit`);
            return { success: true } as const;
          }
          return {
            success: false,
            error: { message: "" },
          };
        })
        .catch((e) => {
          return {
            success: false,
            error: { message: "" },
          };
        });
    },
    [t, editProfileInfo, globalDispatch, openConformModal]
  );

  const cancelGuardian = useCallback((email: string) => {
    deleteGuardian(globalDispatch);
    navigate(`/${i18n.language}/${ROUTE_BASE.PROFILE}/edit`);
  }, []);

  return (
    <Card bgColor={theme.lightCyan} style={{ padding: 35, width: "100%" }}>
      <HeaderTitle title={t("personalInfo.guardian")} />
      <div className="h-[30px]" />
      {!isEmpty(location?.state?.guardians) ? (
        location?.state?.guardians.map((guardian: any) => (
          <GuardianListCard
            key={guardian?.email}
            email={guardian?.email}
            name_en={guardian?.name_en}
            name_zh={guardian?.name_zh}
            status={guardian?.status}
            onCancel={cancelGuardian}
          />
        ))
      ) : (
        <React.Fragment>
          <AddGuardianButton type="button" onClick={handleModalOpen}>
            {t("personalInfo.applyGuardian")}
          </AddGuardianButton>
          <AddGuardianModal
            open={modalOpen}
            onClose={handleModalClose}
            onAddGuardian={createGuardian}
          />
        </React.Fragment>
      )}
    </Card>
  );
};

export default GuardianList;
