import { useContext, useState } from "react";
import {
  listGeneralNotifications,
  listMessageNotifications,
} from "../../network/notification";
import { GlobalContextStore } from "../../context/providers/globalProvider";
import {
  appearGlobalLoading,
  disappearGlobalLoading,
} from "../../context/requests/globalRequest";
import _ from "lodash";
import { useCommonFetcher } from "../Common/CommonViewModel";

export const useNotificationListViewModel = () => {
  const { globalDispatch } = useContext(GlobalContextStore);
  const { fetcher } = useCommonFetcher();
  const notificationListLimit = 10;
  const [currentGeneralNotificationPage, setCurrentGeneralNotificationPage] =
    useState(0);
  const [generalNotificationTotal, setGeneralNotificationTotal] = useState(0);
  const [generalNotificationList, setGeneralNotificationList] = useState([]);

  const [currentMessagelNotificationPage, setCurrentMessageNotificationPage] =
    useState(0);
  const [messageNotificationTotal, setMessageNotificationTotal] = useState(0);
  const [messageNotificationList, setMessageNotificationList] = useState([]);

  const generalNotificationListHasNextPage =
    currentGeneralNotificationPage * notificationListLimit <
    generalNotificationTotal;

  const messageNotificationListHasNextPage =
    currentMessagelNotificationPage * notificationListLimit <
    messageNotificationTotal;

  const getGeneralNotificationList = async ({
    isRefresh = false, // Pull to refresh, reset page num to 0
    nextPage = false, // Load next page
  }) => {
    let pageNum = currentGeneralNotificationPage;
    if (nextPage) pageNum += 1;
    if (isRefresh) pageNum = 0;

    const res = await fetcher({api: () => listGeneralNotifications({
      skip: pageNum * notificationListLimit,
      limit: notificationListLimit,
    })})

    if(res?.success){
      const { total, notifications } = res;

      // Handle Notification List
      let newNotificationList = [];
      if (nextPage) {
        newNotificationList = _.uniqBy(
          [...generalNotificationList, ...notifications],
          "_id"
        );
      } else {
        newNotificationList = notifications;
      }

      // Update state
      setGeneralNotificationList(newNotificationList);
      setGeneralNotificationTotal(total);
      setCurrentGeneralNotificationPage(pageNum);
    }

    return res;
  };

  const getMessageNotificationList = async ({
    isRefresh = false, // Pull to refresh, reset page num to 0
    nextPage = false, // Load next page
  }) => {
    let pageNum = currentMessagelNotificationPage;
    if (nextPage) pageNum += 1;
    if (isRefresh) pageNum = 0;

    const res = await fetcher({api: () => listMessageNotifications({
      skip: pageNum * notificationListLimit,
      limit: notificationListLimit,
    })})

    if(res?.success){
      const { total, notifications } = res;
      // Handle notification list
      let newNotificationList = [];
      if (nextPage) {
        newNotificationList = _.uniqBy(
          [...messageNotificationList, ...notifications],
          "_id"
          );
        } else {
          newNotificationList = notifications;
        }
        
        // Update state
        setMessageNotificationList(newNotificationList);
        setMessageNotificationTotal(total);
        setCurrentMessageNotificationPage(pageNum);
      };
    }
      
    return {
      generalNotificationList,
      messageNotificationList,
      generalNotificationListHasNextPage,
      messageNotificationListHasNextPage,
      getGeneralNotificationList,
      getMessageNotificationList,
    };
  };
