import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getTimeDiffFrom } from "../utils/DateHelper";
import CryptoES from "crypto-es";
import {
  getQRCodeExpiryConfig,
  getQRCodeKey,
  getQrCodeFromApi,
} from "../network/qrcode";
import {
  appearGlobalError,
  appearGlobalLoading,
  disappearGlobalLoading,
} from "../context/requests/globalRequest";
import dayjs from "../utils/dayjs";
import { useCommonFetcher } from "./Common/CommonViewModel";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { getFacilityById } from "common/network/facility";
import { getLocalisedString } from "common/utils/stringHelper";
import { useTranslation } from "react-i18next";
import _, { set } from "lodash";
import { isEmptyValues } from "../utils/common";

const MAX_RETRY_COUNT = 3;
const RETRY_INTERVAL = 5000;

export const useMyPassViewModel = () => {
  const { globalDispatch } = useContext(GlobalContextStore);
  const { t } = useTranslation("common");
  const { fetcher } = useCommonFetcher();
  const [qrCode, setQrCode] = useState(null);
  const [expiredTimeInSecond, setExpiredTimeInSecond] = useState();
  const [retryCounter, setRetryCounter] = useState(MAX_RETRY_COUNT);
  const [qrCodeResponse, setQrCodeResponse] = useState(null);
  const [nextQRCodeResponse, setNextQRCodeResponse] = useState(null);

  const expiredTimeRef = useRef(expiredTimeInSecond);
  const countDownTimeoutRef = useRef(null);
  const nextInitQrCodeRequestTimeout = useRef(null);
  // const qrCodeExpiryTimeout = useRef(null);
  const nextQRCodeRef = useRef(nextQRCodeResponse);
  const qrCodeDelayTimeoutRef = useRef(null);

  const isCurrentKeyValid =
    expiredTimeRef.current && expiredTimeRef.current > 0;

  // useEffect(() => {
  //   let timerInterval;
  //   if (qrCodeResponse?.validUntil) {
  //     timerInterval = setInterval(() => {
  //       setExpiredTimeInSecond(
  //         Math.max(
  //           Math.floor(qrCodeResponse?.validUntil / 1000) - dayjs().unix(),
  //           0
  //         )
  //       );
  //     }, 1000);
  //   }
  //   return () => {
  //     return clearInterval(timerInterval);
  //   };
  // }, [qrCodeResponse?.validUntil]);

  // useEffect(() => {
  //   if (qrCodeResponse?.validUntil) {
  //     const deltaToQRCodeExpiry =
  //       qrCodeResponse?.validUntil - dayjs().valueOf();
  //     if (!!qrCodeExpiryTimeout.current)
  //       clearTimeout(qrCodeExpiryTimeout.current);

  //     qrCodeExpiryTimeout.current = setTimeout(() => {
  //       rotateQRCode();
  //     }, deltaToQRCodeExpiry);
  //   }

  //   return () => {
  //     clearTimeout(qrCodeExpiryTimeout.current);
  //   };
  // }, [qrCodeResponse?.validUntil, nextQRCodeResponse]);

  // useEffect(() => {
  //   if (qrCodeResponse?.suggestRefreshAt) {
  //     const deltaToNextRequest =
  //       qrCodeResponse?.suggestRefreshAt - dayjs().valueOf();
  //     if (!!nextInitQrCodeRequestTimeout.current)
  //       clearTimeout(nextInitQrCodeRequestTimeout.current);
  //     nextInitQrCodeRequestTimeout.current = setTimeout(() => {
  //       requestDoorAccessQRCodeWithPreGen(false);
  //     }, deltaToNextRequest);
  //   }

  //   return () => {
  //     clearTimeout(nextInitQrCodeRequestTimeout.current);
  //   };
  // }, [qrCodeResponse?.suggestRefreshAt]);

  useEffect(() => {
    // update next qr code ref
    nextQRCodeRef.current = nextQRCodeResponse;
  }, [nextQRCodeResponse]);

  useEffect(() => {
    return () => {
      // Reset timer on destroy
      resetTimer();
    };
  }, []);

  useEffect(() => {
    expiredTimeRef.current = expiredTimeInSecond;
  }, [expiredTimeInSecond]);

  //retry logic for get qrcode generation key
  useEffect(() => {
    if (retryCounter < MAX_RETRY_COUNT) {
      setTimeout(() => {
        requestDoorAccessQRCodeWithPreGen(true, true);
      }, RETRY_INTERVAL);
    }
  }, [retryCounter]);

  const initCountdownTimer = (initialTimeout, refreshOpenIn) => {
    resetCountdownTimer();

    // console.log("Init QR Code timer: ", initialTimeout, refreshOpenIn);
    setExpiredTimeInSecond(initialTimeout);

    const timer = setInterval(() => {
      console.log("QR Code timer: ", expiredTimeRef.current);
      if (!isEmptyValues(expiredTimeRef.current)) {
        if (expiredTimeRef.current - 1 < 0) {
          // QRCode expired
          // setExpiredTimeInSecond(expiredTimeRef.current - 1);
          resetCountdownTimer();
          rotateQRCode();
        } else {
          setExpiredTimeInSecond(Math.max(expiredTimeRef.current - 1, 0));
        }
      }
    }, 1000);

    countDownTimeoutRef.current = timer;

    if (refreshOpenIn) {
      const deltaToNextRequest = refreshOpenIn * 1000;
      if (!!nextInitQrCodeRequestTimeout.current)
        clearTimeout(nextInitQrCodeRequestTimeout.current);
      nextInitQrCodeRequestTimeout.current = setTimeout(() => {
        requestDoorAccessQRCodeWithPreGen(false);
      }, deltaToNextRequest);
    }
  };

  const clearCurrentQRCode = () => {
    setQrCodeResponse(null);
    setNextQRCodeResponse(null);
    setExpiredTimeInSecond();
  };

  const resetTimer = () => {
    // Call on screen unfocused
    // Release timers
    resetCountdownTimer();
    resetNextQrCodeRequestTimer();
    resetQRCodeDeplayTimer();
    // clearTimeout(qrCodeExpiryTimeout.current);
    // qrCodeExpiryTimeout.current = null
  };

  const resetCountdownTimer = () => {
    console.log('check resetCountdownTimer ',countDownTimeoutRef.current)
    if (!isEmptyValues(countDownTimeoutRef.current)) {
      // console.log("Clear qr code expiry timer");
      clearInterval(countDownTimeoutRef.current);
      countDownTimeoutRef.current = null;
    }
  };

  const resetNextQrCodeRequestTimer = () => {
    if (!isEmptyValues(nextInitQrCodeRequestTimeout.current)) {
      clearTimeout(nextInitQrCodeRequestTimeout.current);
      nextInitQrCodeRequestTimeout.current = null;
    }
  };

  const resetQRCodeDeplayTimer = () => {
    if (!isEmptyValues(qrCodeDelayTimeoutRef.current)) {
      clearTimeout(qrCodeDelayTimeoutRef.current);
      qrCodeDelayTimeoutRef.current = null;
    }
  };

  const generatePayload = ({ qrCodeType, cardNumber, timeFrom, timeTo }) => {
    const payload_QRCodeType = qrCodeType
      .charCodeAt(0)
      .toString(16)
      .padStart(2, "0");
    // const payload_cardNumber = cardNumber.padStart(14, "0");
    const payload_startTime = getTimeDiffFrom(timeFrom, "2000-01-01 00:00:00")
      .toString(16)
      .padStart(8, "0");
    const payload_endTime = getTimeDiffFrom(timeTo, "2000-01-01 00:00:00")
      .toString(16)
      .padStart(8, "0");

    return `${payload_QRCodeType}${cardNumber}${payload_startTime}${payload_endTime}`;
    // return `${payload_QRCodeType}${payload_cardNumber}${payload_startTime}${payload_endTime}`;
  };

  const encryptData = ({ payload, key }) => {
    var encryptedPayload = CryptoES.AES.encrypt(
      CryptoES.enc.Hex.parse(payload),
      CryptoES.enc.Hex.parse(key),
      {
        mode: CryptoES.mode.ECB,
        padding: CryptoES.pad.ZeroPadding,
        format: {
          stringify: (cipherParams) => {
            return cipherParams.ciphertext.toString();
          },
          parse: (jsonStr) => {
            // extract ciphertext from json object, and create cipher params object
            var cipherParams = CryptoES.lib.CipherParams.create({
              ciphertext: CryptoES.enc.Base64.parse(jsonStr),
            });
            return cipherParams;
          },
        },
      }
    );

    return encryptedPayload.toString();
  };

  const requestDoorAccessQRCode = async ({ memberId }) => {
    const qrCodeExpiryConfigRes = await fetcher({
      api: getQRCodeExpiryConfig,
      skipErrorHandle: true,
    });
    const res = await fetcher({ api: getQRCodeKey });

    if (res?.success) {
      const { secret, createdAt, nextRotation } = res?.result;
      const expiryTimeInSecond = qrCodeExpiryConfigRes?.result ?? 300; // Default 300 seconds

      console.log("Member Id: ", memberId);
      var payload = generatePayload({
        qrCodeType: "D",
        // cardNumber: memberId.replace(/\D/g, ""),
        cardNumber: memberId,
        timeFrom: createdAt,
        timeTo: dayjs().add(expiryTimeInSecond, "second"),
      });

      var cipherText = encryptData({ payload, key: secret });
      setQrCode(cipherText.toString());
    } else {
      setQrCode(null);
    }
  };

  const requestDoorAccessQRCodeWithPreGen = async (
    isRetry = false,
    isForced = false
  ) => {
    if (isForced) {
      clearCurrentQRCode();
      appearGlobalLoading(globalDispatch);
    }
    const res = await fetcher({
      api: () => getQrCodeFromApi({ isForced: isForced }),
      skipErrorHandle: true,
      skipLoading: true,
    });

    if (res?.success) {
      if (isForced) {
        // Directly update current QR Code & expiry countdown
        // console.log("Init qr code", isCurrentKeyValid, isForced);
        resetQRCodeDeplayTimer();
        qrCodeDelayTimeoutRef.current = setTimeout(
          () => {
            updateQRCodeDisplay(res?.result);
            disappearGlobalLoading(globalDispatch);
          },
          (res?.result?.delay ?? 0) * 1000
        ); // Add loading delay for the first generated qr code
      } else {
        // Store qr code res for rotation
        // console.log("Store rotation qr code");
        setNextQRCodeResponse(res?.result);
      }
      // setExpiredTimeInSecond(expiredTime);
      setRetryCounter(MAX_RETRY_COUNT);
    } else {
      if (isRetry) {
        // because api timeout >> each refresh interval, ignore failed response from retry if some response is settled
        console.log("MyPassViewModel: isCurrentKeyValid: ", isCurrentKeyValid);
        if (isCurrentKeyValid) {
          setRetryCounter(MAX_RETRY_COUNT);
          return;
        }
        if (retryCounter + 1 === MAX_RETRY_COUNT) {
          const errorMsg = getLocalisedString(
            res?.error?.localizedMessage?.en,
            res?.error?.localizedMessage?.zh
          );
          appearGlobalError(
            globalDispatch,
            !_.isEmpty(errorMsg) ? errorMsg : t("error.internalServerError")
          );
          setRetryCounter(MAX_RETRY_COUNT);
          disappearGlobalLoading(globalDispatch);
        } else setRetryCounter(retryCounter + 1);
      } else {
        setRetryCounter(0);
      }
    }
  };

  const updateQRCodeDisplay = (qrCodeResult) => {
    // const expiryTime = Math.floor(
    //   (qrCodeResult?.validUntil - dayjs().valueOf()) / 1000
    // );
    const expiryTime = qrCodeResult?.expiresIn;
    setQrCodeResponse(qrCodeResult);
    initCountdownTimer(expiryTime, qrCodeResult?.refreshOpenIn);

    if (nextQRCodeRef?.current) setNextQRCodeResponse(null);
  };

  const rotateQRCode = () => {
    if (nextQRCodeRef?.current) {
      // Update qr code & expiry time display
      updateQRCodeDisplay(nextQRCodeRef?.current);
    } else {
      // Invalid nextQRCodeResponse, request new qr code instead
      requestDoorAccessQRCodeWithPreGen(false, true);
    }
  };

  return {
    qrCode: qrCodeResponse?.qrCode || null,
    expiredTimeInSecond,
    requestDoorAccessQRCode,
    requestDoorAccessQRCodeWithPreGen,
    resetTimer,
  };
};
