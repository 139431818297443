import React from "react";
import MuiCheckBox, {
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material/Checkbox";

// const Circle = function (props) {
//   const { checked, disabled, ...relevantProps } = props;
//   return (
//     <Checkbox
//       // style={{ paddingLeft: 0 }}
//       disabled={disabled}
//       checked={checked}
//       icon={<NoCheckedSvg />}
//       checkedIcon={disabled ? <CheckedDisabledSvg /> : <CircleCheckedSvg />}
//       {...relevantProps}
//     />
//   );
// };

const BoxSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H17C18.3807 0.5 19.5 1.61929 19.5 3V17C19.5 18.3807 18.3807 19.5 17 19.5H3C1.61929 19.5 0.5 18.3807 0.5 17V3Z"
      fill="white"
      stroke="black"
    />
  </svg>
);

const BoxCheckedSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H17C18.3807 0.5 19.5 1.61929 19.5 3V17C19.5 18.3807 18.3807 19.5 17 19.5H3C1.61929 19.5 0.5 18.3807 0.5 17V3Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M4 10.8L8 15L16 6"
      stroke="#5A5C4C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Box = function (props: MuiCheckboxProps) {
  return (
    <MuiCheckBox {...props} icon={<BoxSvg />} checkedIcon={<BoxCheckedSvg />} />
  );
};

type Props = MuiCheckboxProps & {
  type?: "box" | "circle";
};

const Checkbox = function ({ type = "box", ...otherProps }: Props) {
  switch (type) {
    // case "circle":
    //   return <Circle {...otherProps} />;
    default:
      return <Box {...otherProps} />;
  }
};

export default Checkbox;
