import React from "react";
import { useTranslation } from "react-i18next";
import placeholderPng from "../../assets/booking-detail-placeholder.png";
import Styled from "./Styled";
import cn from "classnames";
import { PromoCodeDiscountType } from "models/PromoCode";
import { getDayOfWeekSlug } from "common/utils/DateHelper";
import dayjs from "dayjs";
import GeneralButton from "components/common/Button";
import theme from "utils/theme";
import { ROUTE_BASE } from "Route";
import { getCorrectUrl } from "navigators/Router";
import { useNavigate } from "react-router-dom";
import IconQrcodeWhite from "assets/ic-qrCodeWhite.svg";
import Typography from "components/common/Typography";
import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import { ResponsiveUI } from "utils/responsive";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export type ReceiptVoucherProps = {
  voucherName: string;
  voucherPrice: string;
};

export type ReceiptPromoCodeProps = {
  code: string;
  discountType: PromoCodeDiscountType;
  discountAmount: number;
  discountPercentage?: number;
};

type Props = {
  thumbnail?: string;
  courseName: string;
  promoCode?: ReceiptPromoCodeProps;
  bookings: {
    id: string;
    bookingDate: string;
    bookingTime: string;
    bookingType?: string;
    price?: number;
  }[];
  vouchers: ReceiptVoucherProps[];
  bookingType?: string;
} & React.HTMLProps<HTMLElement>;

const BookingDetailReceipt = (props: Props) => {
  const {
    thumbnail,
    courseName,
    promoCode,
    bookings,
    vouchers,
    bookingType,
    children,
  } = props;

  const navigate = useNavigate();
  const { t } = useTranslation("myBooking");

  const _accessPassBtn = () => {
    return (
      bookingType === ProgramTypes.Event && (
        <div className="mr-[10px]">
          <Styled.AccessPassButton
            onClick={() => navigate(getCorrectUrl(ROUTE_BASE.MY_PASS))}
          >
            <img src={IconQrcodeWhite} />
            <Typography
              color={theme.white}
              fontSize="11px"
              mFontSize="8px"
              whiteSpace="nowrap"
              style={{ lineHeight: "0px", margin: "12px 0px 5px" }}
            >
              {t("detail.accessPass")}
            </Typography>
          </Styled.AccessPassButton>
        </div>
      )
    );
  };

  return (
    <Styled.SharedCard
      className={cn(
        "w-full px-[22px] py-[22px]",
        "lg:px-[37px]",
        "lg:py-[22px]"
      )}
    >
      <div
        className={cn(
          "w-full flex flex-col gap-[20px]",
          "lg:gap-0",
          "lg:flex-row",
          "lg:justify-between"
        )}
      >
        <div className="flex">
          <div className="w-full flex flex-col">
            <Styled.MainInfoTitle>{courseName}</Styled.MainInfoTitle>
            <div className="h-1.5" />
            <div className="flex flex-col gap-y-[20px]">
              {bookings.map((booking) => {
                const dayOfWeek = dayjs(booking.bookingDate, "DD/MM/YYYY")
                  .tz("Asia/Hong_Kong", true).format("ddd")
                  .toLowerCase();
                return (
                  <div key={booking.id} className="flex flex-col">
                    <Styled.MainInfoContent>
                      {`${t(`common:dateFormat.isoWeekday.${dayOfWeek}`)} `}
                      {booking.bookingDate}
                    </Styled.MainInfoContent>
                    <Styled.MainInfoContent>
                      {`${booking.bookingTime} `}
                      {booking.bookingType ? `${booking.bookingType}` : ""}
                    </Styled.MainInfoContent>
                    {booking.price && (
                      <Styled.MainInfoContent>{`HK$${booking.price}`}</Styled.MainInfoContent>
                    )}
                  </div>
                );
              })}
            </div>

            <Styled.MainInfoContent>
              <div className="h-2.5" />
              {children}
            </Styled.MainInfoContent>
          </div>

          <div className="block lg:hidden">{_accessPassBtn()}</div>
        </div>

        <div className="flex w-full justify-end">
          <div className="hidden lg:block">{_accessPassBtn()}</div>
          {thumbnail && (
            <Styled.BookingMainImage
              src={thumbnail}
              alt="booking-detail-image"
            />
          )}
        </div>
      </div>
      {(vouchers.length > 0 || !!promoCode) && (
        <>
          <div className="w-full h-[20px] border-b border-dashed border-[#D5D5D5]" />
          <div
            className={cn("w-full flex flex-col", "lg:flex-row", "lg:mt-[8px]")}
          >
            <div
              className={cn(
                "left w-full pt-[20px]",
                "lg:w-1/2",
                "lg:pt-[12px]"
              )}
            >
              <Styled.MainInfoTitle>{`${t(
                "detail.promoCodeApplied"
              )}:`}</Styled.MainInfoTitle>
              <div className="h-[5px]" />
              {promoCode ? (
                <div
                  className={cn(
                    "w-full flex flex-col gap-[5px] flex-wrap",
                    "lg:flex-row",
                    "lg:gap-2"
                  )}
                >
                  <Styled.MainInfoContent>{`${promoCode.code} ${promoCode.discountType === PromoCodeDiscountType.PRICE
                    ? `(-HK$${promoCode.discountAmount})`
                    : `(${promoCode.discountPercentage}% OFF)`
                    }`}</Styled.MainInfoContent>
                  <Styled.DiscountText>
                    {`-HK$${promoCode.discountAmount}`}
                  </Styled.DiscountText>
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="h-[20px] lg:hidden"></div>
            <div
              className={cn(
                "right w-full pt-[20px] border-dashed border-[#D5D5D5] border-t",
                "lg:w-1/2",
                "lg:border-t-0",
                "lg:border-l",
                "lg:pt-[12px]",
                "lg:pl-[27px]"
              )}
            >
              <Styled.MainInfoTitle>{`${t(
                "detail.voucherApplied"
              )}:`}</Styled.MainInfoTitle>
              <div className="h-[5px]" />
              {vouchers.length > 0
                ? vouchers
                  .map((voucher) => (
                    <div
                      className="w-full flex flex-col gap-[5px] flex-wrap lg:flex-row lg:gap-2"
                      key={voucher.voucherName}
                    >
                      <Styled.MainInfoContent>{`${voucher.voucherName} (-HK$${voucher.voucherPrice})`}</Styled.MainInfoContent>
                      <Styled.DiscountText>{`-HK$${voucher.voucherPrice}`}</Styled.DiscountText>
                    </div>
                  ))
                  .reduce(
                    (acc, curr, index) => [
                      ...acc,
                      <div
                        className="h-[10px] lg:hidden"
                        key={`gap-${index}`}
                      />,
                      curr,
                    ],
                    [] as React.ReactElement[]
                  )
                : "-"}
            </div>
          </div>
        </>
      )}
    </Styled.SharedCard>
  );
};

export default BookingDetailReceipt;
