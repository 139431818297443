import React, { useEffect } from "react";
import cn from "classnames";
import ImageBanner from "assets/bg-tnc-banner.png";
import { useTranslation } from "react-i18next";
import Card from "components/common/Card";
import theme from "utils/theme";
import { getLocalizedString } from "utils/locale";

const PrivacyPolicyPage = () => {
  const { t, i18n } = useTranslation("navBar");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <div className={cn("h-full", "m-[50px_20px_100px]", "md:m-[50px_0_0px]")}>
        <Card
          bgColor={theme.lightCyan}
          style={{ padding: 35, width: "100%" }}
          maxHeight="none"
        >
          <div
            className={cn(
              "flex flex-col",
              "w-full max-w-[879px]",
              "m-[0_auto]",
              "md:pb-[163px]",
              "font-poppins text-base font-normal leading-6 tracking-[0px] text-left text-app-black"
            )}
          >
            <h1 className="text-2xl font-medium leading-[26px] tracking-[0px] text-left">
              {getLocalizedString(
                "GO PARK Sports – Privacy Policy",
                "GO PARK Sports - 隱私政策"
              )}
            </h1>
            <p className="mt-5 md:mt-[40px]">
              {getLocalizedString(
                "GO PARK Sports (GPS) is a sports academy, which is wholly owned by Sun Hung Kai Properties Limited, operated by Inno-Sports Concept Management Limited, and located at 9 Hoi Ying Road, Tai Po, New Territories, Hong Kong.",
                "GO PARK Sports（GPS）是由Inno-Sports Concept Management Limited經營的體育學院，位於香港新界大埔海映路9號。Inno-Sports Concept Management Limited為新鴻基地產發展有限公司全資附屬機構。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "It is the policy of GPS to comply with the Personal Data (Privacy) Ordinance, Chapter 486 of the laws of Hong Kong. GPS also strives to ensure compliance by our staff with the strictest standards of security and confidentiality to ensure that all personal data are handled in accordance with the provisions of the Personal Data (Privacy) Ordinance to set procedures and to ensure good practices within GPS regarding the acquisition, collection, recording, storage, disclosure, correction, erasure and communication of personal data kept by GPS on its employees and users.",
                "GPS的政策是遵守香港法例第486章《個人資料（私隱）條例》。GPS亦致力確保我們的員工符合最嚴格的安全和保密標準，以確保所有個人資料按照《個人資料（私隱）條例》的規定處理，設立程序並確保GPS內部就收集、彙集、記錄、儲存、披露、更正、刪除及傳達GPS持有的員工和用戶的個人資料的良好做法。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "GPS safeguards information provided by our users, mobile application (“App”) users, and website users (“User”). With the only exceptions that personal data may be collected in relation to the services of user application, renewal, account maintenance, email registration, activities and the giving/collection of opinion online, and the IP address of the computer used to visit our website may be recorded, we do not collect any personally identifiable information from User while they use the App and website, and no personal data is collected, unless specified. This information is used only for preparing general statistics on the usage of our App and website.",
                "GPS保護我們的用戶、手機應用程式（「應用程式」）用戶和網站用戶（「用戶」）提供的資訊。除了可能在用戶申請、續會、帳戶維護、電子郵件註冊、線上活動和意見收集過程中收集個人資料，以及可能記錄訪問我們網站所用電腦的IP地址外，我們不會在用戶使用應用程式和網站時收集任何個人身份可識別資訊，除非另有說明。這些資訊僅用於準備我們應用程式和網站使用情況的一般統計資料。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "If personal data is collected through this App or in the process of making enquiries or submitting applications for services provided by different operations within Sun Hung Kai Properties Limited and/or any of its subsidiaries and/or associated companies (the “Group”), users are informed via the Personal Information Collection Statements of such purposes and uses, including the extent of transfer and disclosure and right of access to and correction of the collected data.",
                "如果通過此應用程式或在查詢或申請新鴻基地產有限公司及/或其任何子公司及/或聯營公司（「集團」）內不同業務提供的服務過程中收集了個人資料，用戶將通過個人資料收集聲明被告知此類目的和用途，包括傳輸和披露的範圍以及查閱和更正所收集資料的權利。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "We use encryption to store and sometimes track information about users to provide them with customized information in this App. The information collected by session includes user’s login name and his or her navigation pattern or preferences. This may be done without the users being aware that it is occurring. Generally relevant data are used to estimate audience size, to track users’ progress and number of entries in our promotional activities, to know the frequency of user’s browsing or using of individual services, to measure users’ traffic patterns, and to prioritize the information to be provided to them.",
                "我們使用加密技術儲存並有時追蹤用戶的資訊，以便在此應用程式中為他們提供定制化資訊。收集的會話資訊包括用戶的登錄名稱及其導航模式或偏好。這可能在用戶不知情的情況下進行。一般來說，相關的資料被用於估算觀眾規模，追蹤用戶在我們促銷活動中的進度和參與次數，了解用戶的瀏覽或使用個別服務的頻率，衡量用戶的流量模式，並優先提供資訊給他們。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "We use cookies across our website (including the App) to improve their performance and enhance the User’s online experience. Session cookies are used to enable User to move around the site (including the App) and use essential functions on our websites (including the App), such as maintaining log-in details for the session such that the need to transfer information across the internet may be reduced. We will not collect any personal data from User when the cookies are being used.",
                "我們在我們的網站（包括應用程式）上使用cookies，以提高網站性能並增強用戶的在線體驗。會話cookies被用於讓用戶在網站（包括應用程式）上移動以及使用我們網站（包括應用程式的必要功能，例如維護會話期間的登錄詳情，從而減少跨互聯網傳輸資訊的需要。當使用cookies時，我們不會從用戶那裡收集任何個人資料。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "The collection of any personal data collected will be subject to our Personal Information Collection Statement. Personal data is stored securely in our system, and we will take all practicable steps to ensure that personal data is not kept longer than is necessary for the fulfilment of the purpose (including any related purpose) for which the data is or is to be used. Only trained, authorized staff shall have access to the data, and we do not release personal data to parties outside us except as set out in our Personal Information Collection Statements. Users have the right to request access to and correction of their personal information in accordance with the procedure set out in our Personal Information Collection Statements. ",
                "任何收集的個人資料將受到我們個人資料收集聲明的規範。個人資料將安全地儲存在我們的系統中，我們將採取所有切實可行的步驟以確保不會將個人資料保留超過用於履行資料使用目的（包括任何相關目的）所必需的時間。只有受過訓練、獲得授權的員工才能訪問這些數據，我們不會將個人資料外洩給我們以外的其他方，除非在我們個人資料收集聲明中設定的情況下。用戶有權根據我們個人資料收集聲明中設定的程序，要求查閱和更正其個人資料。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "We may, from time to time, send direct marketing materials promoting products and services to User based on their personal data, but suitable opt-out mechanisms are provided. ",
                "我們可能會不時根據用戶的個人資料向用戶發送促銷產品和服務的直銷資料，但提供了適當的退出機制。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "We collect only limited personal information from children of age 12 or under (such as their email addresses) to respond to their online requests, including but not limited to facility bookings, lesson enrolments and more. We may also ask a child to provide an email address of his/her parent so we can notify the parent or seek consent. We will not collect more detailed information from any child of age 12 or younger, such as postal address or telephone number, without the consent of his/her parent or guardian. ",
                "我們僅從12歲或以下的兒童（例如他們的電子郵件地址）收集有限的個人資料，以回應他們在線的請求，包括但不限於設施預訂、課程報名等。我們也可能要求兒童提供他/她父母的電子郵件地址，以便我們通知父母或尋求同意。未經其父母或監護人同意，我們不會從12歲或以下的任何兒童那裡收集更詳細的資訊，如郵寄地址或電話號碼。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "We make every effort to protect the privacy of User, but given the nature of the Internet, we cannot guarantee perfect security, especially since no information provided over the Internet can be guaranteed to be completely secured. ",
                "我們盡一切努力保護用戶的隱私，但鑑於互聯網的性質，我們不能保證完美的安全性，特別是因為無法保證透過互聯網提供的資訊是完全安全的。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "We use third party content and/or service and/or service providers (the “Third Party Service Providers”) and offer links to third party websites. These Third-Party Service Providers may collect personal data from Users, and they adhere to their own privacy policies. Our Privacy Policy Statement and Personal Information Collection Statements do not cover third parties. ",
                "我們使用第三方內容和/或服務和/或服務提供商（「第三方服務提供商」）並提供指向第三方網站的鏈接。這些第三方服務提供商可能會從用戶那裡收集個人資料，並遵守他們自己的隱私政策。我們的隱私政策聲明和個人資料收集聲明不涵蓋第三方。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "User who has any questions about our privacy policy and practice can contact our Personal Data (Privacy) Officer at 16/F, World Tech Centre, 95 How Ming Street, Kwun Tong, Kowloon or email to: enquiry@goparksports.hk.",
                "如用戶對我們的隱私政策和實踐有任何疑問，可聯繫我們的資料保障主任，電郵地址為enquiry@goparksports.hk，或郵寄至九龍官塘巧明街95號世達中心16樓資料保障主任收。"
              )}
            </p>
            <h2 className="mt-6 text-xl font-medium leading-[26px] tracking-[0px] text-left;">
              {getLocalizedString(
                "Personal Information Collection Statement",
                "個人資料收集聲明"
              )}
            </h2>
            <p className="mt-6">
              {getLocalizedString(
                "This Personal Information Collection Statement sets out the purposes for which the information provided by you as a User will be used following collection, what you are agreeing to with respect to our use of such information and your rights under the Personal Data (Privacy) Ordinance, Cap 486. This statement may change from time to time, so please check back periodically. When you complete the application form to join by submitting thereon or each time you log in to this App and website and use its services, you agree to be bound by the statement in force at that time.",
                "本個人資料收集聲明設定了收集後將如何使用您作為用戶提供的資訊的目的，您對於我們如何使用該等資訊以及您根據《個人資料（私隱）條例》第486章的權利的同意。此聲明可能不時更改，因此請定期檢查。當您完成加入的申請表格提交或每次登入此應用程式和網站並使用其服務時，即表示您同意受當時有效的聲明約束。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "Some information provided as shown in this App is provided by third party content and/or service and/or product providers (the “Third Party Service/Product Providers”). This Personal Information Collection Statement does not cover Third Party Service Providers, please refer to those Third-Party Service Providers’ Personal Information Collection Statement for use and collection of personal data.",
                "此應用程式顯示的一些資訊是由第三方內容和/或服務和/或產品提供商（「第三方服務/產品提供商」）提供。本個人資料收集聲明不涵蓋第三方服務提供商，請參閱那些第三方服務提供商的個人資料收集聲明以了解個人資料的使用和收集。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "The personal data collected will be used for the purpose of handling complaints. Providing personal data is voluntary. However, if a complainant does not provide sufficient information, we may not be able to follow up on and handle the complaint. A complainant may, at any time, request us to cease using his or her personal data by informing our Personal Data (Privacy) Officer. The collection of personal data is necessary in order to conduct daily management and follow up on the complaint you lodged. Information collected from you may be used for the purpose of statistics, analysis and investigation by the Group. Any personal data provided may be transferred to other parties if it is necessary for us to comply with the applicable legal or regulatory requirements, or orders of competent authorities in Hong Kong or other jurisdictions. A complainant has the right to request access to, and correction of, his/her personal data held by us. Any such request should be made to our Personal Data (Privacy) Officer at 16/F, World Tech Centre, 95 How Ming Street, Kwun Tong, Kowloon or email to: enquiry@goparksports.hk.",
                "收集個人資料是為了處理投訴用途。提供個人資料純屬自願。但是,如果投訴人未能提供足夠資料,我們可能無法跟進和處理有關投訴。 GO PARK Sports 將只會回覆已驗證用戶提出的包括姓名、用戶編號和電郵地址的投訴。投訴人可以隨時要求我們停止使用其個人資料, 方法是通知我們的個人資料私隱主任。收集個人資料是為了跟進你提出的投訴及日常管理需要。我們或會將收集所得的資料用作統計、分析和調查之用。如有需要遵守適用法律或監管規定, 或應香港或其他司法管轄區的主管機關命令，任何提供的個人資料可能需轉讓予其他機構。投訴人有權要求查閱和更正我們持有的個人資料。任何有關要求請送交個人資料私隱主任，地址為: 九龍觀塘巧明街95號世達中心16樓或電郵查詢 enquiry@goparksports.hk。"
              )}
            </p>

            <h2 className="mt-6 text-xl font-medium leading-[26px] tracking-[0px] text-left;">
              {getLocalizedString("Collection of Data", "資料收集")}
            </h2>
            <p className="mt-6">
              {getLocalizedString(
                "Users may be asked to provide personally identifiable information such as name, contact number and email address. This is necessary to provide the User with the various services and activities offered on this App and website. You, as a User, are not obliged to supply the data, but if you do not, the services and activities will be unavailable. IP address of the computer/mobile device IP used to visit our website may be recorded.",
                "用戶可能被要求提供可識別個人身份的資訊，如姓名、聯絡號碼和電子郵件地址。這是為了向用戶提供此應用程式和網站上提供的各種服務和活動所必需的。作為用戶，您無義務提供這些資料，但如果不提供，則無法使用這些服務和活動。可能會記錄訪問我們網站的電腦/移動裝置的IP地址。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "When users register for this App, some preference questions will be asked from them. The information collected is to better prioritize the information to be made available to users through this App according to their preferences. Such information may also be used for preparing general statistics on the usage of this App.",
                "當用戶註冊此應用程式時，會被要求回答一些偏好問題。收集的資訊用於更好地根據用戶的偏好優先提供此應用程式中的資訊。這些資訊也可能用於準備此應用程式使用情況的一般統計資料。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "If you are under the age of 18, you shall have your parents’ or guardian’s consent before you provide your personal data to us.",
                "如果您未滿18歲，您在向我們提供您的個人資料前，應先取得父母或監護人的同意。"
              )}
            </p>
            <h2 className="mt-6 text-xl font-medium leading-[26px] tracking-[0px] text-left;">
              {getLocalizedString(
                "Purpose and Use of Data",
                "資料的目的和使用"
              )}
            </h2>
            <p className="mt-4">
              {getLocalizedString(
                "Information collected is used for the followings:",
                "收集的資訊用於以下目的："
              )}
            </p>
            <ul className="mt-2 pl-[16px] list-disc">
              <li>
                {getLocalizedString(
                  "providing the services and information relating to such services to you including any renewal or recurrent services thereof;",
                  "向您提供服務以及與此類服務相關的資訊，包括任何續訂或重複的服務；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "processing billing and payment;",
                  "處理賬單和付款；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "monitoring use of the App and website to help its further development;",
                  "監控應用程式和網站的使用情況，以助於其進一步發展；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "compiling aggregate statistics about our users to analyse App and website usage, for maintaining and developing the App and for improving our services;",
                  "編制關於我們用戶的綜合統計數據，分析應用程式和網站的使用情況，維護和發展應用程式，並改進我們的服務；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "collecting data for identity verification and record and maintaining contact lists for correspondence;",
                  "收集用於身份驗證和記錄以及維護通訊名單的資料；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "operating internal control, resolving disputes, troubleshooting problems, and enforcing terms of use;",
                  "運作內部控制，解決爭議，排除故障，並執行使用條款；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "creating accounts for User who intends to register with the services offered on this App and website and maintaining such accounts;",
                  "為有意註冊本應用程式和網站上提供的服務的用戶建立帳戶，並維護這些帳戶；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "registering accounts for User who intends to join GPS and maintenance of their bookings & Sports Pass; ",
                  "為有意加入GPS的用戶註冊帳戶並維護其預訂和運動通行證；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "identifying and verifying identity and/or eligibility of the User who uses/used and/or applies/applied for the services offered on our App and website and/or by The Point;",
                  "識別和驗證使用/申請本應用程式和網站上提供的服務和/或由The Point提供的用戶的身份和/或資格；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "research and/or analysis by GPS, The Point and/or SHKP and/or Sun Hung Kai Properties Limited and/or any of its subsidiaries and/or associated companies (“the Group”);",
                  "由GPS、The Point和/或新鴻基地產有限公司及/或其任何子公司和/或關聯公司（「集團」）進行的研究和/或分析；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "meeting any requirements to make disclosure under any applicable law;",
                  "符合披露任何適用法律要求；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "any other purpose which an applicant or user may from time to time agree;",
                  "申請人或用戶不時同意的任何其他目的；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "communicating with User in respect of matters related to or arising from the use of the services offered on this App and website and/or by The Point; ",
                  "與使用本應用程式和網站上提供的服務和/或由The Point提供的服務相關或因此而產生的事宜與用戶溝通；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "providing information related to GPS including the products, facilities, services and other privileges, benefits and other advantages from time to time offered by the Group or Third-Party Service Providers to User and rallying and arranging for the same to be provided (see also Use of Information in Direct Marketing). ",
                  "提供與GPS相關的資訊，包括集團或第三方服務提供商不時提供給用戶的產品、設施、服務和其他優惠、福利及其他優勢，並組織和安排提供同等服務（另見直接市場營銷中的資訊使用）；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "evaluating and improving the facilities, services and/or products offered by GPS;",
                  "評估和改善GPS提供的設施、服務和/或產品；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "facilitating communications between User and GPS and encouraging feed-back from User on their needs and expectations of facilities, services and/or products offered by GPS;",
                  "促進用戶與GPS之間的溝通，鼓勵用戶就GPS提供的設施、服務和/或產品的需求和期望提供反饋；"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "to determine the User’s eligibility to any products, facilities, services and other privileges, benefits and other advantages offered by GPS and consider what may best suit User’s needs, comparing User’s personal information with all personal information concerning User previously provided to the Group.",
                  "為了確定用戶對GPS提供的任何產品、設施、服務及其他優惠、福利和其他優勢的資格，並考慮什麼可能最適合用戶的需求，將用戶的個人資料與用戶先前向集團提供的所有個人資料進行比較。"
                )}
              </li>
            </ul>
            <h2 className="mt-6 text-xl font-medium leading-[26px] tracking-[0px] text-left;">
              {getLocalizedString(
                "Use of Information in Direct Marketing",
                "在直接市場營銷中使用資訊"
              )}
            </h2>
            <p className="mt-4">
              {getLocalizedString(
                "Use of Information in Direct Marketing: We intend to use all information provided by you as a user including your name, contact details and all other information which may assist us to understand the goods and services which you might be interested in, for delivering the aforesaid information and conducting direct marketing including products, services, advice and subjects in relation to the followings: Sports classes, facilities, events, corporate social responsibility activities, charitable and non-profitable causes. We also intend to, and in this regard, you consent to, transfer and provide the above-mentioned information to the subsidiary and/or associated companies of the Group and the Third-Party Service/ Product Providers through whom the Group's and / or third party’s products, facilities, services, privileges, benefits or advantages are provided inside and outside Hong Kong for the said purpose. We may not use or provide the said information unless we have received your consent. Your consent may be communicated to us without charge at the time when you provide such information through our website, App or by writing to our Data Protection Officer.",
                "我們打算使用您作為用戶提供的所有資訊，包括您的姓名、聯絡資料以及所有其他可能幫助我們了解您可能感興趣的貨物和服務的資訊，用於提供上述資訊和進行直接市場營銷，包括與以下相關的產品、服務、建議和主題：體育課程、設施、活動、企業社會責任活動、慈善和非牟利事業。我們還打算在此方面將上述資訊轉移和提供給集團的子公司和/或關聯公司以及第三方服務/產品提供商，他們在香港境內外提供集團和/或第三方的產品、設施、服務、特權、福利或優勢，用於上述目的。除非我們收到您的同意，否則我們不會使用或提供上述資訊。您可以在通過我們的網站、應用程式提供此類資訊時或通過書面形式向我們的數據保護主任免費傳達您的同意。"
              )}
            </p>
            <h2 className="mt-6 text-xl font-medium leading-[26px] tracking-[0px] text-left;">
              {getLocalizedString("Transfer of Data", "資料轉移")}
            </h2>
            <p className="mt-4">
              {getLocalizedString(
                "Users’ personal data will be kept confidential, but we may transfer or disclose such personal data to our contractors under a duty of confidentiality to us who provide administrative, telecommunications, computer, data processing or other services to us in connection with management, operation, and maintenance of the system with the purpose of personal data collection.",
                "用戶的個人資料將會保密處理，但我們可能會將這些資料轉交給對我們負有保密義務的承辦商，這些承辦商為我們提供管理、運作和維護系統相關的行政、電訊、電腦、數據處理或其他服務。除非以下情況，否則通常不會向會識別用戶身份的第三方披露或轉移用戶資料："
              )}
            </p>
            {i18n.language === "en" && (
              <p>
                {getLocalizedString(
                  "User information will not generally be disclosed or transferred to any other party in a form that would identify them except as follows:",
                  ""
                )}
              </p>
            )}
            <ul className="mt-2 pl-[16px] list-disc">
              <li>
                {getLocalizedString(
                  "If it is necessary to allow Third Party Service/Product Providers to offer and provide the third-party services (“Third Party Services”) chosen by a user in this App and for purposes relating thereto.",
                  " 當為了允許第三方服務/產品提供商提供用戶在此應用程式中選擇的第三方服務（「第三方服務」）及相關目的時，必要披露。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "In the case of payment default or payment dispute, if it is necessary for GPS to pass your personal data to the relevant third party including but not limited to debt collection agencies or our Group’s legal representatives, any Third-Party Services/Products Providers or any third party involved in a corporate business transaction with our Group for the purpose of debt recovery or verifying the disputed amount.",
                  "若用戶出現付款違約或付款爭議，並且為了GPS向包括但不限於債務收集機構或我們集團法律代表、任何第三方服務/產品提供商或與我們集團進行企業業務交易的任何第三方傳遞用戶的個人資料，以便債務追討或驗證有爭議的金額而必要時。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "If it is necessary to transfer your personal data to comply with any guideline or guidance of any local or foreign legal, regulatory, tax, government, law enforcement or other authorities.",
                  "若為了遵守任何本地或外國的法律、監管、稅務、政府、執法或其他機關的指引或指示而必要時，轉移用戶的個人資料。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "We may also transfer/exchange/disclose your personal data to or with companies within the Group for internal statistical research and/or analysis.",
                  "我們也可能會將用戶的個人資料轉移/交換/披露給集團內的公司，用於內部統計研究和/或分析。"
                )}
              </li>
              <li>
                {getLocalizedString(
                  "As required or permitted by law.",
                  "法律要求或允許的情況下。"
                )}
              </li>
            </ul>
            <p className="mt-4">
              {getLocalizedString(
                "These parties may have places of business outside Hong Kong, so information may be transferred out of Hong Kong. By subscribing to this App or by logging-in, users agree to transfer information to these parties outside Hong Kong when needed. These parties have their own privacy policies, and the Group has no control over their use of information, so the Group is not liable for their use of information.",
                "這些方可能在香港以外地方經營業務，因此資訊可能會被轉移至香港以外。通過訂閱此應用程式或登錄，用戶同意在需要時將資訊轉移給香港以外的這些方。這些方有自己的隱私政策，而集團對他們使用資訊無法控制，因此集團對其使用資訊不承擔責任。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "Please also see our Privacy Policy Statement which sets out our general privacy policy and practices in respect of our collection, holding and use of your information.",
                "請參閱我們的隱私政策聲明，該聲明設定了我們就收集、保有和使用您的資訊的一般隱私政策和實踐。"
              )}
            </p>
            <h2 className="mt-6 text-xl font-medium leading-[26px] tracking-[0px] text-left;">
              {getLocalizedString("Use of Cookies", "使用Cookies")}
            </h2>
            <p className="mt-4">
              {getLocalizedString(
                "We use cookies across our websites and App to improve their performance and enhance User's online experience. Session cookies are used to enable User to move around the site and App and use essential functions on our websites and App, such as maintaining log-in details for the session such that the need to transfer information across the internet may be reduced. We will not collect any personal data from User when the cookies are being used.",
                "我們在我們的網站和應用程式上使用cookies，以提高其性能並增強用戶的在線體驗。會話cookies用於使用戶能夠在網站和應用程式上移動以及使用我們網站和應用程式上的基本功能，例如維持會話期間的登入詳情，從而減少跨互聯網傳輸資訊的需要。當使用cookies時，我們不會從用戶那裡收集任何個人資料。"
              )}
            </p>
            <h2 className="mt-6 text-xl font-medium leading-[26px] tracking-[0px] text-left;">
              {getLocalizedString("Access to data", "資料存取")}
            </h2>
            <p className="mt-4">
              {getLocalizedString(
                "You have the right to request access to and correction of your personal data in accordance with the provisions of the Personal Data (Privacy) Ordinance (Cap.486). Any data access request or data correction request may be made to our Personal Data (Privacy) Officer at 16/F, World Tech Centre, 95 How Ming Street, Kwun Tong, Kowloon or email to: enquiry@goparksports.hk.",
                "您有權根據《個人資料（私隱）條例》（第486章）的規定要求查閱和更正您的個人資料。任何資料存取請求或資料更正請求可向我們的資料保護主任提出，電子郵件地址為enquiry@goparksports.hk，或郵寄至九龍官塘巧明街95號世達中心16樓資料保障主任收。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "If there is any inconsistency or conflict between the English and Chinese version, the English version shall prevail. ",
                "如果英文版和中文版之間有任何不一致或衝突之處，應以英文版為準。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "We will share with you the latest information about GPS, SHKP Malls and The Point which might interest you. Should you not wish to receive direct marketing material and/or information from GPS, you may exercise your opt-out right by notifying us in writing to our Personal Data (Privacy) Officer at 16/F, World Tech Centre, 95 How Ming Street, Kwun Tong, Kowloon or email to: enquiry@goparksports.hk. We may charge a fee for processing a data access request. ",
                "我們將與您分享最新的GO PARK Sports、新鴻基商場和The Point的資訊，這些資訊可能會引起您的興趣。如果您不希望收到GO PARK Sports 的直銷材料和/或資訊，您可以通過書面通知我們的資料保障主任行使退出權利，電子郵件地址為enquiry@goparksports.hk，或郵寄至九龍官塘巧明街95號世達中心16樓資料保障主任收。我們可能會收取處理資料存取請求的費用。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "Please be assured that even if you have chosen not to receive our direct marketing materials, we shall continue to honour your user rights and you will continue to enjoy the benefits conferred accordingly thereunder. You can still learn of our offers on our website and App or notices at the various venues under the Group.",
                "即使您選擇不接收我們的直銷材料，我們仍將繼續尊重您的用戶權利，您將繼續享有相應的權益。您仍然可以通過我們的網站和應用程式或集團各場地的通知了解我們的優惠。"
              )}
            </p>
            <p className="mt-4">
              {getLocalizedString(
                "Last revised on: 04/06/2024",
                "最後修訂日期: 04/06/2024"
              )}
            </p>
          </div>
        </Card>
      </div>
    </main>
  );
};

export default PrivacyPolicyPage;
