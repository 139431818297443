import React, { useMemo } from "react";
import { InferType, date, object, string, mixed } from "yup";
import { Gender } from "../../../models/Gender";
import { UserTitle } from "common/models/User";
import { ICurrentUser } from "../../../@types/common";
import dayjs from "common/utils/dayjs";
import _ from "lodash";

const newUserYupSchema = object({
  profile_pic_ref: string(),
  profile_pic_file: mixed(),
  title: string().oneOf(Object.values(UserTitle)).required(),
  username: string().required(),
  enName: string().required(),
  zhName: string(),
  dateOfBirth: string().required(),
  gender: string().oneOf(Object.values(Gender)).required(),
  phoneNum: string().required(),
  district: string().required(),
  email: string().email().required(),
});

export type NewUser = InferType<typeof newUserYupSchema>;

export const useUserVerificationFormModel = (user?: ICurrentUser | null) => {
  const formInitialValues = useMemo(
    () => ({
      profile_pic_ref: user?.profile_pic_ref ?? "",
      profile_pic_file: {},
      username: user?.username ?? "",
      enName: user?.name_en ?? "",
      zhName: user?.name_zh ?? "",
      dateOfBirth: dayjs(user?.birthday?.birthday) ?? null,
      title: user?.title ?? "",
      gender: !_.isEmpty(user?.gender)
        ? user?.gender === "M"
          ? Gender.MALE
          : Gender.FEMALE
        : null,
      district: user?.address ?? "",
      phoneNum: user?.phoneNum ?? "",
      email: user?.email ?? "",
    }),
    [user]
  );

  return { formInitialValues, formValidateSchema: newUserYupSchema };
};
