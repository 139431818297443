import { FacilityBooking } from "./FacilityBooking";

export class FacilityBookingOrder {
  constructor({
    _id,
    ordernum,
    user_id,
    type,
    booking_id,
    status,
    amount,
    transaction,
    transactions,
    promoCode,
    bookingPriceDetail,
    isPastBooking,
    isAllowEditGuest,
    isAllowCancel,
  }) {
    this._id = _id;
    this.ordernum = ordernum;
    this.user_id = user_id;
    this.type = type;
    this.bookings = booking_id.map((booking) => new FacilityBooking(booking));
    this.status = status;
    this.amount = amount;
    this.transaction = transaction;
    this.transactions = transactions ?? [];
    this.promoCode = promoCode;
    this.bookingPriceDetail = bookingPriceDetail;
    this.isPastBooking = isPastBooking;
    this.isAllowEditGuest = isAllowEditGuest;
    this.isAllowCancel = isAllowCancel;
  }

  getKeyMemberList() {
    return this.bookings
      ?.find(() => true)
      ?.guestlist?.filter((guest) => guest?.key_member == true);
  }
}
