import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as _ from "lodash";
import IconLoader from "../../assets/IconLoader";
import { LatestNew } from "models";
import { getLocalisedString } from "common/utils/stringHelper";
import { useNavigate } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import Typography from "components/common/Typography";
import theme from "utils/theme";
import { breakpoint } from "utils/responsive";
import ImageNewsBg from "assets/bg-homeNews.png";
import GeneralButton from "components/common/Button";

const Wapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  border-radius: 30px;
  margin: 0px auto;
  padding: 100px 50px 20px;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  @media (max-width: 639px) {
    padding: 30px;
  }
`;

const Container = styled.div`
  background-image: url(${ImageNewsBg});
  padding: 30px 0px;
  background-size: 100% 100%;
  height: auto;
`;

const ItemWapper = styled.div`
  display: flex;
  width: 100%;
  float: left;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
`;

const BoxItem = styled.div<{ img: string }>`
  padding: 25px;
  display: block;
  width: calc(33.33% - 30px);
  height: 275px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
  background-image: url(${(p) => p.img});
  background-size: cover;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
  }

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0px;
    left: 0px;

    right: 0px;
    top: 20%;
    background: linear-gradient(
      180deg,
      rgba(9, 99, 119, 0) 16.44%,
      ${theme.blue} 71.72%
    );
  }

  @media (max-width: 1199px) {
    width: calc(33.33% - 22.5px);
  }

  @media (max-width: 991px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 639px) {
    width: 100%;
  }
`;

const ItemTitle = styled.div`
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  position: absolute;
  bottom: 20px;
  left: 25px;
  z-index: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 65px;
  width: calc(100% - 50px);
`;

const Row = styled.div<{ textAlign?: string }>`
  display: block;
  margin: 10px 0px;
  width: 100%;
  text-align: ${(p) => (p.textAlign ? p.textAlign : "left")};
  padding: 20px 0px;
`;

const Title = styled.div`
  @media ${breakpoint.desktop} {
    margin-left: 25px;
  }

  @media ${breakpoint.mobile} {
    display: flex;
    justify-content: center;
  }
`;

interface LatestNewsProps {
  latestNews: LatestNew[];
}

const News = ({ latestNews }: LatestNewsProps) => {
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  return (
    <Container>
      <Title>
        <Typography
          fontSize="35px"
          mFontSize="24px"
          fontWeight="700"
          color={theme.black}
        >
          {t("news.title")}
        </Typography>
      </Title>
      <Wapper>
        <ItemWapper>
          {_.map(latestNews, (item, idx) => {
            if (idx < 3) {
              return (
                <BoxItem
                  img={
                    item.thumbnail_ref_en
                      ? getLocalisedString(
                          item.thumbnail_ref_en,
                          item.thumbnail_ref_zh
                        )
                      : "/no-img.png"
                  }
                  onClick={() =>
                    navigate(`${ROUTE_BASE.NEWS}/${item?._id}`, {
                      state: { news: JSON.stringify(item) },
                    })
                  }
                >
                  <ItemTitle>
                    {getLocalisedString(item.title_en, item.title_zh)}
                  </ItemTitle>
                </BoxItem>
              );
            }
          })}
        </ItemWapper>
      </Wapper>
      <Row textAlign="center">
        <GeneralButton onClick={() => navigate(ROUTE_BASE.NEWS)}>
          {t("event.more")}
        </GeneralButton>
      </Row>
    </Container>
  );
};

export default News;
