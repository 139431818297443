import React, { useState, useEffect } from "react";
import Styled from "../Styled";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { FacilityCategory } from "models";
import { getLocalisedString } from "common/utils/stringHelper";

export interface CategoryFilterViewProps {
  show: boolean;
  activeCategoryId?: string | null;
  categoryList: FacilityCategory[];
  onCloseFilterView: () => void;
  onFilterCategory: (id: string | null) => void;
}

const CategoryFilterView: React.FC<CategoryFilterViewProps> = ({
  show,
  activeCategoryId,
  categoryList,
  onCloseFilterView,
  onFilterCategory,
}) => {
  const { t } = useTranslation("common");
  const [disable, setDisable] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    activeCategoryId ?? null
  );

  useEffect(() => {
    setDisable(!show);
  }, [show]);

  const handleSelectCategory = (id: string) => {
    setSelectedCategoryId((prev) => {
      if (prev === id) {
        return null;
      }
      return id;
    });
  };

  const handleApplyFilter = () => {
    setDisable(true);
    onFilterCategory(selectedCategoryId);
    onCloseFilterView();
  };

  const handleCancelFilter = () => {
    setDisable(true);
    setSelectedCategoryId(activeCategoryId ?? null);
    onCloseFilterView();
  };

  return (
    <Styled.FilterView className={!disable ? "" : "hide"}>
      <Styled.Category
        onClick={() => handleSelectCategory("")}
        className={!selectedCategoryId ? "active" : ""}
      >
        {t("common:button.all")}
      </Styled.Category>
      {categoryList?.map((category) => (
        <Styled.Category
          key={category._id}
          onClick={() => handleSelectCategory(category._id)}
          className={category._id === selectedCategoryId ? "active" : ""}
        >
          {getLocalisedString(category.name_en, category.name_tc)}
        </Styled.Category>
      ))}
      <Styled.Row className="group-button-action">
        <Styled.Button type="default" onClick={() => handleCancelFilter()}>
          {t("event:filter.buttonCancel")}
        </Styled.Button>
        <Styled.Button type="primary" onClick={() => handleApplyFilter()}>
          {t("event:filter.buttonConfirm")}
        </Styled.Button>
      </Styled.Row>
    </Styled.FilterView>
  );
};

export default CategoryFilterView;
