import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { ROUTE_BASE } from "../../Route";
import VoucherScreen from "./VoucherScreen";
import VoucherListPage from "./VoucherListPage";
import VoucherDetailPage from "./VoucherDetailPage";

// full path for component route matching only, not for dom router

const VoucherRouter: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <VoucherScreen />,
      children: [
        {
          path: "/",
          element: <VoucherListPage />,
        },
        /**
         * Currently no use of this page
         */
        // {
        //   path: "/:voucherId",
        //   element: <VoucherDetailPage />,
        // },
        {
          path: "*",
          element: <Navigate to={ROUTE_BASE.VOUCHER} />,
        },
      ],
    },
  ]);
};

export default VoucherRouter;
