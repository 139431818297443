import React, { useMemo } from "react";
import cn from "classnames";
import { UserTitle, userTitleList } from "common/models/User";
import { useTranslation } from "react-i18next";
import { TextFieldLabel } from "components/common/Textfield";

type Props = {
  selectedTitle: string;
  setTitle: (newTitle: UserTitle) => void;
};

const UserNameTitleSelect = (props: Props) => {
  const { selectedTitle, setTitle } = props;
  const { t } = useTranslation("register");

  return (
    <div className="container self-start w-full max-w-[324px] ">
      <TextFieldLabel>{t("register:field.title")}</TextFieldLabel>
      <div className="w-full grid grid-cols-[repeat(4,22%)] md:grid-cols-[repeat(4,25%)] gap-x-[10px] justify-start">
        {userTitleList?.map((each) => (
          <button
            key={each}
            type="button"
            className={cn(
              "rounded-[5px]",
              "py-[8px]",
              selectedTitle === each ? "bg-[#FA804C]" : "bg-[#F3F3F3]",
              selectedTitle === each ? "text-white" : "text-black"
            )}
            onClick={() => setTitle(each)}
          >
            <slot className="text-sm font-medium leading-6 tracking-[0px] text-center">
              {t(`register:placeholder.title.${each}`)}
            </slot>
          </button>
        ))}
      </div>
    </div>
  );
};

export default UserNameTitleSelect;
