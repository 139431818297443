import { NotificationCategoryTag } from "common/models/Notification";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type CategoryTagProps = { tag: NotificationCategoryTag };
const CategoryTag = ({ tag }: CategoryTagProps) => {
  const { t } = useTranslation("notification");

  return <StyledTag tag={tag}>{t(`category.${tag}`)}</StyledTag>;
};

const StyledTag = styled.div<{ tag?: NotificationCategoryTag }>`
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 12px;
  width: fit-content;
  color: white;
  ${(props) => {
    switch (props.tag) {
      case NotificationCategoryTag.EventBooking:
        return "background: #254FA0";
      case NotificationCategoryTag.FacilityBooking:
        return "background: #BC4646";
      case NotificationCategoryTag.MonthlyProgramme:
        return "background: #CC8700";
      case NotificationCategoryTag.PrivateCoaching:
        return "background: #15B1B1";
      case NotificationCategoryTag.Others:
        return "background: #828282";
      default:
        return "";
    }
  }}
`;

export default CategoryTag;
