import React, { useMemo } from "react";
import styled from "styled-components";
import { Gender } from "../../models/Gender";
import Typography from "components/common/Typography";
import { useTranslation } from "react-i18next";
import Space from "components/common/Space";
import Button from "components/common/Button";

type Props = {
  gender: Gender | null;
  setGender: (gender: Gender) => void;
  required?: boolean;
};

const WhiteButton = styled(Button)`
  && {
    background-color: #F3F3F3;
    color: #000;
    width: 50%;
    ${props => props.selected && `
        background-color: #FF7925;
        color: #fff;
      `
  }
    &:hover {
      background-color: #FF7925;
      color: #fff;
      opacity: 0.8;
    }
  }
`

const GenderSelect = (props: Props) => {
  const { gender, setGender, required = false } = props;
  const { t } = useTranslation();

  const onSelect = (type: Gender) => {
    setGender(type)
  }

  return (
    <div>
      <Typography fontSize="12px">{`${t("register:field.gender")}${required ? "*" : ""
        }`}</Typography>
      <Space size="10px" />
      <div className="w-full flex justify-between gap-[16px]">
        <WhiteButton
          selected={gender === Gender.MALE}
          onClick={() => onSelect(Gender.MALE)}
        >
          {t("register:placeholder.gender.male")}
        </WhiteButton>
        <WhiteButton
          selected={gender === Gender.FEMALE}
          onClick={() => onSelect(Gender.FEMALE)}
        >
          {t("register:placeholder.gender.female")}
        </WhiteButton>
      </div>
    </div>
  );
};

export default GenderSelect;
