import React, { useMemo } from "react";
import dayjs from "common/utils/dayjs";
import Styled from "./Styled";
import { EventDate } from "../../../models";
import { PastBookingProps } from "..";
import MyPastBooking from "pages/MyBookingScreen/MyPastBooking";
import { stat } from "fs";
import MyPastListingCard from "../MyPastListingCard/MyPastListingCard";
import { useTranslation } from "react-i18next";
import { getDayOfWeekSlug } from "common/utils/DateHelper";

type Props = {
  title: string;
  dates: EventDate[];
  venue: string;
  onClick?: () => void;
} & PastBookingProps;

const MyEventListingCard = (props: Props) => {
  const { title, dates, venue, onClick, isPastRecord, status } = props;
  const { t } = useTranslation();

  const dateInFormat = useMemo(() => {
    return dates.map((date) => {
      const startDay = dayjs(date.start_date);
      const startDayOfWeek = t(
        `common:dateFormat.isoWeekday.${getDayOfWeekSlug(date.start_date)}`
      );
      const endDay = dayjs(date.end_date);
      return {
        date: startDay.tz().format("DD/MM/YYYY") + ` (${startDayOfWeek})`,
        time: startDay.tz().format("HH:mm") + "-" + endDay.tz().format("HH:mm"),
      };
    });
  }, [t, dates]);

  return isPastRecord ? (
    <MyPastListingCard title={title} status={status} onClick={onClick}>
      {dateInFormat.map((dObj) => (
        <React.Fragment key={dObj.date + "_" + dObj.time}>
          <div>{dObj.date}</div>
          <div>{dObj.time}</div>
        </React.Fragment>
      ))}
      <div>{venue}</div>
    </MyPastListingCard>
  ) : (
    <Styled.Card onClick={onClick}>
      <div className="w-full px-[20px] pt-[22px] pb-[28px] flex flex-col">
        <Styled.TitleText>{title}</Styled.TitleText>
        <div className="h-[5px]"></div>
        {dateInFormat.map((dObj) => (
          <React.Fragment key={dObj.date + "_" + dObj.time}>
            <Styled.BodyText>{dObj.date}</Styled.BodyText>
            <Styled.BodyText>{dObj.time}</Styled.BodyText>
          </React.Fragment>
        ))}
        <Styled.BodyText>{venue}</Styled.BodyText>
      </div>
    </Styled.Card>
  );
};

export default MyEventListingCard;
