import { TableCell } from "@mui/material";
import styled from "styled-components";
import { breakpoint } from "utils/responsive";

const StyledTableCell = styled(TableCell)`
  && {
    font-family: Poppins;
  }
`;

export const EnrollButtonCell = styled(StyledTableCell)`
  && {
    padding: 20px 60px 40px;
    @media ${breakpoint.mobile} {
      padding: 20px 0px 50px;
    }
  }
`;

export const HeaderCell = styled(StyledTableCell)`
  && {
    border-bottom: none;
    vertical-align: top;
    background-color: #f1f1f1;
    font-weight: 500;
    padding: 10px 40px;
    background: #f1f1f1;
    border-right: 1px #d8d8d8 solid;
    @media ${breakpoint.mobile} {
      padding: 11px 20px;
      border: none;
      border-top: 1px #d8d8d8 solid;
      border-bottom: 1px #d8d8d8 solid;
    }
  }
`;

const AlternatingColor = styled(StyledTableCell)<{ count: number }>`
  ${(props) => {
    return props.count % 2 === 0
      ? "background: #F1F1F1"
      : "background: #F5F5F5";
  }}
`;

export const DataCell = styled(AlternatingColor)`
  && {
    border-top: 1px #d8d8d8 solid;
    padding: 10px 40px;
    @media ${breakpoint.mobile} {
      border-bottom: 1px #d8d8d8 solid;
      padding: 11px 48px;
    }
  }
`;
export const DateCell = styled(AlternatingColor)`
  && {
    padding: 10px 40px;
    @media ${breakpoint.mobile} {
      padding: 6px 40px;
    }
  }
`;
