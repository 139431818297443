import Card from "components/common/Card";
import HeaderTitle from "components/common/HeaderTitle";
import InfoField from "pageComponents/profile/InfoField";
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useTranslation, Trans } from "react-i18next";
import { useSnackbar } from "notistack";
import theme from "utils/theme";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import dayjs from "common/utils/dayjs";
import { BasicDatePicker } from "components/common/DatePicker";
import Space from "components/common/Space";
import Typography from "components/common/Typography";
import { useProfileInfoViewModel } from "common/viewModels/myProfile/UpdateProfileInfoViewModel";
import { IGlobalProviderContext } from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { useNavigate } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import { getCorrectUrl } from "navigators/Router";
import GuardianLabel from "pages/RegistrationScreen/Components/GuardianLabel";
import ChildLabel from "pages/RegistrationScreen/Components/ChildLabel";

const PersonalInfo = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["profile", "common"]);
  const { currentUser } = useContext(UserViewModelContext);
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );

  const { editProfileInfo } = useProfileInfoViewModel();
  const { enqueueSnackbar } = useSnackbar();
  const [birth, setBirth] = useState<Date | null>();
  const [isEditBirth, setIsEditBirth] = useState<boolean>(false);

  const passType = useMemo(() => {
    const passType = currentUser?.sportpass
      ? t(`personalInfo.${currentUser?.sportpass?.type?.toLowerCase()}Pass`)
      : "";
    const expiryDate = currentUser?.sportpass
      ? dayjs(currentUser?.sportpass?.expiry_date)
          .tz()
          .format("DD/MM/YYYY")
      : null;

    return currentUser?.sportpass != null
      ? `${passType}, ${t("personalInfo.expiryDate")} ${expiryDate}`
      : null;
  }, [currentUser, t]);

  const updateBirthday = useCallback(async () => {
    if (birth != null) {
      const payload = {
        birthday: dayjs(birth).tz().format("YYYY-MM-DD"),
      };
      await editProfileInfo(payload, globalDispatch);
    }
  }, [birth, editProfileInfo, globalDispatch]);

  const handleBirthdayEditClick = useCallback(async () => {
    if (isEditBirth) {
      await updateBirthday();
    }
    setIsEditBirth((flag) => !flag);
  }, [isEditBirth, updateBirthday, setIsEditBirth]);

  useEffect(() => {
    if (currentUser == null) {
      setBirth(null);
    } else {
      const birthDate = dayjs(currentUser?.birthday?.birthday).toDate();
      setBirth(birthDate);
    }
  }, [currentUser]);

  return (
    <Card
      bgColor={theme.lightCyan}
      maxHeight={"none"}
      style={{ padding: 35, width: "100%" }}
    >
      <HeaderTitle title={t("index.profile")} />
      <InfoField
        title={t("personalInfo.enName")}
        value={
          i18n.language === "zh" ? currentUser?.name_zh : currentUser?.name_en
        }
      />
      <InfoField
        title={t("personalInfo.mobileNumber")}
        titleLabel={
          <GuardianLabel
            disabled={currentUser?.skipPhoneVerification !== true}
          />
        }
        value={currentUser?.phoneNum ?? "N/A"}
        rightClick={() =>
          navigate(getCorrectUrl(`${ROUTE_BASE.PROFILE}/mobile-verification`), {
            state: { isUpdate: true },
          })
        }
        rightTitle={
          currentUser?.isPhoneNumVerified
            ? t("personalInfo.edit")
            : t("personalInfo.add")
        }
      />
      <InfoField title={t("personalInfo.email")} value={currentUser?.email} />
      <InfoField
        title={t("personalInfo.birthday")}
        titleLabel={
          currentUser?.skipPhoneVerification === true && <ChildLabel />
        }
        value={dayjs(birth).tz().format("DD/MM/YYYY")}
      />
      {/* <InfoField
        title={t("personalInfo.account")}
        value={passType}
        rightClick={() => navigate(`/${ROUTE_BASE.SPORT_PASS}`)}
        rightTitle={t("personalInfo.gotoBuyPass")}
      /> */}
      <InfoField
        title={t("personalInfo.guardian")}
        value={
          i18n.language === "zh"
            ? currentUser?.guardian?.name_zh
            : currentUser?.guardian?.name_en
        }
        rightClick={() =>
          navigate(`/${i18n.language}/profile/guardian`, {
            state: {
              guardians: currentUser.guardian ? [currentUser.guardian] : null,
            },
          })
        }
        rightTitle={t("personalInfo.edit")}
      />
      <InfoField
        title={t("personalInfo.cyclingTest")}
        value={
          currentUser?.cyclingTest?.status == "PASS"
            ? t("personalInfo.pass")
            : t("personalInfo.n/a")
        }
      />

      <Typography color={theme.error} fontSize="14px" fontWeight="500">
        {t("index.deleteMyAccount")}
      </Typography>
      <Space size="15px" />
      <Typography
        fontSize="16px"
        color={theme.grey}
        style={{ display: "inline" }}
      >
        <Trans
          i18nKey="profile:index.pleaseContactStaffToDeleteAcc"
          components={{
            url: (
              <Typography
                color={theme.orange}
                style={{
                  textDecorationLine: "underline",
                  display: "inline",
                  cursor: "pointer",
                }}
                onClick={() => window.open("mailto:enquiry@goparksports.hk")}
              />
            ),
          }}
        />
      </Typography>
    </Card>
  );
};

export default PersonalInfo;
