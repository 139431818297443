import { SaiShaAPIFailureResponse } from "../@types/common";
import {
  NotificationCategoryTag,
  NotificationHistory,
} from "common/models/Notification";

export enum NotificationType {
  GENERAL = "general",
  MESSAGE = "message",
  CHATBOT = "chatbot"
}
export interface INotificationListViewModel {
  generalNotificationList: NotificationHistory[];
  messageNotificationList: NotificationHistory[];
  generalNotificationListHasNextPage: boolean;
  messageNotificationListHasNextPage: boolean;
  getGeneralNotificationList: (options?: {
    handledLoading?: boolean;
    isRefresh?: boolean;
    nextPage?: boolean;
  }) => Promise<void>;
  getMessageNotificationList: (options?: {
    handledLoading?: boolean;
    isRefresh?: boolean;
    nextPage?: boolean;
  }) => Promise<void>;
}

export interface INotificationDetailViewModel {
  notificationDetail: NotificationHistory | null;
  getNotificationDetail: () => Promise<
    | { success: boolean; result: NotificationHistory }
    | SaiShaAPIFailureResponse<object>
  >;
  getProgramType: () => NotificationCategoryTag | null;
}
