import styled from "styled-components";
import theme from "utils/theme";

const SimpleModalCotnainer = styled.div`
  background-color: ${theme.white};
  width: fit-content;
  height: auto;
  min-width: 250px;
  border-radius: 30px;
  padding: 20px;
`;

export default { SimpleModalCotnainer };
