import styled from "styled-components";
import theme from "utils/theme";

const OrderList = styled.ol`
  counter-reset: item;
  margin-left: 15px;
  color: ${theme.primary};
  font-family: Poppins;
`;

const ListItem = styled.li`
  display: block;
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;

  &:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
    margin-right: 15px;
  }
`;

const TitleListItem = styled(ListItem)`
  line-height: 3;
  font-weight: 500;
  font-size: 20px;
  font-family: Poppins;
`;

export default {
  OrderList,
  TitleListItem,
  ListItem,
};
