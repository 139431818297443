import * as yup from "yup";

export const isEmail = (email: string) =>
  new Promise((resolve, reject) => {
    yup
      .string()
      .email()
      .validate(email, { strict: true })
      .then(() => resolve(true))
      .catch(() => resolve(false));
  });
