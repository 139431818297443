import { useState } from "react";
import { appearGlobalError } from "../../context/requests/globalRequest";
import {
  listCoachLesson,
  listCoachLessonByCategory,
} from "../../network/coachLesson";
import _ from "lodash";

export const useCoachLessonListViewModel = ({categoryId, dispatch}) => {
  const [coachLessonList, setCoachLessonList] = useState([]);

  const getCoachLessonList = async () => {
    try {
      var res = {};
      if (!_.isEmpty(categoryId))
        res = await listCoachLessonByCategory({ categoryId: categoryId });
      else res = await listCoachLesson();
      setCoachLessonList(res);
    } catch (e) {
      if (dispatch) appearGlobalError(dispatch, e?.response?.data?.msg);
    }
  };

  return {
    coachLessonList,
    getCoachLessonList,
  };
};
