import React, { useMemo } from "react";
import Styled from "./Styled";
import MyPastListingCardStyled from "../MyPastListingCard/Styled";
import LessonAttendanceItem, {
  LessonAttendanceProps,
} from "./LessonAttendanceItem";
import { ProgramBookingLesson } from "models/Programme";
import { isDate } from "lodash";
import dayjs from "common/utils/dayjs";
import { useTranslation } from "react-i18next";
import { PastBookingProps } from "..";
import MyPastListingCard from "../MyPastListingCard/MyPastListingCard";
import { ProgrammeLessonAttendanceStatus } from "common/models/MonthlyProgram";

type Props = {
  programTitle: string;
  classTitle: string;
  startDate: string | Date;
  endDate: string | Date;
  coachNames: string[];
  lessons: ProgramBookingLesson[];
  onClick?: () => void;
} & PastBookingProps;

const MyProgramListingCard = (props: Props) => {
  const {
    programTitle,
    classTitle,
    startDate,
    endDate,
    coachNames,
    lessons,
    onClick,
    status,
    isPastRecord,
  } = props;

  const { t } = useTranslation("myBooking");

  const formattedStartDate = useMemo(() => {
    if (isDate(startDate)) {
      return {
        date: dayjs(startDate).tz().format("DD/MM/YYYY"),
        time: dayjs(startDate).tz().format("HH:mm"),
      };
    }
    return { date: startDate };
  }, [startDate]);
  const formattedEndDate = useMemo(() => {
    if (isDate(endDate)) {
      return {
        date: dayjs(endDate).tz().format("DD/MM/YYYY"),
        time: dayjs(endDate).tz().format("HH:mm"),
      };
    }
    return { date: endDate };
  }, [endDate]);

  return isPastRecord ? (
    <MyPastListingCard title={programTitle} status={status} onClick={onClick}>
      <div>{classTitle}</div>
      <div>{`${formattedStartDate.date}-${formattedEndDate.date}, ${formattedStartDate.time}-${formattedEndDate.time}`}</div>
      {coachNames.map((name, idx) => {
        return <div key={idx}>{name}</div>;
      })}
      <Styled.Divider />
      <div className="h-[20px]"></div>
      {lessons.length > 0 && (
        <MyPastListingCardStyled.TitleText>
          {t("detail.checkInRecords")}
        </MyPastListingCardStyled.TitleText>
      )}
      {lessons.map((lessonWithAttendance, i) => {
        const attendanceProps: LessonAttendanceProps = {
          date: lessonWithAttendance.startTime,
          startTime: lessonWithAttendance.startTime,
          endTime: lessonWithAttendance.endTime,
          status: lessonWithAttendance?.status,
          attendanceStatus: lessonWithAttendance.attendance?.status,
          sickLeave: lessonWithAttendance.attendance?.sickLeave,
          venues: lessonWithAttendance.venues,
        };
        return (
          <LessonAttendanceItem
            key={lessonWithAttendance._id}
            {...attendanceProps}
            className="tile mt-2"
          />
        );
      })}
    </MyPastListingCard>
  ) : (
    <Styled.Card onClick={onClick}>
      <div className="w-full px-[20px] pt-[22px] pb-[28px] flex flex-col">
        <Styled.TitleText>{programTitle}</Styled.TitleText>
        <div className="h-[5px]"></div>
        <Styled.BodyText>{classTitle}</Styled.BodyText>
        <Styled.BodyText>{`${formattedStartDate.date}-${formattedEndDate.date}`}</Styled.BodyText>
        {coachNames.map((name, idx) => {
          return <Styled.BodyText key={idx}>{name}</Styled.BodyText>;
        })}
        <div className="h-[20px]"></div>
        <Styled.Divider />
        <div className="h-[20px]"></div>
        {lessons.length > 0 && (
          <Styled.SubTitleText>
            {t("detail.checkInRecords")}
          </Styled.SubTitleText>
        )}
        <Styled.ClassRoundedContainer
          remainder={lessons.length % 3}
          className="mt-[5px]"
        >
          {lessons.map((lessonWithAttendance, i) => {
            const attendanceProps: LessonAttendanceProps = {
              date: lessonWithAttendance.startTime,
              startTime: lessonWithAttendance.startTime,
              endTime: lessonWithAttendance.endTime,
              status: lessonWithAttendance?.status,
              attendanceStatus: lessonWithAttendance.attendance?.status,
              sickLeave: lessonWithAttendance.attendance?.sickLeave,
              venues: lessonWithAttendance.venues,
            };
            return (
              <LessonAttendanceItem
                key={lessonWithAttendance._id}
                {...attendanceProps}
                className="tile text-[12px]"
              />
            );
          })}
        </Styled.ClassRoundedContainer>
      </div>
    </Styled.Card>
  );
};

export default MyProgramListingCard;
