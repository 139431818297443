import { LatestNew } from "common/models/LatestNew";
import { instance } from ".";

export async function getBanners() {
  const res = await instance.get("/v1/app/banner");
  console.log("getBanners res: ", res.data, instance.getUri());
  return res.data;
}

export async function getHeadlineNews({ limit, skip = 0 }) {
  const payload = {
    limit,
    skip,
  };
  console.log("check 4 ", new URLSearchParams(payload).toString());
  const res = await instance.get(
    "v1/app/news/headlineNews/list?" + new URLSearchParams(payload).toString()
  );
  console.log("getHeadlineNews res: ", res.data, instance.getUri());
  return res.data;
}

export const getHeadlineNewsById = async (id) => {
  const res = await instance.get(`v1/app/news/headlineNews/${id}`);
  console.log("getHeadlineNews res: ", res.data, instance.getUri());
  return res?.data;
}

export async function getLatestNews({ limit, skip = 0, title = "" }) {
  const payload = {
    limit,
    skip,
    title,
  };
  const res = await instance.get(
    "v1/app/news/latestNews/list?" + new URLSearchParams(payload).toString()
  );
  console.log("getLatestNews res: ", res.data, instance.getUri());
  return res.data;
}

export const getLatestNewsById = async (id) => {
  const res = await instance.get(`v1/app/news/latestNews/${id}`);
  console.log("getLatestNewsById res: ", res.data, instance.getUri());
  return res?.data
}
