import { isEmptyValues, negativeToZero } from "../../utils/common";
import {
  ApplyPromoCodeRes,
  CheckPromoCodeAvailabilityRes,
  EstimatePromoCodeDiscountRes,
} from "./PromoCodeReponse";

export enum PromoCodeTypes {
  CONSTANT = "CONSTANT",
  PERCENTAGE = "PERCENTAGE",
}

export enum PromoCodeDiscountType {
  PRICE = "PRICE",
  PERCENTAGE = "PERCENTAGE",
}

export enum PromoCodeBookingType {
  FACILITY = "FACILITY",
  COACH = "COACHLESSONS",
  PROGRAMME = "PROGRAMMES",
  EVENT = "EVENTS",
}

// view model level (copied from monthlyProgrammeViewModel)
export interface IPromoCode {
  code: string;
  type: PromoCodeDiscountType;
  discountAmount: number;
  orderDiscountedAmount: number;
  orderEnjoyedDiscount: number;

  readonly discount: string; // Discount display in string
  readonly discountedAmount: number;
  readonly enjoyedDiscount: number;
}

export class PromoCode implements IPromoCode {
  code: string;
  type: PromoCodeDiscountType;
  discountAmount: number;
  orderDiscountedAmount: number;
  orderEnjoyedDiscount: number;

  constructor(promoCodeRes: CheckPromoCodeAvailabilityRes) {
    this.code = promoCodeRes.promoCode;
    this.type = promoCodeRes.discountType;
    this.discountAmount = promoCodeRes.discountAmount;
    this.orderDiscountedAmount = negativeToZero(
      promoCodeRes.orderDiscountedAmount
    );
    this.orderEnjoyedDiscount = negativeToZero(
      promoCodeRes.orderEnjoyedDiscount
    );
  }

  get discount(): string {
    return this.type == PromoCodeDiscountType.PERCENTAGE
      ? `${this.discountAmount}% OFF`
      : `-HK$${this.discountAmount / 100}`;
  }
  get discountedAmount(): number {
    return negativeToZero(this.orderDiscountedAmount / 100);
  }
  get enjoyedDiscount(): number {
    return negativeToZero(this.orderEnjoyedDiscount / 100);
  }
}

// return from api level
export interface PromoCodeDocument {
  // from api
  discountAmount: number;
  discountPercentage?: number;
  finalPrice: number;
  code: string;
  discountType: PromoCodeDiscountType;
}
