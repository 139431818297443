import React, { useMemo } from "react";
import dayjs from "common/utils/dayjs";
import Styled from "./Styled";
import { useTranslation } from "react-i18next";
import CardBase, { VoucherListCardBaseProps } from "./CardBase";
import { ReactComponent as BadgeActiveSvg } from "assets/bundle-offer-badge-active.svg";
import { ReactComponent as BadgeInActiveSvg } from "assets/bundle-offer-badge-inactive.svg";

export type AdmissionVoucherCardProps = VoucherListCardBaseProps & {
  disableBundle: boolean;
  startDate: Date | string;
  endDate: Date | string;
  expiredDate?: string | Date;
  redeemedDate?: string | Date;
  voucherId?: string;
  onRedeemClick?: () => void;
};

const AdmissionVoucherCard = (props: AdmissionVoucherCardProps) => {
  const {
    voucherId,
    startDate,
    endDate,
    expiredDate,
    redeemedDate,
    disabled,
    disableBundle,
    onRedeemClick,
    ...cardBaseProps
  } = props;

  const { t } = useTranslation();

  const startDateInString = useMemo(() => {
    if (startDate == null) {
      return "";
    }
    return dayjs(startDate).tz().format("DD/MM/YYYY");
  }, [startDate]);

  const endDateInString = useMemo(() => {
    if (endDate == null) {
      return "";
    }
    return dayjs(endDate).tz().format("DD/MM/YYYY");
  }, [endDate]);

  const expiryDateInString = useMemo(() => {
    if (expiredDate == null) {
      return "";
    }
    return dayjs(expiredDate).tz().format("DD/MM/YYYY");
  }, [expiredDate]);

  const redeemedDateInString = useMemo(() => {
    if (redeemedDate == null) {
      return "";
    }
    return dayjs(redeemedDate).tz().format("DD/MM/YYYY");
  }, [redeemedDate]);

  return (
    <CardBase {...cardBaseProps} disabled={disabled}>
      <div className="content h-full flex flex-col lg:flex-row lg:justify-between">
        <div className="detail-left flex flex-col">
          {voucherId && (
            <pre>{t("voucher:detail.ref", { ref: voucherId })}</pre>
          )}

          <p>{t("voucher:restriction.admission")}</p>

          {disabled && (
            <p className="mt-[10px]">
              {redeemedDate == null
                ? t("voucher:detail.expiry").replace(
                  "{{date}}",
                  expiryDateInString
                )
                : t("voucher:detail.redeem").replace(
                  "{{date}}",
                  redeemedDateInString
                )}
            </p>
          )}
          {onRedeemClick == null ? null : (
            <>
              <div className="h-[20px]" />
              <div className="">
                <Styled.RedeemButton
                  type="button"
                  disabled={disabled}
                  onClick={onRedeemClick}
                >
                  {t("voucher:button.redeem")}
                </Styled.RedeemButton>
              </div>
            </>
          )}
        </div>
        <div className="h-[20px] lg:hidden" />
        <div className="detail-right flex-shrink-0 flex flex-col lg:items-end justify-between">
          {disabled === true ? null : (
            <Styled.ExpiryLabel className="mr-[15px]">
              <span>
                {/** Prevent html escape special character */}
                {t("voucher:detail.start").replace(
                  "{{date}}",
                  startDateInString
                )}
              </span>
              <br />
              <span>
                {/** Prevent html escape special character */}
                {t("voucher:detail.end").replace("{{date}}", endDateInString)}
              </span>
              <br />
              <span>
                {/** Prevent html escape special character */}
                {t("voucher:detail.expiry").replace(
                  "{{date}}",
                  expiryDateInString
                )}
              </span>
            </Styled.ExpiryLabel>
          )}
          {disableBundle ? null : disabled ? (
            <BadgeInActiveSvg />
          ) : (
            <BadgeActiveSvg />
          )}
        </div>
      </div>
      <div className="h-[25px]" />
    </CardBase>
  );
};

export default AdmissionVoucherCard;
