import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";
import theme from "utils/theme";

export const Wapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  border-radius: 30px;
  margin: 0px auto;
  flex-wrap: nowrap;
  flex-direction: column;
  z-index: 2;
  @media ${breakpoint.mobile} {
    padding: 0px;
  }
`;

export const Container = styled.div<{ gap?: string }>`
  width: 100%;
  background-color: #eff0e8;
  padding: 100px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${(p) => (p.gap ? p.gap : "30px")};
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  @media ${breakpoint.mobile} {
    padding: 30px 0px;
    gap: 10px;
  }
`;

export const ItemBox = styled.div`
  width: 210px;
  height: 210px;
  overflow: hidden;
  position: relative;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }

  @media ${breakpoint.mobile} {
    width: 110px;
    height: 100px;
  }
`;

export const ItemImg = styled.div<{ bg: string }>`
  width: 170px;
  height: 170px;
  background-image: url(${(p) => p.bg});
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 40px;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
    border-radius: 40px;
  }

  @media ${breakpoint.mobile} {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    &:before {
      border-radius: 20px;
    }
  }
`;

export const ItemContent = styled.div`
  height: 100%;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  cursor: pointer;
  @media ${breakpoint.mobile} {
    font-size: 14px;
  }
`;

export const ItemWapper = styled.div<{ gap?: string }>`
  display: flex;
  width: 100%;
  float: left;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${(p) => (p.gap ? p.gap : "30px")};
`;

export const BoxItem = styled.div`
  display: block;
  width: calc(25% - 22.5px);
  height: 305px;
  background: #fff;
  border-radius: 10px;
  border-width: 0px 4px 4px 0px;
  border-style: solid;
  border-color: ${theme.orange};
  transition: all 0.2s;
  padding: 5px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
  }

  @media (max-width: 1199px) {
    width: calc(33.33% - 22.5px);
  }

  @media (max-width: 991px) {
    width: calc(50% - 20px);
  }

  @media ${breakpoint.mobile} {
    width: 100%;
    &:hover {
      box-shadow: none;
      transform: scale(1);
    }
  }
`;

export const ItemContentBox = styled.div`
  width: 100%;
  position: relative;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ItemTitle = styled.h3`
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  overflow: hidden;
  margin-bottom: 0px;
  text-align: center;
`;

export const ItemDes = styled.div`
  color: #8c8c8c;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  margin: 10px 0px;
  width: 100%;
  padding: 20px 0px;
  flex-direction: column;
`;

export const Paging = styled.div`
  display: block;
  text-align: center;
  margin: 50px 0px;
  width: 100%;
  float: left;
`;

export const PagingItem = styled.span`
  display: inline-block;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 38px;
  border: 1px solid #000;
  margin: 0px 5px;
  border-radius: 10px;
  cursor: pointer;
  &.active,
  &:hover {
    background: #c85d38;
    border-color: #c85d38;
    color: #fff;
  }

  &.active {
    cursor: default;
  }
  &.hide {
    display: none;
  }
`;

export const ItemAva = styled.div<{ bg?: string }>`
  ${(p) => (p.bg ? "background-image: url(" + p.bg + ");" : "")}
  background-size: cover;
  height: 170px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
`;

export const Pin = styled.div`
  width: 45px;
  height: 45px;
  background-color: #fa804c;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
