import { useFormik } from "formik";
import { Voucher } from "models/Voucher";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import { IPromoCode } from "common/models/PromoCode";
import GeneralButton from "../common/Button";
import SecondaryButton from "../common/SecondaryButton";
import { useModal } from "hooks/useModal";
import BookingVoucherModal from "components/modals/BookingVoucherModal/BookingVoucherModal";
import {
  VoucherListingBaseCard,
  useVoucherCardModel,
} from "components/VoucherListingCard";
import dayjs from "common/utils/dayjs";
import TncLabel from "components/TncLabel";
import _ from "lodash";
import theme from "utils/theme";
import IconCircleClose from "assets/ic-circleClose.svg";

type PromoCodeOptions =
  | {
    code?: IPromoCode | null;
    onApplyPromoCode: (code: string) => Promise<void>;
    onRemovePromoCode: () => void;
  }
  | {
    options: { display: { promoCode: false } };
    code?: undefined;
    onApplyPromoCode?: undefined;
    onRemovePromoCode?: undefined;
  };

type VoucherOptions =
  | {
    voucherDisabled?: boolean;
    availableVouchers?: Voucher[];
    selectedVouchers?: Voucher[];
    refreshVoucherList?: () => void;
    onApplyVoucher: (voucher: Voucher) => Promise<void>;
    onRemoveVoucher: (vouchers: Voucher | Voucher[]) => void;
  }
  | {
    options: { display: { voucher: false } };
    voucherDisabled?: undefined;
    availableVouchers?: undefined;
    selectedVouchers?: undefined;
    refreshVoucherList?: undefined;
    onApplyVoucher?: undefined;
    onRemoveVoucher?: undefined;
  };

type PromoCodeBoxDisplayOptions = {
  promoCode: boolean;
  voucher: boolean;
  withBackground: boolean;
};

type PromoCodeBoxOptions = {
  display: Partial<PromoCodeBoxDisplayOptions>;
};

const defaultOptions: PromoCodeBoxOptions = {
  display: {
    promoCode: true,
    voucher: true,
    withBackground: true,
  },
};

type PromoCodeBoxProps = PromoCodeOptions &
  VoucherOptions & {
    options?: PromoCodeBoxOptions;
  } & React.HTMLAttributes<HTMLElement>;

const PromoCodeBox = (props: PromoCodeBoxProps) => {
  const {
    /**
     * promo code props
     */
    code,
    onApplyPromoCode,
    onRemovePromoCode,
    /**
     * voucher props
     */
    voucherDisabled = false,
    availableVouchers,
    selectedVouchers = [],
    onApplyVoucher,
    onRemoveVoucher,
    refreshVoucherList,
    options = defaultOptions,
    ...otherProps
  } = props;
  const { t } = useTranslation();
  const fullOptions = _.defaultsDeep(options, defaultOptions);

  const { modalOpen, handleModalOpen, handleModalClose } = useModal();

  const promoCodeForm = useFormik({
    initialValues: {
      promoCode: "",
    },
    onSubmit: async (values, helper) => {
      if (code) {
        if (onRemovePromoCode != null) {
          await onRemovePromoCode();
        }
        helper.resetForm();
      } else if (values.promoCode) {
        if (onApplyPromoCode != null) {
          await onApplyPromoCode(values.promoCode);
        }
        helper.resetForm();
      }
    },
  });

  const _handleApplyVoucher = useCallback(
    async (voucher: Voucher) => {
      if (onApplyVoucher != null) {
        await onApplyVoucher(voucher);
      }
      handleModalClose();
    },
    [onApplyVoucher, handleModalClose]
  );

  const _handleRemoveVoucher = useCallback(
    (voucher: Voucher) => {
      if (onRemoveVoucher != null) {
        onRemoveVoucher(voucher);
      }
    },
    [onRemoveVoucher]
  );

  useEffect(() => {
    if (modalOpen && refreshVoucherList != null) {
      refreshVoucherList();
    }
  }, [modalOpen, refreshVoucherList]);

  useEffect(() => {
    if (refreshVoucherList != null) {
      refreshVoucherList();
    }
  }, [refreshVoucherList]);

  return (
    <div>
      <Paragraph {...otherProps}>
        <PromoBox isBackground={fullOptions.display.withBackground}>
          <form onSubmit={promoCodeForm.handleSubmit} className="w-full">
            {fullOptions.display.voucher === false ? null : (
              <React.Fragment>
                <Row style={{ color: "#4A4A4A" }}>
                  {t("event:booking.voucher")}
                </Row>
                <Row>
                  <PromoAddVoucherButton
                    type="button"
                    onClick={handleModalOpen}
                    disabled={voucherDisabled}
                  >
                    {t("event:booking.addVoucher")}
                  </PromoAddVoucherButton>
                </Row>
                {selectedVouchers.length > 0 && (
                  <Row>
                    <div className="w-full bg-[#E0E0E0] p-[22px_20px] rounded-[5px] flex flex-col md:flex-row gap-[20px] overflow-auto">
                      {selectedVouchers.map((each) => (
                        <SelectedVoucherCard
                          key={each.voucherId}
                          voucher={each}
                          onRemoveClick={() => _handleRemoveVoucher(each)}
                        />
                      ))}
                    </div>
                  </Row>
                )}
              </React.Fragment>
            )}

            <Hr style={{ marginBottom: "20px" }} />

            {fullOptions.display.promoCode === false ? null : (
              <React.Fragment>
                <Row style={{ color: "#4A4A4A", marginBottom: "20px" }}>
                  {t("event:booking.enterPromoCode")}
                </Row>
                <div className="w-full md:max-w-[330px]">
                  <InputBox>
                    <Input
                      name="promoCode"
                      placeholder={t("event:booking.promoCode")}
                      value={promoCodeForm.values.promoCode}
                      onChange={promoCodeForm.handleChange}
                    />
                    {code ? (
                      <SecondaryButton
                        disabled={!!promoCodeForm.isSubmitting}
                        style={{ height: 40 }}
                        onClick={() => promoCodeForm.handleSubmit()}
                      >
                        {t("booking:bookingDetail.remove")}
                      </SecondaryButton>
                    ) : (
                      <div className="">
                        <GeneralButton
                          disabled={!!promoCodeForm.isSubmitting}
                          style={{ height: 40 }}
                          onClick={() => promoCodeForm.handleSubmit()}
                        >
                          {t("booking:bookingDetail.apply")}
                        </GeneralButton>
                      </div>
                    )}
                  </InputBox>
                  {code && (
                    <AppliedPromoCodeBox>
                      <div>{code.code}</div>
                      <div className="size=[12px] text-[#8C8C8C]">
                        -{" "}
                        {t("common:price", {
                          price: code.enjoyedDiscount,
                        })}
                      </div>
                    </AppliedPromoCodeBox>
                  )}
                </div>
              </React.Fragment>
            )}
          </form>
        </PromoBox>
      </Paragraph>
      <BookingVoucherModal
        open={modalOpen && voucherDisabled !== true}
        onClose={handleModalClose}
        bookingVouchers={availableVouchers ?? []}
        appliedVouchers={selectedVouchers}
        applyVoucher={_handleApplyVoucher}
      />
    </div>
  );
};

/**
 * private/internal component, do not export it
 */
const SelectedVoucherCard = ({
  voucher,
  onRemoveClick,
}: {
  voucher: Voucher;
  onRemoveClick?: () => void;
}) => {
  const { t } = useTranslation();
  const { getVoucherCardProps } = useVoucherCardModel();
  const cardProps = getVoucherCardProps(voucher);

  return (
    <div className="relative w-full flex-shrink-0 md:w-[201px]">
      <VoucherListingBaseCard>
        <div className="px-[19px] pb-[17px] text-xs font-normal leading-4 tracking-[0px] text-left text-app-black">
          <h1 className="text-[color:var(--palette-ss-000,#000)] text-base not-italic font-medium leading-[26.491px]">
            {typeof cardProps.title !== "number"
              ? cardProps.title
              : t("voucher:detail.amount", {
                amount: cardProps.title,
              })}
          </h1>
          <div className="h-[15px]" />
          <p>{cardProps?.description}</p>
          <div className="h-[14px]" />
          {cardProps.expiredDate != null && (
            <span className="inline-block px-[7.62px] py-[3.05px] rounded-[11.43px] bg-[#EAEBE6]">
              <span>
                {t("voucher:detail.expiry").replace(
                  "{{date}}",
                  dayjs(cardProps.expiredDate).tz().format("DD/MM/YYYY")
                )}
              </span>
            </span>
          )}
          <div className="h-3" />
          {voucher.tnc != null && <TncLabel data={voucher.tnc} />}
          <button
            type="button"
            className="absolute top-[13px] right-[13px]"
            onClick={onRemoveClick}
          >
            <img src={IconCircleClose} />
          </button>
        </div>
      </VoucherListingBaseCard>
    </div>
  );
};

const AppliedPromoCodeBox = styled.div`
  margin: 32px 0;
  background: #f7f7f7;
  border: 1px #0096b7 solid;
  border-radius: 5px;
  padding: 12px 14px;
`;
const Row = styled.div`
  display: block;

  margin: 10px 0px;
  width: 100%;
`;

const Paragraph = styled.div`
  display: block;
  margin-bottom: 30px;
  width: 100%;
`;

const Hr = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background: #ddd;
  margin: 30px 0px 40px;
`;

const PromoBox = styled.div<{ isBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: ${(props) => (props.isBackground ? "20px" : "none")};
  background: ${(props) => (props.isBackground ? "#ebebeb" : "none")};
  border-radius: 5px;
  @media ${breakpoint.mobile} {
    padding: 10px 20px;
    border: none;
    border-radius: 0px;
  }
`;

const InputBox = styled.div`
  display: flex;
  /* width: 330px; */

  position: relative;
  @media ${breakpoint.mobile} {
    width: 100%;
    flex-direction: column;
  }
`;

const Input = styled.input`
  display: block;
  height: 40px;
  flex: 1;

  line-height: 40px;
  background: #fff;
  padding: 0px 0px 0px 20px;
  border-radius: 5px;
  border: none;
  outline-color: ${theme.blue};
  @media ${breakpoint.mobile} {
    padding: 0px 20px 0px 20px;
    margin-bottom: 20px;
  }
`;

const ApplyButton = styled.button`
  display: block;
  width: 90px;
  height: 40px;

  text-align: center;
  line-height: 40px;
  background: #0096b7;
  border-radius: 5px;
  //position: absolute;
  right: 0px;
  top: 0px;
  color: #fff;
  @media ${breakpoint.mobile} {
    position: static;
  }
`;

const PromoAddVoucherButton = styled.button`
  display: inline;
  font-size: 18px;
  color: ${theme.orange};
  text-decoration: underline;
  cursor: pointer;

  &:disabled {
    color: var(--palette-ss-grey-text);
    cursor: not-allowed;
  }
`;

export default PromoCodeBox;
