import i18n from "i18n";

export class Coach {
  constructor({
    _id,
    coach_name_en,
    coach_name_zh,
    coach_title_en,
    coach_title_zh,
    profile_pic_ref,
  }) {
    this._id = _id;
    this.coach_name_en = coach_name_en;
    this.coach_name_zh = coach_name_zh;
    this.coach_title_en = coach_title_en;
    this.coach_title_zh = coach_title_zh;
    this.profile_pic_ref = profile_pic_ref;

    this.getName = this.getName.bind(this);
    this.getTitle = this.getTitle.bind(this);
  }

  getName() {
    return i18n.language === "en" ? this.coach_name_en : this.coach_name_zh;
  }

  getTitle() {
    return i18n.language === "en" ? this.coach_title_en : this.coach_title_zh;
  }
}
