import styled from "styled-components";
import Typography from "components/common/Typography";
import theme from "utils/theme";
import { getLocalisedString } from "common/utils/stringHelper";
import React from "react";
import { breakpoint } from "utils/responsive";

interface ICategory {
  thumbnail_photo_ref: string;
  name_en: string;
  name_tc: string;
  shouldGroupByCategory?: boolean;
  category?: {
    name_en: string;
    name_tc: string;
    thumbnail_photo_ref: string;
  };
}

interface CellProps {
  category?: ICategory;
  idx: number;
  onClick?: () => void;
  width?: number;
}

const RotateContainer = styled.div<{ width?: number }>`
  transform: rotate(45deg);
  border-radius: 25%;
  overflow: hidden;
  max-height: 150px;
  max-width: 150px;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.width}px`};
  aspect-ratio: 1;
  position: relative;
  z-index: 99;
`;

const Image = styled.img`
  object-fit: cover;

  height: 100%;
  width: 100%;
  height: 100%;
  transform: rotate(-45deg) scale(1.5);
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
  transition: 0.2s linear;
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  position: relative;
  max-height: 150px;
  max-width: 150px;
  min-height: 50px;
  min-width: 50px;
`;

const BackgroundShadow = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  height: 100%;
  width: 100%;
  transform: rotate(45deg);
  border-radius: 25%;
  position: absolute;
  bottom: -3%;
  right: -3%;
  z-index: 1;
`;

const Title = styled(Typography)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: ${theme.white};

    @media ${breakpoint.mobile} {
      font-size: 14px;
    }
  }
`;

const OuterContainer = styled.div`
  position: relative;
  width: fit-content;
  height: auto;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const GradientOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #000000;
  opacity: 0.5;
`;

const CategoryCell = ({ category, idx, onClick, width }: CellProps) => {
  const getShadowColor = () => {
    switch (idx % 10) {
      case 0:
        return "#E5DC93";
      case 1:
        return "#6A8EC3";
      case 2:
        return "#706A6A";
      case 3:
        return "#E88DD4";
      case 4:
        return "#91A97C";
      case 5:
        return "#E19753";
      case 6:
        return "#EDE490";
      case 7:
        return "#BE99A0";
      case 8:
        return "#68CAD0";
      case 9:
        return "#467635";
      default:
        return "#E5DC93";
    }
  };

  return (
    <OuterContainer onClick={onClick}>
      <Container>
        <RotateContainer width={width}>
          <Image
            src={
              category?.shouldGroupByCategory
                ? category?.category?.thumbnail_photo_ref
                : category?.thumbnail_photo_ref
            }
          />
          <GradientOverlay />
        </RotateContainer>
        <BackgroundShadow color={getShadowColor()} />
        <Title>
          {category?.shouldGroupByCategory
            ? getLocalisedString(
                category?.category?.name_en,
                category?.category?.name_tc
              )
            : getLocalisedString(category?.name_en, category?.name_tc)}
        </Title>
      </Container>
    </OuterContainer>
  );
};

export default CategoryCell;
