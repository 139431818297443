import React from "react";
import {
  VoucherCategory,
  VoucherType,
} from "common/viewModels/VoucherViewModel";
import {
  CashVoucher,
  CoachVoucher,
  EventVoucher,
  ExtractVoucherType,
  FacilityVoucher,
  Voucher,
} from "models/Voucher";
import { useTranslation } from "react-i18next";
import { escapeHTMLtoPlainText } from "utils/common";
import { getLocalizedString } from "utils/locale";
import { CashVoucherCardProps } from "./CashVoucherCard";
import { FacilityVoucherCardProps } from "./FacilityVoucherCard";
import { PassVoucherCardProps } from "./PassVoucherCard";
import { CoachVoucherCardProps } from "./CoachVoucherCard";
import dayjs from "common/utils/dayjs";
import _ from "lodash";
import { EventVoucherProps } from "./EventVoucherCard";

const useVoucherCardModel = () => {
  const { t } = useTranslation();
  const getVoucherCardProps = (voucher: Voucher) => {
    switch (voucher.voucher?.type) {
      case VoucherType.CASH: {
        const cashVoucher: ExtractVoucherType<CashVoucher> = voucher as any;
        const categoryIsGeneral = Object.values(VoucherCategory).includes(
          cashVoucher?.voucher?.category
        );
        const restriction = categoryIsGeneral
          ? t(`voucher:restriction.${cashVoucher?.voucher?.category}`)
          : t(`voucher:restriction.specific`, {
              type: cashVoucher?.voucher?.category,
            });
        const description = escapeHTMLtoPlainText(
          getLocalizedString(
            voucher.descriptionEn || "",
            voucher.descriptionTc || ""
          )
        );

        return {
          voucherId: voucher.voucherId,
          description: restriction + "\n" + description,
          title: cashVoucher.voucher.value,
          expiredDate: voucher.expiredAt,
          redeemedDate: voucher.redeemedAt,
          disableBundle: _.isEmpty(voucher.packageTransaction),
        } as CashVoucherCardProps;
      }
      case VoucherType.FACILITY: {
        const facilityVoucher: ExtractVoucherType<FacilityVoucher> =
          voucher as any;
        const restriction = t("voucher:restriction.specific", {
          type: getLocalizedString(
            facilityVoucher?.voucher?.category?.name_en,
            facilityVoucher?.voucher?.category?.name_tc
          ),
        });
        const timeSlot =
          facilityVoucher?.voucher?.peakHour &&
          facilityVoucher?.voucher?.normalHour
            ? t("voucher:restriction.timeslot.both")
            : facilityVoucher?.voucher?.peakHour
            ? t("voucher:restriction.timeslot.peak")
            : t("voucher:restriction.timeslot.normal");

        const description = escapeHTMLtoPlainText(
          getLocalizedString(
            facilityVoucher.descriptionEn || "",
            facilityVoucher.descriptionTc || ""
          )
        );

        return {
          voucherId: voucher.voucherId,
          title: getLocalizedString(
            facilityVoucher.voucher.category.name_en,
            facilityVoucher.voucher.category.name_tc
          ),
          description: restriction + "\n" + timeSlot + "\n" + description,
          expiredDate: facilityVoucher.expiredAt,
          redeemedDate: facilityVoucher.redeemedAt,
          disableBundle: _.isEmpty(voucher.packageTransaction),
        } as FacilityVoucherCardProps;
      }
      case VoucherType.COACH: {
        const coachVoucher: ExtractVoucherType<CoachVoucher> = voucher as any;
        const coachLessonType = coachVoucher.voucher.private
          ? t(`coaching:coachLessonType.1to1`)
          : coachVoucher.voucher.semi
          ? t(`coaching:coachLessonType.1to2`)
          : t(`coaching:coachLessonType.1to3`);
        const coachName = getLocalizedString(
          coachVoucher.voucher.coachlesson.coach_id.coach_name_en,
          coachVoucher.voucher.coachlesson.coach_id.coach_name_zh
        );
        const dateInString =
          coachVoucher.expiredAt == null
            ? ""
            : dayjs(coachVoucher.expiredAt).tz().format("DD/MM/YYYY (ddd)");
        return {
          voucherId: voucher.voucherId,
          title: coachName,
          description: coachLessonType + "\n" + dateInString,
          expiredDate: coachVoucher.expiredAt,
          redeemedDate: coachVoucher.redeemedAt,
          disableBundle: _.isEmpty(voucher.packageTransaction),
        } as CoachVoucherCardProps;
      }
      case VoucherType.DAY_PASS:
      case VoucherType.MONTHLY_PASS:
      case VoucherType.ANNUAL_PASS: {
        return {
          voucherId: voucher.voucherId,
          title: t(`voucher:type.${voucher?.voucher?.type}`),
          description: escapeHTMLtoPlainText(
            getLocalizedString(
              voucher.descriptionEn || "",
              voucher.descriptionTc || ""
            )
          ),
          expiredDate: voucher.expiredAt,
          redeemedDate: voucher.redeemedAt,
          disableBundle: _.isEmpty(voucher.packageTransaction),
        } as PassVoucherCardProps;
      }
      case VoucherType.EVENT: {
        const eventVoucher: ExtractVoucherType<EventVoucher> = voucher as any;

        return {
          voucherId: eventVoucher.voucherId,
          title: getLocalizedString(
            eventVoucher?.voucher?.eventType?.name_en,
            eventVoucher?.voucher?.eventType?.name_tc
          ),
          expiredDate: eventVoucher.expiredAt,
          disableBundle: _.isEmpty(eventVoucher.packageTransaction),
        } as EventVoucherProps;
      }

      default:
        return {
          title: null,
          description: null,
          expiredDate: null,
        };
    }
  };
  return { getVoucherCardProps };
};

export default useVoucherCardModel;
