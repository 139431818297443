import { Navigate, useRoutes } from "react-router-dom";
import EnquiryPage from "./EnquiryPage";
import EnquirySuccessPage from "./EnquirySuccessPage";

const EnquiryRouter = () => {
  return useRoutes([
    {
      path: "/",
      element: <EnquiryPage />,
    },
    {
      path: "/success",
      element: <EnquirySuccessPage />,
    },
    {
      path: "*",
      element: <Navigate to="../" />,
    },
  ]);
};

export default EnquiryRouter;
