import React, { useState, useEffect, useRef, useContext } from "react";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import IconLoader from "../../assets/IconLoader";
import styled from "styled-components";
import {
  useSearchParams,
  useLocation,
  useParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import PageHeader from "../../components/common/PageHeader";
import { useCoachLessonDetailViewModel } from "common/viewModels/CoachLessionBooking/CoachLessionDetailViewModel";
import {
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { ICoachLessonDetailViewModel } from "models/PrivateCoach";
import HTMLTypography from "components/common/HTMLTypography";
import CoachBookingBase from "pageComponents/privateCoach/CoachBookingBase";
import GeneralButton from "components/common/Button";
import Space from "components/common/Space";
import { ROUTE_BASE } from "Route";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import theme from "utils/theme";
import { getLocalisedString } from "common/utils/stringHelper";
import { getCorrectUrl } from "navigators/Router";
import Typography from "components/common/Typography";
import PageContainer from "components/common/PageContainer";
import CoachBanner from "assets/bg-coach-banner.png";
import { removeHtmlTag } from "utils/common";
import { getLocalizedPageTitleWithPrefix } from "utils/seo";

interface Item {
  title: string;
  id: number;
  des: string;
  img: string;
  date: string;
}

const CoachingDetailPage = () => {
  const { t, i18n } = useTranslation("coaching");
  const { id: categoryId, coachId } = useParams();
  const navigate = useNavigate();
  const { currentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { coachLesson, lessonTypeInfo, getCoachLesson } =
    useCoachLessonDetailViewModel({
      coachLessonId: coachId,
      dispatch: globalDispatch,
    }) as ICoachLessonDetailViewModel;

  useEffect(() => {
    getCoachLesson();
  }, []);

  const getMetaDescription = () => {
    const description = getLocalisedString(
      coachLesson?.desc_en,
      coachLesson?.desc_zh
    );
    return removeHtmlTag(description);
  };

  return _.isEmpty(coachLesson) || _.isEmpty(lessonTypeInfo) ? (
    <PageContainer
      title={t("privateCoachingList.screenHeader")}
      banner={CoachBanner}
    >
      <Typography>{t("common:comingSoon")}</Typography>
    </PageContainer>
  ) : (
    <>
      <Helmet>
        <title>
          {getLocalizedPageTitleWithPrefix({
            nameGetter: () => coachLesson?.coach?.getName(),
          })}
        </title>
        <meta name="description" content={getMetaDescription()} />
      </Helmet>
      <CoachBookingBase
        coachLesson={coachLesson}
        lessonTypeInfo={lessonTypeInfo}
      >
        <div className="flex justify-end">
          {/* <GeneralButton
            onClick={() =>
              navigate(
                {
                  pathname: getCorrectUrl(ROUTE_BASE.PACKAGE_OFFER),
                  search: createSearchParams({
                    coach: coachId ?? "",
                  }).toString(),
                },
                { state: { hideFilter: true } }
              )
            }
          >
            {t("coaching:privateCoachingInfo.btnBuyPackage")}
          </GeneralButton> */}
          <Space width="15px" />
          {currentUser && (
            <GeneralButton
              style={{ color: theme.blue, border: `1px solid ${theme.blue}` }}
              bgColor={theme.white}
              onClick={() => navigate(`../coach/${coachId}/booking`)}
            >
              {t("coaching:privateCoachingInfo.btnBookingNow")}
            </GeneralButton>
          )}
        </div>
      </CoachBookingBase>
    </>
  );
};

export default CoachingDetailPage;
