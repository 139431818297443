import GeneralButton from "components/common/Button";
import Form from "components/common/Form";
import PhoneNumberInput from "components/common/PhoneNumberInput";
import Space from "components/common/Space";
import LogoCard from "components/common/cards/LogoCard";
import { FormikHelpers } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InferType, object, string } from "yup";
import cn from "classnames";
import Typography from "components/common/Typography";
import { FormHelperText } from "@mui/material";
import { validatePassword } from "common/utils/stringHelper";
import { MobileVerificationResult } from "common/models/Verification";
import { useRegisterAccountViewModel } from "common/viewModels/RegisterAccountViewModel";
import { IRegistrationAccountViewModel } from "common/models/User";
import { getCountryOptionFromCountryCode } from "common/utils/countryHelper";
import { CountryCode } from "libphonenumber-js";
import GuardianLabel from "./Components/GuardianLabel";
import { ILoginInfo } from "./RegistrationStepControl";

const FormYupSchema = object({
  phoneNum: string().required(),
  region: string().required(),
  email: string().email().required(),
  password: string()
    .required()
    .test("valid-password", "not valid password", (value) => {
      return !!validatePassword(value);
    }),
  confirmPassword: string().required(),
});

type IDetailForm = InferType<typeof FormYupSchema>;

interface ILoginDetailProps {
  setCurrentStep: (step: number) => void;
  setLoginInfo: (loginInfo: ILoginInfo) => void;
  verificationMobileResult: MobileVerificationResult;
}

const LoginDetail = ({
  setCurrentStep,
  setLoginInfo,
  verificationMobileResult,
}: ILoginDetailProps) => {
  const { t } = useTranslation("login");
  const { validateLoginInfo } =
    useRegisterAccountViewModel() as IRegistrationAccountViewModel;
  const initialFormState = useMemo<IDetailForm>(
    () => ({
      phoneNum: verificationMobileResult?.phoneNum,
      region: verificationMobileResult?.dialCode,
      email: "",
      password: "",
      confirmPassword: "",
    }),
    []
  );

  const onLoginDetailFormSubmit = async (value: IDetailForm) => {
    console.log("submit Mobile otp", value);
    const { email, password } = value;
    const res = await validateLoginInfo({ email, password });

    if (res.success) {
      setLoginInfo({ email, password });
      setCurrentStep(3);
    }
  };

  return (
    <LogoCard title={t("complete_login_detail")}>
      <Form
        initialValues={initialFormState}
        validationSchema={FormYupSchema}
        onSubmit={onLoginDetailFormSubmit}
      >
        {(formik) => {
          const isPWMatch =
            formik.values.password === formik.values.confirmPassword;
          return (
            <Form.Element
              id="login_detail_form"
              className={cn(
                "w-full",
                "flex",
                "flex-col",
                "items-center",
                "gap-[30px]"
              )}
            >
              <div className="w-full">
                <div className="flex items-center justify-start w-full gap-[10px] mb-[10px]">
                  <Typography fontSize="12px">
                    {t("field.phone_number")}
                  </Typography>
                  <GuardianLabel
                    disabled={!verificationMobileResult?.skipPhoneVerification}
                  />
                </div>
                <PhoneNumberInput
                  phoneNum={formik.values.phoneNum}
                  region={formik.values.region}
                  disabled
                />
              </div>

              <Form.Field.TextField
                id="email"
                name="email"
                title={t("login:field.email")}
                placeholder={t("login:field.email")}
              />

              <Form.Field.TextField
                id="password"
                name="password"
                type="password"
                title={t("login:field.password")}
                placeholder={t("login:field.password")}
              />
              {formik.errors.password && (
                <FormHelperText error>
                  {t("register:err.invalid_password")}
                </FormHelperText>
              )}

              <Form.Field.TextField
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                title={t("login:field.confirm_pw")}
                placeholder={t("login:field.confirm_pw")}
              />

              {!isPWMatch && (
                <FormHelperText error>
                  {t("register:err.invalid_confirm_password")}
                </FormHelperText>
              )}

              <GeneralButton
                fullWidth
                type="submit"
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  formik.isSubmitting ||
                  !isPWMatch
                }
              >
                {t("common:button:submit")}
              </GeneralButton>
            </Form.Element>
          );
        }}
      </Form>
    </LogoCard>
  );
};

export default LoginDetail;
