import React from "react";
import styled from "styled-components";

type LabelType = {
  htmlFor: string;
  required: boolean;
  text: string;
};

const FormLabel = function (props: LabelType) {
  const { htmlFor, text, required } = props;
  return (
    <label htmlFor={htmlFor}>
      <StyledTextSpan>{text}</StyledTextSpan>
      {required && <StyledRequiredAsterisk>*</StyledRequiredAsterisk>}
    </label>
  );
};

const StyledTextSpan = styled.span`
  /* font-family: "Roboto"; */
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  white-space: nowrap;
`;

const StyledRequiredAsterisk = styled.span`
  color: red;
  margin-right: 5px;
  font-size: 13px;
  font-weight: 500;
`;

export default FormLabel;
