import styled from 'styled-components';

export const StyledField = styled.div`
  width: 100%;
`;

export const FieldError = styled.div`
  color: #FB522D;
  font-size: 14px;
`;
