import { IUserViewModelContext } from "../../@types/common";
import IconLoader from "assets/IconLoader";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { IGlobalProviderContext } from "common/models/Common";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import GeneralButton from "components/common/Button";
import Card from "components/common/Card";
import Space from "components/common/Space";
import Typography from "components/common/Typography";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";

const ChangePasswordSuccess = () => {
  const { t } = useTranslation("profile");
  const { globalDispatch } = useContext(
    GlobalContextStore as any
  ) as IGlobalProviderContext;
  const { logout } = useContext<IUserViewModelContext>(UserViewModelContext);

  return (
    <Card
      bgColor={theme.lightCyan}
      maxHeight={"none"}
      style={{ padding: 35, width: "100%" }}
    >
      <div className="flex flex-col md:flex-row w-full items-center justify-center">
        <IconLoader.IconSuccess />
        <Space width="80px" />
        <div className="flex flex-col items-center md:items-start">
          <Typography fontSize="40px" fontWeight="600">
            {t("changePassword.successTitle")}
          </Typography>
          <Space size="30px" />
          <Typography>{t("changePassword.successDesc")}</Typography>
          <Space size="30px" />
          <GeneralButton onClick={() => logout(globalDispatch)}>
            {t("changePassword.successBtn")}
          </GeneralButton>
        </div>
      </div>
    </Card>
  );
};

export default ChangePasswordSuccess;
