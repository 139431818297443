import CategoryCell from "./CategoryCell";
import { FacilityCategory } from "models/Facility";
import { Facility, IFacility } from "common/models/Facility";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import { ResponsiveUI, WindowSize } from "utils/responsive";
import { useTranslation } from "react-i18next";

interface CategoryProps {
  categoryList?: (FacilityCategory | IFacility)[];
  onClickCategory: (category: FacilityCategory | IFacility) => void;
  onClickExtraFacility?: () => void;
}

const CategoryList = ({
  categoryList,
  onClickCategory,
  onClickExtraFacility,
}: CategoryProps) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (
    <ResponsiveUI
      desktop={
        <div className="p-[30px]">
          <Grid
            container
            spacing={8}
            justifyContent="center"
            alignItems="center"
          >
            {categoryList?.map((category, idx) =>
              category.is_extra ? (
                <Grid item key={idx}>
                  <a
                    href={
                      i18n.language === "zh"
                        ? category?.external_url_zh
                        : category?.external_url_en
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CategoryCell width={150} category={category} idx={idx} />
                  </a>
                </Grid>
              ) : (
                <Grid item key={idx}>
                  <CategoryCell
                    width={150}
                    category={category}
                    idx={idx}
                    onClick={() => onClickCategory(category)}
                  />
                </Grid>
              )
            )}
          </Grid>
        </div>
      }
      mobile={
        <div className="px-[30px] pt-[130px] max-sm:pt-[30%]  pb-[30px]">
          <Grid
            container
            rowSpacing={4}
            justifyContent="start"
            alignItems="center"
          >
            {categoryList?.map((category, idx) =>
              category.is_extra ? (
                <Grid item key={idx}>
                  <a
                    href={
                      i18n.language === "zh"
                        ? category?.external_url_zh
                        : category?.external_url_en
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CategoryCell category={category} idx={idx} />
                  </a>
                </Grid>
              ) : (
                <Grid item key={idx} xs={4}>
                  <div
                    style={
                      idx % 3 !== 1 ? { transform: "translateY(-65%)" } : {}
                    }
                  >
                    <CategoryCell
                      category={category}
                      idx={idx}
                      onClick={() => onClickCategory(category)}
                    />
                  </div>
                </Grid>
              )
            )}
          </Grid>
        </div>
      }
    />
  );
};

export default CategoryList;
