import i18n from "i18n";

export class Venue {
  constructor({
    _id,
    name_en,
    name_tc,
    facility_id,
    thumbnail_photo_ref,
    zone,
    desc_en,
    desc_tc,
    ref_number,
    status,
    venueId,
    created_time,
    created_by,
    updated_time,
    updated_by,
  }) {
    this._id = _id;
    this.name_en = name_en;
    this.name_tc = name_tc;
    this.facility_id = facility_id;
    this.thumbnail_photo_ref = thumbnail_photo_ref;
    this.zone = zone;
    this.desc_en = desc_en;
    this.desc_tc = desc_tc;
    this.ref_number = ref_number;
    this.status = status;
    this.venueId = venueId;
    this.created_time = created_time;
    this.created_by = created_by;
    this.updated_time = updated_time;
    this.updated_by = updated_by;
    this.getName = this.getName.bind(this);
    this.getDescription = this.getDescription.bind(this);
  }

  getName() {
    return i18n.language === "en" ? this.name_en : this.name_tc;
  }

  getDescription() {
    return i18n.language === "en" ? this.desc_en : this.desc_tc;
  }
}
