import { HeadlineNews } from "models";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { IHeadlineNewsScreen } from "./HeadlineNewsScreen";
import Detail from "pageComponents/headlineNews/Detail";
import { ROUTE_BASE } from "Route";
import Styled from "./Styled";
import { ResponsiveUI } from "utils/responsive";
import { useTranslation } from "react-i18next";

const HeadlineNewsDetail = () => {
  const { newsId } = useParams();
  const navigate = useNavigate();
  const { headlineNews } = useOutletContext<IHeadlineNewsScreen>();
  const [selectedNews, setSelectedNews] = useState<HeadlineNews | null>(null);
  const { t, i18n } = useTranslation("home");

  useEffect(() => {
    const getNews = () => {
      if (newsId) {
        const foundedNews = headlineNews.find((news) => news._id === newsId);
        if (foundedNews) {
          return foundedNews;
        }
      }
      return null;
    };
    if (newsId && headlineNews.length > 0) {
      const news = getNews();
      if (!news) {
        console.log("no matched headline news");
        navigate("../");
      } else {
        setSelectedNews(news);
      }
    }
  }, [newsId, headlineNews]);

  return (
    <ResponsiveUI
      desktop={<Detail news={selectedNews ?? undefined} />}
      mobile={
        selectedNews ? (
          <Styled.Wrapper>
            <Styled.Container className="overflow-auto">
              <Detail news={selectedNews} />
            </Styled.Container>
          </Styled.Wrapper>
        ) : (
          <></>
        )
      }
    ></ResponsiveUI>
  );
};

export default HeadlineNewsDetail;
