import { IGlobalProviderContext } from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { useCategoryListViewModel } from "common/viewModels/FacilityBooking/CategoryListViewModel";
import {
  ICategoryListViewModel,
  IFacilityListViewModel,
} from "models/Facility";
import { IFacility } from "common/models/Facility";
import CategoryList from "pageComponents/facility/CategoryList";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "components/common/PageHeader";
import FacilityBanner from "assets/bg-facility-banner.png";
import { useFacilityListViewModel } from "common/viewModels/FacilityBooking/FacilityListViewModel";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import { IFacilityContextType } from "./FacilityScreen";
import PageContainer from "components/common/PageContainer";

const FacilityListPage = () => {
  const { facilityList, getFacilityList } =
    useFacilityListViewModel() as IFacilityListViewModel;
  const { t } = useTranslation("booking");
  const navigate = useNavigate();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { setFacility } = useOutletContext<IFacilityContextType>();

  useEffect(() => {
    getFacilityList();
  }, []);

  return (
    <PageContainer
      title={t("headerTitle.facilityBooking")}
      banner={FacilityBanner}
      disableBg
    >
      <CategoryList
        categoryList={facilityList}
        onClickCategory={(facilityCategoryItem) => {
          const facilityItem = facilityCategoryItem as IFacility;
          setFacility(facilityCategoryItem);
          if (facilityItem?.shouldGroupByCategory) {
            navigate(`category/${facilityCategoryItem._id}`);
          } else {
            navigate(`${facilityItem?._id}/timeslot`);
          }
        }}
      />
    </PageContainer>
  );
};

export default FacilityListPage;
