import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as _ from "lodash";
import IconLoader from "../../assets/IconLoader";
import { breakpoint } from "utils/responsive";
import Styled from "./Styled";
import Typography from "components/common/Typography";
import theme from "utils/theme";
import Space from "components/common/Space";
import IconAppStore from "assets/ic-appStore.svg";
import IconGooglePlay from "assets/ic-googlePlay.svg";
import ImageAdvBg from "assets/bg-home-adv.png";
import ImagePhone from "assets/phone.png";

const Container = styled.div`
  margin-top: 102px;
  background-image: url(${ImageAdvBg});
  background-size: cover;
  height: 330px;
  width: 100%;
  position: relative;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  @media ${breakpoint.mobile} {
    margin-top: 50px;
    padding-bottom: 50px;
    height: 250px;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

const Wapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 30px;
  margin-top: 20px;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  z-index: 2;
  @media (${breakpoint.mobile}) {
    padding: 10px;
    margin-top: 25px;
  }
`;

const Left = styled.div`
  width: 40%;
  flex-shrink: 0;
  position: relative;
`;

const Right = styled.div`
  width: 60%;
`;

const Raw = styled.p<{ display?: string }>`
  display: ${(p) => (p.display ? p.display : "block")};
  color: #fff;
  align-items: center;
  gap: 20px;
  strong {
    font-size: 40px;
    font-style: italic;
  }
  span: {
    font-size: 12px;
  }

  @media (${breakpoint.mobile}) {
    gap: 10px;
    strong {
      font-size: 22px;
    }

    > img {
      width: 40px;
      margin-left: 20px;
    }
  }
`;

const Button = styled.button<{ type: string }>`
  padding: 0px 30px;
  height: 50px;
  border-radius: 40px;
  color: ${(p) => (p.type !== "primary" ? "#517E93" : "#fff")};
  background: ${(p) => (p.type !== "primary" ? "#fff" : "#517E93")};
  margin: 0px 20px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 15px;
  @media (${breakpoint.mobile}) {
    padding: 0px 15px;
    height: 24px;
    font-size: 7px;
    img {
      width: 14px;
    }
  }
`;

const Adv = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Wapper>
          <Left>
            <div className="absolute right-0 -top-[50px] md:-top-[150px] md:right-50">
              <img
                src={ImagePhone}
                className="w-[126px] h-[260px] md:w-[300px] md:h-[530px]"
              />
            </div>
          </Left>
          <Right>
            <div>
              <Typography
                fontWeight="600"
                mFontSize="22px"
                fontSize="40px"
                color={theme.white}
                style={{ fontStyle: "italic", textShadow: "2px 2px #00000040" }}
              >
                {t("home:adv.download")}
              </Typography>
              <div className="flex flex-col md:flex-row md:items-center">
                <Typography
                  color={theme.white}
                  fontSize="12px"
                  style={{ textShadow: "2px 2px #00000040" }}
                >
                  {t("home:adv.line1")} <br />
                  {t("home:adv.line2")}
                </Typography>
                <Typography
                  fontWeight="600"
                  mFontSize="22px"
                  fontSize="40px"
                  color={theme.white}
                  style={{
                    fontStyle: "italic",
                    textShadow: "2px 2px #00000040",
                  }}
                >
                  {t("home:adv.our")}
                </Typography>
              </div>
            </div>
            <div className="hidden md:flex">
              <Styled.DownloadButton
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/app/go-park-sports/id6504893943",
                    "_blank"
                  )
                }
              >
                <img
                  src={IconAppStore}
                  className="w-[45px] h-[45px] mr-[5px]"
                />
                <div>
                  <Typography color={theme.white}>
                    {t("home:adv.downloadOnThe")}
                  </Typography>
                  <Typography
                    color={theme.white}
                    fontWeight="600"
                    fontSize="20px"
                  >
                    {t("home:adv.appStore")}
                  </Typography>
                </div>
              </Styled.DownloadButton>

              <Space width="20px" />

              <Styled.DownloadButton
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=goparksports.hk",
                    "_blank"
                  )
                }
              >
                <img
                  src={IconGooglePlay}
                  className="w-[45px] h-[45px] mr-[5px]"
                />
                <div>
                  <Typography color={theme.white}>
                    {t("home:adv.getItOn")}
                  </Typography>
                  <Typography
                    color={theme.white}
                    fontWeight="600"
                    fontSize="20px"
                  >
                    {t("home:adv.googlePlay")}
                  </Typography>
                </div>
              </Styled.DownloadButton>
            </div>
          </Right>
        </Wapper>
      </Content>
    </Container>
  );
};

export default Adv;
